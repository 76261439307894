import React from "react";

import { Tabs, Card, PageHeader } from "antd";
import { SolutionOutlined, FolderOpenOutlined } from "@ant-design/icons";
import Notif from '../Components/Notif'

import {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
} from "../actions/global";

import { connect } from "react-redux";
import TpaDetails from "../Views/AnywhereCashless/TpaDetails";
import { 
    download_tpa_user_details, download_tpa_user_details_loading, 
    get_tpa_user_details, get_tpa_user_details_loading } from "../actions/user";

const { TabPane } = Tabs;

class AnywhereCashless extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
    };
  }

  render() {
    console.log(this.props, "this.props  in Professional_container");
    return (
      <React.Fragment>
        <div>
          {this.props.location.search === "?details" ? (
            <React.Fragment>
                <TpaDetails
                    get_tpa_user_details = {this.props.get_tpa_user_details}
                    get_tpa_user_details_ret = {this.props.user_store.get_tpa_user_details_ret}
                    get_tpa_user_details_loading = {this.props.get_tpa_user_details_loading}
                    get_tpa_user_details_loading_flag = {this.props.user_store.get_tpa_user_details_loading}

                    download_tpa_user_details = {this.props.download_tpa_user_details}
                    download_tpa_user_details_ret = {this.props.user_store.download_tpa_user_details_ret}
                    download_tpa_user_details_loading = {this.props.download_tpa_user_details_loading}
                    download_tpa_user_details_loading_flag = {this.props.user_store.download_tpa_user_details_loading}

                    global_error = {this.props.global_error}
                    global_store = {this.props.global_store}
                />
            </React.Fragment>
          ) : <></> }
        </div>
        <Notif 
            global_error = {this.props.global_error}
            global_error_ret = {this.props.global_error_ret}
            global_error_clr = {this.props.global_error_clr}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth_store: state.auth_store,
  user_store: state.user_store,
  global_error_ret: state.global_store.global_error_ret,
  global_store: state.global_store,
});

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_tpa_user_details,
  get_tpa_user_details_loading,
  download_tpa_user_details,
  download_tpa_user_details_loading
})(AnywhereCashless);
