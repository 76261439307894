import React from "react"
import {  Card, Form, Button, Row, Col, Tag, Typography } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { error_form_check } from '../../utils/common_utilities'
const {Title} = Typography
const BulkSms = (props) => {
    const [form] = Form.useForm();
    const [solutions, set_solutions] = React.useState([])
    const [data, set_data] = React.useState({
        message:''
 })
 const [error, set_error] = React.useState({
       message:{ state:false, text:"" }
})
React.useEffect(()=>{
    if(!!props.send_sms_ret){
          if(props.send_sms_ret.success){
            props.global_error({
              success:true,
              message:props.send_sms_ret.message,
              heading:'SMS'
            })

          }else{
            props.global_error({
              success:false,
              message:props.send_sms_ret.message,
              heading:'SMS'
            })
          }
          props.send_sms_loading()
    }
   },[props.send_sms_ret])

   React.useEffect(()=>{
        let arr = [...props.list]
         let filter_func = (accumulator, currentvalue) => {
             console.log(accumulator.indexOf(currentvalue.name),"accumulator.indexOf(currentvalue.name)")
              let some_arr = accumulator.filter(item=>{
                 return (item.name === currentvalue.name)
              })
              if(some_arr.length===0){
                  accumulator.push(currentvalue)
              }
              return accumulator
         }
        let new_arr = arr.reduce(filter_func,[])
        set_solutions(new_arr)
   },[props.list])

 

   const send_message = () => {
    const ret_error = validate_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        let arr  = props.list.map(item=>item._id)
        props.send_sms({
            solutionIds:arr,
            messageBody:data.message
        })
    }
 }

 const validate_message  = (data) =>{
  const  error = {
    message:{ state:false, text:"" }
    }
    if(data.message===''){
      {
        error.message.text = "Please enter your message"
        error.message.state = true
      }
    }
    return error
  }
    return <React.Fragment>
    <Row>
    <Col span ={4}>
  
    <Title level={5}>Users Selected</Title>
            {solutions.map(item=>{
              return    <Tag  style={{margin:'.2rem'}} onClose={()=>props.set_key_manager_professionals(props.keymanager_store.selected_professionals.filter(val=>{
                 return (val._id !==item._id)
              }))}>
                  {item.name}
             </Tag>
              })}
    </Col>
    <Col span ={20}>
    <Form
      form={form}
      layout="vertical"
    >
    <div className="flex-parent-new">
  <span className="flex-child-1-new">
  <Title level={5}>Send Message</Title>
  <AntdInput label="Message"  
            value={data.message} 
            error_text={error.message.text} 
            loading = {props.send_sms_loading_flag}
            disabled = {props.send_sms_loading_flag} 
            toggle_error = {()=>set_error({...error, message:{text:'',state:false}})} 
            error={error.message.state} 
            name="message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            /> 
  <Button loading={props.send_sms_loading_flag} onClick={()=>send_message()} type={"primary"}>Send</Button>
   </span>

 
   </div>
   </Form>
    </Col>
    </Row>
    </React.Fragment>
}
export default BulkSms