import React from "react"
import CreatePages from "../Views/CreatePages";

const CreateMarketingPages = (props) => {
    console.log({search:props.location.search === "?create"},"SEARCH");
    return (
        <div >
            {(props.location.search === "?create") ?
                <CreatePages />
                : <React.Fragment>
                </React.Fragment>}
        </div>
    )
}

export default CreateMarketingPages;
