import React from "react"
import { global_error, global_error_clr, global_loading_set, set_mount, get_services } from "../actions/global"
import {  
  get_professionals, get_professionals_loading, get_professional_byid, get_professional_byid_loading, 
  update_professional_byid,  update_professional_byid_loading, get_prof_specialities, get_prof_specialities_loading,
  get_prof_services, get_prof_services_loading, update_catalogue_loading , update_catalogue,
  get_professional_info, get_professional_info_loading,
  get_insurance, get_insurance_loading,
  add_insurance_to_professional, add_insurance_to_professional_loading,
  remove_insurance_from_professional, remove_insurance_from_professional_loading,
  get_medical_doctors, get_medical_doctors_loading,
  update_medical_doctor, update_medical_doctor_loading,
  delete_hospital_profile, delete_hospital_profile_loading } from "../actions/professionals"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import ProfessionalsList from "../Views/ProfessionalsList"
import ProfessionalDetails from "../Views/ProfessionalDetails"
import { get_url_params } from "../utils/common_utilities"
import MedicalDoctorsList from "../Views/MedicalDoctorsList";

class Professionals extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      // console.log(this.props,"this.props  in Professional_container")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?professionals_list") ? <React.Fragment>
                    <ProfessionalsList 
                       get_professionals= {this.props.get_professionals}
                       get_professionals_ret = {this.props.professionals_store.get_professionals_ret}
                       get_professionals_loading = {this.props.get_professionals_loading}
                       get_professionals_loading_flag = {this.props.professionals_store.get_professionals_loading}
                       global_error = {this.props.global_error}

                       global_store = {this.props.global_store}

                     />
                  </React.Fragment>:
                  (this.props.location.search==="?medical_doctors") ? <React.Fragment>
                  <MedicalDoctorsList 
                     get_medical_doctors= {this.props.get_medical_doctors}
                     get_medical_doctors_ret = {this.props.professionals_store.get_medical_doctors_ret}
                     get_medical_doctors_loading = {this.props.get_medical_doctors_loading}
                     get_medical_doctors_loading_flag = {this.props.professionals_store.get_medical_doctors_loading}
                     
                     update_medical_doctor= {this.props.update_medical_doctor}
                     update_medical_doctor_ret = {this.props.professionals_store.update_medical_doctor_ret}
                     update_medical_doctor_loading = {this.props.update_medical_doctor_loading}
                     update_medical_doctor_loading_flag = {this.props.professionals_store.update_medical_doctor_loading}

                     global_error = {this.props.global_error}
                     global_store = {this.props.global_store}

                   />
                </React.Fragment>:
                  (!!get_url_params('professional_id')) ? <React.Fragment>
                   <ProfessionalDetails
                    user={this.props.user}
                    get_professional_info = {this.props.get_professional_info}
                    get_professional_info_ret = {this.props.professionals_store.get_professional_info_ret}
                    get_professional_info_loading = {this.props.get_professional_info_loading}
                    get_professional_info_loading_flag = {this.props.professionals_store.get_professional_info_loading}
                    
                    get_professional_byid = {this.props.get_professional_byid}
                    get_professional_byid_loading = {this.props.get_professional_byid_loading}
                    get_professional_byid_ret  = {this.props.professionals_store.get_professional_byid_ret}
                    get_professional_byid_loading_flag= {this.props.professionals_store.get_professional_byid_loading}

                    update_professional_byid = {this.props.update_professional_byid}
                    update_professional_byid_loading = {this.props.update_professional_byid_loading}
                    update_professional_byid_ret  = {this.props.professionals_store.update_professional_byid_ret}
                    update_professional_byid_loading_flag= {this.props.professionals_store.update_professional_byid_loading}

                    get_prof_specialities = {this.props.get_prof_specialities}
                    get_prof_specialities_ret = {this.props.professionals_store.get_prof_specialities_ret}
                    get_prof_specialities_loading = {this.props.get_prof_specialities_loading}
                    get_prof_specialities_loading_flag = {this.props.professionals_store.get_prof_specialities_loading}

                    get_prof_services = {this.props.get_prof_services}
                    get_prof_services_ret = {this.props.professionals_store.get_prof_services_ret}
                    get_prof_services_loading = {this.props.get_prof_services_loading}
                    get_prof_services_loading_flag = {this.props.professionals_store.get_prof_services_loading}

                    update_catalogue  = {this.props.update_catalogue}
                    update_catalogue_loading = {this.props.update_catalogue_loading}
                    update_catalogue_loading_flag = {this.props.professionals_store.update_catalogue_loading}
                    update_catalogue_ret = {this.props.professionals_store.update_catalogue_ret}

                    get_insurance={this.props.get_insurance}
                    get_insurance_ret={this.props.professionals_store.get_insurance_ret}
                    get_insurance_loading={this.props.get_insurance_loading}
                    get_insurance_loading_flag={this.props.professionals_store.get_insurance_loading}

                    add_insurance_to_professional={this.props.add_insurance_to_professional}
                    add_insurance_to_professional_ret={this.props.professionals_store.add_insurance_to_professional_ret}
                    add_insurance_to_professional_loading={this.props.add_insurance_to_professional_loading}
                    add_insurance_to_professional_loading_flag={this.props.professionals_store.add_insurance_to_professional_loading}

                    remove_insurance_from_professional={this.props.remove_insurance_from_professional}
                    remove_insurance_from_professional_ret={this.props.professionals_store.remove_insurance_from_professional_ret}
                    remove_insurance_from_professional_loading={this.props.remove_insurance_from_professional_loading}
                    remove_insurance_from_professional_loading_flag={this.props.professionals_store.remove_insurance_from_professional_loading}

                    delete_hospital_profile={this.props.delete_hospital_profile}
                    delete_hospital_profile_ret={this.props.professionals_store.delete_hospital_profile_ret}
                    delete_hospital_profile_loading={this.props.delete_hospital_profile_loading}
                    delete_hospital_profile_loading_flag={this.props.professionals_store.delete_hospital_profile_loading}

                    global_error = {this.props.global_error}
                    global_store = {this.props.global_store}

                    get_services = {this.props.get_services}

                   
                   />
                  </React.Fragment>:<React.Fragment></React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  professionals_store:state.professionals_store,
  global_error_ret:state.global_store.global_error_ret
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_professionals,
  get_professionals_loading,
  get_professional_byid,
  get_professional_byid_loading,
  update_professional_byid,
  update_professional_byid_loading,
  get_prof_specialities,
  get_prof_specialities_loading,
  get_prof_services,
  get_prof_services_loading,
  update_catalogue,
  update_catalogue_loading,
  get_services,
  get_professional_info_loading,
  get_professional_info,
  get_insurance,
  get_insurance_loading,
  add_insurance_to_professional,
  add_insurance_to_professional_loading,
  remove_insurance_from_professional,
  remove_insurance_from_professional_loading,
  get_medical_doctors,
  get_medical_doctors_loading,
  update_medical_doctor,
  update_medical_doctor_loading,
  delete_hospital_profile,
  delete_hospital_profile_loading
 })(Professionals)
