import React from "react"
import { Button , Card,  Menu, Form, DatePicker, Row, Col, Descriptions, Tag  } from 'antd';
import Highlighter from 'react-highlight-words';
import { error_form_check, } from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
//import Servicelist from "./Servicelist";

const AddService = (props) => {
      const [state, set_state] = React.useState({
          check_status:"PENDING"
      })
      const [data, set_data] = React.useState({
        speciality:'',
        service:'',
        category:''
    })
    const [error, set_error] = React.useState({
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
        category:{ state:false, text:"" }
 })
     const [initial_data , set_initial_data] = React.useState({})
      const [form] = Form.useForm()

React.useEffect(()=>{
    
},[])

React.useEffect(()=>{
    if(props.add_service_ret){
            if(props.add_service_ret.success){
                props.global_error({
                    success:true,
                    message:props.add_service_ret.message,
                    heading:"Add Service",
                  })
            }else {
                props.global_error({
                    success:false,
                    message:props.add_service_ret.message,
                    heading:"Add Service",
                  })
            }
        props.add_service_loading()
    }

},[props.add_service_ret])

const speciality_change = (val) => {
    set_data({...data, "speciality":val, category:''})
}



const validate  = (data) =>{
    const  error = {
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
        category:{ state:false, text:"" }
      }
      if(data.speciality===''){
        {
          error.speciality.text = "Select speciality"
          error.speciality.state = true
        }
      }  
  if(data.service===''){
    {
      error.service.text = "Enter service"
      error.service.state = true
    }
  }
  if(data.category===''){
    {
      error.category.text = "Select category"
      error.category.state = true
    }
  }
    return error
  }
const submit = () => {
    const ret_error = validate({...data});
    set_error({...error, ...ret_error});
    console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
          props.add_service({
              specialityId:data.speciality,
              service:data.service,
              category:data.category
          })

          if(props.mock_bidding) {
            props.handleModal();
          }
    }
}

const make_booking = () => {
    props.make_booking({
        serviceId: data.service,
        paymentPercent: 100,
        timeSlot: data.slot,
        date:data.date_string,
        professionalId: data.doctor,
        userId: initial_data._id,
        creditsUsed: false,
        coupon: "",
        bookIn: null,
        doctorId: null,
        bookingStatus: 'Requested',
        price:data.amount
    })
}
console.log("insode AddService ")


        return (<React.Fragment>
            <Card title={props.mock_bidding ? "" : "Add Service In Master Catalogue"} bordered={props.mock_bidding ? false : true}>
                    <Row >
                        
                        <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            >
                                <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput 
                                    label="Speciality"  
                                    value={data.speciality} 
                                    disabled = {props.get_bids_loading_flag ||props.add_service_loading_flag}
                                    options = {props.global_store.specialities}
                                    loading={props.global_store.get_specialities_loading || props.add_service_loading_flag}
                                    error_text={error.speciality.text} 
                                    toggle_error = {()=>set_error({...error,speciality:{text:'',state:false}})} 
                                    error={error.speciality.state}
                                    name="speciality" 
                                    type="select"
                                    onChange = {event=>speciality_change(event)} 
                                    placeholder="Choose speciality" />
                                </span>
                                <span className="flex-child-1-new">
                                    <AntdInput 
                                    label="Category"  
                                    value={data.category} 
                                    disabled = {props.get_bids_loading_flag ||props.add_service_loading_flag}
                                    options = {[{
                                        name:"Procedure",
                                        value:"Procedure"
                                    }, {
                                        name:"Consultation",
                                        value:"Consultation"
                                    },  {
                                        name:"Test",
                                        value:"Test"
                                    }]}
                                    loading={props.global_store.get_specialities_loading|| props.add_service_loading_flag}
                                    error_text={error.category.text} 
                                    toggle_error = {()=>set_error({...error,category:{text:'',state:false}})} 
                                    error={error.category.state}
                                    name="category" 
                                    type="select"
                                    onChange = {event=>  set_data({...data, category:event})} 
                                    placeholder="Choose speciality" />
                                </span>
                                
                                <span className="flex-child-1-new">
                                    <AntdInput 
                                    label="Service"  
                                    value={data.service} 
                                    loading={props.global_store.get_services_loading || props.add_service_loading_flag}
                                    disabled = {props.get_bids_loading_flag || props.add_service_loading_flag}
                                    error_text={error.service.text} 
                                    toggle_error = {()=>set_error({...error,service:{text:'',state:false}})} 
                                    error={error.service.state}
                                    name="service" 
                                    onChange = {event=>{
                                        console.log(event,"event in Add Service")
                                        set_data({...data, service:event.target.value})
                                    }} 
                                    placeholder="Enter service name" />
                                </span>
                                </div>

                          

                                <div className=" u-margin-top-small text-center">
                                <Button
                                    type="primary"
                                    loading={props.add_service_loading_flag}
                                    disabled={props.add_service_loading_flag}
                                    onClick={() => submit()}
                                    style={{ width: 'auto', margin:'auto' }}
                                  >
                                    Submit
                                 </Button>
                                </div>
</Form>
                        </Col>
                    </Row>

                   
            </Card>
        </React.Fragment>)
}
export default AddService