import React, { useState, useEffect } from 'react';
import { get_url } from "../utils/common_utilities";
import ReactQuill from 'react-quill';
import CustomToolbar from '../Components/QuillEditor/CustomToolbar';
import 'react-quill/dist/quill.snow.css';

import { Upload, Button, Typography, message, notification, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';

import axios from 'axios';

function BlogsCard({ heading, header, initialData, editBlog, closeModal, reloadTable}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPlusRotated, setIsPlusRotated] = useState(false);
  const [value, setValue] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    tags: '',
    author: '',
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('')
  const [imageFlieList, setImageFileList] = useState([])

  useEffect(() => {
    if (initialData) {
      setFormData({
        title: initialData.title || '',
        description: initialData.description || '',
        tags: initialData.tags ? initialData.tags?.map((word, idx) => {
          if(idx < initialData.tags?.length - 1) {
            return word + ','
          } else {
            return word
          }
        }).join('') : '',
        author: initialData.author || '',
      });
      setValue(initialData.body || '');
      setImageUrl(initialData.imageUrl || '');
    }
  }, [initialData]);

  const handleFileChange = (info) => {
    if (info.file.status === 'done' || info.file.status === 'removed') {
      setSelectedFile(info.file.status === 'done' ? info.file.originFileObj : null);
    }
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    ['link', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ align: ['right', 'center', false] }],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  const handleChange= (html)=> {
    setValue(html);
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const togglePlusIcon = () => {
    setIsPlusRotated(!isPlusRotated);
    if (isOpen) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const { title, description, tags, author } = formData;
  
    if (!title || !description || !tags || !author || !imageUrl) {
      message.error({
        content: 'Please fill out all fields.',
        duration: 3,
      });
      return;
    }
    if(!value || value==='' || value==='<p><br></p>') {
      message.error({
        content: 'Please fill out all fields.',
        duration: 3,
      });
      return;
    }
  
    const tagsArray = tags.split(',').map(tag => tag.trim());
  
    const token = localStorage.getItem('token');
    if (!token) {
      message.error({
        content: 'No token found. Please login again.',
        duration: 3,
      });
      return;
    }
  
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await axios.post(`${get_url()}/blog/createBlog`, {
        title,
        description,
        tags: tagsArray,
        author,
        body: value,
        imageUrl: imageUrl,
      }, { headers });
  
      if (response.data.success) {
        reloadTable()
        setFormData({
          title: '',
          description: '',
          tags: '',
          author: '',
        })
        setImageFileList([])
        setImageUrl('')
        setValue('')
        setIsOpen(false)
        message.success({
          content: 'Form submitted successfully!',
          duration: 3,
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
      message.error({
        content: 'Error submitting form.',
        duration: 3,
      });
    }
  };

  const handleUpdateBlog = async () => {
    try {

      if(!initialData._id) {
        message.error({
          content: 'Please select the blog to update',
          duration: 3,
        });
        return
      }

      const { title, description, tags, author } = formData;
      if (!title || !description || !tags || !author || !imageUrl) {
        message.error({
          content: 'Please fill out all fields.',
          duration: 3,
        });
        return;
      }
      if(!value || value==='' || value==='<p><br></p>') {
        message.error({
          content: 'Please fill out all fields.',
          duration: 3,
        });
        return;
      }

      const tagsArray = tags.split(',').map(tag => tag.trim());

      const token = localStorage.getItem('token');
      if (!token) {
        message.error({
          content: 'No token found. Please login again.',
          duration: 3,
        });
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const body = {
        title,
        description,
        tags: tagsArray,
        author,
        body: value,
        imageUrl: imageUrl,
      }

      const response = await axios.put(`${get_url()}/blog/updateBlogById?blogId=${initialData._id}`, body, {headers})
      if (response.data.success) {
        message.success({
          content: 'Form submitted successfully!',
          duration: 3,
        });
        reloadTable()
        closeModal();
      } else {
        message.error({
          content: 'Error updating form. 1',
          duration: 3,
        });
      }
      
    } catch (error) {
      console.error('Error updating form:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
      message.error({
        content: 'Error updating form. 2',
        duration: 3,
      });
    }
  }

  const blogImageProps = {
    name: 'file',
    accept: 'image/png, image/jpg, image/jpeg',
    action: `${get_url()}/blog/uploadBlogImage`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    fileList: imageFlieList,
    beforeUpload: (file) => {
      const isImg = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type);
      const isLess5M = (file.size / 1024 / 1024) < 5
      if (!isImg) {
        notification.error({
          message: 'Error',
          description: 'Please upload PNG/JPG/JPEG only'
        })
      }
      // console.warn({file})
      if (!isLess5M) {
        notification.error({
          message: 'Error',
          description: 'Please upload files with size less than 5 MB'
        })
      }
      return (isImg && isLess5M);
    },
    onChange (info) {
      // console.warn({info})
    },
    onSuccess(info, file) {
      console.warn({info})
      if (info.success) {
        setImageUrl(info.data?.url)
        setImageFileList([file])
        notification.success({
          message: 'Success',
          description: 'Image uploaded successfully'
        });
      }
    },
    onError(error, response, file) {
      notification.error({
        message: 'Error',
        description: 'Image upload failed'
      });
    },
    onRemove (info) {
      // console.warn({info})
      setImageFileList([])
      setImageUrl('')
    },
  }

  useEffect(() => {
    console.warn(imageUrl, imageFlieList);
  }, [imageUrl, imageFlieList])
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  
  return (
    <div className={`dropdown-menu ${ !header ? 'open' : isOpen ? 'open' : ''}`}>
      <div className="dropdown-header" onClick={header ? toggleDropdown : undefined}>
        <span>{heading}</span>
        {header && (isOpen ? <FaChevronUp /> : <FaChevronDown />)}
      </div>
      <div className={`dropdown-content ${!header ? 'open' : isOpen ? 'open' : ''}`}>
        <form className={!header ? 'open' : isOpen ? 'open' : ''}>
          <div className="row">
            <div className='blog-input-cont'>
              <label>Title*</label>
              <Input placeholder='Enter Title' value={formData.title} onChange={e=>setFormData({...formData, title: e.target.value})} className='blog-input'/>
            </div>
            <div className='blog-input-cont'>
              <label>Description*</label>
              <Input placeholder='Enter description' value={formData.description} onChange={e=>setFormData({...formData, description: e.target.value})} className='blog-input'/>
            </div>
            <div className='blog-input-cont'>
              <label>Tags*</label>
              <Input  placeholder='Enter tags' value={formData.tags} onChange={e=>setFormData({...formData, tags: e.target.value})} className='blog-input'/>
              <span>Add comma (",") to separate tags</span>
            </div>
            <div className='blog-input-cont'>
              <label>Author*</label>
              <Input placeholder='Enter author' value={formData.author} onChange={e=>setFormData({...formData, author: e.target.value})} className='blog-input'/>
            </div>
          </div>
          <div className='upload-image-container'>
          <div className='upload-button'>
            <Upload
              {...blogImageProps}
            >
              <Button
                icon={<UploadOutlined />}
                type="primary"
                style={{ width: '100%', marginTop: 16 }}
              >
                Upload file
              </Button>
            </Upload>
          </div>
          {imageUrl && (
            <div className="image-preview">
              <img src={imageUrl} alt="Preview" className='blog-image' />
            </div>
          )}
          </div>
          {/* <CustomToolbar2/> */}
          <ReactQuill
            modules={modules}
            theme="snow"
            value={value}
            onChange={setValue}
            // formats={formats2}
            style={{ marginBottom: '20px' }}
            className='blog-text-editor'
          />
          <div
            className={`plus-icon ${isPlusRotated ? 'rotated' : ''}`}
            onClick={!editBlog ? togglePlusIcon : handleUpdateBlog}
          >
            <FiPlus />
          </div>
        </form>
      </div>
    </div>

  );
}

export default BlogsCard;