export const GET_USER  = 'App/User/GET_USER'
export const GET_USER_RET  = 'App/User/GET_USER_RET'
export const GET_USER_LOADING  = 'App/User/GET_USER_LOADING'

export const LOGIN  = 'App/Auth/LOGIN'
export const LOGIN_RET  = 'App/Auth/LOGIN_RET'
export const LOGIN_LOADING  = 'App/Auth/LOGIN_LOADING'

export const LOGOUT  = 'App/Auth/LOGOUT'
export const LOGOUT_RET  = 'App/Auth/LOGOUT_RET'
export const LOGOUT_CLR  = 'App/Auth/LOGOUT_CLR'

export const GET_REPORTS_BYID  = 'App/Bookings/GET_REPORTS_BYID'
export const GET_REPORTS_BYID_RET  = 'App/Bookings/GET_REPORTS_BYID_RET'
export const GET_REPORTS_BYID_LOADING  = 'App/Bookings/GET_REPORTS_BYID_LOADING'



export const GLOBAL_ERROR  = 'App/global/GLOBAL_ERROR'
export const GLOBAL_ERROR_RET  = 'App/global/GLOBAL_ERROR_RET'
export const GLOBAL_ERROR_CLR  = 'App/global/GLOBAL_ERROR_CLR'

export const GET_AGENT_LIST  = 'App/global/GET_AGENT_LIST'
export const GET_AGENT_LIST_RET  = 'App/global/GET_AGENT_LIST_RET'
export const GET_AGENT_LIST_LOADING  = 'App/global/GET_AGENT_LIST_LOADING'


export const UPLOAD_PROGRESS_TOGGLE  = 'App/global/UPLOAD_PROGRESS_TOGGLE'

export const SET_GLOBAL_UPLOAD  = 'App/global/SET_GLOBAL_UPLOAD'
export const SET_USER_DATA  = 'App/global/SET_USER_DATA'
export const GET_USER_DATA  = 'App/global/GET_USER_DATA'

export const SET_MOUNT = 'App/global/SET_MOUNT'

export const GLOBAL_LOADING  = 'App/global/GLOBAL_LOADING'
export const GLOBAL_LOADING_CLR  = 'App/global/GLOBAL_LOADING_CLR'

export const GET_MEMBERS  = 'App/members/GET_MEMBERS'
export const GET_MEMBERS_RET  = 'App/members/GET_MEMBERS_RET'
export const GET_MEMBERS_LOADING  = 'App/members/GET_MEMBERS_LOADING'

export const ADD_MEMBER  = 'App/members/ADD_MEMBER'
export const ADD_MEMBER_RET  = 'App/members/ADD_MEMBER_RET'
export const ADD_MEMBER_LOADING  = 'App/members/ADD_MEMBER_LOADING'

export const GET_SPECIALITIES  = 'App/global/GET_SPECIALITIES'
export const GET_SPECIALITIES_RET  = 'App/global/GET_SPECIALITIES_RET'
export const SET_SPECIALITIES  = 'App/global/SET_SPECIALITIES'
export const GET_SPECIALITIES_LOADING  = 'App/global/GET_SPECIALITIES_LOADING'

export const SET_MEMBERS_DATA  = 'App/members/SET_MEMBERS_DATA'



export const GET_SERVICES  = 'App/global/GET_SERVICES'
export const GET_SERVICES_RET  = 'App/global/GET_SERVICES_RET'
export const SET_SERVICES  = 'App/global/SET_SERVICES'
export const GET_SERVICES_LOADING  = 'App/global/GET_SERVICES_LOADING'

export const GET_SERVICE_FAMILIES =   'App/global/GET_SERVICE_FAMILIES'
export const GET_SERVICE_FAMILIES_RET  = 'App/global/GET_SERVICE_FAMILIES_RET'
export const SET_SERVICE_FAMILIES  = 'App/global/SET_SERVICE_FAMILIES'
export const GET_SERVICE_FAMILIES_LOADING  = 'App/global/GET_SERVICE_FAMILIES_LOADING'

export const GET_BIDS  = 'App/global/GET_BIDS'
export const GET_BIDS_RET  = 'App/global/GET_BIDS_RET'
export const GET_BIDS_LOADING  = 'App/global/GET_BIDS_LOADING'

export const GET_PROFESIONALS  = 'App/Professionals/GET_PROFESIONALS'
export const GET_PROFESIONALS_RET  = 'App/Professionals/GET_PROFESIONALS_RET'
export const GET_PROFESIONALS_LOADING  = 'App/Professionals/GET_PROFESIONALS_LOADING'
export const SET_PROFESSIONALS_LIST  = 'App/Professionals/SET_PROFESSIONALS_LIST'

export const GET_PROFESSIONAL_BYID  = 'App/Professionals/GET_PROFESSIONAL_BYID'
export const GET_PROFESSIONAL_BYID_RET  = 'App/Professionals/GET_PROFESSIONAL_BYID_RET'
export const GET_PROFESSIONAL_BYID_LOADING  = 'App/Professionals/GET_PROFESSIONAL_BYID_LOADING'

export const UPDATE_PROFESSIONAL_BYID  = 'App/Professionals/UPDATE_PROFESSIONAL_BYID'
export const UPDATE_PROFESSIONAL_BYID_RET  = 'App/Professionals/UPDATE_PROFESSIONAL_BYID_RET'
export const UPDATE_PROFESSIONAL_BYID_LOADING  = 'App/Professionals/UPDATE_PROFESSIONAL_BYID_LOADING'

export const GET_PROF_SPECIALITIES  = 'App/Professionals/GET_PROF_SPECIALITIES'
export const GET_PROF_SPECIALITIES_RET  = 'App/Professionals/GET_PROF_SPECIALITIES_RET'
export const GET_PROF_SPECIALITIES_LOADING  = 'App/Professionals/GET_PROF_SPECIALITIES_LOADING'


export const GET_PROF_SERVICES  = 'App/Professionals/GET_PROF_SERVICES'
export const GET_PROF_SERVICES_RET  = 'App/Professionals/GET_PROF_SERVICES_RET'
export const GET_PROF_SERVICES_LOADING = 'App/Professionals/GET_PROF_SERVICES_LOADING'

export const GET_KEY_PROF  = 'App/KeyManager/GET_KEY_PROF'
export const GET_KEY_PROF_RET  = 'App/KeyManager/GET_KEY_PROF_RET'
export const GET_KEY_PROF_LOADING = 'App/KeyManager/GET_KEY_PROF_LOADING'

export const ADD_INSURANCE = 'App/KeyManager/ADD_INSURANCE'
export const ADD_INSURANCE_RET = 'App/KeyManager/ADD_INSURANCE_RET'
export const ADD_INSURANCE_LOADING = 'App/KeyManager/ADD_INSURANCE_LOADING'

export const GET_INSURANCE = 'App/KeyManager/GET_INSURANCE'
export const GET_INSURANCE_RET = 'App/KeyManager/GET_INSURANCE_RET'
export const GET_INSURANCE_LOADING = 'App/KeyManager/GET_INSURANCE_LOADING'

export const REMOVE_INSURANCE_FROM_DB = 'App/KeyManager/REMOVE_INSURANCE_FROM_DB'
export const REMOVE_INSURANCE_FROM_DB_RET = 'App/KeyManager/REMOVE_INSURANCE_FROM_DB_RET'
export const REMOVE_INSURANCE_FROM_DB_LOADING = 'App/KeyManager/REMOVE_INSURANCE_FROM_DB_LOADING'

export const REMOVE_INSURANCE_FROM_PROFESSIONAL = 'App/KeyManager/REMOVE_INSURANCE_FROM_PROFESSIONAL'
export const REMOVE_INSURANCE_FROM_PROFESSIONAL_RET = 'App/KeyManager/REMOVE_INSURANCE_FROM_PROFESSIONAL_RET'
export const REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING = 'App/KeyManager/REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING'

export const PREVIOUS_VARIANCE_LIST  = 'App/KeyManager/PREVIOUS_VARIANCE_LIST'
export const PREVIOUS_VARIANCE_LIST_RET  = 'App/KeyManager/PREVIOUS_VARIANCE_LIST_RET'
export const PREVIOUS_VARIANCE_LIST_LOADING = 'App/KeyManager/PREVIOUS_VARIANCE_LIST_LOADING'

export const GET_KEY_PROF_SERVICES  = 'App/KeyManager/GET_KEY_PROF_SERVICES'
export const GET_KEY_PROF_SERVICES_RET  = 'App/KeyManager/GET_KEY_PROF_SERVICES_RET'
export const GET_KEY_PROF_SERVICES_LOADING = 'App/KeyManager/GET_KEY_PROF_SERVICES_LOADING'

export const GET_PUBLIC_QUERIES  = 'App/Salesfunnel/GET_PUBLIC_QUERIES'
export const GET_PUBLIC_QUERIES_RET  = 'App/Salesfunnel/GET_PUBLIC_QUERIES_RET'
export const GET_PUBLIC_QUERIES_LOADING = 'App/Salesfunnel/GET_PUBLIC_QUERIES_LOADING'

export const GET_COVID_QUERIES  = 'App/Salesfunnel/GET_COVID_QUERIES'
export const GET_COVID_QUERIES_RET  = 'App/Salesfunnel/GET_COVID_QUERIES_RET'
export const GET_COVID_QUERIES_LOADING = 'App/Salesfunnel/GET_COVID_QUERIES_LOADING'

export const UPDATE_COVID_QUERIES  = 'App/Salesfunnel/UPDATE_COVID_QUERIES'
export const UPDATE_COVID_QUERIES_RET  = 'App/Salesfunnel/UPDATE_COVID_QUERIES_RET'
export const UPDATE_COVID_QUERIES_LOADING = 'App/Salesfunnel/UPDATE_COVID_QUERIES_LOADING'

export const GET_FACEBOOK_QUERIES  = 'App/Salesfunnel/GET_FACEBOOK_QUERIES'
export const GET_FACEBOOK_QUERIES_RET  = 'App/Salesfunnel/GET_FACEBOOK_QUERIES_RET'
export const GET_FACEBOOK_QUERIES_LOADING = 'App/Salesfunnel/GET_FACEBOOK_QUERIES_LOADING'

export const UPLOAD_FACEBOOK_QUERIES  = 'App/Salesfunnel/UPLOAD_FACEBOOK_QUERIES'
export const UPLOAD_FACEBOOK_QUERIES_RET  = 'App/Salesfunnel/UPLOAD_FACEBOOK_QUERIES_RET'
export const UPLOAD_FACEBOOK_QUERIES_LOADING = 'App/Salesfunnel/UPLOAD_FACEBOOK_QUERIES_LOADING'

export const SEND_IVR_MESSAGE  = 'App/Salesfunnel/SEND_IVR_MESSAGE'
export const SEND_IVR_MESSAGE_RET  = 'App/Salesfunnel/SEND_IVR_MESSAGE_RET'
export const SEND_IVR_MESSAGE_LOADING = 'App/Salesfunnel/SEND_IVR_MESSAGE_LOADING'

export const SUBMIT_QUERY  = 'App/Salesfunnel/SUBMIT_QUERY'
export const SUBMIT_QUERY_RET  = 'App/Salesfunnel/SUBMIT_QUERY_RET'
export const SUBMIT_QUERY_LOADING = 'App/Salesfunnel/SUBMIT_QUERY_LOADING'

export const UPDATE_CITY  = 'App/Salesfunnel/UPDATE_CITY'
export const UPDATE_CITY_RET  = 'App/Salesfunnel/UPDATE_CITY_RET'
export const UPDATE_CITY_LOADING = 'App/Salesfunnel/UPDATE_CITY_LOADING'

export const UPDATE_STATUS  = 'App/Salesfunnel/UPDATE_STATUS'
export const UPDATE_STATUS_RET  = 'App/Salesfunnel/UPDATE_STATUS_RET'
export const UPDATE_STATUS_LOADING = 'App/Salesfunnel/UPDATE_STATUS_LOADING'

export const GET_REG_PUBLIC_QUERIES  = 'App/Salesfunnel/GET_REG_PUBLIC_QUERIES'
export const GET_REG_PUBLIC_QUERIES_RET  = 'App/Salesfunnel/GET_REG_PUBLIC_QUERIES_RET'
export const GET_REG_PUBLIC_QUERIES_LOADING = 'App/Salesfunnel/GET_REG_PUBLIC_QUERIES_LOADING'

export const SEND_VARIANCE_INFO  = 'App/Salesfunnel/SEND_VARIANCE_INFO'
export const SEND_VARIANCE_INFO_RET  = 'App/Salesfunnel/SEND_VARIANCE_INFO_RET'
export const SEND_VARIANCE_INFO_LOADING = 'App/Salesfunnel/SEND_VARIANCE_INFO_LOADING'


export const SET_KEY_MANAGER_PROFESSIONALS = 'App/Salesfunnel/SET_KEY_MANAGER_PROFESSIONALS'


export const PREVIOUS_BUSINESS_REPORTS  = 'App/KeyManager/PREVIOUS_BUSINESS_REPORTS'
export const PREVIOUS_BUSINESS_REPORTS_RET  = 'App/KeyManager/PREVIOUS_BUSINESS_REPORTS_RET'
export const PREVIOUS_BUSINESS_REPORTS_LOADING = 'App/KeyManager/PREVIOUS_BUSINESS_REPORTS_LOADING'


export const SEND_BUSINESS_REPORTS  = 'App/KeyManager/SEND_BUSINESS_REPORTS'
export const SEND_BUSINESS_REPORTS_RET  = 'App/KeyManager/SEND_BUSINESS_REPORTS_RET'
export const SEND_BUSINESS_REPORTS_LOADING = 'App/KeyManager/SEND_BUSINESS_REPORTS_LOADING'

export const SEND_CATALOGUE_REPORT  = 'App/KeyManager/SEND_CATALOGUE_REPORT'
export const SEND_CATALOGUE_REPORT_RET  = 'App/KeyManager/SEND_CATALOGUE_REPORT_RET'
export const SEND_CATALOGUE_REPORT_LOADING = 'App/KeyManager/SEND_CATALOGUE_REPORT_LOADING'

export const SEND_SMS  = 'App/Salesfunnel/SEND_SMS'
export const SEND_SMS_RET  = 'App/Salesfunnel/SEND_SMS_RET'
export const SEND_SMS_LOADING = 'App/Salesfunnel/SEND_SMS_LOADING'

export const GET_SOLUTIONS  = 'App/Salesfunnel/GET_SOLUTIONS'
export const GET_SOLUTIONS_RET  = 'App/Salesfunnel/GET_SOLUTIONS_RET'
export const GET_SOLUTIONS_LOADING = 'App/Salesfunnel/GET_SOLUTIONS_LOADING'

export const SEND_SOLUTION_SMS  = 'App/Salesfunnel/SEND_SOLUTION_SMS'
export const SEND_SOLUTION_SMS_RET  = 'App/Salesfunnel/SEND_SOLUTION_SMS_RET'
export const SEND_SOLUTION_SMS_LOADING = 'App/Salesfunnel/SEND_SOLUTION_SMS_LOADING'

export const BULK_SEND  = 'App/Salesfunnel/BULK_SEND'
export const BULK_SEND_RET  = 'App/Salesfunnel/BULK_SEND_RET'
export const BULK_SEND_LOADING = 'App/Salesfunnel/BULK_SEND_LOADING'


export const UPDATE_FACEBOOK_DATA  = 'App/Salesfunnel/UPDATE_FACEBOOK_DATA'
export const UPDATE_FACEBOOK_DATA_RET  = 'App/Salesfunnel/UPDATE_FACEBOOK_DATA_RET'
export const UPDATE_FACEBOOK_DATA_LOADING = 'App/Salesfunnel/UPDATE_FACEBOOK_DATA_LOADING'

export const EMAIL_PREVIEW  = 'App/KeyManager/EMAIL_PREVIEW'
export const EMAIL_PREVIEW_RET  = 'App/KeyManager/EMAIL_PREVIEW_RET'
export const EMAIL_PREVIEW_LOADING = 'App/KeyManager/EMAIL_PREVIEW_LOADING'

export const SEND_SPECIALITY_REPORT  = 'App/KeyManager/SEND_SPECIALITY_REPORT'
export const SEND_SPECIALITY_REPORT_RET  = 'App/KeyManager/SEND_SPECIALITY_REPORT_RET'
export const SEND_SPECIALITY_REPORT_LOADING = 'App/KeyManager/SEND_SPECIALITY_REPORT_LOADING'

export const SENT_SPECIALITY_REPORTS  = 'App/KeyManager/SENT_SPECIALITY_REPORTS'
export const SENT_SPECIALITY_REPORTS_RET  = 'App/KeyManager/SENT_SPECIALITY_REPORTS_RET'
export const SENT_SPECIALITY_REPORTS_LOADING = 'App/KeyManager/SENT_SPECIALITY_REPORTS_LOADING'

export const SENT_CATALOGUE_REPORTS  = 'App/KeyManager/SENT_CATALOGUE_REPORTS'
export const SENT_CATALOGUE_REPORTS_RET  = 'App/KeyManager/SENT_CATALOGUE_REPORTS_RET'
export const SENT_CATALOGUE_REPORTS_LOADING = 'App/KeyManager/SENT_CATALOGUE_REPORTS_LOADING'

export const GET_IVR_CALLS  = 'App/KeyManager/GET_IVR_CALLS'
export const GET_IVR_CALLS_RET  = 'App/KeyManager/GET_IVR_CALLS_RET'
export const GET_IVR_CALLS_LOADING = 'App/KeyManager/GET_IVR_CALLS_LOADING'

export const GET_REAL_TIME_INSIGHTS  = 'App/InsightsAnalytics/GET_REAL_TIME_INSIGHTS'
export const GET_REAL_TIME_INSIGHTS_RET  = 'App/InsightsAnalytics/GET_REAL_TIME_INSIGHTS_RET'
export const GET_REAL_TIME_INSIGHTS_LOADING = 'App/InsightsAnalytics/GET_REAL_TIME_INSIGHTS_LOADING'

export const GET_ACTIONABLE_INSIGHTS  = 'App/InsightsAnalytics/GET_ACTIONABLE_INSIGHTS'
export const GET_ACTIONABLE_INSIGHTS_RET  = 'App/InsightsAnalytics/GET_ACTIONABLE_INSIGHTS_RET'
export const GET_ACTIONABLE_INSIGHTS_LOADING = 'App/InsightsAnalytics/GET_ACTIONABLE_INSIGHTS_LOADING'

export const GET_SUGGESTED_INSIGHTS  = 'App/InsightsAnalytics/GET_SUGGESTED_INSIGHTS'
export const GET_SUGGESTED_INSIGHTS_RET  = 'App/InsightsAnalytics/GET_SUGGESTED_INSIGHTS_RET'
export const GET_SUGGESTED_INSIGHTS_LOADING = 'App/InsightsAnalytics/GET_SUGGESTED_INSIGHTS_LOADING'


export const GET_IVR_MESSAGES  = 'App/Salesfunnel/GET_IVR_MESSAGES'
export const GET_IVR_MESSAGES_RET  = 'App/Salesfunnel/GET_IVR_MESSAGES_RET'
export const GET_IVR_MESSAGES_LOADING  = 'App/Salesfunnel/GET_IVR_MESSAGES_LOADING'


export const GET_UPLOAD_LIST  = 'App/Salesfunnel/GET_UPLOAD_LIST'
export const GET_UPLOAD_LIST_RET  = 'App/Salesfunnel/GET_UPLOAD_LIST_RET'
export const GET_UPLOAD_LIST_LOADING  = 'App/Salesfunnel/GET_UPLOAD_LIST_LOADING'

export const GET_BULK_SMS_LIST  = 'App/Salesfunnel/GET_BULK_SMS_LIST'
export const GET_BULK_SMS_LIST_RET  = 'App/Salesfunnel/GET_BULK_SMS_LIST_RET'
export const GET_BULK_SMS_LIST_LOADING  = 'App/Salesfunnel/GET_BULK_SMS_LIST_LOADING'

export const CHECK_NUMBER  = 'App/Bookings/CHECK_NUMBER'
export const CHECK_NUMBER_RET  = 'App/Bookings/CHECK_NUMBER_RET'
export const CHECK_NUMBER_LOADING  = 'App/Bookings/CHECK_NUMBER_LOADING'

export const REGISTER_USER  = 'App/Bookings/REGISTER_USER'
export const REGISTER_USER_RET  = 'App/Bookings/REGISTER_USER_RET'
export const REGISTER_USER_LOADING  = 'App/Bookings/REGISTER_USER_LOADING'

export const GET_DOCTORS  = 'App/Bookings/GET_DOCTORS'
export const GET_DOCTORS_RET  = 'App/Bookings/GET_DOCTORS_RET'
export const GET_DOCTORS_LOADING  = 'App/Bookings/GET_DOCTORS_LOADING'

export const MAKE_BOOKING  = 'App/Bookings/MAKE_BOOKING'
export const MAKE_BOOKING_RET  = 'App/Bookings/MAKE_BOOKING_RET'
export const MAKE_BOOKING_LOADING  = 'App/Bookings/MAKE_BOOKING_LOADING'

export const GENERATE_LINK  = 'App/Bookings/GENERATE_LINK'
export const GENERATE_LINK_RET  = 'App/Bookings/GENERATE_LINK_RET'
export const GENERATE_LINK_LOADING  = 'App/Bookings/GENERATE_LINK_LOADING'

export const GET_BOOKINGS  = 'App/Bookings/GET_BOOKINGS'
export const GET_BOOKINGS_RET  = 'App/Bookings/GET_BOOKINGS_RET'
export const GET_BOOKINGS_LOADING  = 'App/Bookings/GET_BOOKINGS_LOADING'

export const GLOBAL_BOOKINGS  = 'App/Bookings/GLOBAL_BOOKINGS'
export const GLOBAL_BOOKINGS_RET  = 'App/Bookings/GLOBAL_BOOKINGS_RET'
export const GLOBAL_BOOKINGS_LOADING  = 'App/Bookings/GLOBAL_BOOKINGS_LOADING'

export const UPDATE_GLOBAL_BOOKING  = 'App/Bookings/UPDATE_GLOBAL_BOOKING'
export const UPDATE_GLOBAL_BOOKING_RET  = 'App/Bookings/UPDATE_GLOBAL_BOOKING_RET'
export const UPDATE_GLOBAL_BOOKING_LOADING  = 'App/Bookings/UPDATE_GLOBAL_BOOKING_LOADING'

export const APPROVE_BOOKING_INSURANCE_UPDATE  = 'App/Bookings/APPROVE_BOOKING_INSURANCE_UPDATE'
export const APPROVE_BOOKING_INSURANCE_UPDATE_RET  = 'App/Bookings/APPROVE_BOOKING_INSURANCE_UPDATE_RET'
export const APPROVE_BOOKING_INSURANCE_UPDATE_LOADING  = 'App/Bookings/APPROVE_BOOKING_INSURANCE_UPDATE_LOADING'

export const GET_REFUNDS  = 'App/Bookings/GET_REFUNDS'
export const GET_REFUNDS_RET  = 'App/Bookings/GET_REFUNDS_RET'
export const GET_REFUNDS_LOADING  = 'App/Bookings/GET_REFUNDS_LOADING'


export const GET_INSIGHTS  = 'App/Bookings/GET_INSIGHTS'
export const GET_INSIGHTS_RET  = 'App/Bookings/GET_INSIGHTS_RET'
export const GET_INSIGHTS_LOADING  = 'App/Bookings/GET_INSIGHTS_LOADING'


export const GET_SOL_PROFESSIONALS = 'App/Bookings/GET_SOL_PROFESSIONALS'
export const GET_SOL_PROFESSIONALS_RET  = 'App/Bookings/GET_SOL_PROFESSIONALS_RET'
export const GET_SOL_PROFESSIONALS_LOADING  = 'App/Bookings/GET_SOL_PROFESSIONALS_LOADING'


export const UPDATE_SOL_PROFESSIONAL = 'App/Bookings/UPDATE_SOL_PROFESSIONAL'
export const UPDATE_SOL_PROFESSIONAL_RET  = 'App/Bookings/UPDATE_SOL_PROFESSIONAL_RET'
export const UPDATE_SOL_PROFESSIONAL_LOADING  = 'App/Bookings/UPDATE_SOL_PROFESSIONAL_LOADING'



export const SET_USER  = 'App/Bookings/SET_USER'



export const GET_MEDICAL_DETAILS  = 'App/Bookings/GET_MEDICAL_DETAILS'
export const GET_MEDICAL_DETAILS_RET  = 'App/Bookings/GET_MEDICAL_DETAILS_RET'
export const GET_MEDICAL_DETAILS_LOADING  = 'App/Bookings/GET_MEDICAL_DETAILS_LOADING'



export const ADD_SERVICE  = 'App/KeyManager/ADD_SERVICE'
export const ADD_SERVICE_RET  = 'App/KeyManager/ADD_SERVICE_RET'
export const ADD_SERVICE_LOADING = 'App/KeyManager/ADD_SERVICE_LOADING'

export const GET_SERVICE_FAMILY_BYID  = 'App/KeyManager/GET_SERVICE_BYID_FAMILY'
export const GET_SERVICE_FAMILY_BYID_RET  = 'App/KeyManager/GET_SERVICE_FAMILY_BYID_RET'
export const GET_SERVICE_FAMILY_BYID_LOADING = 'App/KeyManager/GET_SERVICE_FAMILY_BYID_LOADING'

export const GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID  = 'App/KeyManager/GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID'
export const GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_RET  = 'App/KeyManager/GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_RET'
export const GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING = 'App/KeyManager/GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING'

export const SERVICE_DATA_HANDLER  = 'App/KeyManager/SERVICE_DATA_HANDLER'
export const SERVICE_DATA_HANDLER_RET  = 'App/KeyManager/SERVICE_DATA_HANDLER_RET'
export const SERVICE_DATA_HANDLER_LOADING = 'App/KeyManager/SERVICE_DATA_HANDLER_LOADING'


export const UPDATE_CATALOGUE  = 'App/Professionals/UPDATE_CATALOGUE'
export const UPDATE_CATALOGUE_RET  = 'App/Professionals/UPDATE_CATALOGUE_RET'
export const UPDATE_CATALOGUE_LOADING = 'App/Professionals/UPDATE_CATALOGUE_LOADING'


export const UPDATE_BOOKINGS  = 'App/Bookings/UPDATE_BOOKINGS'
export const UPDATE_BOOKINGS_RET  = 'App/Bookings/UPDATE_BOOKINGS_RET'
export const UPDATE_BOOKINGS_LOADING  = 'App/Bookings/UPDATE_BOOKINGS_LOADING'

export const GET_BAR_CHART_DATA  = 'App/CatalogueManager/GET_BAR_CHART_DATA'
export const GET_BAR_CHART_DATA_RET  = 'App/CatalogueManager/GET_BAR_CHART_DATA_RET'
export const GET_BAR_CHART_DATA_LOADING  = 'App/CatalogueManager/GET_BAR_CHART_DATA_LOADING'

export const GET_PROF_INFO  = 'App/CatalogueManager/GET_PROF_INFO'
export const GET_PROF_INFO_RET  = 'App/CatalogueManager/GET_PROF_INFO_RET'
export const GET_PROF_INFO_LOADING  = 'App/CatalogueManager/GET_PROF_INFO_LOADING'

export const UPLOAD_CATALOGUE  = 'App/CatalogueManager/UPLOAD_CATALOGUE'
export const UPLOAD_CATALOGUE_RET  = 'App/CatalogueManager/UPLOAD_CATALOGUE_RET'
export const UPLOAD_CATALOGUE_LOADING  = 'App/CatalogueManager/UPLOAD_CATALOGUE_LOADING'

export const CHECK_UPLOAD_STATUS  = 'App/CatalogueManager/CHECK_UPLOAD_STATUS'
export const CHECK_UPLOAD_STATUS_RET  = 'App/CatalogueManager/CHECK_UPLOAD_STATUS_RET'
export const CHECK_UPLOAD_STATUS_LOADING  = 'App/CatalogueManager/CHECK_UPLOAD_STATUS_LOADING'

export const GET_CATALOGUE_LIST  = 'App/CatalogueManager/GET_CATALOGUE_LIST'
export const GET_CATALOGUE_LIST_RET  = 'App/CatalogueManager/GET_CATALOGUE_LIST_RET'
export const GET_CATALOGUE_LIST_LOADING  = 'App/CatalogueManager/GET_CATALOGUE_LIST_LOADING'

export const GET_CATALOGUE_BY_ID  = 'App/CatalogueManager/GET_CATALOGUE_BY_ID'
export const GET_CATALOGUE_BY_ID_RET  = 'App/CatalogueManager/GET_CATALOGUE_BY_ID_RET'
export const GET_CATALOGUE_BY_ID_LOADING  = 'App/CatalogueManager/GET_CATALOGUE_BY_ID_LOADING'


export const GET_RECORDS  = 'App/CatalogueManager/GET_RECORDS'
export const GET_RECORDS_RET  = 'App/CatalogueManager/GET_RECORDS_RET'
export const GET_RECORDS_LOADING  = 'App/CatalogueManager/GET_RECORDS_LOADING'


export const UPDATE_UNMATCHED  = 'App/CatalogueManager/UPDATE_UNMATCHED'
export const UPDATE_UNMATCHED_RET  = 'App/CatalogueManager/UPDATE_UNMATCHED_RET'
export const UPDATE_UNMATCHED_LOADING  = 'App/CatalogueManager/UPDATE_UNMATCHED_LOADING'



export const UPDATE_PRICE_CATALOGUE  = 'App/CatalogueManager/UPDATE_PRICE_CATALOGUE'
export const UPDATE_PRICE_CATALOGUE_RET  = 'App/CatalogueManager/UPDATE_PRICE_CATALOGUE_RET'
export const UPDATE_PRICE_CATALOGUE_LOADING  = 'App/CatalogueManager/UPDATE_PRICE_CATALOGUE_LOADING'

export const GET_PRICE_COMPARISION  = 'App/CatalogueManager/GET_PRICE_COMPARISION'
export const GET_PRICE_COMPARISION_RET  = 'App/CatalogueManager/GET_PRICE_COMPARISION_RET'
export const GET_PRICE_COMPARISION_LOADING  = 'App/CatalogueManager/GET_PRICE_COMPARISION_LOADING'


export const CHECK_FOLLOW_UP  = 'App/FollowUps/CHECK_FOLLOW_UP'
export const CHECK_FOLLOW_UP_RET  = 'App/FollowUps/CHECK_FOLLOW_UP_RET'
export const CHECK_FOLLOW_UP_LOADING  = 'App/FollowUps/CHECK_FOLLOW_UP_LOADING'

export const UPDATE_FOLLOW_UP  = 'App/FollowUps/UPDATE_FOLLOW_UP'
export const UPDATE_FOLLOW_UP_RET  = 'App/FollowUps/UPDATE_FOLLOW_UP_RET'
export const UPDATE_FOLLOW_UP_LOADING  = 'App/FollowUps/UPDATE_FOLLOW_UP_LOADING'

export const GET_FOLLOW_UPS  = 'App/FollowUps/GET_FOLLOW_UPS'
export const GET_FOLLOW_UPS_RET  = 'App/FollowUps/GET_FOLLOW_UPS_RET'
export const GET_FOLLOW_UPS_LOADING  = 'App/FollowUps/GET_FOLLOW_UPS_LOADING'



export const UPLOAD_PROGRESS_DATA  = 'App/Global/UPLOAD_PROGRESS_DATA'

export const GET_USERS  = 'App/Users/GET_USERS'
export const GET_USERS_RET  = 'App/Users/GET_USERS_RET'
export const GET_USERS_LOADING  = 'App/Users/GET_USERS_LOADING'


export const GET_NOTIFICATIONS  = 'App/Notifications/GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_RET  = 'App/Notifications/GET_NOTIFICATIONS_RET'
export const GET_NOTIFICATIONS_LOADING  = 'App/Notifications/GET_NOTIFICATIONS_LOADING'

export const GET_MANUAL_BIDDINGS  = 'App/Salesfunnel/GET_MANUAL_BIDDINGS'
export const GET_MANUAL_BIDDINGS_RET  = 'App/Salesfunnel/GET_MANUAL_BIDDINGS_RET'
export const GET_MANUAL_BIDDINGS_LOADING  = 'App/Salesfunnel/GET_MANUAL_BIDDINGS_LOADING'

export const SET_NOTFICATION_DATA  = 'App/Notifications/SET_NOTFICATION_DATA'

export const CLEAR_COUNT  = 'App/Notifications/CLEAR_COUNT'


export const GET_CATALOGUE = 'GET_CATALOGUE'
export const GET_CATALOGUE_RET = 'GET_CATALOGUE_RET'
export const GET_CATALOGUE_BYID = 'GET_CATALOGUE_BYID'
export const GET_CATALOGUE_BYID_RET = 'GET_CATALOGUE_BYID_RET'
export const CATALOGUE_VIEW = 'CATALOGUE_VIEW'


export const UPDATE_FAQ  = 'App/KeyManager/UPDATE_FAQ'
export const UPDATE_FAQ_RET  = 'App/KeyManager/UPDATE_FAQ_RET'
export const UPDATE_FAQ_LOADING  = 'App/KeyManager/UPDATE_FAQ_LOADING'

export const ADD_INSURANCE_TO_PROFESSIONAL = "App/KeyManager/ADD_INSURANCE_TO_PROFESSIONAL";
export const ADD_INSURANCE_TO_PROFESSIONAL_RET = "App/KeyManager/ADD_INSURANCE_TO_PROFESSIONAL_RET";
export const ADD_INSURANCE_TO_PROFESSIONAL_LOADING = "App/KeyManager/ADD_INSURANCE_TO_PROFESSIONAL_LOADING";


export const GET_PROFESSIONAL_INFO = "App/Professional/GET_PROFESSIONAL_INFO";
export const GET_PROFESSIONAL_INFO_RET = "App/Professional/GET_PROFESSIONAL_INFO_RET";
export const GET_PROFESSIONAL_INFO_LOADING = "App/Professional/GET_PROFESSIONAL_INFO_LOADING";


export const GET_MESSAGE_TEMPLATES = 'App/Salesfunnel/GET_MESSAGE_TEMPLATES'
export const GET_MESSAGE_TEMPLATES_RET = 'App/Salesfunnel/GET_MESSAGE_TEMPLATES_RET'
export const SEND_TEMPLATE_SMS = 'App/Salesfunnel/SEND_TEMPLATE_SMS'
export const SEND_IVR_TEMPLATE_SMS = 'App/Salesfunnel/SEND_IVR_TEMPLATE_SMS'
export const SEND_TEMPLATE_SMS_RET = 'App/Salesfunnel/SEND_TEMPLATE_SMS_RET'
export const SEND_TEMPLATE_SMS_LOADING = 'App/Salesfunnel/SEND_TEMPLATE_SMS_LOADING'


export const FUNNEL_GET_REPORTS_BYID  = 'App/Funnel/GET_REPORTS_BYID'
export const FUNNEL_GET_REPORTS_BYID_RET  = 'App/Funnel/GET_REPORTS_BYID_RET'
export const FUNNEL_GET_REPORTS_BYID_LOADING  = 'App/Funnel/GET_REPORTS_BYID_LOADING'



export const FUNNEL_CHECK_NUMBER  = 'App/Funnel/CHECK_NUMBER'
export const FUNNEL_CHECK_NUMBER_RET  = 'App/Funnel/CHECK_NUMBER_RET'
export const FUNNEL_CHECK_NUMBER_LOADING  = 'App/Funnel/CHECK_NUMBER_LOADING'

export const FUNNEL_REGISTER_USER  = 'App/Funnel/REGISTER_USER'
export const FUNNEL_REGISTER_USER_RET  = 'App/Funnel/REGISTER_USER_RET'
export const FUNNEL_REGISTER_USER_LOADING  = 'App/Funnel/REGISTER_USER_LOADING'

export const FUNNEL_GET_DOCTORS  = 'App/Funnel/GET_DOCTORS'
export const FUNNEL_GET_DOCTORS_RET  = 'App/Funnel/GET_DOCTORS_RET'
export const FUNNEL_GET_DOCTORS_LOADING  = 'App/Funnel/GET_DOCTORS_LOADING'

export const FUNNEL_MAKE_BOOKING  = 'App/Funnel/MAKE_BOOKING'
export const FUNNEL_MAKE_BOOKING_RET  = 'App/Funnel/MAKE_BOOKING_RET'
export const FUNNEL_MAKE_BOOKING_LOADING  = 'App/Funnel/MAKE_BOOKING_LOADING'

export const FUNNEL_GENERATE_LINK  = 'App/Funnel/GENERATE_LINK'
export const FUNNEL_GENERATE_LINK_RET  = 'App/Funnel/GENERATE_LINK_RET'
export const FUNNEL_GENERATE_LINK_LOADING  = 'App/Funnel/GENERATE_LINK_LOADING'

export const FUNNEL_GET_BOOKINGS  = 'App/Funnel/GET_BOOKINGS'
export const FUNNEL_GET_BOOKINGS_RET  = 'App/Funnel/GET_BOOKINGS_RET'
export const FUNNEL_GET_BOOKINGS_LOADING  = 'App/Funnel/GET_BOOKINGS_LOADING'

export const FUNNEL_GLOBAL_BOOKINGS  = 'App/Funnel/GLOBAL_BOOKINGS'
export const FUNNEL_GLOBAL_BOOKINGS_RET  = 'App/Funnel/GLOBAL_BOOKINGS_RET'
export const FUNNEL_GLOBAL_BOOKINGS_LOADING  = 'App/Funnel/GLOBAL_BOOKINGS_LOADING'

export const FUNNEL_UPDATE_GLOBAL_BOOKING  = 'App/Funnel/UPDATE_GLOBAL_BOOKING'
export const FUNNEL_UPDATE_GLOBAL_BOOKING_RET  = 'App/Funnel/UPDATE_GLOBAL_BOOKING_RET'
export const FUNNEL_UPDATE_GLOBAL_BOOKING_LOADING  = 'App/Funnel/UPDATE_GLOBAL_BOOKING_LOADING'

export const FUNNEL_GET_REFUNDS  = 'App/Funnel/GET_REFUNDS'
export const FUNNEL_GET_REFUNDS_RET  = 'App/Funnel/GET_REFUNDS_RET'
export const FUNNEL_GET_REFUNDS_LOADING  = 'App/Funnel/GET_REFUNDS_LOADING'


export const FUNNEL_GET_INSIGHTS  = 'App/Funnel/GET_INSIGHTS'
export const FUNNEL_GET_INSIGHTS_RET  = 'App/Funnel/GET_INSIGHTS_RET'
export const FUNNEL_GET_INSIGHTS_LOADING  = 'App/Funnel/GET_INSIGHTS_LOADING'


export const FUNNEL_GET_SOL_PROFESSIONALS = 'App/Funnel/GET_SOL_PROFESSIONALS'
export const FUNNEL_GET_SOL_PROFESSIONALS_RET  = 'App/Funnel/GET_SOL_PROFESSIONALS_RET'
export const FUNNEL_GET_SOL_PROFESSIONALS_LOADING  = 'App/Funnel/GET_SOL_PROFESSIONALS_LOADING'


export const FUNNEL_UPDATE_SOL_PROFESSIONAL = 'App/Funnel/UPDATE_SOL_PROFESSIONAL'
export const FUNNEL_UPDATE_SOL_PROFESSIONAL_RET  = 'App/Funnel/UPDATE_SOL_PROFESSIONAL_RET'
export const FUNNEL_UPDATE_SOL_PROFESSIONAL_LOADING  = 'App/Funnel/UPDATE_SOL_PROFESSIONAL_LOADING'



export const FUNNEL_SET_USER  = 'App/Funnel/SET_USER'



export const FUNNEL_UPDATE_BOOKINGS  = 'App/Funnel/UPDATE_BOOKINGS'
export const FUNNEL_UPDATE_BOOKINGS_RET  = 'App/Funnel/UPDATE_BOOKINGS_RET'
export const FUNNEL_UPDATE_BOOKINGS_LOADING  = 'App/Funnel/UPDATE_BOOKINGS_LOADING'


// IVR MSG TOKEN
export const GET_IVR_SMS_TOKEN = 'App/Salesfunnel/GET_IVR_SMS_TOKEN'
export const GET_IVR_SMS_TOKEN_RET = 'App/Salesfunnel/GET_IVR_SMS_TOKEN_RET'
export const GET_IVR_SMS_TOKEN_LOADING = 'App/Salesfunnel/GET_IVR_SMS_TOKEN_LOADING'

// Manual Template Message
export const SEND_MANUAL_TEMPLATE_SMS = 'App/Salesfunnel/SEND_MANUAL_TEMPLATE_SMS'
export const SEND_MANUAL_TEMPLATE_SMS_RET = 'App/Salesfunnel/SEND_MANUAL_TEMPLATE_SMS_RET'
export const SEND_MANUAL_TEMPLATE_SMS_LOADING = 'App/Salesfunnel/SEND_MANUAL_TEMPLATE_SMS_LOADING'

// Get Manual SMS List
export const GET_MANUAL_SMS_LIST = 'App/Salesfunnel/GET_MANUAL_SMS_LIST'
export const GET_MANUAL_SMS_LIST_RET = 'App/Salesfunnel/GET_MANUAL_SMS_LIST_RET'
export const GET_MANUAL_SMS_LIST_LOADING = 'App/Salesfunnel/GET_MANUAL_SMS_LIST_LOADING'

// Upload User Report
export const FUNNEL_UPLOAD_USER_REPORT  = 'App/Funnel/UPLOAD_USER_REPORT'
export const FUNNEL_UPLOAD_USER_REPORT_RET  = 'App/Funnel/UPLOAD_USER_REPORT_RET'
export const FUNNEL_UPLOAD_USER_REPORT_LOADING  = 'App/Funnel/UPLOAD_USER_REPORT_LOADING'

// Upload Insurance Report
export const FUNNEL_UPLOAD_INSURANCE_REPORT  = 'App/Funnel/UPLOAD_INSURANCE_REPORT'
export const FUNNEL_UPLOAD_INSURANCE_REPORT_RET  = 'App/Funnel/UPLOAD_INSURANCE_REPORT_RET'
export const FUNNEL_UPLOAD_INSURANCE_REPORT_LOADING  = 'App/Funnel/UPLOAD_INSURANCE_REPORT_LOADING'

// Submit Medical Details
export const FUNNEL_SUBMIT_MEDICAL_DETAILS  = 'App/Funnel/SUBMIT_MEDICAL_DETAILS'
export const FUNNEL_SUBMIT_MEDICAL_DETAILS_RET  = 'App/Funnel/SUBMIT_MEDICAL_DETAILS_RET'
export const FUNNEL_SUBMIT_MEDICAL_DETAILS_LOADING  = 'App/Funnel/SUBMIT_MEDICAL_DETAILS_LOADING'

// Edit Medical Details
export const FUNNEL_EDIT_MEDICAL_DETAILS  = 'App/Funnel/EDIT_MEDICAL_DETAILS'
export const FUNNEL_EDIT_MEDICAL_DETAILS_RET  = 'App/Funnel/EDIT_MEDICAL_DETAILS_RET'
export const FUNNEL_EDIT_MEDICAL_DETAILS_LOADING  = 'App/Funnel/EDIT_MEDICAL_DETAILS_LOADING'

// Custom Bookings
export const FUNNEL_CREATE_CUSTOM_SOLUTION  = 'App/Funnel/CREATE_CUSTOM_SOLUTION'
export const FUNNEL_CREATE_CUSTOM_SOLUTION_RET  = 'App/Funnel/CREATE_CUSTOM_SOLUTION_RET'
export const FUNNEL_CREATE_CUSTOM_SOLUTION_LOADING  = 'App/Funnel/CREATE_CUSTOM_SOLUTION_LOADING'

// Medical Doctors
export const GET_MEDICAL_DOCTORS  = 'App/Professionals/GET_MEDICAL_DOCTORS'
export const GET_MEDICAL_DOCTORS_RET  = 'App/Professionals/GET_MEDICAL_DOCTORS_RET'
export const GET_MEDICAL_DOCTORS_LOADING  = 'App/Professionals/GET_MEDICAL_DOCTORS_LOADING'

export const UPDATE_MEDICAL_DOCTOR  = 'App/Professionals/UPDATE_MEDICAL_DOCTOR'
export const UPDATE_MEDICAL_DOCTOR_RET  = 'App/Professionals/UPDATE_MEDICAL_DOCTOR_RET'
export const UPDATE_MEDICAL_DOCTOR_LOADING  = 'App/Professionals/UPDATE_MEDICAL_DOCTOR_LOADING'

export const UPDATE_MARKETING_PAGE  = 'App/KeyManager/UPDATE_MARKETING_PAGE'
export const UPDATE_MARKETING_PAGE_RET  = 'App/KeyManager/UPDATE_MARKETING_PAGE_RET'
export const UPDATE_MARKETING_PAGE_LOADING = 'App/KeyManager/UPDATE_MARKETING_PAGE_LOADING'

export const GET_ALL_QUERIES  = 'App/Salesfunnel/GET_ALL_QUERIES'
export const GET_ALL_QUERIES_RET  = 'App/Salesfunnel/GET_ALL_QUERIES_RET'
export const GET_ALL_QUERIES_LOADING = 'App/Salesfunnel/GET_ALL_QUERIES_LOADING'

export const GET_MERGED_QUERIES  = 'App/Salesfunnel/GET_MERGED_QUERIES'
export const GET_MERGED_QUERIES_RET  = 'App/Salesfunnel/GET_MERGED_QUERIES_RET'
export const GET_MERGED_QUERIES_LOADING = 'App/Salesfunnel/GET_MERGED_QUERIES_LOADING'

export const GET_QUERIES_BY_NUMBER  = 'App/Salesfunnel/GET_QUERIES_BY_NUMBER'
export const GET_QUERIES_BY_NUMBER_RET  = 'App/Salesfunnel/GET_QUERIES_BY_NUMBER_RET'
export const GET_QUERIES_BY_NUMBER_LOADING = 'App/Salesfunnel/GET_QUERIES_BY_NUMBER_LOADING'

export const UPDATE_PROSPECT  = 'App/Salesfunnel/UPDATE_PROSPECT'
export const UPDATE_PROSPECT_RET  = 'App/Salesfunnel/UPDATE_PROSPECT_RET'
export const UPDATE_PROSPECT_LOADING = 'App/Salesfunnel/UPDATE_PROSPECT_LOADING'

export const UPDATE_QUERY  = 'App/Salesfunnel/UPDATE_QUERY'
export const UPDATE_QUERY_RET  = 'App/Salesfunnel/UPDATE_QUERY_RET'
export const UPDATE_QUERY_LOADING = 'App/Salesfunnel/UPDATE_QUERY_LOADING'

export const UPDATE_BOOKING_PROCESS  = 'App/Salesfunnel/UPDATE_BOOKING_PROCESS'
export const UPDATE_BOOKING_PROCESS_RET  = 'App/Salesfunnel/UPDATE_BOOKING_PROCESS_RET'
export const UPDATE_BOOKING_PROCESS_LOADING = 'App/Salesfunnel/UPDATE_BOOKING_PROCESS_LOADING'

export const UPDATE_ADVANCED_TREATMENT  = 'App/KeyManager/UPDATE_ADVANCED_TREATMENT'
export const UPDATE_ADVANCED_TREATMENT_RET  = 'App/KeyManager/UPDATE_ADVANCED_TREATMENT_RET'
export const UPDATE_ADVANCED_TREATMENT_LOADING  = 'App/KeyManager/UPDATE_ADVANCED_TREATMENT_LOADING'

export const UPLOAD_ADVANCED_TREATMENT_IMAGE  = 'App/KeyManager/UPLOAD_ADVANCED_TREATMENT_IMAGE'
export const UPLOAD_ADVANCED_TREATMENT_IMAGE_RET  = 'App/KeyManager/UPLOAD_ADVANCED_TREATMENT_IMAGE_RET'
export const UPLOAD_ADVANCED_TREATMENT_IMAGE_LOADING  = 'App/KeyManager/UPLOAD_ADVANCED_TREATMENT_IMAGE_LOADING'

export const UPLOAD_ADVANCED_TREATMENT_ICON  = 'App/KeyManager/UPLOAD_ADVANCED_TREATMENT_ICON'
export const UPLOAD_ADVANCED_TREATMENT_ICON_RET  = 'App/KeyManager/UPLOAD_ADVANCED_TREATMENT_ICON_RET'
export const UPLOAD_ADVANCED_TREATMENT_ICON_LOADING  = 'App/KeyManager/UPLOAD_ADVANCED_TREATMENT_ICON_LOADING'

export const DELETE_HOSPITAL_PROFILE  = 'App/KeyManager/DELETE_HOSPITAL_PROFILE'
export const DELETE_HOSPITAL_PROFILE_RET  = 'App/KeyManager/DELETE_HOSPITAL_PROFILE_RET'
export const DELETE_HOSPITAL_PROFILE_LOADING  = 'App/KeyManager/DELETE_HOSPITAL_PROFILE_LOADING'

export const UPDATE_USER_DETAILS  = 'App/KeyManager/UPDATE_USER_DETAILS'
export const UPDATE_USER_DETAILS_RET  = 'App/KeyManager/UPDATE_USER_DETAILS_RET'
export const UPDATE_USER_DETAILS_LOADING  = 'App/KeyManager/UPDATE_USER_DETAILS_LOADING'

// Get Insurance Bookings
export const GET_INSURANCE_BOOKINGS  = 'App/KeyManager/GET_INSURANCE_BOOKINGS'
export const GET_INSURANCE_BOOKINGS_RET  = 'App/KeyManager/GET_INSURANCE_BOOKINGS_RET'
export const GET_INSURANCE_BOOKINGS_LOADING  = 'App/KeyManager/GET_INSURANCE_BOOKINGS_LOADING'

// Update Booking Session
export const UPDATE_BOOKING_SESSION = 'App/Dashboard/UPDATE_BOOKING_SESSION'
export const UPDATE_BOOKING_SESSION_RET = 'App/Dashboard/UPDATE_BOOKING_SESSION_RET'
export const UPDATE_BOOKING_SESSION_LOADING = 'App/Dashboard/UPDATE_BOOKING_SESSION_LOADING'

// Update Booking Session
export const UPDATE_BOOKING = 'App/Dashboard/UPDATE_BOOKING'
export const UPDATE_BOOKING_RET = 'App/Dashboard/UPDATE_BOOKING_RET'
export const UPDATE_BOOKING_LOADING = 'App/Dashboard/UPDATE_BOOKING_LOADING'

// Add Booking Session
export const ADD_BOOKING_SESSION = 'App/Dashboard/ADD_BOOKING_SESSION'
export const ADD_BOOKING_SESSION_RET = 'App/Dashboard/ADD_BOOKING_SESSION_RET'
export const ADD_BOOKING_SESSION_LOADING = 'App/Dashboard/ADD_BOOKING_SESSION_LOADING'

// Upload PATIENT Reports
export const UPLOAD_PATIENT_REPORT = 'App/Dashboard/UPLOAD_PATIENT_REPORT'
export const UPLOAD_PATIENT_REPORT_RET = 'App/Dashboard/UPLOAD_PATIENT_REPORT_RET'
export const UPLOAD_PATIENT_REPORT_LOADING = 'App/Dashboard/UPLOAD_PATIENT_REPORT_LOADING'

// Upload PATIENT Reports
export const UPLOAD_PATIENT_INSURANCE = 'App/Dashboard/UPLOAD_PATIENT_INSURANCE'
export const UPLOAD_PATIENT_INSURANCE_RET = 'App/Dashboard/UPLOAD_PATIENT_INSURANCE_RET'
export const UPLOAD_PATIENT_INSURANCE_LOADING = 'App/Dashboard/UPLOAD_PATIENT_INSURANCE_LOADING'

// GET Campaign Specialities & Services
export const GET_CAMPAIGN_SPECIALITY_AND_SERVICES = 'App/Dashboard/GET_CAMPAIGN_SPECIALITY_AND_SERVICES'
export const GET_CAMPAIGN_SPECIALITY_AND_SERVICES_RET = 'App/Dashboard/GET_CAMPAIGN_SPECIALITY_AND_SERVICES_RET'
export const GET_CAMPAIGN_SPECIALITY_AND_SERVICES_LOADING = 'App/Dashboard/GET_CAMPAIGN_SPECIALITY_AND_SERVICES_LOADING'

// Sync Knowlarity Leads
export const SYNC_KNOWLARITY_LEADS = 'App/Dashboard/SYNC_KNOWLARITY_LEADS'
export const SYNC_KNOWLARITY_LEADS_RET = 'App/Dashboard/SYNC_KNOWLARITY_LEADS_RET'
export const SYNC_KNOWLARITY_LEADS_LOADING = 'App/Dashboard/SYNC_KNOWLARITY_LEADS_LOADING'

// Download Leads
export const DOWNLOAD_SALES_LEADS = 'App/Dashboard/DOWNLOAD_SALES_LEADS'
export const DOWNLOAD_SALES_LEADS_RET = 'App/Dashboard/DOWNLOAD_SALES_LEADS_RET'
export const DOWNLOAD_SALES_LEADS_LOADING = 'App/Dashboard/DOWNLOAD_SALES_LEADS_LOADING_LOADING'

// GET TPA Leads
export const GET_TPA_USER_DETAILS = 'App/Dashboard/GET_TPA_USER_DETAILS'
export const GET_TPA_USER_DETAILS_RET = 'App/Dashboard/GET_TPA_USER_DETAILS_RET'
export const GET_TPA_USER_DETAILS_LOADING = 'App/Dashboard/GET_TPA_USER_DETAILS_LOADING_LOADING'

// Download TPA Leads
export const DOWNLOAD_TPA_USER_DETAILS = 'App/Dashboard/DOWNLOAD_TPA_USER_DETAILS'
export const DOWNLOAD_TPA_USER_DETAILS_RET = 'App/Dashboard/DOWNLOAD_TPA_USER_DETAILS_RET'
export const DOWNLOAD_TPA_USER_DETAILS_LOADING = 'App/Dashboard/DOWNLOAD_TPA_USER_DETAILS_LOADING_LOADING'