import {  GET_NOTIFICATIONS, GET_NOTIFICATIONS_LOADING, GET_NOTIFICATIONS_RET, SET_NOTFICATION_DATA  } from "../Constants"

const initialState = {

get_notification:false,
get_notifications_ret:false,
get_notifications_loading:false,

notification_data:{
            data:[],
            un_read_count:0
        }
}

export default function (state = initialState, action) {
switch (action.type) {

case GET_NOTIFICATIONS:
 return {
     ...state,
     get_notifications:action.data,
     get_notifications_loading:true
 }

case GET_NOTIFICATIONS_RET:
 return {
     ...state,
     get_notifications_ret:action.data,
     get_notifications_loading:true
 }
case GET_NOTIFICATIONS_LOADING:
 return {
     ...state,
     get_notifications_ret:false,
     get_notifications_loading:false
 }

 case SET_NOTFICATION_DATA:
 return {
     ...state,
    notification_data:{
        data:action.data.data,
        un_read_count:action.data.un_read_count
    }
 }


 default:
 return {...state}

 }
}