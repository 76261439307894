import {
	GET_MEMBERS,
	GET_MEMBERS_RET,
	GET_MEMBERS_LOADING,
	SET_MEMBERS_DATA,
	ADD_MEMBER,
	ADD_MEMBER_RET,
	ADD_MEMBER_LOADING,
} from '../Constants';

const initialState = {
	get_members: false,
	get_members_ret: false,
	get_members_loading: false,

	add_member: false,
	add_member_ret: false,
	add_member_loading: false,

	members_data: {
		members: [],
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_MEMBERS_DATA: {
			return {
				...state,
				members_data: { ...action.data },
				get_members_loading: false,
			};
		}
		case GET_MEMBERS:
			return {
				...state,
				get_members: action.data,
				get_members_loading: true,
			};

		case GET_MEMBERS_RET:
			return {
				...state,
				get_members_ret: action.data,
				get_members_loading: false,
			};

		case GET_MEMBERS_LOADING:
			return {
				...state,
				get_members_ret: false,
				get_members_loading: false,
			};

		case ADD_MEMBER:
			return {
				...state,
				add_member: action.data,
				add_member_loading: true,
			};

		case ADD_MEMBER_RET:
			return {
				...state,
				add_member_ret: action.data,
				get_member_loading: false,
			};

		case ADD_MEMBER_LOADING:
			return {
				...state,
				add_member_ret: false,
				add_member_loading: false,
			};

		default:
			return { ...state };
	}
}
