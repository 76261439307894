import React from "react"
import  { Table, Input, Button, Space, Card, Row, Col, Checkbox, Typography, Alert } from "antd"
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { get_url_params, for_loop, objectEquals } from "../../utils/common_utilities";
const { Title } = Typography;

const Catalogue = (props) => {
   const [specialities, set_specialities] = React.useState(false)
   const [state, set_state] = React.useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: '',
    searchedColumn: ''
  })
  const [prof_services_params, set_prof_services_params] = React.useState({
       page:1,
       limit:10,
       total:10,
       specialities:'',
       searchParams:{
         service:'',
       },
       filters:{
        variance:''
       }
  })

  const [data, set_data] = React.useState([])

    React.useEffect(()=>{
            props.get_prof_specialities({
                professional_id:get_url_params('professional_id')
            })
    }, [])

    const speciality_change = (arr) => {
        let str = ''
        arr.map((item,i)=>{
          if(i!==0){
            str = `${str},${item}`
          }else{
            str = `${str}${item}`
          }
          
        })
        props.get_prof_services({...prof_services_params, specialities:str})
        set_prof_services_params({...prof_services_params, specialities:str})
    }

    React.useEffect(()=>{
        if(props.get_prof_specialities_ret){
           if(props.get_prof_specialities_ret.success){
             console.log(props.get_prof_specialities_ret,"props.get_prof_specialities_ret")
                      set_specialities([...props.get_prof_specialities_ret.data.map(item=>{
                        return {
                           name:item.speciality,
                           value:item.specialityId
                        }
                      })])

                      if(props.get_prof_specialities_ret.data.length !==0){
                        props.get_prof_services({
                          page:1,
                          limit:10,
                          total:10,
                          specialities:'',
                          searchParams:{
                            service:'',
                          },
                          filters:{
                            variance:''
                          },
                          speciality_id:props.get_prof_specialities_ret.data[0].specialityId
                        })
                        set_prof_services_params({
                          page:1,
                          limit:10,
                          total:10,
                          specialities:'',
                          searchParams:{
                            service:'',
                          },
                          filters:{
                           variance:''
                          },
                          speciality_id:props.get_prof_specialities_ret.data[0].specialityId
                        })
                      }
           }else{
            props.global_error({
              success:false,
              message:props.get_prof_specialities_ret.message,
              heading:'Catalogue',
            })
           }
           props.get_prof_specialities_loading()
        }

        if(!!props.get_prof_services_ret){
           if(props.get_prof_services_ret.success){
            set_data(modify_data([...props.get_prof_services_ret.data]))
            console.log(props.get_prof_services_ret.data,"props.get_prof_services_ret.")
            set_prof_services_params({...prof_services_params, total:props.get_prof_services_ret.count})
           }else{
            props.global_error({
              success:false,
              message:props.get_prof_services_ret.message,
              heading:'Catalogue'
            })
           }
            props.get_prof_services_loading()
        }
}, [props.get_prof_specialities_ret, props.get_prof_services_ret])


const modify_data = (arr) => {
  try {
    let service_data = []
    for_loop(arr, (item) => {
        let obj = {
        service: item.service,
        serviceId: item.serviceId,
        speciality:item.speciality,
        specialityId: item.specialityId,
        price:item.price[0],
        variance: item.variance,
        }
        service_data.push({
      ...obj
        })
    })

return service_data;
  } catch (error) {
      console.log(error,"error in maodify data")
  }
  }


  const handleChange = (pagination, filters, sorter) => {
    // console.log(pagination, "pagination filter sorter")
     let state_filters = {...prof_services_params.filters}
     let updated_filters = {}
     updated_filters.variance = filters.variance
    if(!!((pagination.current === prof_services_params.page) && (pagination.pageSize === prof_services_params.limit))){
      if(objectEquals(state_filters, updated_filters)){
        console.log("Objects are equal")
      }else{
        // console.log(state_filters, updated_filters,"state_filters, updated_filters")
        console.log("Objects are not equal , call filter operation")
        set_prof_services_params({...prof_services_params, page:1, filters :{
         variance:filters.variance?filters.variance:''
        }})
        props.get_prof_services({...prof_services_params, page:1, filters :{
          variance:filters.variance?filters.variance:''
        }})
      }
    }
    };


    let { sortedInfo, filteredInfo } = state
    sortedInfo = sortedInfo || {}
    filteredInfo = filteredInfo || {}



   const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              state.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => state.searchInput.select(), 100);
        }
      },
      render: text =>
        state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            title={text}
          />
        ) : (
          text
        ),
    });
  
     const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
      confirm();
       set_prof_services_params({...prof_services_params, page:1,searchParams:{
         ...prof_services_params.searchParams,[searchProp]:selectedKeys[0]
       }})
       props.get_prof_services({...prof_services_params, page:1,searchParams:{
        ...prof_services_params.searchParams,[searchProp]:selectedKeys[0]
      }})
      set_state({
        ...state,
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
  
  const  handleReset = clearFilters => {
      clearFilters();
      set_state({ searchText: '' });
    };

 const change_limit = (a, b) => {
  //  console.log(a,b,"a and b in change_limit")
    set_prof_services_params({...prof_services_params, limit:b, page:1})
    props.get_prof_services({...prof_services_params, limit:b, page:1})
  }

  const page_change = (a, b) => {
    // if(prof_services_params.page !== a){
      if(true){
      set_prof_services_params({...prof_services_params, page:a, limit:b})
      props.get_prof_services({...prof_services_params, page:a, limit:b})
    }
  }

  const handle_reset = (a, b, c) => {
    handleReset(a)
    set_state({ filteredInfo: null });
    set_prof_services_params({...prof_services_params,page:1,  searchParams:{
     service:''
    },
    filters:{
      variance:''
    }})
    props.get_prof_services({...prof_services_params,page:1,  searchParams:{
    service:''
    },
    filters:{
      variance:''
    }})
  }
  const clear_filters = () => {
    // handleReset()
    set_state({ filteredInfo: null });
    // clearFilters()
    set_state({ searchText: '' });
    set_prof_services_params({...prof_services_params, page:1, filters:{
     variance:''
    }})
    props.get_prof_services({...prof_services_params, page:1, filters:{
      variance:''
    }})
  }


  const columns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
    
      ...getColumnSearchProps('service', "Search service", 'service'),
    },
    {
        title: 'Service Id',
        dataIndex: 'serviceId',
        key: 'serviceId',
        ...getColumnSearchProps('serviceId', "Search service Id", 'serviceId'),
        
      },
    {
      title: 'Speciality',
      dataIndex: 'speciality',
      key: 'speciality',
      
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
        title: 'Variance',
        dataIndex: 'variance',
        key: 'variance',
        filters: [
          {
            text: '0%-10%',
            value: '0-10',
          },
          {
            text: '10%-20%',
            value: '10-20',
          },
          {
            text: '20%-40%',
            value: '20-40',
          },
          {
            text: '40%-70%',
            value: '40-70',
          },
          {
            text: '70%-100%',
            value: '70-100',
          }
        ],
        filterMultiple: false,
        onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
      
      }
  ]
  function showTotal(total) {
    return `Total ${total} items`;
  }

console.log(specialities,"specialities")
    return (<React.Fragment>
         <Title level={5}>Specialities</Title>
           
        {!!(((!!specialities) && (specialities.length !==0)))? <Checkbox.Group style={{ width: '100%' }} onChange={(val)=>speciality_change(val)}>
    <div className="u-margin-top-small">
     <Row>
    {  specialities.map((item)=>{
        return  <Col span={8}>
        <Checkbox defaultChecked={true} value={item.value}>{item.name}</Checkbox>
      </Col>
      })}
    </Row>
    </div>
  </Checkbox.Group>:  <Alert
                message="No Speciality Available"
                description="It seems this professional has no speciality added to their catalogue"
                type="info"
                showIcon
            />}

{!!(((!!specialities) && (specialities.length !==0)))?
 <div className="u-margin-top-small">
<Title level={5}>Services</Title>
<Table
          columns={columns}
          dataSource={data}
          loading={props.get_prof_services_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:prof_services_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:prof_services_params.page,
            total:prof_services_params.total,
            showTotal:showTotal
          }}
          onRow={(r) => ({
            onClick: (b,a) => console.log(r),
          })}
          />
</div>:""}
  
    </React.Fragment>)
}
export default Catalogue