import React from "react"
import {  Form, Input, Select } from "antd"
import GooglePlacesAutocomplete,  { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
const { Option } = Select;


const SearchLocation = (props) => {
  const [error, set_error] = React.useState(false)
    React.useEffect(()=>{
        set_error(props.error)
    },[props.error])


 const onPlaceSelected = ( place ) => {
  try{
    geocodeByAddress(place.value.description)
  .then(results => getLatLng(results[0]))
  .then(({ lat, lng }) =>{
    if(!!error){
      props.toggle_error()
    }
    props.set_long_lat({
      location:true,
      lng:lng,
      lat:lat
    })
  }
 
  ).catch(e=>{
    if(!!error){
      props.toggle_error()
    }
     props.set_long_lat({
       location:"",
       lng:'',
       lat:''
     })
  })
  }catch (e){
    console.log(e,"catch in onPlaceSelected")
  }
}




    return <React.Fragment>
        <Form.Item
            label={props.label}
            validateStatus={error?"error":''}
            help={error?props.error_text:''}
        >
           <GooglePlacesAutocomplete
            selectProps={{
              onChange: onPlaceSelected,
              isDisabled:props.disabled,
              placeholder:"Search location"
            }}
						componentRestrictions={{country: "in"}}
            apiKey="AIzaSyAqn7q_9-GV3balsnk94-FgODIu_8lT3oM"
					/>
        </Form.Item>
    </React.Fragment>
}
export default SearchLocation