import {
        GET_PROFESIONALS , GET_PROFESIONALS_RET, GET_PROFESIONALS_LOADING, SET_PROFESSIONALS_LIST,
        GET_PROFESSIONAL_BYID, GET_PROFESSIONAL_BYID_RET, GET_PROFESSIONAL_BYID_LOADING, UPDATE_PROFESSIONAL_BYID_LOADING,
        UPDATE_PROFESSIONAL_BYID_RET, UPDATE_PROFESSIONAL_BYID, GET_PROF_SPECIALITIES, GET_PROF_SPECIALITIES_RET,
        GET_PROF_SPECIALITIES_LOADING,  GET_PROF_SERVICES, GET_PROF_SERVICES_LOADING, GET_PROF_SERVICES_RET, 
        UPDATE_CATALOGUE, UPDATE_CATALOGUE_LOADING, UPDATE_CATALOGUE_RET, GET_PROFESSIONAL_INFO_LOADING, GET_PROFESSIONAL_INFO_RET,
        GET_PROFESSIONAL_INFO,
        ADD_INSURANCE_TO_PROFESSIONAL, ADD_INSURANCE_TO_PROFESSIONAL_RET, ADD_INSURANCE_TO_PROFESSIONAL_LOADING,
	    REMOVE_INSURANCE_FROM_PROFESSIONAL, REMOVE_INSURANCE_FROM_PROFESSIONAL_RET, REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING, 
        GET_INSURANCE, GET_INSURANCE_RET, GET_INSURANCE_LOADING, 
        GET_MEDICAL_DOCTORS, GET_MEDICAL_DOCTORS_LOADING, GET_MEDICAL_DOCTORS_RET, 
        UPDATE_MEDICAL_DOCTOR, UPDATE_MEDICAL_DOCTOR_RET, UPDATE_MEDICAL_DOCTOR_LOADING, DELETE_HOSPITAL_PROFILE, DELETE_HOSPITAL_PROFILE_RET, DELETE_HOSPITAL_PROFILE_LOADING,
    } from "../Constants"

const initialState = {
    get_professionals:false,
    get_professionals_ret:false,
    get_professionals_loading:false,

    get_professional_byid:false,
    get_professional_byid_ret:false,
    get_professional_byid_loading:false,

    update_professional_byid:false,
    update_professional_byid_ret:false,
    update_professional_byid_loading:false,

    get_prof_specialities:false,
    get_prof_specialities_ret:false,
    get_prof_specialities_loading:false,

    get_prof_services:false,
    get_prof_services_ret:false,
    get_prof_services_loading:false,

    update_catalogue:false,
    update_catalogue_ret:false,
    update_catalogue_loading:false,

    get_professional_info:false,
    get_professional_info_ret:false,
    get_professional_info_loading:false,

    get_insurance:false,
    get_insurance_ret:false,
    get_insurance_loading:false,

	add_insurance_to_professional: false,
	add_insurance_to_professional_ret: false,
	add_insurance_to_professional_loading: false,

	remove_insurance_from_professional: false,
	remove_insurance_from_professional_ret: false,
	remove_insurance_from_professional_loading: false,

    get_medical_doctors:false,
    get_medical_doctors_ret:false,
    get_medical_doctors_loading:false,

    update_medical_doctor:false,
    update_medical_doctor_ret:false,
    update_medical_doctor_loading:false,

    delete_hospital_profile:false,
    delete_hospital_profile_ret:false,
    delete_hospital_profile_loading:false,
 
    professionals_data: {
     professionals_list:[]
    }
 }
 
 export default function (state = initialState, action) {
     switch (action.type) {
    case GET_PROFESSIONAL_INFO:
        return {
            ...state,
            get_professional_info:action.data,
            get_professional_info_loading:true
        }
    case GET_PROFESSIONAL_INFO_RET:
        return {
            ...state,
            get_professional_info_ret:action.data,
            get_professional_info_loading:true
        }
    case GET_PROFESSIONAL_INFO_LOADING:
        return {
            ...state,
            get_professional_info_loading:false
        }
        case UPDATE_CATALOGUE:
            return {
                ...state,
                update_catalogue:action.data,
                update_catalogue_loading:true
            }
        case UPDATE_CATALOGUE_RET:
            return {
                ...state,
                update_catalogue_ret:action.data,
                update_catalogue_loading:true
            }
        case UPDATE_CATALOGUE_LOADING:
            return {
                ...state,
                update_catalogue_ret:false,
                update_catalogue_loading:false
            }
        case GET_PROF_SERVICES:
            return {
                ...state,
                get_prof_services:action.data,
                get_prof_services_loading:true
            }
        case GET_PROF_SERVICES_RET:
            return {
                ...state,
                get_prof_services_ret:action.data,
                get_prof_services_loading:true
            }
        case GET_PROF_SERVICES_LOADING:
            return {
                ...state,
                get_prof_services_ret:false,
                get_prof_services_loading:false
            }
        case GET_PROF_SPECIALITIES:
            return {
                ...state,
                get_prof_specialities:action.data,
                get_prof_specialities_loading:true
            }
        case GET_PROF_SPECIALITIES_RET:
            return {
                ...state,
                get_prof_specialities_ret:action.data,
                get_prof_specialities_loading:true
            }
        case GET_PROF_SPECIALITIES_LOADING:
            return {
                ...state,
                get_prof_specialities_ret:false,
                get_prof_specialities_loading:false
            }
        case UPDATE_PROFESSIONAL_BYID:
            return {
              ...state,
              update_professional_byid:action.data,
              update_professional_byid_loading:true
            }
        case UPDATE_PROFESSIONAL_BYID_RET:
            return {
                ...state,
                update_professional_byid_ret:action.data,
                update_professional_byid_loading:true
            }
        case UPDATE_PROFESSIONAL_BYID_LOADING:
            return {
                ...state,
                update_professional_byid_ret:false,
                update_professional_byid_loading:false
            }
        case GET_PROFESSIONAL_BYID:
            return {
              ...state,
              get_professional_byid:action.data,
              get_professional_byid_loading:true
            }
        case GET_PROFESSIONAL_BYID_RET:
            return {
                ...state,
                get_professional_byid_ret:action.data,
                get_professional_byid_loading:true
            }
        case GET_PROFESSIONAL_BYID_LOADING:
            return {
                ...state,
                get_professional_byid_ret:false,
                get_professional_byid_loading:false
            }
         case SET_PROFESSIONALS_LIST:{
             return {
                 ...state,
                 professionals_data:{...state.professionals_data, professionals_list:[...action.data]},
                 get_professionals_loading:false
             }
         }
         case GET_PROFESIONALS:
              return {
                ...state,
                get_professionals:action.data,
                get_professionals_loading:true
              }
 
         case GET_PROFESIONALS_RET:
           console.log(action.data,"action.data in get_professionanl_ret")
             return {
             ...state,
             get_professionals_ret:{
                 success:action.data.success,
                 message:action.data.message,
                 data:action.data.data,
                 list:action.data.list,
                 count:action.data.count
             },
             get_professionals_loading:true
             }
 
         case GET_PROFESIONALS_LOADING:
         return {
         ...state,
         get_professionals_ret:false,
         get_professionals_loading:false
         }
		case GET_INSURANCE: {
			return {
				...state,
				get_insurance: { ...action.data },
				get_insurance_loading: true,
			};
		}
		case GET_INSURANCE_RET:
			return {
				...state,
				get_insurance_ret: action.data,
				get_insurance_loading: true,
			};
		case GET_INSURANCE_LOADING:
			return {
				...state,
				get_insurance_loading: false,
			};

		case ADD_INSURANCE_TO_PROFESSIONAL: {
			return {
				...state,
				add_insurance_to_professional: { ...action.data },
				add_insurance_to_professional_loading: true,
			};
		}
		case ADD_INSURANCE_TO_PROFESSIONAL_RET:
			return {
				...state,
				add_insurance_to_professional_ret: action.data,
				add_insurance_to_professional_loading: true,
			};
		case ADD_INSURANCE_TO_PROFESSIONAL_LOADING:
			return {
				...state,
				add_insurance_to_professional_ret: false,
				add_insurance_to_professional_loading: false,
			};

		case REMOVE_INSURANCE_FROM_PROFESSIONAL: {
			return {
				...state,
				remove_insurance_from_professional: { ...action.data },
				remove_insurance_from_professional_loading: true,
			};
		}
		case REMOVE_INSURANCE_FROM_PROFESSIONAL_RET:
			return {
				...state,
				remove_insurance_from_professional_ret: action.data,
				remove_insurance_from_professional_loading: true,
			};
		case REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING:
			return {
				...state,
				remove_insurance_from_professional_ret: false,
				remove_insurance_from_professional_loading: false,
			};

        case GET_MEDICAL_DOCTORS:
            return {
                ...state,
                get_medical_doctors:action.data,
                get_medical_doctors_loading:true
            }
   
        case GET_MEDICAL_DOCTORS_RET:
            return {
                ...state,
                get_medical_doctors_ret:{
                    success:action.data.success,
                    message:action.data.message,
                    data:action.data.data,
                    list:action.data.list,
                    count:action.data.count
                },
                get_medical_doctors_loading:true
            }
   
        case GET_MEDICAL_DOCTORS_LOADING:
            return {
                ...state,
                    get_medical_doctors_ret:false,
                    get_medical_doctors_loading:false
            }

        case UPDATE_MEDICAL_DOCTOR: {
            return {
                ...state,
                update_medical_doctor: { ...action.data },
                update_medical_doctor_loading: true,
            };
        }
        case UPDATE_MEDICAL_DOCTOR_RET:
            return {
                ...state,
                update_medical_doctor_ret: action.data,
                update_medical_doctor_loading: true,
            };
        case UPDATE_MEDICAL_DOCTOR_LOADING:
            return {
                ...state,
                update_medical_doctor_ret: false,
                update_medical_doctor_loading: false,
            };

        case DELETE_HOSPITAL_PROFILE: {
            return {
                ...state,
                delete_hospital_profile: { ...action.data },
                delete_hospital_profile_loading: true,
            };
        }
        case DELETE_HOSPITAL_PROFILE_RET:
            return {
                ...state,
                delete_hospital_profile_ret: action.data,
                delete_hospital_profile_loading: true,
            };
        case DELETE_HOSPITAL_PROFILE_LOADING:
            return {
                ...state,
                delete_hospital_profile_ret: false,
                delete_hospital_profile_loading: false,
            };
         
        default:
            return {...state}
     
    }
 }