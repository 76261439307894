import axios from "axios";
import { get_url_params } from "../utils/common_utilities";
// import { connect } from 'react-redux';

let base_url = "https://devapi.plunes.com/v10";
let base_url_without_v5 = "https://devapi.plunes.com";

const pathLocation = window.location.host;
if (!!pathLocation) {
  console.log("pathLocation : ", pathLocation);
  if (
    pathLocation === "admin.plunes.com" ||
    pathLocation === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    // if(true){
    // Production baseUrl
    base_url = "https://api.plunes.com/v10";
  } else {
    base_url = "https://devapi.plunes.com/v10";
  }
}

let admin_url = "https://devapi.plunes.com/admin";
let prod_admin_url = "https://api.plunes.com/admin";

const admin_location = window.location.host;
if (!!admin_location) {
  console.log("pathLocation : ", admin_location);
  // if(admin_location === "s3.console.aws.amazon.com") {
  if (
    admin_location === "admin.plunes.com" ||
    admin_location === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    console.log("Prod");
    // Production baseUrl
    admin_url = "https://api.plunes.com/admin";
  } else {
    admin_url = "https://devapi.plunes.com/admin";
  }
}

let new_url = "https://devapi.plunes.com/mcm";
// let prod_new_url = "https://api.plunes.com/mcm"

const location = window.location.host;
if (!!location) {
  console.log("pathLocation : ", admin_location);
  // if(admin_location === 's3.console.aws.amazon.com') {
  if (
    location === "admin.plunes.com" ||
    location === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    console.log("PROD");
    // Production baseUrl
    new_url = "https://api.plunes.com/mcm";
  } else {
    new_url = "https://devapi.plunes.com/mcm";
  }
}

let new_url_2 = "https://devapi.plunes.com";
// let prod_new_url = "https://api.plunes.com/mcm"

const new_location = window.location.host;
if (!!new_location) {
  console.log("pathLocation : ", new_location);
  // if(admin_location === 's3.console.aws.amazon.com') {
  if (
    new_location === "admin.plunes.com" ||
    new_location === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    console.log("PROD");
    // Production baseUrl
    new_url_2 = "https://api.plunes.com";
  } else {
    new_url_2 = "https://devapi.plunes.com";
  }
}

export default {
  customSolutions: {
    uploadUserReport: (data, headers) => {
      console.log(data, "UPLOAD_USER_REPORT DATA")
       return axios
        .post(`${base_url}/user/userReport?reportType=${data.data.type}&uploadFrom=ADMIN_PANEL&userId=${data.data.userId}`, data.formData, headers)
        .then((res) => {
            console.log(res, "res in UPLOAD_USER_REPORT RES");
            return res.data;
        })
        .catch((err) => {
            console.log(err, "res in UPLOAD_USER_REPORT ERR");
            return err;
        })
    },
    uploadInsuranceReport: (data, headers) => {
      console.log(data, "UPLOAD_INSURANCE_REPORT DATA")
       return axios
        .post(`${base_url}/user/uploadInsuranceAdminPanel?insuranceType=${data.data.type}&uploadFrom=ADMIN_PANEL&userId=${data.data.userId}`, data.formData, headers)
        .then((res) => {
            console.log(res, "res in UPLOAD_INSURANCE_REPORT RES");
            return res.data;
        })
        .catch((err) => {
            console.log(err, "res in UPLOAD_INSURANCE_REPORT ERR");
            return err;
        })
    },
    submitMedicalDetails: (data, headers) => {
      console.log(data, "res in SUBMIT_MEDICAL_DETAILS DATA");
       return axios
        .post(`${base_url}/user/submitReportAdminPanel?userId=${data.userId}`, data, headers)
        .then((res) => {
            console.log(res, "res in SUBMIT_MEDICAL_DETAILS RES");
            return res.data;
        })
        .catch((err) => {
          console.log(err, "res in SUBMIT_MEDICAL_DETAILS ERR");
          return err;
        })
    },
    editMedicalDetails: (data, headers) => {
      console.log(data, "res in EDIT_MEDICAL_DETAILS DATA");
       return axios
        .post(`${base_url}/user/editReport?userId=${data.userId}&reportId=${data.reportId}`, data, headers)
        .then((res) => {
            console.log(res, "res in EDIT_MEDICAL_DETAILS RES");
            return res.data;
        })
        .catch((err) => {
          console.log(err, "res in EDIT_MEDICAL_DETAILS ERR");
          return err;
        })
    },
    createCustomSolution: (data, headers) => {
       return axios
        .post(`${base_url}/solution/customProfessionalSolution${data.userReportId ? `?userReportId=${data.userReportId}`: ''}`, data, headers)
        .then((res) => {
            console.log(res, "res in createCustomSolution_api");
            return res.data;
        })
        .catch((err) => {
          return err;
        })
    },
  }
}