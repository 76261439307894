import React from "react"
import { global_error, global_error_clr, global_loading_set, set_mount } from "../actions/global"
import { get_members, get_members_loading, set_members_data, add_member, add_member_loading } from "../actions/members"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import MembersView from "../Views/MembersView"
import AddMember from "../Views/AddMember"

class MemberContainer extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:false
            }
        }

    render(){
console.log(this.props,"props in MembersContainer")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?add-member") ?  <AddMember
                   add_member = {this.props.add_member}
                   add_member_ret = {this.props.members_store.add_member_ret}
                   add_member_loading_flag = {this.props.members_store.add_member_loading}
                   add_member_loading = {this.props.add_member_loading}
                   members_store = {this.props.members_store}
                   set_members_data = {this.props.set_members_data}

                    global_error = {this.props.global_error}
                    global_loading_set = {this.props.global_loading_set}
                    mount = {this.props.mount}
                    set_mount = {this.props.set_mount}
                    get_members = {this.props.get_members}
                  /> :<MembersView
                   get_members = {this.props.get_members}
                   get_members_ret = {this.props.members_store.get_members_ret}
                   get_members_loading_flag = {this.props.members_store.get_members_loading_flag}
                   get_members_loading = {this.props.get_members_loading}
                   members_store = {this.props.members_store}
                   set_members_data = {this.props.set_members_data}

                    global_error = {this.props.global_error}
                    global_loading_set = {this.props.global_loading_set}
                    mount = {this.props.mount}
                    set_mount = {this.props.set_mount}
                  /> }
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  members_store:state.members_store,
  mount:state.global_store.mount,
  global_error_ret:state.global_store.global_error_ret
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  set_mount,
  get_members,
  get_members_loading,
  set_members_data,
  add_member,
  add_member_loading
 })(MemberContainer)
