import React from "react"
import { Table, Button, Card, Select, notification } from 'antd';
import { Link } from "react-router-dom"
import AntdInput from "../Components/AntdInput";
import axios from "axios";
import { get_url } from "../utils/common_utilities";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";


const MembersView = (props) => {
    const [data, set_data] = React.useState([
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '4',
          name: 'Jim Red',
          age: 32,
          address: 'London No. 2 Lake Park',
        },
      ])
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
      })
      const [members, set_members] = React.useState([])

    const [mcmUsers, setMcmUsers] = React.useState([{ id: '', name: '' }]);

    React.useEffect(() => {
      const headers = {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      };
      
      axios
        .get(`${get_url()}/mcmUser/getAllmcmUsers`, { headers })
        .then(res => {
          if (res.data.data) {
            const filteredUsers = res.data.data.map(user => ({
              id: user._id,
              name: user.name
            }));
            setMcmUsers(filteredUsers);
          } else {
            notification.error({
              message: 'Error',
              description: 'Something went wrong'
            });
          }
        })
        .catch(err => {
          notification.error({
            message: 'Error',
            description: err.response?.data?.msg || 'Please try again'
          });
        });
    }, []);  

    React.useEffect(()=>{
         if(!props.mount.members_list_mount){
             props.get_members()
             props.set_mount({
                 ...props.mount, members_list_mount:true
             })
         }else {
           set_members([...props.members_store.members_data.members])
         }
    },[])

    React.useEffect(()=>{
        if(props.get_members_ret){
        
            if(props.get_members_ret.success){
               console.clear()
               set_members([...props.get_members_ret.data])
               props.set_members_data({...props.members_store.members_data, members:props.get_members_ret.data})
            }else {
                props.global_error({
                 success:false,
                 message:props.get_members_ret.message,
                 heading:'Login'
               })
            }
            props.get_members_loading()
        }
   },[props.get_members_ret])

  const handleRoleUpdate = (userId, role) => {
    const data = {
      userId: userId,
      role: role
    }
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    axios.post(`${get_url()}/mcmUser/updateRole`, data, { headers })
      .then(res => {
        if (res.data?.success) {
          notification.success({
            message: 'Success',
            description: 'Role updated successfully'
          })
          props.get_members()
        } else {
          console.error(res)
          notification.error({
            message: 'Error',
            description: 'Role not updated'
          })
        }
      })
      .catch(err => {
        console.error(err)
        notification.error({
          message: 'Error',
          description: 'Please try again later'
        })
      })
  }

  const handleProfileDelete = (userId, role) => {
    const data = {
      userId: userId,
      role: role
    }
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    axios.post(`${get_url()}/mcmUser/deleteMcmUser`, data, { headers })
      .then(res => {
        if (res.data?.success) {
          notification.success({
            message: 'Success',
            description: 'User deleted successfully'
          })
          props.get_members()
        } else {
          console.error(res)
          notification.error({
            message: 'Error',
            description: 'User not deleted'
          })
        }
      })
      .catch(err => {
        console.error(err)
        notification.error({
          message: 'Error',
          description: 'Please try again later'
        })
      })
  }

  const handleSubRoleUpdate = (userId, subRole) => {
    const data = {
      userId: userId,
      subRole: subRole
    }
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    axios.post(`${get_url()}/mcmUser/updateSubRole`, data, { headers })
      .then(res => {
        if (res.data?.success) {
          notification.success({
            message: 'Success',
            description: 'Sub Role updated successfully'
          })
          props.get_members()
        } else {
          console.error(res)
          notification.error({
            message: 'Error',
            description: 'Sub Role not updated'
          })
        }
      })
      .catch(err => {
        console.error(err)
        notification.error({
          message: 'Error',
          description: 'Please try again later'
        })
      })
  }
  const handleReportingManagerUpdate = (userId, reportingManagerID, reportingManagerName) => {
    const data = {
      userId: userId,
      reportingManager: {
        reportingManagerID: reportingManagerID,
        reportingManagerName: reportingManagerName
      }
    }
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    axios.post(`${get_url()}/mcmUser/updateReportingManager`, data, { headers })
      .then(res => {
        if (res.data?.success) {
          notification.success({
            message: 'Success',
            description: 'Reporting Manager updated successfully'
          })
          props.get_members()
        } else {
          console.error(res)
          notification.error({
            message: 'Error',
            description: 'Reporting Manager not updated'
          })
        }
      })
      .catch(err => {
        console.error(err)
        notification.error({
          message: 'Error',
          description: 'Please try again later'
        })
      })
  }
  const handleChange = (pagination, filters, sorter) => {
  
    set_state({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
    
      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          // filters: [
          //   { text: 'Joe', value: 'Joe' },
          //   { text: 'Jim', value: 'Jim' },
          // ],
          // filteredValue: filteredInfo.name || null,
          // onFilter: (value, record) => record.name.includes(value),
          sorter: (a, b) => {return a.name.localeCompare(b.name)},
          sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: 'Role',
          dataIndex: 'role',
          key: 'role',
          sorter: (a, b) => {return a.role.localeCompare(b.role)},
          sortOrder: sortedInfo.columnKey === 'role' && sortedInfo.order,
          ellipsis: true,
          render: (role, record) => (
            <Select
              value={role}
              options={[
                {
                  label: 'Admin',
                  value: 'ADMIN'
                },
                {
                  label: 'Key Manager',
                  value: 'KEY_MANAGER'
                },
                {
                  label: 'Sales Manager',
                  value: 'SALES_MANAGER'
                },
                {
                  label: 'Operations',
                  value: 'OPERATIONS'
                },
                {
                  label: 'Developer',
                  value: 'DEVELOPER'
                },
                {
                  label: 'Sales User',
                  value: 'SALES_USER'
                },
                {
                  label: 'Content Team',
                  value: 'CONTENT_TEAM'
                },
                {
                  label: 'Marketing Executive',
                  value: 'MARKETING_EXECUTIVE'
                }
              ]}
              name="role"
              type="select"
              onChange={(e) => handleRoleUpdate(record._id, e)}
              placeholder="Choose Role"
              style={{ width: '100%' }}
            />

          )
        },
        {
          title: 'Sub Role',
          dataIndex: 'subRole',
          key: 'subRole',
          render: (subRole, record) => (
            <Select
              value={subRole}
              options={[
                {
                  label: 'Verification Team',
                  value: 'VERIFICATION_TEAM'
                },
                {
                  label: 'Hospital Team',
                  value: 'HOSPITAL_TEAM'
                },
                {
                  label: 'Claims Team',
                  value: 'CLAIMS_TEAM'
                },
                {
                  label: 'Medical Team',
                  value: 'MEDICAL_TEAM'
                },
                {
                  label: 'Developer',
                  value: 'DEVELOPER'
                },
                {
                  label: 'Accounts Team',
                  value: 'ACCOUNTS_TEAM'
                },
                {
                  label: 'Admin',
                  value: 'ADMIN'
                }
              ]}
              name="subRole"
              type="select"
              onChange={(e) => handleSubRoleUpdate(record._id, e)}
              placeholder="Choose Sub Role"
              style={{ width: '100%' }}
            />

          )
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        //   filters: [
        //     { text: 'London', value: 'London' },
        //     { text: 'New York', value: 'New York' },
        //   ],
        //   filteredValue: filteredInfo.address || null,
        //   onFilter: (value, record) => record.address.includes(value),
          sorter: (a, b) => {return a.email.localeCompare(b.email)},
          sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
          ellipsis: true,
        },
        {
            title: 'Phone number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
          //   filters: [
          //     { text: 'London', value: 'London' },
          //     { text: 'New York', value: 'New York' },
          //   ],
          //   filteredValue: filteredInfo.address || null,
          //   onFilter: (value, record) => record.address.includes(value),
            sorter: (a, b) => {return a.mobileNumber.localeCompare(b.mobileNumber)},
            sortOrder: sortedInfo.columnKey === 'mobileNumber' && sortedInfo.order,
            ellipsis: true,
          },
          {
            title: 'Reporting Manager',
            dataIndex: 'reportingManager',
            key: 'reportingManager',
            render: (reportingManager, record) => (
              <Select
                value={reportingManager?.reportingManagerName}
                options={
                  mcmUsers.map((user) => ({
                    label: user.name,
                    value: user.id,
                  }))
                }
                name="subRole"
                type="select"
                onChange={(value, option) => handleReportingManagerUpdate(record._id, value, option.label)}
                placeholder="Choose Reporting Manager"
                style={{ width: '100%' }}
              />
            )
          },
          {
            title: 'Action',
            dataIndex: 'deletion',
            key: 'deletion',
            render: (text, record) => (
              <Button type="primary" danger onClick={(e) => handleProfileDelete(record._id)}><DeleteOutlined />Delete</Button>
            )
          },

      ];

    
        return (<React.Fragment>
            <div className="u-align-right u-margin-bottom-medium">
              <Link to="/dashboard/members?add-member">
              <Button type="primary">Add member</Button>
              </Link>
              </div>
            <Card title="Members">
                  {/* <Space style={{ marginBottom: 16 }}>
          <Button onClick={setAgeSort}>Sort age</Button>
          <Button onClick={clearFilters}>Clear filters</Button>
          <Button onClick={clearAll}>Clear filters and sorters</Button>
        </Space> */}
        <Table columns={columns} dataSource={members} loading={props.get_members_loading_flag} onChange={handleChange} />
        </Card>
        </React.Fragment>)
}
export default MembersView