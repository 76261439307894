import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { GET_REAL_TIME_INSIGHTS , GET_ACTIONABLE_INSIGHTS, GET_SUGGESTED_INSIGHTS
      } from '../Constants'
import { get_real_time_insights_ret, get_actionable_insights_ret , get_suggested_insights_ret} from '../actions/insights'
import api from '../utils/api_routes'
import store from '../store'

function* get_suggested_insights_saga() {
   console.log("Inside get_members_saga")

  try {
     const  data = yield store.getState().insights_store.get_suggested_insights
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      const api_data = yield call(api.insights.get_suggested_insights, data, headers)
     console.log(api_data,"api_data in get_members_saga")
     if(!!api_data){
        if(!!api_data.success){
          
           yield put(get_suggested_insights_ret({
               success:true,
               message:api_data.message ||'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(get_suggested_insights_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_suggested_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_suggested_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_real_time_insights_saga() {
    console.log("Inside get_members_saga")

   try {
      const  data = yield store.getState().insights_store.get_real_time_insights
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
       const api_data = yield call(api.insights.get_real_time_insights, data, headers)
      console.log(api_data,"api_data in get_members_saga")
      if(!!api_data){
         if(!!api_data.success){
           
            yield put(get_real_time_insights_ret({
                success:true,
                message:api_data.message ||'Something went wrong try again later..',
                data:api_data.data,
                count:api_data.count
               }))
        }else{
            yield put(get_real_time_insights_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_real_time_insights_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_real_time_insights_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
}


function* get_actionable_insights_saga() {
   console.log("Inside get_members_saga")

  try {
     const  data = yield store.getState().insights_store.get_actionable_insights
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      const api_data = yield call(api.insights.get_actionable_insights, data, headers)
     console.log(api_data,"api_data in get_members_saga")
     if(!!api_data){
        if(!!api_data.success){
          
           yield put(get_actionable_insights_ret({
               success:true,
               message:api_data.message ||'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(get_actionable_insights_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_actionable_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_actionable_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


export const insights_saga = [
   takeLatest(GET_REAL_TIME_INSIGHTS, get_real_time_insights_saga),
   takeLatest(GET_ACTIONABLE_INSIGHTS, get_actionable_insights_saga),
   takeLatest(GET_SUGGESTED_INSIGHTS, get_suggested_insights_saga)
]

export default insights_saga

