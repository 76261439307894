import { act } from '@testing-library/react';
import { actionChannel } from 'redux-saga/effects';
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_LOADING, GET_NOTIFICATIONS_RET, SET_NOTFICATION_DATA, CLEAR_COUNT } from '../Constants';


export const get_notifications = (data) => {
    return {
        type: GET_NOTIFICATIONS,
        data
    }
}

export const get_notifications_ret = (data) => {
    return {
        type: GET_NOTIFICATIONS_RET,
        data
    }
}

export const get_notifications_loading = (data) => {
    return {
        type: GET_NOTIFICATIONS_LOADING,
        data
    }
}


export const set_notification_data = (data) => {
    return {
        type: SET_NOTFICATION_DATA,
        data
    }
}

export const clear_count = (data) => {
    return {
        type: CLEAR_COUNT,
        data
    }
}