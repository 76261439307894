import { GET_BIDS, GET_BIDS_RET, GET_BIDS_LOADING } from "../Constants"

export const get_bids = (data) => {
    return {
        type:GET_BIDS,
        data
    }
}
export const get_bids_ret = (data) => {
    return {
        type:GET_BIDS_RET,
        data
    }
}
export const get_bids_loading = (data) => {
    return {
        type:GET_BIDS_LOADING,
        data
    }
}