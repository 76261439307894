import React, { useEffect, useState } from "react"
import { Button, Card, Tabs, Form, Row, Col, Descriptions, Typography, Alert, PageHeader } from 'antd';
import {  EditOutlined, AppleOutlined, AndroidOutlined  } from '@ant-design/icons';
import { error_form_check, get_url } from '../../utils/common_utilities'
import AntdInput from "../../Components/AntdInput"
import EditFaqs from "./EditFaqs"
import UploadImage from "../../Components/UploadImage"
import axios from "axios"
import EditAdvancedTreatments from "./EditAdvancedTreatments";

const { Title } = Typography;

const { TabPane } = Tabs;

const CampaignServiceHandler = (props) => {

    const [add_service, set_add_service] = React.useState({
        speciality: "",
        pageInfo: ""
    })

    const [headingStrings, setHeadingString] = useState({
        top: "",
        second: "",
        green: "",
        end: ""
    })

    const [specialityName, setSpecialityName] = useState(null)
    
    const [addService, setAddService] = React.useState(false)

    const [specialities, set_specialities] = React.useState([])
    const [pageInfo, setPageInfo] = React.useState([])

    const [error_add_service, set_error_add_service] = React.useState({
        speciality: { state: false, text: "" },
        pageInfo: { state: false, text: "" },
    })

    const [add_service_flag , set_add_service_flag] = React.useState(false)


    const [note, set_note] = React.useState(["- Add the word between span tag i.e. <span style=\"color: rgb(37, 178, 129);\">Example</span>.","- For Add new Line add \"<br/ >\" to start the new line with in the paragraph.","-Press Enter to give  bullet to new Line."])


    const [state, set_state] = React.useState({
        check_status: "PENDING"
    })
    const [active_tab, set_active_tab] = React.useState(1)
    const [edit_flag, set_edit_flag] = React.useState(false)
    const [fetch_error, set_fetch_error] = React.useState(false)
    const [service_family_list, set_service_family_list] = React.useState({
        familyList: '',
    })
    const [initial_data, set_initial_data] = React.useState({
        id: '',
        speciality: '',
        specialityId: '',
        procedureFullName: '',
        page: '',
        heading: '',
        formHeading: '',
        definition: '',
        duration: '',
        discharge:'',
        treatments: '',
        treatmentInfo:'',
        riskInfo:'',
        advantageInfo:'',
        causeInfo:'',
        onLinerInfo:'',
        symptomInfo:'',

        precaution_data:'',
        reason_data:'',
        reason_heading:'',
        symptom_data:'',
        symptom_heading:'',
        types_data:'',
        types_heading:'',


        treatment_heading:'',
        risk_heading:'',
        advantage_heading:'',
        cause_heading:'',
        condition_data:'',
        condition_heading:'',

        aboutService_description:'',
        aboutService_heading:'',
        aboutService_subHeading:'',

        bigChainPricePlunes: '',
        bigChainPriceWithoutPlunes: '',

        multiSpecialityPricePlunes: '',
        multiSpecialityPriceWithoutPlunes: '',

        regionalBrandsPricePlunes: '',
        regionalBrandsPriceWithoutPlunes: '',

        need_data:'',
        need_heading:'',

        benefits_data:'',
        benefits_heading:'',

        metaTitle:'',
        metaDesc:'',

        facts_data:'',
        facts_heading:'',

        dos_data:'',
        dos_heading:'',

        donts_data:'',
        donts_heading:'',

        advancedTreatments: [],

        altTags: ''

    })
    const [data, set_data] = React.useState({
        serviceFamily: '',
        _id: '',
        specialityId: '',
        speciality: '',
        familyList: '',
        heading: '',
        formHeading: '',
        definition: '',
        duration: '',
        discharge:'',
        treatments: '',
        treatmentInfo:'',
        riskInfo:'',
        advantageInfo:'',
        causeInfo:'',
        onLinerInfo:'',

        symptomInfo:'',
        precaution_data:'',
        reason_data:'',
        reason_heading:'',
        symptom_data:'',
        symptom_heading:'',
        types_data:'',
        types_heading:'',

        treatment_heading:'',
        risk_heading:'',
        advantage_heading:'',
        cause_heading:'',
        condition_data:'',
        condition_heading:'',

        aboutService_description:'',
        aboutService_heading:'',
        aboutService_subHeading:'',

        bigChainPricePlunes: '',
        bigChainPriceWithoutPlunes: '',

        multiSpecialityPricePlunes: '',
        multiSpecialityPriceWithoutPlunes: '',

        regionalBrandsPricePlunes: '',
        regionalBrandsPriceWithoutPlunes: '',

        need_data:'',
        need_heading:'',

        benefits_data:'',
        benefits_heading:'',

        metaTitle:'',
        metaDesc:'',

        facts_data:'',
        facts_heading:'',

        dos_data:'',
        dos_heading:'',

        donts_data:'',
        donts_heading:'',

        advancedTreatments: [],

        altTags: ''

    })
    const [error, set_error] = React.useState({
        serviceFamily: { state: false, text: "" },
        speciality: { state: false, text: "" },
        heading: { state: false, text: "" },
        formHeading: { state: false, text: "" },
        definition: { state: false, text: "" },
        duration: { state: false, text: "" },
        discharge: { state: false, text: "" },
        treatments: { state: false, text: "" },
        treatmentInfo: { state: false, text: "" },
        riskInfo: { state: false, text: "" },
        advantageInfo: { state: false, text: "" },
        causeInfo: { state: false, text: "" },
        onLinerInfo: { state: false, text: "" },
        symptomInfo: { state: false, text: "" },

        precaution_data:{ state: false, text: "" },
        reason_data:{ state: false, text: "" },
        reason_heading:{ state: false, text: "" },
        symptom_data:{ state: false, text: "" },
        symptom_heading:{ state: false, text: "" },
        types_data:{ state: false, text: "" },
        types_heading:{ state: false, text: "" },

        treatment_heading:{ state: false, text: "" },
        risk_heading:{ state: false, text: "" },
        advantage_heading:{ state: false, text: "" },
        cause_heading:{ state: false, text: "" },

        condition_data:{ state: false, text: "" },
        condition_heading:{ state: false, text: "" },
        aboutService_description:{ state: false, text: "" },
        aboutService_heading:{ state: false, text: "" },
        aboutService_subHeading:{ state: false, text: "" },

        advancedTreatments:{ state: false, text: "" },

        altTags:{ state: false, text: "" }
    })

    const [form] = Form.useForm()

  const initialize_data = () => {
      set_data({
        serviceFamily: '',
        page: '',
        procedureFullName: '',
        _id: '',
        specialityId: '',
        speciality: '',
        familyList: '',
        heading: '',
        formHeading: '',
        definition: '',
        duration: '',
        discharge:'',
        treatments: '',
        treatmentInfo:'',
        riskInfo:'',
        advantageInfo:'',
        causeInfo:'',
        onLinerInfo:'',
        symptomInfo:'',
        precaution_data:'',
        reason_data:'',
        reason_heading:'',
        symptom_data:'',
        symptom_heading:'',
        types_data:'',
        types_heading:'',
        treatment_heading:'',
        risk_heading:'',
        advantage_heading:'',
        cause_heading:'',
        condition_data:'',
        condition_heading:'',
        aboutService_description:'',
        aboutService_heading:'',
        aboutService_subHeading:'',
        bigChainPricePlunes: '',
        bigChainPriceWithoutPlunes: '',
        multiSpecialityPricePlunes: '',
        multiSpecialityPriceWithoutPlunes: '',
        regionalBrandsPricePlunes: '',
        regionalBrandsPriceWithoutPlunes: '',
        need_data:'',
        need_heading:'',
        benefits_data:'',
        benefits_heading:'',
        metaTitle:'',
        metaDesc:'',
        facts_data:'',
        facts_heading:'',
        dos_data:'',
        dos_heading:'',
        donts_data:'',
        donts_heading:'',
        advancedTreatments: [],
        altTags: ''
    })
  }

    const add_service_button = () => {
        set_add_service_flag(true)
        set_edit_flag(true)
    }

    React.useEffect(() => {
        const headers = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }

        try {
            axios
            .get(`${get_url()}/campaign/getCampaignProcedures`, headers)
            .then((res) => {
                console.log(res, 'res in add_service_button');
                if(res.data.data) {
                    let data = res.data.data.map(item=>{
                        return {
                            name:item.procedureFullName,
                            value:item._id,
                            content: item
                        }
                    })
                    set_specialities([...data])
                }
            })
        } catch (error) {
            
        }
    }, [props.updateMarketingPage_ret])

    // useEffect(() => {
    //     if(initial_data && initial_data._id && initial_data._id.length !== 0 && props.specialities_options) {
    //         props.specialities_options.map((speciality) => {
    //             if(speciality.value === initial_data.specialityId)
    //                 setSpecialityName(speciality.name)
    //         })
    //     }
    // }, [initial_data, props.specialities_options])

    const edit_cllck = () => {
        set_edit_flag(!edit_flag)
        set_data({
            ...initial_data
        })
    }

    const procedureChange = (val) => {
        let pagesData
        let speciality
        specialities.map(item=> {
            if(item.value === val) {
                pagesData = item.content.pageInfo
                speciality = item.content.procedureFullName
            }
        })
        let content = []
        pagesData.map((page) => {
            content.push({
                name: page.page,
                value: page.page,
                id: val,
                content: page
            })
        })  
        set_data({
            speciality: speciality,
        })      
        setPageInfo([...content])
        set_initial_data(null)
    }

    const pageInfoChange = (val) => {
        const page = pageInfo.filter((pg) => pg.name === val)[0]
        const pageContent = pageInfo.filter((pg) => pg.name === val)[0].content
        const speciality = specialities.filter((specials) => specials.value === page.id)[0].content
        set_add_service({...add_service, pageInfo: pageContent.page})
        
        set_initial_data({
            id: speciality._id,
            specialityId: speciality.specialityId,
            procedureFullName: speciality.procedureFullName,
            page: pageContent.page,
            heading: pageContent.heading,
            formHeading: pageContent.formHeading,
            altTags: pageContent.altTags,
            definition: speciality.definition,
            duration: speciality.duration,
            discharge:speciality.discharge,
            treatments: speciality.treatments,
            treatmentInfo: speciality.treatments ? speciality.treatments.treatmentData : null,
            riskInfo: speciality.risks ? speciality.risks.riskData : null,
            advantageInfo: speciality.advantages ? speciality.advantages.advantageData : null,
            causeInfo: speciality.causes ? speciality.causes.causesData : null,
            symptomInfo: speciality.symptoms ? speciality.symptoms.symptomData : null,
            onLinerInfo: pageContent.onLiner,
            testimonials:speciality.testimonials,
            FAQ:speciality.FAQ,

            precaution_data:speciality.precaution ? speciality.precaution.data : null,

            reason_data: speciality.reason ? speciality.reason.data : null,
            reason_heading:speciality.reason.heading,

            // symptom_data:speciality.symptoms.symptomData,
            symptom_heading:speciality.symptoms.heading,

            types_data: speciality.types ? speciality.types.data : null,
            types_heading:speciality.types.heading,


            treatment_heading: speciality.treatments ? speciality.treatments.heading : null,
            risk_heading: speciality.risks ? speciality.risks.heading : null,
            advantage_heading: speciality.advantages ? speciality.advantages.heading : null,
            cause_heading: speciality.causes ? speciality.causes.heading : null,
    
            condition_data: speciality.condition ? speciality.condition.data: null ,
            condition_heading: speciality.condition ? speciality.condition.heading: null,

            aboutService_description:speciality.aboutService_description,
            aboutService_heading:speciality.aboutService_heading,
            aboutService_subHeading:speciality.aboutService_subHeading,

            bigChainPricePlunes: speciality.priceWithPlunes ? speciality.priceWithPlunes.bigChains : null,
            bigChainPriceWithoutPlunes: speciality.priceWithoutPlunes ? speciality.priceWithoutPlunes.bigChains : null,
    
            multiSpecialityPricePlunes: speciality.priceWithPlunes ? speciality.priceWithPlunes.multiSpecialities : null,
            multiSpecialityPriceWithoutPlunes: speciality.priceWithoutPlunes ? speciality.priceWithoutPlunes.multiSpecialities : null,
    
            regionalBrandsPricePlunes: speciality.priceWithPlunes ? speciality.priceWithPlunes.regionalBrands : null,
            regionalBrandsPriceWithoutPlunes: speciality.priceWithoutPlunes ? speciality.priceWithoutPlunes.regionalBrands : null,

            need_data: speciality.need ? speciality.need.data : null,
            need_heading: speciality.need ? speciality.need.heading : null,

            benefits_data: speciality.benefits ? speciality.benefits.data : null,
            benefits_heading: speciality.benefits ? speciality.benefits.heading : null,

            metaTitle: pageContent.metaData ? pageContent.metaData.title : null,
            metaDesc: pageContent.metaData ? pageContent.metaData.desc : null,

            advancedTreatments: speciality.advancedTreatments

            // facts_data: speciality.facts.data,
            // facts_heading: speciality.facts.heading,

            // dos_data: speciality.dos.data,
            // dos_heading: speciality.dos.heading,

            // donts_data: speciality.donts.data,
            // donts_heading: speciality.donts.heading
        })
        setSpecialityName(speciality.specialityName)
        let headingBlock = pageContent.heading.replace("<h1>", "").replace("</h1>", "")
        let tempHeading = {
            top: "",
            second: "",
            green: "",
            end: ""
        }
        tempHeading.top = headingBlock.split("<br />")[0]
        let secondary = headingBlock.split("<br />")[1] ?  headingBlock.split("<br />")[1].replace(/<span>/g, "").replace(/<\/span>/g, "") : ""
        tempHeading.second = secondary.split("<b>")[0]
        tempHeading.green = headingBlock.split("<br />")[1] ? secondary.split("<b>")[1].split("</b>")[0] : ""
        tempHeading.end = headingBlock.split("<br />")[1] ? secondary.split("<b>")[1].split("</b>")[1] : ""
        setHeadingString(tempHeading)
    }

    const setServiceContent = (val) => {
        const speciality = specialities.filter((specials) => specials.value === val)[0].content
        set_data({
            id: speciality._id,
            specialityId: speciality.specialityId,
            definition: speciality.definition,
            duration: speciality.duration,
            discharge:speciality.discharge,
            treatments: speciality.treatments,
            treatmentInfo: speciality.treatments ? speciality.treatments.treatmentData : null,
            riskInfo: speciality.risks ? speciality.risks.riskData : null,
            advantageInfo: speciality.advantages ? speciality.advantages.advantageData : null,
            causeInfo: speciality.causes ? speciality.causes.causesData : null,
            symptomInfo: speciality.symptoms ? speciality.symptoms.symptomData : null,
            onLinerInfo: [],
            testimonials:speciality.testimonials,
            FAQ:speciality.FAQ,
            advancedTreatments:speciality.advancedTreatments,

            precaution_data:speciality.precaution ? speciality.precaution.data : null,

            reason_data: speciality.reason ? speciality.reason.data : null,
            reason_heading:speciality.reason.heading,

            // symptom_data:speciality.symptoms.symptomData,
            symptom_heading:speciality.symptoms.heading,

            types_data: speciality.types ? speciality.types.data : null,
            types_heading:speciality.types.heading,


            treatment_heading: speciality.treatments ? speciality.treatments.heading : null,
            risk_heading: speciality.risks ? speciality.risks.heading : null,
            advantage_heading: speciality.advantages ? speciality.advantages.heading : null,
            cause_heading: speciality.causes ? speciality.causes.heading : null,
    
            condition_data: speciality.condition ? speciality.condition.data: null ,
            condition_heading: speciality.condition ? speciality.condition.heading: null,

            aboutService_description:speciality.aboutService_description,
            aboutService_heading:speciality.aboutService_heading,
            aboutService_subHeading:speciality.aboutService_subHeading,

            bigChainPricePlunes: speciality.priceWithPlunes ? speciality.priceWithPlunes.bigChains : null,
            bigChainPriceWithoutPlunes: speciality.priceWithoutPlunes ? speciality.priceWithoutPlunes.bigChains : null,
    
            multiSpecialityPricePlunes: speciality.priceWithPlunes ? speciality.priceWithPlunes.multiSpecialities : null,
            multiSpecialityPriceWithoutPlunes: speciality.priceWithoutPlunes ? speciality.priceWithoutPlunes.multiSpecialities : null,
    
            regionalBrandsPricePlunes: speciality.priceWithPlunes ? speciality.priceWithPlunes.regionalBrands : null,
            regionalBrandsPriceWithoutPlunes: speciality.priceWithoutPlunes ? speciality.priceWithoutPlunes.regionalBrands : null,

            need_data: speciality.need ? speciality.need.data : null,
            need_heading: speciality.need ? speciality.need.heading : null,

            benefits_data: speciality.benefits ? speciality.benefits.data : null,
            benefits_heading: speciality.benefits ? speciality.benefits.heading : null,

            // facts_data: speciality.facts.data,
            // facts_heading: speciality.facts.heading,

            // dos_data: speciality.dos.data,
            // dos_heading: speciality.dos.heading,

            // donts_data: speciality.donts.data,
            // donts_heading: speciality.donts.heading

        })
    }


    const validateHeading = (data) => {
        const error = {
            heading: { state: false, text: "" }
        }
        if (data.heading === '') {
            {
                error.heading.text = "Enter Heading"
                error.heading.state = true
            }
        }
        return error
    }

    const validateSubHeading = (data) => {
        const error = {
            formHeading: { state: false, text: "" }
        }
        if (data.formHeading === '') {
            {
                error.formHeading.text = "Enter formHeading"
                error.formHeading.state = true
            }
        }
        return error
    }

    const validateDuration = (data) => {
        const error = {
            duration: { state: false, text: "" }
        }
        if (data.duration === '') {
            {
                error.duration.text = "Enter duration"
                error.duration.state = true
            }
        }
        return error
    }

    const validateDefinition = (data) => {
        const error = {
            definition: { state: false, text: "" }
        }
        if (data.definition === '') {
            {
                error.definition.text = "Enter definition"
                error.definition.state = true
            }
        }
        return error
    }

    const validateDischarge = (data) => {
        const error = {
            discharge: { state: false, text: "" }
        }
        if (data.discharge === '') {
            {
                error.discharge.text = "Enter On liner"
                error.discharge.state = true
            }
        }
        return error
    }

    const validateTreatment = (data) => {
        const error = {
            treatmentInfo: { state: false, text: "" }
        }
        if (data.treatmentInfo === '') {
            {
                error.treatmentInfo.text = "Enter treatment"
                error.treatmentInfo.state = true
            }
        }
        return error
    }

    const validateRisk = (data) => {
        const error = {
            riskInfo: { state: false, text: "" }
        }
        if (data.riskInfo === '') {
            {
                error.riskInfo.text = "Enter Risk"
                error.riskInfo.state = true
            }
        }
        return error
    }

    const validateAdvantages = (data) => {
        const error = {
            advantageInfo: { state: false, text: "" }
        }
        if (data.advantageInfo === '') {
            {
                error.advantageInfo.text = "Enter Advantages"
                error.advantageInfo.state = true
            }
        }
        return error
    }

    const validateCause = (data) => {
        const error = {
            causeInfo: { state: false, text: "" }
        }
        if (data.causeInfo === '') {
            {
                error.causeInfo.text = "Enter Causes"
                error.causeInfo.state = true
            }
        }
        return error
    }

    const validateSymptom = (data) => {
        const error = {
            symptomInfo: { state: false, text: "" }
        }
        if (data.symptomInfo === '') {
            {
                error.symptomInfo.text = "Enter Symptom"
                error.symptomInfo.state = true
            }
        }
        return error
    }
    const validateOnliner = (data) => {
        const error = {
            onLinerInfo: { state: false, text: "" }
        }
        if (data.onLinerInfo === '') {
            {
                error.onLinerInfo.text = "Enter On liner"
                error.onLinerInfo.state = true
            }
        }
        return error
    }

    const submit_all = () => {
        set_data({...data, heading: `<h1>${headingStrings.top}<br /><span>${headingStrings.second}<b>${headingStrings.green}</b>${headingStrings.end}</span></h1>`})
        if(add_service_flag){
            props.updateMarketingPage({
                ...data,
                procedureFullName:add_service.speciality.trim().replace(" ", "-"),
                pageType:add_service.pageInfo.trim().replace(" ", "-"),
                specialityName: specialityName,
                type:"add_marketinPage",
                heading: `<h1>${headingStrings.top}<br /><span>${headingStrings.second}<b>${headingStrings.green}</b>${headingStrings.end}`,
            })
        }else {
            props.updateMarketingPage({
                id: initial_data.id,
                pageType:add_service.pageInfo.trim().replace(" ", "-"),
                specialityName: specialityName,
                ...data,
                heading: `<h1>${headingStrings.top}<br /><span>${headingStrings.second}<b>${headingStrings.green}</b>${headingStrings.end}`,
            })
        }
        // const ret_error = valid_submit_all({ ...data })
        // set_error({ ...error, ...ret_error })
        // console.log(ret_error, "ret_error in add_member")
        // if (!error_form_check(ret_error)) {
        //     console.log("inside submit_all")


           
        // }else {
        //     props.global_error({
        //         message:"Please check that all fields are filled",
        //         success:false,
        //         heading:"Service Data Uploader"

        //     })
        // }
    }

    const valid_submit_all = (data) => {
        const error = {
            serviceFamily: { state: false, text: "" },
            speciality: { state: false, text: "" },
            heading: { state: false, text: "" },
            subHeading: { state: false, text: "" },
            definition: { state: false, text: "" },
            duration: { state: false, text: "" },
            discharge: { state: false, text: "" },
            treatments: { state: false, text: "" },
            treatmentInfo: { state: false, text: "" },
            riskInfo: { state: false, text: "" },
            advantageInfo: { state: false, text: "" },
            causeInfo: { state: false, text: "" },
            onLinerInfo: { state: false, text: "" },
            symptomInfo: { state: false, text: "" },


            precaution_data:{ state: false, text: "" },
            reason_data:{ state: false, text: "" },
            reason_heading:{ state: false, text: "" },
            symptom_data:{ state: false, text: "" },
            symptom_heading:{ state: false, text: "" },
            types_data:{ state: false, text: "" },
            types_heading:{ state: false, text: "" },

            
        }
        console.log(error,"error in Validate")

        return error
    }

     const tabs_change = (val)=> {
         console.log(val,"val in tabs_change")
         set_active_tab(val)
     }

    console.log(active_tab,"active_tab")

    useEffect(() => {
    if (props.updateMarketingPage_ret) {
        set_edit_flag(!edit_flag)
        let spcl = data.procedureFullName ? data.procedureFullName : ""
        if(!addService)
            specialities.map((speciality) => {
                if(speciality.value.toString() === add_service.speciality.toString())
                    spcl = speciality.name
            })
        else spcl = add_service.speciality
        set_initial_data({
            ...data,
            page: add_service.pageInfo,
            procedureFullName: spcl
        })
        if (props.updateMarketingPage_ret.success) {
            props.global_error({
                success: true,
                message: props.updateMarketingPage_ret.message,
                heading: "Marketing Page",
            })
        } else {
            props.global_error({
                success: false,
                message: props.updateMarketingPage_ret.message,
                heading: "Marketing Page",
            })
        }
        props.updateMarketingPage_loading()
    }

}, [props.updateMarketingPage_ret])

    if (!!edit_flag) {
        return (<React.Fragment>
            <Card title="">
                <PageHeader
                    className="site-page-header"
                    onBack={() => {
                        set_edit_flag(false)
                        set_add_service_flag(false)
                        initialize_data()
                    }}
                    title="Marketing Page Handler"
                  
                />
                <Row align="center" justify="center" style={{ alignItems: 'center', marginTop: "2rem" }}>
                    <AntdInput
                        label="Choose Speciality for Hospitals & Doctors"
                        value={data.specialityId}
                        options={props.specialities_options}
                        loading={props.specialities_loading}
                        error_text={error.speciality.text}
                        toggle_error={() =>
                        set_error({
                            ...error,
                            speciality: { text: "", state: false },
                        })
                        }
                        error={error.speciality.state}
                        name="speciality"
                        type="select"
                        onChange={(val) => {
                            props.specialities_options.map((speciality) => {
                                if(speciality.value === val)
                                    setSpecialityName(speciality.name)
                            })
                            set_data({ ...data, specialityId: val })
                        }}
                        placeholder="Choose speciality"
                    />
                </Row>
                <Row >
                    {add_service_flag && <Row >
                        <Col span={24}>
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                <div className="flex-parent-new">
                                    {!addService ? <Col span={24}>
                                        <Row align="center" justify="center" style={{ alignItems: 'center' }}>
                                            <AntdInput
                                                label="Service"
                                                value={add_service.speciality}
                                                disabled={props.get_bids_loading_flag || props.updateMarketingPage_loading_flag}
                                                options={specialities}
                                                loading={props.global_store.get_specialities_loading || props.updateMarketingPage_loading_flag}
                                                error_text={error_add_service.speciality.text}
                                                toggle_error={() => set_error_add_service({ ...error_add_service, speciality: { text: '', state: false } })}
                                                error={error_add_service.speciality.state}
                                                name="service"
                                                type="select"
                                                onChange={event => {
                                                    setServiceContent(event)
                                                    set_add_service({...add_service, speciality:event})
                                                }}
                                                placeholder="Choose service" />
                                            <div style={{margin: 'auto 1rem', textAlign: "center" }}>or</div>
                                            <Button
                                                type="primary"
                                                onClick={() => setAddService(true)}
                                                style={{ width: 'auto', margin: 'auto auto auto 0' }}
                                            >
                                                Add new Service
                                            </Button>
                                        </Row>
                                    </Col> : <Col span={24}>
                                        <Row align="center" justify="center" style={{ alignItems: 'center', marginTop: "1rem" }}>
                                            <AntdInput
                                                label="Service"
                                                value={add_service.speciality}
                                                disabled={props.get_bids_loading_flag || props.updateMarketingPage_loading_flag}
                                                loading={props.global_store.get_specialities_loading || props.updateMarketingPage_loading_flag}
                                                error_text={error_add_service.speciality.text}
                                                toggle_error={() => set_error_add_service({ ...error_add_service, speciality: { text: '', state: false } })}
                                                error={error_add_service.speciality.state}
                                                name="service"
                                                onChange={event => set_add_service({...add_service, speciality:event.target.value})}
                                                placeholder="Enter speciality" />
                                            <div style={{margin: 'auto 1rem', textAlign: "center" }}>or</div>
                                            <Button
                                                type="primary"
                                                onClick={() => setAddService(false)}
                                                style={{ width: 'auto', margin: 'auto auto auto 0' }}
                                            >
                                                Choose from existing Services
                                            </Button>
                                        </Row>
                                    </Col>}
                                    <span className="flex-child-1-new">
                                    <AntdInput
                                            label="Page"
                                            value={add_service.pageInfo}
                                            disabled={props.get_bids_loading_flag || props.updateMarketingPage_loading_flag}
                                            loading={props.global_store.get_specialities_loading || props.updateMarketingPage_loading_flag}
                                            error_text={error_add_service.pageInfo.text}
                                            toggle_error={() => set_error_add_service({ ...error_add_service, pageInfo: { text: '', state: false } })}
                                            error={error_add_service.pageInfo.state}
                                            name="pageInfo"
                                            onChange={event => set_add_service({...add_service, pageInfo:event.target.value})}
                                            placeholder="Page type" />
                                    </span>
                                </div>
                            </Form>
                        </Col>
                    </Row>}

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <Alert
                                        message="Heading example: <h1>Top Kidney Stone <br /><span>Specialists in <b>Delhi, Gurgaon and Noida</b></span></h1>"
                                        type="info"
                                        showIcon
                                        style={{ marginBottom: '1.5rem' }}
                                    />
                                </span>
                            </div>
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Heading First line"
                                        value={headingStrings.top}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.heading.text}
                                        toggle_error={() => set_error({ ...error, heading: { text: '', state: false } })}
                                        error={error.heading.state}
                                        name="heading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            setHeadingString({ ...headingStrings, top: event.target.value })
                                        }}
                                        placeholder="Enter Heading's first bold line" />
                                    <AntdInput
                                        label="Heading Second line"
                                        value={headingStrings.second}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.heading.text}
                                        toggle_error={() => set_error({ ...error, heading: { text: '', state: false } })}
                                        error={error.heading.state}
                                        name="heading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            setHeadingString({ ...headingStrings, second: event.target.value })
                                        }}
                                        placeholder="Enter Heading's second thin line before the highlighted part" />
                                    <AntdInput
                                        label="Heading green highlighted"
                                        value={headingStrings.green}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.heading.text}
                                        toggle_error={() => set_error({ ...error, heading: { text: '', state: false } })}
                                        error={error.heading.state}
                                        name="heading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            setHeadingString({ ...headingStrings, green: event.target.value })
                                        }}
                                        placeholder="Enter content highlighted in green" />
                                    <AntdInput
                                        label="Heading end"
                                        value={headingStrings.end}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.heading.text}
                                        toggle_error={() => set_error({ ...error, heading: { text: '', state: false } })}
                                        error={error.heading.state}
                                        name="heading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            setHeadingString({ ...headingStrings, end: event.target.value })
                                        }}
                                        placeholder="Enter Heading's last content" />
                                </span>
                            </div>
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <Alert
                                        message="Heading Preview"
                                        type="info"
                                        style={{ marginBottom: '1.5rem' }}
                                    />
                                    <div id="campaign-treatment-head" dangerouslySetInnerHTML={{__html: `<h1>${headingStrings.top}<br /><span>${headingStrings.second}<b>${headingStrings.green}</b>${headingStrings.end}</span></h1>`}}>
                                    </div>
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Form Heading"
                                        value={data.formHeading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.formHeading.text}
                                        toggle_error={() => set_error({ ...error, formHeading: { text: '', state: false } })}
                                        error={error.formHeading.state}
                                        name="formHeading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, formHeading: event.target.value })
                                        }}
                                        placeholder="Enter form heading" />
                                </span>
                              

                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Alt Tags"
                                        value={data.altTags}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.altTags.text}
                                        toggle_error={() => set_error({ ...error, altTags: { text: '', state: false } })}
                                        error={error.altTags.state}
                                        name="altTags"
                                        onChange={event => {
                                            console.log(event, "event in Add definition")
                                            set_data({ ...data, altTags: event.target.value })
                                        }}
                                        placeholder="Enter Alt Tags for the top image" />
                                </span>
                               

                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Definition"
                                        value={data.definition}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.definition.text}
                                        toggle_error={() => set_error({ ...error, definition: { text: '', state: false } })}
                                        error={error.definition.state}
                                        name="definition"
                                        onChange={event => {
                                            console.log(event, "event in Add definition")
                                            set_data({ ...data, definition: event.target.value })
                                        }}
                                        placeholder="Enter Definition" />
                                </span>
                               

                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Duration"
                                        value={data.duration}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.duration.text}
                                        toggle_error={() => set_error({ ...error, duration: { text: '', state: false } })}
                                        error={error.duration.state}
                                        name="duration"
                                        onChange={event => {
                                            console.log(event, "event in Add duration")
                                            set_data({ ...data, duration: event.target.value })
                                        }}
                                        placeholder="Enter Duration" />
                                </span>
                             
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Discharge"
                                        value={data.discharge}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.discharge.text}
                                        toggle_error={() => set_error({ ...error, discharge: { text: '', state: false } })}
                                        error={error.discharge.state}
                                        name="discharge"
                                        onChange={event => {
                                            console.log(event, "event in discharge")
                                            set_data({ ...data, discharge: event.target.value })
                                        }}
                                        placeholder="Enter Discharge" />
                                </span>

                              
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="One Liner"
                                        type="textarea"
                                        rows={8}
                                        value={data.onLinerInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.onLinerInfo.text}
                                        toggle_error={() => set_error({ ...error, onLinerInfo: { text: '', state: false } })}
                                        error={error.onLinerInfo.state}
                                        name="onLinerInfo"
                                        onChange={event => {
                                            console.log(event, "event in Onliner")
                                            set_data({ ...data, onLinerInfo: event.target.value })
                                        }}
                                        placeholder="Enter One Liner" />
                                </span>

                              
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Symptom heading"
                                        value={data.symptom_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.symptom_heading.text}
                                        toggle_error={() => set_error({ ...error, symptom_heading: { text: '', state: false } })}
                                        error={error.symptom_heading.state}
                                        name="symptom_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, symptom_heading: event.target.value })
                                        }}
                                        placeholder="Enter Symptom Heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Symptoms"
                                        type="textarea"
                                        rows={8}
                                        value={data.symptomInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.symptomInfo.text}
                                        toggle_error={() => set_error({ ...error, symptomInfo: { text: '', state: false } })}
                                        error={error.symptomInfo.state}
                                        name="symptomInfo"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, symptomInfo: event.target.value })
                                        }}
                                        placeholder="Enter Symptoms" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Precaution Data"
                                        type="textarea"
                                        rows={8}
                                        value={data.precaution_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.precaution_data.text}
                                        toggle_error={() => set_error({ ...error, precaution_data: { text: '', state: false } })}
                                        error={error.precaution_data.state}
                                        name="precaution_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, precaution_data: event.target.value })
                                        }}
                                        placeholder="Enter Precaution Data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
               
                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Reason heading"
                                        value={data.reason_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.reason_heading.text}
                                        toggle_error={() => set_error({ ...error, reason_heading: { text: '', state: false } })}
                                        error={error.reason_heading.state}
                                        name="reason_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, reason_heading: event.target.value })
                                        }}
                                        placeholder="Enter Reason Heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Reason Data"
                                        type="textarea"
                                        rows={8}
                                        value={data.reason_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.reason_data.text}
                                        toggle_error={() => set_error({ ...error, reason_data: { text: '', state: false } })}
                                        error={error.reason_data.state}
                                        name="reason_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, reason_data: event.target.value })
                                        }}
                                        placeholder="Enter Reason Data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Types Heading"
                                        
                                        value={data.types_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.types_heading.text}
                                        toggle_error={() => set_error({ ...error, types_heading: { text: '', state: false } })}
                                        error={error.types_heading.state}
                                        name="types_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, types_heading: event.target.value })
                                        }}
                                        placeholder="Enter Types Heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>



                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Types Data"
                                        type="textarea"
                                        rows={8}
                                        value={data.types_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.types_data.text}
                                        toggle_error={() => set_error({ ...error, types_data: { text: '', state: false } })}
                                        error={error.types_data.state}
                                        name="types_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, types_data: event.target.value })
                                        }}
                                        placeholder="Enter Types Data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Risk Heading"
                                        value={data.risk_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.risk_heading.text}
                                        toggle_error={() => set_error({ ...error, risk_heading: { text: '', state: false } })}
                                        error={error.risk_heading.state}
                                        name="risk_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, risk_heading: event.target.value })
                                        }}
                                        placeholder="Enter Risk heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Risk"
                                        type="textarea"
                                        rows={8}
                                        value={data.riskInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.riskInfo.text}
                                        toggle_error={() => set_error({ ...error, riskInfo: { text: '', state: false } })}
                                        error={error.riskInfo.state}
                                        name="riskInfo"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, riskInfo: event.target.value })
                                        }}
                                        placeholder="Enter risk" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Advantage Heading"
                                        value={data.advantage_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.advantage_heading.text}
                                        toggle_error={() => set_error({ ...error, advantage_heading: { text: '', state: false } })}
                                        error={error.advantage_heading.state}
                                        name="advantage_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, advantage_heading: event.target.value })
                                        }}
                                        placeholder="Enter Advantage heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Advantages"
                                        type="textarea"
                                        rows={8}
                                        value={data.advantageInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.advantageInfo.text}
                                        toggle_error={() => set_error({ ...error, advantageInfo: { text: '', state: false } })}
                                        error={error.advantageInfo.state}
                                        name="advantageInfo"
                                        onChange={event => {
                                            console.log(event, "event in advantages")
                                            set_data({ ...data, advantageInfo: event.target.value })
                                        }}
                                        placeholder="Enter Advantages" />
                                </span>

                              
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Cause Heading"
                                        value={data.cause_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.cause_heading.text}
                                        toggle_error={() => set_error({ ...error, cause_heading: { text: '', state: false } })}
                                        error={error.cause_heading.state}
                                        name="cause_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, cause_heading: event.target.value })
                                        }}
                                        placeholder="Enter Cause heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Causes"
                                        type="textarea"
                                        rows={8}
                                        value={data.causeInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.causeInfo.text}
                                        toggle_error={() => set_error({ ...error, causeInfo: { text: '', state: false } })}
                                        error={error.causeInfo.state}
                                        name="causeInfo"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, causeInfo: event.target.value })
                                        }}
                                        placeholder="Enter Causes" />
                                </span>

                           
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Condition heading"
                                        value={data.condition_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.condition_heading.text}
                                        toggle_error={() => set_error({ ...error, condition_heading: { text: '', state: false } })}
                                        error={error.condition_heading.state}
                                        name="condition_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, condition_heading: event.target.value })
                                        }}
                                        placeholder="Enter condition heading " />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                
                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Condition Data"
                                        value={data.condition_data}
                                        rows={8}
                                        type="textarea"
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.condition_data.text}
                                        toggle_error={() => set_error({ ...error, condition_data: { text: '', state: false } })}
                                        error={error.condition_data.state}
                                        name="condition_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, condition_data: event.target.value })
                                        }}
                                        placeholder="Enter condition Data " />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Needs Heading"
                                        value={data.need_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="need"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, need_heading: event.target.value })
                                        }}
                                        placeholder="Enter needs heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Needs data"
                                        type="textarea"
                                        rows={8}
                                        value={data.need_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="need"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, need_data: event.target.value })
                                        }}
                                        placeholder="Enter needs data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Benefits Heading"
                                        rows={8}
                                        value={data.benefits_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="stages"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, benefits_heading: event.target.value })
                                        }}
                                        placeholder="Enter benefits heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Benefits"
                                        type="textarea"
                                        rows={8}
                                        value={data.benefits_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="stages"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, benefits_data: event.target.value })
                                        }}
                                        placeholder="Enter benefits" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Metadata Title"
                                        value={data.metaTitle}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="metaTitle"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, metaTitle: event.target.value })
                                        }}
                                        placeholder="Enter MetaData Title" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Metadata Description"
                                        rows={8}
                                        value={data.metaDesc}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="metaDesc"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, metaDesc: event.target.value })
                                        }}
                                        placeholder="Enter Metadata Description" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                {/*<Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Facts"
                                        type="textarea"
                                        rows={8}
                                        value={data.facts_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="facts"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, facts_data: event.target.value })
                                        }}
                                        placeholder="Enter facts" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Facts Heading"
                                        rows={8}
                                        value={data.facts_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="facts"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, facts_heading: event.target.value })
                                        }}
                                        placeholder="Enter facts heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Do's"
                                        type="textarea"
                                        rows={8}
                                        value={data.dos_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="do's"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, dos_data: event.target.value })
                                        }}
                                        placeholder="Enter do's" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Do's Heading"
                                        rows={8}
                                        value={data.dos_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="need"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, dos_heading: event.target.value })
                                        }}
                                        placeholder="Enter do's heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Dont's"
                                        type="textarea"
                                        rows={8}
                                        value={data.donts_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="dont's"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, donts_data: event.target.value })
                                        }}
                                        placeholder="Enter dont's" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Dont's Heading"
                                        rows={8}
                                        value={data.donts_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="dont's heading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, donts_heading: event.target.value })
                                        }}
                                        placeholder="Enter dont's heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="About service heading"
                                        value={data.aboutService_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.aboutService_heading.text}
                                        toggle_error={() => set_error({ ...error, aboutService_heading: { text: '', state: false } })}
                                        error={error.aboutService_heading.state}
                                        name="aboutService_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, aboutService_heading: event.target.value })
                                        }}
                                        placeholder="Enter Aboutservice heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="About service sub heading"
                                        value={data.aboutService_subHeading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.aboutService_subHeading.text}
                                        toggle_error={() => set_error({ ...error, aboutService_subHeading: { text: '', state: false } })}
                                        error={error.aboutService_subHeading.state}
                                        name="aboutService_subHeading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, aboutService_subHeading: event.target.value })
                                        }}
                                        placeholder="Enter Aboutservice subheading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="About service sub heading"
                                        value={data.aboutService_description}
                                        rows={8}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.aboutService_description.text}
                                        toggle_error={() => set_error({ ...error, aboutService_description: { text: '', state: false } })}
                                        error={error.aboutService_description.state}
                                        name="aboutService_description"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, aboutService_description: event.target.value })
                                        }}
                                        placeholder="Enter Aboutservice description" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row> */}

                 
                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Big Chains Price with Plunes"
                                        value={data.bigChainPricePlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="aboutService_description"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, bigChainPricePlunes: event.target.value })
                                        }}
                                        placeholder="Enter big chains price with plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Big Chains Price without Plunes"
                                        value={data.bigChainPriceWithoutPlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="aboutService_description"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, bigChainPriceWithoutPlunes: event.target.value })
                                        }}
                                        placeholder="Enter big chains price without plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Multi Speciality Price with Plunes"
                                        value={data.multiSpecialityPricePlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Multi Speciality Price with Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, multiSpecialityPricePlunes: event.target.value })
                                        }}
                                        placeholder="Enter multi speciality price with plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Multi Speciality Price without Plunes"
                                        value={data.multiSpecialityPriceWithoutPlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Multi Speciality Price Without Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, multiSpecialityPriceWithoutPlunes: event.target.value })
                                        }}
                                        placeholder="Enter multi speciality price without plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Regional Brands Price with Plunes"
                                        value={data.regionalBrandsPricePlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Regional Brands With Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, regionalBrandsPricePlunes: event.target.value })
                                        }}
                                        placeholder="Enter regional brands price with plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Regional Brands Price without Plunes"
                                        value={data.regionalBrandsPriceWithoutPlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Regional Brands Wihtout Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, regionalBrandsPriceWithoutPlunes: event.target.value })
                                        }}
                                        placeholder="Enter regional brands price without plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Treatment Heading"
                                        value={data.treatment_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.treatment_heading.text}
                                        toggle_error={() => set_error({ ...error, treatment_heading: { text: '', state: false } })}
                                        error={error.treatment_heading.state}
                                        name="treatment_heading"
                                        onChange={event => {
                                            console.log( event.target.value, "event in treatment")
                                            set_data({ ...data, treatment_heading: event.target.value })
                                        }}
                                        placeholder="Enter treatment heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical">
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Treatment"
                                        type="textarea"
                                        rows={10}
                                        value={data.treatmentInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.treatmentInfo.text}
                                        toggle_error={() => set_error({ ...error, treatmentInfo: { text: '', state: false } })}
                                        error={error.treatmentInfo.state}
                                        name="treatmentInfo"
                                        onChange={event => {
                                            console.log(event, "event in Add duration")
                                            set_data({ ...data, treatmentInfo: event.target.value })
                                        }}
                                        placeholder="Enter Treatment" />
                                </span>
                            </div>

                        </Form>
                        <div className="u-margin-top-small text-center">
                        <Button
                                    type="primary"
                                    loading={props.service_data_handler_loading_flag}
                                    disabled={props.service_data_handler_loading_flag}
                                    onClick={() => submit_all()}
                                    style={{ width: 'auto', margin: 'auto' }}
                                >
                                    Submit
                                 </Button>
                        </div>
                    </Col>

                    { !add_service_flag   &&  <Col span={24}>
                       <Tabs defaultActiveKey={active_tab} onChange={tabs_change}>
                            <TabPane
                            tab={
                                <span>
                                <AppleOutlined />
                                 FAQs
                                </span>
                            }
                            key="1"
                            >
                                {active_tab=='1'  &&  <EditFaqs
                                    family_id={initial_data.id}
                                    update_faq = {props.update_faq}
                                    update_faq_loading = {props.update_faq_loading}
                                    update_faq_ret = {props.update_faq_ret}
                                    update_faq_loading_flag = {props.update_faq_loading_flag}

                                    global_error = {props.global_error}
                
                                   type="FAQ" typeA ="Question" typeB = "Answer" data={initial_data.FAQ}  />}
                            </TabPane>
                            <TabPane
                            tab={
                                <span>
                                <AndroidOutlined />
                                   Testimonials
                                </span>
                            }
                            key="2"
                            >
                               {active_tab=='2' && <EditFaqs
                                    family_id={initial_data.id}
                                    update_faq = {props.update_faq}
                                    update_faq_loading = {props.update_faq_loading}
                                    update_faq_ret = {props.update_faq_ret}
                                    update_faq_loading_flag = {props.update_faq_loading_flag}

                                    global_error = {props.global_error}
                
                                   type="testimonial" typeA ="User" typeB = "Review" data={initial_data.testimonials}  />}
                            </TabPane>
                            <TabPane
                            tab={
                                <span>
                                <AndroidOutlined />
                                   Advanced Treatments
                                </span>
                            }
                            key="3"
                            >
                               {active_tab=='3' && <EditAdvancedTreatments
                                    family_id={initial_data.id}
                                    
                                    update_advanced_treatment = {props.update_advanced_treatment}
                                    update_advanced_treatment_loading = {props.update_advanced_treatment_loading}
                                    update_advanced_treatment_ret = {props.update_advanced_treatment_ret}
                                    update_advanced_treatment_loading_flag = {props.update_advanced_treatment_loading_flag}

                                    upload_advanced_treatment_image = {props.upload_advanced_treatment_image}
                                    upload_advanced_treatment_image_loading = {props.upload_advanced_treatment_image_loading}
                                    upload_advanced_treatment_image_ret = {props.upload_advanced_treatment_image_ret}
                                    upload_advanced_treatment_image_loading_flag = {props.upload_advanced_treatment_image_loading_flag}

                                    upload_advanced_treatment_icon = {props.upload_advanced_treatment_icon}
                                    upload_advanced_treatment_icon_loading = {props.upload_advanced_treatment_icon_loading}
                                    upload_advanced_treatment_icon_ret = {props.upload_advanced_treatment_icon_ret}
                                    upload_advanced_treatment_icon_loading_flag = {props.upload_advanced_treatment_icon_loading_flag}

                                    global_error = {props.global_error}
                
                                   type="advancedTreatments" typeA ="Name" typeB = "info" typeC = "ImageUrl" typeD="iconURL" data={initial_data.advancedTreatments}  />}
                            </TabPane>
                        </Tabs>
                        
                    </Col>
             }

            </Card>
        </React.Fragment>)
    } else {
        return <React.Fragment>
            <Card title="Upload Service Data">
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Service"
                                        value={data.speciality}
                                        disabled={props.get_bids_loading_flag || props.updateMarketingPage_loading_flag}
                                        options={specialities}
                                        loading={props.global_store.get_specialities_loading || props.updateMarketingPage_loading_flag}
                                        error_text={error.speciality.text}
                                        toggle_error={() => set_error({ ...error, speciality: { text: '', state: false } })}
                                        error={error.speciality.state}
                                        name="service"
                                        type="select"
                                        onChange={event => procedureChange(event)}
                                        placeholder="Choose service" />
                                </span>
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Page"
                                        value={data.serviceFamily}
                                        options={pageInfo}
                                        loading={props.get_service_family_list_by_specialityid_loading_flag}
                                        disabled={props.get_bids_loading_flag}
                                        error_text={error.serviceFamily.text}
                                        toggle_error={() => set_error({ ...error, serviceFamily: { text: '', state: false } })}
                                        error={error.serviceFamily.state}
                                        name="serviceFamily"
                                        type="select"
                                        onChange={event => pageInfoChange(event)}
                                        placeholder="Page type" />
                                </span>
                            </div>
                        </Form>
                    </Col>


                    <Title level={5} >Add New Marketing page on Website</Title>

                    <div className="u-margin-top-medium">
                                <Button
                                    type="primary"
                                    loading={false}
                                    disabled={false}
                                    onClick={(add_service_button)}
                                    style={{ width: 'auto', margin: 'auto' }}
                                >
                                    Add New Marketing Page
                                 </Button> 
                    </div>

                </Row>


            </Card>
           
            <Descriptions   column={1} bordered={true} title="Marketing Page">
                <Descriptions.Item layout="horizontal" label="URL">{initial_data && initial_data.page && initial_data.page.length !== 0 && `https://www.plunes.com/random-content/${initial_data.page}/${initial_data.procedureFullName}/random-string`}</Descriptions.Item>
                <Descriptions.Item layout="horizontal" label="Heading">{initial_data && initial_data.heading}</Descriptions.Item>
                <Descriptions.Item layout="horizontal" label="Form Heading">{initial_data && initial_data.formHeading}</Descriptions.Item>
                <Descriptions.Item label="Definition">{initial_data && initial_data.definition}</Descriptions.Item>
                <Descriptions.Item label="Duration">{initial_data && initial_data.duration}</Descriptions.Item>
                <Descriptions.Item label="Discharge">{initial_data && initial_data.discharge}</Descriptions.Item>
                <Descriptions.Item label="Treatment">{initial_data && initial_data.treatmentInfo}</Descriptions.Item>
                <Descriptions.Item label="Risk">{initial_data && initial_data.riskInfo}</Descriptions.Item>
                <Descriptions.Item label="Advantages">{initial_data && initial_data.advantageInfo}</Descriptions.Item>
                <Descriptions.Item label="Symptom">{initial_data && initial_data.symptomInfo}</Descriptions.Item>
                <Descriptions.Item label="On Liner">{initial_data && initial_data.onLinerInfo}</Descriptions.Item>
            </Descriptions>
        
            <div className="u-margin-top-small">
                <Button
                    type="primary"
                    onClick={() => edit_cllck()}
                    icon={<EditOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >Edit</Button>
            </div>
        </React.Fragment>
    }
}
export default CampaignServiceHandler