import   {  GET_REAL_TIME_INSIGHTS, GET_REAL_TIME_INSIGHTS_RET, GET_REAL_TIME_INSIGHTS_LOADING,
    GET_ACTIONABLE_INSIGHTS_LOADING, GET_ACTIONABLE_INSIGHTS_RET, GET_ACTIONABLE_INSIGHTS, GET_SUGGESTED_INSIGHTS, GET_SUGGESTED_INSIGHTS_RET, GET_SUGGESTED_INSIGHTS_LOADING  } from "../Constants"

export const get_real_time_insights = (data) => {
return {
 type:GET_REAL_TIME_INSIGHTS,
 data
}
}
export const get_real_time_insights_ret = (data) => {
return {
 type:GET_REAL_TIME_INSIGHTS_RET,
 data
}
}

export const get_real_time_insights_loading = (data) => {
return {
 type:GET_REAL_TIME_INSIGHTS_LOADING,
 data
}
}

export const get_actionable_insights = (data) => {
    return {
     type:GET_ACTIONABLE_INSIGHTS,
     data
    }
}

export const get_actionable_insights_ret = (data) => {
    return {
     type:GET_ACTIONABLE_INSIGHTS_RET,
     data
    }
}

export const get_actionable_insights_loading = (data) => {
    return {
     type:GET_ACTIONABLE_INSIGHTS_LOADING,
     data
    }
}

export const get_suggested_insights = (data) => {
    return {
     type:GET_SUGGESTED_INSIGHTS,
     data
    }
}

export const get_suggested_insights_ret = (data) => {
    return {
     type:GET_SUGGESTED_INSIGHTS_RET,
     data
    }
}

export const get_suggested_insights_loading = (data) => {
    return {
     type:GET_SUGGESTED_INSIGHTS_LOADING,
     data
    }
}