import { combineReducers } from 'redux';

import userReducer from './userReducer';
import  authReducer from './authReducer';
import  globalReducer from './global_reducer'
import  memberReducer from './members_reducer'
import  bidding_reducer from './bidding_reducer'
import  professionals_reducer from './professionals_reducer'
import  keymanager_reducer from './keymanager_reducer';
import  salesfunnel_reducer from './salesfunnel_reducer';
import  insights_reducer from './insights_reducer';
import  bookings_reducer from './bookings_reducer';
import catalogue_reducer from './catalogue_reducer';
import catalogue_manager_reducer from "./catalogue_manager_reducer"
import users_reducer from "./users_reducer"
import follow_up_reducer from "./follow_up_reducer"
import notification_reducer from "./notification_reducer"
import funnel_reducer from './funnel_reducer';
import insuranceBookingsReducer from './insuranceBookingsReducer';
export default combineReducers({
  user_store : userReducer,
  auth_store:authReducer,
  global_store:globalReducer,
  members_store:memberReducer,
  bidding_store:bidding_reducer,
  professionals_store:professionals_reducer,
  keymanager_store:keymanager_reducer,
  salesfunnel_store:salesfunnel_reducer,
  insights_store:insights_reducer,
  bookings_store:bookings_reducer,
  catalogue_store:catalogue_reducer,
  catalogue_manager_store:catalogue_manager_reducer,
  users_store:users_reducer,
  follow_up_store:follow_up_reducer,
  notification_store:notification_reducer,
  funnel_store:funnel_reducer,
  insurance_bookings_store:insuranceBookingsReducer
})
