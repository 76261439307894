import React from 'react';
import { Card, PageHeader, Form, Input, Button, notification} from 'antd';
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import { get_url } from "../utils/common_utilities";
import { error_form_check } from '../utils/common_utilities';
import validator from 'validator';
import AntdInput from '../Components/AntdInput';

const AddMember = (props) => {
	const [form] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = React.useState('optional');
	const [move_back, set_move_back] = React.useState(false);
	const [data, set_data] = React.useState({
		name: '',
		email: '',
		mobileNumber: '',
		password: '',
		role: '',
		subRole: '',
		confirmPassword: '',
		reportingManager: {
			id: '',
			name: ''
		}
	});
	const [mcmUsers, setMcmUsers] = React.useState([{ id: '', name: '' }]);

	React.useEffect(() => {
		const headers = {
			authorization: `Bearer ${localStorage.getItem('token')}`,
		};
		
		axios
			.get(`${get_url()}/mcmUser/getAllmcmUsers`, { headers })
			.then(res => {
				if (res.data.data) {
					const filteredUsers = res.data.data.map(user => ({
						id: user._id,
						name: user.name
					}));
					setMcmUsers(filteredUsers);
				} else {
					notification.error({
						message: 'Error',
						description: 'Something went wrong'
					});
				}
			})
			.catch(err => {
				notification.error({
					message: 'Error',
					description: err.response?.data?.msg || 'Please try again'
				});
			});
	}, []);

	const [error, set_error] = React.useState({
		name: { state: false, text: '' },
		email: { state: false, text: '' },
		mobileNumber: { state: false, text: '' },
		password: { state: false, text: '' },
		confirmPassword: { state: false, text: '' },
		role: { state: false, text: '' },
		subRole: {status: false, text: ''}
	});

	React.useEffect(() => {
		if (props.add_member_ret) {
			if (props.add_member_ret.success) {
				props.global_error({
					success: true,
					message: props.add_member_ret.message,
					heading: 'Member',
				});
				set_move_back(true);
				props.get_members();
			} else {
				props.global_error({
					success: false,
					message: props.add_member_ret.message,
					heading: 'Member',
				});
			}
			props.add_member_loading();
		}
	}, [props.add_member_ret]);
	const validate = (data) => {
		const error = {
			name: { state: false, text: '' },
			email: { state: false, text: '' },
			password: { state: false, text: '' },
			mobileNumber: { state: false, text: '' },
			role: { state: false, text: '' },
			subRole: {state: false, text: ''},
			reportingManager: {state: false, text: ''}
		};
		if (data.name === '') {
			{
				error.name.text = 'Please enter member name';
				error.name.state = true;
			}
		}
		if (data.password === '') {
			{
				error.password.text = 'Please enter password';
				error.password.state = true;
			}
			props.global_error({
				success: false,
				message: 'Please enter password',
				heading: 'Password',
			});
		}
		if (data.password.length !== 0) {
			if (data.password.length < 8) {
				{
					error.password.text = 'Password must be atleast 8 characters long';
					error.password.state = true;
				}
				props.global_error({
					success: false,
					message: 'Password must be atleast 8 characters long',
					heading: 'password',
				});
			}
		}

		if (data.email === '') {
			{
				error.email.text = 'Please enter your user email';
				error.email.state = true;
			}
		}
		if (data.email !== '') {
			if (!validator.isEmail(data.email)) {
				error.email.text = 'Hmm, looks like an invalid email address';
				error.email.state = true;
			}
		}

		if (data.mobileNumber === '') {
			{
				error.mobileNumber.text = 'Please enter your phone number';
				error.mobileNumber.state = true;
			}
		}

		if (data.mobileNumber !== '') {
			if (!validator.isMobilePhone(data.mobileNumber, 'en-IN')) {
				{
					error.mobileNumber.text = 'Enter a valid mobile number';
					error.mobileNumber.state = true;
				}
				props.global_error({
					success: false,
					message: 'Enter a valid mobile number',
					heading: 'Phone number',
				});
			}
		}

		if (data.role === '') {
			{
				error.role.text = 'Please choose role for the member';
				error.role.state = true;
			}
		}

		if (data.subRole === '') {
			{
				error.subRole.text = 'Please choose sub role for the member'
				error.subRole.state = true
			}
		}
		if(data.reportingManager.name === ''){
			{
				error.reportingManager.text =  'Please choose a Reporting Manager'
				error.reportingManager.state = true
			}
		}
		return error;
	};
	const add_member = () => {
		const ret_error = validate({ ...data });
		set_error({ ...error, ...ret_error });
		// console.log(ret_error,"ret_error in add_member")
		if (!error_form_check(ret_error)) {
			// console.log("Inside Eror_form_check")
			//    props.execute_captcha()
			props.add_member(
				Object.assign(
					{},
					{
						email: data.email,
						password: data.password,
						mobileNumber: data.mobileNumber,
						name: data.name,
						passwordConfirm: data.confirmPassword,
						role: data.role,
						subRole: data.subRole,
						reportingManager: data.reportingManager
						// token:props.recaptchaToken
					}
				)
			);
		}
	};

	if (move_back) {
		return <Redirect to="/dashboard/members" />;
	}
	// console.log(data,"data in Add Member")
	return (
		<React.Fragment>
			<Card>
				<PageHeader
					className="site-page-header"
					onBack={() => set_move_back(true)}
					title="Add new member"
					subTitle=""
				/>
				<Form
					form={form}
					layout="vertical"
					initialValues={{
						requiredMark,
					}}
					onValuesChange={(val) => console.log(val)}
					// requiredMark={requiredMark}
				>
					<AntdInput
						label="Name"
						value={data.name}
						error_text={error.name.text}
						toggle_error={() =>
							set_error({ ...error, name: { text: '', state: false } })
						}
						error={error.name.state}
						name="name"
						onChange={(event) =>
							set_data({ ...data, [event.target.name]: event.target.value })
						}
						placeholder="Enter member name"
					/>
					<AntdInput
						label="Email"
						value={data.email}
						error_text={error.email.text}
						toggle_error={() =>
							set_error({ ...error, email: { text: '', state: false } })
						}
						error={error.email.state}
						name="email"
						onChange={(event) =>
							set_data({ ...data, [event.target.name]: event.target.value })
						}
						placeholder="Enter member email"
					/>
					<AntdInput
						label="Phone number"
						value={data.mobileNumber}
						error_text={error.mobileNumber.text}
						toggle_error={() =>
							set_error({ ...error, mobileNumber: { text: '', state: false } })
						}
						error={error.mobileNumber.state}
						name="mobileNumber"
						onChange={(event) =>
							set_data({ ...data, [event.target.name]: event.target.value })
						}
						placeholder="Enter member phone number"
					/>
					<AntdInput
						label="Role"
						value={data.role}
						disabled={props.add_member_loading_flag}
						options={[
							{
								name: 'Admin',
							  	value: 'ADMIN'
							},
							{
								name: 'Key Manager',
							  	value: 'KEY_MANAGER'
							},
							{
								name: 'Sales Manager',
							  	value: 'SALES_MANAGER'
							},
							{
								name: 'Operations',
							  	value: 'OPERATIONS'
							},
							{
								name: 'Developer',
							  	value: 'DEVELOPER'
							},
							{
								name: 'Sales User',
							  	value: 'SALES_USER'
							},
							{
								name: 'Content Team',
							  	value: 'CONTENT_TEAM'
							},
							{
								name: 'Marketing Executive',
							  	value: 'MARKETING_EXECUTIVE'
							}
						]}
						loading={props.add_member_loading_flag}
						error_text={error.role.text}
						toggle_error={() =>
							set_error({ ...error, role: { text: '', state: false } })
						}
						error={error.role.state}
						name="role"
						type="select"
						onChange={(val) => set_data({ ...data, role: val })}
						placeholder="Choose role"
					/>
					<AntdInput
						label="Sub Role"
						value={data.subRole}
						disabled={props.add_member_loading_flag}
						options={[
							{
								name: 'Verification Team',
							  	value: 'VERIFICATION_TEAM'
							},
							{
								name: 'Medical Team',
							  	value: 'MEDICAL_TEAM'
							},
							{
								name: 'Hospital Team',
							  	value: 'HOSPITAL_TEAM'
							},
							{
								name: 'Claims Team',
							  	value: 'CLAIMS_TEAM'
							},
							{
								name: 'Developer',
							  	value: 'DEVELOPER'
							},
							{
								name: 'Accounts Team',
							  	value: 'ACCOUNTS_TEAM'
							},
							{
								name: 'Admin',
							  	value: 'ADMIN'
							}
						]}
						loading={props.add_member_loading_flag}
						error_text={error.subRole.text}
						toggle_error={() =>
							set_error({ ...error, subRole: { text: '', state: false } })
						}
						error={error.subRole.state}
						name="subRole"
						type="select"
						onChange={(val) => set_data({ ...data, subRole: val })}
						placeholder="Choose Sub Role"
					/>
					<AntdInput
						label="Reporting Manager"
						value={data.reportingManager.name}  
						disabled={props.add_member_loading_flag}
						options={
							mcmUsers.map((user) => ({
							name: user.name,  
							value: user.id,   
							}))
						}
						loading={props.add_member_loading_flag}
						error_text={error.subRole.text}
						toggle_error={() =>
							set_error({ ...error, reportingManager: { text: '', state: false } })
						}
						error={error.subRole.state}
						name="reportingManager"
						type="select"
						onChange={(val) => {
							const selectedUser = mcmUsers.find(user => user.id === val);
							set_data({
							...data,
							reportingManager: {
								reportingManagerID: selectedUser.id,
								reportingManagerName: selectedUser.name,
							},
							});
						}}
						placeholder="Choose Reporting Manager"
					/>
					<Form.Item
						name="password"
						label="Password"
						value={data.password}
						// name="password"
						onChange={(event) =>
							set_data({ ...data, password: event.target.value })
						}
						rules={[
							{
								required: true,
								message: 'Please input your password!',
							},
						]}
						hasFeedback
					>
						<Input.Password />
					</Form.Item>
					<Form.Item
						// name="confirm"
						label="Confirm Password"
						dependencies={['password']}
						name="confirmPassword"
						onChange={(event) =>
							set_data({ ...data, confirmPassword: event.target.value })
						}
						hasFeedback
						value={data.confirmPassword}
						rules={[
							{
								required: true,
								message: 'Please confirm your password!',
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}

									return Promise.reject(
										'The two passwords that you entered do not match!'
									);
								},
							}),
						]}
					>
						<Input.Password />
					</Form.Item>
					<Form.Item>
						<Button
							onClick={add_member}
							loading={props.add_member_loading_flag}
							type="primary"
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</React.Fragment>
	);
};

export default AddMember;
