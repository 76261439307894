import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services, set_global_upload } from "../actions/global"
import { get_bar_chart_data, get_bar_chart_data_loading, get_prof_info, get_prof_info_loading, 
  upload_catalogue, upload_catalogue_loading, check_upload_status, check_upload_status_loading, 
  get_catalogue_list, get_catalogue_list_loading, get_catalogue_by_id, get_catalogue_by_id_loading, get_records,
  get_records_loading, update_unmatched, update_unmatched_loading, update_price_catalogue, update_price_catalogue_loading,
  get_price_comparision, get_price_comparision_loading } from "../actions/catalogue_manager"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import Panel from '../Components/Panel/Panel';
import CatalogueManagerView from "../Views/CatalogueManager"
import CatalogueView from "../Components/Panel/CatalogueView"
import { get_url_params } from "../utils/common_utilities";

class CatalogueManager extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      const catalogue_id = get_url_params('catalogue_id')
      console.log(this.props,"this.props  in Professional_container")
        return (
             <React.Fragment>
               {console.log("WTAA", this.props.catalogue_manager_store)}
                <div >
                  {(this.props.location.search==="?home") ? <React.Fragment>
                   
                   <CatalogueManagerView
                            global_error = {this.props.global_error}
                            global_store = {this.props.global_store}

                            get_bar_chart_data = {this.props.get_bar_chart_data}
                            get_bar_chart_data_loading = {this.props.get_bar_chart_data_loading}
                            get_bar_chart_data_ret = {this.props.catalogue_manager_store.get_bar_chart_data_ret}
                            get_bar_chart_data_loading_flag = {this.props.catalogue_manager_store.get_bar_chart_data_loading}
                           
                            update_price_catalogue_loading = {this.props.update_price_catalogue_loading}
                            update_price_catalogue = {this.props.update_price_catalogue}
                            update_price_catalogue_ret = {this.props.catalogue_manager_store.update_price_catalogue_ret}
                            update_price_catalogue_loading_flag = {this.props.catalogue_manager_store.update_price_catalogue_loading}
                            
                            get_services = {this.props.get_services}

                            get_records = {this.props.get_records}
                            get_records_loading = {this.props.get_records_loading}
                            get_records_ret = {this.props.catalogue_manager_store.get_records_ret}
                            get_records_loading_flag  = {this.props.catalogue_manager_store.get_records_loading}

                            get_prof_info = {this.props.get_prof_info}
                            get_prof_info_ret = {this.props.catalogue_manager_store.get_prof_info_ret}
                            get_prof_info_loading = {this.props.get_prof_info_loading}
                            get_prof_info_loading_flag = {this.props.catalogue_manager_store.get_prof_info_loading}

                            get_price_comparision_loading = {this.props.get_price_comparision_loading}
                            get_price_comparision = {this.props.get_price_comparision}
                            get_price_comparision_loading_flag = {this.props.catalogue_manager_store.get_price_comparision_loading}
                            get_price_comparision_ret = {this.props.catalogue_manager_store.get_price_comparision_ret}

                   />
                  </React.Fragment>:(this.props.location.search==="?upload-catalogue")   ?   <React.Fragment>
                                <Panel
                                       global_error = {this.props.global_error}
                                       global_store = {this.props.global_store}
                                      
                                       set_global_upload = {this.props.set_global_upload}
                                       upload_file = {this.props.global_store.upload_file}

                                       get_services = {this.props.get_services}

                                    

                                     
                                       get_catalogue_list = {this.props.get_catalogue_list}
                                       get_catalogue_list_loading = {this.props.get_catalogue_list_loading}
                                       get_catalogue_list_ret = {this.props.catalogue_manager_store.get_catalogue_list_ret}
                                       get_catalogue_list_loading_flag = {this.props.catalogue_manager_store.get_catalogue_list_loading}

                                       check_upload_status = {this.props.check_upload_status}
                                       check_upload_status_loading_flag = {this.props.catalogue_manager_store.check_upload_status_loading}
                                       check_upload_status_ret = {this.props.catalogue_manager_store.check_upload_status_loading}
                                       check_upload_status_loading = {this.props.check_upload_status_loading}

                                       upload_catalogue = {this.props.upload_catalogue}
                                       upload_catalogue_loading = {this.props.upload_catalogue_loading}
                                       upload_catalogue_ret = {this.props.catalogue_manager_store.upload_catalogue_ret}
                                       upload_catalogue_loading_flag = {this.props.catalogue_manager_store.upload_catalogue_loading}

                                />
                  </React.Fragment>:(!!catalogue_id)?<React.Fragment>
                   
                        <CatalogueView 
                              
                              update_unmatched = {this.props.update_unmatched}
                              update_unmatched_ret  = {this.props.catalogue_manager_store.update_unmatched_ret}
                              update_unmatched_loading = {this.props.update_unmatched_loading}
                              update_unmatched_loading_flag = {this.props.catalogue_manager_store.update_unmatched_loading}


                              get_catalogue_by_id = {this.props.get_catalogue_by_id}
                              get_catalogue_by_id_loading  = {this.props.get_catalogue_by_id_loading}
                              get_catalogue_by_id_ret = {this.props.catalogue_manager_store.get_catalogue_by_id_ret}
                              get_catalogue_by_id_loading_flag = {this.props.catalogue_manager_store.get_catalogue_by_id_loading}

                              global_error = {this.props.global_error}
                              global_store = {this.props.global_store}
                        />
                  </React.Fragment>:<React.Fragment></React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  insights_store:state.insights_store,
  catalogue_manager_store:state.catalogue_manager_store,
  global_error_ret:state.global_store.global_error_ret,
  global_store:state.global_store
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
  get_bar_chart_data,
  get_bar_chart_data_loading,
  get_prof_info,
  get_prof_info_loading,
  upload_catalogue,
  upload_catalogue_loading,
  set_global_upload,
  check_upload_status,
  check_upload_status_loading,
  get_catalogue_list,
  get_catalogue_list_loading,
  get_catalogue_by_id,
  get_catalogue_by_id_loading,
  get_records,
  get_records_loading,
  update_unmatched,
  update_unmatched_loading,
  update_price_catalogue,
  get_price_comparision,
  get_price_comparision_loading,
  update_price_catalogue_loading
 })(CatalogueManager)
