import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import {
  Card,
  PageHeader,
  Form,
  Input,
  Button,
  Modal,
  Table,
  Row,
  Col,
  Tabs,
  message,
  Checkbox,
  Select,
  Dropdown,
  Menu,
  Space,
} from "antd";
import Search from "antd/lib/input/Search";

import { error_form_check } from "../../utils/common_utilities";
import validator from "validator";
import AntdInput from "../../Components/AntdInput";
import SearchLocation from "../../Components/SearchLocation";
import BidsList from "../BidsList";
import { SearchOutlined, SolutionOutlined } from "@ant-design/icons";
import AddService from "./../KeyManager/AddService";
import ProfessionalService from "./ProfessionalService";
import HospitalService from "./HospitalService";
import TimeSlots from "./TimeSlots";
import { Option } from "antd/lib/mentions";

const { TabPane } = Tabs;

const MockBidding = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [timeSlots, setTimeSlots] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [userReportRetData, setUserReportRetData] = useState(null)

  const [data, set_data] = React.useState({
    speciality: "",
    service: "",
    location: "",
    lng: "",
    lat: "",
    dPrice: ""
  });
  const [doctorsData, set_doctorsData] = React.useState([]);
  const [searchData, set_searchData] = React.useState("");
  const [value, set_value] = React.useState("");
  const [add_service_flag, set_add_service_flag] = React.useState(false);
  const [error, set_error] = React.useState({
    speciality: { state: false, text: "" },
    service: { state: false, text: "" },
    location: { state: false, text: "" },
    lng: { state: false, text: "" },
    lat: { state: false, text: "" },
  });

  const [errorRow, set_errorRow] = useState({
    amount: { state: false, text: "" },
    dPrice: { state: false, text: "" },
    technology: { state: false, text: "" },
    addOn: { state: false, text: "" },
    specialOffers: { state: false, text: "" }
  });


  const [mrpList, setMrpList] = useState([])

  useEffect(() => {
    if (props.get_doctors_ret) {
      if (props.get_doctors_ret.success) {
        set_doctorsData([
          ...props.get_doctors_ret.data.map((item) => {
            console.log(item, "CUSTOM_BOOKINGS_GET_DATA");
            return {
              name: item.isHospitalDoctor ? item.doctors[0].name : item.name,
              price:  <AntdInput
                        loading={props.create_custom_solution_loading_flag}
                        disabled={props.create_custom_solution_loading_flag}
                        error_text={errorRow.amount.text}
                        toggle_error={() =>
                          set_errorRow({ ...errorRow, amount: { text: "", state: false } })
                        }
                        error={errorRow.amount.state}
                        name="MRP"
                        onChange={(e) => {
                          let mrpL = mrpList
                          let index = mrpList.findIndex(mrp => mrp.index === item._id)
                          if (index > -1)
                            mrpL[index].mrp = e.target.value 
                          else 
                            mrpL.push({mrp: e.target.value, index: item._id})
                          setMrpList(mrpL)
                        }}
                        placeholder="Enter MRP"
                      />,
              rating: item.rating ? item.rating.toFixed(2) : "",
              experience: item.isHospitalDoctor
                ? item.doctors[0].experience
                : "---",
              speciality: item.isHospitalDoctor
                ? item.doctors[0].department
                : "---",
              address: item.address ? `${item.address.substring(0, 20)}...` : "---",
              locality: item.locality ? item.locality : "---",
              key: item._id,
              // selected: <Checkbox
              //   name="selected"
              //   key={item._id}
              //   id={item._id}
              //   loading={props.create_custom_solution_loading_flag}
              //   disabled={props.create_custom_solution_loading_flag}
              //   onChange={(e) => {
              //     rowSelection(item, e.target.checked)
              //   }}
              // />,
              userType: item.userType,
              imageUrl: item.imageUrl,
              hospitalId: item.hospitalId,
              doctors: item.doctors,
              isHospitalDoctor: item.isHospitalDoctor,
              Availablity: <button className="timeslot-check-button" onClick={() => {
                setTimeSlots(item.timeSlots)
                setIsModalVisible(true)
              }} >Check</button>
            };
          }),
        ]);
      } else {
        props.global_error({
          success: false,
          message: props.get_doctors_ret.message,
          heading: "No Doctor Available",
        });
      }
      props.get_doctors_loading();
    }

    // console.log(doctorsData, "doctors with");
  }, [props.get_doctors_ret]);

  useEffect(()=>{
    if(!!props.get_medical_details_ret){
      // console.log(props.get_medical_details_ret,"GET_USER_REPORT_RET Status")
       if(props.get_medical_details_ret.success){
          setUserReportRetData(props.get_medical_details_ret.data)
       }
    }       
  },[props.get_medical_details_ret])
  
  useEffect(()=>{
    if(!!props.create_custom_solution_ret){
      console.log(props.create_custom_solution_ret,"CREATE_CUSTOM_SOLUTION_RET Status")

       if(props.create_custom_solution_ret.success){
         message.success(props.create_custom_solution_ret.message || "Link Sent Successfully")
        // props.global_error({
        //   success: true,
        //   message: props.create_custom_solution_ret.message || "Link Sent Successfully",
        //   heading: "Custom Booking",
        // });
        console.log(props.create_custom_solution_ret.data,"CREATE_CUSTOM_SOLUTION_RET DATA")
       } else {
        message.error(props.create_custom_solution_ret.message || "Could not send link")
        // props.global_error({
        //   success: false,
        //   message: props.create_custom_solution_ret.message || "Could not send link",
        //   heading: "Custom Booking",
        // });  
       }

       props.create_custom_solution_loading()
    }       
  },[props.create_custom_solution_ret])
  
  // const rowSelection = (item, flag) => {
  //   let selecteds = selectedRows
  //   if(!flag) {
  //     let index = selectedRows.indexOf(item)
  //     if (index >= 0) {
  //       selecteds.splice( index, 1 );
  //     }
  //   }
  //   else
  //     selecteds.push(item)
  //   setSelectedRows([...selecteds])
  // };

  const [state, set_state] = React.useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: '',
    searchedColumn: ''
  })
  
  const  handleResetF = clearFilters => {
    clearFilters();
    set_state({ searchText: '' });
  };

    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              state.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearchF(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearchF(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleResetF(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => state.searchInput.select(), 100);
        }
      },
      render: text =>
        state.searchedColumn === dataIndex ? (
          // <Highlighter
          //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          //   searchWords={[searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ''}
          // />
          <b>{text}</b>
        ) : (
          text
        ),
    });
  
    const handleSearchF = (selectedKeys, confirm, dataIndex) => {
      confirm();
      set_state({
        ...state,
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
  
    const handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };
  

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
      console.log("SELCTED_ROWS", selectedRowKeys)
    },
  };

  const validate = (data) => {
    const error = {
      lat: { state: false, text: "" },
      lng: { state: false, text: "" },
      location: { state: false, text: "" },
      speciality: { state: false, text: "" },
      service: { state: false, text: "" },
    };
    if (data.speciality === "") {
      {
        error.speciality.text = "Please choose a speciality";
        error.speciality.state = true;
      }
    }
    if (data.service === "") {
      {
        error.service.text = "Please choose a service";
        error.service.state = true;
      }
    }
    if (data.location === "") {
      {
        error.location.text = "Please choose a location";
        error.location.state = true;
      }
    }
    return error;
  };

  const validateRow = (data) => {

    const error = {
      amount: { state: false, text: "" },
      dPrice: { state: false, text: "" }
    };
    if (!data.discrountPrice) {
      {
        error.dPrice.text = "Please enter Discount Price";
        error.dPrice.state = true;
        message.error("Discount Price Cannot be empty");
      }
    } else if (isNaN(data.discrountPrice)) {
      {
        error.dPrice.text = "Discount price must be a number";
        error.dPrice.state = true;
        message.error("Discount Price must be a number");
      }
    }
    if (data.mrp === 0) {
      {
        error.amount.text = "Please enter amount";
        error.amount.state = true;
        message.error("MRP Cannot be empty");
      }
    } else if (isNaN(data.mrp)) {
      error.amount.text = "Amount must be a number";
      error.amount.state = true;
      message.error("MRP must be a number");
    }
    return error;
  }

  const handleSearch = (searchText) => {
    const filteredEvents = doctorsData.filter(({ name }) => {
      name = name.toLowerCase();
      return name.includes(searchText.toLowerCase());
    });

    set_searchData(filteredEvents);
  };

  const handleModal = () => {
    set_add_service_flag(false);
  };

  const set_columns = [
    // {
    //   title: "Selected",
    //   dataIndex: "selected",
    //   key: "selected",
    //   width: "6%",
    //   align: "left",
    // },

    {
      title: "Hospital",
      dataIndex: "name",
      key: "name",
      width: "8%",
      align: "left",
      // ...getColumnSearchProps('name', "Search Professional Name", 'name'),
      // ellipsis: true,
    },

    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      width: "4%",
      align: "left",
    },

    {
      title: "Locality",
      dataIndex: "locality",
      key: "locality",
      width: "5%",
      align: "left",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "5%",
      align: "left",
    },

    {
      title: "Availablity",
      dataIndex: "Availablity",
      key: "Availablity",
      width: "5%",
      align: "center",
    },
  ];

  const createLink = () => {
    const services = []
    let errs
    selectedRows.map((row, index) => {
      console.log(row, "CUSTOM_BOOKINGS_ROW_DATA")
      let mrp = mrpList.find(mrp => mrp.index === row.key)
      let selectService = {
        professionalId: row.key,
        specialOffer: row.offers,
        tech: row.tech,
        addOn: row.addOns,
        mrp: mrp ? mrp.mrp : 0,
        discrountPrice: row.dPrice
      }
      services.push(selectService)
      errs = validateRow(selectService)
    })
    const solutionData = {
      serviceId: props.services_value,
      services: services,
      user: props.user,
    }
    if(userReportRetData)
      solutionData.userReportId = userReportRetData._id
    set_errorRow({...errorRow, ...errs});
    if(!error_form_check(errs)){
      props.create_custom_solution(solutionData)
    }
    console.log(solutionData, "CUSTOM_BOOKINGS_RES_DATA")
    console.log(selectedRows, "SELECTED_ROWS")
  }

  return (
    <div className="funnel-mockbidding-container">
      <Modal className="timeslots-modal-container" title="Select Time Slot" visible={isModalVisible} 
        onOk={() => setIsModalVisible(false)} 
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="submit" className="timeslots-modal-btn" type="primary" onClick={() => setIsModalVisible(false)}
          >
            OK
          </Button>,
        ]}
      >
        <TimeSlots timeSlots={timeSlots} />
      </Modal>
      <Card
        title="Mock Bidding"
        bordered={false}
        headStyle={{ backgroundColor: "#F0F2F5" }}
        bodyStyle={{ height: "62rem" }}
      >
        <Form
          // form={form}
          layout="vertical"
        >
          <Row gutter={[32, 0]} align="bottom">
            <Col span={9}>
              <AntdInput
                label="Speciality"
                value={props.specialities_value}
                disabled={props.specialities_disabled}
                options={props.specialities_options}
                loading={props.specialities_loading}
                error_text={error.speciality.text}
                toggle_error={() =>
                  set_error({
                    ...error,
                    speciality: { text: "", state: false },
                  })
                }
                error={error.speciality.state}
                name="speciality"
                type="select"
                onChange={(val) => {
                  props.specialities_change(val);
                  set_data({
                    ...data,
                    speciality: val,
                  });
                }}
                placeholder="Choose speciality"
              />
            </Col>

            <Col span={9}>
              <AntdInput
                label="Service Name"
                value={props.services_value}
                options={props.services_options}
                loading={props.services_loading}
                disabled={props.service_disabled}
                error_text={error.service.text}
                toggle_error={() =>
                  set_error({ ...error, service: { text: "", state: false } })
                }
                error={error.service.state}
                name="service"
                type="select"
                onChange={(val) => {
                  set_data({
                    ...data,
                    service: val,
                  });
                  props.services_change(val);

                  console.log(doctorsData, "doctors with ");
                }}
                placeholder="Choose service"
              />
            </Col>

            <Col span={6}>
              <Form.Item>
                <Button onClick={() => set_add_service_flag(true)} type="link">
                  Add service
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Modal
            width="90rem"
            title="Add Service"
            visible={add_service_flag}
            onCancel={() => set_add_service_flag(false)}
            footer={null}
          >
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span>
                    <SolutionOutlined />
                    Add new speciality
                  </span>
                }
                key="1"
              >
                <Col span={24}>
                  <HospitalService
                    update_catalogue={props.update_catalogue}
                    update_catalogue_ret={props.update_catalogue_ret}
                    update_catalogue_loading={props.update_catalogue_loading}
                    update_catalogue_loading_flag={
                      props.update_catalogue_loading_flag
                    }
                    get_doctors={props.get_doctors}
                    get_doctors_loading={props.get_doctors_loading}
                    get_doctors_loading_flag={props.get_doctors_loading_flag}
                    get_doctors_ret={props.get_doctors_ret}
                    global_store={props.global_store}
                    get_services={props.get_services}
                    global_error={props.global_error}
                  />
                </Col>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <SolutionOutlined />
                    Add new service to master catalogue
                  </span>
                }
                key="2"
              >
                <AddService
                  add_service_loading={props.add_service_loading}
                  add_service={props.add_service}
                  add_service_ret={props.add_service_ret}
                  add_service_loading_flag={props.add_service_loading_flag}
                  handle_modal={handleModal}
                  get_services={props.get_services}
                  global_store={props.global_store}
                  global_error={props.global_error}
                  mock_bidding={true}
                />
              </TabPane>

              <TabPane
                tab={
                  <span>
                    <SolutionOutlined />
                    Add to doctors catalogue
                  </span>
                }
                key="3"
              >
                <Col span={24}>
                  <ProfessionalService
                    update_catalogue={props.update_catalogue}
                    update_catalogue_ret={props.update_catalogue_ret}
                    update_catalogue_loading={props.update_catalogue_loading}
                    update_catalogue_loading_flag={
                      props.update_catalogue_loading_flag
                    }
                    get_doctors={props.get_doctors}
                    get_doctors_loading={props.get_doctors_loading}
                    get_doctors_loading_flag={props.get_doctors_loading_flag}
                    get_doctors_ret={props.get_doctors_ret}
                    global_store={props.global_store}
                    get_services={props.get_services}
                    global_error={props.global_error}
                  />
                </Col>
              </TabPane>
            </Tabs>
          </Modal>
          <Row className="selected-hospitals-list" gutter={[24, 0]}>
            {selectedRows.map((selectedHospital, index) => <Col key={index} span={10}>
              <span>{selectedHospital.name}
                {/* <button onClick={(e) => {
                  e.preventDefault()
                  let selecteds = selectedRows
                  selecteds.splice( index, 1 )
                  setSelectedRows([...selecteds])
                  if(document.getElementById(selectedHospital._id))
                    document.getElementById(selectedHospital._id).checked = false
                }}>x</button> */}
              </span>
            </Col>)}
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={16}>
              <Input
                placeholder="Enter Hospital's name"
                onChange={(e) => {
                  e.preventDefault()
                  set_state({searchText: e.target.value})
                }}
                // onSearch={handleSearch}
                size="middle"
                allowClear
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                columns={set_columns}
                dataSource={doctorsData}
                scroll={{ x: 0, y: 330 }}
                rowClassName={(record) => {
                  if(state.searchText !== "") {
                      let name = record.name.toLowerCase();
                      return name.includes(state.searchText.toLowerCase()) ? "cursor-pointer" : "row-disp-none cursor-pointer"
                  } else
                  return "cursor-pointer"
                }}
                pagination={false}
                loading={
                  props.get_doctors_loading_flag || props.get_key_prof_loading_flag
                }
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                  columnWidth: "2%",
                  sorter: {
                    multiple: 1
                  }
                }}
                size="small"
                expandable={{ columnWidth: "3%"}}
                expandedRowRender={(record) => {
                  let flag = true
                  if(state.searchText !== "") {
                    let name = record.name.toLowerCase();
                    flag = name.includes(state.searchText.toLowerCase()) ? true : false
                } else
                flag = true
                  // get_doctors(record)
                  return (
                   <Row className={flag ? "" : "row-disp-none"} gutter={[18, 0]}>
                      <Col className="booking-add-ons" span={6}>
                        <AntdInput
                          loading={props.create_custom_solution_loading_flag}
                          disabled={props.create_custom_solution_loading_flag}
                          onChange={(e) => record.tech = e.target.value}
                          placeholder="Technology/Technique"
                        />
                      </Col>
                      <Col className="booking-add-ons" span={6}>
                        <label>Add On's</label>
                        <Select
                          mode="multiple"
                          labelInValue
                          loading={props.create_custom_solution_loading_flag}
                          disabled={props.create_custom_solution_loading_flag}
                          onChange={(value) => {
                            let addOns = ""
                            value.map((tech) => addOns += tech.label + ",")
                            record.addOns = addOns
                          }}
                        >
                          <Option value="1">Home delivery of medicines</Option>
                          <Option value="2">Pre-surgical tests at home</Option>
                          <Option value="3">RT-PCR at home</Option>
                          <Option value="4">Post surgery diet plan</Option>
                          <Option value="5">Free follow up consultations post IPD</Option>
                          <Option value="6">Nursing services-24x7</Option>
                        </Select>
                      </Col>
                      <Col className="booking-add-ons" span={6}>
                        <label>Special Offers</label>
                        <Select
                          mode="multiple"
                          labelInValue
                          loading={props.create_custom_solution_loading_flag}
                          disabled={props.create_custom_solution_loading_flag}
                          onChange={(value) => {
                            let offers = ""
                            value.map((tech) => offers += tech.label + ",")
                            record.offers = offers
                          }}
                        >
                          <Option value="1">Pick-up & Drop Facility</Option>
                          <Option value="2">Free OPD</Option>
                          <Option value="3">Free ward upgrade</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <AntdInput
                          loading={props.create_custom_solution_loading_flag}
                          disabled={props.create_custom_solution_loading_flag}
                          error_text={errorRow.dPrice.text}
                          toggle_error={() =>
                            set_errorRow({ ...errorRow, dPrice: { text: "", state: false } })
                          }
                          error={errorRow.dPrice.state}
                          onChange={(e) => record.dPrice = e.target.value}
                          placeholder="Discounted Price"
                        />
                      </Col>
                    </Row>
                  )
                }}
              />
            </Col>

            <Col span={24}>
              <Form.Item>
                <Button
                  onClick={() => createLink()}
                  loading={props.create_custom_solution_loading_flag}
                  disabled={props.create_custom_solution_loading_flag || selectedRows.length === 0}
                  type="primary"
                  style={{margin: "2rem"}}
                >
                  Send Insight to hospitals
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      {/* {(!!bids_list) && <div className="u-margin-top-small">
    <BidsList   
        bids={bids_list}
    />
  </div>   }  */}
    </div>
  );
};
export default MockBidding