import React from "react"
import { Table, Input, Button, Space, Card, Form, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, get_time} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import Bookings from "../../Containers/Bookings";

const { Option } = Select

// import Map from "./Map.js"


const UsersList = (props) => {
  const {form} = Form.useForm()
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [get_users_params, set_get_users_params] = React.useState({
           page:1,
           limit:10,
          
           professional_id:props.professional_id,
           searchParams:{
            userName:'',
            professionalName:''
           },
           filters:{
            
           }
      })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [speciality, set_speciality] = React.useState([])
      const [list, set_list] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)

    React.useEffect(()=>{
       props.get_users({...get_users_params})
    },[])

    React.useEffect(()=>{
      if(!!props.get_users_ret){
     
         if(props.get_users_ret.success){
               console.log(props.get_users_ret,"get_professional_ret")
          set_data(modif_data([...props.get_users_ret.data]))
          set_get_users_params({...get_users_params, total:props.get_users_ret.total})
         }else{
          props.global_error({
            success:false,
            message:props.get_users_ret.message,
            heading:'Professionals'
          })
         }
          props.get_users_loading()
      }

      if(!!props.update_global_booking_ret){
        
        if(props.update_global_booking_ret.success){
          // set_payment_link(props.generate_link_ret.data)
       
          props.get_users({...get_users_params})
         props.global_error({
           success:true,
           message:props.update_global_booking_ret.message,
           heading:'Booking'
         })
        }else{
         props.global_error({
           success:false,
           message:props.update_global_booking_ret.message,
           heading:'Booking'
         })
        }
         props.update_global_booking_loading()
     }
     
         
  },[props.get_users_ret, props.update_global_booking_ret])

     const handleChange = (pagination, filters, sorter) => {
       console.log(pagination, filters, "pagination and  filters in handekChange")
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_users_params.filters}
       let updated_filters = {}
      
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === get_users_params.page) && (pagination.pageSize === get_users_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_users_params({...get_users_params, page:1,filters :{
         
           createdAt: filters.createdAt? filters.createdAt:'',
        //    level:filters.level?filters.level:'',
        //    catalogue:filters.catalogue?filters.catalogue:''
          }})
          props.get_users({...get_users_params, page:1, filters :{
           
            createdAt: filters.createdAt? filters.createdAt:'',
        //    level:filters.level?filters.level:'',
        //    catalogue:filters.catalogue?filters.catalogue:''        
  }})
        }
      }else {
        // set_get_users_params({...get_users_params, page:1, limit:pagination.pageSize})
        //     props.get_users({...get_users_params, page:1, limit:pagination.pageSize})
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_users_params({...get_users_params, page:1,searchParams:{
           ...get_users_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_users({...get_users_params, page:1,searchParams:{
          ...get_users_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            birthDate: item.birthDate,
            email: item.email,
            mobileNumber:item.mobileNumber,
            createdAt: get_time(item.createdAt),
            name:item.name,
            userType:item.userType,
            _id:item._id
            }
            prof_list.push({
          ...obj
            })
        })

    return prof_list;
    }


    const update_status = (val, data) => {
      props.update_global_booking({
        bookingId:data._id,
        refundStatus:val,
        type:'refund_booking'
      })
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_users_params({...get_users_params, limit:b, page:1})
      props.get_users({...get_users_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      console.log(a, b , "a, b in page_change")
      // console.log(a,b,get_users_params,"a and b get_users_params")
      if(true){
        set_get_users_params({...get_users_params, page:a, limit:b})
        props.get_users({...get_users_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_users_params({...get_users_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
    
        // userType:"",
        // level:"",
        // catalogue:''
      }})
      props.get_users({...get_users_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
   
        // userType:"",
        // level:"",
        // catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_users_params({...get_users_params, page:1, filters:{
       
        // userType:"",
        // catalogue:'',
        // level:''
      }})
      props.get_users({...get_users_params, page:1, filters:{
      
        // userType:"",
        // catalogue:'',
        // level:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const columns = [

      {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width:'20%',
          ...getColumnSearchProps('name', "Search name", 'name'),
          // fixed:'left'
      },
     
      {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width:'20%',
          ...getColumnSearchProps('email', "Search email", 'email'),
      },
      {
        title: 'Phone',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        width:'15%',
            ...getColumnSearchProps('mobileNumber', "Search phone", 'mobileNumber'),
      },
      {
        title: 'Birthday',
        dataIndex: 'birthDate',
        key: 'birthDate',
        width:'15%'
      },
      {
        title:'Create At',
        dataIndex:'createdAt',
        key:'createdAt',
        width:'15%',
        filters: [
          {
            text: 'Today',
            value: '1',
          },
          {
            text: 'Yesterday',
            value: '2',
          },
          {
            text: 'Last week',
            value: '7',
          }
        ]
      },
  //   {
  //     title: 'Refund Status',
  //     dataIndex: 'refundStatus',
  //     key: 'refundStatus',
  //     width:'15%',
  //     render:(item, record) => {
  //       return <div>
  //          <div className="u-align-center">
  //                 <Select value={record.refundStatus} style={{ width: 120 }} onChange={(val)=>update_status(val, record)}>
  //                   <Option disabled={record.status==="Requested"} value="Requested">Requested</Option>
  //                   <Option disabled={record.status==="Cancelled"} value="Cancelled">Cancelled</Option>
  //                   {/* <Option disabled={record.status==="Reserved"} value="Reserved">Reserved</Option> */}
  //                   {/* <Option disabled={record.status==="Failed"} value="Failed">Failed</Option> */}
  //                   <Option disabled={record.status==="Processed"} value="Processed">Processed</Option>
  //                   {/* <Option disabled={record.status==="Cancellation Requested"} value="Cancellation Requested">Cancellation Requested</Option> */}
  //                   {/* <Option disabled={record.status==="Cart"} value="Cart">Cart</Option> */}
  //                </Select>
  //             </div>
  //       </div>
  //     }
  // },
    // {
    //   title: 'Appointement Time',
    //   dataIndex: 'appointmentTime',
    //   key: 'appointmentTime'

    // },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
      width:'15%'
  }
  ]

  console.log(get_users_params,"get_users_params")
      function showTotal(total) {
        return `Total ${total} items`;
      }

   if(!!professional_selected){
        return <Redirect to={`/dashboard/catalogue-manager?catalogue_id=${professional_selected._id}`} />
   }

        return (<React.Fragment>
            <Card title="Users">
            <Form
      form={form}
      layout="vertical"
    >

</Form>
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_users_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          // scroll={{ x: 1300 }}
          pagination = {{
            showSizeChanger:true,
            limit:get_users_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_users_params.page,
            total:get_users_params.total,
            showTotal:showTotal
          }}
        //   onRow={(r) => ({
        //     onClick: (b,a) => row_click(r),
        //   })}
          />
        </Card>
        </React.Fragment>)
}
export default UsersList