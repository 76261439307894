import React from "react";
import { Tabs, Card, PageHeader, Button, Modal, Input } from "antd";
import { SolutionOutlined, FolderOpenOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import ProfileDetails from "./ProfileDetails";
import Catalogue from "./Catalogue";
import UpdateCatalogue from "./UpdateCatalogue";
import UploadVideoLinks from "./UploadVideoLinks";
import UploadImageLinks from "./UploadImageLinks";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
import UpdateInsurance from "./UpdateInsurance";

const { TabPane } = Tabs;

const ProfessionalDetails = (props) => {
  const [flag, set_flag] = React.useState(false);
  const [edit_insurance, set_edit_insurance] = React.useState(false);

  // console.log("Inside ProfessionalDetails");
  // console.log({user:props.user},"PROPSSS")

  if (flag) {
    return <Redirect to="/dashboard/professionals?professionals_list" />;
  }
  return (
    <React.Fragment>
      <Card>
        <PageHeader
          className="site-page-header"
          onBack={() => set_flag(true)}
          title="Professionals"
        />
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <SolutionOutlined />
                Profile
              </span>
            }
            key="1"
          >
            <ProfileDetails
              user={props.user}
              get_professional_byid={props.get_professional_byid}
              get_professional_byid_loading={props.get_professional_byid_loading}
              get_professional_byid_ret={props.get_professional_byid_ret}
              get_professional_byid_loading_flag={ props.get_professional_byid_loading_flag }

              get_professional_info={props.get_professional_info}
              get_professional_info_ret={props.get_professional_info_ret}
              get_professional_info_loading={props.get_professional_info_loading}
              get_professional_info_loading_flag={props.get_professional_info_loading_flag}

              update_professional_byid={props.update_professional_byid}
              update_professional_byid_loading={props.update_professional_byid_loading}
              update_professional_byid_ret={props.update_professional_byid_ret}
              update_professional_byid_loading_flag={props.update_professional_byid_loading_flag}

              delete_hospital_profile={props.delete_hospital_profile}
              delete_hospital_profile_loading={props.delete_hospital_profile_loading}
              delete_hospital_profile_ret={props.delete_hospital_profile_ret}
              delete_hospital_profile_loading_flag={props.delete_hospital_profile_loading_flag}

              global_error={props.global_error}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <FolderOpenOutlined />
                Catalogue
              </span>
            }
            key="2"
          >
            <Catalogue
              get_prof_specialities={props.get_prof_specialities}
              get_prof_specialities_ret={props.get_prof_specialities_ret}
              get_prof_specialities_loading={
                props.get_prof_specialities_loading
              }
              get_prof_specialities_loading_flag={
                props.get_prof_specialities_loading
              }
              get_prof_services={props.get_prof_services}
              get_prof_services_ret={props.get_prof_services_ret}
              get_prof_services_loading={props.get_prof_services_loading}
              get_prof_services_loading_flag={
                props.get_prof_services_loading_flag
              }
              global_error={props.global_error}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <FolderOpenOutlined />
                Add Service
              </span>
            }
            key="3"
          >
            <UpdateCatalogue
              update_catalogue={props.update_catalogue}
              update_catalogue_ret={props.update_catalogue_ret}
              update_catalogue_loading={props.update_catalogue_loading}
              update_catalogue_loading_flag={
                props.update_catalogue_loading_flag
              }
              global_store={props.global_store}
              get_services={props.get_services}
              global_error={props.global_error}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <FolderOpenOutlined />
                Videos
              </span>
            }
            key="4"
          >
            <UploadVideoLinks
              get_professional_info={props.get_professional_info}
              get_professional_info_ret={props.get_professional_info_ret}
              get_professional_info_loading={
                props.get_professional_info_loading
              }
              get_professional_info_loading_flag={
                props.get_professional_info_loading_flag
              }
              global_store={props.global_store}
              get_services={props.get_services}
              global_error={props.global_error}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <FolderOpenOutlined />
                Images
              </span>
            }
            key="5"
          >
            <UploadImageLinks
              get_image_title="Profile"
              image_type="PROFILE"
              get_professional_info={props.get_professional_info}
              get_professional_info_ret={props.get_professional_info_ret}
              get_professional_info_loading={
                props.get_professional_info_loading
              }
              get_professional_info_loading_flag={
                props.get_professional_info_loading_flag
              }
              global_store={props.global_store}
              get_services={props.get_services}
              global_error={props.global_error}
            />

            <UploadImageLinks
              get_image_title="Facility"
              image_type="ABOUT_HOSPITAL"
              get_professional_info={props.get_professional_info}
              get_professional_info_ret={props.get_professional_info_ret}
              get_professional_info_loading={
                props.get_professional_info_loading
              }
              get_professional_info_loading_flag={
                props.get_professional_info_loading_flag
              }
              global_store={props.global_store}
              get_services={props.get_services}
              global_error={props.global_error}
            />

            <UploadImageLinks
              get_image_title="Achievements"
              image_type="ACHEIVEMENTS"
              get_professional_info={props.get_professional_info}
              get_professional_info_ret={props.get_professional_info_ret}
              get_professional_info_loading={
                props.get_professional_info_loading
              }
              get_professional_info_loading_flag={
                props.get_professional_info_loading_flag
              }
              global_store={props.global_store}
              get_services={props.get_services}
              global_error={props.global_error}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <FolderOpenOutlined />
                Insurance
              </span>
            }
            key="6"
          >
            <UpdateInsurance
              get_professional_info={props.get_professional_info}
              get_professional_info_ret={props.get_professional_info_ret}
              get_professional_info_loading={
                props.get_professional_info_loading
              }
              get_professional_info_loading_flag={
                props.get_professional_info_loading_flag
              }

              get_insurance={props.get_insurance}
              get_insurance_ret={props.get_insurance_ret}
              get_insurance_loading={
                props.get_insurance_loading
              }
              get_insurance_loading_flag={
                props.get_insurance_loading_flag
              }

              add_insurance_to_professional={props.add_insurance_to_professional}
              add_insurance_to_professional_ret={props.add_insurance_to_professional_ret}
              add_insurance_to_professional_loading={props.add_insurance_to_professional_loading}
              add_insurance_to_professional_loading_flag={
                props.add_insurance_to_professional_loading_flag
              }

              remove_insurance_from_professional={props.remove_insurance_from_professional}
              remove_insurance_from_professional_ret={props.remove_insurance_from_professional_ret}
              remove_insurance_from_professional_loading={props.remove_insurance_from_professional_loading}
              remove_insurance_from_professional_loading_flag={
                props.remove_insurance_from_professional_loading_flag
              }

              global_store={props.global_store}
              get_services={props.get_services}
              global_error={props.global_error}
            />
          </TabPane>
        </Tabs>
      </Card>
    </React.Fragment>
  );
};

export default ProfessionalDetails;
