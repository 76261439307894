import React from "react"
import { Table, Input, Button, Space, Card,  Menu, Dropdown, Row, Col, Form  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat, get_time, getTime} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AudioPlayer from "../../Components/AudioPlayer"
import  { Link } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
import SendSms from "./SendSms"
import IvrMessageList from "./IvrMessageList"

const { SubMenu }  = Menu


const IvrCallsList = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [form] = Form.useForm();
      const [variance_info, set_variance_info] = React.useState([])
      const [selected_row , set_selected_row] = React.useState([])
      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [selected_professionals, set_selected_professionals] =React.useState([])
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [get_ivr_calls, set_get_ivr_calls] = React.useState({
           page:1,
           limit:10,
           total:10,
           token:'',
           searchParams:{
           
           },
           filters:{
            
           }
      })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [selected_call, set_selected_call] = React.useState({})
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [error, set_error] = React.useState({
        location:{state:false, text:''}
      })
      const [sms_select, set_sms_select] = React.useState({})

      const [template_sms_select, set_template_sms_select] = React.useState({})

      React.useEffect(()=>{
        if(!!props.send_template_sms_ret){
          if(props.send_template_sms_ret.success){
            props.global_error({
              success:true,
              message:props.send_template_sms_ret.message,
              heading:'SMS'
            })
  
            let arr = []
            let new_arr = [...data]
            arr = new_arr.map(item=>{
                  if(item.uuid===template_sms_select.uuid){
                    let new_sms = [...item.sms]
                    new_sms.unshift({
                      ...template_sms_select,
                      messageBody: template_sms_select.templateName
                    })
                    return {
                      ...item,
                      sms:new_sms
                    }   
                  }
                  else {
                    return item
                  }
              })
  
            set_data(arr)
  
          }else{
            props.global_error({
              success:false,
              message:props.send_template_sms_ret.message,
              heading:'SMS'
            })
          }
          props.send_template_sms_loading()
        }
       },[props.send_template_sms_ret])

    // React.useEffect(()=>{
    //     props.get_ivr_sms_token()
    // },[])

    const agents = [
      {
        agent_name: "Pawan",
        agent_number: "+918076207959"
      },
      {
        agent_name: "Priya",
        agent_number: "+919315131844"
      },
      {
        agent_name: "Chander",
        agent_number: "+919816199453"
      }
    ]

    React.useEffect(()=>{
      if(!!props.get_ivr_calls_ret){
        // console.log(props.get_ivr_calls_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_ivr_calls_ret.success){
          set_data(modif_data([...props.get_ivr_calls_ret.data]))
          set_get_ivr_calls({...get_ivr_calls, total:props.get_ivr_calls_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_ivr_calls_ret.message,
            heading:'Professionals'
          })
         }
          props.get_ivr_calls_loading()
      }
     
         
  },[props.get_ivr_calls_ret])

    React.useEffect(()=>{
          set_get_ivr_calls({...get_ivr_calls})
          props.get_ivr_calls({...get_ivr_calls})  
    },[])



 React.useEffect(()=>{
  if(!!props.send_ivr_message_ret){
        if(props.send_ivr_message_ret.success){
          props.global_error({
            success:true,
            message:props.send_ivr_message_ret.message,
            heading:'SMS'
          })

          let arr = []
          let new_arr = [...data]
          arr   = new_arr.map(item=>{
         
                if(item.uuid===sms_select.uuid){
                  let new_sms = [...item.sms]
                  new_sms.unshift({
                    ...sms_select,
                    messageBody:sms_select.message
                  })
                  return {
                    ...item,
                    sms:new_sms
                  }   
                }
                else {
                  return item
                }
           })

          set_data(arr)

        }else{
          props.global_error({
            success:false,
            message:props.send_ivr_message_ret.message,
            heading:'SMS'
          })
        }
        props.send_ivr_message_loading()
  }
 },[props.send_ivr_message_ret])

  const send_ivr_template_sms = (data) => {
    data.uuid = selected_call.uuid
    data.mobileNumber = selected_call.client_number
    data.name= 'user_name'
    set_template_sms_select(data)
    props.send_ivr_template_sms({...data})
  }

  function handleButtonClick(e) {
    console.log('click left button', e);
  }
  
  function handleMenuClick(e) {
    console.log('click', e);
  }

  const send_sms = (data) => {
    set_sms_select({
      mobileNumber:selected_call.client_number,
      uuid:selected_call.uuid,
      message:data.sentMessage,
      queryId:data.id,
      // name:selected_call.user_name
      name:'user_name'
    })
    props.send_ivr_message({
      mobileNumber:selected_call.client_number,
      uuid:selected_call.uuid,
      message:data.sentMessage,
      // name:selected_call.user_name
      name:'user_name'
    })
}



     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_ivr_calls.filters}
       let updated_filters = {}
       updated_filters.locality = "All"
       updated_filters.level = filters.level
       updated_filters.catalogue = filters.catalogue
       updated_filters.userType = filters.userType
      if(!!((pagination.current === get_ivr_calls.page) && (pagination.pageSize === get_ivr_calls.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_ivr_calls({...get_ivr_calls, page:1, filters :{
           locality:"All",
           userType: filters.userType? filters.userType:'',
           level:filters.level?filters.level:'',
           catalogue:filters.catalogue?filters.catalogue:''
          }})
          props.get_ivr_calls({...get_ivr_calls, page:1, filters :{
           locality:"All",
           userType: filters.userType? filters.userType:'',
           level:filters.level?filters.level:'',
           catalogue:filters.catalogue?filters.catalogue:''        
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_ivr_calls({...get_ivr_calls, page:1,searchParams:{
           ...get_ivr_calls.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_ivr_calls({...get_ivr_calls, page:1,searchParams:{
          ...get_ivr_calls.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {

          let timeString = getTime(item.start_time)
          const [hourString, minute] = timeString.split(":");
          const hour = +hourString % 24;
          timeString = ((hour % 12 || 12) < 10 ? `0${(hour % 12 || 12)}` : (hour % 12 || 12)) + ":" + minute + (hour < 12 ? " AM" : " PM");

            let agName = "N/A"
            if(item.agent_number)
              agName = agents.find(( {agent_number} ) =>(item.agent_number === agent_number))
              if(agName)
                agName = agName.agent_name
              else
                agName = item.agent_number
            let obj = {
            agent_name: agName,
            answered_seconds:item.answered_seconds,
            call_duration:item.call_duration,
            client_number:item.customer_number,
            department_name:item.department_name,
            detailed_description:item.detailed_description,
            status:item.business_call_type,
            recording_url:item.call_recording,
            date: getDateFormat(item.start_time),
            time: timeString,
            uuid:item.uuid,
            sms:item.sentMessages,
            id:item.id
            }
            prof_list.push({
          ...obj
            })
           
        })
    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_ivr_calls({...get_ivr_calls, limit:b, page:1})
      props.get_ivr_calls({...get_ivr_calls, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_ivr_calls,"a and b get_ivr_calls")
      // if(get_ivr_calls.page !== a){
        if(true){
        set_get_ivr_calls({...get_ivr_calls, page:a, limit:b})
        props.get_ivr_calls({...get_ivr_calls, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_ivr_calls({...get_ivr_calls,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
      props.get_ivr_calls({...get_ivr_calls,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_ivr_calls({...get_ivr_calls, page:1, filters:{
        locality:"All",
        userType:"",
        catalogue:'',
        level:''
      }})
      props.get_ivr_calls({...get_ivr_calls, page:1, filters:{
        locality:"All",
        userType:"",
        catalogue:'',
        level:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const columns_professional = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          // ellipsis: true,
          ...getColumnSearchProps('name', "Search name", 'name'),
        },
        {
            title: 'Phone number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            ...getColumnSearchProps('mobileNumber', "Search mobileNumber", 'mobileNumber'),
            // ellipsis: true,
          },
        {
          title: 'TYPE',
          dataIndex: 'type',
          key: 'type',
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          // ellipsis: true,
        },
        {
            title: 'Success',
            dataIndex: 'success',
            key: 'success',
            render:(val)=>{
                return <React.Fragment>
                    {val?"SUCCESS":"FAILED"}
                </React.Fragment>
            }
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
          },
          {
            title: 'Text Message',
            dataIndex: 'textMessage',
            key: 'textMessage',
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
          }
        ]

    const columns = [
        {
          title: 'Agent',
          dataIndex: 'agent_name',
          key: 'agent_name',
          width:'20%',
          // ellipsis: true,
          // ...getColumnSearchProps('agent_name', "Search name", 'agent_name'),
        },
        // {
        //     title: 'Department',
        //     dataIndex: 'department_name',
        //     key: 'department_name',
        //     width:'17%'
        //     // ...getColumnSearchProps('email', "Search email", 'email'),
        //     // ellipsis: true,
        //   },
        // {
        //   title: 'Description',
        //   dataIndex: 'detailed_description',
        //   key: 'detailed_description',
        //   width:'23%'
        // //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        //   // ellipsis: true,
        // },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width:'15%'
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          // ellipsis: true,
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          width:'15%'
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          // ellipsis: true,
        },
        {
          title: 'Time',
          dataIndex: 'time',
          key: 'time',
          width:'15%'
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          // ellipsis: true,
        },
          {
            title: 'Client Number',
            dataIndex: 'client_number',
            key: 'client_number',
            width:'15%',
            ...getColumnSearchProps('client_number', "Search Phonenumber", 'client_number'),
            // ellipsis: true,
          },
          {
            title: 'Recording',
            dataIndex: 'recording_url',
            key: 'recording_url',
            width:'15%',
            render:(val)=>{
              console.log(val,"val in audio url")
              return <AudioPlayer  
              streamUrl={val}
              trackTitle={"Recorded Audio"} 
              preloadType="auto" />
            }
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
          },
  
      ];

     const onSelectChange = data => {
        console.log('selectedRowKeys changed: ', data);
        // this.setState({ selectedRowKeys });
      }
      const rowSelection = {
        selectedRowKeys,
        onChange: (e,b)=>console.log(e,b),
      };

      const expand = (record,a,b) => {
        console.log(record,a,b,"record,a,b")
        if(expandedRowKeys[0]!== a.id){
           set_expandedRowKeys([a.id])
           console.log(a,"a.varianceInfo")
          
        }else{
        set_expandedRowKeys([])
        }
      }



    const debounce = (func, wait) => {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {

          timeout = null

          func(...args);
        }

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);
      }
    }
    const returnedFunction = debounce(function(data) {
     
      try{
         variance_change(data)
      }catch(e){
       console.log(e)
      }
      // setState({...state, selectPlace:data})
     
    }, 500);

    const variance_change = (data) => {
      set_get_ivr_calls({...get_ivr_calls,serviceName:data})
      props.get_ivr_calls({...get_ivr_calls,serviceName:data})
    }

    function showTotal(total) {
      return `Total ${total} items`;
    }

    console.log(props.send_ivr_message_loading_flag,"send_ivr_message_loading_flag")


        return (<React.Fragment>

          <IvrMessageList
            get_ivr_messages = {props.get_ivr_messages}
            get_ivr_messages_ret = {props.get_ivr_messages_ret}
            get_ivr_messages_loading = {props.get_ivr_messages_loading}
            get_ivr_messages_loading_flag = {props.get_ivr_messages_loading_flag}


            global_error = {props.global_error}
            global_store = {props.global_store}
          />
            <Card title="Call Records">
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_ivr_calls_loading_flag || props.get_ivr_sms_token_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:get_ivr_calls.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_ivr_calls.page,
            total:get_ivr_calls.total,
            showTotal:showTotal
          }}

          expandedRowRender= {record =>{
            console.log(record,"record")
            set_selected_call(record)
            return <React.Fragment>
                            <div key={record.id}>
                              {console.log("IvrDivRec", record)}
                <SendSms
                    send_sms = {send_sms}
                    type="ivr"
                    sms = {record.sms}
                    queryId={record.id}
                    send_sms_loading_flag = {props.send_ivr_message_loading_flag}
                    
                    get_message_templates = {props.get_message_templates}
                    get_message_templates_ret = {props.get_message_templates_ret}
                    send_template_sms = {send_ivr_template_sms}
                    send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                />
                </div>
      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record.id}
         
          />
        </Card>
        </React.Fragment>)
}
export default IvrCallsList