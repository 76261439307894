import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services } from "../actions/global"
import {  get_users, get_users_loading } from "../actions/users"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import UsersList from "../Views/Users/UsersList"


class Bookings extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      console.log(this.props,"this.props  in Professional_container")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?users-list") ? <React.Fragment>
                    <UsersList 
                       
                       get_users = {this.props.get_users}
                       get_users_ret = {this.props.users_store.get_users_ret}
                       get_users_loading_flag = {this.props.users_store.get_users_loading}
                       get_users_loading = {this.props.get_users_loading}
                      
                       global_error = {this.props.global_error}
                       global_store={this.props.global_store}
                       get_services = {this.props.get_services}
                                            />
                  </React.Fragment>:<React.Fragment>
                    </React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  users_store:state.users_store,
  global_error_ret:state.global_store.global_error_ret,
  global_store:state.global_store
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
  get_users,
  get_users_loading
 })(Bookings)
