import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services } from "../actions/global"
import { get_notifications, get_notifications_loading, set_notification_data} from "../actions/notifications"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import { get_url_params } from "../utils/common_utilities";
import NotificationList from "../Views/Notifications/NotificationList";

class Notifications extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      const catalogue_id = get_url_params('catalogue_id')
      console.log(this.props,"this.props  in Professional_container")
        return (
             <React.Fragment>
                <div>
                  {(this.props.location.search==="?list") ? <React.Fragment>
                    <NotificationList
                             get_notifications_loading = {this.props.get_notifications_loading}
                             get_notifications = {this.props.get_notifications}
                             get_notifications_ret = {this.props.notification_store.get_notifications_ret}
                             get_notifications_loading_flag = {this.props.notification_store.get_notifications_loading}

                             set_notification_data = {this.props.set_notification_data}

                             notification_data = {this.props.notification_store.notification_data}

                             global_error = {this.props.global_error}
                             global_store  = {this.props.global_store}

                        />
                  
                  </React.Fragment>:(this.props.location.search==="?upload-catalogue")   ?   <React.Fragment>
                               
                  </React.Fragment>:(!!catalogue_id)?<React.Fragment>
                   
                     
                  </React.Fragment>:<React.Fragment></React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  follow_up_store:state.follow_up_store,
  notification_store:state.notification_store,
  global_error_ret:state.global_store.global_error_ret,
  global_store:state.global_store
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,

  get_notifications,
  get_notifications_loading,
  set_notification_data
  
 })(Notifications)
