import { call, put, takeLatest } from 'redux-saga/effects'
import {  CHECK_NUMBER, REGISTER_USER, GET_DOCTORS, MAKE_BOOKING, GENERATE_LINK, GET_BOOKINGS, UPDATE_BOOKINGS,
   GET_INSIGHTS, GET_SOL_PROFESSIONALS , UPDATE_SOL_PROFESSIONAL, GLOBAL_BOOKINGS, UPDATE_GLOBAL_BOOKING, GET_REFUNDS, GET_REPORTS_BYID, GET_MEDICAL_DETAILS, 
   APPROVE_BOOKING_INSURANCE_UPDATE, 
   UPDATE_BOOKING_PROCESS,
   UPDATE_USER_DETAILS} from '../Constants'
import { check_number_ret, register_user_ret, set_user, get_doctors_ret, make_booking_ret,
   generate_link_ret, get_bookings_ret, update_bookings_ret, get_insights_ret, global_bookings_ret
   , get_sol_professionals_ret, update_sol_professional_ret, update_global_booking_ret, get_refunds_ret, get_reports_byid_ret, get_medical_details, get_medical_details_ret, approve_booking_insurance_update_ret, update_booking_process_ret, update_user_details_ret } from '../actions/bookings'
import api from '../utils/api_routes'
import store from '../store'


function* update_bookings_saga() {

  try {
     const  data = yield store.getState().bookings_store.update_bookings
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
      let type = data.type
      let api_data    
      if(type==='cancel'){
        api_data = yield call(api.bookings.cancel_booking, data, headers)
      }else if(type==="UPDATE_PAYMENT"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        api_data = yield call(api.bookings.update_payment, data, booking_id,headers)
      }else if(type==="transfer"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        data.bookingId = booking_id
        api_data = yield call(api.bookings.transfer_booking, data, headers)
      }else if(type==="send_credentials"){
        let user_id  = data.user_id
        delete data.user_id
        delete data.type
        data.userId = user_id
        api_data = yield call(api.bookings.send_credentials, data, headers)
      } else if (type==="update_city"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        data.bookingId = booking_id
        api_data = yield call(api.bookings.update_city, data, headers)
      } else if(type==="update_status"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        data.bookingId = booking_id
        api_data = yield call(api.bookings.update_bookings_status, data, headers)
      } else {
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        api_data = yield call(api.bookings.reschedule_booking, data, booking_id, headers)
      }
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_bookings_ret({
               success:true,
               message:api_data.message ||'Success'
              }))
       }else{
           yield put(update_bookings_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* get_medical_details_saga() {

  try {
     const  data = yield store.getState().bookings_store.get_medical_details
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"userId in medical_details")
      let type = data.type
      let api_data    
      if(type==='get_medical_details'){
        api_data = yield call(api.bookings.get_medical_details, data.userId, headers)
        console.log(api_data,"api_data in get_medical_details_saga")

        if(!!api_data.success){
            yield put(get_medical_details_ret({
              success:true,
              data: api_data.data,
              message:api_data.message ||'Success'
            }))
         }else{
            yield put(get_medical_details_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
            }))
        }
      }
  } catch (e) {
   try{
       yield put(get_medical_details_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_medical_details_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }


 function* update_global_booking_saga() {

  try {
     const  data = yield store.getState().bookings_store.update_global_booking
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")

      let type = data.type
      if(!!type){
        delete data.type
      }
      let api_data
      if(type==="refund_booking"){
        api_data = yield call(api.bookings.update_refund_booking, data, headers)
      } else if(data.bookingStatus==="Cancelled"){
        let booking_id  = data.bookingId
        delete data.type
        data.booking_id = booking_id
        api_data = yield call(api.bookings.cancel_booking, data, headers) 
      }
      else {
        api_data = yield call(api.bookings.update_global_booking, data, headers)
      }
     
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_global_booking_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(update_global_booking_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_global_booking_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_global_booking_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }



 function* get_reports_byid_saga() {

  try {
     const  data = yield store.getState().bookings_store.get_reports_byid
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.bookings.get_reports_byid, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_reports_byid_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data
              }))
       }else{
           yield put(get_reports_byid_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_reports_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_reports_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* get_refunds_saga() {

  try {
     const  data = yield store.getState().bookings_store.get_refunds
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.bookings.get_refunds, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_refunds_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(get_refunds_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_refunds_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_refunds_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* global_bookings_saga() {

  try {
     const  data = yield store.getState().bookings_store.global_bookings
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.bookings.global_bookings, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(global_bookings_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(global_bookings_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(global_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(global_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }


 function* get_sol_professionals_saga() {

  try {
     const  data = yield store.getState().bookings_store.get_sol_professionals
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.bookings.get_sol_professionals, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_sol_professionals_ret({
               success:true,
               message:api_data.msg ||'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(get_sol_professionals_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_sol_professionals_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_sol_professionals_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* get_insights_saga() {

  try {
     const  data = yield store.getState().bookings_store.get_insights
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.bookings.get_insights, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_insights_ret({
               success:true,
               message:api_data.msg ||'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(get_insights_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* get_bookings_saga() {

 try {
    const  data = yield store.getState().bookings_store.get_bookings
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
     console.log(data,"data in get_bookings")
    const api_data = yield call(api.bookings.get_bookings, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success || !!api_data.sucess){
          yield put(get_bookings_ret({
              success:true,
              message:api_data.msg ||'Something went wrong try again later..',
              data:api_data.data,
              count:api_data.size,
              total:api_data.count
             }))
      }else{
          yield put(get_bookings_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(get_bookings_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(get_bookings_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* generate_link_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().bookings_store.generate_link
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
    const api_data = yield call(api.bookings.generate_link, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(generate_link_ret({
              success:true,
              message:api_data.msg ||'Payment link sent to the user phone number',
              data:api_data.data.short_url
             }))
      }else{
          yield put(generate_link_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(generate_link_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(generate_link_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* make_booking_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().bookings_store.make_booking
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
    const api_data = yield call(api.bookings.make_booking, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(make_booking_ret({
              success:true,
              message:api_data.msg ||'Booking Created',
              data:api_data.data
             }))
      }else{
          yield put(make_booking_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(make_booking_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(make_booking_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* get_doctors_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().bookings_store.get_doctors
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
    const api_data = yield call(api.bookings.get_doctors, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(get_doctors_ret({
              success:true,
              message:api_data.message ||'Something went wrong try again later..',
              data:api_data.data
             }))
      }else{
          yield put(get_doctors_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(get_doctors_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(get_doctors_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}


function* check_number_saga() {
   console.log("Inside get_members_saga")

  try {
     const  data = yield store.getState().bookings_store.check_number
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     const api_data = yield call(api.bookings.check_number, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success){
           yield put(check_number_ret({
               success:true,
               message:api_data.message ||'Success',
               data:api_data.data
              }))
            yield put(set_user({
              data:{...api_data.data}
            }))
       }else{
           yield put(check_number_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(check_number_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(check_number_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* update_sol_professional_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().bookings_store.update_sol_professional
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
     console.log(data,"data in register_user_saga")

     let type = data.type
     if(!!type){
       delete data.type
     }
     let api_data = {}
     
     if(type==="update_all"){
         api_data = yield call(api.bookings.update_all_sol_professional, data, headers)
     }else {
         api_data = yield call(api.bookings.update_sol_professional, data, headers)
     }
    
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(update_sol_professional_ret({
              success:true,
              message:api_data.message ||'User successfully registered',
              data:api_data.data
             }))
      }else{
          yield put(update_sol_professional_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(update_sol_professional_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(update_sol_professional_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* register_user_saga() {
   console.log("Inside get_members_saga")

  try {
     const  data = yield store.getState().bookings_store.register_user
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in register_user_saga")
     const api_data = yield call(api.bookings.register_user, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success){
           yield put(register_user_ret({
               success:true,
               message:api_data.message ||'User successfully registered',
               data:api_data.data
              }))
       }else{
           yield put(register_user_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(register_user_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(register_user_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* approve_booking_insurance_update_sage() {

  try {
     const  data = yield store.getState().bookings_store.approve_booking_insurance_update
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      let api_data    
      api_data = yield call(api.bookings.approve_booking_insurance_update, data, headers)

     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(approve_booking_insurance_update_ret({
               success:true,
               message:api_data.message ||'Success'
              }))
       }else{
           yield put(approve_booking_insurance_update_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
    yield put(approve_booking_insurance_update_ret({
      success:false,
      message:'Something went wrong try again later..',
      data:[]
     }))
    }
}

function* update_booking_process_saga() {

  try {
     const  data = yield store.getState().bookings_store.update_booking_process
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      let api_data    
      api_data = yield call(api.bookings.update_booking_process, data, headers)

     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_booking_process_ret({
               success:true,
               message:api_data.message ||'Success'
              }))
       }else{
           yield put(update_booking_process_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
    yield put(update_booking_process_ret({
      success:false,
      message:'Something went wrong try again later..',
      data:[]
     }))
    }
}

function* update_user_details_saga() {

  try {
     const  data = yield store.getState().bookings_store.update_user_details
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      let api_data    
      api_data = yield call(api.bookings.update_user_details, data, headers)

     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_user_details_ret({
               success:true,
               message:api_data.message ||'Success'
              }))
       }else{
           yield put(update_user_details_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
    yield put(update_user_details_ret({
      success:false,
      message:'Something went wrong try again later..',
      data:[]
     }))
    }
}

export const bookings_saga = [
   takeLatest(CHECK_NUMBER, check_number_saga),
   takeLatest(REGISTER_USER, register_user_saga),
   takeLatest(GET_DOCTORS, get_doctors_saga),
   takeLatest(MAKE_BOOKING, make_booking_saga),
   takeLatest(GENERATE_LINK, generate_link_saga),
   takeLatest(GET_BOOKINGS, get_bookings_saga),
   takeLatest(UPDATE_BOOKINGS, update_bookings_saga),
   takeLatest(GET_INSIGHTS, get_insights_saga),
   takeLatest(GET_SOL_PROFESSIONALS, get_sol_professionals_saga),
   takeLatest(UPDATE_SOL_PROFESSIONAL, update_sol_professional_saga),
   takeLatest(GLOBAL_BOOKINGS, global_bookings_saga),
   takeLatest(UPDATE_GLOBAL_BOOKING, update_global_booking_saga),
   takeLatest(GET_REFUNDS, get_refunds_saga),
   takeLatest(GET_REPORTS_BYID, get_reports_byid_saga),
   takeLatest(GET_MEDICAL_DETAILS, get_medical_details_saga),
   takeLatest(APPROVE_BOOKING_INSURANCE_UPDATE, approve_booking_insurance_update_sage),
   takeLatest(UPDATE_BOOKING_PROCESS, update_booking_process_saga),
   takeLatest(UPDATE_USER_DETAILS, update_user_details_saga)
]

export default bookings_saga

