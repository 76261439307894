import React,{useEffect,useState} from 'react';
import { Table, PageHeader, Tabs, Card, Tag } from 'antd';
import Button from '../Buttons/index';
import {  get_url_params, for_loop } from '../../utils/common_utilities';
import { Redirect } from 'react-router';
import { SolutionOutlined, FolderOpenOutlined } from '@ant-design/icons';
const  { TabPane } = Tabs

const CatalogueView = (props) => {
    console.log(props.data);
    const [data, set_data] = useState([]);
    const [professional_data, set_professional_data] = useState({})
    const [toggle, set_toggle] = useState(false)
    const [speciality,setSpecialty] = useState([]);
    const [tab,set_tab] = useState(0);
    const [ unmatched_services, set_unmatched_services] = React.useState([])

    const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [catalogue_by_id_params, set_catalogue_by_id_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
            
           },
           filters:{
            
           }
      })


    useEffect(() => {
        console.log(props.data);
         let catalogue_id = get_url_params('catalogue_id')
         console.log(catalogue_id,"catalogue_id")
         if(!!catalogue_id){
             props.get_catalogue_by_id({
                 catalogue_id:catalogue_id
             })
         }
    }, [])

    useEffect(()=>{
        if(!!props.get_catalogue_by_id_ret){
             if(props.get_catalogue_by_id_ret.success){
              set_data(modif_data([...props.get_catalogue_by_id_ret.data.matchedServices]))
              set_unmatched_services(modify_unmatched([...props.get_catalogue_by_id_ret.data.unmatchedServices]))
              set_catalogue_by_id_params({...catalogue_by_id_params, limit:props.get_catalogue_by_id_ret.count, total:props.get_catalogue_by_id_ret.count})
             }else{
              props.global_error({
                success:false,
                message:props.get_catalogue_by_id_ret.message,
                heading:'Catalogue'
              })
             }
              props.get_catalogue_by_id_loading()
          }


          if(!!props.update_unmatched_ret){
            if(props.update_unmatched_ret.success){
                props.global_error({
                    success:true,
                    message:props.update_unmatched_ret.message,
                    heading:'Catalogue'
                  })           
                  props.get_catalogue_by_id({
                    catalogue_id:get_url_params('catalogue_id')
                })
            }else{
             props.global_error({
               success:false,
               message:props.update_unmatched_ret.message,
               heading:'Catalogue'
             })
            }
             props.update_unmatched_loading()
         }
    },[props.get_catalogue_by_id_ret, props.update_unmatched_ret])


    const specialityHandler = (e) => {
        console.log('button clicked')
        console.log(e)
    }


    const modif_data = (arr) => {
        let data = []
            for_loop(arr, (item) => {
                let obj = {
                name: item.name,
                matched: item.matched,
                homeCollection:item.homeCollection,
                category:item.category,
                price:item.price,
                serviceId:item.serviceId,
                speciality:item.speciality,
                variance:item.variance,
                _id: item._id
                }
                data.push({
              ...obj
                })
               
            })
    
        return data;
        }


        const modify_unmatched = (arr) => {
            let data = []
                for_loop(arr, (item) => {
                    let obj = {
                    name: item.name,
                    matched: item.matched,
                    homeCollection:item.homeCollection,
                    category:item.category,
                    price:item.price,
                    serviceId:item.serviceId,
                    speciality:item.speciality,
                    variance:item.variance,
                    alternatives:item.alternatives,
                    selected:false,
                    _id: item._id
                    }
                    data.push({
                  ...obj
                    })
                   
                })
        
            return data;
            }


            const tag_click = (record, alternative) =>{
                let data = [...unmatched_services]
                const updated_unmatched_services = data.map(item=>{
                    if(item._id !== record._id){
                        return {...item}
                    }else {
                        let obj = {...item}
                        let selected = {
                            _id:alternative._id,
                            service:alternative.service,
                            service_id:alternative.serviceId
                        }
                        obj.selected = selected

                        return obj
                    }
                })
                set_unmatched_services(updated_unmatched_services)
            }

            const submit_unmatched = () => {
                console.log(unmatched_services,"unmatched_services")
                let data = [...unmatched_services]
                const updated_unmatched_services = data.map(item=>{
                    let obj = {...item}
                    console.log(obj.selected,"obj.selected")
                    if(!!obj.selected){
                         obj.serviceId = obj.selected.service_id
                         obj.oldName = obj.name
                         obj.name = obj.selected.service
                         delete obj.selected
                         return obj
                    }else{
                        return obj
                    }
                })

                console.log(updated_unmatched_services,"updated_unmatched_Services")

                props.update_unmatched({
                    catalogueId:get_url_params('catalogue_id'),
                    unmatchedServices:updated_unmatched_services,
                })
            }
        

    const columns = [
        {
            title: 'Service Name',
            dataIndex: 'name',
            key: 'id',
            width: '150'

        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'id',
            width: '100',

        },
        {
            title: 'Home Collection',
            dataIndex: 'homeCollection',
            key: 'id',
            width: '100',
            render: text => text ? `True` : `False`

        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'id',
            width: '100'

        },
        {
            title: 'Variance',
            dataIndex: 'variance',
            key: 'id',
            width: '100',
            // render: (text,record) => {
            //     // console.log(record._id)
            //     return <p onClick={() => ViewHandler(record)}>View</p>
                
            // }

        },
        {
            title: 'Speciality',
            dataIndex: 'speciality',
            key: 'id',
            width: '150'

        },
    ];


    const columns_umatched = [
        {
            title: 'Service Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%'

        },
        // {
        //     title: 'Category',
        //     dataIndex: 'category',
        //     key: 'id',
        //     width: '100',

        // },
        // {
        //     title: 'Home Collection',
        //     dataIndex: 'homeCollection',
        //     key: 'id',
        //     width: '100',
        //     render: text => text ? `True` : `False`

        // },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '10%'

        },
        {
            title: 'Variance',
            dataIndex: 'variance',
            key: 'variance',
            width: '10%',
            // render: (text,record) => {
            //     // console.log(record._id)
            //     return <p onClick={() => ViewHandler(record)}>View</p>
                
            // }

        },
        {
            title: 'Speciality',
            dataIndex: 'speciality',
            key: 'speciality',
            width: '15%'
        },
        {
            title: 'Suggestions',
            dataIndex: '_id',
            key: '_id',
            width: '45%',
            render:(a,record)=>{
                return <React.Fragment>
                        {record.alternatives.map(item=>{
                            return  <React.Fragment>
                                  <Tag style={{cursor:'pointer'}} color={record.selected._id === item._id?"processing":'default'} onClick={()=>tag_click(record, item)} className="u-margin-1">{item.service}</Tag>
                                </React.Fragment>
                        })}
                </React.Fragment>
            }
        }
    ]

    console.log(unmatched_services,"unmatched_services")


    if(toggle){
        return (
           <Redirect to="/dashboard/catalogue-manager?upload-catalogue" />
        )
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => set_toggle(true)}
                title={professional_data.hospitalName}
                subTitle="Catalogue"
        >
            <div>
           <Card>
            <Tabs defaultActiveKey="1">
                <TabPane
                        tab={
                            <span onClick = {()=>set_tab(0)}>
                           <SolutionOutlined />
                              Matched Services
                            </span>
                        }
                        key="1">
                        {speciality.map(obj=> {
                    console.log(obj)
                    return (<button id={obj}  value={obj}  className="u-margin-top-small" onClick={e=> specialityHandler(e)}>{obj}</button>)
                })}      
                        <div className="u-margin-top-small">
                           <Table columns={columns} dataSource={data}/>
                        </div>
                        <div className="u-margin-top-medium text-center u-align-center">
                                    <button  onClick={e=> submit_unmatched()}>Submit</button>
                    </div>
                </TabPane>
                <TabPane
                tab={
                    <span  onClick = {()=>set_tab(0)}>
                       <FolderOpenOutlined />
                         Unmatched Services
                    </span>
                }
                key="2"
                >

                    {speciality.map(obj=> {
                    console.log(obj)
                    return (<button id={obj}  value={obj}  className="u-margin-top-small" onClick={e=> specialityHandler(e)}>{obj}</button>)
                })}      
                        <div className="u-margin-top-small">
                           <Table columns={columns_umatched} dataSource={unmatched_services}/>
                        </div>
                    <div className="u-margin-top-medium text-center u-align-center">
                                    <button  onClick={e=> submit_unmatched()}>Submit</button>
                    </div>
                 
                </TabPane>
            </Tabs>
            </Card>
                
            </div>
            
            
    </PageHeader>
        </div>
    )

}

export default CatalogueView;