import React from "react";
import formats from "./ToolbarOptions";

const renderOptions = (formatData) => {
  const { className, options } = formatData;
  return (
    <select className={className} key={className}>
      <option defaultValue></option>
      {options.map((value, index) => (
        <option key={index} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
};

const renderSingle = (formatData) => {
  const { className, value } = formatData;
  return <button className={className} value={value} key={className}></button>;
};

const CustomToolbar = () => (
  <div id="toolbar">
    {formats.map((classes, index) => (
      <span className="ql-formats" key={index}>
        {classes.map((formatData, idx) =>
          formatData.options ? renderOptions(formatData) : renderSingle(formatData)
        )}
      </span>
    ))}
  </div>
);

export default CustomToolbar;