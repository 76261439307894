import React, { useEffect } from "react"
import { Tabs, Radio, Card, Button,  } from 'antd';
import { SolutionOutlined, FolderOpenOutlined } from '@ant-design/icons';
import Publicqueries from "./Pulicqueries";
import Ivrcalls from "./Ivrcalls";
import Covidqueries from "./Covidqueries";
import Regpublicqueries from "./Regpublicqueries";
import Solutions from "./Solutions"
import FacebookQueries from "./FacebookQueries"
import BulkSend from "./BulkSend";
import ManualBidding from "./ManulalBidding"
import ManualMessages from "./ManualMessages";
import AllQueries from "./AllQueries";
import MergedQueries from "./MergedQueries";

const { TabPane } = Tabs

const Querylist = (props) => {

    const [val, set_val] = React.useState('NON_REGISTERED')
    const [syncVal, set_syncVal] = React.useState(0)

    useEffect(() => {
        if (props.sync_knowlarity_leads_ret) {
            if (props.sync_knowlarity_leads_ret.success) {
                props.global_error({
                    success: true,
                    message: props.sync_knowlarity_leads_ret.message,
                    heading: "Knowlarity",
                    });
                    set_syncVal(syncVal + 1)
            } else {
                props.global_error({
                success: false,
                message: props.sync_knowlarity_leads_ret.message,
                heading: "Knowlarity",
                });
            }
            props.sync_knowlarity_leads_loading();
        }
    }, [props.sync_knowlarity_leads_ret])

    return (<React.Fragment>
                <Card>
                      <div className="u-align-center u-margin-top-small">
                      <Radio.Group onChange={e=>{set_val(e.target.value)}} value={val}  style={{ marginBottom: 8, marginLeft:'auto', marginRight:'auto' }}>
                            <Radio.Button value="REGISTERED">Registerd Users</Radio.Button>
                            <Radio.Button value="NON_REGISTERED">Non Registerd Users</Radio.Button>
                     </Radio.Group>
                      </div>
                      <div className="colors-info-container">
                         <div className="colors-container red">
                             <span />
                             Closed
                         </div>
                         <div className="colors-container green">
                             <span />
                             New Leads
                         </div>
                         <div className="colors-container blue">
                             <span />
                             in Follow-Up
                         </div>
                     </div>
                     <div className="u-align-center sync-ivr-lead-container u-margin-bottom-medium">
                        <Button type="primary" onClick={() => props.sync_knowlarity_leads()}>Sync Knowlarity Leads</Button>
                     </div>


{val==="REGISTERED"? <Tabs defaultActiveKey="1">
                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              Public Queries
                            </span>
                        }
                        key="11">
                            <Regpublicqueries 
                                get_reg_public_queries = {props.get_reg_public_queries}
                                get_reg_public_queries_loading = {props.get_reg_public_queries_loading}
                                get_reg_public_queries_ret = {props.get_reg_public_queries_ret}
                                get_reg_public_queries_loading_flag = {props.get_reg_public_queries_loading}

                                update_status = {props.update_status}
                                update_status_loading = {props.update_status_loading}
                                update_status_ret = {props.update_status_ret}
                                update_status_loading_flag = {props.update_status_loading}


                                send_sms = {props.send_sms}
                                send_sms_ret = {props.send_sms_ret}
                                send_sms_loading = {props.send_sms_loading}
                                send_sms_loading_flag = {props.send_sms_loading_flag}

                                get_bulk_sms_list = {props.get_bulk_sms_list}
                                get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                                get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                                get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                                update_follow_up_loading = {props.update_follow_up_loading}
                                update_follow_up_loading_flag = {props.update_follow_up_loading_flag}
                                update_follow_up = {props.update_follow_up}
                                update_follow_up_ret = {props.update_follow_up_ret}

                                get_message_templates = {props.get_message_templates}
                                get_message_templates_ret = {props.get_message_templates_ret}
                                send_template_sms = {props.send_template_sms}
                                send_template_sms_ret = {props.send_template_sms_ret}
                                send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                                send_template_sms_loading = {props.send_template_sms_loading}

                                global_error = {props.global_error}
                            />
                </TabPane>

                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              Solutions Searched
                            </span>
                        }
                        key="12">
                            <Solutions 
                                get_solutions = {props.get_solutions}
                                get_solutions_loading = {props.get_solutions_loading}
                                get_solutions_ret = {props.get_solutions_ret}
                                get_solutions_loading_flag = {props.get_solutions_loading_flag}

                                send_sms = {props.send_sms}
                                send_sms_ret = {props.send_sms_ret}
                                send_sms_loading = {props.send_sms_loading}
                                send_sms_loading_flag = {props.send_sms_loading_flag}
                                
                                send_solution_sms = {props.send_solution_sms}
                                send_solution_sms_ret = {props.send_solution_sms_ret}
                                send_solution_sms_loading = {props.send_solution_sms_loading}
                                send_solution_sms_loading_flag = {props.send_solution_sms_loading_flag}

                                get_bulk_sms_list = {props.get_bulk_sms_list}
                                get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                                get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                                get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                                global_error = {props.global_error}
                                global_store = {props.global_store}
                                get_services = {props.get_services}
                            />
                </TabPane>


                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                               Manual Bidding
                            </span>
                        }
                        key="29">
                            <ManualBidding 
                                get_manual_biddings = {props.get_manual_biddings}
                                get_manual_biddings_loading = {props.get_manual_biddings_loading}
                                get_manual_biddings_ret = {props.get_manual_biddings_ret}
                                get_manual_biddings_loading_flag = {props.get_manual_biddings_loading}

                                send_sms = {props.send_sms}
                                send_sms_ret = {props.send_sms_ret}
                                send_sms_loading = {props.send_sms_loading}
                                send_sms_loading_flag = {props.send_sms_loading_flag}
                                
                                send_solution_sms = {props.send_solution_sms}
                                send_solution_sms_ret = {props.send_solution_sms_ret}
                                send_solution_sms_loading = {props.send_solution_sms_loading}
                                send_solution_sms_loading_flag = {props.send_solution_sms_loading_flag}

                                get_bulk_sms_list = {props.get_bulk_sms_list}
                                get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                                get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                                get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                                global_error = {props.global_error}
                                global_store = {props.global_store}
                                get_services = {props.get_services}
                            />
                </TabPane>

                

                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              Bulk Messages
                            </span>
                        }
                        key="13">
                            <BulkSend 
                                bulk_send = {props.bulk_send}
                                bulk_send_loading = {props.bulk_send_loading}
                                bulk_send_ret = {props.bulk_send_ret}
                                bulk_send_loading_flag = {props.bulk_send_loading_flag}



                                global_error = {props.global_error}
                                global_store = {props.global_store}
                              
                            />
                </TabPane>
               
                <TabPane
                        tab={
                            <span>
                            <SolutionOutlined />
                                Manual Messages
                            </span>
                        }
                        key="912">
                            <ManualMessages 
                                get_message_templates = {props.get_message_templates}
                                get_message_templates_ret = {props.get_message_templates_ret}
                                send_template_sms = {props.send_template_sms}
                                send_template_sms_ret = {props.send_template_sms_ret}
                                send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                                send_template_sms_loading = {props.send_template_sms_loading}

                                send_manual_template_sms = {props.send_manual_template_sms}
                                send_manual_template_sms_ret = {props.send_manual_template_sms_ret}
                                send_manual_template_sms_loading = {props.send_manual_template_sms_loading}
                                send_manual_template_sms_loading_flag = {props.send_manual_template_sms_loading_flag}

                                get_manual_sms_list  = {props.get_manual_sms_list}
                                get_manual_sms_list_loading = {props.get_manual_sms_list_loading}
                                get_manual_sms_list_ret = {props.get_manual_sms_list_ret}
                                get_manual_sms_list_loading_flag = {props.get_manual_sms_list_loading_flag}

                                global_error = {props.global_error}
                            />
                </TabPane>
            
            </Tabs>: <Tabs defaultActiveKey="123">
                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              All Queries
                            </span>
                        }
                        key="123">
                            <AllQueries 
                                get_all_queries = {props.get_all_queries}
                                get_all_queries_loading = {props.get_all_queries_loading}
                                get_all_queries_ret = {props.get_all_queries_ret}
                                get_all_queries_loading_flag = {props.get_all_queries_loading_flag}

                                update_city = {props.update_city}
                                update_city_loading = {props.update_city_loading}
                                update_city_ret = {props.update_city_ret}
                                update_city_loading_flag = {props.update_city_loading}

                                update_status = {props.update_status}
                                update_status_loading = {props.update_status_loading}
                                update_status_ret = {props.update_status_ret}
                                update_status_loading_flag = {props.update_status_loading}

                                send_sms = {props.send_sms}
                                send_sms_ret = {props.send_sms_ret}
                                send_sms_loading = {props.send_sms_loading}
                                send_sms_loading_flag = {props.send_sms_loading_flag}

                                get_bulk_sms_list = {props.get_bulk_sms_list}
                                get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                                get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                                get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                                update_follow_up_loading = {props.update_follow_up_loading}
                                update_follow_up_loading_flag = {props.update_follow_up_loading_flag}
                                update_follow_up = {props.update_follow_up}
                                update_follow_up_ret = {props.update_follow_up_ret}

                                get_message_templates = {props.get_message_templates}
                                get_message_templates_ret = {props.get_message_templates_ret}
                                send_template_sms = {props.send_template_sms}
                                send_template_sms_ret = {props.send_template_sms_ret}
                                send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                                send_template_sms_loading = {props.send_template_sms_loading}

                                get_insurance={props.get_insurance}
                                get_insurance_ret={props.get_insurance_ret}
                                get_insurance_loading={props.get_insurance_loading}
                                get_insurance_loading_flag={props.get_insurance_loading_flag}

                                get_services = {props.get_services}
                                specialities_options={props.specialities_options}
                                specialities_loading={props.specialities_loading}
                                services_options={props.services_options}

                                get_queries_by_number = {props.get_queries_by_number}
                                get_queries_by_number_loading = {props.get_queries_by_number_loading}
                                get_queries_by_number_ret = {props.get_queries_by_number_ret}
                                get_queries_by_number_loading_flag = {props.get_queries_by_number_loading_flag}

                                update_prospect = {props.update_prospect}
                                update_prospect_loading = {props.update_prospect_loading}
                                update_prospect_ret = {props.update_prospect_ret}
                                update_prospect_loading_flag = {props.update_prospect_loading}

                                user = {props.user}

                                update_query = {props.update_query}
                                update_query_loading = {props.update_query_loading}
                                update_query_ret = {props.update_query_ret}
                                update_query_loading_flag = {props.update_query_loading}

                                download_sales_leads = {props.download_sales_leads}
                                download_sales_leads_loading = {props.download_sales_leads_loading}
                                download_sales_leads_ret = {props.download_sales_leads_ret}
                                download_sales_leads_loading_flag = {props.download_sales_leads_loading_flag}

                                sync_knowlarity_leads_loading_flag = {props.sync_knowlarity_leads_loading_flag}
                                syncVal = {syncVal}

                                global_error = {props.global_error}
                                global_store = {props.global_store}
                            />
                </TabPane>
                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              Merged Queries
                            </span>
                        }
                        key="124">
                            <MergedQueries 
                                get_merged_queries = {props.get_merged_queries}
                                get_merged_queries_loading = {props.get_merged_queries_loading}
                                get_merged_queries_ret = {props.get_merged_queries_ret}
                                get_merged_queries_loading_flag = {props.get_merged_queries_loading_flag}

                                update_status = {props.update_status}
                                update_status_loading = {props.update_status_loading}
                                update_status_ret = {props.update_status_ret}
                                update_status_loading_flag = {props.update_status_loading}

                                send_sms = {props.send_sms}
                                send_sms_ret = {props.send_sms_ret}
                                send_sms_loading = {props.send_sms_loading}
                                send_sms_loading_flag = {props.send_sms_loading_flag}

                                get_bulk_sms_list = {props.get_bulk_sms_list}
                                get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                                get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                                get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                                update_follow_up_loading = {props.update_follow_up_loading}
                                update_follow_up_loading_flag = {props.update_follow_up_loading_flag}
                                update_follow_up = {props.update_follow_up}
                                update_follow_up_ret = {props.update_follow_up_ret}

                                get_message_templates = {props.get_message_templates}
                                get_message_templates_ret = {props.get_message_templates_ret}
                                send_template_sms = {props.send_template_sms}
                                send_template_sms_ret = {props.send_template_sms_ret}
                                send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                                send_template_sms_loading = {props.send_template_sms_loading}

                                get_queries_by_number = {props.get_queries_by_number}
                                get_queries_by_number_loading = {props.get_queries_by_number_loading}
                                get_queries_by_number_ret = {props.get_queries_by_number_ret}
                                get_queries_by_number_loading_flag = {props.get_queries_by_number_loading_flag}

                                global_error = {props.global_error}
                            />
                </TabPane>
                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              Public Queries
                            </span>
                        }
                        key="1">
                            <Publicqueries 
                                get_public_queries = {props.get_public_queries}
                                get_public_queries_loading = {props.get_public_queries_loading}
                                get_public_queries_ret = {props.get_public_queries_ret}
                                get_public_queries_loading_flag = {props.get_public_queries_loading_flag}

                                update_status = {props.update_status}
                                update_status_loading = {props.update_status_loading}
                                update_status_ret = {props.update_status_ret}
                                update_status_loading_flag = {props.update_status_loading}

                                send_sms = {props.send_sms}
                                send_sms_ret = {props.send_sms_ret}
                                send_sms_loading = {props.send_sms_loading}
                                send_sms_loading_flag = {props.send_sms_loading_flag}

                                get_bulk_sms_list = {props.get_bulk_sms_list}
                                get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                                get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                                get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                                get_message_templates = {props.get_message_templates}
                                get_message_templates_ret = {props.get_message_templates_ret}
                                send_template_sms = {props.send_template_sms}
                                send_template_sms_ret = {props.send_template_sms_ret}
                                send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                                send_template_sms_loading = {props.send_template_sms_loading}

                                update_prospect = {props.update_prospect}
                                update_prospect_loading = {props.update_prospect_loading}
                                update_prospect_ret = {props.update_prospect_ret}
                                update_prospect_loading_flag = {props.update_prospect_loading}
                                
                                global_error = {props.global_error}
                            />
                </TabPane>
                <TabPane
                tab={
                    <span>
                       <FolderOpenOutlined />
                       Covid Queries
                    </span>
                }
                key="2"
                >
                   <Covidqueries 
                                get_covid_queries = {props.get_covid_queries}
                                get_covid_queries_loading = {props.get_covid_queries_loading}
                                get_covid_queries_ret = {props.get_covid_queries_ret}
                                get_covid_queries_loading_flag = {props.get_covid_queries_loading}


                                update_covid_queries_loading  = {props.update_covid_queries_loading}
                                update_covid_queries = {props.update_covid_queries}
                                update_covid_queries_ret = {props.update_covid_queries_ret}
                                update_covid_queries_loading_flag = {props.update_covid_queries_loading_flag}

                                send_sms = {props.send_sms}
                                send_sms_ret = {props.send_sms_ret}
                                send_sms_loading = {props.send_sms_loading}
                                send_sms_loading_flag = {props.send_sms_loading_flag}


                                get_bulk_sms_list = {props.get_bulk_sms_list}
                                get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                                get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                                get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                                update_prospect = {props.update_prospect}
                                update_prospect_loading = {props.update_prospect_loading}
                                update_prospect_ret = {props.update_prospect_ret}
                                update_prospect_loading_flag = {props.update_prospect_loading}

                                get_message_templates = {props.get_message_templates}
                                get_message_templates_ret = {props.get_message_templates_ret}
                                send_template_sms = {props.send_template_sms}
                                send_template_sms_ret = {props.send_template_sms_ret}
                                send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                                send_template_sms_loading = {props.send_template_sms_loading}

                                global_error = {props.global_error}
                            />
                </TabPane>
                <TabPane
                tab={
                    <span>
                       <FolderOpenOutlined />
                       IVR Calls
                    </span>
                }
                key="3"
                >
                 <Ivrcalls

                    submit_query = {props.submit_query}
                    submit_query_loading = {props.submit_query_loading}
                    submit_query_ret = {props.submit_query_ret}
                    submit_query_loading_flag = {props.submit_query_loading_flag}

                    get_ivr_calls_loading = {props.get_ivr_calls_loading}
                    get_ivr_calls = {props.get_ivr_calls}
                    get_ivr_calls_loading_flag = {props.get_ivr_calls_loading_flag}
                    get_ivr_calls_ret = {props.get_ivr_calls_ret}

                    send_ivr_message = {props.send_ivr_message}
                    send_ivr_message_ret = {props.send_ivr_message_ret}
                    send_ivr_message_loading = {props.send_ivr_message_loading}
                    send_ivr_message_loading_flag = {props.send_ivr_message_loading_flag}

                    get_ivr_messages = {props.get_ivr_messages}
                    get_ivr_messages_ret = {props.get_ivr_messages_ret}
                    get_ivr_messages_loading = {props.get_ivr_messages_loading}
                    get_ivr_messages_loading_flag = {props.get_ivr_messages_loading_flag}

                    update_prospect = {props.update_prospect}
                    update_prospect_loading = {props.update_prospect_loading}
                    update_prospect_ret = {props.update_prospect_ret}
                    update_prospect_loading_flag = {props.update_prospect_loading}

                    get_bulk_sms_list = {props.get_bulk_sms_list}
                    get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                    get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                    get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                    get_message_templates = {props.get_message_templates}
                    get_message_templates_ret = {props.get_message_templates_ret}
                    send_ivr_template_sms = {props.send_ivr_template_sms}
                    send_template_sms_ret = {props.send_template_sms_ret}
                    send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                    send_template_sms_loading = {props.send_template_sms_loading}

                    get_ivr_sms_token = {props.get_ivr_sms_token}
                    get_ivr_sms_token_ret = {props.get_ivr_sms_token_ret}
                    get_ivr_sms_token_loading = {props.get_ivr_sms_token_loading}
                    get_ivr_sms_token_loading_flag = {props.get_ivr_sms_token_loading_flag}

                    global_error = {props.global_error}
                    global_store = {props.global_store}

                />
                </TabPane>

                <TabPane
                tab={
                    <span>
                       <FolderOpenOutlined />
                      Facebook Queries
                    </span>
                }
                key="41"
                >
                 <FacebookQueries

                    upload_facebook_queries = {props.upload_facebook_queries}
                    upload_facebook_queries_loading = {props.upload_facebook_queries_loading}
                    upload_facebook_queries_ret = {props.upload_facebook_queries_ret}
                    upload_facebook_queries_loading_flag = {props.upload_facebook_queries_loading_flag}

                    get_facebook_queries = {props.get_facebook_queries}
                    get_facebook_queries_ret = {props.get_facebook_queries_ret}
                    get_facebook_queries_loading = {props.get_facebook_queries_loading}
                    get_facebook_queries_loading_flag = {props.get_facebook_queries_loading_flag}

                    update_facebook_data = {props.update_facebook_data}
                    update_facebook_data_ret = {props.update_facebook_data_ret}
                    update_facebook_data_loading = {props.update_facebook_data_loading}
                    update_facebook_data_loading_flag = {props.update_facebook_data_loading_flag}

                    get_upload_list = {props.get_upload_list}
                    get_upload_list_ret = {props.get_upload_list_ret}
                    get_upload_list_loading = {props.get_upload_list_loading}
                    get_upload_list_loading_flag = {props.get_upload_list_loading_flag}

                    get_bulk_sms_list = {props.get_bulk_sms_list}
                    get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
                    get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
                    get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

                    update_prospect = {props.update_prospect}
                    update_prospect_loading = {props.update_prospect_loading}
                    update_prospect_ret = {props.update_prospect_ret}
                    update_prospect_loading_flag = {props.update_prospect_loading}

                    get_message_templates = {props.get_message_templates}
                    get_message_templates_ret = {props.get_message_templates_ret}
                    send_template_sms = {props.send_template_sms}
                    send_template_sms_ret = {props.send_template_sms_ret}
                    send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                    send_template_sms_loading = {props.send_template_sms_loading}

                    global_error = {props.global_error}
                    global_store = {props.global_store}

                />
                </TabPane>

                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              Manual Messages
                            </span>
                        }
                        key="912">
                            <ManualMessages 
                                get_message_templates = {props.get_message_templates}
                                get_message_templates_ret = {props.get_message_templates_ret}
                                send_template_sms = {props.send_template_sms}
                                send_template_sms_ret = {props.send_template_sms_ret}
                                send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                                send_template_sms_loading = {props.send_template_sms_loading}

                                send_manual_template_sms = {props.send_manual_template_sms}
                                send_manual_template_sms_ret = {props.send_manual_template_sms_ret}
                                send_manual_template_sms_loading = {props.send_manual_template_sms_loading}
                                send_manual_template_sms_loading_flag = {props.send_manual_template_sms_loading_flag}

                                get_manual_sms_list  = {props.get_manual_sms_list}
                                get_manual_sms_list_loading = {props.get_manual_sms_list_loading}
                                get_manual_sms_list_ret = {props.get_manual_sms_list_ret}
                                get_manual_sms_list_loading_flag = {props.get_manual_sms_list_loading_flag}

                                global_error = {props.global_error}
                            />
                </TabPane>
            </Tabs>}
                     
                </Card>
    </React.Fragment>)
}

export default Querylist