
import {GET_KEY_PROF, GET_KEY_PROF_RET, GET_KEY_PROF_LOADING, GET_KEY_PROF_SERVICES, 
        GET_KEY_PROF_SERVICES_RET, GET_KEY_PROF_SERVICES_LOADING,  SEND_VARIANCE_INFO_LOADING, SEND_VARIANCE_INFO_RET, 
        SEND_VARIANCE_INFO, SET_KEY_MANAGER_PROFESSIONALS, PREVIOUS_VARIANCE_LIST, PREVIOUS_VARIANCE_LIST_RET, 
        PREVIOUS_VARIANCE_LIST_LOADING, SEND_BUSINESS_REPORTS_LOADING, SEND_BUSINESS_REPORTS_RET, SEND_BUSINESS_REPORTS,
        PREVIOUS_BUSINESS_REPORTS, PREVIOUS_BUSINESS_REPORTS_LOADING, PREVIOUS_BUSINESS_REPORTS_RET, SEND_CATALOGUE_REPORT,
        SEND_CATALOGUE_REPORT_RET, SEND_CATALOGUE_REPORT_LOADING, EMAIL_PREVIEW, EMAIL_PREVIEW_RET, EMAIL_PREVIEW_LOADING ,
        SEND_SPECIALITY_REPORT_LOADING, SEND_SPECIALITY_REPORT_RET, SEND_SPECIALITY_REPORT, SENT_SPECIALITY_REPORTS_LOADING,
        SENT_SPECIALITY_REPORTS_RET, SENT_SPECIALITY_REPORTS, SENT_CATALOGUE_REPORTS_LOADING, SENT_CATALOGUE_REPORTS,
         SENT_CATALOGUE_REPORTS_RET, ADD_SERVICE_LOADING, ADD_SERVICE_RET, ADD_SERVICE,
		 ADD_INSURANCE,
		GET_INSURANCE,
		REMOVE_INSURANCE_FROM_DB,
		REMOVE_INSURANCE_FROM_PROFESSIONAL,
		ADD_INSURANCE_RET,
		ADD_INSURANCE_LOADING,
		ADD_INSURANCE_TO_PROFESSIONAL,
		ADD_INSURANCE_TO_PROFESSIONAL_RET,
		ADD_INSURANCE_TO_PROFESSIONAL_LOADING,
		GET_INSURANCE_RET,
		GET_INSURANCE_LOADING,
		REMOVE_INSURANCE_FROM_DB_RET,
		REMOVE_INSURANCE_FROM_DB_LOADING,
		REMOVE_INSURANCE_FROM_PROFESSIONAL_RET,
		REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING, SERVICE_DATA_HANDLER, SERVICE_DATA_HANDLER_RET, 
        SERVICE_DATA_HANDLER_LOADING,GET_SERVICE_FAMILY_BYID, GET_SERVICE_FAMILY_BYID_RET, GET_SERVICE_FAMILY_BYID_LOADING,
        GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID,GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_RET, GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING,
	    UPDATE_FAQ, UPDATE_FAQ_RET, UPDATE_FAQ_LOADING, UPDATE_MARKETING_PAGE, UPDATE_MARKETING_PAGE_LOADING, UPDATE_MARKETING_PAGE_RET, 
		UPDATE_ADVANCED_TREATMENT, UPDATE_ADVANCED_TREATMENT_RET, UPDATE_ADVANCED_TREATMENT_LOADING, 
		UPLOAD_ADVANCED_TREATMENT_IMAGE, UPLOAD_ADVANCED_TREATMENT_IMAGE_RET, UPLOAD_ADVANCED_TREATMENT_IMAGE_LOADING, 
		UPLOAD_ADVANCED_TREATMENT_ICON, UPLOAD_ADVANCED_TREATMENT_ICON_RET, UPLOAD_ADVANCED_TREATMENT_ICON_LOADING  } from "../Constants"



export const update_faq = (data) => {
    return {
        type: UPDATE_FAQ,
        data
    }
}


export const update_faq_ret = (data) => {
    return {
        type: UPDATE_FAQ_RET,
        data
    }
}


export const update_faq_loading = (data) => {
    return {
        type: UPDATE_FAQ_LOADING,
        data
    }
}



export const get_service_family_list_by_specialityid = (data) => {
    return {
        type: GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID,
        data
    }
}

export const get_service_family_list_by_specialityid_ret = (data) => {
    return {
        type: GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_RET,
        data
    }
}
export const get_service_family_list_by_specialityid_loading = (data) => {
    return {
        type: GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING,
        data
    }
}

export const get_service_family_byid = (data) => {
    return {
        type: GET_SERVICE_FAMILY_BYID,
        data
    }
}

export const get_service_family_byid_ret = (data) => {
    return {
        type: GET_SERVICE_FAMILY_BYID_RET,
        data
    }
}
export const get_service_family_byid_loading = (data) => {
    return {
        type: GET_SERVICE_FAMILY_BYID_LOADING,
        data
    }
}

export const service_data_handler = (data) => {
    return {
        type:SERVICE_DATA_HANDLER,
        data
    }
}

export const service_data_handler_ret = (data) => {
    return {
        type:SERVICE_DATA_HANDLER_RET,
        data
    }
}
export const service_data_handler_loading = (data) => {
    return {
        type:SERVICE_DATA_HANDLER_LOADING,
        data
    }
}

export const add_service = (data) => {
	return {
		type: ADD_SERVICE,
		data,
	};
};

export const add_service_ret = (data) => {
	return {
		type: ADD_SERVICE_RET,
		data,
	};
};
export const add_service_loading = (data) => {
	return {
		type: ADD_SERVICE_LOADING,
		data,
	};
};

export const sent_catalogue_reports = (data) => {
	return {
		type: SENT_CATALOGUE_REPORTS,
		data,
	};
};

export const sent_catalogue_reports_ret = (data) => {
	return {
		type: SENT_CATALOGUE_REPORTS_RET,
		data,
	};
};

export const sent_catalogue_reports_loading = (data) => {
	return {
		type: SENT_CATALOGUE_REPORTS_LOADING,
		data,
	};
};

export const sent_speciality_reports = (data) => {
	return {
		type: SENT_SPECIALITY_REPORTS,
		data,
	};
};
export const sent_speciality_reports_ret = (data) => {
	return {
		type: SENT_SPECIALITY_REPORTS_RET,
		data,
	};
};
export const sent_speciality_reports_loading = (data) => {
	return {
		type: SENT_SPECIALITY_REPORTS_LOADING,
		data,
	};
};
export const send_speciality_report = (data) => {
	return {
		type: SEND_SPECIALITY_REPORT,
		data,
	};
};
export const send_speciality_report_ret = (data) => {
	return {
		type: SEND_SPECIALITY_REPORT_RET,
		data,
	};
};
export const send_speciality_report_loading = (data) => {
	return {
		type: SEND_SPECIALITY_REPORT_LOADING,
		data,
	};
};
export const email_preview = (data) => {
	return {
		type: EMAIL_PREVIEW,
		data,
	};
};
export const email_preview_ret = (data) => {
	return {
		type: EMAIL_PREVIEW_RET,
		data,
	};
};
export const email_preview_loading = (data) => {
	return {
		type: EMAIL_PREVIEW_LOADING,
		data,
	};
};
export const previous_business_reports = (data) => {
	return {
		type: PREVIOUS_BUSINESS_REPORTS,
		data,
	};
};
export const send_catalogue_report = (data) => {
	return {
		type: SEND_CATALOGUE_REPORT,
		data,
	};
};
export const send_catalogue_report_ret = (data) => {
	return {
		type: SEND_CATALOGUE_REPORT_RET,
		data,
	};
};
export const send_catalogue_report_loading = (data) => {
	return {
		type: SEND_CATALOGUE_REPORT_LOADING,
		data,
	};
};
export const previous_business_reports_ret = (data) => {
	return {
		type: PREVIOUS_BUSINESS_REPORTS_RET,
		data,
	};
};
export const previous_business_reports_loading = (data) => {
	return {
		type: PREVIOUS_BUSINESS_REPORTS_LOADING,
		data,
	};
};
export const send_business_reports = (data) => {
	return {
		type: SEND_BUSINESS_REPORTS,
		data,
	};
};
export const send_business_reports_ret = (data) => {
	return {
		type: SEND_BUSINESS_REPORTS_RET,
		data,
	};
};
export const send_business_reports_loading = (data) => {
	return {
		type: SEND_BUSINESS_REPORTS_LOADING,
		data,
	};
};
export const previous_variance_list = (data) => {
	return {
		type: PREVIOUS_VARIANCE_LIST,
		data,
	};
};
export const previous_variance_list_ret = (data) => {
	return {
		type: PREVIOUS_VARIANCE_LIST_RET,
		data,
	};
};
export const previous_variance_list_loading = (data) => {
	return {
		type: PREVIOUS_VARIANCE_LIST_LOADING,
		data,
	};
};
export const set_key_manager_professionals = (data) => {
	return {
		type: SET_KEY_MANAGER_PROFESSIONALS,
		data,
	};
};

export const send_variance_info = (data) => {
	return {
		type: SEND_VARIANCE_INFO,
		data,
	};
};
export const send_variance_info_ret = (data) => {
	return {
		type: SEND_VARIANCE_INFO_RET,
		data,
	};
};
export const send_variance_info_loading = (data) => {
	return {
		type: SEND_VARIANCE_INFO_LOADING,
		data,
	};
};

export const get_key_prof = (data) => {
	console.log('Inside get_key_prof_action');
	return {
		type: GET_KEY_PROF,
		data,
	};
};
export const get_key_prof_ret = (data) => {
	return {
		type: GET_KEY_PROF_RET,
		data,
	};
};
export const get_key_prof_loading = (data) => {
	return {
		type: GET_KEY_PROF_LOADING,
		data,
	};
};
export const get_key_prof_services = (data) => {
	return {
		type: GET_KEY_PROF_SERVICES,
		data,
	};
};
export const get_key_prof_services_ret = (data) => {
	return {
		type: GET_KEY_PROF_SERVICES_RET,
		data,
	};
};
export const get_key_prof_services_loading = (data) => {
	return {
		type: GET_KEY_PROF_SERVICES_LOADING,
		data,
	};
};

export const add_insurance = (data) => {
	return {
		type: ADD_INSURANCE,
		data: data,
	};
};

export const add_insurance_ret = (data) => {
	return {
		type: ADD_INSURANCE_RET,
		data: data,
	};
};

export const add_insurance_loading = (data) => {
	return {
		type: ADD_INSURANCE_LOADING,
		data: data,
	};
};

export const add_insurance_to_professional = (data) => {
	return {
		type: ADD_INSURANCE_TO_PROFESSIONAL,
		data: data,
	};
};

export const add_insurance_to_professional_ret = (data) => {
	return {
		type: ADD_INSURANCE_TO_PROFESSIONAL_RET,
		data: data,
	};
};

export const add_insurance_to_professional_loading = (data) => {
	return {
		type: ADD_INSURANCE_TO_PROFESSIONAL_LOADING,
		data: data,
	};
};

export const get_insurance = (data) => {
	return {
		type: GET_INSURANCE,
		data: data,
	};
};

export const get_insurance_ret = (data) => {
	return {
		type: GET_INSURANCE_RET,
		data: data,
	};
};

export const get_insurance_loading = (data) => {
	return {
		type: GET_INSURANCE_LOADING,
		data: data,
	};
};

export const remove_insurance_from_db = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_DB,
		data: data,
	};
};

export const remove_insurance_from_db_ret = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_DB_RET,
		data: data,
	};
};

export const remove_insurance_from_db_loading = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_DB_LOADING,
		data: data,
	};
};

export const remove_insurance_from_professional = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_PROFESSIONAL,
		data: data,
	};
};

export const remove_insurance_from_professional_ret = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_PROFESSIONAL_RET,
		data: data,
	};
};

export const remove_insurance_from_professional_loading = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING,
		data: data,
	};
};

export const updateMarketingPage = (data) => {
    return {
        type: UPDATE_MARKETING_PAGE,
        data
    }
}

export const updateMarketingPage_ret = (data) => {
    return {
        type: UPDATE_MARKETING_PAGE_RET,
        data
    }
}
export const updateMarketingPage_loading = (data) => {
    return {
        type: UPDATE_MARKETING_PAGE_LOADING,
        data
    }
}


export const update_advanced_treatment = (data) => {
    return {
        type: UPDATE_ADVANCED_TREATMENT,
        data
    }
}


export const update_advanced_treatment_ret = (data) => {
    return {
        type: UPDATE_ADVANCED_TREATMENT_RET,
        data
    }
}


export const update_advanced_treatment_loading = (data) => {
    return {
        type: UPDATE_ADVANCED_TREATMENT_LOADING,
        data
    }
}

export const upload_advanced_treatment_image = (data) => {
    return {
        type: UPLOAD_ADVANCED_TREATMENT_IMAGE,
        data
    }
}


export const upload_advanced_treatment_image_ret = (data) => {
    return {
        type: UPLOAD_ADVANCED_TREATMENT_IMAGE_RET,
        data
    }
}


export const upload_advanced_treatment_image_loading = (data) => {
    return {
        type: UPLOAD_ADVANCED_TREATMENT_IMAGE_LOADING,
        data
    }
}

export const upload_advanced_treatment_icon = (data) => {
    return {
        type: UPLOAD_ADVANCED_TREATMENT_ICON,
        data
    }
}


export const upload_advanced_treatment_icon_ret = (data) => {
    return {
        type: UPLOAD_ADVANCED_TREATMENT_ICON_RET,
        data
    }
}


export const upload_advanced_treatment_icon_loading = (data) => {
    return {
        type: UPLOAD_ADVANCED_TREATMENT_ICON_LOADING,
        data
    }
}
