import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './index.css'
import './sass/main.scss';
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { get_user, get_user_loading } from "./actions/user"
import no_auth_route from "./HOC/no_auth_route"
import protected_route from "./HOC/protected_route"
import LoginContainer from "./Containers/LoginContainer"
import {  Route, Switch, Redirect,  BrowserRouter as Router } from 'react-router-dom'
import Dashboard from "./Containers/Dashboard"

import Catalogue from './Components/Panel/Catalogue';
// import '../node_modules/react-google-places-autocomplete/dist/index.min.css';


function App(props) {
  const authObject =()=> {
    return {
     isAuthenticated: !!localStorage.getItem('token')
    }
 }

console.log(props,"props in App.js")
  return (
      <React.Fragment>
         <Router>
          <Switch>
          <Redirect exact from="/" to="/signin" />
            {/* <Route path = '/catalogue' component={Catalogue}/> */}
            {/* <Route path='/panel' component={Panel}/> */}
            <Route  path ="/signin" component={()=>no_auth_route({
                    authObject:authObject
                  })(()=><div ><LoginContainer /></div>)} />
  
            <Route  path ="/dashboard" component={()=>protected_route({
                    authObject:authObject,
                    logout:()=>console.log()
                  })(()=><div ><Dashboard logout={()=>console.log()} /></div>)} />

          </Switch>
      </Router>
      </React.Fragment>
  );
}

const mapStateToProps = state => ({
  user: state
})

export default App