import React from "react"
import { Table, Input, Button, Space, Card, Row, Col } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import {for_loop, objectEquals} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
// import Map from "./Map.js"


const Servicelist = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [speciality_data, set_speciality_data] = React.useState([])
      const [key_prof_services_params, set_key_prof_services_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
             serviceName:''
            //  name:'',
            //  email:'',
            //  mobileNumber:''
           },
           filters:{
            //  locality:"All",
            //  userType:"",
            //  level:"",
            //  catalogue:''
           }
      })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [list, set_list] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)

    React.useEffect(()=>{
      console.log(props.variance_info,"props.varianceinof")
      set_speciality_data(modify_speciality_data(props.variance_info))
        props.get_key_prof_services({...key_prof_services_params, speciality:props.speciality?props.speciality:'', professional_id:props.professional_id})
        set_key_prof_services_params({...key_prof_services_params,speciality:props.speciality?props.speciality:'', professional_id:props.professional_id })
    },[])

    React.useEffect(()=>{
      set_speciality_data(modify_speciality_data(props.variance_info))
    }, [props.variance_info])

    React.useEffect(()=>{
        props.get_key_prof_services({...key_prof_services_params, speciality:props.speciality?props.speciality:'', professional_id:props.professional_id})
        set_key_prof_services_params({...key_prof_services_params,speciality:props.speciality?props.speciality:'', professional_id:props.professional_id })
    },[props.speciality])

    React.useEffect(()=>{
      if(!!props.get_key_prof_services_ret){
        // console.log(props.get_key_prof_services_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_key_prof_services_ret.success){
          set_data(modif_data([...props.get_key_prof_services_ret.data]))

          set_key_prof_services_params({...key_prof_services_params, total:props.get_key_prof_services_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_key_prof_services_ret.message,
            heading:'Professionals'
          })
         }
          props.get_key_prof_services_loading()
      }
     
         
  },[props.get_key_prof_services_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...key_prof_services_params.filters}
       let updated_filters = {}
      //  updated_filters.locality = "All"
      //  updated_filters.level = filters.level
      //  updated_filters.catalogue = filters.catalogue
      //  updated_filters.userType = filters.userType
      if(!!((pagination.current === key_prof_services_params.page) && (pagination.pageSize === key_prof_services_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_key_prof_services_params({...key_prof_services_params, page:1, filters :{
          //  locality:"All",
          //  userType: filters.userType? filters.userType:'',
          //  level:filters.level?filters.level:'',
          //  catalogue:filters.catalogue?filters.catalogue:''
          }})
          props.get_key_prof_services({...key_prof_services_params, page:1, filters :{
          //  locality:"All",
          //  userType: filters.userType? filters.userType:'',
          //  level:filters.level?filters.level:'',
          //  catalogue:filters.catalogue?filters.catalogue:''        
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_key_prof_services_params({...key_prof_services_params, page:1,searchParams:{
           ...key_prof_services_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_key_prof_services({...key_prof_services_params, page:1,searchParams:{
          ...key_prof_services_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
             serviceName:item.serviceName,
             specialityName:item.specialityName,
             variance:item.variance,
             percentage:item.percentage,
             price:item.price?item.price[0]:'N/A',
             serviceId:item.serviceId,
             serviceAveragePrice:item.serviceAveragePrice,
             maxDiscountPrice:item.maxDiscountPrice,
            _id: item._id
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

    const modify_speciality_data = (arr) => {
      let speciality_list = []
          for_loop(arr, (item) => {
              let obj = {
                speciality:item.specialityName,
                variance:item.specialityVariance,
                specialityId:item.specialityId
              }
              speciality_list.push({
            ...obj
              })
             
          })
  
      return speciality_list;
      }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_key_prof_services_params({...key_prof_services_params, limit:b, page:1})
      props.get_key_prof_services({...key_prof_services_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,key_prof_services_params,"a and b key_prof_services_params")
      // if(key_prof_services_params.page !== a){
        if(true){
        set_key_prof_services_params({...key_prof_services_params, page:a, limit:b})
        props.get_key_prof_services({...key_prof_services_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_key_prof_services_params({...key_prof_services_params,page:1,  searchParams:{
        serviceName:''
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
        // locality:"All",
        // userType:"",
        // level:"",
        // catalogue:''
      }})
      props.get_key_prof_services({...key_prof_services_params,page:1,  searchParams:{
        serviceName:''
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
        // locality:"All",
        // userType:"",
        // level:"",
        // catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_key_prof_services_params({...key_prof_services_params, page:1, filters:{
        // locality:"All",
        // userType:"",
        // catalogue:'',
        // level:''
      }})
      props.get_key_prof_services({...key_prof_services_params, page:1, filters:{
        // locality:"All",
        // userType:"",
        // catalogue:'',
        // level:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_key_prof_services_params({...key_prof_services_params,page:1, speciality:r.specialityId,  searchParams:{
            serviceName:''
          },
          filters:{
          }})
          props.get_key_prof_services({...key_prof_services_params,page:1,  speciality:r.specialityId,  searchParams:{
            serviceName:''
          },
          filters:{
          }})
    }

    const speciality_columns = [
      {
        title: 'Speciality',
        dataIndex: 'speciality',
        key: 'speciality',
       
      },
      {
        title: 'Variance',
        dataIndex: 'variance',
        key: 'variance',
       
        render:text => {
          if(text>0){
          return <p>{!!text?text.toString():'N/A'}</p>
          }else{
          return <p>{!!text?text.toString():'N/A'}</p>
          }
       
        },
      },
    ]

    const columns = [
        {
          title: 'Service',
          dataIndex: 'serviceName',
          key: 'serviceName',
          //ellipsis: true,
          width:'50%',
          ...getColumnSearchProps('serviceName', "Search Service", 'serviceName'),
        },
        // {
        //   title: 'speciality',
        //   dataIndex: 'specialityName',
        //   key: 'specialityName',
        //   ellipsis: true,
        //   // ...getColumnSearchProps('specialityName', "Search Service", 'specialityName'),
        // },
        {
            title: 'Variance',
            dataIndex: 'variance',
            key: 'variance',
            width:'15%',
            // ...getColumnSearchProps('email', "Search email", 'email'),
            //ellipsis: true,
          },
        {
          title: 'Percentage',
          dataIndex: 'percentage',
          key: 'percentage',
          width:'15%',
          // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          render:(text,record) => {
            console.log(record,"record")
            if(text>0){
            return <p title={record.serviceAveragePrice?`Average Service Price = ${record.serviceAveragePrice}`:'N?A'}>{!!text?text.toString():'N/A'}{text?<ArrowUpOutlined style={{color:'red'}} />:''}</p>
            }else{
            return <p title={record.serviceAveragePrice?`Average Service Price = ${record.serviceAveragePrice}`:'N?A'}>{!!text?text.toString().substring(1):'N/A'}{text?<ArrowDownOutlined style={{color:'green'}} />:''}</p>
            }
         
          },
       //   ellipsis: true,
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          width:'15%',
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
          ellipsis: true,
        },
        {
          title: 'Price(Max Discount)',
          dataIndex: 'maxDiscountPrice',
          key: 'maxDiscountPrice',
          width:'20%',
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
          // ellipsis: true,
        }
      ];

      function showTotal(total) {
        return `Total ${total} items`;
      }

   if(!!professional_selected){
        return <Redirect to={`/dashboard/professionals?professional_id=${professional_selected._id}`} />
   }

        return (<React.Fragment>
          <Row>
            <Col span={8}>
               <Card title="Specialities">
                  <Table
                      columns={speciality_columns}
                      dataSource = {speciality_data}
                      onRow={(r) => ({
                        onClick: (b,a) => row_click(r),
                      })}
                      rowClassName={(record, index)=>{
                        if(record.specialityId === key_prof_services_params.speciality){
                              return "higlighted_row"
                        }
                      }}
                  />
               </Card>
            </Col>
            <Col span={16}>
            <Card title="Services">
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_key_prof_services_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:key_prof_services_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:key_prof_services_params.page,
            total:key_prof_services_params.total,
            showTotal:showTotal
          }}
          
          />
        </Card>
            </Col>
          </Row>
       
        </React.Fragment>)
}
export default Servicelist