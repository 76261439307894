import   {  GET_PROFESIONALS , GET_PROFESIONALS_RET, GET_PROFESIONALS_LOADING, SET_PROFESSIONALS_LIST, 
            GET_PROFESSIONAL_BYID, GET_PROFESSIONAL_BYID_RET, GET_PROFESSIONAL_BYID_LOADING, UPDATE_PROFESSIONAL_BYID,
            UPDATE_PROFESSIONAL_BYID_RET, UPDATE_PROFESSIONAL_BYID_LOADING, GET_PROF_SPECIALITIES, GET_PROF_SPECIALITIES_RET,
            GET_PROF_SPECIALITIES_LOADING, GET_PROF_SERVICES, GET_PROF_SERVICES_LOADING, GET_PROF_SERVICES_RET,
            UPDATE_CATALOGUE, UPDATE_CATALOGUE_RET, UPDATE_CATALOGUE_LOADING, GET_PROFESSIONAL_INFO, GET_PROFESSIONAL_INFO_LOADING,
            GET_PROFESSIONAL_INFO_RET,
            GET_INSURANCE, GET_INSURANCE_RET, GET_INSURANCE_LOADING,
            ADD_INSURANCE_TO_PROFESSIONAL, ADD_INSURANCE_TO_PROFESSIONAL_RET, ADD_INSURANCE_TO_PROFESSIONAL_LOADING,
            REMOVE_INSURANCE_FROM_PROFESSIONAL, REMOVE_INSURANCE_FROM_PROFESSIONAL_RET, REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING, 
            GET_MEDICAL_DOCTORS, GET_MEDICAL_DOCTORS_RET, GET_MEDICAL_DOCTORS_LOADING, 
            UPDATE_MEDICAL_DOCTOR, UPDATE_MEDICAL_DOCTOR_RET, UPDATE_MEDICAL_DOCTOR_LOADING, DELETE_HOSPITAL_PROFILE, DELETE_HOSPITAL_PROFILE_RET, DELETE_HOSPITAL_PROFILE_LOADING
        } from "../Constants"


export const get_professional_info = (data) => {
    return {
        type:GET_PROFESSIONAL_INFO,
        data
    }
}
export const get_professional_info_ret = (data) => {
    return {
        type:GET_PROFESSIONAL_INFO_RET,
        data
    }
}

export const get_professional_info_loading = (data) => {
    return {
        type:GET_PROFESSIONAL_INFO_LOADING,
        data
    }
}

export const update_catalogue = (data) => {
    return {
        type:UPDATE_CATALOGUE,
        data
    }
}

export const update_catalogue_ret = (data) => {
    return {
        type:UPDATE_CATALOGUE_RET,
        data
    }
}

export const update_catalogue_loading = (data) => {
    return {
        type:UPDATE_CATALOGUE_LOADING,
        data
    }
}
export const get_prof_services = (data) => {
    return {
        type:GET_PROF_SERVICES,
        data
    }
}
export const get_prof_services_ret = (data) => {
    return {
        type:GET_PROF_SERVICES_RET,
        data
    }
}

export const get_prof_services_loading = (data) => {
    return {
        type:GET_PROF_SERVICES_LOADING,
        data
    }
}
        
export const get_prof_specialities = (data) => {
    return {
        type:GET_PROF_SPECIALITIES,
        data
    }
}

export const get_prof_specialities_ret = (data) => {
    return {
        type:GET_PROF_SPECIALITIES_RET,
        data
    }
}
export const get_prof_specialities_loading = (data) => {
    return {
        type:GET_PROF_SPECIALITIES_LOADING,
        data
    }
}

export const update_professional_byid = (data) => {
    return {
        type:UPDATE_PROFESSIONAL_BYID,
        data
    }
}

export const update_professional_byid_ret = (data) => {
    return {
        type:UPDATE_PROFESSIONAL_BYID_RET,
        data
    }
}

export const update_professional_byid_loading = (data) => {
    return {
        type:UPDATE_PROFESSIONAL_BYID_LOADING,
        data
    }
}

export const get_professional_byid = (data) => {
    return {
        type:GET_PROFESSIONAL_BYID,
        data
    }
}
export const get_professional_byid_ret = (data) => {
    return {
        type:GET_PROFESSIONAL_BYID_RET,
        data:data
    }
}

export const get_professional_byid_loading = (data) => {
    return {
        type:GET_PROFESSIONAL_BYID_LOADING,
        data
    }
}



export const get_professionals = (data) => {
    return {
        type:GET_PROFESIONALS,
        data
    }
}

export const get_professionals_ret = (data) => {
    return {
        type:GET_PROFESIONALS_RET,
        data
    }
}

export const get_professionals_loading = (data) => {
    return {
        type:GET_PROFESIONALS_LOADING,
        data
    }
}

export const set_professionals_list = (data) => {
    return {
        type:SET_PROFESSIONALS_LIST,
        data
    }
}

export const get_insurance = (data) => {
	return {
		type: GET_INSURANCE,
		data: data,
	};
};

export const get_insurance_ret = (data) => {
	return {
		type: GET_INSURANCE_RET,
		data: data,
	};
};

export const get_insurance_loading = (data) => {
	return {
		type: GET_INSURANCE_LOADING,
		data: data,
	};
};

export const add_insurance_to_professional = (data) => {
	return {
		type: ADD_INSURANCE_TO_PROFESSIONAL,
		data: data,
	};
};

export const add_insurance_to_professional_ret = (data) => {
	return {
		type: ADD_INSURANCE_TO_PROFESSIONAL_RET,
		data: data,
	};
};

export const add_insurance_to_professional_loading = (data) => {
	return {
		type: ADD_INSURANCE_TO_PROFESSIONAL_LOADING,
		data: data,
	};
};

export const remove_insurance_from_professional = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_PROFESSIONAL,
		data: data,
	};
};

export const remove_insurance_from_professional_ret = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_PROFESSIONAL_RET,
		data: data,
	};
};

export const remove_insurance_from_professional_loading = (data) => {
	return {
		type: REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING,
		data: data,
	};
};


export const get_medical_doctors = (data) => {
	return {
		type: GET_MEDICAL_DOCTORS,
		data: data,
	};
};

export const get_medical_doctors_ret = (data) => {
	return {
		type: GET_MEDICAL_DOCTORS_RET,
		data: data,
	};
};

export const get_medical_doctors_loading = (data) => {
	return {
		type: GET_MEDICAL_DOCTORS_LOADING,
		data: data,
	};
};

export const update_medical_doctor = (data) => {
	return {
		type: UPDATE_MEDICAL_DOCTOR,
		data: data,
	};
};

export const update_medical_doctor_ret = (data) => {
	return {
		type: UPDATE_MEDICAL_DOCTOR_RET,
		data: data,
	};
};

export const update_medical_doctor_loading = (data) => {
	return {
		type: UPDATE_MEDICAL_DOCTOR_LOADING,
		data: data,
	};
};

export const delete_hospital_profile = (data) => {
	return {
		type: DELETE_HOSPITAL_PROFILE,
		data: data,
	};
};

export const delete_hospital_profile_ret = (data) => {
	return {
		type: DELETE_HOSPITAL_PROFILE_RET,
		data: data,
	};
};

export const delete_hospital_profile_loading = (data) => {
	return {
		type: DELETE_HOSPITAL_PROFILE_LOADING,
		data: data,
	};
};