import { call, put, takeLatest } from 'redux-saga/effects'
import {GET_CATALOGUE,GET_CATALOGUE_RET,GET_CATALOGUE_BYID,GET_CATALOGUE_BYID_RET} from '../Constants'
import {get_catalogue,get_catalogue_ret,get_catalogue_byid,get_catalogue_byid_ret} from '../actions/catalogue'
import api from '../utils/api_routes'

import store from '../store'



function* get_catalogue_saga(action) {
    //  console.log(yield store.getState());
    try {
        // do api call
        const api_data = yield call(api.catalogue.get_catalogue);
        // console.log(data);
        yield put(get_catalogue_ret(api_data));
        console.log(api_data);

      } catch (e) {
        console.log(e);
      }
}

function* get_catalogue_byid_saga(action) {
  //  console.log(yield store.getState());
  try {
    console.log(store.getState());
    const data = yield store.getState().catalogue_store.id;
      // do api call
      const api_data = yield call(api.catalogue.get_catalogue_byid,data);
      // console.log(data);
      yield put(get_catalogue_byid_ret(api_data));
      console.log(api_data);

    } catch (e) {
      console.log(e);
    }
}

export const catalogue_saga = [
    takeLatest(GET_CATALOGUE, get_catalogue_saga),
    takeLatest(GET_CATALOGUE_BYID, get_catalogue_byid_saga)
]

export default catalogue_saga;
