import React from "react";
import {
  Card,
  Menu,
  Form,
  DatePicker,
  Row,
  Col,
  Descriptions,
  Tag,
} from "antd";

import Button from "antd-button-color";
import Highlighter from "react-highlight-words";
import { SearchOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import {
  error_form_check,
  isEmpty,
  get_month,
  get_url_params,
} from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
//import Servicelist from "./Servicelist"

const { SubMenu } = Menu;

const options = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Other", value: "O" },
];

const HospitalService = (props) => {
  const [state, set_state] = React.useState({
    check_status: "PENDING",
  });
  const [data, set_data] = React.useState({
    speciality: "",
    service: "",
    category: "",
    doctorName: "",
    center: "",
    doctor: "",
    doctors: [],
    price: "",
    variance: "",
  });
  const [error, set_error] = React.useState({
    speciality: { state: false, text: "" },
    service: { state: false, text: "" },
    category: { state: false, text: "" },
    doctorName: { state: false, text: "" },
    center: { state: false, text: "" },
    doctor: { state: false, text: "" },
    price: { state: false, text: "" },
    variance: { state: false, text: "" },
  });
  const [initial_data, set_initial_data] = React.useState({});
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (props.get_doctors_ret) {
      if (props.get_doctors_ret.success) {
        set_data({
          ...data,
          doctors: [
            ...props.get_doctors_ret.data.map((item) => {
              console.log(item, "doctors with");
              return {
                name: item.isHospitalDoctor ? item.doctors[0].name : item.name,
                price: item.isHospitalDoctor
                  ? item.doctors[0].consultationFee
                  : "---",
                experience: item.isHospitalDoctor
                  ? item.doctors[0].experience
                  : "---",
                speciality: item.isHospitalDoctor
                  ? item.doctors[0].department
                  : "---",
                address: `${item.address.substring(1, 20)}...`,
                key: item._id,
                hospitalId: item.hospitalId,
                doctors: item.doctors,
                isHospitalDoctor: item.isHospitalDoctor,
              };
            }),
          ],
          price: "",
          variance: "",
        });
      }
    }
    else {
      props.global_error({
        success: false,
        message: props.get_doctors_ret.message,
        heading: "No Doctor Available",
      });
    }
    props.get_doctors_loading();

    if (props.update_catalogue_ret) {
      if (props.update_catalogue_ret.success) {
        props.global_error({
          success: true,
          message: props.update_catalogue_ret.message,
          heading: "Add Service",
        });
      } else {
        props.global_error({
          success: false,
          message: props.update_catalogue_ret.message,
          heading: "Add Service",
        });
      }
      props.update_catalogue_loading();
    }
  }, [props.update_catalogue_ret, props.get_doctors_ret]);

  const speciality_change = (val) => {
    props.get_services({ speciality: val });
    set_data({
      ...data,
      speciality_name: props.global_store.specialities.filter(
        (item) => item.value === val
      )[0].name,
      speciality: val,
      service: "",
      price: "",
      variance: "",
    });
  };

  const service_change = (val) => {
    set_data({
      ...data,
      service_name: props.global_store.services.filter(
        (item) => item.value === val
      )[0].name,
      service: val,
      price: "",
      variance: "",
    });

    props.get_doctors({
      specialityId: data.speciality,
      serviceId: val,
    });
  };

  const select_doctor = (id) => {
    console.log(id, "id");
    let doctor = data.doctors.filter((item) => item.value === id)[0];
    console.log(doctor, "doctor in time slot");
    set_data({
      ...data,
     doctor: id
    });
  };

  const doctor_change = (val) => {};

  const validate = (data) => {
    const error = {
      speciality: { state: false, text: "" },
      service: { state: false, text: "" },
      category: { state: false, text: "" },
      hospital: { state: false, text: "" },
      center: { state: false, text: "" },
      doctor: { state: false, text: "" },
      price: { state: false, text: "" },
      variance: { state: false, text: "" },
    };
    if (data.speciality === "") {
      {
        error.speciality.text = "Select speciality";
        error.speciality.state = true;
      }
    }
    if (data.service === "") {
      {
        error.service.text = "Enter service";
        error.service.state = true;
      }
    }

    if(data.category === "") {
      error.category.text = "Select Category";
      error.category.state = true;
    }

    if (data.doctorName === "") {
      {
        error.hospital.text = "Enter Hospital";
        error.hospital.state = true;
      }
    }

    if (data.center === "") {
      {
        error.center.text = "Select Center";
        error.center.state = true;
      }
    }

    if (data.doctor === "") {
      {
        error.doctor.text = "Select Doctor";
        error.doctor.state = true;
      }
    }

    if (data.price === "") {
      {
        error.price.text = "Enter price";
        error.price.state = true;
      }
    }

    if (data.variance === "") {
      {
        error.variance.text = "Enter variance";
        error.variance.state = true;
      }
    }

    if (data.variance !== "") {
      if (data.variance > 100) {
        {
          error.variance.text = "Invalid variance";
          error.variance.state = true;
        }
      }
    }
    return error;
  };
  const submit = () => {
    const ret_error = validate({ ...data });
    set_error({ ...error, ...ret_error });
    console.log(ret_error, "ret_error in add_member");
    if (!error_form_check(ret_error)) {
      props.update_catalogue({
        professional_id: get_url_params("professional_id"),
        specialityId: data.speciality,
        services: [
          {
            speciality: data.speciality_name,
            specialityId: data.speciality,
            serviceId: data.service,
            service: data.service_name,
            category: data.category,
            price: data.price,
            variance: data.variance,
          },
        ],
      });
    }
  };

  console.log("insode AddService ");

  return (
    <React.Fragment>
      <Form form={form} layout="vertical">
        <Row gutter={[32, 0]}>
          <Col span={8}>
            <Form.Item>
              <AntdInput
                label="Speciality"
                value={data.speciality}
                disabled={
                  props.get_bids_loading_flag ||
                  props.update_catalogue_loading_flag
                }
                options={props.global_store.specialities}
                loading={
                  props.global_store.get_specialities_loading ||
                  props.update_catalogue_loading_flag
                }
                error_text={error.speciality.text}
                toggle_error={() =>
                  set_error({
                    ...error,
                    speciality: { text: "", state: false },
                  })
                }
                error={error.speciality.state}
                name="speciality"
                type="select"
                onChange={(event) => speciality_change(event)}
                placeholder="Choose speciality"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item>
              <AntdInput
                label="Hospital/Doctor"
                value={data.doctor}
                options={data.doctors}
                loading={props.get_doctors_loading_flag}
                disabled={
                  props.get_doctors_loading_flag ||
                  props.update_catalogue_loading_flag
                }
                error_text={error.doctor.text}
                toggle_error={() =>
                  set_error({ ...error, doctor: { text: "", state: false } })
                }
                error={error.doctor.state}
                name="service"
                onChange={(event) => {
                  console.log(event, "event in Add Hospital");
                  select_doctor(event);
                }}
                type="select"
                placeholder="Select Hospital/Doctor"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={4}>
            <Button
              type="primary"
              loading={props.update_catalogue_loading_flag}
              disabled={props.update_catalogue_loading_flag}
              onClick={() => submit()}
              style={{ width: "auto", margin: "auto" }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};
export default HospitalService;
