import React from "react"
import { Table, Input, Button, Space, Card,  Menu, Dropdown, Row, Col, Form  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import {for_loop, getDateFormat, objectEquals} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
//import Servicelist from "./Servicelist"
import SearchLocation from "../../Components/SearchLocation"
import  { Link } from "react-router-dom"
import { set_services } from "../../actions/global";

const { SubMenu }  = Menu


const SuggestedInsights = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [form] = Form.useForm();
  
      const [service, set_service] = React.useState('')
      const [selected_row, set_selected_row] =React.useState([])

      const [get_suggested_insights_params, set_get_suggested_insights_params] = React.useState({
           page:1,
           limit:10,
           speciality:'',
           total:10,
           serviceName:'',
           searchParams:{
             name:'',
           },
           filters:{
             createdAt:"",
             booked:''
           }
      })

      const [data, set_data] = React.useState([])
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [error, set_error] = React.useState({
        location:{state:false, text:''}
      })
    React.useEffect(()=>{
        props.get_suggested_insights(get_suggested_insights_params)
    },[])

    React.useEffect(()=>{
      if(!!props.get_suggested_insights_ret){
        // console.log(props.get_suggested_insights_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_suggested_insights_ret.success){
          set_data(modif_data([...props.get_suggested_insights_ret.data]))
          set_get_suggested_insights_params({...get_suggested_insights_params, total:props.get_suggested_insights_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_suggested_insights_ret.message,
            heading:'Professionals'
          })
         }
          props.get_suggested_insights_loading()
      }
     
         
  },[props.get_suggested_insights_ret])

  function handleButtonClick(e) {
    console.log('click left button', e);
  }
  
  function handleMenuClick(e) {
    console.log('click', e);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
      <Link to="/dashboard/keymanager?send_sms">
        Send SMS
        </Link>
      </Menu.Item>
    
      <Menu.Item key="2" icon={<UserOutlined />}>
      <Link to="/dashboard/keymanager?send_email">
        Send Email
        </Link>
      </Menu.Item>
    
      <SubMenu title="Send Manually">
      <Menu.Item>SMS</Menu.Item>
      <Menu.Item>Eamil</Menu.Item>
    </SubMenu>
    </Menu>
  );

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_suggested_insights_params.filters}
       let updated_filters = {}
       updated_filters.createdAt = filters.createdAt
       updated_filters.booked = filters.booked
      if(!!((pagination.current === get_suggested_insights_params.page) && (pagination.pageSize === get_suggested_insights_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_suggested_insights_params({...get_suggested_insights_params, page:1, filters :{
           createdAt:filters.createdAt?filters.createdAt:'',
           booked:filters.booked?filters.booked:''
          }})
          props.get_suggested_insights({...get_suggested_insights_params, page:1, filters :{
           createdAt:filters.createdAt?filters.createdAt:'',
           booked:filters.booked?filters.booked:''     
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_suggested_insights_params({...get_suggested_insights_params, page:1,searchParams:{
           ...get_suggested_insights_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_suggested_insights({...get_suggested_insights_params, page:1,searchParams:{
          ...get_suggested_insights_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item,i) => {
            console.log(i,"i in modif_data")
            let obj = {
            booked: item.booked?"YES":"NO",
            createdAt: getDateFormat(item.createdAt),
            name:item.name,
            serviceInsights: item.serviceInsights,
            serviceName:item.serviceName,
            specialityname: item.specialityname,
            _id: item.createdAt
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_suggested_insights_params({...get_suggested_insights_params, limit:b, page:1})
      props.get_suggested_insights({...get_suggested_insights_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_suggested_insights_params,"a and b get_suggested_insights_params")
      // if(get_suggested_insights_params.page !== a){
        if(true){
        set_get_suggested_insights_params({...get_suggested_insights_params, page:a, limit:b})
        props.get_suggested_insights({...get_suggested_insights_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_suggested_insights_params({...get_suggested_insights_params,page:1,  searchParams:{
        name:'',
      },
      filters:{
        createdAt:'',
        booked:''
      }})
      props.get_suggested_insights({...get_suggested_insights_params,page:1,  searchParams:{
        name:''
      },
      filters:{
       createdAt:'',
       booked:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_suggested_insights_params({...get_suggested_insights_params, page:1, filters:{
       createdAt:'',
       booked:''
      }})
      props.get_suggested_insights({...get_suggested_insights_params, page:1, filters:{
       createdAt:'',
       booked:''
      }})
    }



    const columns = [
        {
          title: 'User',
          dataIndex: 'name',
          key: 'name',
          // ellipsis: true,
          width:'20%',
          ...getColumnSearchProps('name', "Search User", 'name'),
        },
        {
          title: 'Service',
          dataIndex: 'serviceName',
          key: 'serviceName',
          width:'20%',
         ...getColumnSearchProps('serviceName', "Search Service", 'serviceName'),
          // ellipsis: true,
        },
        {
          title: 'Speciality',
          dataIndex: 'specialityname',
          key: 'specialityname', 
          width:'20%',  
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
          // ellipsis: true,
        },
        {
            title: 'Booked',
            dataIndex: 'booked',
            width:'20%',
            key: 'booked',
          //   filters: [
          //       {
          //         text: 'YES',
          //         value: "YES",
          //       },
          //       {
          //         text: 'NO',
          //         value: "NO",
          //       }
          //     ],
          //     filterMultiple: false,
          },
          {
            title: 'Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            filters: [
              {
                text: 'Today',
                value: 1,
              },
              {
                text: 'Yesterday',
                value: 2,
              },
              {
                text: 'Last 7 days',
                value: 7,
              },
              {
                text: 'Last 30 days',
                value: 30,
              },
              {
                text: 'Default Value',
                value: 1000,
              }
            ],
            filterMultiple: false,
            width:'20%',
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
           
          }
      ];
      const professionals_collumns = [
        {
          title: 'Name',
          dataIndex: 'professionalName',
          key: 'professionalName',
          width:'20%'
        },
        {
            title: 'Suggested',
            dataIndex: 'isSuggested',
            key: 'isSuggested',
            width:'20%',
            render:(val)=>val?"YES":"NO"
          },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width:'20%'
          },
          {
            title: 'Updated',
            dataIndex: 'priceUpdated',
            key: 'updatedPrice',
            width:'20%',
            render:(val)=>{
              console.log(val,"val in Updated")
              return val?"YES":"NO"
            }
          },
          {
            title: 'Price Updated',
            dataIndex: 'updatedPrice',
            key: 'updatedPrice',
            width:'20%'
          },
      ];

  



    const debounce = (func, wait) => {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {

          timeout = null

          func(...args);
        }

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);
      }
    }
    const returnedFunction = debounce(function(data) {
     
      try{
         variance_change(data)
      }catch(e){
       console.log(e)
      }
      // setState({...state, selectPlace:data})
     
    }, 500);

    function showTotal(total) {
      return `Total ${total} items`;
    }

    const variance_change = (data) => {
      set_get_suggested_insights_params({...get_suggested_insights_params,serviceName:data})
      props.get_suggested_insights({...get_suggested_insights_params,serviceName:data})
    }

    const expand = (record,a,b) => {
        console.log(record,a,b,"record,a,b")
      
        if(expandedRowKeys[0]!== a._id){
           set_expandedRowKeys([a._id])
           set_selected_row(a.serviceInsights)
           console.log(a,"a.varianceInfo")
          
        }else{
        set_expandedRowKeys([])
        }
      }

      console.log(selected_row,"selected_row in Realtime Component")

        return (<React.Fragment>
         <Row>
         <Col span={12}>
         <AntdInput 
                  label="Speciality"  
                  value={get_suggested_insights_params.speciality} 
                  disabled = {props.get_suggested_insights_loading_flag}
                  options = {props.global_store.specialities}
                  loading={props.global_store.get_specialities_loading}
                  error_text={""} 
                  toggle_error = {()=>console.log()} 
                  error={false}
                  name="speciality" 
                  type="select"
                  onChange = {event=>{
                    set_get_suggested_insights_params({...get_suggested_insights_params, speciality:event, serviceName:''})
                    props.get_suggested_insights({...get_suggested_insights_params, speciality:event, serviceName:''})
                    props.get_services({speciality:event})
                    set_service('')
                  }} 
                  placeholder="Choose speciality" />
          </Col>
          <Col span={12}>
          <AntdInput 
                  label="Service"  
                  value={service} 
                  disabled = {props.get_suggested_insights_loading_flag}
                  options = {props.global_store.services}
                  loading={props.global_store.get_specialities_loading}
                  error_text={""} 
                  toggle_error = {()=>console.log()} 
                  error={false}
                  name="service" 
                  type="select"
                  onChange = {event=>{
                    set_service(event)
                    set_get_suggested_insights_params({...get_suggested_insights_params,serviceName:event})
                    props.get_suggested_insights({...get_suggested_insights_params,serviceName:event})
                  }} 
                  placeholder="Choose speciality" />
             
           </Col>
        {/* <Col span={24}>
        <Form
      form={form}
      layout="vertical"
    >
 

</span>
<span className="flex-child-1-new">
    <SearchLocation  
        label="Location"  
        disabled = {props.get_bids_loading_flag}
        error_text={error.location.text} 
        toggle_error = {()=>set_error({...error,location:{text:'',state:false}})} 
        error={error.location.state}
        name="location" 
        set_long_lat = {val=>{
          set_get_suggested_insights_params({...get_suggested_insights_params, page:1, longitude:val.lng, latitude:val.lat})
          props.get_suggested_insights({...get_suggested_insights_params, page:1, longitude:val.lng, latitude:val.lat})
         }}
        placeholder="Enter Location"
    />
</span>
</div>
    </Form>
        </Col> */}
         </Row>
            <Card title="Suggested Insights">
            <Row>
         <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_suggested_insights_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:get_suggested_insights_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_suggested_insights_params.page,
            total:get_suggested_insights_params.total,
            showTotal:showTotal
          }}
          expandedRowRender= {record =>{
            console.log(record,"record")
            return <React.Fragment>
              <div key={record._id}>
                <Table
                        columns={professionals_collumns}
                        dataSource={selected_row}
                />
              </div>
      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record._id}
        //   onRow={(r) => ({
        //     onClick: (b,a) => set_selected_row(r.serviceInsights),
        //   })}
         
          />
          </Col>
         
          </Row>
        </Card>
        </React.Fragment>)
}
export default SuggestedInsights