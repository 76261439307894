import React, {useState,useEffect} from 'react';
import CatalogueView from './CatalogueView';
import { Table } from 'antd';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import {get_catalogue,get_catalogue_ret,get_catalogue_byid,get_catalogue_byid_ret,catalogue_view} from '../../actions/catalogue';
// import data from './MOCK_DATA.json';

const PanelTable = (props) => {
    const [page,setPage] = useState(1);
    const [dataId, setDataId] = useState({});
    // const [view,setView] = useState(false);

    const ViewHandler = (e,record) => {
        e.preventDefault()
        props.get_catalogue_byid(record._id);
        props.catalogue_view(true);
        // console.log(record._id)
        // console.log(props.catalogue_store);
    }

    useEffect(() => {
        console.log(props.catalogue_store);
        setDataId({...props.catalogue_store.catalogue_byid});
        // if(props.catalogue_store.catalogue_byid.success){
        //     if(props.catalogue_view)
        //         props.view();
        // }
            
        console.log(dataId);
    },[props.catalogue_store.view])

    const columns = [
        // {
        //     title: 'S. No.',
        //     dataIndex: 'sNo',
        //     key: 'id',
        //     width: '5',
        //     fixed: 'left',
        //     render: () => {
        //         const num = page;
        //         setPage(num+1);
        //         return (<p>num</p>);
        //     }
        // },
        {
            title: 'Hostpital Name',
            dataIndex: 'hospitalName',
            key: 'id',
            width: '150'

        },
        {
            title: 'Posted At',
            dataIndex: 'createdAt',
            key: 'id',
            width: '100'

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'id',
            width: '100'

        },
        {
            title: 'Added to Hospital',
            dataIndex: 'added',
            key: 'id',
            width: '100',
            render: text => text ? `True` : `False`

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'id',
            width: '100',
            render: (text,record) => {
                // console.log(record._id)
                return <a onClick={(e) => ViewHandler(e,record)}>View</a>
                
            }

        },
    ]

    // console.log(props.panel);
    return (
        <div>
            {/* {view ? 
            (
            <CatalogueView data={dataId}/>
            ) : 
            (
                <Table columns={columns} dataSource={props.panel} scroll={{ x: 550, y: 500 }} />
            ) } */}
            <Table columns={columns} dataSource={props.panel} scroll={{ x: 550, y: 450 }} />
        </div>
    )
}


const mapStateToProps = (state) => ({
    catalogue_store: state.catalogue_store
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ get_catalogue_byid, catalogue_view }, dispatch);


export default connect(mapStateToProps,mapDispatchToProps)(PanelTable);