import React, { useState } from "react";
import { Button, Card, Form, Row, Col } from 'antd';
// import { global_error, global_error_clr, global_loading_set, get_services } from "../../../actions/global"
import AntdInput from "../../Components/AntdInput";
import { error_form_check, get_url } from "../../utils/common_utilities";
import axios from 'axios';
const CreatePages = (props) => {

    const [form] = Form.useForm()
    const [htmlContent, setHtmlContent] = useState(null);


    const [data, set_data] = useState({
        formTreatmentName: '',
        title: '',
        description: '',
        url: '',
        topHeading: '',
        serviceName: '',
        oneLinersHeading: '',
        treatment: '',
        formHeading: '',
        bigChainPricesWithoutPlunes: '',
        multiSpecialityPricesWithoutPlunes: '',
        testimonialHeading: '',
        procedureHeading: '',
        procedureDefination: '',
        riskHeading: '',
        customerTestimonialHeading: '',
        oneLiners: ['',],
        bigChainPricesWithPlunes: [],
        multiSpecialityPricesWithPlunes: [],
        testimonialList: [{ imageUrl: '', videoUrl: '', heading: '' }],
        doctorList: [{ imageUrl: '', name: '', experience: '', designation: '', hospitalName: '', hospitalAdress: '', consultationFee: '', rating: '', speciality: '' }],
        hospitalList: [{ imageUrl: '', rating: '', name: '', adress: '', speciality: `` }],
        knowMoreList: [{ heading: "", points: [''] }, { heading: "", points: [''] }],
        benifitList: [{ withPlunes: '', withoutPlunes: '' }],
        customerTestimonialList: [{ name: '', rating: '', description: '', imageUrl: '' }],
        riskList: [''],
        faqList: [{ question: '', answer: '' }],
    })
    const [error, set_error] = useState({
        formTreatmentName: {state: false, text: ""},
        title: { state: false, text: "" },
        description: { state: false, text: "" },
        url: { state: false, text: "" },
        topHeading: { state: false, text: "" },
        serviceName: { state: false, text: "" },
        oneLinersHeading: { state: false, text: "" },
        treatment: { state: false, text: "" },
        formHeading: { state: false, text: "" },
        bigChainPricesWithoutPlunes: { state: false, text: "" },
        multiSpecialityPricesWithoutPlunes: { state: false, text: "" },
        testimonialHeading: { state: false, text: "" },
        procedureHeading: { state: false, text: "" },
        procedureDefination: { state: false, text: "" },
        riskHeading: { state: false, text: "" },
        customerTestimonialHeading: { state: false, text: "" },
        oneLiners: ['',],
        bigChainPricesWithPlunes: [],
        multiSpecialityPricesWithPlunes: [],
        testimonialList: [],
        doctorList: [],
        hospitalList: [],
        knowMoreList: [],
        benifitList: [],
        customerTestimonialList: [],
        riskList: [],
        faqList: [],
    })

    const onChange = (name, val) => {
        set_data({ ...data, [name]: val });
    }

    const onChangeList = (listname, index, name = '', val) => {
        const newVal = [...data[listname]];

        if (index > newVal.length) {
            newVal.push({ [name]: val });
        }
        else {
            newVal[index][name] = val;
        }
        set_data({ ...data, [listname]: newVal });
    }

    const onChangeOneLiner = (listname, index, val) => {
        const newVal = [...data[listname]];

        if (index > newVal.length) {
            newVal.push(val);
        }
        else {
            newVal[index] = val;
        }
        set_data({ ...data, [listname]: newVal });
    }

    const onChangeKnowMoreHeading = (name, index, heading) => {
        const newData = [...data[name]];
        newData[index].heading = heading;

        set_data({ ...data, [name]: newData });
    }
    const onChangeKnowMorePoints = (name, index, pointIndex, point) => {
        const newData = [...data[name]];
        newData[index].points[pointIndex] = point;
        set_data({ ...data, newData });
    }

    const handleAddKnowMore = (listname, index) => {
        const newData = data[listname];

        newData[index].points.push('');
        set_data({ ...data, [listname]: newData });
    }

    const handleAdd = (listname) => {
        const newData = data[listname];

        const first = newData?.[0] || {};

        const newField = {};
        Object.keys(first).forEach(key => {
            newField[key] = '';
        })
        newData.push(newField);
        set_data({ ...data, [listname]: newData });
    }

    const handleAddOneLiner = (listname) => {
        const newData = data[listname];
        newData.push('');
        set_data({ ...data, [listname]: newData });
    }
    const validate = (data) => {
        const ret_error = { ...error };

        if (data.formTreatmentName === '') {
            ret_error.formTreatmentName.text = "Select Form Treatment Name"
            ret_error.formTreatmentName.state = true
        }

        if (data.title === '') {
            ret_error.title.text = "Select Title"
            ret_error.title.state = true
        }

        if (data.description === '') {
            ret_error.description.text = "Select Description"
            ret_error.description.state = true
        }

        if (data.url === '') {
            ret_error.url.text = "Select Url"
            ret_error.url.state = true
        }

        if (data.topHeading === '') {
            ret_error.topHeading.text = "Select Top Heading"
            ret_error.topHeading.state = true
        }

        if (data.serviceName === '') {
            ret_error.serviceName.text = "Select Service Name"
            ret_error.serviceName.state = true
        }

        if (data.oneLinersHeading === '') {
            ret_error.oneLinersHeading.text = "Select One Liners Heading"
            ret_error.oneLinersHeading.state = true
        }

        if (data.treatment === '') {
            ret_error.treatment.text = "Select Treatment"
            ret_error.treatment.state = true
        }

        if (data.formHeading === '') {
            ret_error.formHeading.text = "Select Form Heading"
            ret_error.formHeading.state = true
        }

        // if (data.bigChainPricesWithoutPlunes === '') {
        //     ret_error.bigChainPricesWithoutPlunes.text = "Select Big Chain Prices Without Plunes"
        //     ret_error.bigChainPricesWithoutPlunes.state = true
        // }

        // if (data.multiSpecialityPricesWithoutPlunes === '') {
        //     ret_error.multiSpecialityPricesWithoutPlunes.text = "Select Multi Speciality Prices Without Plunes"
        //     ret_error.multiSpecialityPricesWithoutPlunes.state = true
        // }

        // if (data.testimonialHeading === '') {
        //     ret_error.testimonialHeading.text = "Select Testimonial Heading"
        //     ret_error.testimonialHeading.state = true
        // }

        if (data.procedureHeading === '') {
            ret_error.procedureHeading.text = "Select Procedure Heading"
            ret_error.procedureHeading.state = true
        }

        if (data.procedureDefination === '') {
            ret_error.procedureDefination.text = "Select Procedure Defination"
            ret_error.procedureDefination.state = true
        }

        // if (data.riskHeading === '') {
        //     ret_error.riskHeading.text = "Select Risk Heading"
        //     ret_error.riskHeading.state = true
        // }

        // if (data.customerTestimonialHeading === '') {
        //     ret_error.customerTestimonialHeading.text = "Select Customer Testimonial Heading"
        //     ret_error.customerTestimonialHeading.state = true
        // }
        return ret_error;
    }

    const downloadHtmlFile = () => {
        const blob = new Blob([htmlContent], { type: 'text/html' });

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'marketing-file.html';
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const submit = async () => {
        const ret_error = validate({ ...data });
        set_error({ ...error, ...ret_error });
        
        setHtmlContent(null);
        // console.log(htmlContent===null,"RESPONSE")
        if (!error_form_check(error)) {
            try {
                const url = `${get_url()}/campaign/createMarketingPages`;
                const res = await axios.post(url, data);
                console.log({ res: res.data }, "RESPONSE");
                setHtmlContent(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
    }
    // const [initial_data, set_initial_data] = useState({})
    return (<Card title='Create New Marketing Page'>
        <Form
            form={form}
            layout="vertical"
        >
            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Form Treatment Name"
                        value={data.formTreatmentName}
                        error_text={error.formTreatmentName.text}
                        toggle_error={() => set_error({ ...error, formTreatmentName: { text: '', state: false } })}
                        error={error.formTreatmentName.state}
                        name="Form Treatment Name"
                        type="input"
                        onChange={event => onChange("formTreatmentName", event.target.value)}
                        placeholder="Enter Form Treatment Name" />
                </span>
            </div>
            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Title"
                        value={data.title}
                        error_text={error.title.text}
                        toggle_error={() => set_error({ ...error, title: { text: '', state: false } })}
                        error={error.title.state}
                        name="title"
                        type="input"
                        onChange={event => onChange("title", event.target.value)}
                        placeholder="Enter Title" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Description"
                        value={data.description}
                        error_text={error.description.text}
                        toggle_error={() => set_error({ ...error, description: { text: '', state: false } })}
                        error={error.description.state}
                        name="description"
                        type="input"
                        onChange={event => onChange("description", event.target.value)}
                        placeholder="Enter Description" />
                </span>
            </div>
            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Url"
                        value={data.url}
                        error_text={error.url.text}
                        toggle_error={() => set_error({ ...error, url: { text: '', state: false } })}
                        error={error.url.state}
                        name="url"
                        type="input"
                        onChange={event => onChange("url", event.target.value)}
                        placeholder="Enter Url" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Top Heading"
                        value={data.topHeading}
                        error_text={error.topHeading.text}
                        toggle_error={() => set_error({ ...error, topHeading: { text: '', state: false } })}
                        error={error.topHeading.state}
                        name="topHeading"
                        type="input"
                        onChange={event => onChange("topHeading", event.target.value)}
                        placeholder="Enter Top Heading" />
                </span>
            </div>
            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Service Name"
                        value={data.serviceName}
                        error_text={error.serviceName.text}
                        toggle_error={() => set_error({ ...error, serviceName: { text: '', state: false } })}
                        error={error.serviceName.state}
                        name="serviceName"
                        type="input"
                        onChange={event => onChange("serviceName", event.target.value)}
                        placeholder="Enter Service Name" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="One Liners Heading"
                        value={data.oneLinersHeading}
                        error_text={error.oneLinersHeading.text}
                        toggle_error={() => set_error({ ...error, oneLinersHeading: { text: '', state: false } })}
                        error={error.oneLinersHeading.state}
                        name="oneLinersHeading"
                        type="input"
                        onChange={event => onChange("oneLinersHeading", event.target.value)}
                        placeholder="Enter One Liners Heading" />
                </span>
            </div>
            {
                data.oneLiners.length > 0 &&
                <div>
                    {
                        data.oneLiners.map((line, index) =>
                        (<div className="flex-parent-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label={index === 0 ? 'Add One Liners' : ''}
                                    value={line}
                                    name="oneLiners"
                                    type="input"
                                    onChange={event => onChangeOneLiner("oneLiners", index, event.target.value)}
                                    placeholder="Enter One Liners Heading" />
                            </span>
                        </div>))
                    }
                    <Button onClick={() => handleAddOneLiner("oneLiners")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>
                </div>

            }
            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Treatment"
                        value={data.treatment}
                        error_text={error.treatment.text}
                        toggle_error={() => set_error({ ...error, treatment: { text: '', state: false } })}
                        error={error.treatment.state}
                        name="treatment"
                        type="input"
                        onChange={event => onChange("treatment", event.target.value)}
                        placeholder="Enter Treatment" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Form Heading"
                        value={data.formHeading}
                        error_text={error.formHeading.text}
                        toggle_error={() => set_error({ ...error, formHeading: { text: '', state: false } })}
                        error={error.formHeading.state}
                        name="formHeading"
                        type="input"
                        onChange={event => onChange("formHeading", event.target.value)}
                        placeholder="Enter Form Heading" />
                </span>
            </div>
            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Big Chain Prices Without Plunes"
                        value={data.bigChainPricesWithoutPlunes}
                        error_text={error.bigChainPricesWithoutPlunes.text}
                        toggle_error={() => set_error({ ...error, bigChainPricesWithoutPlunes: { text: '', state: false } })}
                        error={error.bigChainPricesWithoutPlunes.state}
                        name="bigChainPricesWithoutPlunes"
                        type="input"
                        onChange={event => onChange("bigChainPricesWithoutPlunes", event.target.value)}
                        placeholder="Enter Big Chain Prices Without Plunes" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Multi Speciality Prices Without Plunes"
                        value={data.multiSpecialityPricesWithoutPlunes}
                        error_text={error.multiSpecialityPricesWithoutPlunes.text}
                        toggle_error={() => set_error({ ...error, multiSpecialityPricesWithoutPlunes: { text: '', state: false } })}
                        error={error.multiSpecialityPricesWithoutPlunes.state}
                        name="multiSpecialityPricesWithoutPlunes"
                        type="input"
                        onChange={event => onChange("multiSpecialityPricesWithoutPlunes", event.target.value)}
                        placeholder="Enter Multi Speciality Prices Without Plunes" />
                </span>
            </div>

            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Big Chain Prices With Plunes Lower Limit"
                        value={data.bigChainPricesWithPlunes?.[0]}
                        name="bigChainPricesWithPlunesLower"
                        type="input"
                        onChange={event => onChangeOneLiner("bigChainPricesWithPlunes", 0, event.target.value)}
                        placeholder="Enter Big Chain Prices With Plunes Lower Limit" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Big Chain Prices With Plunes Upper Limit"
                        value={data.bigChainPricesWithPlunes?.[1]}
                        name="bigChainPricesWithPlunesUpper"
                        type="input"
                        onChange={event => onChangeOneLiner("bigChainPricesWithPlunes", 1, event.target.value)}
                        placeholder="Enter Big Chain Prices With Plunes Upper Limit" />
                </span>
            </div>

            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Multi Speciality Prices With Plunes Lower Limit"
                        value={data.multiSpecialityPricesWithPlunes?.[0]}
                        name="multiSpecialityPricesWithPlunesLower"
                        type="input"
                        onChange={event => onChangeOneLiner("multiSpecialityPricesWithPlunes", 0, event.target.value)}
                        placeholder="Enter Multi Speciality Prices With Plunes Lower Limit" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Multi Speciality Prices With Plunes Upper Limit"
                        value={data.multiSpecialityPricesWithPlunes?.[1]}
                        name="multiSpecialityPricesWithPlunesUpper"
                        type="input"
                        onChange={event => onChangeOneLiner("multiSpecialityPricesWithPlunes", 1, event.target.value)}
                        placeholder="Enter Multi Speciality Prices With Plunes Upper Limit" />
                </span>
            </div>

            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Testimonial Heading"
                        value={data.testimonialHeading}
                        error_text={error.testimonialHeading.text}
                        toggle_error={() => set_error({ ...error, testimonialHeading: { text: '', state: false } })}
                        error={error.testimonialHeading.state}
                        name="testimonialHeading"
                        type="input"
                        onChange={event => onChange("testimonialHeading", event.target.value)}
                        placeholder="Enter Testimonial Heading" />
                </span>
            </div>

            {data.testimonialList.length > 0 &&
                <div>
                    <label style={{ marginLeft: 30, fontWeight: 'bold' }}>Add Testimonials</label>
                    {
                        data.testimonialList.map((item, index) =>
                            <>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.imageUrl}
                                            name="imageUrl"
                                            type="input"
                                            onChange={event => onChangeList("testimonialList", index, "imageUrl", event.target.value)}
                                            placeholder="Enter imageUrl" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.videoUrl}
                                            name="videoUrl"
                                            type="input"
                                            onChange={event => onChangeList("testimonialList", index, "videoUrl", event.target.value)}
                                            placeholder="Enter videourl" />
                                    </span>
                                </div>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.heading}
                                            name="heading"
                                            type="input"
                                            onChange={event => onChangeList("testimonialList", index, "heading", event.target.value)}
                                            placeholder="Enter heading" />
                                    </span>
                                </div>
                            </>)
                    }
                    <Button onClick={() => handleAdd("testimonialList")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                </div>

            }

            {data.doctorList.length > 0 &&
                <div>
                    <label style={{ marginLeft: 30, fontWeight: 'bold' }}>Add Doctors</label>
                    {
                        data.doctorList.map((item, index) =>
                            <>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.name}
                                            name="name"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "name", event.target.value)}
                                            placeholder="Enter Name" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.experience}
                                            name="experience"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "experience", event.target.value)}
                                            placeholder="Enter Experience" />
                                    </span>

                                </div>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.designation}
                                            name="designation"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "designation", event.target.value)}
                                            placeholder="Enter Designation" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.hospitalName}
                                            name="hospitalName"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "hospitalName", event.target.value)}
                                            placeholder="Enter Hospital Name" />
                                    </span>
                                </div>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.hospitalAdress}
                                            name="hospitalAdress"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "hospitalAdress", event.target.value)}
                                            placeholder="Enter Hospital Adress" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.consultationFee}
                                            name="consultationFee"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "consultationFee", event.target.value)}
                                            placeholder="Enter Consultation Fee" />
                                    </span>
                                </div>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.speciality}
                                            name="speciality"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "speciality", event.target.value)}
                                            placeholder="Enter Speciality" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.rating}
                                            name="rating"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "rating", event.target.value)}
                                            placeholder="Enter Rating" />
                                    </span>
                                </div>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.imageUrl}
                                            name="imageUrl"
                                            type="input"
                                            onChange={event => onChangeList("doctorList", index, "imageUrl", event.target.value)}
                                            placeholder="Enter Image Url" />
                                    </span>
                                </div>
                            </>)
                    }
                    <Button onClick={() => handleAdd("doctorList")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                </div>

            }

            {data.hospitalList.length > 0 &&
                <div>
                    <label style={{ marginLeft: 30, fontWeight: 'bold' }}>Add Hospitals</label>
                    {
                        data.hospitalList.map((item, index) =>
                            <>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.name}
                                            name="name"
                                            type="input"
                                            onChange={event => onChangeList("hospitalList", index, "name", event.target.value)}
                                            placeholder="Enter Name" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.adress}
                                            name="adress"
                                            type="input"
                                            onChange={event => onChangeList("hospitalList", index, "adress", event.target.value)}
                                            placeholder="Enter Adress" />
                                    </span>
                                </div>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.rating}
                                            name="rating"
                                            type="input"
                                            onChange={event => onChangeList("hospitalList", index, "rating", event.target.value)}
                                            placeholder="Enter Rating" />
                                    </span>



                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.speciality}
                                            name="speciality"
                                            type="input"
                                            onChange={event => onChangeList("hospitalList", index, "speciality", event.target.value)}
                                            placeholder="Enter Speciality" />
                                    </span>
                                </div>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.imageUrl}
                                            name="imageUrl"
                                            type="input"
                                            onChange={event => onChangeList("hospitalList", index, "imageUrl", event.target.value)}
                                            placeholder="Enter Image Url" />
                                    </span>
                                </div>
                            </>)
                    }
                    <Button onClick={() => handleAdd("hospitalList")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                </div>

            }

            {data.benifitList.length > 0 &&
                <div>
                    <label style={{ marginLeft: 30, fontWeight: 'bold' }}>Add Benifits With and Without Plunes</label>
                    {
                        data.benifitList.map((item, index) =>
                            <>
                                <div className="flex-parent-new">

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.withPlunes}
                                            name="withPlunes"
                                            type="input"
                                            onChange={event => onChangeList("benifitList", index, "withPlunes", event.target.value)}
                                            placeholder="Enter With Plunes" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.withoutPlunes}
                                            name="withoutPlunes"
                                            type="input"
                                            onChange={event => onChangeList("benifitList", index, "withoutPlunes", event.target.value)}
                                            placeholder="Enter Without Plunes" />
                                    </span>

                                </div>

                            </>)
                    }
                    <Button onClick={() => handleAdd("benifitList")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                </div>

            }

            {data.faqList.length > 0 &&
                <div>
                    <label style={{ marginLeft: 30, fontWeight: 'bold' }}>Add FAQs</label>
                    {
                        data.faqList.map((item, index) =>
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        value={item.question}
                                        name="question"
                                        type="input"
                                        onChange={event => onChangeList("faqList", index, "question", event.target.value)}
                                        placeholder="Enter Question" />
                                </span>

                                <span className="flex-child-1-new">
                                    <AntdInput
                                        value={item.answer}
                                        name="answer"
                                        type="input"
                                        onChange={event => onChangeList("faqList", index, "answer", event.target.value)}
                                        placeholder="Enter Answer" />
                                </span>
                            </div>)
                    }
                    <Button onClick={() => handleAdd("faqList")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                </div>

            }

            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Risk Heading"
                        value={data.riskHeading}
                        error_text={error.riskHeading.text}
                        toggle_error={() => set_error({ ...error, riskHeading: { text: '', state: false } })}
                        error={error.riskHeading.state}
                        name="riskHeading"
                        type="input"
                        onChange={event => onChange("riskHeading", event.target.value)}
                        placeholder="Enter Risk Heading" />
                </span></div>

            {
                data.riskList.length > 0 &&
                <div>
                    {
                        data.riskList.map((line, index) =>
                        (<div className="flex-parent-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label={index === 0 ? 'Add Risks related to procedure' : ''}
                                    value={line}
                                    name="riskList"
                                    type="input"
                                    onChange={event => onChangeOneLiner("riskList", index, event.target.value)}
                                    placeholder="Enter One Liners Heading" />
                            </span>
                        </div>))
                    }
                    <Button onClick={() => handleAddOneLiner("riskList")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>
                </div>

            }

            {
                data.knowMoreList.length > 0 &&
                <>
                    <label style={{ marginLeft: 30, fontWeight: 'bold' }}>Add Know More data</label>
                    <div className="flex-parent-new">
                        <div className="flex-child-1-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="Know More Heading"
                                    value={data.knowMoreList?.[0]?.heading}
                                    name="knowMoreList"
                                    type="input"
                                    onChange={event => onChangeKnowMoreHeading("knowMoreList", 0, event.target.value)}
                                    placeholder="Enter Know More Heading" />
                            </span>
                            {
                                data.knowMoreList?.[0].points?.map((line, index) =>
                                (<div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            label={index === 0 ? 'Add Know More points' : ''}
                                            value={line}
                                            name="knowMoreList"
                                            type="input"
                                            onChange={event => onChangeKnowMorePoints("knowMoreList", 0, index, event.target.value)}
                                            placeholder="Enter Know More points" />
                                    </span>
                                </div>))
                            }
                            <Button onClick={() => handleAddKnowMore("knowMoreList", 0)} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                        </div>
                        <div className="flex-child-1-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="Know More Heading"
                                    value={data.knowMoreList?.[1]?.heading}
                                    name="knowMoreList"
                                    type="input"
                                    onChange={event => onChangeKnowMoreHeading("knowMoreList", 1, event.target.value)}
                                    placeholder="Enter Know More Heading" />
                            </span>
                            {
                                data.knowMoreList?.[1].points?.map((line, index) =>
                                (<div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            label={index === 0 ? 'Add Know More points' : ''}
                                            value={line}
                                            name="knowMoreList"
                                            type="input"
                                            onChange={event => onChangeKnowMorePoints("knowMoreList", 1, index, event.target.value)}
                                            placeholder="Enter Know More points" />
                                    </span>
                                </div>))
                            }
                            <Button onClick={() => handleAddKnowMore("knowMoreList", 1)} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                        </div>
                    </div>
                </>

            }

            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Procedure Heading"
                        value={data.procedureHeading}
                        error_text={error.procedureHeading.text}
                        toggle_error={() => set_error({ ...error, procedureHeading: { text: '', state: false } })}
                        error={error.procedureHeading.state}
                        name="procedureHeading"
                        type="input"
                        onChange={event => onChange("procedureHeading", event.target.value)}
                        placeholder="Enter Procedure Heading" />
                </span>
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Procedure Defination"
                        value={data.procedureDefination}
                        error_text={error.procedureDefination.text}
                        toggle_error={() => set_error({ ...error, procedureDefination: { text: '', state: false } })}
                        error={error.procedureDefination.state}
                        name="procedureDefination"
                        type="input"
                        onChange={event => onChange("procedureDefination", event.target.value)}
                        placeholder="Enter Procedure Defination" />
                </span>

            </div>
            <div className="flex-parent-new">
                <span className="flex-child-1-new">
                    <AntdInput
                        label="Customer Testimonial Heading"
                        value={data.customerTestimonialHeading}
                        error_text={error.customerTestimonialHeading.text}
                        toggle_error={() => set_error({ ...error, customerTestimonialHeading: { text: '', state: false } })}
                        error={error.customerTestimonialHeading.state}
                        name="customerTestimonialHeading"
                        type="input"
                        onChange={event => onChange("customerTestimonialHeading", event.target.value)}
                        placeholder="Enter Customer Testimonial Heading" />
                </span>
            </div>

            {data.customerTestimonialList.length > 0 &&
                <div>
                    <label style={{ marginLeft: 30, fontWeight: 'bold' }}>Add Customer Testimonials</label>
                    {
                        data.customerTestimonialList.map((item, index) =>
                            <>
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.name}
                                            name="name"
                                            type="input"
                                            onChange={event => onChangeList("customerTestimonialList", index, "name", event.target.value)}
                                            placeholder="Enter Name" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.rating}
                                            name="rating"
                                            type="input"
                                            onChange={event => onChangeList("customerTestimonialList", index, "rating", event.target.value)}
                                            placeholder="Enter Rating" />
                                    </span>
                                </div>
                                <div className="flex-parent-new">


                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.description}
                                            name="description"
                                            type="input"
                                            onChange={event => onChangeList("customerTestimonialList", index, "description", event.target.value)}
                                            placeholder="Enter Description" />
                                    </span>

                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            value={item.imageUrl}
                                            name="imageUrl"
                                            type="input"
                                            onChange={event => onChangeList("customerTestimonialList", index, "imageUrl", event.target.value)}
                                            placeholder="Enter Image Url" />
                                    </span>

                                </div>
                            </>)
                    }
                    <Button onClick={() => handleAdd("customerTestimonialList")} style={{ marginLeft: 20, marginBottom: 30 }}>Add</Button>

                </div>

            }

            <Button
                type="primary"
                onClick={submit}
                style={{ width: 'auto', margin: 'auto',marginLeft:20 }}
            >
                Submit
            </Button>
        </Form>
        
            <Button
                disabled={htmlContent? false:true}
                type="primary"
                onClick={downloadHtmlFile}
                style={{ margin: 20 }}
            >Download HTML File</Button>
    </Card >);
}
export default CreatePages;