import React, {Component, useEffect,useState} from 'react'
import {get_catalogue,get_catalogue_ret,get_catalogue_loading} from '../../actions/catalogue';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import api from '../../utils/api_routes';
import store from '../../store'
import Panel from './Panel'
// import {get_catalogue} from '../../actions/catalogue';


class Catalogue extends Component {

    constructor(props){
        super(props)
        this.state = {
            valid:'no data'
        }
    }

    componentDidMount() {
        if(this.props.catalogue_store[0]){

        }
        else{
            this.props.get_catalogue(); 
        }
        
    }

    nameHandler = (length) => {
        console.log(length[0]);
        if(length>0){
            // res = 'load'
            this.setState({valid:'loaded'});
            console.log(this.state.valid);
        }
    }

    render() {
        console.log(this.props.catalogue_store);

        this.nameHandler(this.props.catalogue_store);
        // if(this.props.catalogue_store.length>0){
        //     res = 'load'
        //     this.setState({valid:'2'});
        //     console.log(this.state.valid);
        // }
        
        return (
            <div>{this.state.valid}</div>
            // <Panel data={this.props.catalogue_store}/>
        )
    }
}




// const Catalogue = (props) => {
//     const [panel,setPanel] = useState({});
//     const [load,setLoad] = useState(false);
//     useEffect(() => {
//         props.get_catalogue();
        
//     },[])

//     useEffect(() => {
//         console.log(props.catalogue_store)
//         if(props.catalogue_store.length>0){
//             setLoad(true);
//         }
//     },[props.catalogue_store.length])

//     // const fetchData = () => {

//     //     for(let i=0;i<1;i++){
//     //         props.get_catalogue();
//     //         // const d = api.catalogue.get_catalogue();
//     //         // console.log(d);
//     //         console.log(store.getState());
//     //         console.log(props);
//     //         console.log(props.get_catalogue_ret);
//     //         if(props.catalogue_store){
//     //             setPanel({...props.catalogue_store});
//     //         }
//     //         console.log(panel);
//     //     }
//     //     console.log(panel);
       
//     // }

//     return (
//         <div>
//             {load ? <p>loaded</p> : <p> no data</p>}
//             {/* {props.catalogue_store.map(data => <p>data</p>)} */}
//         </div>
//     )
// }

const mapStateToProps = (state) => ({
    catalogue_store: state.catalogue_store
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ get_catalogue }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Catalogue);