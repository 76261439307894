import React, { useEffect } from "react"
import { Button, Card, Form, Row, Col, notification, Input } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { get_url } from "../../utils/common_utilities";
import axios from "axios";

const MostBookedServices = (props) => {

    const [data, set_data] = React.useState({
        meta_title: '',
        meta_description: '',
        definition: '',
        dnd: ['']
    })
    const [options, setOptions] = React.useState([]);
    const [speciality, setSpeciality] = React.useState("");
    const [form] = Form.useForm()

    useEffect(() => {

        const getOptions = async (url) => {
            try {
                const initial_options = await axios.get(url);
                // console.log({initial_options},"INITIAL");
                const newOptions = initial_options?.data?.data?.map(item => ({ name: item?.serviceName, value: item?._id }));
                setOptions(newOptions);

                const initial_data = initial_options?.data?.data?.find(item => (item?._id === speciality));
                set_data({ meta_title: initial_data?.metaData?.title, meta_description: initial_data?.metaData?.desc, definition: initial_data?.definition, dnd: initial_data?.dnd });
            }
            catch (error) {
                notification["error"]({
                    message: 'Error',
                    description: "An Error Occurred!! Please try again later.",
                })
            }
        }
       
        const url = `${get_url()}/mcmCatalogue/getMostBookedTreatments`;
        getOptions(url);

    }, [speciality])

    const submit = async () => {
        const url = `${get_url()}/mcmCatalogue/updateMostBookedTreatmentsById?id=${speciality}`;
        try {
            for (let i = 0; i < data.dnd.length; i++){
                if (data.dnd[i] === '') {
                    notification['error']({
                        message: 'Error',
                        description: "Fill in all do's and don'ts!"
                    })
                    return
                }
            }
            console.clear()
            console.log(data)
            const response = await axios.post(url, data);

            if (response?.data?.success) {
                // console.log({response},"HEREEEE")
                notification["success"]({
                    message: 'Success',
                    description: "Data added sucessfully",
                })
            }
        }
        catch (error) {
            notification["error"]({
                message: 'Error',
                description: "An Error Occurred!! Please try again later.",
            })
        }

    }

    const handleDndChange = (newValue, idx) => {
        let newDnds = data.dnd
        newDnds[idx] = newValue
        set_data({
            ...data,
            dnd: newDnds
        })
    }

    const handleDndRemove = (idx) => {
        let newDnds = []
        for (let i = 0; i < data.dnd.length; i++){
            if (i !== idx) {
                newDnds.push(data.dnd[i])
            }
        }
        set_data({
            ...data,
            dnd: newDnds
        })
    }

    const handleDndAdd = () => {
        let newDnds = data.dnd
        newDnds.push('')
        set_data({
            ...data,
            dnd: newDnds
        })
    }

    return (<React.Fragment>
        <Card title="Update Most Booked Services Meta Data" bordered={true}>
            <Row >
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <div className="flex-parent-new">
                            <AntdInput
                                label="Most Booked Treatment"
                                value={speciality}
                                disabled={props.get_bids_loading_flag || props.add_service_loading_flag}
                                options={options}
                                loading={props.global_store.get_specialities_loading || props.add_service_loading_flag}
                                name="speciality"
                                type="select"
                                onChange={event => setSpeciality(event)}
                                placeholder="Choose Most Booked Treatment" />
                        </div>
                        <div className="flex-parent-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="MetaData Title"
                                    value={data.meta_title}
                                    disabled={props.update_professional_byid_loading_flag}
                                    loading={props.update_professional_byid_loading_flag}
                                    name="meta_title"
                                    onChange={event => set_data({ ...data, meta_title: event.target.value })}
                                    placeholder="Enter MetaData Title" />
                            </span>

                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="MetaData Description"
                                    value={data.meta_description}
                                    disabled={props.update_professional_byid_loading_flag}
                                    loading={props.update_professional_byid_loading_flag}
                                    name="meta_description"
                                    onChange={event => set_data({ ...data, meta_description: event.target.value })}
                                    placeholder="Enter MetaData Description" />
                            </span>
                        </div>
                        <div className="flex-parent-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="Definition"
                                    type="textarea"
                                    value={data.definition}
                                    disabled={props.update_professional_byid_loading_flag}
                                    loading={props.update_professional_byid_loading_flag}
                                    name="definition"
                                    onChange={event => set_data({ ...data, definition: event.target.value })}
                                    placeholder="Enter Definition" />
                            </span>
                        </div>
                        <div className="flex-parent-new">
                            <span className="flex-child-1-new">
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                                    <label style={{ display: 'flex', alignItems: 'flex-end' }}>Do's and Don'ts</label>
                                    <Button onClick={handleDndAdd}>Add More</Button>
                                </div>
                                {
                                    data?.dnd?.map((item, idx) => (
                                        <div key={idx} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Input
                                                type="text"
                                                value={data.dnd[idx]}
                                                disabled={props.update_professional_byid_loading_flag}
                                                loading={props.update_professional_byid_loading_flag}
                                                onChange={e => handleDndChange(e.target.value, idx)}
                                                style={{ marginBottom: '16px', flexGrow: '1' }}
                                            />
                                            <Button style={{ marginLeft: '16px' }} onClick={() => handleDndRemove(idx)}>Remove</Button>
                                        </div>
                                    ))
                                }
                            </span>
                        </div>


                        <div className=" u-margin-top-small text-center">
                            <Button
                                type="primary"
                                loading={props.add_service_loading_flag}
                                disabled={props.add_service_loading_flag}
                                onClick={() => submit()}
                                style={{ width: 'auto', margin: 'auto' }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>


        </Card>
    </React.Fragment>)
}
export default MostBookedServices;