import React, { useState } from "react"
import { Layout, Menu } from 'antd';
import HeaderComponent from "../Components/Header";
import  GlobalLoading from "../Components/GlobalLoading";
import Sidebar from "../Components/Sidebar";
import { connect } from 'react-redux';
import { get_user } from '../actions/user'
import { global_loading_set, get_specialities , global_error, upload_progress_toggle, get_agent_list, get_agent_list_loading,
   set_global_upload, upload_progress_data} from '../actions/global'
import { logout } from "../actions/Auth"
import { get_url, isEmpty } from "../utils/common_utilities"
import MemberContainer from "./MemberContainer"
import Bidding from "./Bidding"
import Blogs from './Blogs'
import Professionals from "./Professionals"
import KeyManager from "./KeyManager"
import Salesfunnel from "./Salesfunnel"
import InsightsAnalysis from "./InsightsAnalysis"
import Bookings from "./Bookings"
import CatalogueManager from "./CatalogueManager"
import { upload_catalogue_loading, check_upload_status, check_upload_status_loading } from "../actions/catalogue_manager"
import { get_notifications, get_notifications_loading, set_notification_data, clear_count } from '../actions/notifications'
import Users from "./Users"
import FollowUp from "./FollowUps"
import InsuranceBookings from "./InsuranceBookings"
import Notifications from "./Notifications"
import CreateMarketingPages from "./CreateMarketingPages";

import { withRouter } from "react-router-dom"
import Funnel from "./Funnel";
import { BellFilled } from "@ant-design/icons";
import axios from "axios";
import AnywhereCashless from "./AnywhereCashless";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;


const Dashboard = (props) => {

    const [sidebar_toggle, set_sidebar_toggle] = React.useState(true)

    const [queryNotif, setQueryNotif] = useState({
      active: false,
      message: "",
      id: null
    })

  const progress_check =  () => {
  window.upload_status_setInterval =   setInterval(()=>{
      console.log("Progress check executed")
      props.check_upload_status({
        file_name:props.global_store.upload_file.filename
      })
  }, 3000)
  }

  const markeNotifasRead = async () => {
    if(queryNotif.active && queryNotif.id) {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      await axios.put(`${get_url()}/notification/readNotificationById?id=${queryNotif.id}`,{},headers)
      setQueryNotif({
        active: false,
        message: "",
        id: null
      })
    }
  }

  const notification_routine =  () => {
    window.notification_routine_timeout =   setInterval(()=>{

        props.get_notifications({
         page:1,
         limit:200
        })
    }, 10000)
    }

  React.useEffect(()=>{
      if(!!isEmpty(props.user_store.user_data)){
        props.global_loading_set(true)
        props.get_user()
        props.get_specialities()
      }
      notification_routine()
      props.get_agent_list()
  },[])

  React.useEffect(()=>{
    if(props.global_store.get_specialities_ret){
      props.global_error({
       success:false,
       message:props.global_store.get_specialities_ret.message,
       heading:'Specialities',
     })
  }


//   if(props.global_store.get_agent_list_ret){
//       if(props.global_store.get_agent_list_ret.success){
//         console.log(props.global_store.get_agent_list_ret,"props.global_store.get_agent_list_ret")
//       }
// }


  if(props.global_store.get_services_ret){
    props.global_error({
     success:false,
     message:props.global_store.get_services_ret.message,
     heading:'Services',
   })
}

if(props.global_store.check_upload_status_ret){
    console.log(props.global_store.check_upload_status_ret,"props.check_upload_status_ret")
      if(props.global_store.check_upload_status_ret.success){
          
          if(props.global_store.check_upload_status_ret.status !=="Inprogress"){
              props.global_error({
                success:false,
                message:"Your catalogue has been successfully updated.",
                heading:'Catalogue Update',
              })
              window.clearInterval(window.upload_status_setInterval)
              // props.upload_progress_toggle(false)
              props.set_global_upload()
          }else {
            props.upload_progress_data({...props.global_store.check_upload_status_ret.data})
          }
      }else {
       
        props.global_error({
          success:false,
          message:props.global_store.check_upload_status_ret.message,
          heading:'Catalogue Update',
        })
      }
      props.check_upload_status_loading()
}

if(props.notification_store.get_notifications_ret){
  console.log(props.notification_store.get_notifications_ret,"props.check_upload_status_ret")
    if(props.notification_store.get_notifications_ret.success){
        
      console.log(props.notification_store.get_notifications_ret,"Inside get_notificaion_ret")
      props.set_notification_data({
        data:props.notification_store.get_notifications_ret.data,
        un_read_count:props.notification_store.get_notifications_ret.un_read_count
      })

      let newNotif = props.notification_store.get_notifications_ret.data.find((notif) => notif.notificationType === "mcmQueryReminder" && notif.read === false)
      if(newNotif && !queryNotif.active) {
        setQueryNotif({
          active: true,
          message: newNotif.notificationData,
          id: newNotif.queryId
        })
      }


    }else {
     
      props.global_error({
        success:false,
        message:props.notification_store.get_notifications_ret.message,
        heading:'Notification',
      })
    }
    props.get_notifications_loading()
}


},[props.global_store.get_services_ret, props.global_store.check_upload_status_ret, props.notification_store.get_notifications_ret])




React.useEffect(()=>{
  console.log(props.catalogue_manager_store.upload_catalogue_ret,"props.catalogue_manager_store.upload_catalogue_ret")
  if(!!props.catalogue_manager_store.upload_catalogue_ret){
    if(props.catalogue_manager_store.upload_catalogue_ret.success){
      progress_check()
      props.upload_progress_toggle()
      props.upload_catalogue_loading()
    }
  }

},[props.catalogue_manager_store.upload_catalogue_ret])

const clear_count = () => {
  console.log("Clear_Count")
      props.clear_count()
      props.set_notification_data({
        un_read_count:0,
        data:[...props.notification_store.notification_data.data]
      })
}   


// console.log(props.notification_store.notification_data,"props.notification_store.notif")
// console.log({dashboard:props.user},"DASHBOARD")


  if(props.global_store.global_loading){
    return <GlobalLoading />
  }
            return <React.Fragment>
                         <Layout>
    <div className={`query-reminder-container fade-in ${queryNotif.active && "active"}`}>
      <h1><BellFilled />Call Reminder</h1>    
      <p>{queryNotif.message}</p>
      <button onClick={(e) => {
        e.preventDefault()
        markeNotifasRead()
      }}>Mark as read</button>
    </div>
    <HeaderComponent clear_count = {clear_count} un_read_count = {props.notification_store.notification_data.un_read_count} sidebar_toggle = {sidebar_toggle} set_sidebar_toggle={set_sidebar_toggle} user={props.user_store.user_data} logout = {props.logout} />
    <Layout>
      {sidebar_toggle &&   <Sidebar user={props.user_store.user_data} /> }
  
      <Layout
      //  style={{ padding: '0 24px 24px' }}
       >
        <Content
          className="site-layout-background"
          style={{
            padding: 12,
            margin: 0,
            minHeight: 280,
          }}
        >
         <div id = "dashboard_content">
         {props.location.pathname === "/dashboard/members" && <MemberContainer location = {props.location} />}
         {props.location.pathname === "/dashboard/bidding" && <Bidding location = {props.location} />}
         {props.location.pathname === "/dashboard/blogs" && <Blogs location = {props.location} />}
         {props.location.pathname === "/dashboard/professionals" && <Professionals location = {props.location} user={props.user_store.user_data}/>}
         {props.location.pathname === "/dashboard/keymanager" && <KeyManager location = {props.location} />}
         {props.location.pathname === "/dashboard/salesfunnel" && <Salesfunnel location = {props.location} />}
         {props.location.pathname === "/dashboard/insights" && <InsightsAnalysis location = {props.location} />}
         {props.location.pathname === "/dashboard/bookings" && <Bookings location = {props.location} />}
         {props.location.pathname === "/dashboard/catalogue-manager" && <CatalogueManager location = {props.location} />}
         {props.location.pathname === "/dashboard/users" && <Users location = {props.location} />}
         {props.location.pathname === "/dashboard/follow-ups" && <FollowUp location = {props.location} />}
         {props.location.pathname === "/dashboard/notifications" && <Notifications location = {props.location} />}
         {props.location.pathname === "/dashboard/funnel" && <Funnel location = {props.location} />}
         {props.location.pathname === "/dashboard/insurance-bookings" && <InsuranceBookings location = {props.location} />}
         {props.location.pathname === "/dashboard/anywhere-cashless" && <AnywhereCashless location = {props.location} />}
         {props.location.pathname === "/dashboard/create-marketing-pages" && <CreateMarketingPages location = {props.location} />}
         
         </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>
            </React.Fragment>
}

const mapStateToProps = state => ({
  global_store:state.global_store,
  user_store:state.user_store,
  catalogue_manager_store:state.catalogue_manager_store,
  notification_store:state.notification_store
})

export default connect(mapStateToProps, {
  get_user,
  global_loading_set,
  get_specialities,
  logout,
  global_error,
  upload_catalogue_loading,
  check_upload_status,
  upload_progress_toggle,
  check_upload_status_loading,
  set_global_upload,
  upload_progress_data,
  get_notifications,
  get_notifications_loading,
  set_notification_data,
  clear_count,
  get_agent_list,
  get_agent_list_loading
 })(withRouter(Dashboard))
