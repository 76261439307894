import React from "react"
import { Button , Card,  Menu, Form, DatePicker, Checkbox  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { error_form_check, get_month} from '../../utils/common_utilities'
import { Redirect, Link } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
//import Servicelist from "./Servicelist"
import SearchLocation from "../../Components/SearchLocation"
import { set_services } from "../../actions/global";
import validator from "validator"
import moment from "moment"

const { SubMenu }  = Menu

const options = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
    { label: 'Other', value: 'O' },
  ];

const User = (props) => {
      const [state, set_state] = React.useState({
          check_status:"PENDING"
      })
      const [data, set_data] = React.useState({
        number:'',
        email:'',
        address:'',
        name:'',
        location:false,
        dob:""
      })
      const [error, set_error] = React.useState({
       number:{ state:false, text:"" },
       email:{ state:false, text:"" },
       address:{ state:false, text:"" },
       name:{ state:false, text:"" },
       location:{ state:false, text:"" }
     })
     const [checkbox, set_checkbox] = React.useState({
         value:''
     })
      const [form] = Form.useForm()

React.useEffect(()=>{
    if(props.register_user_ret){
            if(props.register_user_ret.success){
                props.global_error({
                    success:true,
                    message:props.register_user_ret.message,
                    heading:"Register User",
                  })
                  
                 props.set_user({
                    data:{...props.register_user_ret.data}
                  })
                  set_state({
                    check_status:"DONE"
                  })
            }else {
              props.global_error({
                success:false,
                message:props.register_user_ret.message,
                heading:"Register User",
              })
            }
        props.register_user_loading()
    }

},[props.register_user_ret])



const validate  = (data) =>{
    const  error = {
        number:{ state:false, text:"" },
        email:{ state:false, text:"" },
        address:{ state:false, text:"" },
        name:{ state:false, text:"" },
        location:{ state:false, text:"" },
        dob:{ state:false, text:"" }
      }
      if(data.name===''){
        {
          error.name.text = "Please enter name"
          error.name.state = true
        }
      }  
  if(data.number===''){
    {
      error.number.text = "Please enter phonenumber"
      error.number.state = true
    }
  }
  if(data.email===''){
    {
      error.email.text = "Please enter email"
      error.email.state = true
    }
  }
  // if(data.dob===''){
  //   {
  //     error.dob.text = "Please enter date of birth"
  //     error.dob.state = true
  //   }

  //   props.global_error({
  //       success:false,
  //       message:"Enter date of birth",
  //       heading:'D.O.B',
  //     })
  // }
  if(data.address===''){
    {
      error.address.text = "Please enter address"
      error.address.state = true
    }
  }
  if(data.location===false){
    {
      error.location.text = "Please enter location"
      error.location.state = true
    }

    props.global_error({
        success:false,
        message:"Enter location",
        heading:'Location',
      })
  }
    return error
  }
const add_user = () => {
    const ret_error = validate({...data});
    set_error({...error, ...ret_error});
    console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        console.log("Call the api")
        let obj =  {
            name: data.name,
            mobileNumber: data.number,
            userType: "User",
            email: data.email,
            fromPanel:true,
            gender:data.gender,
            birthDate:data.dob_string,
            address:data.address,
            location:{
                coordinates:[...data.location.coordinates]
            }
    }
   props.register_user({...obj})
    }
        // console.log("Inside Eror_form_check")
    //    props.execute_captcha()
    // let arr = data.speciality.map((item)=>{
    //   return {
    //     specialityId:item
    //   }
    // })
    // let obj = {
    //     "name":data.name,
    //     "mobileNumber":data.phonenumber,
    //      "specialities":arr,
    //      "message": data.message
    // }
    //     props.submit_query({...obj})
    // }
}

console.log(data,"data in Add User")



console.log(props.check_number_ret,"props.check_number_ret")
    const   onChange = e => {
        const { value } = e.target;

        const reg = /^-?\d*(\.\d*)?$/;
        if(value.length < 11){
           
            if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                set_data({
                    ...data,
                    number:value
                })
                set_state({
                    ...state,
                    check_status:"PENDING"
                })
                if(value.length > 9){
                    let flag = validator.isMobilePhone(value,'en-IN')
                    if(!flag){
                        set_error({
                            ...error,
                            number:{state:true, text:'Enter a valid mobile number'}
                        })
                         set_state({
                            ...state,
                            check_status:"PENDING"
                        })  
                    }else {
                     
                        set_data({
                            ...data,
                            number:value
                        })
                    }
                }
            }
        }else{
            if(value.length > 10){
                if(!validator.isMobilePhone(data.number,'en-IN')){
                    set_error({
                        ...error,
                        number:{state:true, text:'Enter a valid mobile number'}
                    })
                    set_state({
                        ...state,
                        check_status:"PENDING"
                    })
                }
            }
        }
      }
console.log(checkbox,"checkbox in User")
    
        return (<React.Fragment>

            <Card title="Register User">
                        <div className="check-user-wrapper">
                                <div className="enter_user_details_wrapper">
                                    <div className="u-margin-top-small">
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            >
                                                <div className="flex-parent-new">
                                                  <span className="flex-child-1-new">
                                                      <AntdInput label="User Mobile Number"  
                                                            value={data.number} 
                                                            error_text={error.number.text} 
                                                            loading = {props.check_number_loading_flag}
                                                            disabled = {props.check_number_loading_flag} 
                                                            toggle_error = {()=>set_error({...error, number:{text:'',state:false}})} 
                                                            error={error.number.state} 
                                                            name="number" 
                                                            onChange = {event=>onChange(event)} 
                                                            placeholder="Enter mobile number"  /> 
                    
                                                   </span>

                                                   <span className="flex-child-1-new">
                                                      <AntdInput label="Email"  
                                                            value={data.email} 
                                                            error_text={error.email.text} 
                                                            loading = {props.check_number_loading_flag}
                                                            disabled = {props.check_number_loading_flag} 
                                                            toggle_error = {()=>set_error({...error, email:{text:'',state:false}})} 
                                                            error={error.email.state} 
                                                            name="email" 
                                                            onChange = {event=>set_data({...data, [event.target.name]:event.target.value})} 
                                                           
                                                            placeholder="Enter email"  /> 
                    
                                                   </span>
                                                </div>
                                               
                                                <div className="flex-parent-new">
                                                  <span className="flex-child-1-new">
                                                      <AntdInput label="Name"  
                                                            value={data.name} 
                                                            error_text={error.name.text} 
                                                            loading = {props.check_number_loading_flag}
                                                            disabled = {props.check_number_loading_flag} 
                                                            toggle_error = {()=>set_error({...error, name:{text:'',state:false}})} 
                                                            error={error.name.state} 
                                                            name="name" 
                                                            onChange = {event=>set_data({...data, [event.target.name]:event.target.value})} 
                                                            placeholder="Enter name"  /> 
                    
                                                   </span>

                                                   <span className="flex-child-1-new">
                                                    <Form.Item
                                                           label={"D.O.B (Date of birth)"}
                                                          
                                                           
                                                    >       
                                                       {/* onChange = {event=>}  */}
                                                         <DatePicker 
                                                         style={{width:'100%'}}
                                                         value={data.dob}
                                                         onChange={(val=>{
                                                             let date = moment(val)
                                                             let year = date.year()
                                                             let month = date.month()
                                                           let  day = date.date()
                                                             console.log({year, month, day},"year")
                                                             if(year<2005){
                                                                set_data({...data, dob:moment(val), dob_string:`${day}-${get_month(month)}-${year}`})
                                                                console.log("asdasdasdasdasdasd")
                                                                console.log({year, month, day},"year")
                                                             }else {
                                                                props.global_error({
                                                                    success:false,
                                                                    message:"User should be more than 15 years old",
                                                                    heading:'D.O.B'
                                                                  })
                                                             }
                                                         })} />
                                                    </Form.Item>
                                                    </span>


                                                </div>


                                                <div className="flex-parent-new">
                                               
                                                </div>

                                                <div className="flex-parent-new">
                                                  <span className="flex-child-1-new">
                                                      <AntdInput label="Address"  
                                                            value={data.address} 
                                                            error_text={error.address.text} 
                                                            loading = {props.check_number_loading_flag}
                                                            disabled = {props.check_number_loading_flag} 
                                                            toggle_error = {()=>set_error({...error, address:{text:'',state:false}})} 
                                                            error={error.address.state} 
                                                            name="address" 
                                                            onChange = {event=>set_data({...data, [event.target.name]:event.target.value})} 
                                                            placeholder="Enter address"  /> 
                    
                                                   </span>
                                                </div>
                                                <div className="flex-parent-new">
                                                  <span className="flex-child-1-new">
                                                      <SearchLocation  
                                                            label="Location"  
                                                            value={data.location} 
                                                            loading = {props.check_number_loading_flag}
                                                            disabled = {props.check_number_loading_flag} 
                                                            error_text={error.location.text} 
                                                            toggle_error = {()=>set_error({...error,location:{text:'',state:false}})} 
                                                            error={error.location.state}
                                                            name="location" 
                                                            set_long_lat = {val=>{
                                                                console.log(val,"val in locations")
                                                                set_data({
                                                                    ...data,
                                                                    location:{
                                                                        coordinates:[val.lng , val.lat]
                                                                    }
                                                                })
                                                            // set_data({...data,...val})
                                                            // set_bids_list(false)
                                                            }}
                                                            placeholder="Enter Location"
                                                        />
                    
                                                   </span>
                                                </div>

                                                <div className="flex-parent-new">
                                                    <span className="flex-child-1-new">
                                                    <Form.Item
                                                           label={"Gender"}
                                                           validateStatus={error?"error":''}
                                                           help={error?props.error_text:''}
                                                    >       
                                                        <Checkbox.Group 
                                                        options={options}
                                                        value={[checkbox.value]} onChange={(val)=>{
                                                            console.log(val,"val in Checkbox")
                                                            if(val[0] !==""){
                                                                let flag = val.filter(item=>(item!==checkbox.value))[0]
                                                                 set_checkbox({
                                                                    value:flag
                                                                })
                                                                set_data({...data,gender:flag})
                                                            }else {
                                                                set_checkbox({
                                                                    value:false
                                                                })
                                                                set_data({...data,gender:false})
                                                            }
                                                        }} />
                                                    </Form.Item>
                                                    </span>
                                                </div>
                                              <div className="u-margin-top-small text-center">
                                                {state.check_status === "DONE"?
                                                    <Link to="/dashboard/bookings?add-booking" >
                                                      <Button
                                                         type="primary"
                                                         onClick={()=>console.log()}
                                                         style={{ width: 'auto', margin:'auto' }}
                                                        
                                                        >
                                                          Make Booking
                                                       </Button>
                                                    </Link>
                                                :<Button
                                                         type="primary"
                                                         onClick={add_user}
                                                         style={{ width: 'auto', margin:'auto' }}
                                                        
                                                        >
                                                          Submit
                                                       </Button>}
                                                       
                                                </div>
                                        </Form>
                                    </div>
                                </div>
                        </div>
        </Card>
        </React.Fragment>)
}
export default User