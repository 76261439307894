import { CHECK_NUMBER, CHECK_NUMBER_RET, CHECK_NUMBER_LOADING,
         REGISTER_USER, REGISTER_USER_RET, REGISTER_USER_LOADING, SET_USER,
         GET_DOCTORS_LOADING, GET_DOCTORS_RET, GET_DOCTORS, MAKE_BOOKING_LOADING, MAKE_BOOKING_RET,
          MAKE_BOOKING, GENERATE_LINK_LOADING, GENERATE_LINK_RET, GENERATE_LINK, GET_BOOKINGS_LOADING,
          GET_BOOKINGS_RET, GET_BOOKINGS, UPDATE_BOOKINGS_LOADING, UPDATE_BOOKINGS_RET, UPDATE_BOOKINGS,
          GET_INSIGHTS, GET_INSIGHTS_RET, GET_INSIGHTS_LOADING, GET_SOL_PROFESSIONALS_LOADING, GET_SOL_PROFESSIONALS_RET, 
          GET_SOL_PROFESSIONALS, UPDATE_SOL_PROFESSIONAL_LOADING, UPDATE_SOL_PROFESSIONAL_RET, UPDATE_SOL_PROFESSIONAL,
          GLOBAL_BOOKINGS_LOADING, GLOBAL_BOOKINGS_RET, GLOBAL_BOOKINGS, UPDATE_GLOBAL_BOOKING_LOADING, 
          UPDATE_GLOBAL_BOOKING_RET, UPDATE_GLOBAL_BOOKING, GET_REFUNDS, GET_REFUNDS_LOADING, GET_REFUNDS_RET,
          GET_REPORTS_BYID_LOADING, GET_REPORTS_BYID_RET, GET_REPORTS_BYID, GET_MEDICAL_DETAILS, GET_MEDICAL_DETAILS_LOADING, GET_MEDICAL_DETAILS_RET, 
          APPROVE_BOOKING_INSURANCE_UPDATE, APPROVE_BOOKING_INSURANCE_UPDATE_RET, APPROVE_BOOKING_INSURANCE_UPDATE_LOADING, UPDATE_BOOKING_PROCESS, UPDATE_BOOKING_PROCESS_RET, UPDATE_BOOKING_PROCESS_LOADING, UPDATE_USER_DETAILS, UPDATE_USER_DETAILS_RET, UPDATE_USER_DETAILS_LOADING } from "../Constants"


export const get_reports_byid = (data) => {
    return {
        type:GET_REPORTS_BYID,
        data
    }
}

export const get_reports_byid_ret = (data) => {
    return {
        type:GET_REPORTS_BYID_RET,
        data
    }
}

export const get_reports_byid_loading = (data) => {
    return {
        type:GET_REPORTS_BYID_LOADING,
        data
    }
}

export const get_refunds = (data) => {
    return {
        type:GET_REFUNDS,
        data
    }
}


export const get_refunds_ret = (data) => {
    return {
        type:GET_REFUNDS_RET,
        data
    }
}


export const get_refunds_loading = (data) => {
    return {
        type:GET_REFUNDS_LOADING,
        data
    }
}
export const global_bookings = (data) => {
    return {
        type:GLOBAL_BOOKINGS,
        data
    }
}

export const global_bookings_ret = (data) => {
    return {
        type:GLOBAL_BOOKINGS_RET,
        data
    }
}
        
export const global_bookings_loading = (data) => {
    return {
        type:GLOBAL_BOOKINGS_LOADING,
        data
    }
}


export const update_global_booking = (data) => {
    return {
        type:UPDATE_GLOBAL_BOOKING,
        data
    }
}

export const update_global_booking_ret = (data) => {
    return {
        type:UPDATE_GLOBAL_BOOKING_RET,
        data
    }
}
        
export const update_global_booking_loading = (data) => {
    return {
        type:UPDATE_GLOBAL_BOOKING_LOADING,
        data
    }
}
        
export const update_sol_professional = (data) => {
    return {
        type:UPDATE_SOL_PROFESSIONAL,
        data
    }
}

export const update_sol_professional_ret = (data) => {
    return {
        type:UPDATE_SOL_PROFESSIONAL_RET,
        data
    }
}

export const update_sol_professional_loading = (data) => {
    return {
        type:UPDATE_SOL_PROFESSIONAL_LOADING,
        data
    }
}
export const get_sol_professionals = (data) => {
    return {
        type:GET_SOL_PROFESSIONALS,
        data
    }
}

export const  get_sol_professionals_ret = (data) => {
    return {
        type:GET_SOL_PROFESSIONALS_RET,
        data
    }
}

export const get_sol_professionals_loading = (data) => {
    return {
        type:GET_SOL_PROFESSIONALS_LOADING,
        data
    }
}

export const get_insights = (data) => {
    return {
        type:GET_INSIGHTS,
        data
    }
}

export const  get_insights_ret = (data) => {
    return {
        type:GET_INSIGHTS_RET,
        data
    }
}

export const get_insights_loading = (data) => {
    return {
        type:GET_INSIGHTS_LOADING,
        data
    }
}

export const update_bookings = (data) => {
    return {
        type:UPDATE_BOOKINGS,
        data
    }
}

export const update_bookings_ret = (data) => {
    return {
        type:UPDATE_BOOKINGS_RET,
        data
    }
}

export const update_bookings_loading = (data) => {
    return {
        type:UPDATE_BOOKINGS_LOADING,
        data
    }
}

export const get_bookings = (data) => {
    return {
        type:GET_BOOKINGS,
        data
    }
}
export const get_bookings_ret = (data) => {
    return {
        type:GET_BOOKINGS_RET,
        data
    }
}
export const get_bookings_loading = (data) => {
    return {
        type:GET_BOOKINGS_LOADING,
        data
    }
}

export const generate_link = (data) => {
    return {
        type:GENERATE_LINK,
        data
    }
}

export const generate_link_ret = (data) => {
    return {
        type:GENERATE_LINK_RET,
        data
    }
}

export const generate_link_loading = (data) => {
    return {
        type:GENERATE_LINK_LOADING,
        data
    }
}
export const make_booking = (data) => {
    return {
        type:MAKE_BOOKING,
        data
    }
}
export const make_booking_ret = (data) => {
    return {
        type:MAKE_BOOKING_RET,
        data
    }
}
export const make_booking_loading = (data) => {
    return {
        type:MAKE_BOOKING_LOADING,
        data
    }
}
export const get_doctors = (data) => {
    return {
        type:GET_DOCTORS,
        data
    }
}

export const get_doctors_ret = (data) => {
    return {
        type:GET_DOCTORS_RET,
        data
    }
}

export const get_doctors_loading = (data) => {
    return {
        type:GET_DOCTORS_LOADING,
        data
    }
}

export const set_user = (data) => {
    return {
        type:SET_USER,
        data
    }
}

export const register_user = (data) => {
    return {
        type:REGISTER_USER,
        data
    }
}

export const register_user_ret = (data) => {
    return {
        type:REGISTER_USER_RET,
        data
    }
}

export const register_user_loading = (data) => {
    return {
        type:REGISTER_USER_LOADING,
        data
    }
}


export const check_number = (data) => {
    return {
        type:CHECK_NUMBER,
        data
    }
}
export const check_number_ret = (data) => {
    return {
        type:CHECK_NUMBER_RET,
        data
    }
}
export const check_number_loading = (data) => {
    return {
        type:CHECK_NUMBER_LOADING,
        data
    }
}

export const get_medical_details = (data) => {
    return {
        type: GET_MEDICAL_DETAILS,
        data
    }
}

export const get_medical_details_ret = (data) => {
    return {
        type: GET_MEDICAL_DETAILS_RET,
        data
    }
}

export const get_medical_details_loading = (data) => {
    return {
        type: GET_MEDICAL_DETAILS_LOADING,
        data
    }
}


export const approve_booking_insurance_update = (data) => {
    return {
        type: APPROVE_BOOKING_INSURANCE_UPDATE,
        data
    }
}

export const approve_booking_insurance_update_ret = (data) => {
    return {
        type: APPROVE_BOOKING_INSURANCE_UPDATE_RET,
        data
    }
}

export const approve_booking_insurance_update_loading = (data) => {
    return {
        type: APPROVE_BOOKING_INSURANCE_UPDATE_LOADING,
        data
    }
}

export const update_booking_process = (data) => {
    return {
        type: UPDATE_BOOKING_PROCESS,
        data
    }
}

export const update_booking_process_ret = (data) => {
    return {
        type: UPDATE_BOOKING_PROCESS_RET,
        data
    }
}

export const update_booking_process_loading = (data) => {
    return {
        type: UPDATE_BOOKING_PROCESS_LOADING,
        data
    }
}

export const update_user_details = (data) => {
    return {
        type: UPDATE_USER_DETAILS,
        data
    }
}

export const update_user_details_ret = (data) => {
    return {
        type: UPDATE_USER_DETAILS_RET,
        data
    }
}

export const update_user_details_loading = (data) => {
    return {
        type: UPDATE_USER_DETAILS_LOADING,
        data
    }
}
