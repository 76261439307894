import React from "react"
import {Bar} from 'react-chartjs-2';
const should_render = (prevProps, nextProps) => {
    return true
}

const green = '#DEE9FF'
const red = '#FF5151'
const blue = '#226CFC'

const prepare_data = (data) => {
  console.log(data,"data in prepare_data")
    console.log(data,"data in prepare_data")
    let average = data[0]
    
    let backgroundColor = data.map(((item,i)=>{
        if(i===0){
          return blue
        }else{
          if(item > average){
            return red
          }else{
            return green
          }
        }
    }))

    return {
      backgroundColor
    }
}


const Barchart = (props) => {
    console.log(props,'Props in Barchart')

    const { backgroundColor } = prepare_data(props.data.y_array)
    const data = {
      labels: [...props.data.x_array],
      datasets: [
        {
          label: 'Professionals',
          backgroundColor: backgroundColor,
          borderColor: 'rgb(73, 157, 204)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(44, 100, 130)',
          hoverBorderColor: 'rgb(44, 100, 130)',
          data:[...props.data.y_array]
        }
      ]
    };
    return (
        <React.Fragment>
                 <div>
                <Bar
                data={data}
                // width={100}
                // height={50}
                options={{
                  hover: {
                    onHover: function(e) {
                       var point = this.getElementAtEvent(e);
                       if (point.length) e.target.style.cursor = 'pointer';
                       else e.target.style.cursor = 'default';
                    }
                 },
                  maintainAspectRatio: true,
                  responsive: true,
                  startFrom:0,
                  title:{
                    display:false
                  },
                  onClick: function(c,i,o) {
                    if(!!i[0]){
                      let index = i[0]._index 
                      
                      if(index===0){
                            console.log("Plunes Average Clicked")
                      }else {
                          let id = [...props.data.x_array].filter((item,i)=>{
                            return i === index
                          })[0].professionalId

                          console.log(id,"id  in onClick")
                          props.get_prof_info({
                            professional_id:id
                          })

                          props.get_records({
                            professional_id:id,
                            limit:props.records_params.limit,
                            page:props.records_params.page
                          })
                          props.get_price_comparision({
                            limit:10,
                            page:1,
                            professional_id:id
                          })

                          props.set_professional_id(id)
                      }
                    }
                },
                  scales: {
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Avg. Price'
                        },
                        interval : '10',
                        ticks: {
                          autoSkip: true,
                          stepSize: 100 
                         }
                      }],
                      xAxes: [{
                        display:false,
                        scaleLabel: {
                          display: true,
                          labelString: 'Count'
                        },
                        categoryPercentage: 1,
                        barPercentage: .4,
                        barValueSpacing : .1,  
                      }],
                    },
                  tooltips: {
                    callbacks: {
                      title: function(tooltipItem, data,i) {
                        console.log(tooltipItem, data,i,"data in BAr Chart")
                        return data['labels'][tooltipItem[0]['index']].name;
                      },
                      label: function(tooltipItem, data) {
                        console.log(tooltipItem, data,"data and tooltipItem in Label")
                        return  'Price:'+` ${data['labels'][tooltipItem['index']].price}`;
                      },
                      afterLabel: function(tooltipItem, data) {
                        return  'Variance:'+` ${data['labels'][tooltipItem['index']].variance}`;
                      }
                    },
                    backgroundColor: '#FAFAFA',
                    titleFontSize: 12,
                    titleFontColor: '#000',
                    bodyFontColor: '#000',
                    bodyFontSize: 10,
                    displayColors: false
                  }
                }}
                />
      </div>
        </React.Fragment>
    )
}

export default React.memo(Barchart)