import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, Layout, Modal, notification, Row, Space, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import AntdInput from '../../Components/AntdInput';
dayjs.extend(weekday)
dayjs.extend(localeData)

function BookingDetails(props) {

    const [data, setData] = useState([])
    const [isNotifShown, setIsNotifShown] = useState(true)
    // const [editSessionModal, setEditSessionModal] = useState(false)
    // const [pdfModal, setPdfModal] = useState(false)
    const [selectedSession, setSelectedSession] = useState({
        alNumber: props.alNumber,
        ipNumber: props.ipNumber,
        cardNumber: props.cardNumber,
        doa: props.doa,
        dod: props.dod,
        tdsDeductions: props.tdsDeductions ? props.tdsDeductions: 0,
        sourcingCost: props.paymentDetails.sourcingCost,
        initialApproved: props.paymentDetails.initialApproved ? props.paymentDetails.initialApproved : 0,
        finalApproved: props.paymentDetails.finalApproved ? props.paymentDetails.finalApproved : 0,
        discount: props.paymentDetails.discount,
        settledAmount: props.paymentDetails.settledAmount ? props.paymentDetails.settledAmount : 0,
        totalBill: props.paymentDetails.totalBill,
        finalBill: props.paymentDetails.finalBill,
        utr: props.utr ? props.utr : 0,
    })
    const dateFormat = 'DD-MMM-YYYY';
    // const [tableParams, setTableParams] = useState({
    //     page:1,
    //     limit:10,
    //     total:10,
    //     searchParams:{},
    //     filters:{}
    // })

    useEffect (() => {
        props.paymentDetails && setData({...props.paymentDetails, doa: props.doa, dod: props.dod, alNumber: props.alNumber, ipNumber: props.ipNumber, cardNumber: props.cardNumber, preAuthStatus: props.preAuthStatus, finalAmount: props.finalAmount})
    }, [props.paymentDetails])

    // const subColumns = [
    //     {
    //         title: "Action",
    //         dataIndex: 'editSession',
    //         key: 'editSession',
    //         width: '2%',
    //         render: (text, record) => {
    //             return <button className="edit-session-button" onClick={() => {
    //                 setSelectedSession(record)
    //                 setEditSessionModal(true)
    //             }}>Edit</button>
    //         }
    //     },
    //     {
    //         title: 'Expected DOA',
    //         dataIndex: 'doa',
    //         key: 'doa',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Discharge Date',
    //         dataIndex: 'dod',
    //         key: 'dod',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Sourcing Cost',
    //         dataIndex: 'sourcingCost',
    //         key: 'sourcingCost',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Initial Approved Amount',
    //         dataIndex: 'initialApproved',
    //         key: 'initialApproved',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Final Approved Amount',
    //         dataIndex: 'finalApproved',
    //         key: 'finalApproved',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Discount',
    //         dataIndex: 'discount',
    //         key: 'discount',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Settled Amount',
    //         dataIndex: 'settledAmount',
    //         key: 'settledAmount',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Status',
    //         dataIndex: 'preAuthStatus',
    //         key: 'preAuthStatus',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Total Bill',
    //         dataIndex: 'totalBill',
    //         key: 'totalBill',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Final Bill',
    //         dataIndex: 'finalBill',
    //         key: 'finalBill',
    //         width: "5%",
    //         render: text => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'Deductions',
    //         dataIndex: 'deductions',
    //         key: 'deductions',
    //         width: "5%",
    //         render: (text) => <span>{text ? text : "-"}</span>,
    //     },
    //     {
    //         title: 'UTR',
    //         dataIndex: 'utr',
    //         key: 'utr',
    //         width: "5%",
    //         render: text => <span>{text}</span>,
    //     }
    // ];

    const getDateString = (date) => {
        let ts = date.split("-")
        ts = ts[0] + "-" + ts[1].charAt(0).toUpperCase() + ts[1].charAt(1).toLowerCase() + ts[1].charAt(2).toLowerCase() + "-" + ts[2]
        return ts
    }

    // const scrollToTop = () => {
    //     window.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    //     })
    // }

    // function showTotal(total) {
    //     return `Total ${total} items`;
    // }

    const openErrorNotificationWithIcon = () => {
        notification["error"]({
        message: 'Could not fetch details',
        description:
            'Please try again later',
        });
    };

    // const page_change = (a, b) => {
    //     setTableParams({...tableParams, page:a, limit:b})
    //     props.get_professional_bookings({...tableParams, page:a, limit:b})
    // }

    const updateSession = () => {
        setIsNotifShown(false)
        props.update_booking({
            ...selectedSession,
            bookingId: props.bookingId
        })
    }
    
    useEffect(()=>{
        if (props.update_booking_ret) {
            if (!isNotifShown) {
                if(props.update_booking_ret.success) {
                    // setSelectedSession(null)
                    // setEditSessionModal(false)
                    notification["success"]({
                        message: 'Success',
                        description: 'Booking Updated'
                    })
                    props.set_edit_flag(0)
                } else {
                    openErrorNotificationWithIcon()
                }
                setTimeout(() => {
                    setIsNotifShown(true)
                }, 200)
            }
            props.update_booking_session_loading()
        }
      },[props.update_booking_ret])

    return (
      <>
        <Layout style={{ backgroundColor: 'white' }}>
            {/* <div className="patient-table-container">
                <Table 
                    scroll={{ x: 2500 }} 
                    columns={subColumns} 
                    dataSource={[data]}
                    loading={props.get_professional_bookings_loading_flag || props.update_booking_session_loading_flag}
                />
            </div>
            <Modal
                title="Edit Session"
                open={editSessionModal}
                onCancel={() => {
                    setSelectedSession(null)
                    setEditSessionModal(false)
                }}

                onOk={() => updateSession()}
            >
                {selectedSession && <> */}
                    {
                        props.edit_flag === 1 && (
                            <React.Fragment>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={18} className="col-6">
                                        <label className="session-label">AL Number</label>
                                        <Input value={selectedSession.alNumber} onChange={(e) => setSelectedSession({...selectedSession, alNumber: e.target.value})} />
                                    </Col>
                                </Row>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={12} className="col-6">
                                        <label className="session-label">IP Number</label>
                                        <Input value={selectedSession.ipNumber} onChange={(e) => setSelectedSession({...selectedSession, ipNumber: e.target.value})} />
                                    </Col>
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Card Number</label>
                                        <Input value={selectedSession.cardNumber} onChange={(e) => setSelectedSession({...selectedSession, cardNumber: e.target.value})} />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )
                    }
                    {
                        props.edit_flag ===  3 && (
                            <React.Fragment>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Expected DOA</label>
                                        {selectedSession.doa && <DatePicker style={{"width":"100%"}} disabledDate={(current) => current.isBefore(dayjs(dayjs(), dateFormat))} defaultValue={dayjs(getDateString(selectedSession.doa), dateFormat)} onChange={(date) => setSelectedSession({...selectedSession, doa: date.format(dateFormat)})} format={dateFormat} />}
                                    </Col>
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Date of Discharge</label>
                                        {selectedSession.dod && <DatePicker style={{"width":"100%"}} disabledDate={(current) => current.isBefore(dayjs(getDateString(selectedSession.doa), dateFormat))} defaultValue={dayjs(getDateString(selectedSession.dod), dateFormat)} onChange={(date) => setSelectedSession({...selectedSession, dod: date.format(dateFormat)})} format={dateFormat} />}
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )
                    }
                    {
                        props.edit_flag === 2 && (
                            <React.Fragment>
                                
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Sourcing Cost</label>
                                        <Input value={selectedSession.sourcingCost} onChange={(e) => setSelectedSession({...selectedSession, sourcingCost: e.target.value})} />
                                    </Col>
                                </Row>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Initial Approved Amount</label>
                                        <Input value={selectedSession.initialApproved} onChange={(e) => setSelectedSession({...selectedSession, initialApproved: e.target.value})} />
                                    </Col>
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Final Approved Amount</label>
                                        <Input value={selectedSession.finalApproved} onChange={(e) => setSelectedSession({...selectedSession, finalApproved: e.target.value})} />
                                    </Col>
                                </Row>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Discount Amount</label>
                                        <Input value={selectedSession.discount} onChange={(e) => setSelectedSession({...selectedSession, discount: e.target.value})} />
                                    </Col>
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Settled Amount</label>
                                        <Input value={selectedSession.settledAmount} onChange={(e) => setSelectedSession({...selectedSession, settledAmount: e.target.value})} />
                                    </Col>
                                </Row>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Total Bill</label>
                                        <Input value={selectedSession.totalBill} onChange={(e) => setSelectedSession({...selectedSession, totalBill: e.target.value})} />
                                    </Col>
                                    <Col span={12} className="col-6">
                                        <label className="session-label">Final Bill</label>
                                        <Input disabled value={selectedSession.finalBill} onChange={(e) => setSelectedSession({...selectedSession, finalBill: e.target.value})} />
                                    </Col>
                                </Row>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={12} className="col-6">
                                        <label className="session-label">TDS Deductions</label>
                                        <Input value={selectedSession.tdsDeductions} onChange={(e) => setSelectedSession({...selectedSession, tdsDeductions: e.target.value})} />
                                    </Col>
                                </Row>
                                <Row gutter={24} className="row insurance-booking-session">
                                    <Col span={24} className="col-12">
                                        <label className="session-label">UTR</label>
                                        <Input disabled={selectedSession.settledAmount == 0 ? true : false} value={selectedSession.utr} onChange={(e) => setSelectedSession({...selectedSession, utr: e.target.value})} />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )
                    }
                    {/* <div className="row insurance-booking-session">
                        <div className="col-6">
                            <label className="session-label">Requested Amount</label>
                            <Input type="number" value={selectedSession.requestedAmount} onChange={(e) => setSelectedSession({...selectedSession, requestedAmount: e.target.value})}  />
                        </div>
                        <div className="col-6">
                            <label className="session-label">Initial Amount</label>
                            <Input type="number" value={selectedSession.initialAmount} onChange={(e) => setSelectedSession({...selectedSession, initialAmount: e.target.value})}  />
                        </div>
                    </div>
                    <div className="row insurance-booking-session">
                        <div className="col-4">
                            <label className="session-label">Final Amount</label>
                            <Input type="number" value={selectedSession.finalAmount} onChange={(e) => setSelectedSession({...selectedSession, finalAmount: e.target.value})}  />
                        </div>
                        <div className="col-4">
                            <label className="session-label">Deductibles</label>
                            <Input type="number" value={selectedSession.deductibles} onChange={(e) => setSelectedSession({...selectedSession, deductibles: e.target.value})}  />
                        </div>
                        <div className="col-4">
                            <label className="session-label">CoPay</label>
                            <Input type="number" value={selectedSession.coPay} onChange={(e) => setSelectedSession({...selectedSession, coPay: e.target.value})}  />
                        </div>
                    </div> */}
                    {/* <Row gutter={24} className="row insurance-booking-session">
                        <Col span={12} className="col-6">
                            <label className="session-label">Status</label>
                            <AntdInput 
                                value={selectedSession.preAuthStatus} 
                                className="insurance-booking-status"
                                options = {[
                                    {
                                        key: "UNDER PROCESS",
                                        value: "UNDER PROCESS"
                                    },
                                    {
                                        key: "APPROVED",
                                        value: "APPROVED"
                                    },
                                    {
                                        key: "REJECTED",
                                        value: "REJECTED"
                                    },
                                    {
                                        key: "OUERIED",
                                        value: "OUERIED"
                                    },
                                    {
                                        key: "QUERY RECIEVED",
                                        value: "QUERY RECIEVED"
                                    }
                                ]}
                                loading={props.get_professional_bookings_loading_flag || props.update_booking_session_loading_flag}
                                disabled = {props.get_professional_bookings_loading_flag || props.update_booking_session_loading_flag}
                                name="preAuthStatus" 
                                type="select"
                                onChange = {event=>{
                                    setSelectedSession({...selectedSession, preAuthStatus: event})
                                }} 
                                placeholder="Select Status"
                            />
                        </Col>
                        <Col span={12} className="col-6">
                            <label className="session-label">Payment</label>
                            <AntdInput 
                                value={selectedSession.paymentStatus} 
                                className="insurance-booking-status"
                                options = {[
                                    {
                                        key: "PENDING",
                                        value: "PENDING"
                                    },
                                    {
                                        key: "SUCCESSFUL",
                                        value: "SUCCESSFUL"
                                    }
                                ]}
                                loading={props.get_professional_bookings_loading_flag || props.update_booking_session_loading_flag}
                                disabled = {props.get_professional_bookings_loading_flag || props.update_booking_session_loading_flag}
                                name="paymentStatus" 
                                type="select"
                                onChange = {event=>{
                                    setSelectedSession({...selectedSession, paymentStatus: event})
                                }} 
                                placeholder="Select Payment Status"
                            />
                        </Col>
                    </Row> */}
                {/* </>}
            </Modal> */}
        </Layout>
        
        
        <div className='u-margin-top-small'>
            <Row gutter={12}>
                <Col>
                    <Button type='default' size='middle' onClick={() => props.set_edit_flag(0)}>Cancel</Button>
                    <Button type='primary' size='middle' onClick={() => updateSession()} style={{ marginLeft: '10px' }}>OK</Button>
                </Col>
            </Row>
        </div>
      </>
    )
}

export default BookingDetails