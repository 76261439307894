import React from "react"
import { Table, Input, Button, Space, Card, Form, Select, Modal, Calendar, TimePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, get_time} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import validator from "validator"
import AntdInput from "../../Components/AntdInput"

const { Option } = Select

// import Map from "./Map.js"


const FollowUpList = (props) => {
  const {form} = Form.useForm()
        const [state, set_state] = React.useState({
          filteredInfo: null,
          sortedInfo: null,
          searchText: '',
          searchedColumn: ''
        })  
      const [check_follow_up_params, set_check_follow_up_params] = React.useState({
           page:1,
           limit:10,
           searchParams:{
          
           },
           filters:{
            
           },
           mobile_number:''
      })
      const [user_data, set_user_data] = React.useState({
        number:''
      })
      const [error, set_error] = React.useState({
       number:{ state:false, text:"" }
     })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [speciality, set_speciality] = React.useState([])
      const [list, set_list] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [visible, set_visible] = React.useState(false)
      const [selected_follow_up, set_selected_follow_up] = React.useState({

      })

            const [update_follow_up_data, set_update_follow_up_data] = React.useState({
              date:'',
              time:'',
              note:''
        })
          const [update_follow_up_error, set_update_follow_up_error] = React.useState({
            date:{ state:false, text:"" },
            time:{ state:false, text:"" },
            note:{state:false, text:''}
          })

    React.useEffect(()=>{
    //    props.check_follow_up({...check_follow_up_params})
    },[])

    React.useEffect(()=>{
      if(!!props.check_follow_up_ret){
     
        if(props.check_follow_up_ret.success){
              console.log(props.check_follow_up_ret,"check_follow_up_ret")
              set_data(modif_data([...props.check_follow_up_ret.data]))
              set_check_follow_up_params({...check_follow_up_params,limit:props.check_follow_up_ret.data.length,  total:props.check_follow_up_ret.count})
        //  set_data(modif_data([...props.check_follow_up_ret.data]))
        //  set_check_follow_up_params({...check_follow_up_params, total:props.check_follow_up_ret.total})
        }else{
         props.global_error({
           success:false,
           message:props.check_follow_up_ret.message,
           heading:'Follow-up'
         })
        }
         props.check_follow_up_loading()
     }
     if(!!props.update_follow_up_ret){
        
      if(props.update_follow_up_ret.success){
       
        handleCancel()
        props.check_follow_up({ ...check_follow_up_params,  mobile_number:user_data.number})
      
       props.global_error({
         success:true,
         message:props.update_follow_up_ret.message,
         heading:'Update Follow up'
       })
      }else{
       props.global_error({
         success:false,
         message:props.update_follow_up_ret.message,
         heading:'Update Follow up'
       })
      }
       props.update_follow_up_loading()
   }
     
         
  },[props.update_follow_up_ret, props.update_global_booking_ret, props.check_follow_up_ret])

     const handleChange = (pagination, filters, sorter) => {
       console.log("-=-===================>")
       console.log(pagination, filters, "pagination and  filters in handekChange")
       console.log(check_follow_up_params,"check_follow_up_params")
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...check_follow_up_params.filters}
       let updated_filters = {}
      
       updated_filters.createdAt = filters.createdAt
       updated_filters.queryType = filters.queryType
       updated_filters.status = filters.status
      if(!!((pagination.current === check_follow_up_params.page) && (pagination.pageSize === check_follow_up_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_check_follow_up_params({...check_follow_up_params, page:1,filters :{
            createdAt: filters.createdAt? filters.createdAt:'',
            status: filters.status? filters.status:'',
            queryType: filters.queryType? filters.queryType:'',
      
          }})
          props.check_follow_up({...check_follow_up_params, page:1, filters :{
            createdAt: filters.createdAt? filters.createdAt:'',
            status: filters.status? filters.status:'',
            queryType: filters.queryType? filters.queryType:'',
  }})
        }
      }else {
        // set_check_follow_up_params({...check_follow_up_params, page:1, limit:pagination.pageSize})
        //     props.check_follow_up({...check_follow_up_params, page:1, limit:pagination.pageSize})
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_check_follow_up_params({...check_follow_up_params, page:1,searchParams:{
           ...check_follow_up_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.check_follow_up({...check_follow_up_params, page:1,searchParams:{
          ...check_follow_up_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            createdAt: get_time(item.createdAt),
            message: item.message,
            mobileNumber:item.mobileNumber,
            name:item.name,
            queryType:item.queryType,
            _id:item._id,
            status:item.status,
            note:item.note,
            sentMessages:item.sentMessages,
            sms:item.sms,
            updatedAt:item.updatedAt,
            reminderTime:item.reminderTime
            }
            prof_list.push({
          ...obj
            })
        })

    return prof_list;
    }


    const update_status = (val, data) => {
      props.update_global_booking({
        bookingId:data._id,
        refundStatus:val,
        type:'refund_booking'
      })
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_check_follow_up_params({...check_follow_up_params, limit:b, page:1})
      props.check_follow_up({...check_follow_up_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      console.log(a, b , "a, b in page_change")
      // console.log(a,b,check_follow_up_params,"a and b check_follow_up_params")
      if(true){
        set_check_follow_up_params({...check_follow_up_params, page:a, limit:b})
        props.check_follow_up({...check_follow_up_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_check_follow_up_params({...check_follow_up_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
    
        // userType:"",
        // level:"",
        // catalogue:''
      }})
      props.check_follow_up({...check_follow_up_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
   
        // userType:"",
        // level:"",
        // catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_check_follow_up_params({...check_follow_up_params, page:1, filters:{
       
        // userType:"",
        // catalogue:'',
        // level:''
      }})
      props.check_follow_up({...check_follow_up_params, page:1, filters:{
      
        // userType:"",
        // catalogue:'',
        // level:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const columns = [

      {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width:'10%',
          fixed:'left'
        //   ...getColumnSearchProps('name', "Search name", 'name'),
          // fixed:'left'
      },
     
      {
          title: 'Type',
          dataIndex: 'queryType',
          key: 'queryType',
          width:'10%',
          filterMultiple: false,   
          filters: [
            {
              text: 'PUBLIC_QUERY',
              value: 'PUBLIC_QUERY',
            },
            {
              text: 'IVR_QUERY',
              value: 'IVR_QUERY',
            },
            {
              text: 'MEDIA_QUERY',
              value: 'MEDIA_QUERY',
            },
            {
              text: 'COVID_QUERY',
              value: 'COVID_QUERY',
            },
            {
              text: 'REGISTERED_QUERY',
              value: 'REGISTERED_QUERY',
            }
          ]
        //   ...getColumnSearchProps('email', "Search email", 'email'),
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        width:'20%',
            // ...getColumnSearchProps('mobileNumber', "Search phone", 'mobileNumber'),
      },
      {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        width:'20%'
      },
      {
        title: 'Reminder time',
        dataIndex: 'reminderTime',
        key: 'reminderTime',
        width:'20%',
        render:(text, record) => {
          return get_time(record.reminderTime)
        }
      },
      {
        title:'Create At',
        dataIndex:'createdAt',
        key:'createdAt',
        width:'15%',
        // filters: [
        //   {
        //     text: 'Today',
        //     value: '1',
        //   },
        //   {
        //     text: 'Yesterday',
        //     value: '2',
        //   },
        //   {
        //     text: 'Last week',
        //     value: '7',
        //   }
        // ]
      },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width:'15%',
      filterMultiple: false,   
      filters: [
        {
          text: 'PENDING',
          value: 'PENDING',
        },
        {
          text: 'HOT_LEAD',
          value: 'HOT_LEAD',
        },
        {
          text: 'COLD_LEAD',
          value: 'COLD_LEAD',
        },
        {
          text: 'CALLBACK_DUE',
          value: 'CALLBACK_DUE',
        },
        {
          text: 'DONE',
          value: 'DONE',
        }
      ]
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    render: (text,record) => {
        // console.log(record._id)
        return <div className="text-center">
            <CalendarOutlined style={{cursor:'pointer'}} title="Add Reminder" onClick={()=>{
              set_visible(true)
              set_selected_follow_up(record)
            }
              } />
        </div>
    }
},
  ]


  const   onChange = e => {
    const { value } = e.target;

    const reg = /^-?\d*(\.\d*)?$/;
    if(value.length < 11){
       
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            set_user_data({
                ...data,
                number:value
            })
            set_check_follow_up_params({
              ...check_follow_up_params,
              mobile_number:value
            })
           
            if(value.length > 9){
                let flag = validator.isMobilePhone(value,'en-IN')
                if(!flag){
                    set_error({
                        ...error,
                        number:{state:true, text:'Enter a valid mobile number'}
                    })
                   
                }else {
                  set_check_follow_up_params({
                    ...check_follow_up_params,
                    mobile_number:value
                  })
                    props.check_follow_up({   ...check_follow_up_params,mobile_number:value})
                 
                }
            }
        }
    }else{
        if(value.length > 10){
            if(!validator.isMobilePhone(data.number,'en-IN')){
                set_error({
                    ...error,
                    number:{state:true, text:'Enter a valid mobile number'}
                })
              
            }
        }
    }
  }



const  handleCancel = e => {
  console.log("Inside handleCancel")
  console.log(e);
  set_visible(false)
  set_update_follow_up_data({
    date:'',
    time:'',
    note:''
  })
  set_update_follow_up_error({
    date:{ state:false, text:"" },
    time:{ state:false, text:"" },
    note:{state:false, text:''}
  })
}

const submit = () => {
    try {
      if(update_follow_up_data.time===0  || update_follow_up_data.date===""){
        set_error({...error, date:{state:true, text:'Select time to set reminder'}})
        throw new Error("Select time to set reminder")
      }

      if(update_follow_up_data.note===0){
        set_error({...error, node:{state:true, text:'Enter Note'}})
      
      }else {
        props.update_follow_up({
          queryId:selected_follow_up._id,
          queryType:selected_follow_up.queryType,
          timeSlot: update_follow_up_data.time*1000,
          date: update_follow_up_data.date,
          note:update_follow_up_data.note,
          status:selected_follow_up.status,
          type:'add_reminder'
        })
      }
    } catch (error) {
      console.log(error)
      props.global_error({
        success:false,
        message:error.message,
        heading:"Set Reminder",
      })
    }
  
}

console.log(check_follow_up_params,"check_follow_up_params")
      function showTotal(total) {
        return `Total ${total} items`;
      }


        return (<React.Fragment>
            <Card>
            <Form
      form={form}
      layout="vertical"
    >

        <div className="flex-parent-new">
            <span className="flex-child-1-new">
                <AntdInput label="User Mobile Number"  
                    value={user_data.number} 
                    error_text={error.number.text} 
                    loading = {props.check_number_loading_flag}
                    disabled = {props.check_number_loading_flag} 
                    toggle_error = {()=>set_error({...error, number:{text:'',state:false}})} 
                    error={error.number.state} 
                    name="number" 
                    onChange = {event=>onChange(event)} 
                    placeholder="Enter mobile number"  /> 
            </span>
        </div>
</Form>
        <Table
          columns={columns}
          dataSource={data}
          loading={props.check_follow_up_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          scroll={{ x: 1300 }}
          pagination = {{
            showSizeChanger:true,
            limit:check_follow_up_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:check_follow_up_params.page,
            total:check_follow_up_params.total,
            showTotal:showTotal
          }}
        //   onRow={(r) => ({
        //     onClick: (b,a) => row_click(r),
        //   })}
          />


        <Modal
          title="Set Reminder"
          visible={visible}
          onOk={submit}
          onCancel={handleCancel}
          width={600}
        >
           {visible && <div className="flex-parent-new u-margin-top-small">
                <span className="flex-child-1-new">
                <div className="site-calendar-demo-card">
                    <Calendar fullscreen={false}
                  
                     onPanelChange={(e,r)=>console.log(e, r ,"e, r in calendat")}
                     onSelect={(val,e)=>{
                      console.log(val,e,"val in Calendar")
                      console.log( val.unix())
                        set_update_follow_up_data({
                          ...update_follow_up_data, date:val.unix()
                        })
                     
                     }}  
                     />
                  </div>
                </span>
                <span className="flex-child-1-new">
                     <div className="">
                     <TimePicker onChange={(val,r)=>{
                        set_update_follow_up_data({
                          ...update_follow_up_data, time:val.unix()
                         })
                     }} />
                     <div className="u-margin-top-small">
                        <AntdInput label="Note"  
                        value={update_follow_up_data.note} 
                        error_text={update_follow_up_error.note.text} 
                        loading = {props.send_sms_loading_flag}
                        disabled = {props.send_sms_loading_flag} 
                        toggle_error = {()=>set_update_follow_up_error({...error, note:{text:'',state:false}})} 
                        error={update_follow_up_error.note.state} 
                        name="note" 
                        onChange = {event=>set_update_follow_up_data({...update_follow_up_data,[event.target.name]:event.target.value})} 
                        placeholder="Enter note"
                        type="textarea"
                        rows= {10}
                        /> 
                     </div>
                     </div>
                      
                </span>
          </div>}
          <div className="text-center">
          {/* <Button
                  type="primary"
                 onClick={() => update_payment()}
                 disabled = {props.update_bookings_loading_flag}
                 loading={props.update_bookings_loading_flag}
                 style={{ width: 'auto', margin:'auto' }}
      >
       Submit
    </Button> */}
          </div>
        </Modal>
   


        </Card>
        </React.Fragment>)
}
export default FollowUpList