import { 
    FUNNEL_CHECK_NUMBER, FUNNEL_CHECK_NUMBER_RET, FUNNEL_CHECK_NUMBER_LOADING, FUNNEL_SET_USER, 
    FUNNEL_REGISTER_USER_LOADING, FUNNEL_REGISTER_USER, FUNNEL_REGISTER_USER_RET,
    FUNNEL_GET_DOCTORS_LOADING, FUNNEL_MAKE_BOOKING_LOADING, FUNNEL_MAKE_BOOKING_RET, FUNNEL_MAKE_BOOKING,
    FUNNEL_GET_DOCTORS_RET, FUNNEL_GET_DOCTORS,  FUNNEL_GENERATE_LINK_LOADING, FUNNEL_GENERATE_LINK_RET, FUNNEL_GENERATE_LINK, FUNNEL_GET_BOOKINGS_LOADING,
    FUNNEL_GET_BOOKINGS_RET, FUNNEL_GET_BOOKINGS, FUNNEL_UPDATE_BOOKINGS_LOADING, FUNNEL_UPDATE_BOOKINGS_RET, FUNNEL_UPDATE_BOOKINGS, FUNNEL_GET_INSIGHTS,
    FUNNEL_GET_INSIGHTS_RET, FUNNEL_GET_INSIGHTS_LOADING, FUNNEL_GET_SOL_PROFESSIONALS, FUNNEL_GET_SOL_PROFESSIONALS_RET, FUNNEL_GET_SOL_PROFESSIONALS_LOADING,
    FUNNEL_UPDATE_SOL_PROFESSIONAL_LOADING, FUNNEL_UPDATE_SOL_PROFESSIONAL_RET, FUNNEL_UPDATE_SOL_PROFESSIONAL, FUNNEL_UPDATE_GLOBAL_BOOKING_LOADING, FUNNEL_UPDATE_GLOBAL_BOOKING_RET,
    FUNNEL_UPDATE_GLOBAL_BOOKING, FUNNEL_GLOBAL_BOOKINGS, FUNNEL_GLOBAL_BOOKINGS_RET, FUNNEL_GLOBAL_BOOKINGS_LOADING , FUNNEL_GET_REFUNDS_LOADING, FUNNEL_GET_REFUNDS_RET, FUNNEL_GET_REFUNDS,
    FUNNEL_GET_REPORTS_BYID_LOADING, FUNNEL_GET_REPORTS_BYID_RET, FUNNEL_GET_REPORTS_BYID, 
    FUNNEL_UPLOAD_USER_REPORT, FUNNEL_UPLOAD_USER_REPORT_RET, FUNNEL_UPLOAD_USER_REPORT_LOADING, 
    FUNNEL_UPLOAD_INSURANCE_REPORT, FUNNEL_UPLOAD_INSURANCE_REPORT_RET, FUNNEL_UPLOAD_INSURANCE_REPORT_LOADING,
    FUNNEL_SUBMIT_MEDICAL_DETAILS, FUNNEL_SUBMIT_MEDICAL_DETAILS_RET, FUNNEL_SUBMIT_MEDICAL_DETAILS_LOADING,
    FUNNEL_EDIT_MEDICAL_DETAILS, FUNNEL_EDIT_MEDICAL_DETAILS_RET, FUNNEL_EDIT_MEDICAL_DETAILS_LOADING,
    FUNNEL_CREATE_CUSTOM_SOLUTION, FUNNEL_CREATE_CUSTOM_SOLUTION_LOADING, FUNNEL_CREATE_CUSTOM_SOLUTION_RET
} from "../Constants"

const initialState = {
    
        check_number:false,
        check_number_ret:false,
        check_number_loading:false,

        register_user:false,
        register_user_ret:false,
        register_user_loading:false,

        get_doctors:false,
        get_doctors_ret:false,
        get_doctors_loading:false,

        make_booking:false,
        make_booking_ret:false,
        make_booking_loading:false,

        generate_link:false,
        generate_link_ret:false,
        generate_link_loading:false,

        get_bookings:false,
        get_bookings_ret:false,
        get_bookings_loading:false,

        update_bookings:false,
        update_bookings_ret:false,
        update_bookings_loading:false,

        get_insights:false,
        get_insights_ret:false,
        get_insights_loading:false,

        get_sol_professionals:false,
        get_sol_professionals_ret:false,
        get_sol_professionals_loading:false,

        update_sol_professional:false,
        update_sol_professional_ret:false,
        update_sol_professional_loading:false,

        global_bookings:false,
        global_bookings_ret:false,
        global_bookings_loading:false,

        update_global_booking:false,
        update_global_booking_ret:false,
        update_global_booking_loading:false,

        get_refunds:false,
        get_refunds_ret:false,
        get_refunds_loading:false,

        get_reports_byid:false,
        get_reports_byid_ret:false,
        get_reports_byid_loading:false,

        upload_user_report:false,
        upload_user_report_ret:false,
        upload_user_report_loading:false,

        upload_insurance_report:false,
        upload_insurance_report_ret:false,
        upload_insurance_report_loading:false,

        submit_medical_details:false,
        submit_medical_details_ret:false,
        submit_medical_details_loading:false,

        edit_medical_details:false,
        edit_medical_details_ret:false,
        edit_medical_details_loading:false,

        create_custom_solution:false,
        create_custom_solution_ret:false,
        create_custom_solution_loading:false,

        user: {}
}

export default function (state = initialState, action) {
    switch (action.type) {

        case FUNNEL_GET_REPORTS_BYID: {
            return {
                ...state,
                get_reports_byid:{...action.data},
                get_reports_byid_loading:true
            }
        }

        case FUNNEL_GET_REPORTS_BYID_RET: {
            return {
                ...state,
                get_reports_byid_ret:{...action.data},
                get_reports_byid_loading:true
            }
        }

        case FUNNEL_GET_REPORTS_BYID_LOADING: {
            return {
                ...state,
                get_reports_byid_ret:false,
                get_reports_byid_loading:false
            }
        }


        case FUNNEL_GLOBAL_BOOKINGS: {
            return {
                ...state,
                global_bookings:{...action.data},
                global_bookings_loading:true
            }
        }

        case FUNNEL_GLOBAL_BOOKINGS_RET: {
            return {
                ...state,
                global_bookings_ret:{...action.data},
                global_bookings_loading:true
            }
        }

        case FUNNEL_GLOBAL_BOOKINGS_LOADING: {
            return {
                ...state,
                global_bookings_ret:false,
                global_bookings_loading:false
            }
        }

        case FUNNEL_GET_REFUNDS: {
            return {
                ...state,
                get_refunds:{...action.data},
                get_refunds_loading:true
            }
        }

        case FUNNEL_GET_REFUNDS_RET: {
            return {
                ...state,
                get_refunds_ret:{...action.data},
                get_refunds_loading:true
            }
        }

        case FUNNEL_GET_REFUNDS_LOADING: {
            return {
                ...state,
                get_refunds_ret:false,
                get_refunds_loading:false
            }
        }


        case FUNNEL_UPDATE_GLOBAL_BOOKING: {
            return {
                ...state,
                update_global_booking:{...action.data},
                update_global_booking_loading:true
            }
        }

        case FUNNEL_UPDATE_GLOBAL_BOOKING_RET: {
            return {
                ...state,
                update_global_booking_ret:{...action.data},
                update_global_booking_loading:true
            }
        }

        case FUNNEL_UPDATE_GLOBAL_BOOKING_LOADING: {
            return {
                ...state,
                update_global_booking_ret:false,
                update_global_booking_loading:false
            }
        }


        case FUNNEL_UPDATE_SOL_PROFESSIONAL: {
            return {
                ...state,
                update_sol_professional:{...action.data},
                update_sol_professional_loading:true
            }
        }

        case FUNNEL_UPDATE_SOL_PROFESSIONAL_RET: {
            return {
                ...state,
                update_sol_professional_ret:{...action.data},
                update_sol_professional_loading:true
            }
        }

        case FUNNEL_UPDATE_SOL_PROFESSIONAL_LOADING: {
            return {
                ...state,
                update_sol_professional_ret:false,
                update_sol_professional_loading:false
            }
        }


        case FUNNEL_GET_SOL_PROFESSIONALS: {
            return {
                ...state,
                get_sol_professionals:{...action.data},
                get_sol_professionals_loading:true
            }
        }

        case FUNNEL_GET_SOL_PROFESSIONALS_RET: {
            return {
                ...state,
                get_sol_professionals_ret:{...action.data},
                get_sol_professionals_loading:true
            }
        }

        case FUNNEL_GET_SOL_PROFESSIONALS_LOADING: {
            return {
                ...state,
                get_sol_professionals_ret:false,
                get_sol_professionals_loading:false
            }
        }

        case FUNNEL_GET_INSIGHTS: {
            return {
                ...state,
                get_insights:{...action.data},
                get_insights_loading:true
            }
        }

        case FUNNEL_GET_INSIGHTS_RET: {
            return {
                ...state,
                get_insights_ret:{...action.data},
                get_insights_loading:true
            }
        }

        case FUNNEL_GET_INSIGHTS_LOADING: {
            return {
                ...state,
                get_insights_ret:false,
                get_insights_loading:false
            }
        }


        case FUNNEL_UPDATE_BOOKINGS: {
            return {
                ...state,
                update_bookings:{...action.data},
                update_bookings_loading:true
            }
        }

        case FUNNEL_UPDATE_BOOKINGS_RET: {
            return {
                ...state,
                update_bookings_ret:{...action.data},
                update_bookings_loading:true
            }
        }

        case FUNNEL_UPDATE_BOOKINGS_LOADING: {
            return {
                ...state,
                update_bookings_ret:false,
                update_bookings_loading:false
            }
        }

        case FUNNEL_GET_BOOKINGS: {
            return {
                ...state,
                get_bookings:{...action.data},
                get_bookings_loading:true
            }
        }

        case FUNNEL_GET_BOOKINGS_RET: {
            return {
                ...state,
                get_bookings_ret:{...action.data},
                get_bookings_loading:true
            }
        }

        case FUNNEL_GET_BOOKINGS_LOADING: {
            return {
                ...state,
                get_bookings_ret:false,
                get_bookings_loading:false
            }
        }

        case FUNNEL_GENERATE_LINK: {
            return {
                ...state,
                generate_link:{...action.data},
                generate_link_loading:true
            }
        }

        case FUNNEL_GENERATE_LINK_RET: {
            console.log("Inside GENERATE_LINK_RET", action.data)
            return {
                ...state,
                generate_link_ret:{...action.data},
                generate_link_loading:true
            }
        }

        case FUNNEL_GENERATE_LINK_LOADING: {
            return {
                ...state,
                generate_link_ret:false,
                generate_link_loading:false
            }
        }


        case FUNNEL_MAKE_BOOKING: {
            return {
                ...state,
                make_booking:{...action.data},
                make_booking_loading:true
            }
        }

        case FUNNEL_MAKE_BOOKING_RET: {
            return {
                ...state,
                make_booking_ret:action.data,
                make_booking_loading:true
            }
        }

        case FUNNEL_MAKE_BOOKING_LOADING:
            return {
            ...state,
            make_booking_ret:false,
            make_booking_loading:false
            }


        case FUNNEL_GET_DOCTORS: {
            return {
                ...state,
                get_doctors:{...action.data},
                get_doctors_loading:true
            }
        }

        case FUNNEL_GET_DOCTORS_RET: {
            return {
                ...state,
                get_doctors_ret:action.data,
                get_doctors_loading:true
            }
        }

        case FUNNEL_GET_DOCTORS_LOADING:
            return {
            ...state,
            get_doctors_ret:false,
            get_doctors_loading:false
            }

        case FUNNEL_SET_USER: {
            return {
                ...state,
                user:{...action.data}
            }
        }

        case FUNNEL_CHECK_NUMBER: {
            return {
                ...state,
                check_number:action.data,
                check_number_loading:true
            }
        }

        case FUNNEL_CHECK_NUMBER_RET:
             return {
               ...state,
               check_number_ret:action.data,
               check_number_loading:true
             }

        case FUNNEL_REGISTER_USER: {
            return {
                ...state,
                register_user:{...action.data},
                register_user_loading:true
            }
        }

        case FUNNEL_REGISTER_USER_RET: {
            return {
                ...state,
                register_user_ret:action.data,
                register_user_loading:true
            }
        }

        case FUNNEL_REGISTER_USER_LOADING:
            return {
            ...state,
            register_user_ret:false,
            register_user_loading:false
            }

        case FUNNEL_CHECK_NUMBER_LOADING:
            console.log("Inside check Number Reducer")
            return {
            ...state,
            check_number_ret:false,
            check_number_loading:false
            }

        case FUNNEL_UPLOAD_USER_REPORT:
            return {
                ...state,
                upload_user_report: action.data,
                upload_user_report_loading: true
            }

        case FUNNEL_UPLOAD_USER_REPORT_RET:
            return {
                ...state,
                upload_user_report_ret: action.data,
                upload_user_report_loading: true
            }

        case FUNNEL_UPLOAD_USER_REPORT_LOADING:
            return {
                ...state,
                upload_user_report_loading: false
            }

        case FUNNEL_UPLOAD_INSURANCE_REPORT:
            return {
                ...state,
                upload_insurance_report: action.data,
                upload_insurance_report_loading: true
            }

        case FUNNEL_UPLOAD_INSURANCE_REPORT_RET:
            return {
                ...state,
                upload_insurance_report_ret: action.data,
                upload_insurance_report_loading: true
            }

        case FUNNEL_UPLOAD_INSURANCE_REPORT_LOADING:
            return {
                ...state,
                upload_insurance_report_loading: false
            }

        case FUNNEL_SUBMIT_MEDICAL_DETAILS:
            return {
                ...state,
                submit_medical_details: action.data,
                submit_medical_details_loading: true
            }

        case FUNNEL_SUBMIT_MEDICAL_DETAILS_RET:
            return {
                ...state,
                submit_medical_details_ret: action.data,
                submit_medical_details_loading: true
            }

        case FUNNEL_SUBMIT_MEDICAL_DETAILS_LOADING:
            return {
                ...state,
                submit_medical_details_ret: false,
                submit_medical_details_loading: false
            }

        case FUNNEL_EDIT_MEDICAL_DETAILS:
            return {
                ...state,
                edit_medical_details: action.data,
                edit_medical_details_loading: true
            }

        case FUNNEL_EDIT_MEDICAL_DETAILS_RET:
            return {
                ...state,
                edit_medical_details_ret: action.data,
                edit_medical_details_loading: true
            }

        case FUNNEL_EDIT_MEDICAL_DETAILS_LOADING:
            return {
                ...state,
                edit_medical_details_ret: false,
                edit_medical_details_loading: false
            }

        case FUNNEL_CREATE_CUSTOM_SOLUTION:
            return {
                ...state,
                create_custom_solution: action.data,
                create_custom_solution_loading: true
            }

        case FUNNEL_CREATE_CUSTOM_SOLUTION_RET:
            return {
                ...state,
                create_custom_solution_ret: action.data,
                create_custom_solution_loading: true
            }

        case FUNNEL_CREATE_CUSTOM_SOLUTION_LOADING:
            return {
                ...state,
                create_custom_solution_ret: false,
                create_custom_solution_loading: false
            }

        default:
        return {...state}
    
        }
}