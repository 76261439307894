import React, { useEffect, useState } from "react"
import { Table, Input, Button, Space, Card, Select, Modal, PageHeader, Col, Row, Calendar, TimePicker, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat, get_time, getTime} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import { update_status, update_city } from "../../actions/salesfunnel";
import AntdInput from "../../Components/AntdInput"
import SendSms from "./SendSms"
import BulkSms from "./BulkSms"
import BulkSmsList from "./BulkSmsList"
import moment from 'moment';
import AudioPlayer from "../../Components/AudioPlayer";

const { Option }  =  Select

const AllQueries = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: '',
        searchDate: "",
        reminderFilter: '',
        counter: 0
      })
      const [allQueries, setAllQueries] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
             name:'',
             email:'',
             mobileNumber:'',
            },
          filters:{
            stateAndCity: '',
            status:'',
            prospect: '',
            specialityName: '',
            requestedService: '',
            source: '',
            incomeGroup: '',
            mop: '',
            stage: '',
            queryType: '',
            agentName: '',
            paymentStatus: '',
            searchDate: null,
            reminderFilter: null,
            createdAt:1000
           }
      })

      const [data, set_data] = React.useState([])
      const [specialities, setSpecialities] = React.useState([])
      const [services, setServices] = React.useState([])
      const [visible, set_visible] = React.useState(false)
      const [editQuery, setEditQuery] = React.useState(false)
      const [selectedQuery, setSelectedQuery] = React.useState(null)
      const [bulk_sms_list , set_bulk_sms_list] = React.useState(false)
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [selected_row, set_selected_row] = React.useState({})
      const [sms_select, set_sms_select] = React.useState({})
      const [selected_city, set_selected_city] = React.useState('')
      const [selected_status, set_selected_status] = React.useState('')
      const [selected_prospect, set_selected_prospect] = React.useState('')
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [expandedNumber, setExpandedNumber] = React.useState("")
      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [selectedDate, setSelectedDate] =React.useState("")
      const [selectedDateReminder, setSelectedDateReminder] =React.useState("")
      const [selected_queries, set_selected_queries] =React.useState([])
      const [send_sms_flag, set_send_sms_flag] = React.useState(false)
      const [insuranceListGlobal, setInsuranceListGlobal] = useState([]);
      // const [selectedSpeciality, setSelectedSpeciality] = useState([]);
      const [note, set_note] = React.useState({
          note:''
    })
    const [error, set_error] = React.useState({
        note:{ state:false, text:"" }
    })
    const [template_sms_select, set_template_sms_select] = React.useState({})
    const [remarksModal, setRemarksModal] = React.useState(false)
    const [notesModal, setNotesModal] = useState(false)
    const [notesDetails, setNotesDetails] = useState([])

    const { RangePicker } = DatePicker;

    const dateFormat = 'DD/MM/YYYY';
    const weekFormat = 'MM/DD';
    const monthFormat = 'YYYY/MM';

  useEffect(() => {
    if(props.get_insurance)
      props.get_insurance()
  }, [props.get_insurance])

  useEffect(() => {
    let specials = []

    if(props.specialities_options && props.specialities_options.length > 0) {
      props.specialities_options.map((speciality) => {
        specials.push({
          text: speciality.name,
          value: speciality.name,
          name: speciality.name,
          id: speciality.value
        })
      })
      setSpecialities(specials)
    }
  }, [props.specialities_options])

  // useEffect(() => {
  //   let services = []

  //   if(props.services_options && props.services_options.length > 0) {
  //     props.services_options.map((speciality) => {
  //       services.push({
  //         text: speciality.name,
  //         value: speciality.name,
  //         id: speciality.value
  //       })
  //     })
  //     setServices(services)
  //   }
  // }, [props.services_options])

  useEffect(() => {
    if (props.get_insurance_ret) {
      if (props.get_insurance_ret.success) {
        console.log("GET_INSURANCE_RET", props.get_insurance_ret)
        let insuranceProvidersGlobal = []
        props.get_insurance_ret.data.map(insurance => {
          insuranceProvidersGlobal.push({ name: insurance.insurancePartner, value: insurance.insurancePartner} )
        })
        setInsuranceListGlobal(insuranceProvidersGlobal)
      } else {
        props.global_error({
          success: false,
          message: props.get_insurance_ret.message,
          heading: "Insurance",
        });
      }
      props.get_insurance_loading();
    }
  }, [props.get_insurance_ret]);

  useEffect(() => {
    if (props.update_query_ret) {
      if (props.update_query_ret.success) {
        props.global_error({
          success: true,
          message: props.update_query_ret.message,
          heading: "Query Update",
        });
        setEditQuery(false)
        props.get_all_queries(allQueries)
      } else {
        props.global_error({
          success: false,
          message: props.update_query_ret.message,
          heading: "Query Update",
        });
      }
      props.update_query_loading();
    }
  }, [props.update_query_ret]);

  useEffect(() => {
    props.get_all_queries(allQueries)
  }, [props.syncVal])
  
 React.useEffect(()=>{
  if(!!props.send_sms_ret){
        if(props.send_sms_ret.success){
          props.global_error({
            success:true,
            message:props.send_sms_ret.message,
            heading:'SMS'
          })

          let arr = []
          let new_arr = [...data]
          arr = new_arr.map(item=>{
            console.log(item, sms_select, "======================>")
                if(item._id===sms_select.queryId){
                  console.log(sms_select,"send_sms")
                  let new_sms = [...item.sms]
                  new_sms.unshift({
                    ...sms_select,
                    messageBody:sms_select.message
                  })
                  return {
                    ...item,
                    sms:new_sms
                  }   
                }
                else {
                  return item
                }
           })

          set_data(arr)

        }else{
          props.global_error({
            success:false,
            message:props.send_sms_ret.message,
            heading:'SMS'
          })
        }
        props.send_sms_loading()
  }
 },[props.send_sms_ret])
  
 useEffect(()=>{
  if(!!props.download_sales_leads_ret){
        if(props.download_sales_leads_ret.success){
          const link = document.createElement('a');
          link.href = props.download_sales_leads_ret.url;
          link.setAttribute('download', props.download_sales_leads_ret.filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          props.global_error({
            success:true,
            message:props.download_sales_leads_ret.message,
            heading:'Queries'
          })

        }else{
          props.global_error({
            success:false,
            message:props.download_sales_leads_ret.message,
            heading:'Queries'
          })
        }
        props.download_sales_leads_loading()
  }
 },[props.download_sales_leads_ret])

 React.useEffect(()=>{
   console.log("SEND_SMS_TEMPLATE_RET", props.send_template_sms_ret)
  if(!!props.send_template_sms_ret){
        if(props.send_template_sms_ret.success){
          props.global_error({
            success:true,
            message:props.send_template_sms_ret.message,
            heading:'SMS'
          })

          let arr = []
          let new_arr = [...data]
          arr = new_arr.map(item=>{
                if(item._id===template_sms_select.queryId){
                  let new_sms = [...item.sms]
                  new_sms.unshift({
                    ...template_sms_select,
                    messageBody: template_sms_select.templateName
                  })
                  return {
                    ...item,
                    sms:new_sms
                  }   
                }
                else {
                  return item
                }
           })

          set_data(arr)

        }else{
          props.global_error({
            success:false,
            message:props.send_template_sms_ret.message,
            heading:'SMS'
          })
        }
        props.send_template_sms_loading()
  }
 },[props.send_template_sms_ret])

    React.useEffect(()=>{
        props.get_all_queries(allQueries)
    },[])

    React.useEffect(()=>{
      if(!!props.get_all_queries_ret){
        // console.log(props.get_all_queries_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_all_queries_ret.success){
          console.error([...props.get_all_queries_ret.data])
          set_data(modif_data([...props.get_all_queries_ret.data]))
          let servs = []
          props.get_all_queries_ret.services.map((ser, index) => {
            servs.push({
              id: index,
              text: ser,
              value: ser,
              name: ser
            })
          })
          setServices(servs)
          setAllQueries({...allQueries, total:props.get_all_queries_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_all_queries_ret.message,
            heading:'Professionals'
          })
         }
          props.get_all_queries_loading()
      }


      if (!!props.update_city_ret) {
        if (props.update_city_ret.success) {
          props.global_error({
            success: true,
            message: props.update_city_ret.message,
            heading: "Update City",
          });
          if(visible){
            let new_data = data.map(item=>{
              if(!!(item._id === selected_row._id)){
                return {
                  ...item,
                  note:note.note
                }
              }else{
                return item
              }
            })
            set_data([...new_data])
            set_visible(false)
          }else {
            let new_data = data.map(item=>{
              if(!!(item._id === selected_row._id)){
                return {
                  ...item,
                  city:selected_city
                }
              }else{
                return item
              }
            })
            set_data([...new_data])
          }
        } else {
          props.global_error({
            success: false,
            message: props.update_city_ret.message,
            heading: "Update City",
          });
        }
        props.update_city_loading();
      }


      if(!!props.update_status_ret){
         if(props.update_status_ret.success){
          props.global_error({
            success:true,
            message:props.update_status_ret.message,
            heading:'Update Status'
          })
          if(visible){
             let new_data = data.map(item=>{
               console.log(item, selected_row,"item, selected_row")
               if(!!(item._id === selected_row._id)){
                 console.log(note.note,"True Called")
                 let notesNew = props.update_status_ret.data.notes.sort().reverse()
                 if(item.note) {
                  notesNew.push({
                    note: item.note
                  })
                }
                 return {
                   ...item,
                   notes: notesNew
                 }
               }else{
                 return item
               }
             })
             console.log(new_data,"new_data")
            set_data([...new_data])
            set_visible(false)
          }else {
            let new_data = data.map(item=>{
              console.log(item, selected_row,"item, selected_row")
              if(!!(item._id === selected_row._id)){
                console.log(note.note,"True Called")
                return {
                  ...item,
                  status:selected_status
                }
              }else{
                return item
              }
            })
            set_data([...new_data])
          }
         }else{
          props.global_error({
            success:false,
            message:props.update_status_ret.message,
            heading:'Update Status'
          })
         }
          props.update_status_loading()
      }

      if(!!props.update_prospect_ret){
         if(props.update_prospect_ret.success){
          props.global_error({
            success:true,
            message:props.update_prospect_ret.message,
            heading:'Update Prospect'
          })
          if(visible){
             let new_data = data.map(item=>{
               if(!!(item._id === selected_row._id)){
                 return {
                   ...item,
                   note:note.note
                 }
               }else{
                 return item
               }
             })
            set_data([...new_data])
            set_visible(false)
          }else {
            let new_data = data.map(item=>{
              if(!!(item._id === selected_row._id)){
                return {
                  ...item,
                  prospect:selected_prospect
                }
              }else{
                return item
              }
            })
            set_data([...new_data])
          }
         }else{
          props.global_error({
            success:false,
            message:props.update_prospect_ret.message,
            heading:'Update Prospect'
          })
         }
          props.update_prospect_loading()
      }

      if(!!props.update_follow_up_ret){
         if(props.update_follow_up_ret.success){
          props.global_error({
            success:true,
            message:props.update_follow_up_ret.message,
            heading:'Update Query'
          })
          if(visible){
             let new_data = data.map(item=>{
               if(!!(item._id === selected_row._id)){
                 return {
                   ...item,
                   note:note.note
                 }
               }else{
                 return item
               }
             })
            set_data([...new_data])
            set_visible(false)
          }else {
            let new_data = data.map(item=>{
              if(!!(item._id === selected_row._id)){
                return {
                  ...item,
                  paymentStatus:props.update_follow_up_ret.data.paymentStatus,
                  agentName:props.update_follow_up_ret.data.agentName,
                  incomeGroup:props.update_follow_up_ret.data.incomeGroup,
                  mop:props.update_follow_up_ret.data.mop,
                  stage:props.update_follow_up_ret.data.stage,
                }
              }else{
                return item
              }
            })
            set_data([...new_data])
          }
         }else{
          props.global_error({
            success:false,
            message:props.update_follow_up_ret.message,
            heading:'Update Prospect'
          })
         }
          props.update_follow_up_loading()
      }
     
         
  },[props.get_all_queries_ret, props.update_city_ret, props.update_status_ret, props.update_prospect_ret, props.update_follow_up_ret, props.user])

     const handleChange = (pagination, filters, sorter) => {
       let state_filters = {...allQueries.filters}
       let updated_filters = {}
   
      //  updated_filters.city = filters.city
       updated_filters.status = filters.status
       updated_filters.stateAndCity = filters.stateAndCity
       updated_filters.prospect = filters.prospect
       updated_filters.specialityName = filters.specialityName
       updated_filters.requestedService = filters.requestedService
       updated_filters.source = filters.source
       updated_filters.queryType = filters.queryType
       updated_filters.paymentStatus = filters.paymentStatus
       updated_filters.agentName = filters.agentName
       updated_filters.incomeGroup = filters.incomeGroup
       updated_filters.mop = filters.mop
       updated_filters.stage = filters.stage
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === allQueries.page) && (pagination.pageSize === allQueries.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          if(state_filters.specialityName !== updated_filters.specialityName && filters.specialityName) {
            props.get_services({ speciality: specialities.find((special) => special.text === filters.specialityName[0]).id });
          }
          setAllQueries({...allQueries, page:1, filters :{
           createdAt: filters.createdAt? filters.createdAt:'',
          //  city:filters.city?filters.city:'',
           status:filters.status?filters.status:'',
           stateAndCity:filters.stateAndCity?filters.stateAndCity:'',
           prospect:filters.prospect?filters.prospect:'',
           speciality:filters.specialityName?filters.specialityName:'',
           service:filters.requestedService?filters.requestedService:'',
           agentName:filters.agentName?filters.agentName:'',
           source:filters.source?filters.source:'',
           incomeGroup:filters.incomeGroup?filters.incomeGroup:'',
           mop:filters.mop?filters.mop:'',
           stage:filters.stage?filters.stage:'',
           queryType:filters.queryType?filters.queryType:'',
           paymentStatus:filters.paymentStatus?filters.paymentStatus:''
          }})
          props.get_all_queries({...allQueries, page:1, filters :{
            createdAt: filters.createdAt? filters.createdAt:'',
            stateAndCity:filters.stateAndCity?filters.stateAndCity:'',
            // city:filters.city?filters.city:'',
            status:filters.status?filters.status:'', 
            prospect:filters.prospect?filters.prospect:'',
            speciality:filters.specialityName?filters.specialityName:'',
            service:filters.requestedService?filters.requestedService:'',
            agentName:filters.agentName?filters.agentName:'',
            source:filters.source?filters.source:'',
            incomeGroup:filters.incomeGroup?filters.incomeGroup:'',
            mop:filters.mop?filters.mop:'',
            stage:filters.stage?filters.stage:'',
            queryType:filters.queryType?filters.queryType:'',
            paymentStatus:filters.paymentStatus?filters.paymentStatus:''
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });

     const  getColumnDateSearchProps = (dataIndex,placeholder, searchProp, type) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            ref={node => {
              state.searchDate = node;
            }}
            style={{ margin: "auto", width: "100%"}}
            value={selectedDate.length > 0 ? [moment(selectedDate.split(',')[0], dateFormat), moment(selectedDate.split(',')[1], dateFormat)] : null}
            onChange={(e) => {
              if(e) {
                let dateFilter = [e[0].format("YYYY-MM-DD") + "," + e[1].format("YYYY-MM-DD")]
                setSelectedDate(e[0].format("DD/MM/YYYY") + "," + e[1].format("DD/MM/YYYY"))
                setSelectedKeys(dateFilter)
              }
            }}
            format={dateFormat}
          />
          <Row
            gutter={12}
            justify="center"
            style={{ margin: "auto", width: "100%", marginTop: 8, display: 'flex', justifyContent: 'center' }}
          >
            <Col span={12}>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: "100%" }}>
                Reset
              </Button>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                disabled={selectedDate.length === 0}
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp, true)}
                icon={<DownloadOutlined />}
                size="small"
                style={{ width: "100%", display: "block", marginTop: 8 }}
              >
                Download
              </Button>
            </Col>
          </Row>
        </div>
      ),
    });

     const  getColumnReminderDateSearchProps = (dataIndex,placeholder, searchProp, type) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            ref={node => {
              state.reminderFilter = node;
            }}
            value={selectedDateReminder.length > 0 ? [moment(selectedDateReminder.split(',')[0], dateFormat), moment(selectedDateReminder.split(',')[1], dateFormat)] : null}
            onChange={(e) => {
              if(e) {
                let dateFilter = [e[0].format("YYYY-MM-DD") + "," + e[1].format("YYYY-MM-DD")]
                setSelectedDateReminder(e[0].format("DD/MM/YYYY") + "," + e[1].format("DD/MM/YYYY"))
                setSelectedKeys(dateFilter)
              }
            }}
            format={dateFormat}
          />
          <Space
            style={{ width: 188, marginTop: 8, display: 'flex', justifyContent: 'center' }}
          >
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
    });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp, downloadRequest) => {
        confirm();
         setAllQueries({...allQueries, page:1,searchParams:{
           ...allQueries.searchParams,[searchProp]:selectedKeys[0]
         }})
         if(downloadRequest)
         props.download_sales_leads({...allQueries, page:1,searchParams:{
          ...allQueries.searchParams,[searchProp]:selectedKeys[0],
        }})
        else
         props.get_all_queries({...allQueries, page:1,searchParams:{
          ...allQueries.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        setSelectedDate("")
        set_state({ searchText: '', selectedDate: "" });
      };


    const modif_data = (arr) => {
    let query_list = []
        for_loop(arr, (item) => {

            let timeString = getTime(item.createdAt)
            const [hourString, minute] = timeString.split(":");
            const hour = +hourString % 24;
            timeString = ((hour % 12 || 12) < 10 ? `0${(hour % 12 || 12)}` : (hour % 12 || 12)) + ":" + minute + (hour < 12 ? " AM" : " PM");

            let reminderTS = ""
            if(item.reminderTime) {
              reminderTS = getTime(item.reminderTime)
              const [hourString, minute] = reminderTS.split(":");
              const hour = +hourString % 24;
              reminderTS = ((hour % 12 || 12) < 10 ? `0${(hour % 12 || 12)}` : (hour % 12 || 12)) + ":" + minute + (hour < 12 ? " AM" : " PM");
            }

            let obj = {
              name: item.name,
              email: item.email ? item.email : "N/A",
              message:item.message,
              mobileNumber:item.mobileNumber,
              specialityName:item.specialityName?item.specialityName:'N/A',
              hospitalName:item.hospitalName?item.hospitalName:'N/A',
              requestedService:item.requestedService?item.requestedService:'N/A',
              note:item.note,
              utmTerm:item.utmTerm,
              notes: item.notes ? item.notes.sort().reverse() : [],
              queryType: item.queryType,
              recording_url: item.recordingUrl,
              city:item.stateAndCity ? item.stateAndCity : "N/A",
              status:item.status ? item.status : "N/A",
              prospect:item.prospect ? item.prospect : "N/A",
              paymentStatus: item.paymentStatus ? item.paymentStatus : "N/A",
              insuranceProvider:item.insuranceProvider ? item.insuranceProvider : "N/A",
              address:item.address ? item.address : "N/A",
              stateAndCity:item.stateAndCity ? item.stateAndCity : "",
              reminderTime: item.reminderTime ? `${getDateFormat(item.reminderTime)}   ${reminderTS}` : "N/A",
              source:item.source,
              incomeGroup:item.incomeGroup,
              mop:item.mop,
              gclId:item.gclId,
              stage:item.stage,
              agentName:item.agentName ? item.agentName : "N/A",
              age:item.age ? item.age : "N/A",
              gender:item.gender ? item.gender : "N/A",
              createdAt:getDateFormat(item.createdAt),
              createdAtTime: timeString,
              sms:item.sentMessages,
              _id: item._id
            }
            if(item.note) {
              obj.notes.push({
                note: item.note
              })
            }
            query_list.push({
          ...obj
            })
           
        })

    return query_list;
    }

    const submitQueryEdit = () => {
      try {
        // if(!selectedQuery.time || !selectedQuery.date || selectedQuery.time===0  || selectedQuery.date===""){
        //   set_error({...error, date:{state:true, text:'Select time to set reminder'}})
        //   throw new Error("Select time to set reminder")
        // }
        // timeSlot: update_follow_up_data.time*1000,
        // date: update_follow_up_data.date,
        props.update_query({...selectedQuery, timeSlot: selectedQuery.time*1000})
      } catch (error) {
        props.global_error({
          success:false,
          message:error.message,
          heading:"Set Reminder",
        })  
      }
    }
  
    const update_payment_status = (val, data) => {
      set_selected_row(data)
      props.update_follow_up({
        queryId:data._id,
        source:data.source,
        paymentStatus:val,
        type:'update_payment_status'
      })
    }
  
    const update_agent_name = (val, data) => {
      set_selected_row(data)
      props.update_follow_up({
        queryId:data._id,
        agentId:val,
        type:'update_agent_name'
      })
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      setAllQueries({...allQueries, limit:b, page:1})
      props.get_all_queries({...allQueries, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,allQueries,"a and b allQueries")
      // if(allQueries.page !== a){
        if(true){
        setAllQueries({...allQueries, page:a, limit:b})
        props.get_all_queries({...allQueries, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      setAllQueries({...allQueries,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:'',
        // stateAndCity: ""
      },
      filters:{
        city:'',
        status:'',
        searchDate:'',
        reminderFilter: '',
        createdAt:1000
      }})
      props.get_all_queries({...allQueries,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:'',
        // stateAndCity: ""
      },
      filters:{
        city:'',
        status:'',
        searchDate: '',
        reminderFilter: '',
        createdAt:1000
      }})
      set_state({ ...state, counter: state.counter + 1 })
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      setAllQueries({...allQueries, page:1, filters:{
        city:'',
        status:'',
        createdAt:1000
      }})
      props.get_all_queries({...allQueries, page:1, filters:{
        city:'',
        status:'',
        createdAt:1000
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const update_city = (val, record) => {
      set_selected_row(record)
      set_selected_city(val)
      props.update_city({
        city:val,
        queryId:record._id,
        flag:"Non-Registered"
      })
    }

    const update_status = (val, record ) => {
        set_selected_row(record)
        set_selected_status(val)
            props.update_status({
              status:val,
              queryId:record._id,
              flag:"Non-Registered"
            })
    }

    const update_prospect = (val, record ) => {
        set_selected_row(record)
        set_selected_prospect(val)
            props.update_prospect({
              prospect:val,
              queryId:record._id,
              flag:"Non-Registered"
            })
    }

    const close_modal = () => {
            set_visible(false)
    }

    const expand = (record,a,b) => {
      setExpandedNumber(a.mobileNumber)
      if(expandedRowKeys[0]!== a._id){
         set_expandedRowKeys([a._id])
         console.log(a,"a.varianceInfo")
      }else{
      set_expandedRowKeys([])
      }
    }

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name', "Search name", 'name'),
        width:'30%',
        fixed:'left'
      },
      
      {
        title: 'Phone',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        width:'30%',
        fixed:'left'
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        width:'25%',
        filters: [
          {
            text: 'Facebbok',
            value: 'FACEBOOK',
          },
          {
            text: "Organic",
            value: "ORGANIC"
          },
          {
            text: "GADS",
            value: "GADS"
          }
        ],
        // filterMultiple: false
      },
      props.user.role === "ADMIN" ? {
        title: 'Search Term',
        dataIndex: 'utmTerm',
        key: 'utmTerm',
        // ...getColumnSearchProps('name', "Search name", 'name'),
        width:'40%',
        // fixed:'left'
      } : {},
      {
        title: 'GCL ID',
        dataIndex: 'gclId',
        key: 'gclId',
        // ...getColumnSearchProps('name', "Search name", 'name'),
        width:'40%',
        // fixed:'left'
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        // ...getColumnSearchProps('name', "Search name", 'name'),
        width:'20%',
        // fixed:'left'
      },
      {
        title: 'Type',
        dataIndex: 'queryType',
        key: 'queryType',
        width:'35%',
        filters: [
          {
            text: "Ivr Calls",
            value: "IVR_QUERY"
          },
          {
            text: "Public",
            value: "PUBLIC_QUERY"
          }
        ]
        // filterMultiple: false
      },
      {
        title: 'City',
        dataIndex: 'stateAndCity',
        key: 'stateAndCity',
        width:'25%',
        // ...getColumnSearchProps('stateAndCity', "Search City", 'stateAndCity'),
        // filterMultiple: false
        filters: [
          {
            text: 'Delhi',
            value: 'Delhi',
          },
          {
            text: 'Gurgaon',
            value: 'Gurgaon',
          },
          {
            text: "Faridabad",
            value: "Faridabad"
          },
          {
            text: "Ghaziabad",
            value: "Ghaziabad"
          },
          {
            text: "Noida",
            value: "Noida"
          },
          {
            text: "Greater Noida",
            value: "Greater Noida"
          },
          {
            text: "Mumbai",
            value: "Mumbai"
          },
          {
            text: "Navi Mumbai",
            value: "Navi Mumbai"
          },
          {
            text: "Thane",
            value: "Thane"
          },
          {
            text: "Bangalore",
            value: "Bangalore"
          },
          {
            text: "Pune",
            value: "Pune"
          },
          {
            text: "Hyderabad",
            value: "Hyderabad"
          },
          {
            text: "Kolkata",
            value: "Kolkata"
          },
          {
            text: "Others",
            value: "Others"
          }
        ],
        // filterMultiple: false,   
        // onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
        render:(item, record) => {
            return <div className="u-align-center">
                <Select value={record.city} style={{ width: 120 }} onChange={(val)=> {
                  update_city(val, record)
                }}>
                  <Option disabled={record.city==="Delhi"} value="Delhi">Delhi</Option>
                  <Option disabled={record.city==="Gurgaon"} value="Gurgaon">Gurgaon</Option>
                  <Option disabled={record.city==="Faridabad"} value="Faridabad">Faridabad</Option>
                  <Option disabled={record.city==="Ghaziabad"} value="Ghaziabad">Ghaziabad</Option>
                  <Option disabled={record.city==="Noida"} value="Noida">Noida</Option>
                  <Option disabled={record.city==="Greater Noida"} value="Greater Noida">Greater Noida</Option>
                  <Option disabled={record.city==="Mumbai"} value="Mumbai">Mumbai</Option>
                  <Option disabled={record.city==="Navi Mumbai"} value="Navi Mumbai">Navi Mumbai</Option>
                  <Option disabled={record.city==="Thane"} value="Thane">Thane</Option>
                  <Option disabled={record.city==="Bangalore"} value="Bangalore">Bangalore</Option>
                  <Option disabled={record.city==="Pune"} value="Pune">Pune</Option>
                  <Option disabled={record.city==="Hyderabad"} value="Hyderabad">Hyderabad</Option>
                  <Option disabled={record.city==="Kolkata"} value="Kolkata">Kolkata</Option>
                  <Option disabled={record.city==="Others"} value="Others">Others</Option>
                </Select>
            </div>
        }
      },
      {
        title: 'Speciality',
        dataIndex: 'specialityName',
        key: 'specialityName',
        width:'35%',
        filters: specialities,
        filterMultiple: false,   
        onChange :(a,b,c)=>{
          console.log(a,b,c,"FILTER CHANGE")
        },
      },
      {
        title: 'Service',
        dataIndex: 'requestedService',
        key: 'requestedService',
        // ...getColumnSearchProps('name', "Search name", 'name'),
        width:'30%',
        filters: services,
        // fixed:'left'
      },
      {
        title: 'Professional',
        dataIndex: 'hospitalName',
        key: 'hospitalName',
        // ...getColumnSearchProps('name', "Search name", 'name'),
        width:'30%',
        // fixed:'left'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email', "Search email", 'email'),
        width:'35%'
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        width:'30%',
        // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        render: (text) => {
          return <div>
            {text && text.length > 20 ? <span>{text && text.slice(0, 20)} ... <br /> <button className="remarks-view-more" onClick={() => setRemarksModal(text)}>View More</button></span> : text}
            <Modal
              title="Message"
              visible={remarksModal}
              onOk={() => setRemarksModal(false)}
              onCancel={() => setRemarksModal(false)}
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              {remarksModal}
            </Modal>
          </div> 
        }
        
      },
      {
        title: 'Note',
        dataIndex: 'notes',
        key: 'notes',
        width:'20%',
        render:(text)=>{
          return text ? <Button onClick={() => {
            setNotesModal(true)
            setNotesDetails(text)
          }}>Show</Button> : "N/A"
        },
        // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        
      },
      {
        title: 'Recording',
        dataIndex: 'recording_url',
        key: 'recording_url',
        width:'30%',
        render:(val)=>
          val ? <AudioPlayer  
          streamUrl={val}
          trackTitle={"Recorded Audio"} 
          preloadType="auto" /> : "N/A"
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        // ellipsis: true,
      },
      {
        title: 'Agent',
        dataIndex: 'agentName',
        key: 'agentName',
        width:'35%',
        filters: props.global_store.get_agent_list_ret.data.map((item)=>{
              return {
                text:item.name,
                value:item.name
              }
        }),
        render:(item, record) => {
          console.log(props.global_store.get_agent_list_ret.data,"props.global_store")
          return <div>
             <div className="u-align-center">
                    <Select value={record.agentName} style={{ width: 120 }} onChange={(val)=>update_agent_name(val, record)}>
                    {props.global_store.get_agent_list_ret.data.map((item)=>{
              return       <Option disabled={record.agentName===item._id} value={item._id}>{item.name}</Option>
                      })}
                   </Select>
                </div>
          </div>
        }
        // fixed:'left'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width:'25%',
        filters: [
          {
            text: 'Pending',
            value: 'PENDING',
          },
          {
            text: "Irrelevant",
            value: "Irrelevant"
          },
          {
            text: "Follow-up",
            value: "Follow-up"
          },
          {
            text: "Repeat",
            value: "Repeat"
          },
          {
            text: "DNP",
            value: "DNP"
          },
          {
            text: "OPD Scheduled",
            value: "OPD_Scheduled"
          },
          {
            text: "OPD Done",
            value: "OPD_Done"
          },
          {
            text: "OPD Cancelled",
            value: "OPD_Cancelled"
          },
          {
            text: "OPD Rescheduled",
            value: "OPD_Rescheduled"
          },
          {
            text: "IPD Scheduled",
            value: "IPD_Scheduled"
          },
          {
            text: "IPD Done",
            value: "IPD_Done"
          },
          {
            text: "IPD Cancelled",
            value: "IPD_Cancelled"
          },
          {
            text: "IPD Rescheduled",
            value: "IPD_Rescheduled"
          },
          {
            text: "Low Budget",
            value: "Low_Budget"
          },
          {
            text: "Medication",
            value: "Medication"
          },
          {
            text: "Other Hospital",
            value: "Other_Hospital"
          },
          {
            text: "Mode of Payment",
            value: "Mode_of_Payment"
          },
          {
            text: "Remote Lead",
            value: "Remote_Lead"
          },
          {
            text: "Plan Dropped",
            value: "Plan_Dropped"
          },
          {
            text: "Already Visited",
            value: "Already_Visited"
          },
          {
            text: "Test Lead",
            value: "Test_Lead"
          },
          {
            text: "Lost",
            value: "Lost"
          },
          {
            text: "Govt Hospital",
            value: "Govt_Hospital"
          },
          {
            text: "Hospital Empanelment",
            value: "Hospital_Empanelment"
          },
        ],
        // filterMultiple: false,   
        onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
        render:(item, record) => {
            return <div className="u-align-center">
                <Select value={record.status} style={{ width: 120 }} onChange={(val)=>update_status(val, record)}>
                  <Option disabled={record.status==="Irrelevant"} value="Irrelevant">Irrelevant</Option>
                  <Option disabled={record.status==="Follow-up"} value="Follow-up">Follow-up</Option>
                  <Option disabled={record.status==="Repeat"} value="Repeat">Repeat</Option>
                  <Option disabled={record.status==="DNP"} value="DNP">DNP</Option>
                  <Option disabled={record.status==="OPD_Scheduled"} value="OPD_Scheduled">OPD Scheduled</Option>
                  <Option disabled={record.status==="OPD_Done"} value="OPD_Done">OPD Done</Option>
                  <Option disabled={record.status==="OPD_Cancelled"} value="OPD_Cancelled">OPD Cancelled</Option>
                  <Option disabled={record.status==="OPD_Rescheduled"} value="OPD_Rescheduled">OPD Rescheduled</Option>
                  <Option disabled={record.status==="IPD_Scheduled"} value="IPD_Scheduled">IPD Scheduled</Option>
                  <Option disabled={record.status==="IPD_Done"} value="IPD_Done">IPD Done</Option>
                  <Option disabled={record.status==="IPD_Cancelled"} value="IPD_Cancelled">IPD Cancelled</Option>
                  <Option disabled={record.status==="IPD_Rescheduled"} value="IPD_Rescheduled">IPD Rescheduled</Option>
                  <Option disabled={record.status==="Low_Budget"} value="Low_Budget">Low Budget</Option>
                  <Option disabled={record.status==="Medication"} value="Medication">Medication</Option>
                  <Option disabled={record.status==="Other_Hospital"} value="Other_Hospital">Other Hospital</Option>
                  <Option disabled={record.status==="Mode_of_Payment"} value="Mode_of_Payment">Mode of Payment</Option>
                  <Option disabled={record.status==="Remote_Lead"} value="Remote_Lead">Remote Lead</Option>
                  <Option disabled={record.status==="Plan_Dropped"} value="Plan_Dropped">Plan Dropped</Option>
                  <Option disabled={record.status==="Already_Visited"} value="Already_Visited">Already Visited</Option>
                  <Option disabled={record.status==="Test_Lead"} value="Test_Lead">Test Lead</Option>
                  <Option disabled={record.status==="Lost"} value="Lost">Lost</Option>
                  <Option disabled={record.status==="Govt_Hospital"} value="Govt_Hospital">Govt Hospital</Option>
                  <Option disabled={record.status==="Hospital_Empanelment"} value="Hospital_Empanelment">Hospital Empanelment</Option>
                </Select>
            </div>
        }
      },
      {
        title: 'Prospect',
        dataIndex: 'prospect',
        key: 'prospect',
        width:'35%',
        filters: [
          {
            text: 'Pending',
            value: 'PENDING',
          },
          {
            text: 'Hot',
            value: 'HOT',
          },
          {
            text: "Warm",
            value: "WARM"
          },
          {
            text: "Cold",
            value: "COLD"
          }
        ],
        // filterMultiple: false,   
        onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
        render:(item, record) => {
            return <div className="u-align-center">
                <Select value={record.prospect} style={{ width: 120 }} onChange={(val)=> {
                  update_prospect(val, record)
                }}>
                  <Option disabled={record.prospect==="PENDING"} value="PENDING">Pending</Option>
                  <Option disabled={record.prospect==="HOT"} value="HOT">Hot</Option>
                  <Option disabled={record.prospect==="WARM"} value="WARM">Warm</Option>
                  <Option disabled={record.prospect==="COLD"} value="COLD">Cold</Option>
                </Select>
            </div>
        }
      },
      {
        title: 'Payment',
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
        width:'35%',
        filters: [
          {
            text: 'PENDING',
            value: 'PENDING',
          },
          {
            text: "NOT REQUESTED",
            value: "NOT_REQUESTED"
          },
          {
            text: "REQUESTED",
            value: "REQUESTED"
          },
          {
            text: "DONE",
            value: "DONE"
          }
        ],
        render:(item, record) => {
          return <div>
             <div className="u-align-center">
                    <Select value={record.paymentStatus} style={{ width: 120 }} onChange={(val)=>update_payment_status(val, record)}>
                      <Option disabled={record.paymentStatus==="PENDING"} value="PENDING">PENDING</Option>
                      <Option disabled={record.paymentStatus==="NOT_REQUESTED"} value="NOT_REQUESTED">NOT REQUESTED</Option>
                      <Option disabled={record.paymentStatus==="REQUESTED"} value="REQUESTED">REQUESTED</Option>
                      <Option disabled={record.paymentStatus==="DONE"} value="DONE">DONE</Option>
                   </Select>
                </div>
          </div>
        }
      },
      {
        title: 'Income Group',
        dataIndex: 'incomeGroup',
        key: 'incomeGroup',
        width:'40%',
        filters: [
          {
            text: 'LOW',
            value: 'LOW',
          },
          {
            text: "MID",
            value: "MID"
          },
          {
            text: "HIGH",
            value: "HIGH"
          },
          {
            text: "PENDING",
            value: "PENDING"
          }
        ],
        render:(item, record) => {
          return <div>
             <div className="u-align-center">
                    <Select value={record.incomeGroup} style={{ width: 120 }} onChange={(val)=>update_status_incomeGroup(val, record)}>
                      <Option disabled={record.incomeGroup==="PENDING"} value="PENDING">PENDING</Option>
                      <Option disabled={record.incomeGroup==="HIGH"} value="HIGH">HIGH</Option>
                      <Option disabled={record.incomeGroup==="MID"} value="MID">MID</Option>
                      <Option disabled={record.incomeGroup==="LOW"} value="LOW">LOW</Option>
                   </Select>
                </div>
          </div>
        }
      },
      {
        title: 'MOP',
        dataIndex: 'mop',
        key: 'mop',
        width:'35%',
        filters: [
          {
            text: 'PENDING',
            value: 'PENDING',
          },
          {
            text: 'CASH',
            value: 'CASH',
          },
          {
            text: "EMI",
            value: "EMI"
          },
          {
            text: "INSURANCE",
            value: "INSURANCE"
          },
          {
            text: "OTHERS",
            value: "OTHERS"
          }
        ],
        render:(item, record) => {
          return <div>
             <div className="u-align-center">
                    <Select value={record.mop} style={{ width: 120 }} onChange={(val)=>update_status_mop(val, record)}>
                      <Option disabled={record.mop==="PENDING"} value="PENDING">PENDING</Option>
                      <Option disabled={record.mop==="CASH"} value="CASH">CASH</Option>
                      <Option disabled={record.mop==="EMI"} value="EMI">EMI</Option>
                      <Option disabled={record.mop==="INSURANCE"} value="INSURANCE">INSURANCE</Option>
                      <Option disabled={record.mop==="OTHERS"} value="OTHERS">OTHERS</Option>
                   </Select>
                </div>
          </div>
        }
      },
      {
        title: 'Stage',
        dataIndex: 'stage',
        key: 'stage',
        width:'35%',
        filters: [
          {
            text: 'PENDING',
            value: 'PENDING',
          },
          {
            text: 'SYMPTOMS',
            value: 'SYMPTOMS',
          },
          {
            text: "EARLY",
            value: "EARLY"
          },
          {
            text: "CHRONIC",
            value: "CHRONIC"
          },
          {
            text: "LAST",
            value: "LAST"
          }
        ],
        render:(item, record) => {
          return <div>
             <div className="u-align-center">
                    <Select value={record.stage} style={{ width: 120 }} onChange={(val)=>update_status_stage(val, record)}>
                      <Option disabled={record.stage==="PENDING"} value="PENDING">PENDING</Option>
                      <Option disabled={record.stage==="SYMPTOMS"} value="SYMPTOMS">SYMPTOMS</Option>
                      <Option disabled={record.stage==="EARLY"} value="EARLY">EARLY</Option>
                      <Option disabled={record.stage==="CHRONIC"} value="CHRONIC">CHRONIC</Option>
                      <Option disabled={record.stage==="LAST"} value="LAST">LAST</Option>
                   </Select>
                </div>
          </div>
        }
      },
      {
          title: 'Date',
          dataIndex: 'createdAt',
          key: 'date',
          width:'25%',
          filterMultiple: false,
          ...getColumnDateSearchProps('date', "Search dateda", 'date', 'date'),
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),          
      },
      {
          title: 'Time',
          dataIndex: 'createdAtTime',
          key: 'createdAtTime',
          width:'20%'          
      },
      {
          title: 'Reminder',
          dataIndex: 'reminderTime',
          key: 'reminderTime',
          ...getColumnReminderDateSearchProps('reminderDate', "Search Reminder Date", 'reminderDate', 'reminderDate'),
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"), 
          width:'17.5%'          
      },
      {
        title: 'Action',
        key: 'action',
        width:'20%',
        render: (text, record) => (
          <Space size="middle">
            <a onClick = {()=>open_modal(record)}>Add/Update Note</a>
          </Space>
        ),
      }
    ]

      const open_modal = (record) => {
        set_selected_row(record)
        set_visible(true)
        set_note({
          note:record.note
        })
      }

      const submit_note = () => {
        props.update_status({
          notes:note.note,
          queryId:selected_row._id,
          flag:"Non-Registered"
        })
      }

      const update_status_incomeGroup = (val, record) => {
        set_selected_row(record)
        
        props.update_follow_up({
          incomeGroup: val,
          queryId: record._id,
          type:'update_status_incomeGroup'
        })
      }

      const update_status_mop = (val, record) => {
        set_selected_row(record)

        props.update_follow_up({
          mop: val,
          queryId: record._id,
          type:'update_status_mop'
        })
      }

      const update_status_stage = (val, record) => {
        set_selected_row(record)
        
        props.update_follow_up({
          stage: val,
          queryId: record._id,
          type:'update_status_stage'
        })
      }

      const send_bulk_sms = (data) => {
        let userids = ""
        data.solutionIds.map((item,i)=>{
                    if(i!==0){
                      userids = `${userids},${item}`
                    }else{
                      userids = `${userids}${item}`
                    }
                    
                  })
        props.send_sms({
          queryId:userids,
          sentMessage:data.messageBody,
          type:'sms'
        })
   }

      function showTotal(total) {
        return `Total ${total} items`;
      }

   if(!!professional_selected){
        return <Redirect to={`/dashboard/professionals?professional_id=${professional_selected._id}`} />
   }

   if(send_sms_flag){
    return <React.Fragment>
           <PageHeader
            className="site-page-header"
            onBack={() => {
              set_send_sms_flag(false)
              set_selected_queries([])
            }}
            title="Send Bulk Messages"
          >
          <BulkSms
            list = {selected_queries}
            send_sms = {send_bulk_sms}
            send_sms_ret = {props.update_facebook_data_ret}
            send_sms_loading = {props.update_facebook_data_loading}
            send_sms_loading_flag = {props.update_facebook_data_loading_flag}
            global_error = {props.global_error}
          />

      </PageHeader>

    </React.Fragment>
  }


  if(bulk_sms_list){
    return <React.Fragment>
           <PageHeader
     className="site-page-header"
   onBack={() => {
     set_bulk_sms_list(false)
     set_selected_queries([])
   }}
   title="Previous Bulk Messages"
   // subTitle="This is a subtitle"
 >
          <BulkSmsList
            reportType = {"NON_REG_PUBLIC_QUERIES_REPORT"}

            get_bulk_sms_list = {props.get_bulk_sms_list}
            get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
            get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
            get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

            global_error = {props.global_error}
          />

      </PageHeader>

    </React.Fragment>
  }



  

        return (<React.Fragment>
          <Modal
          title="Update/Add"
          visible={visible}
          onOk={submit_note}
          onCancel={close_modal}
        >
    <div className="flex-parent-new">
       <span className="flex-child-1-new">
           <AntdInput label="Note"  
            value={note.note} 
            error_text={error.note.text} 
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error,note:{text:'',state:false}})} 
            error={error.note.state} 
            name="note" 
            onChange = {event=>set_note({[event.target.name]:event.target.value})} 
            placeholder="Enter note"
            type="textarea"
            rows= {5}
            />
      </span>
</div>
        </Modal>
        <Modal
            title="Query Notes"
            open={notesModal}
            width={"70%"}
            onOk={() => setNotesModal(false)}
            onCancel={() => setNotesModal(false)}
        >
          <Table 
            columns={[
              {
                title: 'Agent Name',
                dataIndex: 'agent',
                key: 'agent',
                width:'20%'          
              },
              {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                width:'40%'          
              },
              {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (text) => text ? <>{getDateFormat(text)}</> : "N/A"       
              },
              {
                title: 'Time',
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (text) =>  {
                  if(text) {
                    let timeString = getTime(text)
                    const [hourString, minute] = timeString.split(":");
                    const hour = +hourString % 24;
                    timeString = ((hour % 12 || 12) < 10 ? `0${(hour % 12 || 12)}` : (hour % 12 || 12)) + ":" + minute + (hour < 12 ? " AM" : " PM");
                    return timeString
                  } else return "N/A"
                }              
              },
            ]}
            dataSource={notesDetails}
          />
        </Modal>

        {(selected_queries.length >1) &&  <Button
                type="primary"
                onClick={() => set_send_sms_flag(true)}
                size="small"
                style={{ width: 90 }}
                className="u-margin-top-small"
              >
                Send Sms
            </Button>}
          
            <div className="u-margin-top-small">
        <Table
          columns={columns}
          dataSource={data}
          key={`all-queries-table ${state.counter}`}
          className="all-query-table"
          loading={props.get_all_queries_loading_flag || props.sync_knowlarity_leads_loading_flag || props.download_sales_leads_loading_flag}
          onChange={handleChange}
          rowClassName={(record) => record.status === "PENDING" ? "new-lead cursor-pointer all-queries-item" : (record.status === "Irrelevant" || record.status === "Repeat" || record.status === "IPD_Done" || record.status === "Low_Budget" || record.status === "Medication" || record.status === "Other_Hospital" || record.status === "Mode_of_Payment" || record.status === "Remote_Lead" || record.status === "Plan_Dropped" || record.status === "Already_Visited" || record.status === "Test_Lead" || record.status === "Hospital_Empanelment" ||  record.status === "Lost" || record.status === "Govt_Hospital") ? "closed-lead cursor-pointer all-queries-item" : "follow-up-lead cursor-pointer all-queries-item"}
          scroll={{ x: 4000 }}
          pagination = {{
            showSizeChanger:true,
            limit:allQueries.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:allQueries.page,
            showTotal:showTotal,
            total:allQueries.total
          }}
          expandedRowRender= {record =>{
             return <div id={expandedNumber} key={record._id + "-" + expandedNumber}>
                <Modal
                  title="Update/Add"
                  visible={editQuery}
                  width="80%"
                  onOk={submitQueryEdit}
                  onCancel={() => {
                    setSelectedQuery(null)
                    setEditQuery(false)
                  }}
                >
                  {selectedQuery && <Row gutter={[16,0]} className="query-details" align="middle" justify="center">
                    <Col span={4}>
                      <label className="query-label">Name</label>
                      <Input
                        placeholder={selectedQuery.name}
                        value={selectedQuery.name}
                        onChange={(e) => setSelectedQuery({...selectedQuery, name: e.target.value})}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Number</label>
                      <Input
                        disabled={true}
                        placeholder={selectedQuery.mobileNumber}
                        value={selectedQuery.mobileNumber}
                        onChange={(e) => setSelectedQuery({...selectedQuery, mobileNumber: e.target.value})}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">City</label>
                      <AntdInput
                        value={selectedQuery.stateAndCity}
                        // loading={props.specialities_loading}
                        disabled
                        name="city"
                        onChange={(e) => setSelectedQuery({...selectedQuery, stateAndCity: e.target.value})}
                        placeholder={selectedQuery.stateAndCity}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Source</label>
                      <div className="query-content">{selectedQuery.source}</div>
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Speciality</label>
                      <AntdInput
                        value={selectedQuery.specialityName}
                        options={specialities}
                        // loading={props.specialities_loading}
                        name="speciality"
                        type="select"
                        onChange={(e) => setSelectedQuery({...selectedQuery, specialityName: e})}
                        placeholder={selectedQuery.specialityName}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Service</label>
                      <AntdInput
                        value={selectedQuery.requestedService}
                        options={services}
                        // loading={props.specialities_loading}
                        name="requestedService"
                        type="select"
                        onChange={(e) => setSelectedQuery({...selectedQuery, requestedService: e})}
                        placeholder={selectedQuery.requestedService}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Age</label>
                      <Input
                        placeholder={selectedQuery.age}
                        value={selectedQuery.age}
                        onChange={(e) => setSelectedQuery({...selectedQuery, age: e.target.value})}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Gender</label>
                      <AntdInput
                        value={selectedQuery.gender}
                        options={[ {name:"Male", value:"Male"}, {name:"Female", value:"Female"}]}
                        // loading={props.specialities_loading}
                        name="gender"
                        type="select"
                        onChange={(e) => setSelectedQuery({...selectedQuery, gender: e})}
                        placeholder={selectedQuery.gender}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Address</label>
                      <Input
                        placeholder={selectedQuery.address}
                        value={selectedQuery.address}
                        onChange={(e) => setSelectedQuery({...selectedQuery, address: e.target.value})}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Insurance Partner</label>
                      <AntdInput
                        value={selectedQuery.insuranceProvider}
                        options={insuranceListGlobal}
                        // loading={props.specialities_loading}
                        name="insuranceProvider"
                        type="select"
                        onChange={(e) => setSelectedQuery({...selectedQuery, insuranceProvider: e})}
                        placeholder={selectedQuery.insuranceProvider}
                      />
                    </Col>
                    <Col span={4}>
                      <label className="query-label">Status</label>
                      <div className="query-content">{selectedQuery.status}</div>
                    </Col>
                    <Col span={18}>
                      <h2 className=" u-margin-top-small" style={{textAlign: "center"}}>Select Reminder Time</h2>
                      <div className="flex-parent-new u-margin-top-small">
                        <span className="flex-child-1-new">
                            <Col>
                              <Calendar fullscreen={false}
                              defaultValue={null}
                                onPanelChange={(e,r)=>console.log(e, r ,"e, r in calendat")}
                                onSelect={(val,e)=>{
                                    setSelectedQuery({
                                      ...selectedQuery, date:val.unix()
                                    })
                                }}  
                              />
                            </Col>
                        </span>
                        <span className="flex-child-1-new">
                          <div className="">
                            <Col span={8}>
                              <TimePicker onChange={(val,r)=>{
                                  setSelectedQuery({
                                    ...selectedQuery, time:val.unix()
                                  })
                              }} />
                            </Col>
                          </div>
                        </span>
                      </div>
                    </Col>
                  </Row>}
                </Modal>
                <Row gutter={[16,0]} className="query-details" align="middle" justify="center">
                  <Col span={4}>
                    <label className="query-label">Name</label>
                    <div className="query-content">{record.name}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Number</label>
                    <div className="query-content">{record.mobileNumber}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">City</label>
                    <div className="query-content">{record.stateAndCity}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Source</label>
                    <div className="query-content">{record.source}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Age</label>
                    <div className="query-content">{record.age}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Gender</label>
                    <div className="query-content">{record.gender}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Address</label>
                    <div className="query-content">{record.address}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Insurance Partner</label>
                    <div className="query-content">{record.insuranceProvider}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Status</label>
                    <div className="query-content">{record.status}</div>
                  </Col>
                  <Col span={4}>
                    <label className="query-label">Reminder Time</label>
                    <div className="query-content">{record.reminderTime}</div>
                  </Col>
                  <Button onClick={() => {
                    setSelectedQuery(record)
                    setEditQuery(true)
                  }} type="primary">
                    Edit
                  </Button>
                </Row>
              </div>
          }}
          expandedRowKeys={expandedRowKeys}
          // onExpandedRowsChange ={(a,b,c)=> setExpandedNumber(a.mobileNumber)}
          onExpand = {(a,b,c) =>expand(a,b,c)}
          rowKey={record => record._id}
          rowSelection={{
            selectedRowKeys:selectedRowKeys,
            onChange: (e,b)=>{
              setSelectedKeys([...e])
              set_selected_queries([...b.map(item=>{
                return {
                  name:item.name,
                  _id:item._id
                }
              })])
            },
          }}
          />
          </div>
       
        </React.Fragment>)
}
export default AllQueries