import React from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Menu,
  DatePicker,
  Modal,
  Form,
  Select,
  Popover,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  DownOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  for_loop,
  objectEquals,
  isEmpty,
  error_form_check,
  getDateFormat,
} from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";

const { SubMenu } = Menu;
const { Option } = Select;

const PreviousBookings = (props) => {
  const [state, set_state] = React.useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: "",
    searchedColumn: "",
  });
  const [reschedule_flag, set_reschedule_flag] = React.useState(false);
  const [selected_row, set_selected_row] = React.useState([]);
  const [copy, set_copy] = React.useState(false);
  const [selectedRowKeys, setSelectedKeys] = React.useState([]);
  const [payment_link, set_payment_link] = React.useState(false);
  const [expandedRowKeys, set_expandedRowKeys] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [visible, set_visible] = React.useState(false);
  const [descriptionVisiblity, set_description_visibility] = React.useState(
    false
  );
  const [update_payment_data, set_update_payment_data] = React.useState({
    amount: "",
    method: "",
    description: "",
  });
  const [update_payment_error, set_update_payment_error] = React.useState({
    amount: { state: false, text: "" },
    method: { state: false, text: "" },
    description: { state: false, text: "" },
  });
  const [get_bookings, set_get_bookings] = React.useState({
    page: 1,
    limit: 10,
    total: 10,
    number: props.user.data?.mobileNumber,
    name: props.user.data?.name,
    searchParams: {},
    filters: {},
  });
  const [data_reschedule, set_data_reschedule] = React.useState({
    date: "",
    date_string: "",
    slots: [],
    timeSlots: [],
    slot: "",
    price: "",
  });

  const [data_transfer, set_data_transfer] = React.useState({
    date: "",
    date_string: "",
    slots: [],
    timeSlots: [],
    slot: "",
    doctor: "",
    doctors: [],
  });

  const [professionals_list, set_professionals_list] = React.useState([]);
  const [data, set_data] = React.useState([]);
  const [infoData, set_info_data] = React.useState([]);
  const [list, set_list] = React.useState([]);
  const [professional_selected, set_professional_selected] = React.useState([]);
  const [error, set_error] = React.useState({
    amount: { state: false, text: "" },
  });

  const [error_reschedule, set_error_reschedule] = React.useState({
    date_string: { state: false, text: "" },
    slot: { state: false, text: "" },
  });

  const [error_transfer, set_error_transfer] = React.useState({
    date_string: { state: false, text: "" },
    slot: { state: false, text: "" },
    doctor: { state: false, text: "" },
    price: { state: false, text: "" },
  });
  const [payment_link_data, set_payment_link_data] = React.useState({
    amount: "",
  });
  React.useEffect(() => {
    console.log(props.user?.data, "props.user.data");
    props.get_bookings(get_bookings);
  }, []);

  React.useEffect(() => {
    if (!!props.get_bookings_ret) {
      // console.log(props.get_bookings_ret,"get_professional_ret")
      // set_bids(modif_data(props.bids))
      if (props.get_bookings_ret.success) {
        set_data(modif_data([...props.get_bookings_ret.data]));
        set_get_bookings({
          ...get_bookings,
          total: props.get_bookings_ret.total,
        });
        setAllData([...props.get_bookings_ret.data]);
      } else {
        // props.global_error({
        //   success:false,
        //   message:props.get_bookings_ret.message,
        //   heading:'Professionals'
        // })
      }
      props.get_bookings_loading();
    }
    console.log(props.generate_link_ret, "props.generate_link_ret");
    if (!!props.generate_link_ret) {
      if (props.generate_link_ret.success) {
        set_payment_link(props.generate_link_ret.data);
        props.global_error({
          success: true,
          message: props.generate_link_ret.message,
          heading: "Payment Link",
        });
      } else {
        props.global_error({
          success: false,
          message: props.generate_link_ret.message,
          heading: "Payment Link",
        });
      }
      props.generate_link_loading();
    }

    if (!!props.update_bookings_ret) {
      if (props.update_bookings_ret.success) {
        // set_payment_link(props.generate_link_ret.data)
        set_expandedRowKeys([]);
        props.get_bookings({
          page: 1,
          limit: 10,
          total: 10,
          number: props.user.data?.mobileNumber,
          searchParams: {},
          filters: {},
        });
        props.global_error({
          success: true,
          message: props.update_bookings_ret.message,
          heading: "Booking",
        });
        handleCancel();
      } else {
        props.global_error({
          success: false,
          message: props.update_bookings_ret.message,
          heading: "Booking",
        });
      }
      props.update_bookings_loading();
    }

    if (props.get_doctors_ret) {
      if (props.get_doctors_ret.success) {
        set_data_transfer({
          ...data_transfer,
          doctors: [...props.get_doctors_ret.data].map((item) => {
            return {
              ...item,
              name: item.name,
              value: item._id,
            };
          }),
        });
      } else {
        props.global_error({
          success: false,
          message: props.get_doctors_ret.message,
          heading: "No Doctor Available",
        });
      }
      props.get_doctors_loading();
    }
  }, [
    props.get_bookings_ret,
    props.generate_link_ret,
    props.update_bookings_ret,
    props.get_doctors_ret,
  ]);

  function handleButtonClick(e) {
    console.log("click left button", e);
  }

  function handleMenuClick(e) {
    console.log("click", e);
  }

  const handleChange = (pagination, filters, sorter) => {
    // console.log(pagination, "pagination filter sorter")
    let state_filters = { ...get_bookings.filters };
    let updated_filters = {};

    updated_filters.appointmentTime = filters.appointmentTime;
    if (
      !!(
        pagination.current === get_bookings.page &&
        pagination.pageSize === get_bookings.limit
      )
    ) {
      if (objectEquals(state_filters, updated_filters)) {
        console.log("Objects are equal");
      } else {
        // console.log(state_filters, updated_filters,"state_filters, updated_filters")
        console.log("Objects are not equal , call filter operation");
        set_get_bookings({
          ...get_bookings,
          page: 1,
          filters: {
            appointmentTime: filters.appointmentTime
              ? filters.appointmentTime
              : "",
          },
        });
        props.get_bookings({
          ...get_bookings,
          page: 1,
          filters: {
            appointmentTime: filters.appointmentTime
              ? filters.appointmentTime
              : "",
          },
        });
      }
    }
  };

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};

  const getColumnSearchProps = (dataIndex, placeholder, searchProp, type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, searchProp)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, searchProp)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handle_reset(clearFilters, data, dataIndex, searchProp)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
          title={text}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
    confirm();
    set_get_bookings({
      ...get_bookings,
      page: 1,
      searchParams: {
        ...get_bookings.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    props.get_bookings({
      ...get_bookings,
      page: 1,
      searchParams: {
        ...get_bookings.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    set_state({
      ...state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    set_state({ searchText: "" });
  };

  const get_time = (val) => {
    console.log(val, "val in get_time");
    let date = new Date(val);
    console.log(date, "date in get_time====================>");
    // window.alert("asdasdasdasdasdasdasd")
    return date.toLocaleString("en-IN", { hour12: false });
  };

  const modif_data = (arr) => {
    let prof_list = [];
    for_loop(arr, (item) => {
      let obj = {
        bookingPrice: item.bookingPrice,
        bookingStatus: item.bookingStatus,
        // paidAmount: item.paidAmount,
        professionalName: item.professionalName,
        professionalMobileNumber: item.professionalMobileNumber,
        // remainingAmount: item.remainingAmount,
        serviceName: item.serviceName,
        userMobileNumber: item.userMobileNumber,
        userName: item.userName,
        timeSlots: item.professionalTimeSlots,
        appointmentTime: get_time(item.appointmentTime),
        _id: item._id,
        userEmail: item.userEmail,
        userId: item.userId,
        serviceId: item.serviceId,
        specialityId: item.specialityId,
        paidAmount: item.paidAmount,
        remainingAmount: item.remainingAmount,
        onlinePaid: item.onlinePaid,
        offlinePaid: item.offlinePaid,
      };
      prof_list.push({
        ...obj,
      });
    });

    return prof_list;
  };

  const modif_info_data = (arr, record) => {
    let prof_list = [];
    for_loop(arr, (item) => {
      if (item._id == record._id) {
        if (item.offline.cashPaid != 0) {
          let obj = {
            paidAmount: item.offline.cashPaid,
            paymentThrough: item.offline.paymentMode,
            appointmentTime: getDateFormat(item.appointmentTime),
          };
          prof_list.push({
            ...obj,
          });
        }
        if (item.paymentLink.payLinkAmount != 0) {
          let obj = {
            paidAmount: item.paymentLink.payLinkAmount,
            paymentThrough: item.paymentLink.paymentMode,
            appointmentTime: getDateFormat(item.appointmentTime),
          };
          prof_list.push({
            ...obj,
          });
        }
        if (item.paytm.paytmAmount != 0) {
          let obj = {
            paidAmount: item.paytm.paytmAmount,
            paymentThrough: item.paytm.paymentMode,
            appointmentTime: getDateFormat(item.appointmentTime),
          };
          prof_list.push({
            ...obj,
          });
        }
        if (item.razorPay.razorpayAmount != 0) {
          let obj = {
            paidAmount: item.razorPay.razorpayAmount,
            paymentThrough: item.razorPay.paymentMode,
            appointmentTime: getDateFormat(item.appointmentTime),
          };
          prof_list.push({
            ...obj,
          });
        }
      }
    });
    return prof_list;
  };

  const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
    set_get_bookings({ ...get_bookings, limit: b, page: 1 });
    props.get_bookings({ ...get_bookings, limit: b, page: 1 });
  };

  const page_change = (a, b) => {
    // console.log(a,b,get_bookings,"a and b get_bookings")
    // if(get_bookings.page !== a){
    if (true) {
      set_get_bookings({ ...get_bookings, page: a, limit: b });
      props.get_bookings({ ...get_bookings, page: a, limit: b });
    }
  };

  const handle_reset = (a, b, c) => {
    handleReset(a);
    set_state({ filteredInfo: null });
    set_get_bookings({
      ...get_bookings,
      page: 1,
      limit: 10,
      total: 10,
      number: props.user.data?.mobileNumber,
      searchParams: {},
      filters: {},
    });
    props.get_bookings({
      ...get_bookings,
      page: 1,
      limit: 10,
      total: 10,
      number: props.user.data?.mobileNumber,
      searchParams: {},
      filters: {},
    });
  };
  const clear_filters = () => {
    // handleReset()
    set_state({ filteredInfo: null });
    // clearFilters()
    set_state({ searchText: "" });
    set_get_bookings({ ...get_bookings, page: 1, filters: {} });
    props.get_bookings({ ...get_bookings, page: 1, filters: {} });
  };

  const row_click = (r) => {
    console.log(r, "r in row_click");
    set_professional_selected(r);
  };

  const update_status = (val, data) => {
    props.update_bookings({
      type: "update_status",
      booking_id: data._id,
      bookingStatus: val,
    });
  };

  const infoColumns = [
    {
      title: "Amount Paid",
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: "30%",
    },
    {
      title: "Mode",
      dataIndex: "paymentThrough",
      key: "paymentThrough",
      width: "30%",
    },
    {
      title: "Date",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
      width: "30%",
    },
  ];

  const content = <Table columns={infoColumns} dataSource={infoData} />;

  const columns_professional = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ellipsis: true,
      //   ...getColumnSearchProps('name', "Search name", 'name'),
    },
    {
      title: "Phone number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      // ...getColumnSearchProps('email', "Search email", 'email'),
      // ellipsis: true,
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
    {
      title: "Success",
      dataIndex: "success",
      key: "success",
      render: (val) => {
        return <React.Fragment>{val ? "SUCCESS" : "FAILED"}</React.Fragment>;
      },
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
    {
      title: "Text Message",
      dataIndex: "textMessage",
      key: "textMessage",
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      width: "10%",
      // ellipsis: true,
      //   ...getColumnSearchProps('name', "Search name", 'name'),
    },
    {
      title: "Service",
      dataIndex: "serviceName",
      key: "serviceName",
      width: "10%",
      // ...getColumnSearchProps('email', "Search email", 'email'),
      // ellipsis: true,
    },
    // {
    //   title: 'Price',
    //   dataIndex: 'bookingPrice',
    //   key: 'bookingPrice',
    //   width:'10%'
    // //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
    //   // ellipsis: true,
    // },
    {
      title: "Payments",
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: "25%",
      render: (item, record) => {
        return record.bookingStatus === "Confirmed" ? (
          <React.Fragment>
            <ul>
              <li>
                <Popover content={content} placement="bottomLeft">
                  <InfoCircleOutlined
                    onMouseEnter={() => {
                      set_info_data(modif_info_data(allData, record));
                    }}
                  />
                </Popover>
                {` Booking Price: ${record.bookingPrice}`}
              </li>
              <li>{`Paid Amount: ${record.paidAmount}`}</li>
              <li>{`Remaining Amount: ${record.remainingAmount}`}</li>
              <li>{`Online Paid: ${record.onlinePaid}`}</li>
              <li>{`Offline Amount: ${record.offlinePaid}`}</li>
            </ul>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ul>
              <li>{` Booking Price: ${record.bookingPrice}`}</li>
              <li>{`Paid Amount: ${record.paidAmount}`}</li>
              <li>{`Remaining Amount: ${record.remainingAmount}`}</li>
              <li>{`Online Paid: ${record.onlinePaid}`}</li>
              <li>{`Offline Amount: ${record.offlinePaid}`}</li>
            </ul>
          </React.Fragment>
        );
      },
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
    {
      title: "Professional",
      dataIndex: "professionalName",
      key: "professionalName",
      width: "10%",
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
    {
      title: "Time",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
      width: "10%",
      filters: [
        {
          text: "Level 1",
          value: "Today",
        },
        {
          text: "Level 2",
          value: "Yesterday",
        },
        {
          text: "Level 3",
          value: "Last week",
        },
      ],
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: "userMobileNumber",
      key: "userMobileNumber",
      width: "10%",
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
    // {
    //   title: 'Email',
    //   dataIndex: 'userEmail',
    //   key: 'userEmail',
    //   width:'10%'
    // //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
    //   // ellipsis: true,
    // },
    {
      title: "Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      width: "10%",
      filters: [
        {
          text: "Requested",
          value: "Requested",
        },
        {
          text: "Confirmed",
          value: "Confirmed",
        },
        {
          text: "Reserved",
          value: "Reserved",
        },
      ],
      filterMultiple: false,
      onChange: (a, b, c) => console.log(a, b, c, "a,b,c in onFilter"),
      render: (item, record) => {
        return (
          <div className="u-align-center">
            <Select
              value={record.bookingStatus}
              style={{ width: 120 }}
              onChange={(val) => update_status(val, record)}
            >
              <Option
                disabled={record.status === "Requested"}
                value="Requested"
              >
                Requested
              </Option>
              <Option
                disabled={record.status === "Confirmed"}
                value="Confirmed"
              >
                Confirmed
              </Option>
              <Option disabled={record.status === "Reserved"} value="Reserved">
                Reserved
              </Option>
              {/* <Option disabled={record.status==="HOT_LEAD"} value="HOT_LEAD">Hot Lead</Option>
                            <Option disabled={record.status==="COLD_LEAD"} value="COLD_LEAD">Cold Lead</Option>
                            <Option disabled={record.status==="CALLBACK_DUE"} value="CALLBACK_DUE">Callback Due</Option> */}
            </Select>
          </div>
        );
      },
      //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
      // ellipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <React.Fragment>
          <Space size="middle">
            <a
              onClick={() =>
                props.update_bookings({
                  user_id: record.userId,
                  type: "send_credentials",
                })
              }
            >
              Send Credentials
            </a>
          </Space>
          <br />
          <Space size="middle">
            <a
              onClick={() => {
                set_visible(true);
                set_selected_row(record);
              }}
            >
              Update Payment
            </a>
          </Space>
        </React.Fragment>
      ),
    },
  ]

  const onSelectChange = (data) => {
    console.log("selectedRowKeys changed: ", data);
    // this.setState({ selectedRowKeys });
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (e, b) => console.log(e, b),
  };

  const expand = (record, a, b) => {
    console.log(record, a, b, "record,a,b");
    if (a.bookingStatus !== "Cancelled") {
      if (expandedRowKeys[0] !== a._id) {
        console.log(a, "a in expand");
        set_expandedRowKeys([a._id]);
        console.log(a, "a.varianceInfo");
        props.get_doctors({
          specialityId: a.specialityId,
          serviceId: a.serviceId,
        });
        //  set_variance_info(a.varianceInfo)
      } else {
        set_expandedRowKeys([]);
      }
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        timeout = null;

        func(...args);
      };

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);
    };
  };
  const returnedFunction = debounce(function (data) {
    try {
      variance_change(data);
    } catch (e) {
      console.log(e);
    }
    // setState({...state, selectPlace:data})
  }, 500);

  const variance_change = (data) => {
    set_get_bookings({ ...get_bookings, serviceName: data });
    props.get_bookings({ ...get_bookings, serviceName: data });
  };

  function showTotal(total) {
    return `Total ${total} items`;
  }

  const submit_payment_link_amount = () => {
    try {
      if (payment_link_data.amount === 0 || payment_link_data.amount === "") {
        set_error({
          ...error,
          amount: {
            state: true,
            text: "Enter amount for generating payment link.",
          },
        });
        throw new Error("Enter amount");
      }

      props.generate_link({
        bookingId: selected_row._id,
        paymentPrice: payment_link_data.amount,
      });
    } catch (error) {
      console.log(error);
    }
    if (payment_link_data.amount > selected_row.remainingAmount) {
      props.global_error({
        success: false,
        message: "Invalid Amount",
        heading: "Generate Payment Link",
      });
    } else {
    }
  };

  const select_transfer_doctor = (id) => {
    let doctor = data_transfer.doctors.filter((item) => item.value === id)[0];
    console.log(doctor, "doctor in time slot");
    set_data_transfer({
      ...data_transfer,
      timeSlots: [...doctor.timeSlots],
      doctor: id,
      amount: doctor.specialities.services?.price[0],
      max_dicount: doctor.specialities.services.variance,
      slot: "",
      slots: [],
      date_string: "",
      date: "",
      applied: false,
    });
  };

  const validate = (data) => {
    const error = {
      amount: { state: false, text: "" },
      method: { state: false, text: "" },
      description: { state: false, text: "" },
    };
    if (update_payment_data.amount === "") {
      console.log("T====================");
      {
        error.amount.text = "Enter Amount";
        error.amount.state = true;
      }
    }
    if (update_payment_data.method === "") {
      {
        error.method.text = "Select Method";
        error.method.state = true;
      }
    }
    if (
      update_payment_data.method === "OTHER" &&
      update_payment_data.description === ""
    ) {
      {
        error.description.text = "Enter Description";
        error.description.state = true;
      }
    }
    return error;
  };

  const submit = () => {
    const ret_error = validate({ ...update_payment_data });
    set_update_payment_error({ ...ret_error });
    console.log(ret_error, "ret_error in add_member");
    if (!error_form_check(ret_error)) {
      props.update_bookings({
        type: "UPDATE_PAYMENT",
        paymentAmount: update_payment_data.amount,
        paymentMethod: update_payment_data.method,
        paymentDescription: update_payment_data.description,
        paymentId: "",
        paymentUrl: "",
        booking_id: selected_row._id,
      });
    }
  };

  const handleCancel = (e) => {
    console.log(e);
    set_visible(false);
    set_description_visibility(false);
    set_update_payment_data({
      amount: "",
      method: "",
      description: "",
    });
    set_update_payment_error({
      amount: { state: false, text: "" },
      method: { state: false, text: "" },
      description: { state: false, text: "" },
    });
  };

  console.log(props, "props in PreviousBookings");
  if (isEmpty(props.user)) {
    return <Redirect to="/dashboard/funnel?add-user" />;
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={data}
        loading={
          props.get_bookings_loading_flag || props.update_bookings_loading_flag
        }
        onChange={handleChange}
        rowClassName="cursor-pointer"
        expandable={{ columnWidth: "3%"}}
        expandedRowRender={(record) => {
          console.log(record, "record");
          set_selected_row(record);
          // get_doctors(record)
          return (
            <React.Fragment>
              <div key={record._id}>
                <div className="flex-parent-new">
                  <span className="flex-child-1-new">
                    <AntdInput
                      label="Amount"
                      value={payment_link_data.amount}
                      disabled={props.generate_link_loading_flag}
                      loading={props.generate_link_loading_flag}
                      error_text={error.amount.text}
                      toggle_error={() =>
                        set_error({
                          ...error,
                          amount: { text: "", state: false },
                        })
                      }
                      error={error.amount.state}
                      name="amount"
                      validation="positive_integer"
                      onChange={(event) =>
                        set_payment_link_data({
                          ...payment_link_data,
                          amount: event.target.value,
                        })
                      }
                      placeholder="Enter Amount"
                    />
                  </span>
                  <span className="flex-child-1-new">
                    <Button
                      type="primary"
                      onClick={() => submit_payment_link_amount()}
                      disabled={props.generate_link_loading_flag}
                      loading={props.generate_link_loading_flag}
                      style={{ width: "auto", margin: "auto" }}
                    >
                      Generate Link
                    </Button>
                  </span>
                </div>
                {payment_link && (
                  <div className="flex-parent-new">
                    <CopyToClipboard
                      text={payment_link}
                      onCopy={() => {
                        set_copy(true);
                        setTimeout(() => {
                          set_copy(false);
                        }, 2000);
                      }}
                    >
                      <span className="flex-child-1-new">
                        <div
                          title={copy ? "Copied" : "Click to copy code"}
                          className="payment_link_wrapper"
                        >
                          <text className="payment_link_text">
                            {payment_link}
                          </text>
                        </div>
                      </span>
                    </CopyToClipboard>
                    <span className="flex-child-1-new"></span>
                  </div>
                )}

                {reschedule_flag ? (
                  <React.Fragment>
                    {" "}
                    <div className="flex-parent-new">
                      <span className="flex-child-3-new">
                        <Form.Item label={"Appointment date"}>
                          <DatePicker
                            style={{ width: "100%" }}
                            value={data.date}
                            onChange={(val) => {
                              try {
                                let today = new Date();
                                today.setDate(today.getDate() - 1);
                                let date = val;
                                if (date >= today) {
                                  let year = date.year();
                                  let month = date.month();
                                  let day = date.day();
                                  let day_string = moment(val).date();

                                  console.log({ year, month, day }, "year");
                                  let slot_code = day;
                                  let slot_day = false;
                                  let slots = false;
                                  if (day === 0) {
                                    slot_day = "Sunday";
                                  } else if (day === 1) {
                                    slot_day = "Monday";
                                  } else if (day === 2) {
                                    slot_day = "Tuesday";
                                  } else if (day === 3) {
                                    slot_day = "Wednesday";
                                  } else if (day === 4) {
                                    slot_day = "Thursday";
                                  } else if (day === 5) {
                                    slot_day = "Friday";
                                  } else if (day === 6) {
                                    slot_day = "Saturday";
                                  }
                                  let open = true;
                                  console.log(slot_day, "slot_day");
                                  console.log(selected_row, "selected_row");
                                  if (!!selected_row.timeSlots.length) {
                                    slots = [...selected_row.timeSlots]
                                      .filter((item) => {
                                        if (item.day === slot_day) {
                                          open = !item.closed;
                                        }
                                        return (
                                          item.day.toLowerCase() ===
                                          slot_day.toLowerCase()
                                        );
                                      })[0]
                                      .slotArray.reduce(
                                        (accumulator, currentValue) => {
                                          let arr = [...accumulator];
                                          arr.push(currentValue);
                                          return arr;
                                        },
                                        []
                                      )
                                      .map((x) => {
                                        return {
                                          name: x,
                                          value: x,
                                        };
                                      });
                                  }
                                  console.log(slots, "slots in Date Selector");
                                  if (!!slots) {
                                    if (open) {
                                      set_data_reschedule({
                                        ...data_reschedule,
                                        slot: "",
                                        slots: slots,
                                        date: moment(val),
                                        date_string: `${day_string}-${
                                          month + 1
                                        }-${year}`,
                                      });
                                    } else {
                                      props.global_error({
                                        success: false,
                                        message:
                                          "Doctor not available on selected day",
                                        heading: "Appointment date",
                                      });
                                    }
                                  }
                                }
                                throw new Error("Invalid Date");
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          />
                        </Form.Item>
                      </span>
                      <span className="flex-child-3-new">
                        <AntdInput
                          label="Choose slots"
                          value={data_reschedule.slot}
                          options={data_reschedule.slots}
                          loading={props.get_doctors_loading_flag}
                          disabled={props.get_doctors_loading_flag}
                          error_text={error_reschedule.slot.text}
                          toggle_error={() =>
                            set_error_reschedule({
                              ...error_reschedule,
                              slot: { text: "", state: false },
                            })
                          }
                          error={error_reschedule.slot.state}
                          name="slot"
                          type="select"
                          onChange={(event) => {
                            console.log(event);
                            // let time = stringToTime(event)

                            // console.log(stringToTime(event),"stringToTime")
                            set_data_reschedule({
                              ...data_reschedule,
                              slot: event,
                            });
                          }}
                          placeholder="Choose slot"
                        />
                      </span>
                    </div>
                    <div className="flex-parent-new u-margin-top-small">
                      <span className="flex-child-1-new">
                        <Button
                          type="primary"
                          onClick={() => set_reschedule_flag(false)}
                          disabled={props.generate_link_loading_flag}
                          loading={props.generate_link_loading_flag}
                          style={{ width: "auto", margin: "auto" }}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="primary"
                          onClick={() =>
                            props.update_bookings({
                              type: "reschedule",
                              booking_id: selected_row._id,
                              timeSlot: data_reschedule.slot,
                              date: data_reschedule.date_string,
                            })
                          }
                          disabled={props.generate_link_loading_flag}
                          loading={props.generate_link_loading_flag}
                          style={{ width: "auto", marginLeft: "15px" }}
                        >
                          Reschedule
                        </Button>
                      </span>
                    </div>
                    <div className="flex-parent-new u-margin-top-small">
                      <span className="flex-child-3-new">
                        <AntdInput
                          label="Hospital/Doctor/Labs"
                          value={data_transfer.doctor}
                          options={data_transfer.doctors}
                          loading={props.get_doctors_loading_flag}
                          disabled={props.get_doctors_loading_flag}
                          error_text={error_transfer.doctor.text}
                          toggle_error={() =>
                            set_error_transfer({
                              ...error,
                              doctor: { text: "", state: false },
                            })
                          }
                          error={error_transfer.doctor.state}
                          name="doctor"
                          type="select"
                          onChange={(event) => {
                            console.log(event, "event in doctors dropdown");
                            select_transfer_doctor(event);
                            // set_data({...data,"service":event})
                            // props.get_doctors({
                            //     specialityId:data.speciality,
                            //     serviceId:event
                            // })
                          }}
                          placeholder="Choose doctor"
                        />
                      </span>
                      <span className="flex-child-3-new">
                        <Form.Item label={"Appointment date"}>
                          <DatePicker
                            style={{ width: "100%" }}
                            value={data.date}
                            onChange={(val) => {
                              try {
                                let today = new Date();
                                today.setDate(today.getDate() - 1);
                                let date = val;
                                if (date >= today) {
                                  let year = date.year();
                                  let month = date.month();
                                  let day = date.day();
                                  let day_string = moment(val).date();

                                  console.log({ year, month, day }, "year");
                                  let slot_code = day;
                                  let slot_day = false;
                                  let slots = false;
                                  if (day === 0) {
                                    slot_day = "Sunday";
                                  } else if (day === 1) {
                                    slot_day = "Monday";
                                  } else if (day === 2) {
                                    slot_day = "Tuesday";
                                  } else if (day === 3) {
                                    slot_day = "Wednesday";
                                  } else if (day === 4) {
                                    slot_day = "Thursday";
                                  } else if (day === 5) {
                                    slot_day = "Friday";
                                  } else if (day === 6) {
                                    slot_day = "Saturday";
                                  }
                                  let open = true;
                                  console.log(slot_day, "slot_day");
                                  console.log(data_transfer, "data_transfer");
                                  if (!!data_transfer.timeSlots.length) {
                                    slots = [...data_transfer.timeSlots]
                                      .filter((item) => {
                                        if (item.day === slot_day) {
                                          open = !item.closed;
                                        }
                                        return (
                                          item.day.toLowerCase() ===
                                          slot_day.toLowerCase()
                                        );
                                      })[0]
                                      .slotArray.reduce(
                                        (accumulator, currentValue) => {
                                          let arr = [...accumulator];
                                          arr.push(currentValue);
                                          return arr;
                                        },
                                        []
                                      )
                                      .map((x) => {
                                        return {
                                          name: x,
                                          value: x,
                                        };
                                      });
                                  }
                                  console.log(slots, "slots in Date Selector");
                                  if (!!slots) {
                                    if (open) {
                                      set_data_transfer({
                                        ...data_transfer,
                                        slot: "",
                                        slots: slots,
                                        date: moment(val),
                                        date_string: `${day_string}-${
                                          month + 1
                                        }-${year}`,
                                      });
                                    } else {
                                      props.global_error({
                                        success: false,
                                        message:
                                          "Doctor not available on selected day",
                                        heading: "Appointment date",
                                      });
                                    }
                                  }
                                }
                                throw new Error("Invalid Date");
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          />
                        </Form.Item>
                      </span>
                      <span className="flex-child-3-new">
                        <AntdInput
                          label="Choose slots"
                          value={data_transfer.slot}
                          options={data_transfer.slots}
                          loading={props.get_doctors_loading_flag}
                          disabled={props.get_doctors_loading_flag}
                          error_text={error_transfer.slot.text}
                          toggle_error={() =>
                            set_error_transfer({
                              ...error_reschedule,
                              slot: { text: "", state: false },
                            })
                          }
                          error={error_transfer.slot.state}
                          name="slot"
                          type="select"
                          onChange={(event) => {
                            console.log(event);
                            // let time = stringToTime(event)

                            // console.log(stringToTime(event),"stringToTime")
                            set_data_transfer({
                              ...data_transfer,
                              slot: event,
                            });
                          }}
                          placeholder="Choose slot"
                        />
                      </span>
                    </div>
                    <div className="flex-parent-new">
                      <span className="flex-child-3-new">
                        <AntdInput
                          label="Price"
                          value={data_transfer.price}
                          options={data_transfer.price}
                          loading={props.get_doctors_loading_flag}
                          disabled={props.get_doctors_loading_flag}
                          error_text={error_transfer.price.text}
                          toggle_error={() =>
                            set_error_transfer({
                              ...error_transfer,
                              price: { text: "", state: false },
                            })
                          }
                          error={error_transfer.price.state}
                          name="price"
                          validation="positive_integer"
                          onChange={(event) => {
                            console.log(event);
                            // let time = stringToTime(event)

                            // console.log(stringToTime(event),"stringToTime")
                            set_data_transfer({
                              ...data_transfer,
                              price: event.target.value,
                            });
                          }}
                          placeholder="Enter Price"
                        />
                      </span>
                      <span className="flex-child-3-new"></span>
                      <span className="flex-child-3-new"></span>
                    </div>
                    <div className="flex-parent-new u-margin-top-small">
                      <span className="flex-child-1-new">
                        <Button
                          type="primary"
                          onClick={() => set_reschedule_flag(false)}
                          disabled={props.generate_link_loading_flag}
                          loading={props.generate_link_loading_flag}
                          style={{ width: "auto", margin: "auto" }}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="primary"
                          onClick={() =>
                            props.update_bookings({
                              type: "transfer",
                              booking_id: record._id,
                              timeSlot: data_transfer.slot,
                              date: data_transfer.date_string,
                              professionalId: data_transfer.doctor,
                              price: data_transfer.price,
                            })
                          }
                          disabled={props.generate_link_loading_flag}
                          loading={props.generate_link_loading_flag}
                          style={{ width: "auto", marginLeft: "15px" }}
                        >
                          Transfer
                        </Button>
                      </span>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="flex-parent-new u-margin-top-small">
                    <span className="flex-child-1-new">
                      <Button
                        type="primary"
                        onClick={() =>
                          props.update_bookings({
                            type: "cancel",
                            booking_id: record._id,
                          })
                        }
                        disabled={props.generate_link_loading_flag}
                        loading={props.generate_link_loading_flag}
                        style={{ width: "auto", margin: "auto" }}
                      >
                        Cancel Booking
                      </Button>

                      <Button
                        type="primary"
                        onClick={() => set_reschedule_flag(true)}
                        disabled={props.generate_link_loading_flag}
                        loading={props.generate_link_loading_flag}
                        style={{ width: "auto", marginLeft: "15px" }}
                      >
                        Update Booking
                      </Button>
                    </span>
                    <span className="flex-child-1-new"></span>
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        }}
        expandedRowKeys={expandedRowKeys}
        onExpandedRowsChange={(a, b, c) =>
          console.log(a, b, c, "onExpandedRowsChange")
        }
        onExpand={(a, b, c) => expand(a, b, c)}
        rowKey={(record) => record._id}
        pagination={{
          showSizeChanger: true,
          limit: get_bookings.limit,
          onChange: (a, c) => page_change(a, c),
          // onShowSizeChange:(a,b)=>change_limit(a,b),
          defaultCurrent: get_bookings.page,
          total: get_bookings.total,
          showTotal: showTotal,
        }}
      />

      <Modal
        title="Update Payment"
        visible={visible}
        onOk={submit}
        onCancel={handleCancel}
      >
        <div className="flex-parent-new u-margin-top-small">
          <span className="flex-child-1-new">
            <AntdInput
              label="Amount"
              value={update_payment_data.amount}
              error_text={update_payment_error.amount.text}
              loading={props.check_number_loading_flag}
              disabled={props.check_number_loading_flag}
              toggle_error={() =>
                set_update_payment_error({
                  ...update_payment_error,
                  amount: { text: "", state: false },
                })
              }
              error={update_payment_error.amount.state}
              name="amount"
              onChange={(event) =>
                set_update_payment_data({
                  ...update_payment_data,
                  amount: event.target.value,
                })
              }
              placeholder="Enter amount"
              validation="positive_integer"
            />
          </span>

          <span className="flex-child-1-new">
            <AntdInput
              label="Payment Method"
              value={update_payment_data.method}
              error_text={update_payment_error.method.text}
              loading={props.check_number_loading_flag}
              disabled={props.check_number_loading_flag}
              options={[
                {
                  name: "CASH PAYMENT",
                  value: "CASH",
                },
                {
                  name: "PAYMENT LINK",
                  value: "PAYMENT_LINK",
                },
                {
                  name: "BANK TRANSFER",
                  value: "BANK_TRANSFER",
                },
                {
                  name: "OTHER  METHOD",
                  value: "OTHER",
                },
              ]}
              toggle_error={() =>
                set_update_payment_error({
                  ...update_payment_error,
                  method: { text: "", state: false },
                })
              }
              error={update_payment_error.method.state}
              name="method"
              onChange={(event) => {
                set_update_payment_data({
                  ...update_payment_data,
                  method: event,
                });
                if (event === "OTHER") set_description_visibility(true);
                else set_description_visibility(false);
              }}
              placeholder="Select Method"
              type="select"
            />
          </span>
        </div>
        <div>
          {descriptionVisiblity && (
            <AntdInput
              label="Description"
              value={update_payment_data.description}
              error_text={update_payment_error.description.text}
              loading={props.check_number_loading_flag}
              disabled={props.check_number_loading_flag}
              toggle_error={() =>
                set_update_payment_error({
                  ...update_payment_error,
                  description: { text: "", state: false },
                })
              }
              error={update_payment_error.description.state}
              name="description"
              onChange={(event) => {
                if (update_payment_data.method === "OTHER")
                  set_update_payment_data({
                    ...update_payment_data,
                    description: event.target.value,
                  });
              }}
              placeholder="Enter description"
              type="textarea"
            />
          )}
        </div>
        <div className="text-center">
          {/* <Button
                  type="primary"
                 onClick={() => update_payment()}
                 disabled = {props.update_bookings_loading_flag}
                 loading={props.update_bookings_loading_flag}
                 style={{ width: 'auto', margin:'auto' }}
      >
       Submit
    </Button> */}
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default PreviousBookings;
