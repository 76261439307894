import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {  DOWNLOAD_TPA_USER_DETAILS, GET_TPA_USER_DETAILS, GET_USER } from '../Constants'
import { get_user_ret, get_user_loading, set_user_data, get_tpa_user_details_ret, download_tpa_user_details_ret } from '../actions/user'
import { global_loading_clr } from '../actions/global'
import api from '../utils/api_routes'
import { get_url_params } from "../utils/common_utilities"
import store from '../store'

console.log(store,"store in saga file")

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* get_user_saga() {
    console.log("Inside get_user_saga")

   try {
      const  data = yield store.getState().user_store.get_user
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
       const api_data = yield call(api.user.get_user, data, headers)
      console.log(api_data,"api_data in get_usersaga")
      if(!!api_data){
         if(api_data.success){
            yield put(global_loading_clr())
            yield put(set_user_data({
               ...api_data.data?.user
               }))
        }else{
            yield put(get_user_ret({
                success:false,
                message:api_data.data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e.response,"e in get_user saga")
    try{
        yield put(get_user_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_user_loading({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
}


function* get_tpa_user_details_saga() {
   console.log("Inside get_real_insight_saga")

   try {
       const  data  = yield store.getState().user_store.get_tpa_user_details
       const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
       const api_data = yield call(api.user.getTpaUserDetails, data, headers)
       if(!!api_data){
         if (api_data.status === 200) {
             yield put(get_tpa_user_details_ret({
                 success:true,
                 message:'Data fetched successfully',
                 data:api_data.data
               }))
           }else{
             yield put(get_tpa_user_details_ret({
                 success:false,
                 message:'Something went wrong try again later..',
                 data:[]
               }))
           }
       }
   } catch (e) {
     console.log(e,"e in get_act insigt saga")
     try{
         yield put(get_tpa_user_details_ret({
             success:false,
             message:'Something went wrong try again later..',
             data:[]
           }))
       }catch(x){
         yield put(get_tpa_user_details_ret({
             success:false,
             message:'Something went wrong try again later..',
             data:[]
           }))
         }
   }
}

function* download_tpa_user_details_saga() {
   console.log("Inside get_real_insight_saga")

   try {
       const  data  = yield store.getState().user_store.download_tpa_user_details
       const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
       const api_data = yield call(api.user.downloadTpaUserDetails, data, headers)
       if(!!api_data){
         if (api_data.status === 200) {
             yield put(download_tpa_user_details_ret({
                 success:true,
                 message:'Data fetched successfully',
                 data:api_data.data
               }))
           }else{
             yield put(download_tpa_user_details_ret({
                 success:false,
                 message:'Something went wrong try again later..',
                 data:[]
               }))
           }
       }
   } catch (e) {
     console.log(e,"e in get_act insigt saga")
     try{
         yield put(download_tpa_user_details_ret({
             success:false,
             message:'Something went wrong try again later..',
             data:[]
           }))
       }catch(x){
         yield put(download_tpa_user_details_ret({
             success:false,
             message:'Something went wrong try again later..',
             data:[]
           }))
         }
   }
}


export const user_saga = [
   takeLatest(GET_USER, get_user_saga),
   takeLatest(GET_TPA_USER_DETAILS, get_tpa_user_details_saga),
   takeLatest(DOWNLOAD_TPA_USER_DETAILS, download_tpa_user_details_saga)
]

export default user_saga