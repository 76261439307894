import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services } from "../actions/global"
import { get_real_time_insights_loading, get_real_time_insights, get_actionable_insights,
   get_actionable_insights_loading, get_suggested_insights, get_suggested_insights_loading } from "../actions/insights"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import Realtime from "../Views/Insights/Realtime"
import ActionableInsight from "../Views/Insights/ActionableInsight"
import SuggestedInsights from "../Views/Insights/SuggestedInsights"
import { get_url_params } from "../utils/common_utilities"

class InsightsAnalysis extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      console.log(this.props,"this.props  in Professional_container")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?realtime") ? <React.Fragment>
                    <Realtime 

                       get_real_time_insights= {this.props.get_real_time_insights}
                       get_real_time_insights_ret = {this.props.insights_store.get_real_time_insights_ret}
                       get_real_time_insights_loading_flag = {this.props.insights_store.get_real_time_insights_loading}
                       get_real_time_insights_loading = {this.props.get_real_time_insights_loading}

                       get_actionable_insights_loading= {this.props.get_actionable_insights_loading}
                       get_actionable_insights_ret = {this.props.insights_store.get_actionable_insights_ret}
                       get_actionable_insights_loading_flag = {this.props.insights_store.get_actionable_insights_loading}
                       get_actionable_insights = {this.props.get_actionable_insights}
            

                       global_error = {this.props.global_error}
                       global_store={this.props.global_store}
                       get_services = {this.props.get_services}
                     />
                  </React.Fragment>:(this.props.location.search==="?actionable")   ?   <React.Fragment>
                     <ActionableInsight 

                        get_actionable_insights_loading= {this.props.get_actionable_insights_loading}
                        get_actionable_insights_ret = {this.props.insights_store.get_actionable_insights_ret}
                        get_actionable_insights_loading_flag = {this.props.insights_store.get_actionable_insights_loading}
                        get_actionable_insights = {this.props.get_actionable_insights}


                        global_error = {this.props.global_error}
                        global_store={this.props.global_store}
                        get_services = {this.props.get_services}
                    />
                  </React.Fragment>:(this.props.location.search==="?suggested")?<React.Fragment>
                  <SuggestedInsights 

                        get_suggested_insights_loading= {this.props.get_suggested_insights_loading}
                        get_suggested_insights_ret = {this.props.insights_store.get_suggested_insights_ret}
                        get_suggested_insights_loading_flag = {this.props.insights_store.get_suggested_insights_loading}
                        get_suggested_insights = {this.props.get_suggested_insights}


                        global_error = {this.props.global_error}
                        global_store={this.props.global_store}
                        get_services = {this.props.get_services}
/>
                  </React.Fragment>:<React.Fragment></React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  insights_store:state.insights_store,
  global_error_ret:state.global_store.global_error_ret,
  global_store:state.global_store
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_real_time_insights,
  get_real_time_insights_loading,
  get_services,
  get_actionable_insights,
  get_actionable_insights_loading,
  get_suggested_insights,
  get_suggested_insights_loading
 })(InsightsAnalysis)
