import { 
    GET_INSURANCE_BOOKINGS, 
    GET_INSURANCE_BOOKINGS_LOADING, 
    GET_INSURANCE_BOOKINGS_RET,
  
    UPDATE_BOOKING_SESSION,
    UPDATE_BOOKING_SESSION_RET,
    UPDATE_BOOKING_SESSION_LOADING,
    
    ADD_BOOKING_SESSION,
    ADD_BOOKING_SESSION_RET,
    ADD_BOOKING_SESSION_LOADING,

    UPDATE_BOOKING,
    UPDATE_BOOKING_RET,
    UPDATE_BOOKING_LOADING,

    UPLOAD_PATIENT_REPORT,
    UPLOAD_PATIENT_REPORT_RET,
    UPLOAD_PATIENT_REPORT_LOADING,
    
    UPLOAD_PATIENT_INSURANCE,
    UPLOAD_PATIENT_INSURANCE_RET,
    UPLOAD_PATIENT_INSURANCE_LOADING

} from "../Constants"

const initialState = {
    get_insurance_bookings:false,
    get_insurance_bookings_ret:false,
    get_insurance_bookings_loading:false,

    update_booking_session: false,
    update_booking_session_ret: false,
    update_booking_session_loading: false,
  
    add_booking_session: false,
    add_booking_session_ret: false,
    add_booking_session_loading: false,
  
    update_booking: false,
    update_booking_ret: false,
    update_booking_loading: false,

    upload_patient_report:false,
    upload_patient_report_ret:false,
    upload_patient_report_loading:false,

    upload_patient_insurance:false,
    upload_patient_insurance_ret:false,
    upload_patient_insurance_loading:false,
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_INSURANCE_BOOKINGS:
             return {
               ...state,
               get_insurance_bookings:action.data,
               get_insurance_bookings_loading:true
             }

        case GET_INSURANCE_BOOKINGS_RET:
        
            return {
                ...state,
                get_insurance_bookings_ret:action.data,
                get_insurance_bookings_loading:false
            }

        case GET_INSURANCE_BOOKINGS_LOADING:
        return {
            ...state,
            get_insurance_bookings_ret:false,
            get_insurance_bookings_loading:false
        }
        
        case UPDATE_BOOKING_SESSION:
            return {
                ...state,
                update_booking_session:action.data,
                update_booking_session_loading:true
            }
        case UPDATE_BOOKING_SESSION_RET:
            return {
                ...state,
                update_booking_session_ret:action.data,
            //    get_act_insight_loading:false
            }
        case UPDATE_BOOKING_SESSION_LOADING:
            return {
                ...state,
                update_booking_session_ret:false,
                update_booking_session_loading:false
            }
            
        case ADD_BOOKING_SESSION:
            return {
                ...state,
                add_booking_session:action.data,
                add_booking_session_loading:true
            }
        case ADD_BOOKING_SESSION_RET:
            return {
                ...state,
                add_booking_session_ret:action.data,
            //    get_act_insight_loading:false
            }
        case ADD_BOOKING_SESSION_LOADING:
            return {
                ...state,
                add_booking_session_ret:false,
                add_booking_session_loading:false
            }
            
        case UPDATE_BOOKING:
            return {
                ...state,
                update_booking:action.data,
                update_booking_loading:true
            }
        case UPDATE_BOOKING_RET:
            return {
                ...state,
                update_booking_ret:action.data,
            //    get_act_insight_loading:false
            }
        case UPDATE_BOOKING_LOADING:
            return {
                ...state,
                update_booking_ret:false,
                update_booking_loading:false
            }

        case UPLOAD_PATIENT_REPORT:
            return {
                ...state,
                upload_patient_report:action.data,
                upload_patient_report_loading:true
            }
        case UPLOAD_PATIENT_REPORT_RET:
            return {
                ...state,
                upload_patient_report_ret:action.data,
            //    get_act_insight_loading:false
            }
        case UPLOAD_PATIENT_REPORT_LOADING:
            return {
                ...state,
                upload_patient_report_ret:false,
                upload_patient_report_loading:false
            }

        case UPLOAD_PATIENT_INSURANCE:
            return {
                ...state,
                upload_patient_insurance:action.data,
                upload_patient_insurance_loading:true
            }
        case UPLOAD_PATIENT_INSURANCE_RET:
            return {
                ...state,
                upload_patient_insurance_ret:action.data,
            //    get_act_insight_loading:false
            }
        case UPLOAD_PATIENT_INSURANCE_LOADING:
            return {
                ...state,
                upload_patient_insurance_ret:false,
                upload_patient_insurance_loading:false
            }   

        default:
            return {...state}
    
    }
}