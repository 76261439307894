import { GET_USERS, GET_USERS_RET, GET_USERS_LOADING, GET_TPA_USER_DETAILS, GET_TPA_USER_DETAILS_RET, GET_TPA_USER_DETAILS_LOADING, DOWNLOAD_TPA_USER_DETAILS, DOWNLOAD_TPA_USER_DETAILS_RET, DOWNLOAD_TPA_USER_DETAILS_LOADING } from "../Constants"

export const get_users = (data) => {
    return {
        type:GET_USERS,
        data
    }
}

export const get_users_ret = (data) => {
    return {
        type:GET_USERS_RET,
        data
    }
}

export const get_users_loading = (data) => {
    return {
        type:GET_USERS_LOADING,
        data
    }
}