import { act } from '@testing-library/react';
import { actionChannel } from 'redux-saga/effects';
import { CHECK_FOLLOW_UP, CHECK_FOLLOW_UP_RET, CHECK_FOLLOW_UP_LOADING, 
         UPDATE_FOLLOW_UP_LOADING, UPDATE_FOLLOW_UP_RET, UPDATE_FOLLOW_UP, 
         GET_FOLLOW_UPS_LOADING, GET_FOLLOW_UPS_RET, GET_FOLLOW_UPS } from '../Constants';


export const get_follow_ups = (data) => {
    console.log(data,"data in get_follow_ups_section")
    return {
        type: GET_FOLLOW_UPS,
        data
    }
}

export const get_follow_ups_ret = (data) => {
    return {
        type: GET_FOLLOW_UPS_RET,
        data
    }
}


export const get_follow_ups_loading = (data) => {
    return {
        type: GET_FOLLOW_UPS_LOADING,
        data
    }
}


export const update_follow_up = (data) => {
    return {
        type: UPDATE_FOLLOW_UP,
        data
    }
}

export const update_follow_up_ret = (data) => {
    return {
        type: UPDATE_FOLLOW_UP_RET,
        data
    }
}

export const update_follow_up_loading = (data) => {
    return {
        type: UPDATE_FOLLOW_UP_LOADING,
        data
    }
}

export const check_follow_up = (data) => {
    return {
        type: CHECK_FOLLOW_UP,
        data
    }
}

export const check_follow_up_ret = (data) => {
    return {
        type: CHECK_FOLLOW_UP_RET,
        data
    }
}

export const check_follow_up_loading = (data) => {
    return {
        type: CHECK_FOLLOW_UP_LOADING,
        data
    }
}
