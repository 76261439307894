import React from "react"
import {  Card, Form, Button, Row, Col, Tag, Typography, Alert, Select } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { error_form_check } from '../../utils/common_utilities'
const { Title } = Typography;
const { Option }  =  Select

const SendSms = (props) => {
    const [form] = Form.useForm();
    
    const [data, set_data] = React.useState({
           message:''
    })
    const [error, set_error] = React.useState({
          message:{ state:false, text:"" }
    })
    const [template, setTemplate] = React.useState(null)
    const [templates, setTemplates] = React.useState(null)

    React.useEffect(()=>{
      props.get_message_templates && props.get_message_templates()
    },[])
  
    React.useEffect(()=>{
      if(props.get_message_templates)
        if(!!props.get_message_templates_ret){
                if(props.get_message_templates_ret.success){
                    setTemplates(props.get_message_templates_ret.data)
            } else {
                props.global_error({
                    success:false,
                    message:props.get_message_templates_ret.message,
                    heading:'Templates'
                })
            }
        }
    },[props.get_message_templates_ret])
    
 const send_message = () => {
    const ret_error = validate_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        console.log("Inside Eror_form_check")
        props.send_sms({
                type:props.type,
                queryId:props.queryId,
                sentMessage:data.message
        })
    }
 }

  const send_template_message = () => {
    const ret_error = validate_template(template);
    set_error({...error, ...ret_error});
    if(!error_form_check(ret_error)){
        props.send_template_sms({
          queryId: props.queryId,
          messagecontent: data.message,
          templateId: template.templateId,
          templateType: template.templateType,
          templateName: template.templateName
        })
    }
  }

  const validate_template = (data) => {
    const  error = {
      message:{ state:false, text:"" }
    }
    if(data === null){
      error.message.text = "Please select a template"
      error.message.state = true
    }
    return error
  }

 const validate_message  = (data) =>{
  const  error = {
    message:{ state:false, text:"" }
    }
console.log(data,"data sdsdsdsd")
    if(data.message===''){
      {
        error.message.text = "Please enter your message"
        error.message.state = true
      }
    }
    if(data.business_leads===''){
      {
        error.business_leads.text = "Please enter value"
        error.business_leads.state = true
      }
    }
    return error
  }


console.log(props.send_sms_loading_flag,"props.send_sms_loading_flag")

   return <React.Fragment>
        <Row>
            <Col span={12}>
              <Form
                form={form}
                layout="vertical"> 
                <div className="flex-parent-new">
                  <span className="flex-child-1-new">
                      <Title className={templates && "u-margin-top-small"} level={5}>Send Message</Title>
                      {templates && <div className="u-margin-top-small u-margin-bottom-small">
                          <Select 
                            placeholder="Please Select a Template"
                            style={{ width: 300 }} onChange={(val) => {
                            const selTemplate = templates.find(template => template.templateId === val)
                            setTemplate(selTemplate)
                            set_data({
                              message: selTemplate.templateContent
                            })
                          }}>
                            {templates.map((template, index) => 
                              <Option key={index} value={template.templateId}>{template.templateName.replaceAll('_', ' ')}</Option>
                            )}
                          </Select>
                        </div>
                      }
                      <AntdInput
                              value={data.message} 
                              error_text={error.message.text} 
                              loading = {props.send_sms_loading_flag || props.send_template_sms_loading_flag}
                              disabled = {props.send_sms_loading_flag || props.send_template_sms_loading_flag} 
                              toggle_error = {()=>set_error({...error, message:{text:'',state:false}})} 
                              error={error.message.state} 
                              name="message" 
                              onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
                              placeholder="Enter message"
                              type="textarea"
                              rows= {5}
                      />
                    <Button loading={props.send_sms_loading_flag || props.send_template_sms_loading_flag} onClick={()=> templates ? send_template_message() : send_message()} type={"primary"}>Send</Button>
                  </span>
                </div>
              </Form>
            </Col>
            <Col span={12}>
              <div className="flex-parent-new">
                  <span className="flex-child-1-new">
                  <Title className={templates && "u-margin-top-small"} level={5}>Previous Messages</Title>
                  {props.sms && props.sms.length===0?<div className="u-margin-top-small">
                          <Alert
                              message={"No Previous Messages"}
                              type="warning"
                              showIcon
                            />  
                      </div> :''}
                  {props.sms && props.sms.map(item=>{
                      return  <div className="u-margin-top-small">
                          <Alert
                              message={item.messageBody}
                              type="success"
                              showIcon
                            />  
                      </div> 
                  })}
                </span>
              </div>
            </Col>
        </Row>
   </React.Fragment>
}

export default SendSms