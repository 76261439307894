import { 
    GET_USER, GET_USER_RET, GET_USER_LOADING, SET_USER_DATA,
    GET_TPA_USER_DETAILS, GET_TPA_USER_DETAILS_RET, GET_TPA_USER_DETAILS_LOADING, 
    DOWNLOAD_TPA_USER_DETAILS, DOWNLOAD_TPA_USER_DETAILS_RET, DOWNLOAD_TPA_USER_DETAILS_LOADING 
} from "../Constants"

export const get_user = (data) => {
    return {
        type:GET_USER,
        data
    }
}
export const get_user_ret = (data) => {
    return {
        type:GET_USER_RET,
        data
    }
}
export const get_user_loading = (data) => {
    return {
        type:GET_USER_LOADING,
        data
    }
}
export const set_user_data = (data) => {
    return {
        type:SET_USER_DATA,
        data
    }
}

export const get_tpa_user_details = (data) => {
    return {
        type: GET_TPA_USER_DETAILS,
        data
    }
}
export const get_tpa_user_details_ret = (data) => {
    return {
        type: GET_TPA_USER_DETAILS_RET,
        data
    }
}
export const get_tpa_user_details_loading = (data) => {
    return {
        type: GET_TPA_USER_DETAILS_LOADING,
        data
    }
}


export const download_tpa_user_details = (data) => {
    return {
        type: DOWNLOAD_TPA_USER_DETAILS,
        data
    }
}
export const download_tpa_user_details_ret = (data) => {
    return {
        type: DOWNLOAD_TPA_USER_DETAILS_RET,
        data
    }
}
export const download_tpa_user_details_loading = (data) => {
    return {
        type: DOWNLOAD_TPA_USER_DETAILS_LOADING,
        data
    }
}