import React from "react";
import { Button, Card, Menu, Form, Alert } from "antd";
import {
  for_loop,
  getDateFormat,
  objectEquals,
} from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
//import Servicelist from "./Servicelist"
import SearchLocation from "../../Components/SearchLocation";
import validator from "validator";
import User from "./User";

const { SubMenu } = Menu;

const AddUser = (props) => {
  const [state, set_state] = React.useState({
    check_status: "PENDING",
    register: "PENDING",
  });
  const [data, set_data] = React.useState({
    number: "",
  });
  const [error, set_error] = React.useState({
    number: { state: false, text: "" },
  });
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (props.check_number_ret) {
      if (props.check_number_ret.success) {
        set_state({
          ...state,
          check_status: "AVAILABLE",
        });
      } else {
        set_state({
          ...state,
          check_status: "NOT AVAILABLE",
        });
      }
      props.check_number_loading();
    }
  }, [props.check_number_ret]);

  const button_click = () => {
    if (state.check_status === "AVAILABLE") {
      set_state({
        register: "DONE",
      });
    } else {
      set_state({
        register: "INITIATED",
      });
    }
  };
  const onChange = (e) => {
    const { value } = e.target;

    const reg = /^-?\d*(\.\d*)?$/;
    if (value.length < 11) {
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        set_data({
          ...data,
          number: value,
        });
        set_state({
          ...state,
          check_status: "PENDING",
        });
        if (value.length > 9) {
          let flag = validator.isMobilePhone(value, "en-IN");
          if (!flag) {
            set_error({
              ...error,
              number: { state: true, text: "Enter a valid mobile number" },
            });
            set_state({
              ...state,
              check_status: "PENDING",
            });
          } else {
            props.check_number({ mobile_number: value });
          }
        }
      }
    } else {
      if (value.length > 10) {
        if (!validator.isMobilePhone(data.number, "en-IN")) {
          set_error({
            ...error,
            number: { state: true, text: "Enter a valid mobile number" },
          });
          set_state({
            ...state,
            check_status: "PENDING",
          });
        }
      }
    }
  };

  if (state.previous_bookings) {
    return <Redirect to="/dashboard/bookings?previous-bookings" />;
  }
  if (state.preAuth) {
    return <Redirect to="/dashboard/bookings?preAuth" />;
  }

  if (state.register === "DONE") {
    return <Redirect to="/dashboard/bookings?add-booking" />;
  }

  if (state.register === "INITIATED") {
    return (
      <User
        register_user_loading={props.register_user_loading}
        register_user_loading_flag={props.register_user_loading_flag}
        register_user_ret={props.register_user_ret}
        register_user={props.register_user}
        set_user={props.set_user}
        global_error={props.global_error}
        global_store={props.global_store}
      />
    );
  }
  return (
    <React.Fragment>
      <Card title="Check User">
        <div className="check-user-wrapper">
          <div className="enter_user_number_wrapper">
            <div className="u-margin-top-small">
              <Alert
                message={
                  state.check_status === "PENDING"
                    ? "Enter user number to check user in our system."
                    : state.check_status === "AVAILABLE"
                    ? "User exist in our system. Press Continue to make a booking"
                    : "It seems user is not registered in our system. Press Register to continue"
                }
                type={
                  state.check_status === "PENDING"
                    ? "info"
                    : state.check_status === "AVAILABLE"
                    ? "success"
                    : "warning"
                }
                showIcon
              />
            </div>
            <div className="u-margin-top-small">
              <Form form={form} layout="vertical">
                <div className="flex-parent-new">
                  <span className="flex-child-1-new">
                    <AntdInput
                      label="User Mobile Number"
                      value={data.number}
                      error_text={error.number.text}
                      loading={props.check_number_loading_flag}
                      disabled={props.check_number_loading_flag}
                      toggle_error={() =>
                        set_error({
                          ...error,
                          number: { text: "", state: false },
                        })
                      }
                      error={error.number.state}
                      name="number"
                      onChange={(event) => onChange(event)}
                      placeholder="Enter mobile number"
                    />
                  </span>
                </div>
                {state.check_status !== "PENDING" && (
                  <div className="u-margin-top-small text-center">
                    <Button
                      type="primary"
                      onClick={() => button_click()}
                      style={{ width: "auto", margin: "auto" }}
                    >
                      {state.check_status === "AVAILABLE"
                        ? "BOOK SOLUTIONS"
                        : "REGISTER"}
                    </Button>

                    {state.check_status === "AVAILABLE" && (
                      <Button
                        type="primary"
                        onClick={() =>
                          set_state({ ...state, previous_bookings: true })
                        }
                        style={{ width: "auto", marginLeft: "10px" }}
                      >
                        PREVIOUS BOOKINGS
                      </Button>
                    )}
                      <Button
                        type="primary"
                        disabled={true}
                        onClick={() =>
                          set_state({ ...state, preAuth: true })
                        }
                        style={{ width: "auto", marginLeft: "10px" }}
                      >
                        PREAUTH
                      </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default AddUser;
