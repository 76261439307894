import React from "react"
import {  Card, Form, Button, Row, Col, Tag, Typography } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { error_form_check } from '../../utils/common_utilities'
const {Title} = Typography


const BulkSend = (props) => {
    const [form] = Form.useForm();
    const [solutions, set_solutions] = React.useState([])
    const [data, set_data] = React.useState({
        message:'',
        notification_text:'',
        notification_title:''
 })
 const [error, set_error] = React.useState({
       message:{ state:false, text:"" },
       notification_text:{state: false, text:''},
       notification_title:{state:false, text:''}
})
React.useEffect(()=>{
    if(!!props.bulk_send_ret){
          if(props.bulk_send_ret.success){
            props.global_error({
              success:true,
              message:props.bulk_send_ret.message,
              heading:'SMS'
            })
          }else{
            props.global_error({
              success:false,
              message:props.bulk_send_ret.message,
              heading:'SMS'
            })
          }
          props.bulk_send_loading()
    }
   },[props.bulk_send_ret])

 

const send_notification = () => {
    const ret_error = validate_notification({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        
        props.bulk_send({
            message:false,
            notification:true,
            textMessage:'',
            pushMessage:data.notification_text,
            notificationTitle:data.notification_title

        })
    }
}

const validate_notification  = (data) =>{
    const  error = {
      message:{ state:false, text:"" },
      notification_text:{state: false, text:''},
      notification_title:{state:false, text:''}
      }
      if(data.notification_text===''){
        {
          error.notification_text.text = "Please enter your message"
          error.notification_text.state = true
        }
      }

      if(data.notification_title===''){
        {
          error.notification_title.text = "Please enter title"
          error.notification_title.state = true
        }
      }
      return error
    }

   const send_message = () => {
    const ret_error = validate_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
       
        props.bulk_send({
            message:true,
            notification:false,
            textMessage:data.message,
            pushMessage:'',
            notificationTitle:''
        })
    }
 }

 const validate_message  = (data) =>{
  const  error = {
    message:{ state:false, text:"" },
      notification_text:{state: false, text:''},
      notification_title:{state:false, text:''}
    }
    if(data.message===''){
      {
        error.message.text = "Please enter your message"
        error.message.state = true
      }
    }
    return error
  }
    return <React.Fragment>
    <Row>

    <Col span ={24}>
    <Form
      form={form}
      layout="vertical"
    >
    <div className="flex-parent-new">
  <span className="flex-child-1-new">
  <Title level={5}>Send Message</Title>
  <AntdInput label="Message"  
            value={data.message} 
            error_text={error.message.text} 
            loading = {props.bulk_send_loading_flag}
            disabled = {props.bulk_send_loading_flag} 
            toggle_error = {()=>set_error({...error, message:{text:'',state:false}})} 
            error={error.message.state} 
            name="message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            /> 
  <Button loading={props.bulk_send_loading_flag} onClick={()=>send_message()} type={"primary"}>Send</Button>
   </span>

 
   </div>
   </Form>



   <Form
      form={form}
      layout="vertical"
    >
    <div className="flex-parent-new u-margin-top-small">
  <span className="flex-child-1-new">
  <Title level={5}>Send Notification</Title>

  <AntdInput label="Title"  
            value={data.notification_title} 
            error_text={error.notification_title.text} 
            loading = {props.bulk_send_loading_flag}
            disabled = {props.bulk_send_loading_flag} 
            toggle_error = {()=>set_error({...error, notification_title:{text:'',state:false}})} 
            error={error.notification_title.state} 
            name="notification_title" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter Title"
            type="text"
           
            /> 


  <AntdInput label="Message"  
            value={data.notification_text} 
            error_text={error.notification_text.text} 
            loading = {props.bulk_send_loading_flag}
            disabled = {props.bulk_send_loading_flag} 
            toggle_error = {()=>set_error({...error, notification_text:{text:'',state:false}})} 
            error={error.notification_text.state} 
            name="notification_text" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            /> 

  <Button loading={props.bulk_send_loading_flag} onClick={()=>send_notification()} type={"primary"}>Send</Button>
   </span>

 
   </div>
   </Form>
    </Col>
    </Row>
    </React.Fragment>
}
export default BulkSend