import { 
    GET_INSURANCE_BOOKINGS, 
    GET_INSURANCE_BOOKINGS_RET, 
    GET_INSURANCE_BOOKINGS_LOADING, 

    UPDATE_BOOKING_SESSION, 
    UPDATE_BOOKING_SESSION_RET, 
    UPDATE_BOOKING_SESSION_LOADING,
     
    ADD_BOOKING_SESSION,
    ADD_BOOKING_SESSION_RET,
    ADD_BOOKING_SESSION_LOADING,
    
    UPDATE_BOOKING,
    UPDATE_BOOKING_RET,
    UPDATE_BOOKING_LOADING,

    UPLOAD_PATIENT_REPORT,
    UPLOAD_PATIENT_REPORT_RET,
    UPLOAD_PATIENT_REPORT_LOADING,
    
    UPLOAD_PATIENT_INSURANCE,
    UPLOAD_PATIENT_INSURANCE_RET,
    UPLOAD_PATIENT_INSURANCE_LOADING
} from "../Constants"

export const get_insurance_bookings = (data) => {
    return {
        type: GET_INSURANCE_BOOKINGS,
        data
    }
}
export const get_insurance_bookings_ret = (data) => {
    return {
        type: GET_INSURANCE_BOOKINGS_RET,
        data
    }
}
export const get_insurance_bookings_loading = (data) => {
    return {
        type: GET_INSURANCE_BOOKINGS_LOADING,
        data
    }
}
 
export const update_booking_session = (data) => {
    return {
        type: UPDATE_BOOKING_SESSION,
        data
    }
}
export const update_booking_session_ret = (data) => {
    return {
        type: UPDATE_BOOKING_SESSION_RET,
        data
    }
}
export const update_booking_session_loading = (data) => {
    return {
        type: UPDATE_BOOKING_SESSION_LOADING,
        data
    }
}

export const add_booking_session = (data) => {
    return {
        type: ADD_BOOKING_SESSION,
        data
    }
}
export const add_booking_session_ret = (data) => {
    return {
        type: ADD_BOOKING_SESSION_RET,
        data
    }
}
export const add_booking_session_loading = (data) => {
    return {
        type: ADD_BOOKING_SESSION_LOADING,
        data
    }
}

export const update_booking = (data) => {
    return {
        type: UPDATE_BOOKING,
        data
    }
}
export const update_booking_ret = (data) => {
    return {
        type: UPDATE_BOOKING_RET,
        data
    }
}
export const update_booking_loading = (data) => {
    return {
        type: UPDATE_BOOKING_LOADING,
        data
    }
}

export const upload_patient_report = (data) => {
    return  {
      type: UPLOAD_PATIENT_REPORT,
      data
    }
}
export const upload_patient_report_ret = (data) => {
   return  {
     type: UPLOAD_PATIENT_REPORT_RET,
     data
   }
}
export const upload_patient_report_loading = (data) => {
   return  {
     type: UPLOAD_PATIENT_REPORT_LOADING,
     data
   }
}

export const upload_patient_insurance = (data) => {
    return  {
      type: UPLOAD_PATIENT_INSURANCE,
      data
    }
}
export const upload_patient_insurance_ret = (data) => {
   return  {
     type: UPLOAD_PATIENT_INSURANCE_RET,
     data
   }
}
export const upload_patient_insurance_loading = (data) => {
   return  {
     type: UPLOAD_PATIENT_INSURANCE_LOADING,
     data
   }
}
 