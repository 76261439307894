import React, { useState } from "react"
import { Descriptions, Alert, Button, Form, Row, Col, Modal } from 'antd';
import { EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { error_form_check, get_url_params } from "../../utils/common_utilities"
import validator from "validator"
import AntdInput from "../../Components/AntdInput"
import SearchLocation from "../../Components/SearchLocation"
import { useHistory } from "react-router";

const ProfileDetails = (props) => {

  // console.log({props:props.user.role},"USERRR")
  const disableField = (props.user.role === 'CONTENT_TEAM');
  // console.log({props})
  const [edit_flag, set_edit_flag] = React.useState(false)
  const history = useHistory()
  const [form] = Form.useForm();
  const [fetch_error, set_fetch_error] = React.useState(false)
  const [initial_data, set_initial_data] = React.useState({
    name: "",
    email: "",
    mobileNumber: '',
    qualification: '',
    registrationNumber: '',
    experience: '',
    latitude: '',
    longitude: '',
    experience: '',
    account_number: '',
    account_name: '',
    bank_name: '',
    ifsc_code: '',
    pan: '',
    userType: '',
    meta_title: '',
    meta_description: '',
  })


  const [data, set_data] = React.useState({
    name: "",
    email: "",
    mobileNumber: '',
    qualification: '',
    registrationNumber: '',
    experience: '',
    latitude: '',
    longitude: '',
    experience: '',
    account_number: '',
    account_name: '',
    bank_name: '',
    ifsc_code: '',
    pan: '',
    userType: '',
    meta_title: '',
    meta_description: '',

  })

  const confirmDeleteProfile = () => {
    props.delete_hospital_profile({ hospitalId: get_url_params('professional_id') })
  }

  const [error, set_error] = React.useState({
    name: { state: false, text: "" },
    email: { state: false, text: "" },
    mobileNumber: { state: false, text: "" },
    qualification: { state: false, text: "" },
    registrationNumber: { state: false, text: "" },
    experience: { state: false, text: "" },
    latitude: { state: false, text: "" },
    longitude: { state: false, text: "" },
    account_name: { state: false, text: "" },
    ifsc_code: { state: false, text: "" },
    bank_name: { state: false, text: "" },
    pan: { state: false, text: "" },
    account_number: { state: false, text: "" }
  })

  React.useEffect(() => {
    const id = get_url_params('professional_id')
    if (!!id) {
      props.get_professional_byid({
        professional_id: id
      })
      props.get_professional_info({
        professional_id: id,
        type: 'get_info'
      })
    }
  }, [])

  React.useEffect(() => {

    if (props.get_professional_byid_ret) {
      if (props.get_professional_byid_ret.success) {
        //   props.global_error({
        //    success:true,
        //    message:props.get_professional_byid_ret.message,
        //    heading:'Professional',
        //  })
        try {
          let data = props.get_professional_byid_ret.data
          // console.log(data, "data in get_professional_byid_ret")
          // console.log({data},"HERE");
          set_initial_data({
            name: data.name,
            email: data.email,
            mobileNumber: data.mobileNumber,
            qualification: data.qualification,
            registrationNumber: data.registrationNumber,
            experience: data.experience,
            latitude: data.geoLocation?.latitude,
            longitude: data.geoLocation?.longitude,
            account_number: data.bankDetails?.accountNumber,
            account_name: data.bankDetails?.name,
            bank_name: data.bankDetails?.bankName,
            ifsc_code: data.bankDetails?.ifscCode,
            pan: data.bankDetails?.panNumber,
            userType: data.userType?.userType,
            meta_title: data?.metaData?.title,
            meta_description: data?.metaData?.desc
          })

        } catch (error) {
          console.log(error)
        }

      } else {
        set_fetch_error(true)
        props.global_error({
          success: false,
          message: props.get_professional_byid_ret.message,
          heading: 'Professional'
        })
      }
      props.get_professional_byid_loading()
    }

    if (props.update_professional_byid_ret) {
      // console.log(data,"data in adasd")
      set_edit_flag(!edit_flag)
      set_initial_data({
        ...data
      })
      if (props.update_professional_byid_ret.success) {
        props.global_error({
          success: true,
          message: props.update_professional_byid_ret.message,
          heading: 'Professional',
        })
      } else {
        set_fetch_error(true)
        props.global_error({
          success: false,
          message: props.update_professional_byid_ret.message,
          heading: 'Professional'
        })
      }
      props.update_professional_byid_loading()
    }

    if (props.delete_hospital_profile_ret) {
      if (props.delete_hospital_profile_ret.success) {
        props.global_error({
          success: true,
          message: props.delete_hospital_profile_ret.message,
          heading: 'Professional',
        })
        history.push("/dashboard/professionals?professionals_list")
      } else {
        set_fetch_error(true)
        props.global_error({
          success: false,
          message: props.delete_hospital_profile_ret.message,
          heading: 'Professional'
        })
      }
      props.delete_hospital_profile_loading()
    }


  }, [props.get_professional_byid_ret, props.update_professional_byid_ret, props.delete_hospital_profile_ret])

  const edit_cllck = () => {
    set_edit_flag(!edit_flag)
    set_data({
      ...initial_data
    })
  }


  const validate = (data) => {
    console.log(data, "data in validate")
    const error = {
      name: { state: false, text: "" },
      email: { state: false, text: "" },
      mobileNumber: { state: false, text: "" },
      qualification: { state: false, text: "" },
      registrationNumber: { state: false, text: "" },
      experience: { state: false, text: "" },
      latitude: { state: false, text: "" },
      longitude: { state: false, text: "" },
      account_name: { state: false, text: "" },
      ifsc_code: { state: false, text: "" },
      bank_name: { state: false, text: "" },
      pan: { state: false, text: "" },
      account_number: { state: false, text: "" }
    }
    if (!data.account_number) {
      {
        error.account_number.text = "Please provide account number"
        error.account_number.state = true
      }
    }
    if (!data.pan) {
      {
        error.pan.text = "Please provide Pan number"
        error.pan.state = true
      }
    }
    if (!data.bank_name) {
      {
        error.bank_name.text = "Please provide bank name"
        error.bank_name.state = true
      }
    }
    if (!data.ifsc_code) {
      {
        error.ifsc_code.text = "Please enter IFSC code"
        error.ifsc_code.state = true
      }
    }
    if (!data.account_name) {
      {
        error.account_name.text = "Please enter account name"
        error.account_name.state = true
      }
    }
    // if(!data.longitude){
    //   {
    //     error.longitude.text = "Please Provide longitude"
    //     error.longitude.state = true
    //   }
    // }
    // if(!data.latitude){
    //   {
    //     error.latitude.text = "Please Provide latitude"
    //     error.latitude.state = true
    //   }
    // }
    console.log(initial_data, "initial_data")
    if (initial_data.userType === "Doctor") {
      if (!data.experience) {
        {
          error.experience.text = "Please enter experience"
          error.experience.state = true
        }
      }

      if (!data.qualification) {
        {
          error.qualification.text = "Please enter qualitfication"
          error.qualification.state = true
        }
      }

    }


    if (!data.name) {
      {
        error.name.text = "Please enter  name"
        error.name.state = true
      }
    }
    if (!data.email) {
      {
        error.email.text = "Please enter email address"
        error.email.state = true
      }
    }
    if (data.mobileNumber.length !== 0) {
      if (data.mobileNumber.length < 10) {
        {
          error.mobileNumber.text = "Phonenumber must be 10 characters long"
          error.mobileNumber.state = true
        }
      }
    }


    if (!data.registrationNumber) {
      {
        error.registrationNumber.text = "Please enter registration number"
        error.registrationNumber.state = true
      }
    }
    if (!!data.email) {
      if (!validator.isEmail(data.email)) {
        error.email.text = "Hmm, looks like an invalid email address";
        error.email.state = true
      }
    }

    if (!data.mobileNumber) {
      {
        error.mobileNumber.text = "Please enter your phone number"
        error.mobileNumber.state = true
      }
    }
    return error
  }
  const confirm = () => {
    Modal.confirm({
      title: 'Delete Profile',
      icon: <ExclamationCircleFilled />,
      onOk() {
        confirmDeleteProfile()
      },
      content: 'Are you sure ?',
      okText: 'Confirm',
      cancelText: 'Cancel',
    });
  };
  const update_professional = () => {
    const ret_error = validate({ ...data });
    set_error({ ...error, ...ret_error });
    // console.log(ret_error,"ret_error in add_member")
    if (!error_form_check(ret_error) || disableField) {
      // console.log("Inside Eror_form_check")
      //    props.execute_captcha()
      props.update_professional_byid(Object.assign({},
        {
          id: get_url_params('professional_id'),
          name: data.name,
          email: data.email,
          mobileNumber: data.mobileNumber,
          qualification: data.qualification,
          registrationNumber: data.registrationNumber,
          experience: data.experience,
          latitude: data.latitude,
          longitude: data.longitude,
          bankDetails: {
            accountNumber: data.account_number,
            name: data.bank_name,
            bankName: data.bank_name,
            ifscCode: data.ifsc_code,
            panNumber: data.pan,
          },
          meta_title: data.meta_title,
          meta_description: data.meta_description

          // token:props.recaptchaToken
        }))
    }
  }



  if (fetch_error) {
    return <React.Fragment>
      <Alert
        message="Unable to fetch Information"
        description="Currently we are unable to fetch professional info, we will update you soon"
        type="info"
        showIcon
      />
    </React.Fragment>
  }
  if (!!edit_flag) {
    return <React.Fragment>
      <Form
        form={form}
        layout="vertical"
      >

        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="Name"
              value={data.name}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.name.text}
              toggle_error={() => set_error({ ...error, name: { text: '', state: false } })}
              error={error.name.state}
              name="name"
              onChange={event => set_data({ ...data, name: event.target.value })}
              placeholder="Enter Name" />
          </span>
          <span className="flex-child-1-new">
            <AntdInput
              label="Email"
              value={data.email}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.email.text}
              toggle_error={() => set_error({ ...error, email: { text: '', state: false } })}
              error={error.email.state}
              name="email"
              onChange={event => set_data({ ...data, email: event.target.value })}
              placeholder="Enter email" />
          </span>
        </div>
        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="Phonenumber"
              value={data.mobileNumber}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.mobileNumber.text}
              toggle_error={() => set_error({ ...error, mobileNumber: { text: '', state: false } })}
              error={error.mobileNumber.state}
              name="mobileNumber"
              onChange={event => set_data({ ...data, mobileNumber: event.target.value })}
              placeholder="Enter Phonenumber" />
          </span>

          <span className="flex-child-1-new">
            <AntdInput
              label="Registration Number"
              value={data.registrationNumber}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.registrationNumber.text}
              toggle_error={() => set_error({ ...error, registrationNumber: { text: '', state: false } })}
              error={error.registrationNumber.state}
              name="registrationNumber"
              onChange={event => set_data({ ...data, registrationNumber: event.target.value })}
              placeholder="Enter Registration Number" />
          </span>
        </div>


        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="Qualifications"
              value={data.qualification}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.qualification.text}
              toggle_error={() => set_error({ ...error, qualification: { text: '', state: false } })}
              error={error.qualification.state}
              name="qualification"
              onChange={event => set_data({ ...data, qualification: event.target.value })}
              placeholder="Enter Qualification" />
          </span>

          <span className="flex-child-1-new">
            <AntdInput
              label="Experience"
              value={data.experience}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.experience.text}
              toggle_error={() => set_error({ ...error, experience: { text: '', state: false } })}
              error={error.experience.state}
              name="experience"
              onChange={event => set_data({ ...data, experience: event.target.value })}
              placeholder="Enter Experience(In Years)" />
          </span>
        </div>

        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="Bank Account Name"
              value={data.account_name}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.account_name.text}
              toggle_error={() => set_error({ ...error, account_name: { text: '', state: false } })}
              error={error.account_name.state}
              name="account_name"
              onChange={event => set_data({ ...data, account_name: event.target.value })}
              placeholder="Enter account name" />
          </span>

          <span className="flex-child-1-new">
            <AntdInput
              label="Bank Account Number"
              value={data.account_number}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.account_number.text}
              toggle_error={() => set_error({ ...error, account_number: { text: '', state: false } })}
              error={error.account_number.state}
              name="account_number"
              onChange={event => set_data({ ...data, account_number: event.target.value })}
              placeholder="Enter account number" />
          </span>
        </div>


        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="IFSC Code"
              value={data.ifsc_code}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.ifsc_code.text}
              toggle_error={() => set_error({ ...error, ifsc_code: { text: '', state: false } })}
              error={error.ifsc_code.state}
              name="ifsc_code"
              onChange={event => set_data({ ...data, ifsc_code: event.target.value })}
              placeholder="Enter IFSC code" />
          </span>

          <span className="flex-child-1-new">
            <AntdInput
              label="PAN Number"
              value={data.pan}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.pan.text}
              toggle_error={() => set_error({ ...error, pan: { text: '', state: false } })}
              error={error.pan.state}
              name="pan"
              onChange={event => set_data({ ...data, pan: event.target.value })}
              placeholder="Enter PAN number" />
          </span>
        </div>

        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="MetaData Title"
              value={data.meta_title}
              disabled={props.update_professional_byid_loading_flag}
              loading={props.update_professional_byid_loading_flag}
              name="meta_title"
              onChange={event => set_data({ ...data, meta_title: event.target.value })}
              placeholder="Enter MetaData Title" />
          </span>

          <span className="flex-child-1-new">
            <AntdInput
              label="MetaData Description"
              value={data.meta_description}
              disabled={props.update_professional_byid_loading_flag}
              loading={props.update_professional_byid_loading_flag}
              name="meta_description"
              onChange={event => set_data({ ...data, meta_description: event.target.value })}
              placeholder="Enter MetaData Description" />
          </span>
        </div>

        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="Bank Name"
              value={data.bank_name}
              disabled={props.update_professional_byid_loading_flag || disableField}
              loading={props.update_professional_byid_loading_flag}
              error_text={error.bank_name.text}
              toggle_error={() => set_error({ ...error, bank_name: { text: '', state: false } })}
              error={error.bank_name.state}
              name="bank_name"
              onChange={event => set_data({ ...data, bank_name: event.target.value })}
              placeholder="Enter Bank name" />
          </span>
        </div>

        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <Form.Item>
              <Button onClick={() => update_professional()} loading={props.get_bids_loading_flag} type="primary">Submit</Button>
              <Button className="u-margin-left-small" onClick={() => edit_cllck()} loading={props.get_bids_loading_flag} type="primary">Cancel</Button>
            </Form.Item>
          </span>
        </div>
      </Form>
    </React.Fragment>
  } else {
    return <React.Fragment>
      <Descriptions title="User Info" bordered>
        <Descriptions.Item label="Name">{initial_data.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{initial_data.email}</Descriptions.Item>
        <Descriptions.Item label="Phonenumber">{initial_data.mobileNumber}</Descriptions.Item>
        <Descriptions.Item label="Qualification">{initial_data.qualification}</Descriptions.Item>
        <Descriptions.Item label="Registration Number" span={2}>
          {initial_data.registrationNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Experience" span={3}>
          {initial_data.experience}
        </Descriptions.Item>
        <Descriptions.Item label="Latitude">{initial_data.latitude}</Descriptions.Item>
        <Descriptions.Item label="Lonigitude">{initial_data.longitude}</Descriptions.Item>
        <Descriptions.Item label="Experience">{initial_data.experience}</Descriptions.Item>
        <Descriptions.Item label="Bank Account Details">
          Account Name: {initial_data.account_name}
          <br />
          Bank Name: {initial_data.bank_name}
          <br />
          IFSC Code: {initial_data.ifsc_code}
          <br />
          Account Number: {initial_data.account_number}
          <br />
          Pan Number: {initial_data.pan}
          <br />
        </Descriptions.Item>
        <Descriptions.Item label="MetaData Title">{initial_data?.meta_title}</Descriptions.Item>
        <Descriptions.Item label="MetaData Description">{initial_data?.meta_description}</Descriptions.Item>
      </Descriptions>
      <div className="u-margin-top-small">
        <Row gutter={12}>
          <Col>
            <Button
              type="primary"
              onClick={() => edit_cllck()}
              icon={<EditOutlined />}
              size="small"
              style={{ width: 90 }}
            >Edit</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              danger={true}
              onClick={confirm}
              icon={<EditOutlined />}
              size="small"
            >Delete Profile</Button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  }

}

export default ProfileDetails