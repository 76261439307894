import React from "react"
import { Layout, Menu } from 'antd';
import { UserOutlined, NotificationOutlined, BookOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"

const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar = (props) => {
  // console.log(props,"props in SideBar")
        return ( <Sider width={200} className="site-layout-background">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
       
          style={{ height: '100%', borderRight: 0 }}
        >
          {(props.user.role==="ADMIN"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub1" icon={<UserOutlined />} title="Members">
         
            <Menu.Item  key="1"><Link to="/dashboard/members">List of Members</Link></Menu.Item>
            <Menu.Item selected={true} key="2"><Link to="/dashboard/members?add-member">Add Member</Link></Menu.Item>
              
          </SubMenu> }
          {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:props.user.role==="CONTENT_TEAM"?true:props.user.role==="MARKETING_EXECUTIVE"?true:false)  &&   <SubMenu key="sub2" icon={<UserOutlined />} title="Bidding">
            
            <Menu.Item  key="2"><Link to="/dashboard/bidding">Track Bids</Link></Menu.Item>
            
          </SubMenu> }
          {/* {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:props.user.role==="CONTENT_TEAM"?true:props.user.role==="MARKETING_EXECUTIVE"?true:false)  &&   <SubMenu key="1234" icon={<UserOutlined />} title="Blogs">
         
            <Menu.Item  key="1234"><Link to="/dashboard/blogs">Update Blogs</Link></Menu.Item>
            
          </SubMenu> } */}

        
          {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="DEVELOPER"?true:props.user.role==="CONTENT_TEAM"?true:props.user.role==="MARKETING_EXECUTIVE"?true:props.user.role==="SALES_MANAGER"?true:false) &&   <SubMenu key="sub3" icon={<NotificationOutlined />} title="Professionals">
            <Menu.Item key="3"><Link to="/dashboard/professionals?professionals_list">List</Link></Menu.Item>
            <Menu.Item key="31"><Link to="/dashboard/professionals?medical_doctors">Medical Doctors</Link></Menu.Item>          
          </SubMenu>}
          {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="DEVELOPER"?true:props.user.role==="CONTENT_TEAM"?true:props.user.role==="MARKETING_EXECUTIVE"?true:false) &&   <SubMenu key="sub4" icon={<NotificationOutlined />} title="Key Manager">
            
            {(props.user.role==="ADMIN" || props.user.role==="KEY_MANAGER" || props.user.role==="DEVELOPER" ||props.user.role==="ADMIN" || props.user.role==="CONTENT_TEAM" || props.user.role==="MARKETING_EXECUTIVE")    && <React.Fragment>
            <Menu.Item key="4"><Link to="/dashboard/keymanager?professionals_list">List</Link></Menu.Item>
            <Menu.Item key="42"><Link to="/dashboard/keymanager?add-service">Add Service</Link></Menu.Item>
            </React.Fragment>  }
            <Menu.Item key="422"><Link to="/dashboard/keymanager?service-data-handler">Service Data Handler</Link></Menu.Item>
            <Menu.Item key="423"><Link to="/dashboard/keymanager?campaign-service-data-handler">Campaign Data Handler</Link></Menu.Item>
            <Menu.Item key="424"><Link to="/dashboard/keymanager?set-meta-data">Update Speciality</Link></Menu.Item>
            <Menu.Item key="425"><Link to="/dashboard/keymanager?most-booked-services">Most Booked Services</Link></Menu.Item>
          </SubMenu> }

          {(props.user.role==="ADMIN"?true:props.user.role==="SALES_MANAGER"?true: props.user.role==="MARKETING_EXECUTIVE"?true:props.user.role==="DEVELOPER") &&   <SubMenu key="sub5" icon={<UserOutlined />} title="Sales Funnel">
         
         <Menu.Item  key="2"><Link to="/dashboard/salesfunnel?queries_list">Queries</Link></Menu.Item>
       
       </SubMenu> }

       {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub6" icon={<UserOutlined />} title="Insights Analytics">
         
         <Menu.Item  key="1212"><Link to="/dashboard/insights?realtime">Real Time Insights</Link></Menu.Item>
         <Menu.Item  key="1111"><Link to="/dashboard/insights?actionable">Actionable Insights</Link></Menu.Item>
         <Menu.Item  key="2111"><Link to="/dashboard/insights?suggested">Suggested Insights</Link></Menu.Item>

       </SubMenu> }

       {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub7" icon={<UserOutlined />} title="Bookings">
         
         <Menu.Item  key="1212"><Link to="/dashboard/bookings?add-user">Add User</Link></Menu.Item>
         <Menu.Item  key="272"><Link to="/dashboard/bookings?solutions">Solutions</Link></Menu.Item>
         <Menu.Item  key="23202434"><Link to="/dashboard/bookings?gloabl-bookings">Bookings</Link></Menu.Item>
         <Menu.Item  key="20009"><Link to="/dashboard/bookings?refunds">Refunds</Link></Menu.Item>
         

       </SubMenu> }
       {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub27" icon={<UserOutlined />} title="Catalogue Manager">
       
         <Menu.Item  key="2727272"><Link to="/dashboard/catalogue-manager?home">Home</Link></Menu.Item>
         <Menu.Item  key="9292929"><Link to="/dashboard/catalogue-manager?upload-catalogue">Upload Catalogue</Link></Menu.Item>
      
         

       </SubMenu> }

       {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub279232" icon={<UserOutlined />} title="Users">
       
       <Menu.Item  key="272e2ee72"><Link to="/dashboard/users?users-list">UsersList</Link></Menu.Item>
    
      
     </SubMenu> }

     {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="suwe79232" icon={<UserOutlined />} title="Follow-ups">
       
       <Menu.Item  key="weweweswe"><Link to="/dashboard/follow-ups?list">UsersList</Link></Menu.Item>
       <Menu.Item  key="wewsdswe"><Link to="/dashboard/follow-ups?previous-followups">Follow Ups</Link></Menu.Item>
       <Menu.Item  key="ssdaaadd"><Link to="/dashboard/follow-ups?your-followups">Your Followups</Link></Menu.Item>
    
      
     </SubMenu> }


     {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub72" icon={<UserOutlined />} title="Custom Bookings">
         
         <Menu.Item  key="1213"><Link to="/dashboard/funnel?add-user">Add User</Link></Menu.Item>
         <Menu.Item  key="232024234"><Link to="/dashboard/funnel?global-bookings">Bookings</Link></Menu.Item>
         

       </SubMenu> }

     {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="SALES_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub73" icon={<UserOutlined />} title="Insurance Bookings">
         
         {/* <Menu.Item  key="96434"><Link to="/dashboard/funnel?add-user">Add User</Link></Menu.Item> */}
         <Menu.Item  key="6969"><Link to="/dashboard/insurance-bookings?check-user">Create Booking</Link></Menu.Item>
         <Menu.Item  key="1009"><Link to="/dashboard/insurance-bookings?list">List</Link></Menu.Item>
       </SubMenu> }

     {(props.user.role==="ADMIN"?true:props.user.role==="KEY_MANAGER"?true:props.user.role==="DEVELOPER"?true:false) &&   <SubMenu key="sub74" icon={<BookOutlined />} title="Anywhere Cashless">
         
         {/* <Menu.Item  key="96434"><Link to="/dashboard/funnel?add-user">Add User</Link></Menu.Item> */}
         <Menu.Item  key="42069"><Link to="/dashboard/anywhere-cashless?details">Details</Link></Menu.Item>
       </SubMenu> }

      
       <SubMenu key="sub79" icon={<UserOutlined />} title="Create Marketing Pages">
         <Menu.Item  key="7070"><Link to="/dashboard/create-marketing-pages?create">Create Pages</Link></Menu.Item>
       </SubMenu>

        </Menu>
      </Sider>)
}
export default Sidebar