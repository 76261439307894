import React from "react"
import { Table, Input, Button, Space, Modal, Select, Calendar, TimePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined , CalendarOutlined} from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import { update_status } from "../../actions/salesfunnel";
import AntdInput from "../../Components/AntdInput"
import SendSms from "./SendSms"
import BulkSmsList from "./BulkSmsList"
const { Option }  =  Select

const Regpublicqueries = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [public_queries_params, set_public_queries_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
             userName:'',
             email:'',
             mobileNumber:''
           },
           filters:{
            status:'',
            createdTime:'',
            userType:''
           }
      })

      const [data, set_data] = React.useState([])
      const [visible, set_visible] = React.useState(false)
      const [bulk_sms_list , set_bulk_sms_list] = React.useState(false)
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [selected_row, set_selected_row] = React.useState({})
      const [selected_status, set_selected_status] = React.useState('')
      const [sms_select, set_sms_select] = React.useState({})
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [note, set_note] = React.useState({
          note:''
    })
    const [error, set_error] = React.useState({
        note:{ state:false, text:"" }
 })
      const [visible_reminder, set_visible_reminder] = React.useState(false)
      const [selected_follow_up, set_selected_follow_up] = React.useState({

      })

      const [update_follow_up_data, set_update_follow_up_data] = React.useState({
        date:'',
        time:'',
        note:''
      })
      const [update_follow_up_error, set_update_follow_up_error] = React.useState({
      date:{ state:false, text:"" },
      time:{ state:false, text:"" },
      note:{state:false, text:''}
      })
      const [template_sms_select, set_template_sms_select] = React.useState({})

      React.useEffect(()=>{
        if(!!props.send_template_sms_ret){
              if(props.send_template_sms_ret.success){
                props.global_error({
                  success:true,
                  message:props.send_template_sms_ret.message,
                  heading:'SMS'
                })
      
                let arr = []
                let new_arr = [...data]
                arr = new_arr.map(item=>{
                      if(item._id===template_sms_select.queryId){
                        let new_sms = [...item.sms]
                        new_sms.unshift({
                          ...template_sms_select,
                          messageBody: template_sms_select.templateName
                        })
                        return {
                          ...item,
                          sms:new_sms
                        }   
                      }
                      else {
                        return item
                      }
                 })
      
                set_data(arr)
      
              }else{
                props.global_error({
                  success:false,
                  message:props.send_template_sms_ret.message,
                  heading:'SMS'
                })
              }
              props.send_template_sms_loading()
        }
       },[props.send_template_sms_ret])

    React.useEffect(()=>{
        props.get_reg_public_queries(public_queries_params)
    },[])

    React.useEffect(()=>{
      if(!!props.send_sms_ret){
            if(props.send_sms_ret.success){
              props.global_error({
                success:true,
                message:props.send_sms_ret.message,
                heading:'SMS'
              })
    
              let arr = []
              let new_arr = [...data]
              arr   = new_arr.map(item=>{
                console.log(item, sms_select, "======================>")
                    if(item._id===sms_select.queryId){
                      console.log(sms_select,"send_sms")
                      let new_sms = [...item.sms]
                      new_sms.unshift({
                        ...sms_select,
                        messageBody:sms_select.message
                      })
                      return {
                        ...item,
                        sms:new_sms
                      }   
                    }
                    else {
                      return item
                    }
               })
    
              set_data(arr)
    
            }else{
              props.global_error({
                success:false,
                message:props.send_sms_ret.message,
                heading:'SMS'
              })
            }
            props.send_sms_loading()
      }
     },[props.send_sms_ret])

    React.useEffect(()=>{
      if(!!props.get_reg_public_queries_ret){
        // console.log(props.get_reg_public_queries_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_reg_public_queries_ret.success){
          console.log("called again")
          set_data(modif_data([...props.get_reg_public_queries_ret.data]))
          set_public_queries_params({...public_queries_params, total:props.get_reg_public_queries_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_reg_public_queries_ret.message,
            heading:'Professionals'
          })
         }
          props.get_reg_public_queries_loading()
      }


      if(!!props.update_status_ret){
         if(props.update_status_ret.success){
          props.global_error({
            success:true,
            message:props.update_status_ret.message,
            heading:'Update Status'
          })
          if(visible){
             let new_data = data.map(item=>{
               console.log(item, selected_row,"item, selected_row")
               if(!!(item._id === selected_row._id)){
                 console.log(note.note,"True Called")
                 return {
                   ...item,
                   note:note.note
                 }
               }else{
                 return item
               }
             })
             console.log(new_data,"new_data")
            set_data([...new_data])
            set_visible(false)
          }else {
            let new_data = data.map(item=>{
              console.log(item, selected_row,"item, selected_row")
              if(!!(item._id === selected_row._id)){
                console.log(note.note,"True Called")
                return {
                  ...item,
                  status:selected_status
                }
              }else{
                return item
              }
            })
            set_data([...new_data])
          }
         }else{
          props.global_error({
            success:false,
            message:props.update_status_ret.message,
            heading:'Update Status'
          })
         }
          props.update_status_loading()
      }


      if(!!props.update_follow_up_ret){
        
        if(props.update_follow_up_ret.success){
         
          handleCancel()
          props.get_reg_public_queries(public_queries_params)
         props.global_error({
           success:true,
           message:props.update_follow_up_ret.message,
           heading:'Update Follow up'
         })
        }else{
         props.global_error({
           success:false,
           message:props.update_follow_up_ret.message,
           heading:'Update Follow up'
         })
        }
         props.update_follow_up_loading()
     }
     
         
  },[props.get_reg_public_queries_ret, props.update_status_ret, props.update_follow_up_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...public_queries_params.filters}
       let updated_filters = {}
   
       updated_filters.status = filters.status
       updated_filters.userType = filters.userType
       updated_filters.createdTime = filters.createdTime
       updated_filters.userType = filters.userType
      if(!!((pagination.current === public_queries_params.page) && (pagination.pageSize === public_queries_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_public_queries_params({...public_queries_params, page:1, filters :{
           createdTime: filters.createdTime? filters.createdTime:'',
           status:filters.status?filters.status:'',
           userType:filters.userType?filters.userType:''
          }})
          props.get_reg_public_queries({...public_queries_params, page:1, filters :{
            createdTime: filters.createdTime? filters.createdTime:'',
            status:filters.status?filters.status:'',
            userType:filters.userType?filters.userType:''    
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}

      const send_template_sms = (data) => {
        set_template_sms_select(data)
        props.send_template_sms({...data})
      }

     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_public_queries_params({...public_queries_params, page:1,searchParams:{
           ...public_queries_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_reg_public_queries({...public_queries_params, page:1,searchParams:{
          ...public_queries_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let query_list = []
        for_loop(arr, (item) => {
            let obj = {
            userName: item.userName,
            email: item.email,
            message:item.message,
            mobileNumber:item.mobileNumber,
            note:item.note?item.note:"N/A",
            status:item.status,
            createdTime:getDateFormat(item.createdTime),
            sms:item.sentMessages,
            _id: item._id,
            userType:item.userType
            }
            query_list.push({
          ...obj
            })
           
        })

    return query_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_public_queries_params({...public_queries_params, limit:b, page:1})
      props.get_reg_public_queries({...public_queries_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,public_queries_params,"a and b public_queries_params")
      // if(public_queries_params.page !== a){
        if(true){
        set_public_queries_params({...public_queries_params, page:a, limit:b})
        props.get_reg_public_queries({...public_queries_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_public_queries_params({...public_queries_params,page:1,  searchParams:{
        userName:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        status:'',
        createdTime:1000,
        userType:''
      }})
      props.get_reg_public_queries({...public_queries_params,page:1,  searchParams:{
        userName:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        status:'',
        createdTime:1000,
        userType:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_public_queries_params({...public_queries_params, page:1, filters:{
        status:'',
        createdTime:1000,
        userType:''
      }})
      props.get_reg_public_queries({...public_queries_params, page:1, filters:{
        status:'',
        createdTime:1000,
        userType:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const update_status = (val, record ) => {
      set_selected_row(record)
      set_selected_status(val)
            props.update_status({
              status:val,
              queryId:record._id,
              flag:"Registered"
            })
    }

    const close_modal = () => {
            set_visible(false)
    }

    console.log(note,"note")

    const columns = [
        {
          title: 'Name',
          dataIndex: 'userName',
          key: 'userName',
          width:'10%',
         
          ...getColumnSearchProps('userName', "Search userName", 'userName'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width:'10%',
            ...getColumnSearchProps('email', "Search email", 'email'),
           
          },
        {
          title: 'Phone',
          dataIndex: 'mobileNumber',
          key: 'mobileNumber',
          width:'10%',
          ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            width:'15%',
            // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            
          },
          {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            width:'15%',
            // render:(text)=>{
            //   return <p title={text}>{text}</p>
            // },
            // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        
          },
          {
            title: 'User',
            dataIndex: 'userType',
            key: 'userType',
            width:'10%',
            filters: [
                {
                    text: 'User',
                    value: 'User',
               },
                {
                  text: 'Hospitals',
                  value: 'Hospital',
                },
                {
                  text: 'Labs',
                  value: 'Lab',
                },
                {
                  text: 'Doctors',
                  value: 'Doctor',
                }
              ],
            filterMultiple: false,   
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
          
          },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width:'10%',
          filters: [
            {
              text: 'Pending',
              value: 'PENDING',
            },
            {
              text: 'Done',
              value: 'DONE',
            },
            {
              text: 'Hot Lead',
              value: 'HOT_LEAD',
            },
            {
              text: 'Cold Lead',
              value: 'COLD_LEAD',
            },
            {
              text:"Callback Due",
              value:"CALLBACK_DUE"
            }
          ],
          filterMultiple: false,   
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
         
          render:(item, record) => {
              return <div className="u-align-center">
                  <Select value={record.status} style={{ width: 120 }} onChange={(val)=>update_status(val, record)}>
                            <Option disabled={record.status==="PENDING"} value="PENDING">Pending</Option>
                            <Option disabled={record.status==="DONE"} value="DONE">Done</Option>
                            <Option disabled={record.status==="HOT_LEAD"} value="HOT_LEAD">Hot Lead</Option>
                            <Option disabled={record.status==="COLD_LEAD"} value="COLD_LEAD">Cold Lead</Option>
                            <Option disabled={record.status==="CALLBACK_DUE"} value="CALLBACK_DUE">Callback Due</Option>
    </Select>
              </div>
          }
        },
        {
            title: 'Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width:'10%',
            filters: [
              {
                text: 'Today',
                value: 1,
              },
              {
                text: 'Yesterday',
                value: 2,
              },
              {
                text: 'Last 7 days',
                value: 7,
              },
              {
                text: 'Last 30 days',
                value: 30,
              },
              {
                text: 'Default Value',
                value: 1000,
              }
            ],
            filterMultiple: false,
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
           
          },
          {
            title: 'Action',
            key: 'action',
            width:'10%',
            render: (text, record) => (
              <React.Fragment>
              <Space size="middle">
                <a onClick = {()=>open_modal(record)}>Add/Update Note</a>
              </Space>
              <Space size="middle">
                 <div className="text-center">
                        <CalendarOutlined style={{cursor:'pointer'}} title="Add Reminder" onClick={()=>{
                set_visible_reminder(true)
                set_selected_follow_up(record)
                   } 
                 } />
               </div>
              </Space>
              </React.Fragment>
            ),
          }
      ]

      const open_modal = (record) => {
        set_selected_row(record)
        set_visible(true)
        console.log(record,"record")
        set_note({
          note:record.note
        })
      }

      const submit_note = () => {
        props.update_status({
          note:note.note,
          queryId:selected_row._id,
          flag:"Registered"
        })
      }

      const expand = (record,a,b) => {
        console.log(record,a,b,"record,a,b")
        if(expandedRowKeys[0]!== a._id){
           set_expandedRowKeys([a._id])
           console.log(a,"a.varianceInfo")
        }else{
        set_expandedRowKeys([])
        }
      }
  
     const send_sms = (data) => {
          set_sms_select({
            queryId:data.queryId,
            message:data.sentMessage,
            sentMessage:data.sentMessage
          })
          props.send_sms({
            ...data
          })
     }
     function showTotal(total) {
      return `Total ${total} items`;
    }


    const  handleCancel = e => {
      console.log("Inside handleCancel")
      console.log(e);
      set_visible_reminder(false)
      set_update_follow_up_data({
        date:'',
        time:'',
        note:''
      })
      set_update_follow_up_error({
        date:{ state:false, text:"" },
        time:{ state:false, text:"" },
        note:{state:false, text:''}
      })
    }


    const submit = () => {
      try {
        if(update_follow_up_data.time===0  || update_follow_up_data.date===""){
          set_error({...error, date:{state:true, text:'Select time to set reminder'}})
          throw new Error("Select time to set reminder")
        }
  
        if(update_follow_up_data.note===0){
          set_error({...error, node:{state:true, text:'Enter Note'}})
        
        }else {
          props.update_follow_up({
            queryId:selected_follow_up._id,
            queryType:'PUBLIC_QUERY',
            timeSlot: update_follow_up_data.time,
            date: update_follow_up_data.date,
            note:update_follow_up_data.note,
            status:selected_follow_up.status
          })
        }
      } catch (error) {
        console.log(error)
        props.global_error({
          success:false,
          message:error.message,
          heading:"Set Reminder",
        })
      }
    
  }

      console.log(note,"note in public queries")

   if(!!professional_selected){
        return <Redirect to={`/dashboard/professionals?professional_id=${professional_selected._id}`} />
   }



//   if(bulk_sms_list){
//     return <React.Fragment>
//            <PageHeader
//      className="site-page-header"
//    onBack={() => {
//      set_bulk_sms_list(false)
//      set_selected_queries([])
//    }}
//    title="Previous Bulk Messages"
//    // subTitle="This is a subtitle"
//  >
//           <BulkSmsList
//             reportType = {"REG_PUBLIC_QUERIES_REPORT"}

//             get_bulk_sms_list = {props.get_bulk_sms_list}
//             get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
//             get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
//             get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

//             global_error = {props.global_error}
//           />

//       </PageHeader>

//     </React.Fragment>
//   }

        return (<React.Fragment>
          <Modal
          title="Update/Add"
          visible={visible}
          onOk={submit_note}
          onCancel={close_modal}
        >
    <div className="flex-parent-new">
       <span className="flex-child-1-new">
           <AntdInput label="Note"  
            value={note.note} 
            error_text={error.note.text} 
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error,note:{text:'',state:false}})} 
            error={error.note.state} 
            name="note" 
            onChange = {event=>set_note({[event.target.name]:event.target.value})} 
            placeholder="Enter note"
            type="textarea"
            rows= {5}
            />
      </span>
</div>
        </Modal>

{/* 
        <div className="u-margin-top-small">

          <Button
            type="primary"
            onClick={() => set_bulk_sms_list(true)}
            size="small"
            style={{ width: 200 }}
            className="u-margin-top-small"
          >
            Previous Messages
          
          </Button>
        </div> */}
            
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_reg_public_queries_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:public_queries_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:public_queries_params.page,
            total:public_queries_params.total,
            showTotal:showTotal
          }}
          expandedRowRender= {record =>{
            console.log(record,"record")
            return <React.Fragment>
              <div key={record._id}>
                <SendSms
                    send_sms = {send_sms}
                    type="registered"
                    sms = {record.sms}
                    queryId={record._id}
                    send_sms_loading_flag = {props.send_sms_loading_flag}
                    get_message_templates = {props.get_message_templates}
                    get_message_templates_ret = {props.get_message_templates_ret}
                    send_template_sms = {send_template_sms}
                    send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                />
              </div>
      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record._id}
          />


<Modal
          title="Set Reminder"
          visible={visible_reminder}
          onOk={submit}
          onCancel={handleCancel}
          width={600}
        >
           {visible_reminder && <div className="flex-parent-new u-margin-top-small">
                <span className="flex-child-1-new">
                <div className="site-calendar-demo-card">
                    <Calendar fullscreen={false}
                  
                     onPanelChange={(e,r)=>console.log(e, r ,"e, r in calendat")}
                     onSelect={(val,e)=>{
                      console.log(val,e,"val in Calendar")
                      console.log( val.unix())
                        set_update_follow_up_data({
                          ...update_follow_up_data, date:val.unix()
                        })
                     
                     }}  
                     />
                  </div>
                </span>
                <span className="flex-child-1-new">
                     <div className="">
                     <TimePicker onChange={(val,r)=>{
                        set_update_follow_up_data({
                          ...update_follow_up_data, time:val.unix()
                         })
                     }} />
                     <div className="u-margin-top-small">
                        <AntdInput label="Note"  
                        value={update_follow_up_data.note} 
                        error_text={update_follow_up_error.note.text} 
                        loading = {props.send_sms_loading_flag}
                        disabled = {props.send_sms_loading_flag} 
                        toggle_error = {()=>set_update_follow_up_error({...error, note:{text:'',state:false}})} 
                        error={update_follow_up_error.note.state} 
                        name="note" 
                        onChange = {event=>set_update_follow_up_data({...update_follow_up_data,[event.target.name]:event.target.value})} 
                        placeholder="Enter note"
                        type="textarea"
                        rows= {10}
                        /> 
                     </div>
                     </div>
                      
                </span>
          </div>}
          <div className="text-center">
          {/* <Button
                  type="primary"
                 onClick={() => update_payment()}
                 disabled = {props.update_bookings_loading_flag}
                 loading={props.update_bookings_loading_flag}
                 style={{ width: 'auto', margin:'auto' }}
      >
       Submit
    </Button> */}
          </div>
        </Modal>
       
        </React.Fragment>)
}
export default Regpublicqueries