import React from "react";

import { Tabs, Card, PageHeader } from "antd";
import { SolutionOutlined, FolderOpenOutlined } from "@ant-design/icons";

import {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
} from "../actions/global";
import {
  check_number,
  check_number_loading,
  register_user,
  register_user_loading,
  get_doctors,
  get_doctors_loading,
  make_booking,
  make_booking_loading,
  get_bookings_loading,
  get_bookings,
  generate_link,
  generate_link_loading,
  set_user,
  update_bookings,
  update_bookings_loading,
  get_insights,
  get_insights_loading,
  get_sol_professionals_loading,
  get_sol_professionals,
  update_sol_professional_loading,
  update_sol_professional,
  global_bookings,
  global_bookings_loading,
  update_global_booking,
  update_global_booking_loading,
  get_refunds,
  get_refunds_loading,
  get_reports_byid,
  get_reports_byid_loading,
  get_medical_details,
  get_medical_details_ret,
  get_medical_details_loading,
} from "../actions/bookings";

import { add_service, add_service_loading } from "../actions/keyManager";
import { get_bids, get_bids_loading } from "../actions/bidding";
import {
  update_catalogue,
  update_catalogue_loading,
} from "../actions/professionals";

import { upload_user_report, upload_user_report_ret, upload_user_report_loading, 
  upload_insurance_report, upload_insurance_report_ret, upload_insurance_report_loading,
  submit_medical_details, submit_medical_details_ret, submit_medical_details_loading,
  edit_medical_details, edit_medical_details_ret, edit_medical_details_loading,
  create_custom_solution, create_custom_solution_loading } from "../actions/funnel";

import { connect } from "react-redux";
import AddUser from "../Views/Funnel/AddUser";
import Booking from "../Views/Funnel/Booking";
import PreviousBookings from "../Views/Funnel/PreviousBookings";
import BookingsView from "./../Views/Bookings/BookingsView";
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

class Funnel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
    };
  }

  render() {
    console.log(this.props, "this.props  in Professional_container");
    return (
      <React.Fragment>
        <div>
          {this.props.location.search === "?add-user" ? (
            <React.Fragment>
              <AddUser
                check_number_loading={this.props.check_number_loading}
                check_number={this.props.check_number}
                check_number_ret={this.props.bookings_store.check_number_ret}
                check_number_loading_flag={
                  this.props.bookings_store.check_number_loading
                }
                global_error={this.props.global_error}
                global_store={this.props.global_store}
                get_services={this.props.get_services}
                register_user_loading={this.props.register_user_loading}
                register_user_loading_flag={
                  this.props.bookings_store.register_user_loading
                }
                register_user_ret={this.props.bookings_store.register_user_ret}
                register_user={this.props.register_user}
                get_bookings={this.props.get_bookings}
                get_bookings_loading={this.props.get_bookings_loading}
                get_bookings_loading_flag={
                  this.props.bookings_store.get_bookings_loading
                }
                get_bookings_ret={this.props.bookings_store.get_bookings_ret}
                set_user={this.props.set_user}
              />
            </React.Fragment>
          ) : this.props.location.search === "?global-bookings" ? (
            <React.Fragment>
              <BookingsView
                global_bookings={this.props.global_bookings}
                global_bookings_loading={this.props.global_bookings_loading}
                global_bookings_ret={
                  this.props.bookings_store.global_bookings_ret
                }
                global_bookings_loading_flag={
                  this.props.bookings_store.global_bookings_loading
                }
                update_global_booking={this.props.update_global_booking}
                update_global_booking_ret={
                  this.props.bookings_store.update_global_booking_ret
                }
                update_global_booking_loading={
                  this.props.update_global_booking_loading
                }
                update_global_booking_loading_flag={
                  this.props.bookings_store.update_global_booking_loading
                }
                generate_link={this.props.generate_link}
                generate_link_loading={this.props.generate_link_loading}
                generate_link_loading_flag={
                  this.props.bookings_store.generate_link_loading
                }
                generate_link_ret={this.props.bookings_store.generate_link_ret}
                global_error={this.props.global_error}
                global_store={this.props.global_store}
              />
            </React.Fragment>
          ) : (
            <Card bodyStyle={{ backgroundColor: "#F0F2F5" }}>
              <Tabs
                tabBarStyle={{ backgroundColor: "white", padding: "0rem 1rem" }}
                defaultActiveKey="1"
              >
                <TabPane
                  tab={
                    <span>
                      <SolutionOutlined />
                      Registered User
                    </span>
                  }
                  key="1"
                >
                  <Booking
                    global_error={this.props.global_error}
                    global_store={this.props.global_store}
                    get_services={this.props.get_services}
                    global_error_ret={this.props.global_error_ret}
                    global_error_clr={this.props.global_error_clr}
                    user={this.props.bookings_store.user}
                    get_doctors_loading={this.props.get_doctors_loading}
                    get_doctors_loading_flag={
                      this.props.bookings_store.get_doctors_loading
                    }
                    get_doctors_ret={this.props.bookings_store.get_doctors_ret}
                    get_doctors={this.props.get_doctors}
                    make_booking_loading={this.props.make_booking_loading}
                    make_booking_loading_flag={
                      this.props.bookings_store.make_booking_loading
                    }
                    make_booking_ret={
                      this.props.bookings_store.make_booking_ret
                    }
                    make_booking={this.props.make_booking}
                    get_bids={this.props.get_bids}
                    get_bids_loading={this.props.get_bids_loading}
                    get_bids_ret={this.props.bidding_store.get_bids_ret}
                    get_bids_loading_flag={
                      this.props.bidding_store.get_bids_loading
                    }
                    get_medical_details={this.props.get_medical_details}
                    get_medical_details_ret={this.props.bookings_store.get_medical_details_ret}
                    get_medical_details_loading={
                      this.props.get_medical_details_loading
                    }
                    get_medical_details_loading_flag={
                      this.props.bookings_store.get_medical_details_loading
                    }
                    generate_link={this.props.generate_link}
                    generate_link_loading={this.props.generate_link_loading}
                    generate_link_loading_flag={
                      this.props.bookings_store.generate_link_loading
                    }
                    generate_link_ret={
                      this.props.bookings_store.generate_link_ret
                    }
                    add_service_loading={this.props.add_service_loading}
                    add_service={this.props.add_service}
                    add_service_ret={
                      this.props.keymanager_store.add_service_ret
                    }
                    add_service_loading_flag={
                      this.props.keymanager_store.add_service_loading
                    }
                    update_catalogue={this.props.update_catalogue}
                    update_catalogue_loading={
                      this.props.update_catalogue_loading
                    }
                    update_catalogue_loading_flag={
                      this.props.professionals_store.update_catalogue_loading
                    }
                    update_catalogue_ret={
                      this.props.professionals_store.update_catalogue_ret
                    }

                    upload_user_report={this.props.upload_user_report}
                    upload_user_report_ret={this.props.funnel_store.upload_user_report_ret}
                    upload_user_report_loading={this.props.upload_user_report_loading}
                    upload_user_report_loading_flag={this.props.funnel_store.upload_user_report_loading}

                    upload_insurance_report={this.props.upload_insurance_report}
                    upload_insurance_report_ret={this.props.funnel_store.upload_insurance_report_ret}
                    upload_insurance_report_loading={this.props.upload_insurance_report_loading}
                    upload_insurance_report_loading_flag={this.props.funnel_store.upload_insurance_report_loading}

                    submit_medical_details={this.props.submit_medical_details}
                    submit_medical_details_ret={this.props.funnel_store.submit_medical_details_ret}
                    submit_medical_details_loading={this.props.submit_medical_details_loading}
                    submit_medical_details_loading_flag={this.props.funnel_store.submit_medical_details_loading}

                    edit_medical_details={this.props.edit_medical_details}
                    edit_medical_details_ret={this.props.funnel_store.edit_medical_details_ret}
                    edit_medical_details_loading={this.props.edit_medical_details_loading}
                    edit_medical_details_loading_flag={this.props.funnel_store.edit_medical_details_loading}

                    create_custom_solution={this.props.create_custom_solution}
                    create_custom_solution_ret={this.props.funnel_store.create_custom_solution_ret}
                    create_custom_solution_loading={this.props.create_custom_solution_loading}
                    create_custom_solution_loading_flag={this.props.funnel_store.create_custom_solution_loading}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <FolderOpenOutlined />
                      Previous Bookings
                    </span>
                  }
                  key="2"
                >
                  <PreviousBookings
                    global_error={this.props.global_error}
                    global_store={this.props.global_store}
                    get_services={this.props.get_services}
                    user={this.props.bookings_store.user}
                    get_bookings_loading_flag={
                      this.props.bookings_store.get_bookings_loading
                    }
                    get_bookings_loading={this.props.get_bookings_loading}
                    get_bookings={this.props.get_bookings}
                    get_bookings_ret={
                      this.props.bookings_store.get_bookings_ret
                    }
                    generate_link={this.props.generate_link}
                    generate_link_loading={this.props.generate_link_loading}
                    generate_link_loading_flag={
                      this.props.bookings_store.generate_link_loading
                    }
                    generate_link_ret={
                      this.props.bookings_store.generate_link_ret
                    }
                    update_bookings_loading={this.props.update_bookings_loading}
                    update_bookings_ret={
                      this.props.bookings_store.update_bookings_ret
                    }
                    update_bookings={this.props.update_bookings}
                    update_bookings_loading_flag={
                      this.props.bookings_store.update_bookings_loading
                    }
                    get_doctors_loading={this.props.get_doctors_loading}
                    get_doctors_loading_flag={
                      this.props.bookings_store.get_doctors_loading
                    }
                    get_doctors_ret={this.props.bookings_store.get_doctors_ret}
                    get_doctors={this.props.get_doctors}
                  />
                </TabPane>
              </Tabs>
            </Card>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth_store: state.auth_store,
  global_store: state.global_store,
  insights_store: state.insights_store,
  bookings_store: state.bookings_store,
  bidding_store: state.bidding_store,
  keymanager_store: state.keymanager_store,
  professionals_store: state.professionals_store,
  funnel_store: state.funnel_store,
  global_error_ret: state.global_store.global_error_ret,
  global_store: state.global_store,
});

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
  check_number,
  check_number_loading,
  register_user,
  register_user_loading,
  get_doctors,
  make_booking,
  make_booking_loading,
  get_doctors_loading,
  get_bookings_loading,
  get_bookings,
  generate_link,
  generate_link_loading,
  set_user,
  update_bookings,
  update_bookings_loading,
  get_insights,
  get_insights_loading,
  get_sol_professionals_loading,
  get_sol_professionals,
  update_sol_professional,
  update_sol_professional_loading,
  global_bookings,
  global_bookings_loading,
  update_global_booking_loading,
  update_global_booking,
  get_refunds,
  get_refunds_loading,
  get_reports_byid,
  get_reports_byid_loading,
  get_bids,
  get_bids_loading,
  get_medical_details,
  get_medical_details_loading,
  add_service,
  add_service_loading,
  update_catalogue,
  update_catalogue_loading,
  upload_user_report,
  upload_user_report_ret,
  upload_user_report_loading,
  upload_insurance_report,
  upload_insurance_report_ret,
  upload_insurance_report_loading,
  submit_medical_details,
  submit_medical_details_ret,
  submit_medical_details_loading,
  edit_medical_details,
  edit_medical_details_ret,
  edit_medical_details_loading,
  create_custom_solution,
  create_custom_solution_loading
})(Funnel);
