import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Input, Layout, message, Modal, notification, Row, Select, Space, Table, Tag, Upload } from 'antd';
import BookingDetails from './BookingDetails';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import Highlighter from 'react-highlight-words';

import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import AntdInput from '../../Components/AntdInput';
import axios from 'axios';
import { get_url, objectEquals } from '../../utils/common_utilities';
import Icon from '@ant-design/icons/lib/components/AntdIcon';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom'
import moment from 'moment';
dayjs.extend(weekday)
dayjs.extend(localeData)

const { Option }  =  Select
const { RangePicker } = DatePicker;

function BookingTable(props) {

  const [expandedRowKeys, setExpandRowKeys] = useState([])
  const [rentDetailsFlag, setRentDetailsFlag] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState()
  const [addSession, setAddSession] = useState(false)
  const [alLetterModal, setAlLetterModal] = useState(false)
  const [alLetterDetails, setAlLetterDetails] = useState({})
  const [insuranceDocuments, setInsuranceDocuments] = useState({})
  const [docModalFlag, setDocModalFlag] = useState(false)
  const dateFormat = 'DD-MMM-YYYY';
  const timeFormat = 'hh:mm A';
  const [selectedDate, setSelectedDate] = useState("")
  const [loading, setLoading] = useState(false)
  const [queryDocFlag, setQueryDocFlag] = useState(false)
  const [queryDocuments, setQueryDocuments] = useState({})
  const [booking_selected, set_booking_selected] = useState(false)
  const [selected_row, set_selected_row] = useState(null)
  const [is_name_clicked, set_is_name_clicked] = useState(false)
  
  const [state, set_state] = React.useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: '',
    searchedColumn: '',
    searchDate: "",
    reminderFilter: '',
    counter: 0
  })

  const [tableParams, setTableParams] = useState({
    page:1,
    limit:10,
    total:10,
    searchParams: {
      userName: '',
      alNumber: '',
      ipNumber: '',
      userMobileNumber: ''
    },
    filters:{
      status: "",
      searchDate: ""
    }
  })

  const getDateString = (date) => {
    let ts = date.split("-")
    ts = ts[0] + "-" + ts[1].charAt(0).toUpperCase() + ts[1].charAt(1).toLowerCase() + ts[1].charAt(2).toLowerCase() + "-" + ts[2]
    return ts
  }


  const updatePreAuthStatus = (bookingId, status) => {
    props.update_booking({
      bookingId: bookingId,
      preAuthStatus: status,
      preAuth: "TRUE"
    })
  }

  const updatePaymentStatus = (bookingId, status) => {
    props.update_booking({
      bookingId: bookingId,
      paymentStatus: status
    })
  }

  const updatePatientStatus = (bookingId, status) => {
    props.update_booking({
      bookingId: bookingId,
      patientStatus: status
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProps) => {
    confirm();
    setTableParams({ ...tableParams, searchParams: { [searchProps]: selectedKeys[0] }, page: 1 })
    props.get_insurance_bookings({ ...tableParams, searchParams: { [searchProps]: selectedKeys[0] }, page: 1 })
    set_state({
      ...state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  const handle_reset = (clearFilters, b, c) => {
    clearFilters()
    set_state({
      ...state,
      counter: state.counter + 1,
      searchText: '',
      searchedColumn: '',
      filteredInfo: null,
    })
    setTableParams({ ...tableParams,
      page: 1,
      searchParams: {
        userName: '',
        alNumber: '',
        ipNumber: '',
        userMobileNumber: ''
      },
      filters: {
        status: ""
      }
    })
    props.get_insurance_bookings({ ...tableParams,
      page: 1,
      searchParams: {
        userName: '',
        alNumber: '',
        ipNumber: '',
        userMobileNumber: ''
      },
      filters: {
        status: ""
      }
    })
  }

  const getColumnSearchProps = (dataIndex, searchProp) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handle_reset(clearFilters, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => state.searchInput.select(), 100);
      }
    },
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleChange = (pagination, filters, sorter) => {
    let state_filters = {...tableParams.filters}
    let updated_filters = {}

   //  updated_filters.city = filters.city
    updated_filters.status = filters.status
   if(!!((pagination.current === tableParams.page) && (pagination.pageSize === tableParams.limit))){
     if(objectEquals(state_filters, updated_filters)){
       console.log("Objects are equal")
     }else{
       setTableParams({...tableParams, page:1, filters :{
        status:filters.status?filters.status:'',
       }})
       props.get_insurance_bookings({...tableParams, page:1, filters :{
         status:filters.status?filters.status:'', 
      }})
     }
   }
  };

  const  getColumnDateSearchProps = (dataIndex,placeholder, searchProp, type) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          ref={node => {
            state.searchDate = node;
          }}
          style={{ margin: "auto", width: "100%"}}
          onChange={(e) => {
            if(e) {
              let dateFilter = [e[0].format("YYYY-MM-DD") + "," + e[1].format("YYYY-MM-DD")]
              setSelectedDate(e[0].format("DD/MM/YYYY") + "," + e[1].format("DD/MM/YYYY"))
              setSelectedKeys(dateFilter)
            }
          }}
          format={dateFormat}
        />
        <Row
          gutter={12}
          justify="center"
          style={{ margin: "auto", width: "100%", marginTop: 8, display: 'flex', justifyContent: 'center' }}
        >
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: "100%" }}
            >
              Search
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: "100%" }}>
              Reset
            </Button>
          </Col>
        </Row>
      </div>
    ),
  });
  

  const columns = [
    // {
    //   title: 'Add Session',
    //   dataIndex: 'addSession',
    //   key: 'addSession',
    //   width: "1%",
    //   render: (text, record) => {
    //     return <button className="edit-session-button" onClick={() => {
    //         setSelectedBooking({
    //           bookingId: record._id,
    //           alNumber: record.alNumber,
    //           ipNumber: record.ipNumber
    //         })
    //         setAddSession(true)
    //     }}>Add</button>
    //   }
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', 'userName'),
      width: "2%",
      render: text => <span onClick={() => set_is_name_clicked(true)} style={{ width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }}>{text}</span>,
    },
    {
      title: 'AL Number',
      dataIndex: 'alNumber',
      key: 'alNumber',
      ...getColumnSearchProps('alNumber', 'alNumber'),
      width: "2%",
      render: text => <span onClick={() => set_is_name_clicked(true)} style={{ width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }}>{text}</span>,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      ...getColumnSearchProps("mobileNumber", "userMobileNumber"),
      width: "1%",
      render: text => <span onClick={() => set_is_name_clicked(true)} style={{ width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }}>{text}</span>,
    },
    {
      title: 'IP Number',
      dataIndex: 'ipNumber',
      key: 'ipNumber',
      ...getColumnSearchProps("ipNumber", "ipNumber"),
      width: "1%",
      render: text => <span onClick={() => set_is_name_clicked(true)} style={{ width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }}>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: "1%",
      filters: [
        {
          text: 'UNDER PROCESS',
          value: 'UNDER PROCESS',
        },
        {
          text: "INITIAL APPROVED",
          value: "INITIAL APPROVED"
        },
        {
          text: 'QUERIED',
          value: 'QUERIED',
        },
        {
          text: "QUERY RECIEVED",
          value: "QUERY RECIEVED"
        },
        {
          text: 'FINAL APPROVED',
          value: 'FINAL APPROVED',
        },
        {
          text: "REJECTED",
          value: "REJECTED"
        },
      ],
      onChange :(a,b,c)=>console.log(a,b,c,"StatusChange"),
      render:(item, record) => {
        return <div className="u-align-center">
            <Select value={record.status} style={{ width: "80%", margin: "0 1rem" }} onChange={(val)=> updatePreAuthStatus(record._id, val)}>
              <Option disabled={record.status==="UNDER PROCESS"} value="UNDER PROCESS">UNDER PROCESS</Option>
              <Option disabled={record.status==="INITIAL APPROVED"} value="INITIAL APPROVED">INITIAL APPROVED</Option>
              <Option disabled={record.status==="QUERIED"} value="QUERIED">QUERIED</Option>
              <Option disabled={record.status==="QUERY RECIEVED"} value="QUERY RECIEVED">QUERY RECIEVED</Option>
              <Option disabled={record.status==="FINAL APPROVED"} value="FINAL APPROVED">FINAL APPROVED</Option>
              <Option disabled={record.status==="REJECTED"} value="REJECTED">REJECTED</Option>
            </Select>
        </div>
      }
    },
    {
      title: 'Patient Status',
      dataIndex: 'patientStatus',
      key: 'patientStatus',
      width: "1%",
      render:(item, record) => {
        return <div className="u-align-center">
            <Select value={record.patientStatus} style={{ width: "80%", margin: "0 1rem" }} onChange={(val)=> updatePatientStatus(record._id, val)}>
              <Option disabled={record.patientStatus==="Pending"} value="Pending">Pending</Option>
              <Option disabled={record.patientStatus==="Admitted"} value="Admitted">Admitted</Option>
              <Option disabled={record.patientStatus==="Discharged"} value="Discharged">Discharged</Option>
            </Select>
        </div>
      }
    },
    {
      title: 'Card Number',
      dataIndex: 'cardNumber',
      key: 'cardNumber',
      width: "2%",
      render: text => <span onClick={() => set_is_name_clicked(true)} style={{ width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }}>{text}</span>,
    },
    // {
    //   title: 'Query Documents',
    //   dataIndex: 'queryDoc',
    //   key: 'queryDoc',
    //   width: "2%",
    //   align: "center",
    //   render: (docs, record) => <Button className="edit-session-button" onClick={(e) => {
    //     e.preventDefault()
    //     setQueryDocuments({
    //       _id: record._id,
    //       queryUrl: docs && docs.queryUrl ? docs.queryUrl : null,
    //       queryResponseUrl: docs && docs.queryResponseUrl ? docs.queryResponseUrl : null
    //     })
    //     setQueryDocFlag(true)
    //   }}>Open</Button>
    // },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: "2%",
      ...getColumnDateSearchProps('date', "Search dateda", 'date', 'date'),
      render: text => <span onClick={() => set_is_name_clicked(true)} style={{ width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }}>{text}</span>,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: "2%",
      render: text => <span onClick={() => set_is_name_clicked(true)} style={{ width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }}>{text}</span>,
    },
    // {
    //   title: 'Net Sanctioned Amount(Rs)',
    //   dataIndex: 'netSanctionedAmount',
    //   key: 'netSanctionedAmount',
    //   width: "3%",
    //   render: text => <span>{text}</span>,
    // },
    // {
    //   title: 'Payment Status',
    //   dataIndex: 'paymentStatus',
    //   key: 'paymentStatus',
    //   width: "3%",
    //   render:(item, record) => {
    //     return <div className="u-align-center">
    //         <Select value={record.paymentStatus} style={{ width: "80%", margin: "0 1rem" }} onChange={(val)=> updatePaymentStatus(record._id, val)}>
    //           <Option disabled={record.paymentStatus==="PENDING"} value="PENDING">PENDING</Option>
    //           <Option disabled={record.paymentStatus==="SUCCESSFUL"} value="SUCCESSFUL">SUCCESSFUL</Option>
    //         </Select>
    //     </div>
    //   }
    // },
    // {
    //   title: 'Documents',
    //   dataIndex: 'documents',
    //   key: 'documents',
    //   width: "3%",
    //   align: "center",
    //   render: (docs, record) => record.haveInsurance ? <Button className="edit-session-button" onClick={(e) => {
    //     e.preventDefault()
    //     let insCard = []
    //     if(docs.insuranceCard && docs.insuranceCard.length > 0) {
    //       docs.insuranceCard.map((card) => {
    //         insCard.push({
    //           imageUrl: card
    //         })
    //       })
    //       docs.insuranceCard = insCard
    //     }
    //     setInsuranceDocuments(docs)
    //     setDocModalFlag(true)
    //   }}>View Documents</Button> : "N/A"
    // },
    // {
    //   title: 'Rent Details',
    //   dataIndex: 'rentDetails',
    //   key: 'rentDetails',
    //   width: "3%",
    //   align: "center",
    //   render: (docs, record) => {return record.paymentDetails ? <Button className="edit-session-button" onClick={(e) => {
    //     e.preventDefault()
    //     setSelectedBooking({
    //       bookingId: record._id,
    //       roomRent: record.paymentDetails.roomRent,
    //       iCURent: record.paymentDetails.iCURent,
    //       dod: record.dod,
    //       doa: record.doa,
    //       nursingCharges: record.paymentDetails.nursingCharges,
    //       superSpecialityCharges: record.paymentDetails.superSpecialityCharges,
    //       cvc: record.paymentDetails.cvc,
    //       soa: record.paymentDetails.soa,
    //       others: record.paymentDetails.others,
    //       estimatedLengthOfStay: record.paymentDetails.estimatedLengthOfStay,
    //       totalBillAmount: record.paymentDetails.totalBillAmount,
    //       remarks: record.remarks
    //     })
    //     setRentDetailsFlag(true)
    //   }}>View Details</Button> : "N/A"},
    // },
    // {
    //   title: 'AL Letter',
    //   dataIndex: 'alLetterUrl',
    //   key: 'alLetterUrl',
    //   width: "3%",
    //   render: (text) => text ? <a href={text}>Download AL Letter</a> : "Not Updated Yet",
    // },
    // {
    //   title: 'Actions',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: "3%",
    //   render: (text, record) => <span><button style={{display: "block", width: "80%", margin: "auto"}} onClick={() => {
    //     setAlLetterDetails(record)
    //     setAlLetterModal(true)
    //   }} className="booking-info view-details-btn">AL Letter</button></span>,
    // },
    // {
    //   title: 'Discharge Documents',
    //   dataIndex: 'dischargeDoc',
    //   key: 'dischargeDoc',
    //   width: "3%",
    //   render: text => text && text.docUploaded ? <a href={text.docURL}>Download</a> : "N/A"
    // }
  ];

  const [data, setData] = useState([])

  useEffect(()=>{
    props.get_insurance_bookings(tableParams)
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  function showTotal(total) {
    return `Total ${total} items`;
  }

  useEffect(()=>{
    if(props.get_insurance_bookings_ret) {
      if(props.get_insurance_bookings_ret.success) {
        let tableData = []
        props.get_insurance_bookings_ret.data.data.map((booking) => {
          let obj = {
            _id: booking._id,
            time: dayjs(booking.createdAt).format(timeFormat),
            date: dayjs(booking.createdAt).format(dateFormat),
            name: booking.userName,
            mobileNumber: booking.userMobileNumber,
            finalAmount: booking.price,
            approvedAmount: booking.paymentDetails ? booking.paymentDetails.approvedAmount : "N/A",
            alNumber: booking.alNumber ? booking.alNumber : "Not Updated Yet",
            netSanctionedAmount: booking.netSanctionedAmount ? booking.netSanctionedAmount : "Not Updated Yet",
            status:  booking.preAuthStatus,
            paymentDetails: booking.paymentDetails,
            patientStatus: booking.patientStatus,
            paymentStatus: booking.paymentDetails ? booking.paymentDetails.paymentStatus : "N/A",
            cardNumber: booking.cardNumber,
            dischargeDoc: booking.dischargeDoc,
            ipNumber: booking.ipNumber,
            dod: booking.dod,
            doa: booking.doa,
            queryDoc: booking.queryDoc,
            haveInsurance: booking.haveInsurance,
            documents: booking.insuranceDetails,
            remarks: booking.remarks,
            utr: booking.utr,
            alLetterUrl: booking.alLetterUrl,
            alLetterDetails: booking.alLetterDetails,
            hospitalName: booking.professionalName,
            hospitalAddress: booking.professionalAddress,
            hospitalNumber: booking.professionalMobileNumber,
            policyNumber: booking.insuranceDetails ? booking.insuranceDetails.policyNumber : "N/A"
          }
          tableData.push(obj)
        })

        setTableParams({...tableParams, total: props.get_insurance_bookings_ret.data.count})
        setData(tableData)
        scrollToTop()
      } else {
        openNotificationWithIcon()
      }
      props.get_insurance_bookings_loading()
    }
  },[props.get_insurance_bookings_ret])


  useEffect(()=>{
    if(props.update_booking_ret) {
      if(props.update_booking_ret.success) {
        const resBooking =  props.update_booking_ret.data
        let tempData = data
        tempData.map((booking) => {
          if(booking._id == resBooking._id) {
            booking.paymentDetails = resBooking.paymentDetails
            booking.alNumber = resBooking.alNumber ? resBooking.alNumber : "Not Updated Yet"
            booking.ipNumber = resBooking.ipNumber
            booking.patientStatus = resBooking.patientStatus
            booking.cardNumber = resBooking.cardNumber
            booking.status = resBooking.preAuthStatus
            booking.dod = resBooking.dod
            booking.doa = resBooking.doa
            if(resBooking.paymentDetails && resBooking.paymentDetails.paymentStatus) {
              booking.paymentStatus = resBooking.paymentDetails.paymentStatus
            }
            if(resBooking.paymentDetails && resBooking.paymentDetails.approvedAmount) {
              booking.approvedAmount = resBooking.paymentDetails.approvedAmount
            }
            }
          })
          setData(tempData)
          if(rentDetailsFlag) {
            notification["success"]({
              message: 'Success',
              description:
                'Rent details updated',
            });   
            setRentDetailsFlag(false)          
          } else {
            // notification["success"]({
            //   message: 'Success',
            //   description:
            //       'Booking Updated',
            //   });

              props.update_booking_loading()
          }
      }
    }
  },[props.update_booking_ret])


  useEffect(()=>{
    if(props.add_booking_session_ret) {
      if(props.add_booking_session_ret.success) {
        const resBooking =  props.add_booking_session_ret.data
        let tempData = data
        tempData.map((booking) => {
          if(booking._id == resBooking._id) {
            booking.sessions = resBooking.sessions
            booking.alNumber = resBooking.alNumber ? resBooking.alNumber : "Not Updated Yet"
            booking.ipNumber = resBooking.ipNumber
          }
        })
        setData(tempData)
      } else {
        notification["error"]({
          message: 'Could not add Session',
          description:
            'Please try again later',
        }); 
      }
      setAddSession(false)
      setSelectedBooking(false)
      props.add_booking_session_loading()
    }
  },[props.add_booking_session_ret])

  const openNotificationWithIcon = () => {
    notification["error"]({
      message: 'Could not fetch details',
      description:
        'Please try again later',
    });
  };

  const page_change = (a, b) => {
      setTableParams({...tableParams, page:a, limit:b})
      props.get_insurance_bookings({...tableParams, page:a, limit:b})
  }

  const addNewSession = () => {
    let flag = true
    if(!selectedBooking.ipNumber) {
      notification["error"]({
        message: 'Invalid Data',
        description:
          'IP Number Cannot be empty',
      });
      flag = false
    }
    else if(!selectedBooking.cardNumber) {
      notification["error"]({
        message: 'Invalid Data',
        description:
          'Card Number Cannot be empty',
      });
      flag = false
    }
    else if(!selectedBooking.doa) {
      notification["error"]({
        message: 'Invalid Data',
        description:
          'Date of Arrival Cannot be empty',
      });
      flag = false
    }
    else if(!selectedBooking.dod) {
      notification["error"]({
        message: 'Invalid Data',
        description:
          'Date of Discharge Cannot be empty',
      });
      flag = false
    }
    else if(dayjs(selectedBooking.dod).isBefore(dayjs(selectedBooking.doa))) {
      notification["error"]({
        message: 'Invalid Data',
        description:
          'Date of Arrival Cannot be before Date of Discharge',
      });
      flag = false
    }
    else if(!selectedBooking.requestedAmount) {
      notification["error"]({
        message: 'Invalid Data',
        description:
          'Requested amount Cannot be empty',
      });
      flag = false
    }
    else if(selectedBooking.requestedAmount < 0) {
      notification["error"]({
        message: 'Invalid Data',
        description:
          'Requested amount Cannot be negetive',
      });
      flag = false
    }

    if(flag)
      props.add_booking_session(selectedBooking)
  }

  const expand = (record,a,b) => {
    console.log(record,a,b,"record,a,b")
    if(expandedRowKeys[0]!== a._id){
      setExpandRowKeys([a._id])
    }else{
    setExpandRowKeys([])
    }
  }

  const updatePaymentDetails = () => {
    props.update_booking({
      ...selectedBooking,
      paymentDetails: {
        totalBillAmount: selectedBooking.totalBillAmount,
        roomRent: selectedBooking.roomRent,
        iCURent: selectedBooking.iCURent,
        nursingCharges: selectedBooking.nursingCharges,
        superSpecialityCharges: selectedBooking.superSpecialityCharges,
        cvc: selectedBooking.cvc,
        soa: selectedBooking.soa,
        others: selectedBooking.others
      }
    })
  }

  const initialProps = {
    name: 'file',
    action: `${get_url()}/booking/uploadAuthLetter?status=INITIAL&bookingId=${alLetterDetails._id}`,
    accept: "application/pdf",
    headers: {
      authorization: `Bearer ${localStorage.getItem('token') }`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const resBooking = info.file.response.data
        let tempData = data
        tempData.map((booking) => {
          if(booking._id == resBooking.bookingId) {
            booking.alLetterDetails = resBooking.alLetterDetails
          }
        })
        setData(tempData)
        message.success(`Initial AL Letter uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Initial AL Letter upload failed.`);
      }
    },
  };

  const finalProps = {
    name: 'file',
    action: `${get_url()}/booking/uploadAuthLetter?status=FINAL&bookingId=${alLetterDetails._id}`,
    accept: "application/pdf",
    headers: {
      authorization: `Bearer ${localStorage.getItem('token') }`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const resBooking = info.file.response.data
        let tempData = data
        tempData.map((booking) => {
          if(booking._id == resBooking.bookingId) {
            booking.alLetterDetails = resBooking.alLetterDetails
          }
        })
        setData(tempData)
        message.success(`Final AL Letter uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Final AL Letter upload failed.`);
      }
    },
  };

  const queryProps = {
    name: 'file',
    action: `${get_url()}/booking/uploadBookingQueryDoc?bookingId=${queryDocuments._id}`,
    accept: "application/pdf",
    headers: {
      authorization: `Bearer ${localStorage.getItem('token') }`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const resBooking = info.file.response.data
        let tempData = data
        tempData.map((booking) => {
          if(booking._id == resBooking.bookingId) {
            booking.queryDoc = resBooking.queryDoc
            setQueryDocuments({...queryDocuments, queryResponseUrl: resBooking.queryDoc.queryResponseUrl})
          }
        })
        setData(tempData)
        message.success(`Query Document uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Query Document upload failed.`);
      }
    },
  };

  const uploadAlLetter = async () => {
    setLoading(true)
    try {
      const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
      const alLetterRes = await axios.post(`${get_url()}/booking/getAuthLetter?bookingId=${alLetterDetails._id}`, alLetterDetails, headers)
      if(!!alLetterRes){
        if (alLetterRes.status === 200) {
          notification["success"]({
            message: 'Success',
            description:
              'AL Letter Generated',
          });
          const resBooking = alLetterRes.data.data
          let tempData = data
          tempData.map((booking) => {
            if(booking._id == resBooking.bookingId) {
              booking.alLetterUrl = resBooking.letterUrl
            }
          })
          setData(tempData)
          setLoading(false)
          setAlLetterModal(false)
          setAlLetterDetails(null)
          }else{
            notification["error"]({
              message: 'Error',
              description:
                'AL Letter not generated',
            });
          }
      }
    } catch (error) {
      setLoading(false)
      notification["error"]({
        message: 'Error',
        description:
          'AL Letter not generated',
      });
    }
  }

  const createAlLetter = async () => {
    setLoading(true)
    try {
      const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
      const alLetterRes = await axios.post(`${get_url()}/booking/getAuthLetter?bookingId=${alLetterDetails._id}`, alLetterDetails, headers)
      if(!!alLetterRes){
        if (alLetterRes.status === 200) {
          notification["success"]({
            message: 'Success',
            description:
              'AL Letter Generated',
          });
          const resBooking = alLetterRes.data.data
          let tempData = data
          tempData.map((booking) => {
            if(booking._id == resBooking.bookingId) {
              booking.alLetterUrl = resBooking.letterUrl
            }
          })
          setData(tempData)
          setLoading(false)
          setAlLetterModal(false)
          setAlLetterDetails(null)
          }else{
            notification["error"]({
              message: 'Error',
              description:
                'AL Letter not generated',
            });
          }
      }
    } catch (error) {
      setLoading(false)
      notification["error"]({
        message: 'Error',
        description:
          'AL Letter not generated',
      });
    }
  }

  const row_click = (r) => {
    set_booking_selected(r._id)
  }

  useEffect(() => {
    if (is_name_clicked) {
      row_click(selected_row)
      setTimeout(() => {
        set_is_name_clicked(false)
      }, 500)
    }
  }, [is_name_clicked, selected_row])

  if (!!booking_selected) {
    return <Redirect to={`/dashboard/insurance-bookings?booking_id=${booking_selected}`}></Redirect>
  }
  
    return (
      <>
      {/* <InsurancePDF /> */}
        <Card title="Bookings">
          <Layout className="site-layout">
            <div className="patient-table-container">
              <Table scroll={{ x: 2500 }} columns={columns} dataSource={data}
                loading={props.get_insurance_bookings_loading_flag || props.update_booking_session_loading_flag || props.add_booking_session_loading_flag || props.update_booking_loading_flag || loading}
                // expandable={{ columnWidth: "1%"}}
                // expandedRowKeys={expandedRowKeys}
                // onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onChange={handleChange}
                // onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record._id}
                onRow={(r) => ({
                  onClick: (b,a) => set_selected_row(r),
                })}
              // expandedRowRender= {record => <div key={record._id}>
              //       <BookingDetails
              //         alNumber={record.alNumber}
              //         finalAmount={record.finalAmount}
              //         cardNumber={record.cardNumber}
              //         ipNumber={record.ipNumber}
              //         doa={record.doa} 
              //         dod={record.dod} 
              //         preAuthStatus={record.status}
              //         paymentDetails={record.paymentDetails} 

              //         bookingId={record._id}

              //         get_insurance_bookings_loading_flag = {props.get_insurance_bookings_loading_flag}

              //         update_booking = {props.update_booking}
              //         update_booking_ret = {props.update_booking_ret}
              //         update_booking_loading = {props.update_booking_loading}
              //         update_booking_loading_flag = {props.update_booking_loading_flag}

              //         update_booking_session = {props.update_booking_session}
              //         update_booking_session_ret = {props.update_booking_session_ret}
              //         update_booking_session_loading = {props.update_booking_session_loading}
              //         update_booking_session_loading_flag = {props.update_booking_session_loading_flag}

              //       />
              //     </div>
              //   }
                pagination = {{
                  // showSizeChanger:true,
                  limit:tableParams.limit,
                  onChange:(a,c)=>page_change(a,c),
                  defaultCurrent:tableParams.page,
                  total:tableParams.total,
                  showTotal:showTotal
                }} 
              />
              <Modal
                  title="Add Session"
                  open={addSession}
                  onCancel={() => {
                      setSelectedBooking(null)
                      setAddSession(false)
                  }}
                  onOk={() => addNewSession()}
              >
                  {selectedBooking && <>
                      <div className="row insurance-booking-session">
                          <div className="col-6">
                              <label className="session-label">AL Number</label>
                              <Input value={selectedBooking.alNumber} disabled={true} />
                          </div>
                      </div>
                      <div className="row insurance-booking-session">
                          <div className="col-6">
                              <label className="session-label">IP Number</label>
                              <Input value={selectedBooking.ipNumber} onChange={(e) => setSelectedBooking({...selectedBooking, ipNumber: e.target.value})} />
                          </div>
                          <div className="col-6">
                              <label className="session-label">Card Number</label>
                              <Input value={selectedBooking.cardNumber} onChange={(e) => setSelectedBooking({...selectedBooking, cardNumber: e.target.value})} />
                          </div>
                      </div>
                      <div className="row insurance-booking-session">
                          <div className="col-6">
                              <label className="session-label">Expected DOA</label>
                              <DatePicker disabledDate={(current) => current.isBefore(dayjs(dayjs(), dateFormat))} onChange={(date) => setSelectedBooking({...selectedBooking, doa: date.format(dateFormat)})} format={dateFormat} />
                          </div>
                          <div className="col-6">
                              <label className="session-label">Date of Discharge</label>
                              <DatePicker disabledDate={(current) => {
                                return selectedBooking.doa ?
                                  current.isBefore(dayjs(getDateString(selectedBooking.doa), dateFormat))
                                : current.isBefore(dayjs(dayjs(), dateFormat))
                              }} onChange={(date) => setSelectedBooking({...selectedBooking, dod: date.format(dateFormat)})} format={dateFormat} />
                          </div>
                      </div>
                      <div className="row insurance-booking-session">
                          <div className="col-6">
                              <label className="session-label">Requested Amount</label>
                              <Input type="number" value={selectedBooking.requestedAmount} onChange={(e) => setSelectedBooking({...selectedBooking, requestedAmount: e.target.value})}  />
                          </div>
                      </div>
                  </>}
              </Modal>
              <Modal
                  title="Rent Details:"
                  open={rentDetailsFlag}
                  width={"100%"}
                  onCancel={() => {
                      setSelectedBooking(null)
                      setRentDetailsFlag(false)
                  }}
                  onOk={() => updatePaymentDetails()}
              >
                  {selectedBooking && <div className="container">
                      <div className="row insurance-booking-session">
                          <div className="col-3">
                              <label className="session-label">Total Bill Amount</label>
                              <Input value={selectedBooking.totalBillAmount} onChange={(e) => setSelectedBooking({...selectedBooking, totalBillAmount: e.target.value})} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">Estimated Lenght of Stay</label>
                              <Input value={selectedBooking.estimatedLengthOfStay} onChange={(e) => setSelectedBooking({...selectedBooking, estimatedLengthOfStay: e.target.value})} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">Expected DOA</label>
                              <DatePicker defaultValue={selectedBooking.doa && dayjs(getDateString(selectedBooking.doa), dateFormat)} disabledDate={(current) => current.isBefore(dayjs(dayjs(), dateFormat))} onChange={(date) => setSelectedBooking({...selectedBooking, doa: date.format(dateFormat)})} format={dateFormat} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">Date of Discharge</label>
                                <DatePicker defaultValue={selectedBooking.dod && dayjs(getDateString(selectedBooking.dod), dateFormat)} disabledDate={(current) => {
                                  return selectedBooking.doa ?
                                    current.isBefore(dayjs(getDateString(selectedBooking.doa), dateFormat))
                                  : current.isBefore(dayjs(dayjs(), dateFormat))
                                }} onChange={(date) => setSelectedBooking({...selectedBooking, dod: date.format(dateFormat)})} format={dateFormat} />
                          </div>
                      </div>
                      <div className="row insurance-booking-session">
                          <div className="col-3">
                              <label className="session-label">Room Rent</label>
                              <Input value={selectedBooking.roomRent} onChange={(e) => setSelectedBooking({...selectedBooking, roomRent: e.target.value})} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">ICU Rent</label>
                              <Input value={selectedBooking.iCURent} onChange={(e) => setSelectedBooking({...selectedBooking, iCURent: e.target.value})} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">Nursing Charges</label>
                              <Input value={selectedBooking.nursingCharges} onChange={(e) => setSelectedBooking({...selectedBooking, nursingCharges: e.target.value})} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">Consultant Visit Charges</label>
                              <Input value={selectedBooking.cvc} onChange={(e) => setSelectedBooking({...selectedBooking, cvc: e.target.value})} />
                          </div>
                      </div>
                      <div className="row insurance-booking-session">
                          <div className="col-6">
                              <label className="session-label">Others</label>
                              <Input value={selectedBooking.others} onChange={(e) => setSelectedBooking({...selectedBooking, others: e.target.value})} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">Super Specialist Charges</label>
                              <Input value={selectedBooking.superSpecialityCharges} onChange={(e) => setSelectedBooking({...selectedBooking, superSpecialityCharges: e.target.value})} />
                          </div>
                          <div className="col-3">
                              <label className="session-label">Surgeon Fee/OT/ Anaestheist</label>
                              <Input value={selectedBooking.soa} onChange={(e) => setSelectedBooking({...selectedBooking, soa: e.target.value})} />
                          </div>
                      </div>
                      <div className="row align-items-center">
                          <div className="col-6">
                              <label className="session-label">Remarks</label>
                              <TextArea rows={5} type='textarea' value={selectedBooking.remarks} onChange={(e) => setSelectedBooking({...selectedBooking, remarks: e.target.value})} />
                          </div>
                      </div>
                  </div>}
              </Modal>
              <Modal
                  title="Insurance Documents"
                  closeIcon={false}
                  open={docModalFlag}
                  footer={[
                    <Button key='submit' type='primary' onClick={() => setDocModalFlag(false)}>
                      Okay
                    </Button>
                  ]}
              >
                <div className="row insurance-booking-session">
                  <div className="col-6">
                    <label className="policy-label">Policy Number</label>
                    <span className="policy-text">{insuranceDocuments.policyNumber}</span>
                  </div>
                  <div className="col-6">
                    <label className="policy-label">Insurance Partner</label>
                    <span className="policy-text">{insuranceDocuments.insurancePartner}</span>
                  </div>
                </div>
                <div className="row insurance-booking-session">
                  {insuranceDocuments.insurancePolicy && <div className="col-6">
                    <label className="policy-label">Insurance PDF</label>
                    <a className="edit-session-button policy" href={insuranceDocuments.insurancePolicy}>Download</a>
                  </div>}
                  {/* {insuranceDocuments.insuranceCard && insuranceDocuments.insuranceCard.length > 0 && <div className="col-6">
                    <label className="policy-label">Insurance Card</label>
                    <PhotoGallery id="insurance-card" data={insuranceDocuments.insuranceCard} />
                  </div>}  */}
                </div>
              </Modal>
              <Modal
                  title="Upload AL Letters"
                  open={alLetterModal}
                  width={"70%"}
                  onOk={() => setAlLetterModal(false)}
                  onCancel={() => setAlLetterModal(false)}
              >
                <Row justify="center" gutter={24}>
                  <Col span={8}>
                    <label className="session-label">Initial AL Letter</label>
                    {alLetterDetails.alLetterDetails && alLetterDetails.alLetterDetails.initialLetter && <label style={{margin: "1rem 0"}} className="policy-label"><a href={alLetterDetails.alLetterDetails.initialLetter}>Download Initial Letter</a></label> }
                    <Upload {...initialProps}>
                      <Button icon={<UploadOutlined />}>{alLetterDetails.alLetterDetails && alLetterDetails.alLetterDetails.initialLetter ? "Update " : "Upload "} Initial AL Letter</Button>
                    </Upload>
                  </Col>
                  <Col span={8}>
                    <label className="session-label">Final AL Letter</label>
                    {alLetterDetails.alLetterDetails && alLetterDetails.alLetterDetails.finalLetter && <label style={{margin: "1rem 0"}} className="policy-label"><a href={alLetterDetails.alLetterDetails.finalLetter}>Download Final Letter</a></label>}
                    <Upload {...finalProps}>
                      <Button icon={<UploadOutlined />}>{alLetterDetails.alLetterDetails && alLetterDetails.alLetterDetails.finalLetter ? "Update " : "Upload "} Final AL Letter</Button>
                    </Upload>
                  </Col>
                </Row>
              </Modal>
              <Modal
                  title="Query Document"
                  open={queryDocFlag}
                  width={"70%"}
                  onOk={() => setQueryDocFlag(false)}
                  onCancel={() => setQueryDocFlag(false)}
              >
                <Row justify="space-around" gutter={24}>
                  <Col span={8}>
                    <label className="policy-label"><b>Submit Query </b></label>
                    <Upload {...queryProps}>
                      <Button icon={<UploadOutlined />}>{queryDocuments.queryUrl ? "Update " : "Upload "} Query Document</Button>
                    </Upload>
                    {queryDocuments && queryDocuments.queryUrl && <label style={{margin: "1.25rem 0"}} className="policy-label"><a href={queryDocuments.queryUrl}>Download Uploaded Document</a></label> }
                  </Col>
                  <Col span={8}>
                    <label className="policy-label"><b>Query Response</b></label>
                    {queryDocuments && queryDocuments.queryResponseUrl ? <label style={{margin: "1.25rem 0"}} className="policy-label"><a href={queryDocuments.queryResponseUrl}>Download Query Response</a></label> : <label className="policy-label">No Response Received</label> }
                  </Col>
                </Row>
              </Modal>
              {/* <Modal
                  title="Create AL Letter"
                  open={alLetterModal}
                  width={"80%"}
                  onOk={() => createAlLetter()}
                  onCancel={() => setAlLetterModal(false)}
              >
              {alLetterDetails && <> 
                  <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">AL Approval Date</label>
                        <DatePicker onChange={(date) => setAlLetterDetails({...alLetterDetails, alApprovalDate: date.format(dateFormat)})} format={dateFormat} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">AL Requested Date</label>
                        <DatePicker onChange={(date) => setAlLetterDetails({...alLetterDetails, alRequestedDate: date.format(dateFormat)})} format={dateFormat} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Hospital Name</label>
                        <Input value={alLetterDetails.hospitalName} onChange={(e) => setAlLetterDetails({...alLetterDetails, hospitalName: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Hospital Address</label>
                        <Input value={alLetterDetails.hospitalAddress} onChange={(e) => setAlLetterDetails({...alLetterDetails, hospitalAddress: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Phone Number</label>
                        <Input value={alLetterDetails.hospitalNumber} onChange={(e) => setAlLetterDetails({...alLetterDetails, hospitalNumber: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Rohini ID</label>
                        <Input value={alLetterDetails.rohiniId} onChange={(e) => setAlLetterDetails({...alLetterDetails, rohiniId: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Hospital Tel</label>
                        <Input value={alLetterDetails.hospitatel} onChange={(e) => setAlLetterDetails({...alLetterDetails, hospitatel: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Hospital Address</label>
                        <Input value={alLetterDetails.hospitalAddress} onChange={(e) => setAlLetterDetails({...alLetterDetails, hospitalAddress: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Total Amount</label>
                        <Input value={alLetterDetails.totalBillAmount} onChange={(e) => setAlLetterDetails({...alLetterDetails, totalBillAmount: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Amount in Words</label>
                        <Input value={alLetterDetails.amountString} onChange={(e) => setAlLetterDetails({...alLetterDetails, amountString: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Patient Name</label>
                        <Input value={alLetterDetails.name} onChange={(e) => setAlLetterDetails({...alLetterDetails, name: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">UHID Number</label>
                        <Input value={alLetterDetails.cardNumber} onChange={(e) => setAlLetterDetails({...alLetterDetails, cardNumber: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Requested Room Type</label>
                        <Input value={alLetterDetails.requestedRoomType} onChange={(e) => setAlLetterDetails({...alLetterDetails, requestedRoomType: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Class of Accomodation</label>
                        <Input value={alLetterDetails.accomodation} onChange={(e) => setAlLetterDetails({...alLetterDetails, accomodation: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Provisional Diagnosis</label>
                        <Input value={alLetterDetails.diagnosis} onChange={(e) => setAlLetterDetails({...alLetterDetails, diagnosis: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Date of Addmission</label>
                        <Input value={alLetterDetails.doa} onChange={(e) => setAlLetterDetails({...alLetterDetails, doa: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Policy Name</label>
                        <Input value={alLetterDetails.policyName} onChange={(e) => setAlLetterDetails({...alLetterDetails, policyName: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Patient IP No</label>
                        <Input value={alLetterDetails.ipNumber} onChange={(e) => setAlLetterDetails({...alLetterDetails, ipNumber: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Age</label>
                        <Input value={alLetterDetails.age} onChange={(e) => setAlLetterDetails({...alLetterDetails, age: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Gender</label>
                        <Input value={alLetterDetails.gender} onChange={(e) => setAlLetterDetails({...alLetterDetails, gender: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Policy Number</label>
                        <Input value={alLetterDetails.policyNumber} onChange={(e) => setAlLetterDetails({...alLetterDetails, policyNumber: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Date of Discharge</label>
                        <Input value={alLetterDetails.dod} onChange={(e) => setAlLetterDetails({...alLetterDetails, dod: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Estimated Length of Stay</label>
                        <Input value={alLetterDetails.stayLength} onChange={(e) => setAlLetterDetails({...alLetterDetails, stayLength: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Proposed Line of Treatment</label>
                        <Input value={alLetterDetails.lineOfTreatment} onChange={(e) => setAlLetterDetails({...alLetterDetails, lineOfTreatment: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Total Bill Amount</label>
                        <Input value={alLetterDetails.totalBillAmount} onChange={(e) => setAlLetterDetails({...alLetterDetails, totalBillAmount: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Policy Related Deductions</label>
                        <Input value={alLetterDetails.deductions} onChange={(e) => setAlLetterDetails({...alLetterDetails, deductions: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Co Pay</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.coPay : ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, coPay: e.target.value }})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Deductiblest</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.deductibles: ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, deductibles: e.target.value }})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Sub Limit</label>
                        <Input value={alLetterDetails.sublimit} onChange={(e) => setAlLetterDetails({...alLetterDetails, sublimit: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Proportionate as per Eligible Room</label>
                        <Input value={alLetterDetails.proportionate} onChange={(e) => setAlLetterDetails({...alLetterDetails, proportionate: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Non Medical Expenses</label>
                        <Input value={alLetterDetails.nonMedocalExpenses} onChange={(e) => setAlLetterDetails({...alLetterDetails, nonMedocalExpenses: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Others</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.others: ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, others: e.target.value }})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                        <label className="session-label">Network Related Deductions</label>
                        <Input value={alLetterDetails.networkDeductions} onChange={(e) => setAlLetterDetails({...alLetterDetails, networkDeductions: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Hospital Terrif Deductions</label>
                        <Input value={alLetterDetails.hospitalTerrif} onChange={(e) => setAlLetterDetails({...alLetterDetails, hospitalTerrif: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Discount</label>
                        <Input value={alLetterDetails.discounts} onChange={(e) => setAlLetterDetails({...alLetterDetails, discounts: e.target.value})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Amount to be paid by Insured</label>
                        <Input value={alLetterDetails.amountByInsured} onChange={(e) => setAlLetterDetails({...alLetterDetails, amountByInsured: e.target.value })} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                <div className="col-4">
                        <label className="session-label">Inital Approval Date</label>
                        <DatePicker onChange={(date) => setAlLetterDetails({...alLetterDetails, initialApprovalDate: date.format(dateFormat)})} format={dateFormat} />
                    </div>
                    <div className="col-4">
                        <label className="session-label">Initial Sanctioned Amount</label>
                        <Input value={alLetterDetails.initialApprovalAmount} onChange={(e) => setAlLetterDetails({...alLetterDetails, initialApprovalAmount: e.target.value})} />
                    </div>
                    <div className="col-4">
                        <label className="session-label">Initial Status</label>
                        <Input value={alLetterDetails.initialApprovalStatus} onChange={(e) => setAlLetterDetails({...alLetterDetails, initialApprovalStatus: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-4">
                        <label className="session-label">Final Approval Date</label>
                        <DatePicker onChange={(date) => setAlLetterDetails({...alLetterDetails, finalApprovalDate: date.format(dateFormat)})} format={dateFormat} />
                    </div>
                    <div className="col-4">
                        <label className="session-label">Final Sanctioned Amount</label>
                        <Input value={alLetterDetails.finalApprovalAmount} onChange={(e) => setAlLetterDetails({...alLetterDetails, finalApprovalAmount: e.target.value})} />
                    </div>
                    <div className="col-4">
                        <label className="session-label">Final Status</label>
                        <Input value={alLetterDetails.finalApprovalStatus} onChange={(e) => setAlLetterDetails({...alLetterDetails, finalApprovalStatus: e.target.value})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                      <label className="session-label">Room Rent</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.roomRent : ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, roomRent: e.target.value }})} />
                    </div>
                    <div className="col-3">
                      <label className="session-label">ICU Rent</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.iCURent : ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, iCURent: e.target.value }})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Nursing Charges</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.nursingCharges : ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, nursingCharges: e.target.value }})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Consultant Visit Charges</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.cvc: ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, cvc: e.target.value }})} />
                    </div>
                </div>
                <div className="row insurance-booking-session">
                    <div className="col-3">
                      <label className="session-label">Super Specialist Charges</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.superSpecialityCharges : ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, superSpecialityCharges: e.target.value }})} />
                    </div>
                    <div className="col-3">
                      <label className="session-label">Surgeon Fee/OT/Anaestheist</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.soa : ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, soa: e.target.value }})} />
                    </div>
                    <div className="col-3">
                        <label className="session-label">Others(specify)</label>
                        <Input value={alLetterDetails.paymentDetails ? alLetterDetails.paymentDetails.others : ""} onChange={(e) => setAlLetterDetails({...alLetterDetails, paymentDetails : {...alLetterDetails.paymentDetails, others: e.target.value }})} />
                    </div>
                </div>
              </>}
              </Modal> */}
            </div>
          </Layout>
        </Card>
      </>
    )
}

export default BookingTable