
import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import {   GLOBAL_ERROR , GET_SPECIALITIES, GET_SERVICES,GET_AGENT_LIST, GET_SERVICE_FAMILIES} from '../Constants'
import { global_error_ret, global_error_clr, get_specialities_ret, set_specialities, get_services_ret, set_services,get_agent_list_ret,
get_service_families_ret, set_service_families} from '../actions/global'

import api from '../utils/api_routes'
import { get_url_params } from "../utils/common_utilities"
import store from '../store'

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* global_error_saga() {

   try {
      const  data = yield store.getState().global_store.global_error
      const headers  = { 'headers': { 'Authorization': localStorage.getItem('token') } }
     
      yield put(global_error_ret({
        success:data.success,
        message:data.message,
        heading:data.heading,
        data:data.data
       }))
   } catch (e) {
    console.log(e.response,"e in global_error_saga")
   }
}



function* get_agent_list_saga() {
  console.log("inside")
   try {
      const  data = yield store.getState().global_store.get_agent_list
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
       const api_data = yield call(api.global.get_agent_list, data, headers)
   console.log(api_data,"api_data in get_agent_list_saga")
      if(!!api_data){
         if(api_data.success){
            yield put(get_agent_list_ret({
                success:true,
                message:api_data.message ||'Success in get speciallities ',
                data:[...api_data.data]
               }))
        }else{
            yield put(get_agent_list_ret({
                success:false,
                message:api_data.message || 'Unable to fetch specialities',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_agent_list_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_agent_list_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
 }



function* get_specialities_saga() {
  
  try {
     const  data = yield store.getState().global_store.get_specialities
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      const api_data = yield call(api.global.get_specialities, data, headers)
  
     if(!!api_data){
        if(api_data.status){
           yield put(set_specialities({
               success:true,
               message:api_data.message ||'Success in get speciallities ',
               data:[...api_data.data]
              }))
       }else{
           yield put(get_specialities_ret({
               success:false,
               message:api_data.message || 'Unable to fetch specialities',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_specialities_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_specialities_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}



function* get_services_saga() {
  
   try {
      const  data = yield store.getState().global_store.get_services
      const  specialities = yield store.getState().global_store.specialities
      let arr = specialities.filter((item)=>{
         return item.value === data.speciality
      })
      console.log(data,"data in data")
      // let speciality =  {
      //    speciality:arr[0].name
      // }
      let speciality = {
         speciality:data
      }
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
   const api_data = yield call(api.global.get_services, data, headers)
   console.log(api_data,"api_data in get_services saga")
      if(!!api_data){
         if(api_data.success){
            yield put(set_services({
                success:true,
                message:api_data.message ||'Success in get services',
                data:[...api_data.services]
               }))
        }else{
            yield put(get_services_ret({
                success:false,
                message:api_data.message || 'Unable to fetch services',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_services_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_services_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
 }


 function* get_service_families_saga() {
  
   try {
      const  data = yield store.getState().global_store.get_service_families
     
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`
         }
       }
       console.log("Working till here", data, headers)
       console.log(api.global,"api.global")
       const api_data = yield call(api.global.get_service_families, data, headers)
   console.log(api_data,"api_data in get_services saga")
      if(!!api_data){
         if(api_data.success){
            yield put(set_service_families({
                success:true,
                message:api_data.message ||'Success in get services',
                data:[...api_data.data]
               }))
        }else{
            yield put(get_service_families_ret({
                success:false,
                message:api_data.message || 'Unable to fetch services',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_service_families_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_service_families_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
 }

export const global_saga = [
   takeLatest(GLOBAL_ERROR, global_error_saga),
   takeLatest(GET_SPECIALITIES, get_specialities_saga),
   takeLatest(GET_SERVICES, get_services_saga),
   takeLatest(GET_SERVICE_FAMILIES, get_service_families_saga),
   takeLatest(GET_AGENT_LIST, get_agent_list_saga)
]

export default global_saga