import React from "react"
import { Table, Input, Button, Space, Card, Form, Modal, Col, Row } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat, getTime, get_time} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
import SolProfessionals from "./SolProfessionals";
import Photogallery from "../../Components/Photogallery"
// import Map from "./Map.js"


const Solutions = (props) => {
  const {form} = Form.useForm()
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [get_insights_params, set_get_insights_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{},
           filters:{}
      })

      const [report_data, set_report_data] = React.useState({
        imageUrl:[],
        videoUrl:[],
        reportUrl:[]
      })
      const [data, set_data] = React.useState([])
      const [speciality, set_speciality] = React.useState([])
      const [list, set_list] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [selected_row, set_selected_row] = React.useState({})
      const [visible, set_visible] = React.useState(false)
      const [allPricesUpdated, set_allPricesUpdated] = React.useState();

    React.useEffect(()=>{
        props.get_insights(get_insights_params)
    },[])

    React.useEffect(()=>{
      if(!!props.get_insights_ret){
        // console.log(props.get_insights_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_insights_ret.success){
          set_data(modif_data([...props.get_insights_ret.data]))
          // console.log(props.get_insights_ret.data, "all Prices updated");
          set_get_insights_params({...get_insights_params, total:props.get_insights_ret.total})
         }else{
          props.global_error({
            success:false,
            message:props.get_insights_ret.message,
            heading:'Professionals'
          })
         }
          props.get_insights_loading()
      }


      if(!!props.get_reports_byid_ret){
        // console.log(props.get_insights_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_reports_byid_ret.success){
           console.log(props.get_reports_byid_ret,"props.get_reports_byid_ret")
             set_report_data(props.get_reports_byid_ret.data)
         }else{
          props.global_error({
            success:false,
            message:props.get_reports_byid_ret.message,
            heading:'Solution Data'
          })
         }
          props.get_reports_byid_loading()
      }
  
     
         
  },[props.get_insights_ret, props.get_reports_byid_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_insights_params.filters}
       let updated_filters = {}
      
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === get_insights_params.page) && (pagination.pageSize === get_insights_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_insights_params({...get_insights_params, page:1, filters :{
            createdAt: filters.createdAt? filters.createdAt:'',
        //    level:filters.level?filters.level:'',
        //    catalogue:filters.catalogue?filters.catalogue:''
          }})
          props.get_insights({...get_insights_params, page:1, filters :{
            createdAt: filters.createdAt? filters.createdAt:'',
        //    level:filters.level?filters.level:'',
        //    catalogue:filters.catalogue?filters.catalogue:''        
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`${placeholder}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_insights_params({...get_insights_params, page:1,searchParams:{
           ...get_insights_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_insights({...get_insights_params, page:1,searchParams:{
          ...get_insights_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            booked: item.booked?"Yes":"No",
            email:item.email?item.email:'N/A',
            name: item.name,
            mobileNumber:item.mobileNumber,
            geoLocationName:item.geoLocationName,
            serviceName:item.serviceName,
            allPricesUpdated: item.allPricesUpdated,
            solutionId:item._id,
            isServiceChildren: item.isServiceChildren,
            serviceChildren: item.serviceChildren,
            createdAt:get_time(item.createdAt),
            userReportId:item.userReportId,
            date:`${getDateFormat(item.date)} ${getTime(item.date)}`,
            category:item.category,
            allPricesUpdated: item.allPricesUpdated,
            _id: item._id
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_insights_params({...get_insights_params, limit:b, page:1})
      props.get_insights({...get_insights_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_insights_params,"a and b get_insights_params")
      // if(get_insights_params.page !== a){
        if(true){
        set_get_insights_params({...get_insights_params, page:a, limit:b})
        props.get_insights({...get_insights_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_insights_params({...get_insights_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
    
        // userType:"",
        // level:"",
        // catalogue:''
      }})
      props.get_insights({...get_insights_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
   
        // userType:"",
        // level:"",
        // catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_insights_params({...get_insights_params, page:1, filters:{
       
        // userType:"",
        // catalogue:'',
        // level:''
      }})
      props.get_insights({...get_insights_params, page:1, filters:{
      
        // userType:"",
        // catalogue:'',
        // level:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }


    const expand = (record,a,b) => {
      console.log(record,a,b,"record,a,b")
      if(expandedRowKeys[0]!== a._id){
         set_expandedRowKeys([a._id])
         props.get_sol_professionals({
          solutionId:a._id,
          limit:100,
          page:1
         })
      
      }else{
      set_expandedRowKeys([])
      }
    }

    const handleAllPricesUpdated = (id) => {
      set_allPricesUpdated(id);
    }

    const submit = () => {
      // console.log('in submit:',up_data)
      // const ret_error = validate({...up_data});
      // set_error({...error, ...ret_error});
      // console.log(ret_error,"ret_error in add_member")
      // if(!error_form_check(ret_error)){
      //      props.update_sol_professional({
      //       solutionId:props.solutionId,
      //       id:selected_row.id,
      //       updatedPrice:up_data.amount,
      //       technology:up_data.tech,
      //       add_on:up_data.add_on,
      //       special_offer:up_data.sp_offer,
      //       // updatedPrice:amount
      //      })
      // }
    }

    const  handleCancel = e => {
      console.log(e);
      set_visible(false)
      set_report_data({
        imageUrl:[],
        videoUrl:[],
        reportUrl:[]
      })
      // set_up_data({amount:"",sp_offer:"",add_on:"",tech:""})
    };

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width:'10%',
          ...getColumnSearchProps('name', "Search name", 'name'),
        },
        {
          title: 'Solution id',
          dataIndex: '_id',
          key: '_id',
          width:'20%',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width:'15%',
          ...getColumnSearchProps('email', "Search email", 'email'),
        },
        {
            title: 'Service',
            dataIndex: 'serviceName',
            key: 'serviceName',
            width:'10%',
            ...getColumnSearchProps('serviceName', "Search email", 'serviceName')
           
          },
          {
            title: 'Booked',
            dataIndex: 'booked',
            key: 'booked',
            width:'10%'
            // ...getColumnSearchProps('email', "Search email", 'email'),
           
          },
        {
          title: 'Location',
          dataIndex: 'geoLocationName',
          key: 'geoLocationName',
          width:'15%'
          // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
        },
        {
          title: 'Phone',
          dataIndex: 'mobileNumber',
          key: 'mobileNumber',
          width:'15%'
          // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
        },
        {
          title: 'Created at',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width:'15%',
          filters: [
            {
              text: 'Today',
              value: '0',
            },
            {
              text: 'Yesterday',
              value: '1',
            },
            {
              text: 'Last week',
              value: '7',
            }
          ],
          // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
        },
        {
          title: 'User Details',
          key: 'action',
          width:'10%',
          render: (text, record) => (
            <Space size="middle">
              <a onClick={()=>update_click(record)}>View</a>
            </Space>
          ),
        }
      ]
      function showTotal(total) {
        return `Total ${total} items`;
      }

      const update_click = (data) => {
        set_selected_row(data)
        set_visible(true)
        props.get_reports_byid({
          userReportId:data.userReportId
        })
      }

      console.log(expandedRowKeys,"expandedRowKeys")

   if(!!professional_selected){
        return <Redirect to={`/dashboard/professionals?professional_id=${professional_selected._id}`} />
   }

        return (<React.Fragment>
            <Card title="Solutions">
            {/* <Form
      form={form}
      layout="vertical"
    >
        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput 
              label="Speciality"  
              value={speciality} 
              disabled = {props.get_bids_loading_flag}
              options = {props.global_store.specialities}
              loading={props.global_store.get_specialities_loading}
              error_text={""} 
              toggle_error = {()=>console.log()} 
              error={false}
              name="speciality" 
              type="select"
              mode="multiple"
              onChange = {event=>{
                console.log(event)
                let specialityId = ""
                event.map((item,i)=>{
                  if(i!==0){
                    specialityId = `${specialityId},${item}`
                  }else{
                    specialityId = `${specialityId}${item}`
                  }
                  
                })
                set_get_insights_params({...get_insights_params, specialityId:specialityId, page:1})
                props.get_insights({...get_insights_params, specialityId:specialityId, page:1})
                set_speciality(event)
              }} 
             
              placeholder="Choose speciality" />
        </span>
        </div>
</Form> */}
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_insights_loading_flag}
          onChange={handleChange}
          // rowClassName="cursor-pointer"
          rowClassName={(record, index)=>{
            if(record.category === "Procedure"){
              if(!!record.allPricesUpdated){
                return "green_row"
              }
              else{
                return "red_row"
              }
            }else {
              // if(record.allPricesUpdated || allPricesUpdated === record._id){
              //   // set_allPricesUpdated(false);
              //   return "green_row"
              // }
              return ""
            }
         
        }}
          pagination = {{
            showSizeChanger:true,
            limit:get_insights_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_insights_params.page,
            total:get_insights_params.total,
            showTotal:showTotal
          }}
          expandedRowKeys={expandedRowKeys}
          onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
          onExpand = {(a,b,c) =>expand(a,b,c)}
          rowKey={record => record._id}
           expandedRowRender= {record =>{
            console.log(record,"record")
            return   <div key={record._id}>
            
               <SolProfessionals
                  get_sol_professionals = {props.get_sol_professionals}
                  get_sol_professionals_ret = {props.get_sol_professionals_ret}
                  get_sol_professionals_loading = {props.get_sol_professionals_loading}
                  get_sol_professionals_loading_flag = {props.get_sol_professionals_loading_flag}

                  update_sol_professional = {props.update_sol_professional}
                  update_sol_professional_ret = {props.update_sol_professional_ret}
                  update_sol_professional_loading = {props.update_sol_professional_loading}
                  update_sol_professional_loading_flag = {props.update_sol_professional_loading_flag}

                  solutionId = {record._id}
                  handleAllPricesUpdated = {handleAllPricesUpdated}

                  category = {record.category}

                  global_error = {props.global_error}
                  global_store = {props.global_store}
               />
           </div>
          }}
         
        //   rowSelection={{
        //      selectedRowKeys:selectedRowKeys,
        //      onChange: (e,b)=>{
        //        setSelectedKeys([...e])
        //        set_selected_professionals([...b.map(item=>{
        //          return {
        //            name:item.name,
        //            _id:item._id
        //          }
        //        })])
        //      },
        //   }}
        
          />
        <Modal
          title="User Details"
          visible={visible}
          onOk={handleCancel}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={800}
        >
          {visible  &&  <div className="solution-user-details">
              <Row gutter={[20,0]}>
                {selected_row.isServiceChildren && selected_row.serviceChildren.map((serviceChild, index) => <Col>
                    <Card className="body-part-cards">
                      <Row gutter={[32, 0]}>
                        <Col className="body-part-col">
                          <span>Body Part</span><br />
                          {serviceChild.bodyPart && serviceChild.bodyPart}
                        </Col>
                        <Col className="body-col-separator" span={1}>
                          .
                        </Col>
                        <Col className="body-part-col">
                          <span>Session</span><br />
                          {serviceChild.session_grafts ? serviceChild.session_grafts : "Unavailable"}
                        </Col>
                      </Row>
                    </Card>
                  </Col>)}
              </Row>
              <div  className="informnation-wrapper-modal">
                <div  className="informnation-child-1">
                    <div className="information-heading">
                          Description
                    </div>
                    <div className="information-content">
                          {report_data.description?report_data.description:"N/A"}
                    </div>
                </div>
                <div  className="informnation-child-1">
                    <div className="information-heading">
                          Additional Details
                    </div>
                    <div className="information-content">
                    {report_data.additionalDetails?report_data.additionalDetails:"N/A"}
                    </div>
                </div>
              </div>

              <div className="padding-1rem">
                 <div className="heading-new">
                    Videos
                 </div>
                 {report_data.videoUrl.map((item,i) => {
                          return   <React.Fragment>
                               <div className="video-div">
                                 <a href={item.videoUrl} target='_blank'>
                                    {item.videoUrl}
                                  </a>
                               </div>
                          </React.Fragment>
                        })}
                        {report_data.videoUrl.length ===0 &&  <div>
                             N/A
                          </div>}
              </div>

              <div className="video-reports-wrapper">
                    <div className="video-reports-child-1">
                      <div className="heading-new">
                        Photos
                    </div>
                      <div className="photos-wrapper">
                        <Photogallery data={report_data.imageUrl} limit={1} />
                        {report_data.imageUrl.length ===0 &&  <div>
                             N/A
                          </div>}
                      </div>
                    </div>

                    <div className="video-reports-child-1">
                      <div className="heading-new">
                        Reports
                    </div>
                      <div className="photos-wrapper">
                        {report_data.reportUrl.map((item,i) => {
                          return   <React.Fragment>
                               <div className="video-div">
                               <a href={item.reportUrl} target='_blank'>
                                    {item.reportUrl}
                                </a>
                               </div>
                          </React.Fragment>
                        })}
                          {report_data.reportUrl.length ===0 &&  <div>
                             N/A
                          </div>}
                      </div>
                    </div>
              </div>
              <div  className="informnation-wrapper-modal">
                  <div  className="informnation-child-1">
                      <div className="information-heading">
                            Has Insurance?
                      </div>
                      <div className="information-content">
                           {report_data.insurance?"Yes":"No"}
                      </div>
                  </div>
              </div>
          </div>
}
        </Modal>
        </Card>
        </React.Fragment>)
}
export default Solutions