import { call, put, take, takeLatest } from 'redux-saga/effects'
import {   GET_BAR_CHART_DATA, GET_PROF_INFO, UPLOAD_CATALOGUE, CHECK_UPLOAD_STATUS,
           GET_CATALOGUE_LIST, GET_CATALOGUE_BY_ID, GET_RECORDS, UPDATE_UNMATCHED, UPDATE_PRICE_CATALOGUE, GET_PRICE_COMPARISION, GET_PRICE_COMPARISION_RET } from '../Constants'
import { get_prof_info_ret, get_bar_chart_data_ret, upload_catalogue_ret, check_upload_status_ret,
         get_catalogue_list_ret, get_catalogue_by_id_ret, get_records_ret, update_unmatched_ret,
          update_price_catalogue_ret, get_price_comparision_ret } from '../actions/catalogue_manager'
import api from '../utils/api_routes'
import store from '../store'



function* get_price_comparision_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.get_price_comparision
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     
   
     const  api_data = yield call(api.catalogue_manager.get_price_comparision, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_price_comparision_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Success',
               data:api_data.data,
               count:api_data.count
              
              }))
       }else{
           yield put(get_price_comparision_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_price_comparision_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_price_comparision_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* update_price_catalogue_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.update_price_catalogue
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     
     let professional_id  = data.professional_id

     if(!!professional_id){
       delete data.professional_id
     }
     const  api_data = yield call(api.catalogue_manager.update_price_catalogue, data, professional_id, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_price_catalogue_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Success',
               data:api_data.data,
              
              }))
       }else{
           yield put(update_price_catalogue_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_price_catalogue_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_price_catalogue_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* update_unmatched_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.update_unmatched
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.update_unmatched, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_unmatched_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Success',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(update_unmatched_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_unmatched_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_unmatched_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* get_records_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.get_records
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.get_records, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_records_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Success',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(get_records_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_records_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_records_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* get_catalogue_by_id_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.get_catalogue_by_id
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.get_catalogue_by_id, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_catalogue_by_id_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Success',
               data:api_data.data,
            
              }))
       }else{
           yield put(get_catalogue_by_id_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_catalogue_by_id_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_catalogue_by_id_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* get_catalogue_list_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.get_catalogue_list
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.get_catalogue_list, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_catalogue_list_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Success',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(get_catalogue_list_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_catalogue_list_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_catalogue_list_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* check_upload_status_saga() {

  try {
     const  data = yield store.getState().global_store.check_upload_status
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.check_upload_status, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(check_upload_status_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Success',
               data:api_data.data,
               status:api_data.status
              }))
       }else{
           yield put(check_upload_status_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(check_upload_status_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(check_upload_status_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* get_prof_info_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.get_prof_info
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.get_prof_info, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_prof_info_ret({
               success:true,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:api_data.data
              }))
       }else{
           yield put(get_prof_info_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_prof_info_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_prof_info_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* get_bar_chart_data_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.get_bar_chart_data
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.get_bar_chart_data, data, headers)

         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_bar_chart_data_ret({
               success:true,
               average:api_data.average,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:api_data.data
              }))
       }else{
           yield put(get_bar_chart_data_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_bar_chart_data_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_bar_chart_data_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }



function* upload_catalogue_saga() {

  try {
     const  data = yield store.getState().catalogue_manager_store.upload_catalogue
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
     
     

     const  api_data = yield call(api.catalogue_manager.upload_catalogue, data, headers)
     console.log("UPLOAD_CATALOGUE_DATA", api_data)
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess || (api_data.status===1)){
          console.log("UPLOAD_CATALOGUE_DATA-YAY", api_data)
          yield put(upload_catalogue_ret({
               success:true,
               message:api_data.message || "File upload has started and it might take a few minutes",
               data:api_data.data
              }))
       }else{
           yield put(upload_catalogue_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(upload_catalogue_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(upload_catalogue_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }



export const catalogue_manager_saga = [
   takeLatest(GET_BAR_CHART_DATA, get_bar_chart_data_saga),
   takeLatest(GET_PROF_INFO, get_prof_info_saga),
   takeLatest(UPLOAD_CATALOGUE, upload_catalogue_saga),
   takeLatest(CHECK_UPLOAD_STATUS, check_upload_status_saga),
   takeLatest(GET_CATALOGUE_LIST, get_catalogue_list_saga),
   takeLatest(GET_CATALOGUE_BY_ID, get_catalogue_by_id_saga),
   takeLatest(GET_RECORDS, get_records_saga),
   takeLatest(UPDATE_UNMATCHED, update_unmatched_saga),
   takeLatest(UPDATE_PRICE_CATALOGUE, update_price_catalogue_saga),
   takeLatest(GET_PRICE_COMPARISION, get_price_comparision_saga)
]

export default catalogue_manager_saga

