import React from "react"
import { Button , Card,  Menu, Form, DatePicker, Row, Col, Descriptions, Tag  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { error_form_check, isEmpty, get_month, get_url_params } from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
//import Servicelist from "./Servicelist"
import SearchLocation from "../../Components/SearchLocation"
import  { Link } from "react-router-dom"
import { set_services } from "../../actions/global";
import validator from "validator"
import {
    CheckCircleOutlined
  } from '@ant-design/icons';

const { SubMenu }  = Menu

const options = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
    { label: 'Other', value: 'O' },
  ]

const UpdateCatalogue = (props) => {
      const [state, set_state] = React.useState({
          check_status:"PENDING"
      })
      const [confirm_booking, set_confirm_booking] = React.useState(false)
      const [data, set_data] = React.useState({
        speciality:'',
        service:'',
        price:'',
        variance:''
    })
    const [error, set_error] = React.useState({
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
        price:{ state:false, text:"" },
        variance:{ state:false, text:"" }
 })
     const [initial_data , set_initial_data] = React.useState({})
      const [form] = Form.useForm()

React.useEffect(()=>{
    
},[])

React.useEffect(()=>{
    if(props.update_catalogue_ret){
            if(props.update_catalogue_ret.success){
                props.global_error({
                    success:true,
                    message:props.update_catalogue_ret.message,
                    heading:"Add Service",
                  })
            }else {
                props.global_error({
                    success:false,
                    message:props.update_catalogue_ret.message,
                    heading:"Add Service",
                  })
            }
        props.update_catalogue_loading()
    }

},[props.update_catalogue_ret])

const speciality_change = (val) => {
    props.get_services({speciality:val})
    set_data({...data, speciality_name:props.global_store.specialities.filter(item=>(item.value === val))[0].name, "speciality":val, service:'', price:'', variance:''})
}

const service_change = (val) => {
    set_data({...data, service_name:props.global_store.services.filter(item=>(item.value === val))[0].name, service:val, price:'', variance:''})
}



const validate  = (data) =>{
    const  error = {
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
      
        price:{ state:false, text:"" },
        variance:{ state:false, text:"" }
      }
      if(data.speciality===''){
        {
          error.speciality.text = "Select speciality"
          error.speciality.state = true
        }
      }  
  if(data.service===''){
    {
      error.service.text = "Enter service"
      error.service.state = true
    }
  }

  if(data.price===''){
    {
      error.price.text = "Enter price"
      error.price.state = true
    }
  }

  if(data.variance===''){
    {
      error.variance.text = "Enter variance"
      error.variance.state = true
    }
  }

  if(data.variance!==''){
      if(data.variance > 100 ){
        {
          error.variance.text = "Invalid variance"
          error.variance.state = true
        }
      }
  }
    return error
  }
const submit = () => {
    const ret_error = validate({...data});
    set_error({...error, ...ret_error});
    console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
          props.update_catalogue({
            professional_id:get_url_params('professional_id'),
            specialityId:data.speciality,
            services:[{
                    speciality:data.speciality_name,
                    specialityId:data.speciality,
                    serviceId:data.service,
                    service:data.service_name,
                    category:"Test",
                    price:data.price,
                    variance:data.variance}]
                    }
          )
    }
}

console.log("insode AddService ")


        return (<React.Fragment>
            <Card title="Add Service In Master Catalogue">
                    <Row >
                        
                        <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            >
                                <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput 
                                    label="Speciality"  
                                    value={data.speciality} 
                                    disabled = {props.get_bids_loading_flag ||props.update_catalogue_loading_flag}
                                    options = {props.global_store.specialities}
                                    loading={props.global_store.get_specialities_loading || props.update_catalogue_loading_flag}
                                    error_text={error.speciality.text} 
                                    toggle_error = {()=>set_error({...error,speciality:{text:'',state:false}})} 
                                    error={error.speciality.state}
                                    name="speciality" 
                                    type="select"
                                    onChange = {event=>speciality_change(event)} 
                                    placeholder="Choose speciality" />
                                </span>
                             
                                
                                <span className="flex-child-1-new">
                                    <AntdInput 
                                    label="Service"  
                                    value={data.service} 
                                    options = {props.global_store.services}
                                    loading={props.global_store.get_services_loading || props.update_catalogue_loading_flag}
                                    disabled = {props.get_bids_loading_flag || props.update_catalogue_loading_flag}
                                    error_text={error.service.text} 
                                    toggle_error = {()=>set_error({...error,service:{text:'',state:false}})} 
                                    error={error.service.state}
                                    name="service" 
                                    onChange = {event=>{
                                        console.log(event,"event in Add Service")
                                        service_change(event)
                                    }} 
                                    type="select"
                                    placeholder="Select service" />
                                </span>
                                </div>

                                <div className="flex-parent-new">
                               
                                <span className="flex-child-1-new">
                                <AntdInput 
                                    label="Amount"  
                                    value={data.price} 
                                    disabled = {props.get_bids_loading_flag ||props.update_catalogue_loading_flag}
                                    loading={props.global_store.get_specialities_loading || props.update_catalogue_loading_flag}
                                    error_text={error.price.text} 
                                    toggle_error = {()=>set_error({...error,price:{text:'',state:false}})} 
                                    error={error.price.state}
                                    name="price" 
                                    onChange = {event=>{
                                        set_data({...data, price:event.target.value})
                                    }} 
                                    validation = "positive_integer"
                                    placeholder="Enter price" />
                                </span>
                                <span className="flex-child-1-new">
                                <AntdInput 
                                    label="Variance"  
                                    value={data.variance} 
                                    loading={props.global_store.get_services_loading || props.update_catalogue_loading_flag}
                                    disabled = {props.get_bids_loading_flag || props.update_catalogue_loading_flag}
                                    error_text={error.variance.text} 
                                    toggle_error = {()=>set_error({...error,variance:{text:'',state:false}})} 
                                    error={error.variance.state}
                                    name="variance" 
                                    onChange = {event=>{
                                        console.log(event,"event in Add Service")
                                        set_data({...data, variance:event.target.value})
                                    }} 
                                    validation = "positive_integer"
                                    placeholder="Enter variance" />
                                </span>
                                </div>


                          

                                <div className=" u-margin-top-small text-center">
                                <Button
                                    type="primary"
                                    loading={props.update_catalogue_loading_flag}
                                    disabled={props.update_catalogue_loading_flag}
                                    onClick={() => submit()}
                                    style={{ width: 'auto', margin:'auto' }}
                                  >
                                    Submit
                                 </Button>
                                </div>
</Form>
                        </Col>
                    </Row>

                   
            </Card>
        </React.Fragment>)
}
export default UpdateCatalogue