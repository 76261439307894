import { GET_BAR_CHART_DATA, GET_BAR_CHART_DATA_RET, GET_BAR_CHART_DATA_LOADING,
    GET_PROF_INFO_LOADING, GET_PROF_INFO_RET, GET_PROF_INFO, UPLOAD_CATALOGUE_LOADING, 
    UPLOAD_CATALOGUE_RET, UPLOAD_CATALOGUE, CHECK_UPLOAD_STATUS_LOADING,
    CHECK_UPLOAD_STATUS_RET, CHECK_UPLOAD_STATUS, GET_CATALOGUE_LIST_LOADING, GET_CATALOGUE_LIST,
    GET_CATALOGUE_LIST_RET, GET_CATALOGUE_BY_ID_LOADING, GET_CATALOGUE_BY_ID, GET_CATALOGUE_BY_ID_RET,
    GET_RECORDS_LOADING, GET_RECORDS, GET_RECORDS_RET, UPDATE_UNMATCHED_LOADING, UPDATE_UNMATCHED_RET, UPDATE_UNMATCHED,
    UPDATE_PRICE_CATALOGUE_LOADING, UPDATE_PRICE_CATALOGUE_RET, UPDATE_PRICE_CATALOGUE, GET_PRICE_COMPARISION_LOADING, 
    GET_PRICE_COMPARISION_RET, GET_PRICE_COMPARISION
     } from "../Constants"


export const get_price_comparision = (data) => {
    return {
        type:GET_PRICE_COMPARISION,
        data
    }
}

export const get_price_comparision_ret = (data) => {
    return {
        type:GET_PRICE_COMPARISION_RET,
        data
    }
}

export const get_price_comparision_loading = (data) => {
    return {
        type:GET_PRICE_COMPARISION_LOADING,
        data
    }
}

export const update_price_catalogue = (data) => {
    return {
        type:UPDATE_PRICE_CATALOGUE,
        data
    }
}

export const update_price_catalogue_ret = (data) => {
    return {
        type:UPDATE_PRICE_CATALOGUE_RET,
        data
    }
}

export const update_price_catalogue_loading = (data) => {
    return {
        type:UPDATE_PRICE_CATALOGUE_LOADING,
        data
    }
}


export const update_unmatched = (data) => {
    return {
        type:UPDATE_UNMATCHED,
        data
    }
}

export const update_unmatched_ret = (data) => {
    return {
        type:UPDATE_UNMATCHED_RET,
        data
    }
}


export const update_unmatched_loading = (data) => {
    return {
        type:UPDATE_UNMATCHED_LOADING,
        data
    }
}

export const get_records = (data) => {
    return {
        type:GET_RECORDS,
        data
    }
}

export const get_records_ret = (data) => {
    return {
        type:GET_RECORDS_RET,
        data
    }
}

export const get_records_loading = (data) => {
    return {
        type:GET_RECORDS_LOADING,
        data
    }
}

    
export const get_catalogue_by_id = (data) => {
    return {
        type:GET_CATALOGUE_BY_ID,
        data
    }
}

export const get_catalogue_by_id_ret = (data) => {
    return {
        type:GET_CATALOGUE_BY_ID_RET,
        data
    }
}

export const get_catalogue_by_id_loading = (data) => {
    return {
        type:GET_CATALOGUE_BY_ID_LOADING,
        data
    }
}



export const get_catalogue_list = (data) => {
    return {
        type:GET_CATALOGUE_LIST,
        data
    }
}

export const get_catalogue_list_ret = (data) => {
    return {
        type:GET_CATALOGUE_LIST_RET,
        data
    }
}

export const get_catalogue_list_loading = (data) => {
    return {
        type:GET_CATALOGUE_LIST_LOADING,
        data
    }
}


export const check_upload_status = (data) => {
    return {
        type:CHECK_UPLOAD_STATUS,
        data
    }
}

export const check_upload_status_ret = (data) => {
    return {
        type:CHECK_UPLOAD_STATUS_RET,
        data
    }
}

export const check_upload_status_loading = (data) => {
    return {
        type:CHECK_UPLOAD_STATUS_LOADING,
        data
    }
}


export const upload_catalogue = (data) => {
    return {
        type:UPLOAD_CATALOGUE,
        data
    }
}

export const upload_catalogue_ret = (data) => {
    return {
        type:UPLOAD_CATALOGUE_RET,
        data
    }
}

export const upload_catalogue_loading = (data) => {
    return {
        type:UPLOAD_CATALOGUE_LOADING,
        data
    }
}

export const get_prof_info = (data) => {
    return {
        type:GET_PROF_INFO,
        data
    }
}

export const get_prof_info_ret = (data) => {
    return {
        type:GET_PROF_INFO_RET,
        data
    }
}

export const get_prof_info_loading = (data) => {
    return {
        type:GET_PROF_INFO_LOADING,
        data
    }
}


export const get_bar_chart_data = (data) => {
    return {
        type:GET_BAR_CHART_DATA,
        data
    }
}

export const get_bar_chart_data_ret = (data) => {
    return {
        type:GET_BAR_CHART_DATA_RET,
        data
    }
}

export const get_bar_chart_data_loading = (data) => {
    return {
        type:GET_BAR_CHART_DATA_LOADING,
        data
    }
}