import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { GET_PROFESIONALS, GET_PROFESSIONAL_BYID, UPDATE_PROFESSIONAL_BYID, GET_PROF_SPECIALITIES, GET_PROF_SERVICES, 
   UPDATE_CATALOGUE, GET_PROFESSIONAL_INFO,
   REMOVE_INSURANCE_FROM_PROFESSIONAL, ADD_INSURANCE_TO_PROFESSIONAL, GET_INSURANCE, GET_MEDICAL_DOCTORS, UPDATE_MEDICAL_DOCTOR, DELETE_HOSPITAL_PROFILE, } from '../Constants'
import { get_professionals_ret, get_professional_byid_ret, update_professional_byid_ret, get_prof_specialities_ret,
         get_prof_services_ret, update_catalogue_ret, get_professional_info_ret, 
         add_insurance_to_professional_ret, get_insurance_ret, remove_insurance_from_professional_ret, get_medical_doctors_ret, update_medical_doctor_ret, 
         delete_hospital_profile_ret,
         } from '../actions/professionals'
import { global_loading_clr } from '../actions/global'
import api from '../utils/api_routes'
import { get_url_params } from "../utils/common_utilities"
import store from '../store'



function* get_professional_info_saga() {
   console.log("Inside get_professional_byid_saga")

  try {
     const  data = yield store.getState().professionals_store.get_professional_info
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_professionals")
      let professional_id = data.professional_id
      delete data.professional_id

      let api_data  = {}

      let type = data.type

      if(type){
         delete data.type
      }

      if(type==="get_info"){
         api_data = yield call(api.professionals.get_professional_info, data, professional_id, headers)
      }else if(type==="add_video"){
         api_data = yield call(api.professionals.add_video, data, professional_id, headers)
      }else if(type==="edit_video"){
         let id = data._id
         delete data._id
         console.log(id,"id in edit image")
         data.videoId = id
         api_data = yield call(api.professionals.update_video, data, professional_id, headers)
      }else if(type==="delete_video"){
         api_data = yield call(api.professionals.delete_video, data, professional_id, headers)
      }
      else if(type==="add_image"){
         let formData = new FormData();

         formData.append("file", data.file);
         let imageType = data.imageType;
         api_data = yield call(api.professionals.add_image, formData, imageType, professional_id, headers)
      }else if(type==="delete_image"){
         console.log(data, "data in delete_image")
         api_data = yield call(api.professionals.delete_image, data, professional_id, headers)
      }

     console.log(api_data,"api_data in get_prof_services_saga")
     if(!!api_data){
        if(api_data.success){
           yield put(get_professional_info_ret({
              success:true,
              message:api_data.message ||'Catalogue updated successfully.',
              data:api_data.data[0],
              type
              }))
       }else{
           yield put(get_professional_info_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_professional_info_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_professional_info_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* update_catalogue_saga() {
   console.log("Inside get_professional_byid_saga")

  try {
     const  data = yield store.getState().professionals_store.update_catalogue
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_professionals")
      let professional_id = data.professional_id
      delete data.professional_id
      
      const api_data = yield call(api.professionals.update_catalogue, data, professional_id, headers)
      // const api_data = {
      //    success:false,
      //    message: "Some Message"
      // }


     console.log(api_data,"api_data in get_prof_services_saga")
     if(!!api_data){
        if(api_data.success){
           yield put(update_catalogue_ret({
              success:true,
              message:api_data.message ||'Catalogue updated successfully.'
              }))
       }else{
           yield put(update_catalogue_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_catalogue_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_catalogue_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* get_prof_services_saga() {
   console.log("Inside get_professional_byid_saga")

  try {
     const  data = yield store.getState().professionals_store.get_prof_services
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_professionals")
      let speciality_id = data.speciality_id
      delete data.speciality_id
      const api_data = yield call(api.professionals.get_prof_services, data, speciality_id, get_url_params('professional_id'), headers)
     console.log(api_data,"api_data in get_prof_services_saga")
     if(!!api_data){
        if(api_data.success){
           yield put(get_prof_services_ret({
              success:true,
              message:api_data.message ||'Professional services successfully fetched',
              data:[...api_data.data],
              count:api_data.count
              }))
       }else{
           yield put(get_prof_services_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_prof_services_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_prof_services_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_prof_specialities_saga() {
   console.log("Inside get_professional_byid_saga")

  try {
     const  data = yield store.getState().professionals_store.get_prof_specialities
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_professionals")
      const api_data = yield call(api.professionals.get_prof_specialities, data, headers)
     console.log(api_data,"api_data in get_prof_specialities_saga")
     if(!!api_data){
        if(api_data.success){
           yield put(get_prof_specialities_ret({
              success:true,
              message:api_data.message ||'Professional specialities successfully fetched',
              data:[...api_data.data]
              }))
       }else{
           yield put(get_prof_specialities_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_prof_specialities_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_prof_specialities_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* update_professional_byid_saga() {
   console.log("Inside get_professional_byid_saga")

  try {
     const  data = yield store.getState().professionals_store.update_professional_byid
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_professionals")
      const api_data = yield call(api.professionals.update_professional_byid, data, headers)
     console.log(api_data,"api_data in get_professional_byid_saga")
     if(!!api_data){
        if(api_data.success){
           yield put(update_professional_byid_ret({
              success:true,
              message:api_data.message ||'Professional successfully fetched',
              data:{...api_data.data}
              }))
       }else{
           yield put(update_professional_byid_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_professional_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_professional_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_professional_byid_saga() {
   console.log("Inside get_professional_byid_saga")

  try {
     const  data = yield store.getState().professionals_store.get_professional_byid
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_professionals")
      const api_data = yield call(api.professionals.get_professional_byid, data, headers)
     console.log(api_data,"api_data in get_professional_byid_saga")
     if(!!api_data){
        if(api_data.success){
           yield put(get_professional_byid_ret({
              success:true,
              message:api_data.message ||'Professional successfully fetched',
              data:{...api_data.data}
              }))
       }else{
           yield put(get_professional_byid_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_professional_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_professional_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_professionals_saga() {
    console.log("Inside get_user_saga")

   try {
      const  data = yield store.getState().professionals_store.get_professionals
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
       console.log(data,"data in get_professionals")
       const api_data = yield call(api.professionals.get_professionals, data, headers)
      console.log(api_data,"api_data in get_usersaga")
      if(!!api_data){
         if(api_data.success){
            yield put(get_professionals_ret({
               success:true,
               message:api_data.message ||'Professionals successfully fetched',
               data:api_data.data,
               count:api_data.count
               }))
        }else{
            yield put(get_professionals_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_professionals_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_professionals_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
}

function* get_insurance_saga() {
    console.log("Inside get_insurance_saga")

   try {
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
       const api_data = yield call(api.professionals.get_insurance, headers)
      if(!!api_data){
         if(api_data.success){
            yield put(get_insurance_ret({
               success:true,
               message:api_data.message ||'Insurances successfully fetched',
               data:api_data.data,
               count:api_data.count
               }))
        }else{
            yield put(get_insurance_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_insurance_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_insurance_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
}

function* add_insurance_to_professional_saga() {
	console.log('inside get insurance saga');

	try {
		const data = yield store.getState().professionals_store.add_insurance_to_professional;
		const headers = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		};

      const api_data = yield call(api.professionals.add_insurance_to_professional, data, headers);
      console.log(api_data, 'data in api_data insurance');
      if (api_data) {
         if (api_data.success) {
            yield put(add_insurance_to_professional_ret({
               success: true,
               message: api_data.message || 'Insurace added successfully!',
               data: api_data.data
            }))
         } else {
            yield put(add_insurance_to_professional_ret({
               success: false,
               message: api_data.message || 'Something went wrong, try again later...!',
               data: []
            }))
         }
      }
   } catch(event) {
      console.log(event,"e in get_act insigt saga")
      try{
        yield put(add_insurance_to_professional_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
         yield put(add_insurance_to_professional_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
         }))
      }
   }
}

function* remove_insurance_from_professional_saga() {
	console.log('inside get insurance saga');

	try {
		const data = yield store.getState().professionals_store.remove_insurance_from_professional;
		const headers = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		};

      const api_data = yield call(api.professionals.remove_insurance_from_professional, data, headers);
      console.log('data in api_data REMOVE_INSURANCE', api_data);
      if (api_data) {
         if (api_data.success) {
            yield put(remove_insurance_from_professional_ret({
               success: true,
               message: api_data.message || 'Insurace removed successfully!',
               data: api_data.data
            }))
         } else {
            yield put(remove_insurance_from_professional_ret({
               success: false,
               message: api_data.message || 'Something went wrong, try again later...!',
               data: []
            }))
         }
      }
   } catch(event) {
      console.log(event,"e in get_act insigt saga")
      try{
        yield put(remove_insurance_from_professional_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
         yield put(remove_insurance_from_professional_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
         }))
      }
   }
}

function* get_medical_doctors_saga() {
   console.log("Inside medical_doctors_saga")

  try {
     const  data = yield store.getState().professionals_store.get_medical_doctors
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_professionals")
      const api_data = yield call(api.professionals.get_medical_doctors, data, headers)
     console.log(api_data,"api_data in get_usersaga")
     if(!!api_data){
        if(api_data.success){
           yield put(get_medical_doctors_ret({
              success:true,
              message:api_data.message ||'Medical Doctors successfully fetched',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_medical_doctors_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_medical_doctors_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_medical_doctors_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* update_medical_doctor_saga() {
	console.log('inside get insurance saga');

	try {
		const data = yield store.getState().professionals_store.update_medical_doctor;
		const headers = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		};

      const api_data = yield call(api.professionals.update_medical_doctor, data, headers);
      console.log(api_data, 'data in api_data insurance');
      if (api_data) {
         if (api_data.success) {
            yield put(update_medical_doctor_ret({
               success: true,
               message: api_data.message || 'Doctor updated successfully!',
               data: api_data.data
            }))
         } else {
            yield put(update_medical_doctor_ret({
               success: false,
               message: api_data.message || 'Something went wrong, try again later...!',
               data: []
            }))
         }
      }
   } catch(event) {
      console.log(event,"e in get_act insigt saga")
      try{
        yield put(update_medical_doctor_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
         yield put(update_medical_doctor_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
         }))
      }
   }
}

function* delete_hospital_profile_sage() {
	console.log('inside get insurance saga');

	try {
		const data = yield store.getState().professionals_store.delete_hospital_profile;
		const headers = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		};

      const api_data = yield call(api.professionals.delete_hospital_profile, data, headers);
      console.log(api_data, 'DELETE_HOSPITAL_PROFILE');
      if (api_data) {
         if (api_data.success) {
            yield put(delete_hospital_profile_ret({
               success: true,
               message: api_data.message || 'Hospital deleted successfully!',
               data: api_data.data
            }))
         } else {
            yield put(delete_hospital_profile_ret({
               success: false,
               message: api_data.message || 'Something went wrong, try again later...!',
               data: []
            }))
         }
      } else {
         yield put(delete_hospital_profile_ret({
            success: false,
            message: api_data.message || 'Something went wrong, try again later...!',
            data: []
         }))
      }
   } catch(event) {
      console.log(event,"e","DELETE_HOSPITAL_PROFILE")
      yield put(delete_hospital_profile_ret({
         success:false,
         message:'Something went wrong try again later..',
         data:[]
      }))
   }
}

export const professionals_saga = [
   takeLatest(GET_PROFESIONALS, get_professionals_saga),
   takeLatest(GET_PROFESSIONAL_BYID, get_professional_byid_saga),
   takeLatest(UPDATE_PROFESSIONAL_BYID, update_professional_byid_saga),
   takeLatest(GET_PROF_SPECIALITIES, get_prof_specialities_saga),
   takeLatest(GET_PROF_SERVICES, get_prof_services_saga),
   takeLatest(UPDATE_CATALOGUE, update_catalogue_saga),
   takeLatest(GET_PROFESSIONAL_INFO, get_professional_info_saga),
   takeLatest(GET_INSURANCE, get_insurance_saga),
   takeLatest(ADD_INSURANCE_TO_PROFESSIONAL, add_insurance_to_professional_saga),
   takeLatest(REMOVE_INSURANCE_FROM_PROFESSIONAL, remove_insurance_from_professional_saga),
   takeLatest(GET_MEDICAL_DOCTORS, get_medical_doctors_saga),
   takeLatest(UPDATE_MEDICAL_DOCTOR, update_medical_doctor_saga),
   takeLatest(DELETE_HOSPITAL_PROFILE, delete_hospital_profile_sage),
]

export default professionals_saga