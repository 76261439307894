import React from "react";
import { Button, Form, Input, message, Select, Upload } from "antd";
import { is_positive_whole_number } from "../../utils/common_utilities";
import { UploadOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;

const AntdInput = (props) => {
  const [error, set_error] = React.useState(false);
  React.useEffect(() => {
    set_error(props.error);
  }, [props.error]);
  const [active, set_active] = React.useState(false)

  const handle_change = (event) => {
    if (!!error) {
      if (props.validation === "positive_integer") {
        if (
          !!is_positive_whole_number(event.target.value) ||
          event.target.value === "0"
        ) {
          props.toggle_error();
          props.onChange(event);
        }
      } else {
        props.toggle_error();
        props.onChange(event);
      }
    } else {
      if (props.validation === "positive_integer") {
        console.log("========>", event.target.value);
        if (
          !!is_positive_whole_number(event.target.value) ||
          event.target.value === "0"
        ) {
          props.toggle_error();
          props.onChange(event);
        }
      } else {
        props.onChange(event);
      }
    }
  };

  const handle_select_change = (event) => {
    console.log(event, "inside handle_select_change");
    if (!!error) {
      props.toggle_error();
      props.onChange(event);
    } else {
      props.onChange(event);
    }
  };

  const handle_upload_change = (event) => {

    props.onChange(event);
      if(event.file.status !== 'uploading') {
        console.log(event, event.fileList,"Event file");
      }
      if (event.file.status === 'done') {

        console.log(event, event.fileList,"Event file uploaded");
        message.success(`${event.file.name} file uploaded successfully`);
      } else if (event.file.status === 'error') {
        message.error(`${event.file.name} file upload failed.`);
      }
  }
  // const onBlur = () => {
  //   console.log("blur");
  // };

const onBlur=()=> {
  console.log('blur');
  set_active(false)
}

const onSearch = (val) => {
  console.log("search:", val);
};

const  onFocus = ()=> {
  console.log('focus');
  set_active(true)
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("not ok");
  }, 500);
};

  if (props.type === "textarea") {
    return (
      <Form.Item
        label={props.label}
        validateStatus={error ? "error" : ""}
        help={error ? props.error_text : ""}
      >
        <TextArea
          value={props.value}
          name={props.name}
          disabled={props.disabled}
          loading={props.loading}
          onChange={handle_change}
          rows={props.rows}
          placeholder={props.placeholder}
        />
      </Form.Item>
    );
  } else if (props.type === "select") {
    return (
      <React.Fragment>
        <Form.Item
          label={props.label}
          validateStatus={error ? "error" : ""}
          help={error ? props.error_text : ""}
        >
            <Select
             name={props.name} 
             value={props.value}
             onChange = {handle_select_change}  
             loading = {props.loading}
             mode={props.mode==="multiple"?"multiple":''}
             showSearch
             placeholder={props.placeholder}
             optionFilterProp="children"
             onFocus={onFocus}
             onBlur={onBlur}
             onSearch={onSearch}
             disabled={props.disabled}
             filterOption={(input, option) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             }
             >
               {!active && <Option value={""}>{props.placeholder}</Option>}
              {props.options.map((item, index)=>{
                return <Option key={index} value={item.value}>{item.name}</Option>
              })}
           </Select>
        </Form.Item>
      </React.Fragment>
    );
  }

  else if (props.type === "upload") {
    return (
      <React.Fragment>
        <Form.Item
          label={props.label}
          validateStatus={error ? "error" : ""}
          help={error ? props.error_text : ""}
        >
            <Upload
             name={props.name}   
             loading = {props.loading}
             multiple = {props.multiple}
             onChange={handle_upload_change}
             optionFilterProp="children"
             onFocus={onFocus}
             fileList={props.fileList}
             customRequest={dummyRequest}
             maxCount={props.maxCount}
             >
               <Button icon={<UploadOutlined />}>Click to Upload</Button>
           </Upload>
        </Form.Item>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Form.Item
        label={props.label}
        validateStatus={error ? "error" : ""}
        help={error ? props.error_text : ""}
        className={props.className}
      >
        <Input
          value={props.value}
          name={props.name}
          className={props.className}
          disabled={props.disabled}
          onChange={handle_change}
          placeholder={props.placeholder}
        />
      </Form.Item>
    </React.Fragment>
  );
};
export default AntdInput;
