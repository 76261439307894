import React from "react"
import { Table, Input, Button, Space, Card, Form, Tag, PageHeader } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, error_form_check, getDateFormat} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import validator from "validator"
import AntdInput from "../../Components/AntdInput"
import SearchLocation from "../../Components/SearchLocation"
import SemdNotification from "./SemdNotification"
import BulkSmsList from "./BulkSmsList"
import  BulkSms from "./BulkSms"
// import Map from "./Map.js"


const Solutions = (props) => {
    const {form} = Form.useForm()
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [get_solutions_params, set_get_solutions_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           serviceId:'',
           specialityId:[],
           searchParams:{
             name:'',
            //  email:'',
             mobileNumber:'',
             createdAt:''
           },
           filters:{
             booked:""
           }
      })
      const [data, set_data] = React.useState({
        speciality:[],
        service:[],
        location:'',
        lng:'',
        lat:''
    })
    const [error, set_error] = React.useState({
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
        location:{ state:false, text:"" },
        lng:{ state:false, text:"" },
        lat:{ state:false, text:"" }
 })

      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [bulk_sms_list , set_bulk_sms_list] = React.useState(false)
      const [selected_solutions, set_selected_solutions] =React.useState([])
      const [solutions, set_solutions] = React.useState([])
      const [solutions_selected, set_solutions_selected] = React.useState(false)
      const [sms_select, set_sms_select] = React.useState({})
      const [selected_status, set_selected_status] = React.useState('')
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [send_sms_flag, set_send_sms_flag] = React.useState(false)

      React.useEffect(()=>{
        if(!!props.send_sms_ret){
              if(props.send_sms_ret.success){
                props.global_error({
                  success:true,
                  message:props.send_sms_ret.message,
                  heading:'SMS'
                })
      
                // let arr = []
                // let new_arr = [...data]
                // arr   = new_arr.map(item=>{
                //   console.log(item, sms_select, "======================>")
                //       if(item._id===sms_select.queryId){
                //         console.log(sms_select,"send_sms")
                //         let new_sms = [...item.sms]
                //         new_sms.push(sms_select.message)
                //         return {
                //           ...item,
                //           sms:new_sms
                //         }   
                //       }
                //       else {
                //         return item
                //       }
                //  })
      
                // set_data(arr)
      
              }else{
                props.global_error({
                  success:false,
                  message:props.send_sms_ret.message,
                  heading:'SMS'
                })
              }
              props.send_sms_loading()
        }
       },[props.send_sms_ret])

    React.useEffect(()=>{
        props.get_solutions(get_solutions_params)
    },[])

    React.useEffect(()=>{
      if(!!props.get_solutions_ret){
        // console.log(props.get_solutions_ret,"get_solutions_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_solutions_ret.success){
            set_solutions(modif_data([...props.get_solutions_ret.data]))
        //   set_list([...props.get_solutions_ret.list])
          set_get_solutions_params({...get_solutions_params, total:props.get_solutions_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_solutions_ret.message,
            heading:'Solutions'
          })
         }
          props.get_solutions_loading()
      }
     
         
  },[props.get_solutions_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_solutions_params.filters}
       let updated_filters = {}
       updated_filters.booked = filters.booked
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === get_solutions_params.page) && (pagination.pageSize === get_solutions_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_solutions_params({...get_solutions_params, page:1, filters :{
           booked:filters.booked?filters.booked:'',
           createdAt:filters.createdAt?filters.createdAt:''
          }})
          props.get_solutions({...get_solutions_params, page:1, filters :{
            booked:filters.booked?filters.booked:'',
            createdAt:filters.createdAt?filters.createdAt:''       
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_solutions_params({...get_solutions_params, page:1,searchParams:{
           ...get_solutions_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_solutions({...get_solutions_params, page:1,searchParams:{
          ...get_solutions_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            name: item.name,
            searchQuery: item.searchQuery,
            mobileNumber:item.mobileNumber,
            serviceName: item.serviceName,
            createdAt:getDateFormat(item.createdAt),
            specialityName:item.specialityName,
            active:!!item.active?"YES":"NO",
            booked:!!item.booked?"YES":"NO",
            specialityNames:item.specialityNames,
            geoLocationName:item.geoLocationName,
            _id: item._id
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_solutions_params({...get_solutions_params, limit:b, page:1})
      props.get_solutions({...get_solutions_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_solutions_params,"a and b get_solutions_params")
      if(true){
        set_get_solutions_params({...get_solutions_params, page:a, limit:b})
        props.get_solutions({...get_solutions_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_solutions_params({...get_solutions_params,page:1,  searchParams:{
        name:'',
        //  email:'',
      mobileNumber:'',
        createdAt:''
      },
      filters:{
        booked:""
      }})
      props.get_solutions({...get_solutions_params,page:1,  searchParams:{
        name:'',
        //  email:'',
      mobileNumber:'',
        createdAt:''
      },
      filters:{
        booked:""
      }})
    }
  

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_solutions_selected(r)
    }

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width:'15%',
        
          ...getColumnSearchProps('name', "Search name", 'name'),
        },
        {
          title: 'Phone',
          dataIndex: 'mobileNumber',
          key: 'mobileNumber',
          width:'14%',
          ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
         
        },
        {
            title: 'Query',
            dataIndex: 'searchQuery',
            key: 'searchQuery',
            width:'15%',
            ...getColumnSearchProps('searchQuery', "Search query", 'searchQuery'),
          },
        {
          title: 'Speciality',
          dataIndex: 'specialityName',
          key: 'specialityName',
          width:'14%'
        },
        {
            title: 'Service',
            dataIndex: 'serviceName',
            key: 'serviceName',
            ...getColumnSearchProps('serviceName', "Search serviceName", 'serviceName'),
            width:'13%'
          },
          {
            title: 'Location',
            dataIndex: 'geoLocationName',
            key: 'geoLocationName',
            width:'10%'
          },
          {
            title: 'Booked',
            dataIndex: 'booked',
            key: 'booked',
            width:'11%'
          },
          {
            title: 'Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'8%',
            filters: [
              {
                text: 'Today',
                value: '1',
              },
              {
                text: 'Yesterday',
                value: '2',
              },
              {
                text: 'Last week',
                value: '7',
              }
            ]
          }
      ]

      const speciality_change = (val) => {
        props.get_services({speciality:val})
        set_data({...data,"speciality":val, service:''})
   }

   const expand = (record,a,b) => {
    console.log(record,a,b,"record,a,b")
    if(expandedRowKeys[0]!== a._id){
       set_expandedRowKeys([a._id])
       console.log(a,"a.varianceInfo")
    }else{
    set_expandedRowKeys([])
    }
  }

 const send_sms = (data) => {
    //   set_sms_select({
    //     solutionId:data.queryId,
    //     messageBody:data.sentMessage,
    //     notificationTitle:data.title
    //   })
      props.send_sms({
        solutionId:data.solutionId,
        messageBody:data.message,
        notificationTitle:data.title,
        type:"solutions"
      })
 }

 const clear_service = () => {
     set_data({...data, service:[]})
    set_get_solutions_params({...get_solutions_params,page:1, serviceId:'', specialityId:[],  searchParams:{
        name:'',
        //  email:'',
      mobileNumber:'',
        createdAt:''
      },
      filters:{
        booked:""
      }})
      props.get_solutions({...get_solutions_params,page:1, serviceId:'', specialityId:[], searchParams:{
        name:'',
        //  email:'',
      mobileNumber:'',
        createdAt:''
      },
      filters:{
        booked:""
      }})
 }
 function showTotal(total) {
  return `Total ${total} items`;
}
 
 console.log(selected_solutions,"selected_solutions")

   if(!!solutions_selected){
        return <Redirect to={`/dashboard/solutionss?solutions_id=${solutions_selected._id}`} />
   }

   if(send_sms_flag){
     return <React.Fragment>
            <PageHeader
      className="site-page-header"
    onBack={() => {
      set_send_sms_flag(false)
      set_selected_solutions([])
    }}
    title="Send Bulk Messages"
    // subTitle="This is a subtitle"
  >
           <BulkSms
             list = {selected_solutions}
             send_sms = {props.send_solution_sms}
             send_sms_ret = {props.send_solution_sms_ret}
             send_sms_loading = {props.send_solution_sms_loading}
             send_sms_loading_flag = {props.send_solution_sms_loading_flag}
             global_error = {props.global_error}
           />

       </PageHeader>

     </React.Fragment>
   }


   if(bulk_sms_list){
    return <React.Fragment>
           <PageHeader
     className="site-page-header"
   onBack={() => {
     set_bulk_sms_list(false)
     set_selected_solutions([])
   }}
   title="Previous Bulk Messages"
   // subTitle="This is a subtitle"
 >
          <BulkSmsList
            reportType = {"SOLUTION_SEARCHED_REPORT"}

            get_bulk_sms_list = {props.get_bulk_sms_list}
            get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
            get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
            get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

            global_error = {props.global_error}
          />

      </PageHeader>

    </React.Fragment>
  }

        return (<React.Fragment>
          
            <Form
      form={form}
      layout="vertical"
    >
 
<div className="flex-parent-new">
  <span className="flex-child-1-new">
    <AntdInput 
      label="Speciality"  
      value={data.specialityId} 
      disabled = {props.get_bids_loading_flag}
      options = {props.global_store.specialities}
      loading={props.global_store.get_specialities_loading}
      error_text={error.speciality.text} 
      toggle_error = {()=>set_error({...error,speciality:{text:'',state:false}})} 
      error={error.speciality.state}
      name="speciality" 
      mode="multiple"
      type="select"
      onChange = {event=>{
        console.log(event)
        let specialityId = ""
        event.map((item,i)=>{
          if(i!==0){
            specialityId = `${specialityId},${item}`
          }else{
            specialityId = `${specialityId}${item}`
          }
          
        })
        set_get_solutions_params({...get_solutions_params,page:1, serviceId:'', specialityId:specialityId})
        props.get_solutions({...get_solutions_params,page:1, serviceId:'', specialityId:specialityId})
        set_data({...data,"specialityId":event, service:[]})
        props.get_services({speciality:specialityId})
      }}
      placeholder="Choose speciality" />
</span>
<span className="flex-child-1-new">
    <AntdInput 
      label="Service"  
      value={data.service} 
      options = {props.global_store.services}
      loading={props.global_store.get_services_loading}
      disabled = {props.get_bids_loading_flag}
      error_text={error.service.text} 
      toggle_error = {()=>set_error({...error,service:{text:'',state:false}})} 
      error={error.service.state}
      name="service" 
      type="select"
      mode="multiple"
      onChange = {event=>{
        let serviceId = ""
        event.map((item,i)=>{
          if(i!==0){
            serviceId = `${serviceId},${item}`
          }else{
            serviceId = `${serviceId}${item}`
          }
          
        })
          console.log(props.global_store.services,"props.global_store.services")
        set_data({...data,"service":event})
        set_get_solutions_params({...get_solutions_params,page:1, serviceId:serviceId})
        props.get_solutions({...get_solutions_params,page:1,serviceId:serviceId})
      }} 
      placeholder="Choose service" />
      <Tag onClick={clear_service}>
        Clear service
    </Tag>
</span>

</div>
{/* <div className="flex-parent-new">
<span className="flex-child-1-new">
    <SearchLocation  
        label="Location"  
        value={data.location} 
        disabled = {props.get_bids_loading_flag}
        error_text={error.location.text} 
        toggle_error = {()=>set_error({...error,location:{text:'',state:false}})} 
        error={error.location.state}
        name="location" 
        set_long_lat = {val=>{
          set_data({...data,...val})
         }}
        placeholder="Enter Location"
    />
  </span>
  </div> */}

  {/* <div className="flex-parent-new">
<span className="flex-child-1-new">
      <Form.Item>
        <Button onClick={()=>console.log()} loading={props.get_solutions_loading_flag} type="primary">Get Solutions</Button>
      </Form.Item>
      </span>
 </div> */}
    </Form>
    {(selected_solutions.length !==0) &&  <Button
                type="primary"
                onClick={() => set_send_sms_flag(true)}
                size="small"
                style={{ width: 90 }}
              >
                Send Sms
            </Button>}


            <div className="u-margin-top-small">
                <Button
                  type="primary"
                  onClick={() => set_bulk_sms_list(true)}
                  size="small"
                  style={{ width: 200 }}
                  className="u-margin-top-small"
                >
                  Previous Messages
                
                </Button>
</div>
          
        <Table
          columns={columns}
          dataSource={solutions}
          loading={props.get_solutions_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:get_solutions_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_solutions_params.page,
            total:get_solutions_params.total,
            showTotal:showTotal
          }}
          rowSelection={{
            selectedRowKeys:selectedRowKeys,
            onChange: (e,b)=>{
              setSelectedKeys([...e])
              set_selected_solutions([...b.map(item=>{
                return {
                  name:item.name,
                  _id:item._id
                }
              })])
            },
         }}
          expandedRowRender= {record =>{
            console.log(record,"record")
            return <React.Fragment>
                <SemdNotification
                    send_sms = {send_sms}
                    type="solutions"
                    sms = {record.sms || []}
                    solutionId={record._id}
                    send_sms_loading_flag = {props.send_sms_loading_flag}
                />
      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record._id}
          />
    
        </React.Fragment>)
}
export default Solutions