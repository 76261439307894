import React from "react"
import {
    Button,
    Card,
    Form,
    Modal,
    Descriptions
  } from "antd";
  import { EditOutlined, PlusCircleTwoTone, DeleteOutlined } from '@ant-design/icons';
  import AntdInput from "../../Components/AntdInput"
  import { error_form_check , get_url_params} from '../../utils/common_utilities';
import validator from 'validator';

const UploadVideoLinks = (props) => {
    const [videos, set_videos] = React.useState([])
    const [ add_flag, set_add_flag] = React.useState(false)
    const [edit_flag , set_edit_flag] = React.useState(false)

    const [data, set_data] = React.useState({
        videoUrl:"",
        videoType:"",
        heading:"",
        subHeading:"",
        serviceName:""
    })

    const [selected_video_data, set_selected_video_data] = React.useState({
        videoUrl:"",
        videoType:"",
        heading:"",
        subHeading:"",
        serviceName:""
    })
    const [error_selected_video, set_selected_video_error] = React.useState({
        videoUrl: {state:false, text:''},
        videoType:{state:false, text:''},
        heading:{state:false, text:''},
        subHeading:{state:false, text:''},
        serviceName:{state:false, text:''}
    })
    const [error, set_error] = React.useState({
        videoUrl: {state:false, text:''},
        videoType:{state:false, text:''},
        heading:{state:false, text:''},
        subHeading:{state:false, text:''},
        serviceName:{state:false, text:''}
    })
    React.useEffect(()=>{
            if(props.get_professional_info_ret){
                if(props.get_professional_info_ret.success){

                    let type = props.get_professional_info_ret.type

                    if(type==="get_info"){

                    }else {
                        props.global_error({
                            success:true,
                            message:props.get_professional_info_ret.message,
                            heading:'Professional',
                        })        
                    }
                    
               if(type==="get_info"){
                set_videos([...props.get_professional_info_ret.data.videos])
               }else if(type==="add_video"){
                  props.get_professional_info({
                    professional_id:get_url_params('professional_id'),
                    type:'get_info'
                  })
                  set_add_flag(false)
               }else if(type==="edit_video"){
                    props.get_professional_info({
                      professional_id:get_url_params('professional_id'),
                      type:'get_info'
                    })
                   set_edit_flag(false)
                   set_selected_video_error({
                    videoUrl: {state:false, text:''},
                    videoType:{state:false, text:''},
                    heading:{state:false, text:''},
                    subHeading:{state:false, text:''},
                    serviceName:{state:false, text:''}
                   })
                   set_selected_video_data({
                    videoUrl:"",
                    videoType:"",
                    heading:"",
                    subHeading:"",
                    serviceName:""
                   })
               }else if(type==="delete_video"){
                set_edit_flag(false)
                set_selected_video_error({
                 videoUrl: {state:false, text:''},
                 videoType:{state:false, text:''},
                 heading:{state:false, text:''},
                 subHeading:{state:false, text:''},
                 serviceName:{state:false, text:''}
                })
                set_selected_video_data({
                 videoUrl:"",
                 videoType:"",
                 heading:"",
                 subHeading:"",
                 serviceName:""
                })
                props.get_professional_info({
                    professional_id:get_url_params('professional_id'),
                    type:'get_info'
                  })
               }
                }else {
                    props.global_error({
                    success:false,
                    message:props.get_professional_info_ret.message,
                    heading:'Professional'
                })
                }
                props.get_professional_info_loading()
            }
    }, [props.get_professional_info_ret])

    const handleVedioChange = (e, index) => {
      console.log(e,index,"e and index in handleVideoChange")
      }
    
      const addVedioDetails = () => {
        let arr = [...videos]
          arr.push({})
        set_videos([...arr])
        
      };
    
      const removeVedioDetails = (e,index) => {
        console.log(e,index,"e and index in handleVideoChange")
      }

      const handle_change = (event) => {
          if(edit_flag){
            set_selected_video_data({
                ...selected_video_data,
                [event.target.name]:event.target.value
            })
          }else {
            set_data({
                ...data,
                [event.target.name]:event.target.value
            })
          }
           
      }

      const handle_dropdown_change = (val) => {
          if(edit_flag){
            set_selected_video_data({
                ...selected_video_data,
                videoType:val
              })
          }else{
            set_data({
                ...data,
                videoType:val
              })
          }
         
      }
      

      const validate = (data) => {
		const error = {
            videoUrl: {state:false, text:''},
            videoType:{state:false, text:''},
            heading:{state:false, text:''},
            subHeading:{state:false, text:''},
            serviceName:{state:false, text:''}
		};
		if (data.videoUrl === '') {
			{
				error.videoUrl.text = 'Please enter value';
				error.videoUrl.state = true;
			}
		}
		if (data.videoUrl === '') {
			{
				error.videoUrl.text = 'Please enter value';
				error.videoUrl.state = true;
			}
		}

		if (data.email === '') {
			{
				error.email.text = 'Please enter your user email';
				error.email.state = true;
			}
		}
		if (data.heading === '') {
				error.heading.text = 'Please Enter value';
				error.heading.state = true;
		}

		if (data.mobileNumber === '') {
			{
				error.mobileNumber.text = 'Please enter your phone number';
				error.mobileNumber.state = true;
			}
		}

		if (data.subHeading === '') {
				{
					error.subHeading.text = 'Please enter value';
					error.subHeading.state = true;
				}
		}

		if (data.serviceName === '') {
			{
				error.serviceName.text = 'Please enter value';
				error.serviceName.state = true;
			}
		}
		return error;
	};

      const handle_submit = () => {
          let ret_error = {}
          if(edit_flag){
            ret_error = validate({ ...selected_video_data });
          }else {
            ret_error = validate({ ...data });
          }

          if(edit_flag){
              set_selected_video_error({
                  ...error_selected_video, ...ret_error
              })
          }else {
            set_error({ ...error, ...ret_error });
          }
         
		
		if (!error_form_check(ret_error)) {
            if(edit_flag){
                console.log("Inside edit_case")
                console.log("selected_video_data",selected_video_data)
                props.get_professional_info({
                    ...selected_video_data,
                    professional_id:get_url_params('professional_id'),
                    type:'edit_video'
                })
            }else {
                props.get_professional_info({
                    ...data,
                    professional_id:get_url_params('professional_id'),
                    type:'add_video'
                })
            }
               
		}
      }

      const edit_click = (item) => {
          set_edit_flag(true)
          set_selected_video_data({
              ...item
          })
          set_selected_video_error({
            videoUrl: {state:false, text:''},
            videoType:{state:false, text:''},
            heading:{state:false, text:''},
            subHeading:{state:false, text:''},
            serviceName:{state:false, text:''}
          })
      }

      const delete_click = () =>{
        props.get_professional_info({
            ...selected_video_data,
            professional_id:get_url_params('professional_id'),
            type:'delete_video'
        })
      }

    return   <Card title="Video">
    <Form layout="vertical">
      <Form.Item>
          {add_flag   &&   <div key={"1221"}>
              <div className="flex-parent-new ">
                <span className="flex-child-1-new">
                  <Form.Item label="Enter Video link">
                    <AntdInput 
                        value={data.videoUrl} 
                        name="videoUrl" 
                        onChange={(event)=>handle_change(event)}
                        error_text={error.videoUrl.text}
						toggle_error={() =>
							set_error({ ...error, videoUrl: { text: '', state: false } })
						}
						error={error.videoUrl.state} />
                  </Form.Item>
                </span>
                <span className="flex-child-1-new">
                  <Form.Item label="Enter Heading">
                    <AntdInput 
                      value={data.heading} 
                      name="heading" 
                      onChange={(event)=>handle_change(event)}
                      error_text={error.heading.text}
                      toggle_error={() =>
                          set_error({ ...error, heading: { text: '', state: false } })
                      }
                      error={error.heading.state} />
                  </Form.Item>
                </span>
              </div>
              
              <div className="flex-parent-new ">
                <span className="flex-child-1-new">
                  <Form.Item label="Sub Heading">
                    <AntdInput 
                      value={data.subHeading} 
                      name="subHeading" 
                      onChange={(event)=>handle_change(event)}
                      error_text={error.subHeading.text}
                      toggle_error={() =>
                          set_error({ ...error, subHeading: { text: '', state: false } })
                      }
                      error={error.subHeading.state} />
                  </Form.Item>
                </span>
               
                  <span className="flex-child-1-new">
                    <Form.Item label="Video Type">
                    <AntdInput
                          value={data.videoType}
                          name="videoType"
                          options={[{
                              name:"SERVICE",
                              value:'SERVICE'
                          }, {
                            name:"CUSTOMER_REVIEW",
                            value:'CUSTOMER_REVIEW'
                        }, {
                            name:"INTRODUCTION",
                            value:'INTRODUCTION'
                        }, {
                            name:"ACHIEVEMENTS",
                            value:'ACHIEVEMENTS'
                        },{
                            name:'OTHER',
                            value:'OTHER'
                        }]}
                        type="select"
                        error_text={error.videoType.text}
                        toggle_error={() =>
                            set_error({ ...error, videoType: { text: '', state: false } })
                        }
                        error={error.videoType.state}
                         onChange={(val)=>handle_dropdown_change(val)}
                        />
                    </Form.Item>
                    </span>
              
                <span className="flex-child-1-new">
                  <Form.Item label="Enter Indexing">
                    <div className="flex-parent-new">
                      <span className="flex-child-1-new">
                        <AntdInput
                          placeholder="Ex. 1"
                          name="serviceName"
                          value={data.serviceName}
                          onChange={(event)=>handle_change(event)}
                          error_text={error.serviceName.text}
                          toggle_error={() =>
                              set_error({ ...error, serviceName: { text: '', state: false } })
                          }
                          error={error.serviceName.state}
                        />
                      </span>
                     
                      <span className="flex-child-1-new">
                        <Button
                          type="transparent"
                         
                          onClick={() => handle_submit()}
                        >
                          Submit
                        </Button>
                      </span>
                    
                    </div>
                  </Form.Item>
                </span>
              </div>
            </div>}
        {videos.map((item, index) => {
          return (
            <div key={index}>
                         <Descriptions
                                    title="Video"
                                    size={"medium"}
                                    extra={<Button onClick={()=>edit_click(item)} type="primary">Edit</Button>}
                              >
                                    <Descriptions.Item label="Vide Url">{item.videoUrl}</Descriptions.Item>
                                    <Descriptions.Item label="Heading">{item.heading}</Descriptions.Item>
                                    <Descriptions.Item label="Sub Heading">{item.subHeading}</Descriptions.Item>
                                    <Descriptions.Item label="Type">{item.videoType}</Descriptions.Item>
                                    <Descriptions.Item label="Service Name">{item.serviceName}</Descriptions.Item>
                       </Descriptions>
            </div>
          );
        })}
        <Button
          type="dashed"
          icon={<PlusCircleTwoTone />}
          onClick={()=>set_add_flag(true)}
        >
          Add more
        </Button>
      </Form.Item>
    </Form>

    <Modal
          title="Edit Video"
          visible={edit_flag}
          onOk={()=>set_edit_flag(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          width={600}
        >
                 <div key={"1221"}>
              <div className="flex-parent-new ">
                <span className="flex-child-1-new">
                  <Form.Item label="Enter Video link">
                    <AntdInput 
                        value={selected_video_data.videoUrl} 
                        name="videoUrl" 
                        onChange={(event)=>handle_change(event)}
                        error_text={error_selected_video.videoUrl.text}
						toggle_error={() =>
							set_selected_video_error({ ...error_selected_video, videoUrl: { text: '', state: false } })
						}
						error={error_selected_video.videoUrl.state} />
                  </Form.Item>
                </span>
                <span className="flex-child-1-new">
                  <Form.Item label="Enter Heading">
                    <AntdInput 
                      value={selected_video_data.heading} 
                      name="heading" 
                      onChange={(event)=>handle_change(event)}
                      error_text={error_selected_video.heading.text}
                      toggle_error={() =>
                          set_selected_video_error({ ...error_selected_video, heading: { text: '', state: false } })
                      }
                      error={error_selected_video.heading.state} />
                  </Form.Item>
                </span>
              </div>
              
              <div className="flex-parent-new ">
                <span className="flex-child-1-new">
                  <Form.Item label="Sub Heading">
                    <AntdInput 
                      value={selected_video_data.subHeading} 
                      name="subHeading" 
                      onChange={(event)=>handle_change(event)}
                      error_text={error_selected_video.subHeading.text}
                      toggle_error={() =>
                          set_selected_video_error({ ...error_selected_video, subHeading: { text: '', state: false } })
                      }
                      error={error_selected_video.subHeading.state} />
                  </Form.Item>
                </span>
               
                  <span className="flex-child-1-new">
                    <Form.Item label="Video Type">
                    <AntdInput
                          value={selected_video_data.videoType}
                          name="videoType"
                          options={[{
                              name:"SERVICE",
                              value:'SERVICE'
                          }, {
                            name:"CUSTOMER_REVIEW",
                            value:'CUSTOMER_REVIEW'
                        }, {
                            name:"INTRODUCTION",
                            value:'INTRODUCTION'
                        }, {
                            name:"ACHIEVEMENTS",
                            value:'ACHIEVEMENTS'
                        },{
                            name:'OTHER',
                            value:'OTHER'
                        }]}
                        type="select"
                        error_text={error_selected_video.videoType.text}
                        toggle_error={() =>
                            set_selected_video_error({ ...error_selected_video, videoType: { text: '', state: false } })
                        }
                        error={error_selected_video.videoType.state}
                         onChange={(val)=>handle_dropdown_change(val)}
                        />
                    </Form.Item>
                    </span>
              
                <span className="flex-child-1-new">
                  <Form.Item label="Enter Indexing">
                    <div className="flex-parent-new">
                      <span className="flex-child-1-new">
                        <AntdInput
                          placeholder="Ex. 1"
                          name="serviceName"
                          value={selected_video_data.serviceName}
                          onChange={(event)=>handle_change(event)}
                          error_text={error_selected_video.serviceName.text}
                          toggle_error={() =>
                              set_selected_video_error({ ...error_selected_video, serviceName: { text: '', state: false } })
                          }
                          error={error_selected_video.serviceName.state}
                        />
                      </span>
                    </div>
                  </Form.Item>
                </span>
              </div>
            </div>

            <div className="u-margin-top-small">
                 <div className="flex-parent-new">
                     <div className="flex-child-1-new text-center" >
                        <Button onClick={()=>handle_submit()} type="primary">Submit</Button>
                     </div>
                     <div className="flex-child-1-new text-center ">
                        <Button onClick={()=>delete_click()} type="transparent">Delete</Button>
                     </div>
                 </div>
            </div>


    </Modal>
  </Card>
}

export default UploadVideoLinks