
import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  GET_KEY_PROF , GET_KEY_PROF_SERVICES, SEND_VARIANCE_INFO, PREVIOUS_VARIANCE_LIST, SEND_BUSINESS_REPORTS, 
  PREVIOUS_BUSINESS_REPORTS, SEND_CATALOGUE_REPORT, EMAIL_PREVIEW, SEND_SPECIALITY_REPORT, SENT_SPECIALITY_REPORTS, 
  SENT_CATALOGUE_REPORTS, ADD_SERVICE, ADD_INSURANCE, UPDATE_FAQ,
  GET_INSURANCE,
  REMOVE_INSURANCE_FROM_DB,
  REMOVE_INSURANCE_FROM_PROF, SERVICE_DATA_HANDLER, GET_SERVICE_FAMILY_BYID, GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID, GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING, UPDATE_MARKETING_PAGE, 
  UPDATE_ADVANCED_TREATMENT,
  UPLOAD_ADVANCED_TREATMENT_ICON,
  UPLOAD_ADVANCED_TREATMENT_IMAGE} from '../Constants'
import { 
  get_key_prof_ret, get_key_prof_services_ret,  send_variance_info_ret, previous_variance_list_ret,
  send_business_reports_ret, previous_business_reports_ret, send_catalogue_report_ret, email_preview_ret,
  send_speciality_report_ret, sent_speciality_reports_ret, sent_catalogue_reports_ret, add_service_ret, add_insurance_ret,
  get_insurance_ret,
  remove_insurance_from_db_ret,
  remove_insurance_from_prof_ret,
  service_data_handler_ret, get_service_family_byid_ret, get_service_family_list_by_specialityid_ret, 
  update_faq_ret,
  updateMarketingPage_ret,
  update_advanced_treatment_ret, 
  upload_advanced_treatment_image_ret,
  upload_advanced_treatment_icon_ret} from '../actions/keyManager'
import { global_loading_clr } from '../actions/global'
import api from '../utils/api_routes'
import { get_url_params } from "../utils/common_utilities"
import store from '../store'




function* update_faq_saga() {
	console.log('Inside get_key_prof_saga');

	try {
		const data = yield store.getState().keymanager_store.update_faq;
		const headers = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		};

		console.log(data, 'data in get_key_prof');
		let api_data = {}
		let type = data.type
		if(type){
			delete data.type
		}

		let variant = data.variant
		if(type){
			delete data.variant
		}

		if(type==="edit_faq"){
			let family_id = data.family_id
			let faq_id = data.faq_id
			if(family_id){
				delete data.family_id
			}
			if(faq_id){
				delete data.faq_id
			}
			if(variant==="testimonial"){
				api_data = yield call(api.key_manager.edit_testimonial, data, family_id, faq_id, headers)
			}else {
				api_data = yield call(api.key_manager.edit_faq, data, family_id, faq_id, headers)
			}
			
		}else if(type==="delete_faq"){
			let family_id = data.family_id
			let faq_id = data.faq_id
			if(family_id){
				delete data.family_id
			}
			if(faq_id){
				delete data.faq_id
			}
			if(variant==="testimonial"){
				api_data = yield call(api.key_manager.delete_testimonial,  family_id, faq_id, headers)
			}else {
				api_data = yield call(api.key_manager.delete_faq,  family_id, faq_id, headers)
			}
			 
		}else if(type==="add_faq"){
			let family_id = data.family_id
			if(family_id){
				delete data.family_id
			}
			if(variant==="testimonial"){
				api_data = yield call(api.key_manager.add_testimonial,data, family_id, headers)
			}else {
				api_data = yield call(api.key_manager.add_faq,data, family_id, headers)
			}
			 
		}
		
		console.log(api_data, 'api_data in api_data');
		if (!!api_data) {
			if (api_data.success) {
				yield put(
					update_faq_ret({
						success: true,
						message:
							api_data.message ||
							'Updated Successfully',
					})
				);
			} else {
				yield put(
					update_faq_ret({
						success: false,
						message:
							api_data.message || 'Something went wrong try again later..',
						data: [],
					})
				);
			}
		}
	} catch (e) {
		console.log(e, 'e in get_act insigt saga');
		try {
			yield put(
				update_faq_ret({
					success: false,
					message: 'Something went wrong try again later..',
					data: [],
				})
			);
		} catch (x) {
			yield put(
				update_faq_ret({
					success: false,
					message: 'Something went wrong try again later..',
					data: [],
				})
			);
		}
	}
}


function* add_service_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.add_service;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(api.key_manager.add_service, data, headers);
    console.log(api_data, "api_data in api_data");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          add_service_ret({
            success: true,
            message:
              api_data.message ||
              "Service successfully added to master catalogue",
          })
        );
      } else {
        yield put(
          add_service_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        add_service_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        add_service_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* add_insurance_saga() {
  console.log("inside get insurance saga");

  try {
    const data = yield store.getState().keymanager_store.add_insurance;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const api_data = yield call(api.key_manager.add_insurance, data, headers);
    console.log(api_data, "data in api_data insurance");
    if (api_data) {
      if (api_data.status === 201) {
        yield put(
          add_insurance_ret({
            success: true,
            message: api_data.message || "Insurace added successfully!",
            data: api_data.data,
          })
        );
      } else {
        yield put(
          add_insurance_ret({
            success: false,
            message:
              api_data.message || "Something went wrong, try again later...!",
            data: [],
          })
        );
      }
    }
  } catch (event) {
    console.log(event, "e in get_act insigt saga");
    try {
      yield put(
        add_insurance_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        add_insurance_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* service_data_handler_saga() {
	console.log("Inside get_key_prof_saga")
 
   try {
	  const  data = yield store.getState().keymanager_store.service_data_handler
	  const headers = {
		 headers: {
		   Authorization: `Bearer ${localStorage.getItem('token')}`,
		 },
	   }
	  
	   console.log(data,"data in get_key_prof")
	   let api_data = {}
	   let type = data.type

	   if(type){
		   delete data.type
		   delete data.speciality
		   delete data.serviceFamily
		   delete data._id
	   }

	   if(type==="add_service"){
		   api_data = yield call(api.key_manager.service_data_add_service, data, headers)
		
	   }else {
		    api_data = yield call(api.key_manager.service_data_handler, data, headers)
	   }
	    
	  console.log(api_data,"api_data in api_data")
	  if(!!api_data){
		 if(api_data.success || api_data.status){
			yield put(service_data_handler_ret({
			 success:true,
			 message:api_data.message ||'Service successfully added to master catalogue'
			   }))
		}else{
			yield put(service_data_handler_ret({
				success:false,
				message:api_data.message ||'Something went wrong try again later..',
				data:[]
			   }))
		}
	  }
   } catch (e) {
	console.log(e,"e in get_act insigt saga")
	try{
		yield put(service_data_handler_ret({
			success:false,
			message:'Something went wrong try again later..',
			data:[]
		   }))
	  }catch(x){
		yield put(service_data_handler_ret({
			success:false,
			message:'Something went wrong try again later..',
			data:[]
		   }))
		}
   }
 }
 

function* get_service_family_byid_saga () {
  console.log("Inside get_key_prof_saga")

 try {
    const  data = yield store.getState().keymanager_store.get_service_family_byid
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
    
     console.log(data,"data in get service family")
     const api_data = yield call(api.key_manager.get_service_family_byid, data, headers)
    console.log(api_data,"api_data in api_data")
    if(!!api_data){
       if(api_data.success){
          yield put(get_service_family_byid_ret({
           success:true,
           data:api_data.data,
     editInstruction:api_data.editInstruction,
           message:api_data.message ||'Service data fetch successfully '
             }))
      }else{
          yield put(get_service_family_byid_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(get_service_family_byid_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(get_service_family_byid_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* get_service_family_list_by_specialityid_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store
      .get_service_family_list_by_specialityid;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get service family list");
    const api_data = yield call(
      api.key_manager.get_service_family_list_by_specialityid,
      data,
      headers
    );
    console.log(api_data, "api_data in api_data");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          get_service_family_list_by_specialityid_ret({
            success: true,
            data: api_data.data,
            message: api_data.message || "Service data fetch successfully ",
          })
        );
      } else {
        yield put(
          get_service_family_list_by_specialityid_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        get_service_family_list_by_specialityid_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        get_service_family_list_by_specialityid_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}
function* sent_catalogue_reports_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.sent_catalogue_reports;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.sent_catalogue_reports,
      data,
      headers
    );
    console.log(api_data, "api_data in api_data");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          sent_catalogue_reports_ret({
            success: true,
            count: api_data.count,
            data: api_data.data,
          })
        );
      } else {
        yield put(
          sent_catalogue_reports_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        sent_catalogue_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        sent_catalogue_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* sent_speciality_reports_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store
      .sent_speciality_reports;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.sent_speciality_reports,
      data,
      headers
    );
    console.log(api_data, "api_data in api_data");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          sent_speciality_reports_ret({
            success: true,
            count: api_data.count,
            data: api_data.data,
          })
        );
      } else {
        yield put(
          sent_speciality_reports_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        sent_speciality_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        sent_speciality_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* send_speciality_report_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.send_speciality_report;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.send_speciality_report,
      data,
      headers
    );
    console.log(api_data, "api_data in api_data");
    if (!!api_data) {
      if (api_data.success || api_data.sucsess) {
        yield put(
          send_speciality_report_ret({
            success: true,
            message: api_data.message || "Message successfully send",
            // data:api_data.data
          })
        );
      } else {
        yield put(
          send_speciality_report_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        send_speciality_report_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        send_speciality_report_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}
function* email_preview_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.email_preview;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(api.key_manager.email_preview, data, headers);
    console.log(api_data, "api_data in api_data");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          email_preview_ret({
            success: true,
            message: api_data.message || "Preview successfully fetched",
            data: api_data.data,
          })
        );
      } else {
        yield put(
          email_preview_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        email_preview_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        email_preview_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* previous_business_reports_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store
      .previous_business_reports;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.previous_business_reports,
      data,
      headers
    );
    console.log(api_data, "api_data in api_data");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          previous_business_reports_ret({
            success: true,
            count: api_data.count,
            data: api_data.data,
          })
        );
      } else {
        yield put(
          previous_business_reports_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        previous_business_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        previous_business_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}
function* send_business_reports_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.send_business_reports;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.send_business_reports,
      data,
      headers
    );
    console.log(api_data, "api_data in get_public_queries_ret");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          send_business_reports_ret({
            success: true,
            message: api_data.message || "Variance info success",
          })
        );
      } else {
        yield put(
          send_business_reports_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        send_business_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        send_business_reports_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}
function* previous_variance_list_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.previous_variance_list;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.previous_variance_list,
      data,
      headers
    );
    console.log(api_data, "api_data in get_public_queries_ret");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          previous_variance_list_ret({
            success: true,
            data: api_data.data,
            count: api_data.count,
          })
        );
      } else {
        yield put(
          previous_variance_list_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        previous_variance_list_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        previous_variance_list_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}
function* send_variance_info_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.send_variance_info;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.send_variance_info,
      data,
      headers
    );
    console.log(api_data, "api_data in get_public_queries_ret");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          send_variance_info_ret({
            success: true,
            message: api_data.message || "Variance info success",
          })
        );
      } else {
        yield put(
          send_variance_info_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        send_variance_info_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        send_variance_info_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* send_catalogue_report_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.send_catalogue_report;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.send_catalogue_report,
      data,
      headers
    );
    console.log(api_data, "api_data in get_public_queries_ret");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          send_catalogue_report_ret({
            success: true,
            message: api_data.message || "Variance info success",
          })
        );
      } else {
        yield put(
          send_catalogue_report_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        send_catalogue_report_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        send_catalogue_report_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* get_key_prof_services_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.get_key_prof_services;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(
      api.key_manager.get_key_prof_services,
      data,
      headers
    );
    console.log(api_data, "api_data in get_key_prof_services");
    if (!!api_data) {
      if (api_data.success) {
        yield put(
          get_key_prof_services_ret({
            success: true,
            message:
              api_data.message || "Professionals Services successfully fetched",
            data: api_data.data,
            count: api_data.count,
          })
        );
      } else {
        yield put(
          get_key_prof_services_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        get_key_prof_services_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        get_key_prof_services_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}

function* get_key_prof_saga() {
  console.log("Inside get_key_prof_saga");

  try {
    const data = yield store.getState().keymanager_store.get_key_prof;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    console.log(data, "data in get_key_prof");
    const api_data = yield call(api.key_manager.get_key_prof, data, headers);
    console.log(api_data, "api_data in get_usersaga");
    if (!!api_data) {
      if (api_data.sucess) {
        yield put(
          get_key_prof_ret({
            success: true,
            message: api_data.message || "Professionals successfully fetched",
            data: api_data.data,
            list: api_data.list,
            count: api_data.count,
          })
        );
      } else {
        yield put(
          get_key_prof_ret({
            success: false,
            message:
              api_data.message || "Something went wrong try again later..",
            data: [],
          })
        );
      }
    }
  } catch (e) {
    console.log(e, "e in get_act insigt saga");
    try {
      yield put(
        get_key_prof_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    } catch (x) {
      yield put(
        get_key_prof_ret({
          success: false,
          message: "Something went wrong try again later..",
          data: [],
        })
      );
    }
  }
}


function* updateMarketingPage_saga() {
	console.log("Inside get_key_prof_saga")
 
   try {
	  const  data = yield store.getState().keymanager_store.update_marketing_page
	  const headers = {
		 headers: {
		   Authorization: `Bearer ${localStorage.getItem('token')}`,
		 },
	   }
	  
	   console.log(data,"data in get_key_prof")
	   let api_data = {}
	   let type = data.type

	   if(type){
		   delete data.type
		   delete data.speciality
		   delete data.serviceFamily
		   delete data._id
	   }

	   if(type==="add_marketinPage"){
		   api_data = yield call(api.key_manager.addNewMarketingPage, data, headers)
		
	   }else {
		    api_data = yield call(api.key_manager.updateMarketingPage, data, headers)
	   }
	    
	  console.log(api_data,"api_data in api_data")
	  if(!!api_data){
		 if(api_data.success || api_data.status){
			yield put(updateMarketingPage_ret({
			 success:true,
			 message:api_data.message ||'Service successfully added to master catalogue'
			   }))
		}else{
			yield put(updateMarketingPage_ret({
				success:false,
				message:api_data.message ||'Something went wrong try again later..',
				data:[]
			   }))
		}
	  }
   } catch (e) {
	console.log(e,"e in get_act insigt saga")
	try{
		yield put(updateMarketingPage_ret({
			success:false,
			message:'Something went wrong try again later..',
			data:[]
		   }))
	  }catch(x){
		yield put(updateMarketingPage_ret({
			success:false,
			message:'Something went wrong try again later..',
			data:[]
		   }))
		}
   }
 }

function* update_advanced_treatment_saga() {
	console.log('Inside get_key_prof_saga advanced_treatment');

	try {
		const data = yield store.getState().keymanager_store.update_advanced_treatment;
		const headers = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		};

		let api_data = {}
		let type = data.type
		if(type){
			delete data.type
		}

    if(type==="edit_advanced_treatment"){
			let family_id = data.family_id
			let faq_id = data.faq_id
			if(family_id){
				delete data.family_id
			}
			if(faq_id){
				delete data.faq_id
			}

			api_data = yield call(api.key_manager.edit_advanced_treatment, data, family_id, faq_id, headers)
			
		}else if(type==="delete_advanced_treatment"){
			let family_id = data.family_id
			let faq_id = data.faq_id
			if(family_id){
				delete data.family_id
			}
			if(faq_id){
				delete data.faq_id
			}

			api_data = yield call(api.key_manager.delete_advanced_treatment,  family_id, faq_id, headers)
			 
		}else if(type==="add_advanced_treatment"){
			let family_id = data.family_id
			if(family_id){
				delete data.family_id
			}
			api_data = yield call(api.key_manager.add_advanced_treatment,data, family_id, headers)
			 
		}
		
		console.log(api_data, 'api_data in api_data');
		if (!!api_data) {
			if (api_data.success) {
				yield put(
					update_advanced_treatment_ret({
						success: true,
						message:
							api_data.message ||
							'Updated Successfully',
					})
				);
			} else {
				yield put(
					update_advanced_treatment_ret({
						success: false,
						message:
							api_data.message || 'Something went wrong try again later..',
						data: [],
					})
				);
			}
		}
	} catch (e) {
		console.log(e, 'e in get_act insigt saga');
		try {
			yield put(
				update_advanced_treatment_ret({
					success: false,
					message: 'Something went wrong try again later..',
					data: [],
				})
			);
		} catch (x) {
			yield put(
				update_advanced_treatment_ret({
					success: false,
					message: 'Something went wrong try again later..',
					data: [],
				})
			);
		}
	}
}

function* upload_advanced_treatment_image_saga() {
  try {
     const  data = yield store.getState().keymanager_store.upload_advanced_treatment_image
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }
			let family_id = data.family_id
			let faq_id = data.faq_id
			if(family_id){
				delete data.family_id
			}
			if(faq_id){
				delete data.faq_id
			}
      let formData = new FormData()
      formData.append("file", data.file)

      let finalData = {
        formData: formData,
        data: data
      }

     const api_data = yield call(api.key_manager.add_advanced_treatment_image, formData, family_id, headers)
         console.log(api_data,"api_data in UPLOAD_USER_REPORT")
     if(!!api_data){
        if(!!api_data.success){
           yield put(upload_advanced_treatment_image_ret({
               success:true,
               message:api_data.message ||'Image Uploaded Successfully',
               data:api_data.data
              }))
       }else{
           yield put(upload_advanced_treatment_image_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   yield put(upload_advanced_treatment_image_ret({
    success:false,
    message:'Something went wrong try again later..',
    data:[]
   }))
  }
}

function* upload_advanced_treatment_icon_saga() {

  try {
     const  data = yield store.getState().keymanager_store.upload_advanced_treatment_icon
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }
			let family_id = data.family_id
			let faq_id = data.faq_id
			if(family_id){
				delete data.family_id
			}
			if(faq_id){
				delete data.faq_id
			}
      let formData = new FormData()
      formData.append("file", data.file)

      let finalData = {
        formData: formData,
        data: data
      }

     const api_data = yield call(api.key_manager.add_advanced_treatment_image, formData, family_id, headers)
         console.log(api_data,"api_data in UPLOAD_USER_REPORT")
     if(!!api_data){
        if(!!api_data.success){
           yield put(upload_advanced_treatment_icon_ret({
               success:true,
               message:api_data.message ||'Image Uploaded Successfully',
               data:api_data.data
              }))
       }else{
           yield put(upload_advanced_treatment_icon_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   yield put(upload_advanced_treatment_icon_ret({
    success:false,
    message:'Something went wrong try again later..',
    data:[]
   }))
  }
}

export const key_mananger_saga = [
   takeLatest(SEND_BUSINESS_REPORTS, send_business_reports_saga),
   takeLatest(SEND_CATALOGUE_REPORT, send_catalogue_report_saga),
   takeLatest(PREVIOUS_BUSINESS_REPORTS, previous_business_reports_saga),
   takeLatest(SEND_VARIANCE_INFO, send_variance_info_saga),
   takeLatest(GET_KEY_PROF, get_key_prof_saga),
   takeLatest(GET_KEY_PROF_SERVICES, get_key_prof_services_saga),
   takeLatest(PREVIOUS_VARIANCE_LIST, previous_variance_list_saga),
   takeLatest(EMAIL_PREVIEW, email_preview_saga),
   takeLatest(SEND_SPECIALITY_REPORT, send_speciality_report_saga),
   takeLatest(SENT_SPECIALITY_REPORTS, sent_speciality_reports_saga),
   takeLatest(SENT_CATALOGUE_REPORTS, sent_catalogue_reports_saga),
   takeLatest(ADD_SERVICE, add_service_saga),
   takeLatest(ADD_INSURANCE, add_insurance_saga),
   takeLatest(SERVICE_DATA_HANDLER, service_data_handler_saga),
   takeLatest(GET_SERVICE_FAMILY_BYID, get_service_family_byid_saga),
   takeLatest(GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID, get_service_family_list_by_specialityid_saga),
   takeLatest(UPDATE_FAQ, update_faq_saga),
   takeLatest(UPDATE_ADVANCED_TREATMENT, update_advanced_treatment_saga),
   takeLatest(UPLOAD_ADVANCED_TREATMENT_IMAGE, upload_advanced_treatment_image_saga),
   takeLatest(UPLOAD_ADVANCED_TREATMENT_ICON, upload_advanced_treatment_icon_saga),
   takeLatest(UPDATE_MARKETING_PAGE, updateMarketingPage_saga)
]


export default key_mananger_saga

