import React from "react"
import { Table, Input, Button, Space, Card, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, get_time} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
// import Map from "./Map.js"


const ProfessionalsList = (props) => {
  const {form} = Form.useForm()
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [get_price_comparision_params, set_get_price_comparision_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           professional_id:props.professional_id,
           searchParams:{
            
           },
           filters:{
            
           }
      })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [speciality, set_speciality] = React.useState([])
      const [list, set_list] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)

    React.useEffect(()=>{
      if(props.professional_id){
        set_get_price_comparision_params({...get_price_comparision_params, professional_id:props.professional_id})
      }
    },[props.professional_id])

    React.useEffect(()=>{
      if(!!props.get_price_comparision_ret){
        // console.log(props.get_price_comparision_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_price_comparision_ret.success){
          set_data(modif_data([...props.get_price_comparision_ret.data]))
          set_get_price_comparision_params({...get_price_comparision_params, total:props.get_price_comparision_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_price_comparision_ret.message,
            heading:'Professionals'
          })
         }
          props.get_price_comparision_loading()
      }
     
         
  },[props.get_price_comparision_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_price_comparision_params.filters}
       let updated_filters = {}
      
       updated_filters.level = filters.level
       updated_filters.catalogue = filters.catalogue
       updated_filters.userType = filters.userType
      if(!!((pagination.current === get_price_comparision_params.page) && (pagination.pageSize === get_price_comparision_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_price_comparision_params({...get_price_comparision_params, page:1, filters :{
         
        //    userType: filters.userType? filters.userType:'',
        //    level:filters.level?filters.level:'',
        //    catalogue:filters.catalogue?filters.catalogue:''
          }})
          props.get_price_comparision({...get_price_comparision_params, page:1, filters :{
           
        //    userType: filters.userType? filters.userType:'',
        //    level:filters.level?filters.level:'',
        //    catalogue:filters.catalogue?filters.catalogue:''        
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_price_comparision_params({...get_price_comparision_params, page:1,searchParams:{
           ...get_price_comparision_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_price_comparision({...get_price_comparision_params, page:1,searchParams:{
          ...get_price_comparision_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            service: item.service,
            serviceId: item.serviceId,
            speciality:item.speciality,
            speciality_id:item.specialityId,
            variance:item.variance,
            price:item.price?item.price[0]:'N/A',
            catalogue_price:item.cataloguePrice?item.cataloguePrice[0]:'N/A',
            catalogue_variance:item.catalogueVariance?item.catalogueVariance:'N/A'
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_price_comparision_params({...get_price_comparision_params, limit:b, page:1})
      props.get_price_comparision({...get_price_comparision_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_price_comparision_params,"a and b get_price_comparision_params")
      // if(get_price_comparision_params.page !== a){
        if(true){
        set_get_price_comparision_params({...get_price_comparision_params, page:a, limit:b})
        props.get_price_comparision({...get_price_comparision_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_price_comparision_params({...get_price_comparision_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
    
        // userType:"",
        // level:"",
        // catalogue:''
      }})
      props.get_price_comparision({...get_price_comparision_params,page:1,  searchParams:{
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters:{
   
        // userType:"",
        // level:"",
        // catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_price_comparision_params({...get_price_comparision_params, page:1, filters:{
       
        // userType:"",
        // catalogue:'',
        // level:''
      }})
      props.get_price_comparision({...get_price_comparision_params, page:1, filters:{
      
        // userType:"",
        // catalogue:'',
        // level:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const columns = [

      {
          title: 'Service',
          dataIndex: 'service',
          key: 'service',
          width: '20%',
          ...getColumnSearchProps('service', "Search service", 'service'),

      },
      {
          title: 'Db Price',
          dataIndex: 'price',
          key: 'price',
          width: '20%'

      },
      {
          title: 'Hospital Price',
          dataIndex: 'catalogue_price',
          key: 'catalogue_price',
          width: '20%'

      },
      {
        title: 'Db Variance',
        dataIndex: 'variance',
        key: 'variance',
        width: '20%'

    },
    {
        title: 'Hospital variance',
        dataIndex: 'catalogue_variance',
        key: 'catalogue_variance',
        width: '20%'
    }
  ]
      function showTotal(total) {
        return `Total ${total} items`;
      }

   if(!!professional_selected){
        return <Redirect to={`/dashboard/catalogue-manager?catalogue_id=${professional_selected._id}`} />
   }

        return (<React.Fragment>
            <Card title="Price Comparison -  Hospital Catalog Vs DB Catalog">
            <Form
      form={form}
      layout="vertical"
    >
        {/* <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput 
              label="Speciality"  
              value={speciality} 
              disabled = {props.get_bids_loading_flag}
              options = {props.global_store.specialities}
              loading={props.global_store.get_specialities_loading}
              error_text={""} 
              toggle_error = {()=>console.log()} 
              error={false}
              name="speciality" 
              type="select"
              mode="multiple"
              onChange = {event=>{
                console.log(event)
                let specialityId = ""
                event.map((item,i)=>{
                  if(i!==0){
                    specialityId = `${specialityId},${item}`
                  }else{
                    specialityId = `${specialityId}${item}`
                  }
                  
                })
                set_get_price_comparision_params({...get_price_comparision_params, specialityId:specialityId, page:1})
                props.get_price_comparision({...get_price_comparision_params, specialityId:specialityId, page:1})
                set_speciality(event)
              }} 
             
              placeholder="Choose speciality" />
        </span>
        </div> */}
</Form>
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_price_comparision_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:get_price_comparision_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_price_comparision_params.page,
            total:get_price_comparision_params.total,
            showTotal:showTotal
          }}
        //   onRow={(r) => ({
        //     onClick: (b,a) => row_click(r),
        //   })}
          />
        </Card>
        </React.Fragment>)
}
export default ProfessionalsList