import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Pagination, Button, Modal, notification, Input } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import BlogsCard from "./BlogsCard";
import { get_url } from "../utils/common_utilities";

const BlogsTable = (props) => {
  const [blogs, setBlogs] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshData, setRefreshData] = useState(false);
  const [name, setName] = useState('')
  const itemsPerPage = 5;

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [blogDeleteModal, setBlogDeleteModal] = useState(false);

  const fetchBlogs = async (page, limit, name) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${get_url()}/blog/allBlogList`,
        {
          headers,
          params: { page, limit, name },
        }
      );

      if (true) {
        const blogsWithKeys = response.data.data.map((blog, index) => ({
          ...blog,
          key: blog._id || index,
        }));
        setBlogs(blogsWithKeys);
        setTotalBlogs(response.data.count);
      }
    } catch (error) {
      if (true) {
        console.error("Error fetching blogs:", error);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    fetchBlogs(currentPage, itemsPerPage, name);

    return () => {
      isMounted = false;
    };
  }, [currentPage, refreshData, props.reloadTable]);

  const showModal = (blog) => {
    setSelectedBlog(blog);
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
      setRefreshData(!refreshData);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
    setRefreshData(!refreshData);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const options = {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const getDateKey = (blog) => {
    return blog.updatedAt || blog.createdAt;
  };

  const handleDeleteBlog = () => {
    if (selectedBlog._id === "" || selectedBlog._id === undefined) {
      return;
    }
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    axios
      .post(
        `${get_url()}/blog/deleteBlogById?blogId=${selectedBlog._id}`,
        {},
        { headers }
      )
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: "Success",
            description: "Blog deleted successfully",
          });
          setRefreshData(!refreshData);
          setBlogDeleteModal(false);
        } else {
          notification.error({
            message: "Error",
            description: "Couldn't delete the blog",
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Couldn't delete the blog",
        });
      });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "left",
      className: "title",
      width: '300px',
      render: (text) => (
        <span>
          <Link className="title-link" to="" style={{wordBreak: "keep-all"}}>
            {text ? text : "N/A"}
          </Link>
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left",
      render: (text) => (
        <span
          style={{
            fontSize: "14px",
            lineHeight: "25px",
            wordSpacing: "5px",
            paddingRight: "1rem",
            maxWidth: "90%",
            wordBreak: "keep-all",
          }}
        >
          {text ? text : "N/A"}
        </span>
      ),
    },
    {
      title: "Date",
      key: "date",
      align: "left",
      width: '150px',
      render: (text, record) => (
        <span
          style={{
            fontSize: "14px",
            lineHeight: "25px",
            wordSpacing: "5px",
            paddingRight: "1rem",
          }}
        >
          {formatDate(getDateKey(record))}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "left",
      width: '100px',
      render: (text, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            className="edit-btn"
            onClick={() => showModal(record)}
          />
          <Button
            icon={<DeleteOutlined />}
            className="delete-btn"
            onClick={() => {
              setSelectedBlog(record);
              setBlogDeleteModal(true);
            }}
          />
        </span>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchByName = () => {
    fetchBlogs(1, itemsPerPage, name)
  }

  return (
    <>
      <div className="table-container">
        <Input.Search type="text" placeholder="Search by title" value={name} onChange={(e)=>setName(e.target.value)} onSearch={handleSearchByName} className="blog-search-input" />
        <Table
          columns={columns}
          dataSource={blogs}
          pagination={false}
          rowClassName={() => "custom-row"}
          bordered={false}
        />
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalBlogs}
          onChange={handlePageChange}
          style={{ marginTop: "20px", textAlign: "center" }}
        />
      </div>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={"90%"}
        key={selectedBlog?._id}
        footer={null}
      >
        <BlogsCard
          heading={"Edit Post"}
          header={false}
          initialData={selectedBlog}
          closeModal={() => setOpen(false)}
          editBlog={true}
          reloadTable={()=>setRefreshData(!refreshData)}
        />
      </Modal>
      <Modal
        width={600}
        open={blogDeleteModal}
        className="booking-delete-modal"
        onCancel={() => setBlogDeleteModal(false)}
        onOk={() => setBlogDeleteModal(false)}
      >
        <div className="delete-modal-text">
          <h3>Are you sure you want to delete this blog?</h3>
          <p>This action cannot be undone.</p>
        </div>
        <div className="delete-modal-btns">
          <Button
            className="cancel-button"
            onClick={() => setBlogDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="delete-button" onClick={() => handleDeleteBlog()}>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default BlogsTable;
