import React from "react"
import { Table, Input, Button, Space, Card, Form  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat} from '../../utils/common_utilities'


const ManualMessagesList = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })

      const [get_manual_sms_list, set_get_manual_sms_list] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
           
           },
           filters:{
            
           }
      })

      const [data, set_data] = React.useState([])
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])

    React.useEffect(()=>{
        props.get_manual_sms_list(get_manual_sms_list)
    },[])

    React.useEffect(()=>{
      if(!!props.get_manual_sms_list_ret){
        // console.log(props.get_manual_sms_list_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_manual_sms_list_ret.success){
            console.log(props.get_manual_sms_list_ret,"GET_MANUAL_SMS_LIST_RET")
            set_data(modif_data([...props.get_manual_sms_list_ret.data]))
            set_get_manual_sms_list({...get_manual_sms_list, total:props.get_manual_sms_list_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_manual_sms_list_ret.message,
            heading:'Professionals'
          })
         }
          props.get_manual_sms_list_loading()
      }
     
         
  },[props.get_manual_sms_list_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_manual_sms_list.filters}
       let updated_filters = {}
 
      
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === get_manual_sms_list.page) && (pagination.pageSize === get_manual_sms_list.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_manual_sms_list({...get_manual_sms_list, page:1, filters :{
           
            createdAt:filters.createdAt?filters.createdAt:''
          }})
          props.get_manual_sms_list({...get_manual_sms_list, page:1, filters :{
          
            createdAt:filters.createdAt?filters.createdAt:''        
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_manual_sms_list({...get_manual_sms_list, page:1,searchParams:{
           ...get_manual_sms_list.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_manual_sms_list({...get_manual_sms_list, page:1,searchParams:{
          ...get_manual_sms_list.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            name: item.name ? item.name : "---",
            createdAt: getDateFormat(item.createdAt),
            updateAt: getDateFormat(item.updateAt),
            mobileNumber: item.mobileNumber,
            messagesInformation: item.messagesInformation,
            _id:item._id
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_manual_sms_list,"a and b get_manual_sms_list")
      // if(get_manual_sms_list.page !== a){
        if(true){
        set_get_manual_sms_list({...get_manual_sms_list, page:a, limit:b})
        props.get_manual_sms_list({...get_manual_sms_list, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_manual_sms_list({...get_manual_sms_list,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
      props.get_manual_sms_list({...get_manual_sms_list,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
    }

    const expand = (record,a,b) => {
        if(expandedRowKeys[0]!== a._id){
           set_expandedRowKeys([a._id])
           console.log(record,a,b,"MANUAL_MESSAGE_LIST_RECORD,a,b")
        }else{
        set_expandedRowKeys([])
        }
      }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width:'25%',
            ...getColumnSearchProps('name', "Search Name", 'name'),
            // ellipsis: true,
        },
        {
            title: 'Phone',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            width:'25%',
            ...getColumnSearchProps('mobileNumber', "Search email", 'mobileNumber'),
            // ellipsis: true,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'25%',
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
        },
        {
            title: 'Updated At',
            dataIndex: 'updateAt',
            key: 'updateAt',
            width:'25%',
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
        },
    ]

    const subColumns = [
        {
          title: 'Template Name',
          dataIndex: 'templateName',
          key: 'templateName',
          width:'33%',
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          // ellipsis: true,
        },
        {
            title: 'Template Type',
            dataIndex: 'templateType',
            key: 'templateType',
            width:'33%',
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
        },
        {
            title: 'Time',
            dataIndex: 'sentDate',
            key: 'sentDate',
            width:'33%',
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
        },
    ]

    function showTotal(total) {
      return `Total ${total} items`;
    }


        return (<React.Fragment>
            <Card title="Manually Sent Messages">
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_manual_sms_list_loading_flag}
          onChange={handleChange}
          pagination = {{
            showSizeChanger:true,
            limit:get_manual_sms_list.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_manual_sms_list.page,
            total:get_manual_sms_list.total,
            showTotal:showTotal
            
          }}
          expandedRowKeys={expandedRowKeys}
          onExpand = {(a,b,c) =>expand(a,b,c)}
          rowKey={record => record._id}
           expandedRowRender= {record =>{
            return   <div key={record._id}>
                {console.log(record, "MANUAL_MESSAGE_LIST_RECORD")}
                <Table
                    columns={subColumns}
                    dataSource={record.messagesInformation}
                />
           </div> }}
          />
        </Card>
        </React.Fragment>)
}
export default ManualMessagesList