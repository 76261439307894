import React from "react"
import { Table, Input, Button, Space, Card,  Menu, Dropdown, Row, Col, Form  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
import Servicelist from "./Servicelist"
import SearchLocation from "../../Components/SearchLocation"
import  { Link } from "react-router-dom"

const { SubMenu }  = Menu


const SentBusinessReports = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [form] = Form.useForm();
      const [variance_info, set_variance_info] = React.useState([])
      const [selected_row , set_selected_row] = React.useState([])
      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [selected_professionals, set_selected_professionals] =React.useState([])
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [previous_business_reports, set_previous_business_reports] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
           
           },
           filters:{
            
           }
      })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [list, set_list] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [error, set_error] = React.useState({
        location:{state:false, text:''}
      })
    React.useEffect(()=>{
        props.previous_business_reports(previous_business_reports)
    },[])

    React.useEffect(()=>{
      if(!!props.previous_business_reports_ret){
        // console.log(props.previous_business_reports_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.previous_business_reports_ret.success){
          set_data(modif_data([...props.previous_business_reports_ret.data]))
          set_previous_business_reports({...previous_business_reports, total:props.previous_business_reports_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.previous_business_reports_ret.message,
            heading:'Professionals'
          })
         }
          props.previous_business_reports_loading()
      }
     
         
  },[props.previous_business_reports_ret])

  function handleButtonClick(e) {
    console.log('click left button', e);
  }
  
  function handleMenuClick(e) {
    console.log('click', e);
  }



     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...previous_business_reports.filters}
       let updated_filters = {}
       updated_filters.locality = "All"
       updated_filters.level = filters.level
       updated_filters.catalogue = filters.catalogue
       updated_filters.userType = filters.userType
      if(!!((pagination.current === previous_business_reports.page) && (pagination.pageSize === previous_business_reports.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_previous_business_reports({...previous_business_reports, page:1, filters :{
           locality:"All",
           userType: filters.userType? filters.userType:'',
           level:filters.level?filters.level:'',
           catalogue:filters.catalogue?filters.catalogue:''
          }})
          props.previous_business_reports({...previous_business_reports, page:1, filters :{
           locality:"All",
           userType: filters.userType? filters.userType:'',
           level:filters.level?filters.level:'',
           catalogue:filters.catalogue?filters.catalogue:''        
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_previous_business_reports({...previous_business_reports, page:1,searchParams:{
           ...previous_business_reports.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.previous_business_reports({...previous_business_reports, page:1,searchParams:{
          ...previous_business_reports.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            businessLeads:item.businessLeads===""?'N/A':item.businessLeads,
            consultationLeads:item.consultationLeads===""?'N/A':item.consultationLeads,
            consultationMessage: item.textMessage===""?"N/A":item.textMessage,
            businessMessage:item.businessMessage===""?"N/A":item.businessMessage,
            businessNotification:item.businessNotification===""?'N/A':item.businessNotification,
            consultationNotification:item.pushMessage===""?'N/A':item.pushMessage,
            messageSent: item.messageSent?"YES":"NO",
            notificationSent: item.notificationSent?"YES":"NO",
            emailSent: item.emailSent?"YES":"NO",
            createdAt:getDateFormat(item.createdAt),
            userReports:item.userReports,
            _id: item._id
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_previous_business_reports({...previous_business_reports, limit:b, page:1})
      props.previous_business_reports({...previous_business_reports, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,previous_business_reports,"a and b previous_business_reports")
      // if(previous_business_reports.page !== a){
        if(true){
        set_previous_business_reports({...previous_business_reports, page:a, limit:b})
        props.previous_business_reports({...previous_business_reports, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_previous_business_reports({...previous_business_reports,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
      props.previous_business_reports({...previous_business_reports,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_previous_business_reports({...previous_business_reports, page:1, filters:{
        locality:"All",
        userType:"",
        catalogue:'',
        level:''
      }})
      props.previous_business_reports({...previous_business_reports, page:1, filters:{
        locality:"All",
        userType:"",
        catalogue:'',
        level:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const columns_professional = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          // ellipsis: true,
          ...getColumnSearchProps('name', "Search name", 'name'),
        },
        {
            title: 'Phone number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            ...getColumnSearchProps('mobileNumber', "Search mobileNumber", 'mobileNumber'),
            // ellipsis: true,
          },
        {
          title: 'TYPE',
          dataIndex: 'type',
          key: 'type',
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          // ellipsis: true,
        },
        {
            title: 'Success',
            dataIndex: 'success',
            key: 'success',
            render:(val)=>{
                return <React.Fragment>
                    {val?"SUCCESS":"FAILED"}
                </React.Fragment>
            }
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
          }
        //   {
        //     title: 'Text Message',
        //     dataIndex: 'textMessage',
        //     key: 'textMessage',
        //   //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        //     // ellipsis: true,
        //   }
        ]

    const columns = [
        {
          title: 'Email',
          dataIndex: 'emailSent',
          key: 'emailSent',
          width:'10%'
        },
        {
            title: 'Message',
            dataIndex: 'messageSent',
            key: 'messageSent',
            width:'10%'
          },
        {
          title: 'Notification (Consultation)',
          dataIndex: 'consultationNotification',
          key: 'consultationNotification',
          width:'15%'
        },
        {
            title: 'Notification (Business)',
            dataIndex: 'businessNotification',
            key: 'businessNotification',
            width:'15%'
          },
          {
            title: 'Text Business ',
            dataIndex: 'businessMessage',
            key: 'businessMessage',
            width:'15%'
          },
          {
            title: 'Text Consultation',
            dataIndex: 'consultationMessage',
            key: 'consultationMessage',
            width:'15%'
          },
          {
            title: 'C.Leads',
            dataIndex: 'consultationLeads',
            key: 'consultationLeads',
            width:'10%'
          },
          {
            title: 'B.Leads',
            dataIndex: 'businessLeads',
            key: 'businessLeads',
            width:'10%'
          }
      ];

     const onSelectChange = data => {
        console.log('selectedRowKeys changed: ', data);
        // this.setState({ selectedRowKeys });
      }
      const rowSelection = {
        selectedRowKeys,
        onChange: (e,b)=>console.log(e,b),
      };

      const expand = (record,a,b) => {
        console.log(record,a,b,"record,a,b")
        if(expandedRowKeys[0]!== a._id){
           set_expandedRowKeys([a._id])
           console.log(a,"a.varianceInfo")
           set_variance_info(a.varianceInfo)
        }else{
        set_expandedRowKeys([])
        }
      }



    const debounce = (func, wait) => {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {

          timeout = null

          func(...args);
        }

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);
      }
    }
    const returnedFunction = debounce(function(data) {
     
      try{
         variance_change(data)
      }catch(e){
       console.log(e)
      }
      // setState({...state, selectPlace:data})
     
    }, 500);
    function showTotal(total) {
      return `Total ${total} items`;
    }

    const variance_change = (data) => {
      set_previous_business_reports({...previous_business_reports,serviceName:data})
      props.previous_business_reports({...previous_business_reports,serviceName:data})
    }

    console.log(selected_row,"selected_row")


        return (<React.Fragment>
           
        <Table
          columns={columns}
          dataSource={data}
          loading={props.previous_business_reports_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          expandedRowRender= {record =>{
            console.log(record,"record")
            set_selected_row(record.userReports)
            return <React.Fragment>
              <div key={record._id}>
           <Table
             columns={columns_professional}
             dataSource = {selected_row}
           />
           </div>
</React.Fragment>
          }}
          expandedRowKeys={expandedRowKeys}
          onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
          onExpand = {(a,b,c) =>expand(a,b,c)}
          rowKey={record => record._id}
        //   rowSelection={{
        //      selectedRowKeys:selectedRowKeys,
        //      onChange: (e,b)=>{
        //        setSelectedKeys([...e])
        //        set_selected_professionals([...b.map(item=>{
        //          return {
        //            name:item.name,
        //            _id:item._id
        //          }
        //        })])
        //      },
        //   }}
          pagination = {{
            showSizeChanger:true,
            limit:previous_business_reports.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:previous_business_reports.page,
            total:previous_business_reports.total,
            showTotal:showTotal
          }}
         
          />
      
        </React.Fragment>)
}
export default SentBusinessReports