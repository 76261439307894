import { GET_REAL_TIME_INSIGHTS_LOADING, GET_REAL_TIME_INSIGHTS, GET_REAL_TIME_INSIGHTS_RET,
    GET_ACTIONABLE_INSIGHTS_LOADING, GET_ACTIONABLE_INSIGHTS_RET, GET_ACTIONABLE_INSIGHTS,
    GET_SUGGESTED_INSIGHTS, GET_SUGGESTED_INSIGHTS_RET,GET_SUGGESTED_INSIGHTS_LOADING } from "../Constants"

const initialState = {

    get_real_time_insights:false,
    get_real_time_insights_ret:false,
    get_real_time_insights_loading:false,

    get_actionable_insights:false,
    get_actionable_insights_ret:false,
    get_actionable_insights_loading:false,

    get_suggested_insights:false,
    get_suggested_insights_ret:false,
    get_suggested_insights_loading:false

}

export default function (state = initialState, action) {
    switch (action.type) {
   
    case GET_REAL_TIME_INSIGHTS:
        return {
            ...state,
            get_real_time_insights:action.data,
            get_real_time_insights_loading:true
        }
    case GET_REAL_TIME_INSIGHTS_RET:
        return {
            ...state,
            get_real_time_insights_ret:action.data,
            get_real_time_insights_loading:true
        }
    case GET_REAL_TIME_INSIGHTS_LOADING:
        return {
            ...state,
            get_real_time_insights_ret:false,
            get_real_time_insights_loading:false
        }

    case GET_ACTIONABLE_INSIGHTS:
        return {
            ...state,
            get_actionable_insights:action.data,
            get_actionable_insights_loading:true
        }
    case GET_ACTIONABLE_INSIGHTS_RET:
        return {
            ...state,
            get_actionable_insights_ret:action.data,
            get_actionable_insights_loading:true
        }
    case GET_ACTIONABLE_INSIGHTS_LOADING:
        return {
            ...state,
            get_actionable_insights_ret:false,
            get_actionable_insights_loading:false
        }


    case GET_SUGGESTED_INSIGHTS:
        return {
            ...state,
            get_suggested_insights:action.data,
            get_suggested_insights_loading:true
        }
    case GET_SUGGESTED_INSIGHTS_RET:
        return {
            ...state,
            get_suggested_insights_ret:action.data,
            get_suggested_insights_loading:true
        }
    case GET_SUGGESTED_INSIGHTS_LOADING:
        return {
            ...state,
            get_suggested_insights_ret:false,
            get_suggested_insights_loading:false
        }
    

        default:
        return {...state}
    
        }
}