import React from "react"
import {  Card, Form, Button, Row, Col, Tag, Typography, Alert } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { error_form_check } from '../../utils/common_utilities'
const { Title } = Typography;

const SemdNotification = (props) => {
    const [form] = Form.useForm();
    
    const [data, set_data] = React.useState({
           message:'',
           title:''
    })
    const [error, set_error] = React.useState({
          message:{ state:false, text:"" },
          title:{ state:false, text:"" }
 })
 const send_message = () => {
    const ret_error = validate_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_sms({
                type:props.type,
                solutionId:props.solutionId,
                message:data.message,
                title:data.title
        })
    }
 }

 const validate_message  = (data) =>{
  const  error = {
    message:{ state:false, text:"" },
    title:{ state:false, text:"" }
    }
console.log(data,"data sdsdsdsd")
    if(data.message===''){
      {
        error.message.text = "Please enter your message"
        error.message.state = true
      }
    }
    if(data.title===''){
      {
        error.title.text = "Please enter title"
        error.title.state = true
      }
    }
    return error
  }


console.log(props.sms,"props.sms")

   return <React.Fragment>
        <Row>
            <Col span={12}>
            <Form
      form={form}
      layout="vertical"
    >
 
<div className="flex-parent-new">
  <span className="flex-child-1-new">
  <Title level={5}>Send Notification</Title>
  <AntdInput label="Title"  
            value={data.title} 
            error_text={error.title.text} 
            loading = {props.send_message_loading_flag}
            disabled = {props.send_message_loading_flag} 
            toggle_error = {()=>set_error({...error, title:{text:'',state:false}})} 
            error={error.title.state} 
            name="title" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter Title"
            /> 
  <AntdInput label="Message"  
            value={data.message} 
            error_text={error.message.text} 
            loading = {props.send_message_loading_flag}
            disabled = {props.send_message_loading_flag} 
            toggle_error = {()=>set_error({...error, message:{text:'',state:false}})} 
            error={error.message.state} 
            name="message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            /> 
  <Button loading={props.send_message_loading_flag} onClick={()=>send_message()} type={"primary"}>Send</Button>
   </span>

 
   </div>

   </Form>
            </Col>
        
        </Row>
   </React.Fragment>
}

export default SemdNotification