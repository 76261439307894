import React from "react"
import { Button , Card,  Menu, Form, Modal, Row, Col, Input, Tag  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { error_form_check, isEmpty, get_month } from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
//import Servicelist from "./Servicelist"
import SearchLocation from "../../Components/SearchLocation"
import  { Link } from "react-router-dom"
import { set_services } from "../../actions/global";
import validator from "validator"
import {
    ArrowRightOutlined,
    EditOutlined
  } from '@ant-design/icons';

import BarChart from "../../Components/BarChart";
import LineChart from "../../Components/LineChart";
import PriceComparision from "./PriceComparision"

const { SubMenu }  = Menu
const { Search } = Input



const options = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
    { label: 'Other', value: 'O' },
  ]

const CatalogueManagerView = (props) => {
      const [state, set_state] = React.useState({
          check_status:"PENDING"
      })
      const [update_price, set_update_price] = React.useState({
        price:'',
        variance:'',
        professional_id:'',
        professional_name:''
      })
      const [professional_id, set_professional_id] = React.useState('')
      const [ update_price_error, set_update_price_error ] = React.useState({
        price:{ state:false, text:"" },
        variance:{ state:false, text:"" }
      })
      const [graph_data, set_graph_data] = React.useState({
        x_array:[],
        y_array:[]
      })

      const [searched_prof, set_searched_prof] = React.useState([])

      const [records_params, set_records_parmas] = React.useState({
        page:1,
        limit:10
      })
      const [visible, set_visible] = React.useState(false)
      const [records , set_records] = React.useState([])
      const [confirm_booking, set_confirm_booking] = React.useState(false)
      const [hospital_data, set_hospital_data] = React.useState({})
      const [data, set_data] = React.useState({
        speciality:'',
        service:'',
        location:'',
        lng:'',
        lat:''
    })
    const [error, set_error] = React.useState({
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
        location:{ state:false, text:"" }
 })
     const [initial_data , set_initial_data] = React.useState({})
      const [form] = Form.useForm()

React.useEffect(()=>{
  if(!!props.get_bar_chart_data_ret){
  
     if(props.get_bar_chart_data_ret.success){
              let y_array = []
              y_array.push(props.get_bar_chart_data_ret.average.price)
              let x_array = [{
                name:"Plunes Average",
                price:props.get_bar_chart_data_ret.average.price,
                variance:0
              }]
              let data = [...props.get_bar_chart_data_ret.data]
              data.forEach((item, i)=>{
                y_array.push(item.price)
                let obj = {
                  name:item.name,
                  variance:item.variance,
                  price:item.price,
                  professionalId:item.professionalId
                }
                x_array.push(obj)
              })
              set_graph_data({x_array, y_array})
     }else{
      props.global_error({
        success:false,
        message:props.get_bar_chart_data_ret.message,
        heading:'Professionals'
      })
     }
      props.get_bar_chart_data_loading()
  }


  if(!!props.get_prof_info_ret){
  
    if(props.get_prof_info_ret.success){
          console.log(props.get_prof_info_ret,"get_prof_info_ret")
          set_hospital_data({
            ...props.get_prof_info_ret.data
          })
    }else{
     props.global_error({
       success:false,
       message:props.get_prof_info_ret.message,
       heading:'Professionals'
     })
    }
     props.get_prof_info_loading()
 }



 if(!!props.update_price_catalogue_ret){
  
  if(props.update_price_catalogue_ret.success){
    props.get_bar_chart_data({
      lat:data.lat,
      serviceId:data.service,
      lng:data.lng
  })
        props.global_error({
          success:true,
          message:props.update_price_catalogue_ret.message,
          heading:'Edit Service'
        })
  }else{
   props.global_error({
     success:false,
     message:props.update_price_catalogue_ret.message,
     heading:'Edit Service'
   })
  }
   props.update_price_catalogue_loading()
}
 
  
},[props.get_bar_chart_data_ret, props.get_prof_info_ret, props.update_price_catalogue_ret])

const onSearch = value =>  {
  if(value.length!==0){
        let arr = graph_data.x_array.filter((x)=>{
             return x.name.toLowerCase().includes(value.toLowerCase())
        })
        set_searched_prof([...arr])
  }else {
        set_searched_prof([])
  }
}


React.useEffect(()=>{
    if(props.add_service_ret){
            if(props.add_service_ret.success){
                props.global_error({
                    success:true,
                    message:props.add_service_ret.message,
                    heading:"Add Service",
                  })
            }else {
                props.global_error({
                    success:false,
                    message:props.add_service_ret.message,
                    heading:"Add Service",
                  })
            }
        props.add_service_loading()
    }

    if(props.get_records_ret){
      if(props.get_records_ret.success){

          let data = props.get_records_ret.data
          console.log(data,"data")

          let records = data.map((item)=>{
            let obj = {
              price:item.price,
              previous_price:item.oldPrice,
              serviceName:item.serviceName,
              updated_by:item.updatedFrom==="ANALYTICS_PANEL"?"ANALYTICS_PANEL":"PLUNES"
            }
            return obj
          })

          set_records([...records])
        
      }else {
          props.global_error({
              success:false,
              message:props.get_records_ret.message,
              heading:"Records",
            })
      }
  props.get_records_loading()
}

},[props.add_service_ret, props.get_records_ret])

const speciality_change = (val) => {
    set_data({...data, "speciality":val, category:''})
    props.get_services({
      speciality:val
    })
}



const validate  = (data) =>{
    const  error = {
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
        location:{ state:false, text:"" }
      }
      if(data.speciality===''){
        {
          error.speciality.text = "Select speciality"
          error.speciality.state = true
        }
      }  
  if(data.service===''){
    {
      error.service.text = "Enter service"
      error.service.state = true
    }
  }
  if(data.location===''){
    {
      error.location.text = "Enter Location"
      error.location.state = true
    }
 
  }
    return error
  }
const submit = () => {
    const ret_error = validate({...data});
    set_error({...error, ...ret_error});
    console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
          props.get_bar_chart_data({
              lat:data.lat,
              serviceId:data.service,
              lng:data.lng
          })
    }
}


const validate_update_price  = (data) =>{
  const  error = {
      price:{ state:false, text:"" },
      variance:{ state:false, text:"" }
    }
    if(update_price.price===''){
      {
        error.price.text = "Select speciality"
        error.price.state = true
      }
    }  
if(update_price.variance===''){
  {
    error.variance.text = "Enter service"
    error.variance.state = true
  }
}
  return error
}

const submit_price= () => {
  const ret_error = validate_update_price({...update_price});
  set_update_price_error({...update_price_error, ...ret_error});
  console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
      props.update_price_catalogue({
        specialityId:data.speciality,
        serviceId:data.service,
        price:update_price.price,
        variance:update_price.variance,
        professional_id:update_price.professional_id
      })
    }
}

const  handleCancel = e => {
  console.log(e);
  set_update_price({
    price:'',
    variance:'',
    professional_name:'',
    professional_id:'',
    visible:false
  })
  set_update_price_error({
    price:{ state:false, text:"" },
    variance:{ state:false, text:"" }
  })
}

const make_booking = () => {
    props.make_booking({
        serviceId: data.service,
        paymentPercent: 100,
        timeSlot: data.slot,
        date:data.date_string,
        professionalId: data.doctor,
        userId: initial_data._id,
        creditsUsed: false,
        coupon: "",
        bookIn: null,
        doctorId: null,
        bookingStatus: 'Requested',
        price:data.amount
    })
}
console.log(professional_id, "professional_id")


        return (<React.Fragment>
            <Card title="Catalogue Mananger">
                    <Row >
                        
                        <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            >
                                <div className="flex-parent-new">
                                <span className="flex-child-3-new">
                                    <AntdInput 
                                    label="Speciality"  
                                    value={data.speciality} 
                                    disabled = {props.get_bids_loading_flag ||props.get_bar_chart_data_loading_flag}
                                    options = {props.global_store.specialities}
                                    loading={props.global_store.get_specialities_loading || props.get_bar_chart_data_loading_flag}
                                    error_text={error.speciality.text} 
                                    toggle_error = {()=>set_error({...error,speciality:{text:'',state:false}})} 
                                    error={error.speciality.state}
                                    name="speciality" 
                                    type="select"
                                    onChange = {event=>speciality_change(event)} 
                                    placeholder="Choose speciality" />
                                </span>

                                <span className="flex-child-3-new">
                                    <AntdInput 
                                    label="Service"  
                                    value={data.service} 
                                    options = {props.global_store.services}
                                    loading={props.global_store.get_services_loading || props.get_bar_chart_data_loading_flag}
                                    disabled = {props.get_bids_loading_flag ||props.get_bar_chart_data_loading_flag}
                                    error_text={error.service.text} 
                                    toggle_error = {()=>set_error({...error,service:{text:'',state:false}})} 
                                    error={error.service.state}
                                    name="service" 
                                    type="select"
                                    onChange = {event=>{
                                        console.log(event,"event in Add Service")
                                        set_data({...data, service:event})
                                    }} 
                                    placeholder="Enter service name" />
                                </span>

                                <span className="flex-child-3-new">
                                  <SearchLocation  
                                          label="Location"  
                                          value={data.location} 
                                          disabled = {props.get_bids_loading_flag || props.get_bar_chart_data_loading_flag}
                                          error_text={error.location.text} 
                                          toggle_error = {()=>set_error({...error,location:{text:'',state:false}})} 
                                          error={error.location.state}
                                          name="location" 
                                          set_long_lat = {val=>{
                                            set_data({...data,...val})
                                            
                                          }}
                                          placeholder="Enter Location"
                                    />
                                </span>
                               
                                </div>

                          

                                <div className="text-center">
                                <Button
                                    type="primary"
                                    loading={props.add_service_loading_flag}
                                    disabled={props.add_service_loading_flag}
                                    onClick={() => submit()}
                                    style={{ width: 'auto', margin:'auto' }}
                                  >
                                    Submit
                                 </Button>
                                </div>

              <div  className="flex-parent-new u-margin-top-small" >
                <div style={{width:'100%'}}>
                  <div className='u-margin-top-small tag-wrapper'>
                     <Tag onClick={()=> props.get_bar_chart_data({
                            lat:data.lat,
                            serviceId:data.service,
                            lng:data.lng,
                            level:"1"
          })} style={{cursor:'pointer'}} color="default">Level 1 Hospitals</Tag>
                     <Tag onClick={()=> props.get_bar_chart_data({
              lat:data.lat,
              serviceId:data.service,
              lng:data.lng,
              level:'2'
          })}  style={{cursor:'pointer'}} color="default">Level 2 Hospitals</Tag>
                     <Tag onClick={()=> props.get_bar_chart_data({
              lat:data.lat,
              serviceId:data.service,
              lng:data.lng,
              level:'3'
          })}  style={{cursor:'pointer'}} color="default">Level 3 Hospitals</Tag>
                     <Tag onClick={()=> props.get_bar_chart_data({
              lat:data.lat,
              serviceId:data.service,
              lng:data.lng
          })}  style={{cursor:'pointer'}} color="processing">View All</Tag>
                  </div>
                  <div className="catalogue_chart_wrapper">
                       <BarChart
                          data = {graph_data}
                          get_prof_info = {props.get_prof_info}
                          get_records = {props.get_records}
                          records_params = {records_params}

                          set_professional_id = {set_professional_id}
                          get_price_comparision = {props.get_price_comparision}
                       />
                  </div>  
                    
                  </div>
              </div>
</Form>

</Col>
  
              <Col span={16}>
                    <div className="table-wrapper">


                              <div className="table-heading">
                                      <span className="heading-4">
                                            <Search
                                                placeholder="Search Hospitals"
                                                allowClear
                                                onSearch={onSearch}
                                                style={{ width: 200, margin: '0 10px' }}
                                            />
                                      </span>
                                   
                                      <span className="heading-2">
                                            <text className="table-heading">
                                                Amount
                                             </text>
                                      </span>
                                      <span className="heading-2">
                                          <text className="table-heading">
                                              Variance
                                          </text>
                                      </span>
                              </div>
                              
                              <div className="table-body overflow-scroll">

                                {searched_prof.length === 0 ?graph_data.x_array.map(item=>{
                                  return <div  style={{cursor:'pointer'}} onClick={()=>{
                                    set_update_price({
                                      price:item.price,
                                      validate:item.variance,
                                      professional_name:item.name,
                                      professional_id:item.professionalId,
                                      visible:true
                                    })
                                  }} className="table-body-row ">
                                  <span className="table-body-4">
                                     <text className="table-body-text">
                                     {item.name}
                                     </text>             
                                  </span>
                                  <span className="table-body-2">       
                                    <text className="table-body-text">
                                    &#8377;{item.price}
                                    </text>
                                  </span>
                                  <span className="table-body-2"> 
                                    <text className="table-body-text">
                                    {item.variance}
                                    </text>
                                    <span>
                                      <EditOutlined />
                                    </span>
                                  </span>
                                </div>
                                }) :searched_prof.map(item=>{
                                  return  <div  style={{cursor:'pointer'}} onClick={()=>{
                                    set_update_price({
                                      price:item.price,
                                      validate:item.variance,
                                      professional_name:item.name,
                                      visible:true,
                                      professional_id:item.professionalId
                                    })
                                  }} className="table-body-row ">
                                  <span className="table-body-4">
                                     <text className="table-body-text">
                                     {item.name}
                                     </text>             
                                  </span>
                                  <span className="table-body-2">       
                                    <text className="table-body-text">
                                    &#8377;{item.price}
                                    </text>
                                  </span>
                                  <span className="table-body-2"> 
                                    <text className="table-body-text">
                                    {item.variance}
                                    </text>
                                    <span>
                                    <EditOutlined />
                                    </span>
                                  </span>
                                </div>
                                })}

                                {graph_data.x_array.map(item=>{
                                  return   
                                })}  
                              </div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className="table-wrapper">
                        
                    <div className="table-heading">
                                      <span className="heading-half">
                                            <span className="orange-box"></span>
                                            <text className="table-body-text">
                                               Changed by Hospital
                                            </text>
                                       
                                      </span>
                                      <span className="heading-half">
                                      <span className="green-box"></span>
                                         <text className="table-body-text">
                                           Changed by Plunes
                                        </text>
                                      </span>
                                     
                              </div>
                              <div className="table-body">
                                        <div className="u-margin-top-mini u-margin-bottom-small u-align-center">
                                          {/* <Search
                                                placeholder="Search specility"
                                                allowClear
                                                onSearch={onSearch}
                                                style={{ width: 200, margin: '0 10px' }}
                                            /> */}
                                        </div>


                                        {records.map(item=>{
                                          return  <div className="wrapper-card">
                                          <span title={item.updated_by==="ANALYTICS_PANEL"?"Hospital":'Plunes'} className="wrapper-card-4">
                                                <span className={item.updated_by==="ANALYTICS_PANEL"?"orange-box":'green-box'}>

                                                </span>
                                                <text className="wrapper-card-text">
                                                    {item.serviceName}
                                                </text>
                                          </span>

                                          <span className="wrapper-card-3">
                                             <text className="wrapper-card-text">
                                             &#8377;{item.previous_price}
                                              </text>
                                          </span>

                                          <span className="wrapper-card-3">
                                               <ArrowRightOutlined />
                                          </span>

                                          <span className="wrapper-card-3">
                                              <text className="wrapper-card-text">
                                              &#8377;{item.price}
                                              </text>
                                          </span>

                                    </div>
                                        })}
                              </div>
                    </div>
                </Col>
                <Col span={8} >
                   <div className="u-margin-top-mini u-margin-bottom-small u-align-center">
                      {/* <Search
                            placeholder="Search hospital"
                            allowClear
                            onSearch={()=>console.log()}
                            style={{ width: '100%', margin: '0 10px' }}
                        /> */}
                    </div>
                    <div className="u-margin-top-mini u-align-center">
                                <div className="">
                                    <div className="profile-wrapper">
                                        <img
                                         src={hospital_data.coverImageUrl ?hospital_data.coverImageUrl:'../img/cover-stock.jpeg'} 
                                        className="cover-img-small" />
                                        <img 
                                        src={hospital_data.professionalImageUrl ?hospital_data.professionalImageUrl:'http://imgc.allpostersimages.com/images/P-473-488-90/68/6896/2GOJ100Z/posters/despicable-me-2-minions-movie-poster.jpg'} 
                                        alt={hospital_data.name?hospital_data.name:"Cover Image Url"} 
                                        className="image--cover profile-image" />
                                    </div>
                                     <div className="u-center-text">
                                        <h3 className="bold-heading">{hospital_data.name?hospital_data.name:'Mahajan Imaging'}</h3>
                                        <div className="">{hospital_data.state?`${hospital_data.subLocality}, ${hospital_data.state}`:'New Delhi, 231222  '}</div>

                                            <div className="u-margin-top-mini">{hospital_data.mobileNumber?`Phone no. ${hospital_data.mobileNumber}`:"Phone no. 9238573948"}</div>
                                            <div className=""> {hospital_data.email?`Email id. ${hospital_data.email}`:"Email id. doitfor@231"} </div>
                                     </div>
                                </div>
                    </div>
                </Col>
                <Col span={16} >
                <div className="introdiuction-wrapper">
                <h3 className="bold-heading u-margin-top-mini">Introduction</h3>
                <div className="u-margin-top-mini">
                           {hospital_data.biography?hospital_data.biography:'N/A'}
                </div>
                  </div>
                </Col>   
                    </Row>
                <Row>
                  {/* <Col span={8}>
                  <div className="u-margin-top-medium u-margin-bottom-small u-align-center">
                      <Search
                            placeholder="Search speciality"
                            allowClear
                            onSearch={onSearch}
                            style={{ width: '100%', margin: '0 10px' }}
                        />
                    </div>
                    <div className="wrapper specilaity-wrapper">
                          <div className="speciality-item">
                            Dentist
                          </div>
                          <div className="speciality-item">
                            Dermatology
                          </div>
                          <div className="speciality-item">
                            Pathilogy
                          </div>
                          <div className="speciality-item">
                            IVF
                          </div>
                          <div className="speciality-item">
                            Gynaecology
                          </div>
                          <div className="speciality-item">
                            Dentist
                          </div>
                          <div className="speciality-item">
                            Dermatology
                          </div>
                          <div className="speciality-item">
                            Pathilogy
                          </div>
                          <div className="speciality-item">
                            IVF
                          </div>
                          <div className="speciality-item">
                            Gynaecology
                          </div>
                          <div className="speciality-item">
                            Dentist
                          </div>
                          <div className="speciality-item">
                            Dermatology
                          </div>
                          <div className="speciality-item">
                            Pathilogy
                          </div>
                          <div className="speciality-item">
                            IVF
                          </div>
                          <div className="speciality-item">
                            Gynaecology
                          </div>
                    </div>
                  </Col>
                  <Col span={16}>
                    <div className="u-margin-top-medium u-margin-bottom-small introdiuction-wrapper">
                    <h3 className="bold-heading">Number of speciality</h3>
                    <div>Number of services added to the Speciality</div>
                        <div className="catalogue_chart_wrapper">
                          <LineChart
                          data = {[]}
                          />
                        </div>
                    </div>
                  </Col> */}
                </Row>
                <div className="u-margin-top-medium u-margin-bottom-small ">
                  <Row>
                     <Col span = {24}>
                    <PriceComparision
                            get_price_comparision_loading = {props.get_price_comparision_loading}
                            get_price_comparision = {props.get_price_comparision}
                            get_price_comparision_loading_flag = {props.get_price_comparision_loading_flag}
                            get_price_comparision_ret = {props.get_price_comparision_ret}

                            professional_id = {professional_id}

                            global_store = {props.global_store}
                            global_error = {props.global_error}
                    />
                    </Col>
                </Row>

                    </div>
            
                   
            </Card>


        <Modal
          title= {`Edit Price for ${update_price.professional_name}`}
          visible={update_price.visible}
          onOk={submit_price}
          onCancel={handleCancel}
        >
           <div className="flex-parent-new u-margin-top-small">
                <span className="flex-child-1-new">
                <AntdInput label="Amount"  
                      value={update_price.price} 
                      error_text={update_price_error.price.text} 
                      loading = {props.update_price_catalogue_loading_flag}
                      disabled = {props.update_price_catalogue_loading_flag} 
                      toggle_error = {()=>set_update_price_error({...update_price_error, price:{text:'',state:false}})} 
                      error={update_price_error.price.state} 
                      name="price" 
                      onChange = {event=>set_update_price({...update_price, price:event.target.value})} 
                      placeholder="Enter Price" 
                      validation = "positive_integer"
                  />  
                </span>
                             
                <span className="flex-child-1-new">
                <AntdInput label="Variance"  
                      value={update_price.variance} 
                      error_text={update_price_error.variance.text} 
                      loading = {props.update_price_catalogue_loading_flag}
                      disabled = {props.update_price_catalogue_loading_flag} 
                      toggle_error = {()=>set_update_price_error({...update_price_error, variance:{text:'',state:false}})} 
                      error={update_price_error.variance.state} 
                      name="variance" 
                      onChange = {event=>set_update_price({...update_price, variance:event.target.value})} 
                      placeholder="Enter variance" 
                      validation = "positive_integer"
                  />  
                </span>
          </div>
      </Modal>
        </React.Fragment>)
}
export default CatalogueManagerView