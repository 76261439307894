import React, { useEffect } from "react"
import { Table, Input, Button, Space, Card, Select, Modal, PageHeader } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import SendSms from "./SendSms"
import AntdInput from "../../Components/AntdInput"
import BulkSms from "./BulkSms"
import BulkSmsList from "./BulkSmsList"
const { Option }  =  Select
// import Map from "./Map.js"


const Covidqueries = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [covid_queries_params, set_covid_queries_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
             name:'',
             mobileNumber:''
           },
           filters:{
            status:'',
            createdAt:1000
           }
      })

      const [data, set_data] = React.useState([])
      const [sms_select, set_sms_select] = React.useState({})
      const [bulk_sms_list , set_bulk_sms_list] = React.useState(false)
      const [selected_status, set_selected_status] = React.useState('')
      const [selected_row, set_selected_row] = React.useState({})
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [selected_prospect, set_selected_prospect] = React.useState('')
      const [selected_queries, set_selected_queries] =React.useState([])
      const [send_sms_flag, set_send_sms_flag] = React.useState(false)
      const [note, set_note] = React.useState({
        note:''
         })
      const [error, set_error] = React.useState({
          note:{ state:false, text:"" }
          })
    const [visible, set_visible] = React.useState(false)

    const [template_sms_select, set_template_sms_select] = React.useState({})


 useEffect(()=>{
  if(!!props.update_prospect_ret){
    if(props.update_prospect_ret.success){
     props.global_error({
       success:true,
       message:props.update_prospect_ret.message,
       heading:'Update Prospect'
     })
     if(visible){
        let new_data = data.map(item=>{
          if(!!(item._id === selected_row._id)){
            return {
              ...item,
              note:note.note
            }
          }else{
            return item
          }
        })
       set_data([...new_data])
       set_visible(false)
     }else {
       let new_data = data.map(item=>{
         if(!!(item._id === selected_row._id)){
           return {
             ...item,
             prospect:selected_prospect
           }
         }else{
           return item
         }
       })
       set_data([...new_data])
     }
    }else{
     props.global_error({
       success:false,
       message:props.update_prospect_ret.message,
       heading:'Update Prospect'
     })
    }
     props.update_prospect_loading()
 }
 },[props.update_prospect_ret])

    React.useEffect(()=>{
      if(!!props.send_template_sms_ret){
            if(props.send_template_sms_ret.success){
              props.global_error({
                success:true,
                message:props.send_template_sms_ret.message,
                heading:'SMS'
              })
    
              let arr = []
              let new_arr = [...data]
              arr = new_arr.map(item=>{
                    if(item._id===template_sms_select.queryId){
                      let new_sms = [...item.sms]
                      new_sms.unshift({
                        ...template_sms_select,
                        messageBody: template_sms_select.templateName
                      })
                      return {
                        ...item,
                        sms:new_sms
                      }   
                    }
                    else {
                      return item
                    }
               })
    
              set_data(arr)
    
            }else{
              props.global_error({
                success:false,
                message:props.send_template_sms_ret.message,
                heading:'SMS'
              })
            }
            props.send_template_sms_loading()
      }
     },[props.send_template_sms_ret])

    React.useEffect(()=>{
        props.get_covid_queries(covid_queries_params)
    },[])

    React.useEffect(()=>{
      if(!!props.send_sms_ret){
            if(props.send_sms_ret.success){
              props.global_error({
                success:true,
                message:props.send_sms_ret.message,
                heading:'SMS'
              })
    
              let arr = []
              let new_arr = [...data]
              arr   = new_arr.map(item=>{
                console.log(item, sms_select, "======================>")
                    if(item._id===sms_select.queryId){
                      console.log(sms_select,"send_sms")
                      let new_sms = [...item.sms]
                      new_sms.unshift({
                        ...sms_select,
                        messageBody:sms_select.message
                      })
                      return {
                        ...item,
                        sms:new_sms
                      }   
                    }
                    else {
                      return item
                    }
               })
    
              set_data(arr)
    
            }else{
              props.global_error({
                success:false,
                message:props.send_sms_ret.message,
                heading:'SMS'
              })
            }
            props.send_sms_loading()
      }


      if(!!props.update_covid_queries_ret){
        console.log(props.update_covid_queries_ret,"==============>")
        if(props.update_covid_queries_ret.success){
         props.global_error({
           success:true,
           message:props.update_covid_queries_ret.message,
           heading:'Update Status'
         })
         if(visible){
            let new_data = data.map(item=>{
              console.log(item, selected_row,"item, selected_row")
              if(!!(item._id === selected_row._id)){
                console.log(note.note,"True Called")
                return {
                  ...item,
                  note:note.note
                }
              }else{
                return item
              }
            })
            console.log(new_data,"new_data")
           set_data([...new_data])
           set_visible(false)
         }else {
           let new_data = data.map(item=>{
             console.log(item, selected_row,"item, selected_row")
             if(!!(item._id === selected_row._id)){
               return {
                 ...item,
                 status:selected_status
               }
             }else{
               return item
             }
           })
           set_data([...new_data])
         }
        }else{
         props.global_error({
           success:false,
           message:props.update_covid_queries_ret.message,
           heading:'Update Status'
         })
        }
         props.update_covid_queries_loading()
     }


     },[props.send_sms_ret, props.update_covid_queries_ret])

    React.useEffect(()=>{
      if(!!props.get_covid_queries_ret){
        // console.log(props.get_covid_queries_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_covid_queries_ret.success){
          set_data(modif_data([...props.get_covid_queries_ret.data]))
          set_covid_queries_params({...covid_queries_params, total:props.get_covid_queries_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_covid_queries_ret.message,
            heading:'Professionals'
          })
         }
          props.get_covid_queries_loading()
      }
     
         
  },[props.get_covid_queries_ret])

     const handleChange = (pagination, filters, sorter) => {
       console.log("Insidehandle Page jhfghjfhjfhjfhjfjfhjfhjgfhjfhjfhjchange")
       
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...covid_queries_params.filters}
       let updated_filters = {}
       updated_filters.status = filters.status
       updated_filters.createdAt = filters.createdAt
      if(!!(!!((pagination.current === covid_queries_params.page) && (pagination.pageSize === covid_queries_params.limit)))){
        if(objectEquals(state_filters, updated_filters)){
        //  window.alert("Objects are equal")
        }else{
          if(!!(pagination.pageSize === covid_queries_params.limit)){
            set_covid_queries_params({...covid_queries_params, page:1, filters :{
              createdAt: filters.createdAt? filters.createdAt:'',
              status:filters.status?filters.status:''
             }})
             props.get_covid_queries({...covid_queries_params, page:1, filters :{
               createdAt: filters.createdAt? filters.createdAt:'',
               status:filters.status?filters.status:''    
     }})
          }
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}

      const send_template_sms = (data) => {
        set_template_sms_select(data)
        props.send_template_sms({...data})
      }
  
     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        //  window.alert("Inside handle search")
        confirm();
         set_covid_queries_params({...covid_queries_params, page:1,searchParams:{
           ...covid_queries_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_covid_queries({...covid_queries_params, page:1,searchParams:{
          ...covid_queries_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let query_list = []
        for_loop(arr, (item) => {
            let obj = {
            name: item.name,
            prospect:item.prospect ? item.prospect : "N/A",
            message:item.message,
            mobileNumber:item.mobileNumber,
            note:item.note,
            status:item.status,
            createdAt:getDateFormat(item.createdAt),
            sms:item.sentMessages,
            _id: item._id
            }
            query_list.push({
          ...obj
            })
           
        })

    return query_list;
    }

   const change_limit = (a, b) => {
     console.log(a,b,"a and b in change_limit")
    // window.alert("Inside change limit ")
    if(covid_queries_params.limit !== b){
      set_covid_queries_params({...covid_queries_params, limit:b, page:1})
      props.get_covid_queries({...covid_queries_params, limit:b, page:1})
    }
     
    }

    const page_change = (a, b) => {
      console.log(a,b,covid_queries_params,"a and b covid_queries_params")
      // window.alert("Inside page change")
      // if(covid_queries_params.page !== a){
        if(true){
        set_covid_queries_params({...covid_queries_params, page:a, limit:b})
        props.get_covid_queries({...covid_queries_params, page:a, limit:b})
      }else{
        // window.alert("limit change change")
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_covid_queries_params({...covid_queries_params,page:1,  searchParams:{
        name:'',
        mobileNumber:''
      },
      filters:{
        status:'',
        createdAt:1000
      }})
      props.get_covid_queries({...covid_queries_params,page:1,  searchParams:{
        name:'',
      
        mobileNumber:''
      },
      filters:{
        status:'',
        createdAt:1000
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_covid_queries_params({...covid_queries_params, page:1, filters:{
        status:'',
        createdAt:1000
      }})
      props.get_covid_queries({...covid_queries_params, page:1, filters:{
        status:'',
        createdAt:1000
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const update_status = (val, record ) => {
      set_selected_row(record)
      set_selected_status(val)
          props.update_covid_queries({
            status:val,
            queryId:record._id
          })
  }

  const open_modal = (record) => {
    set_selected_row(record)
    set_visible(true)
    set_note({
      note:record.note
    })
  }

  const submit_note = () => {
    props.update_covid_queries({
      note:note.note,
      queryId:selected_row._id
    })
  }

  const close_modal = () => {
    set_visible(false)
}
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        
          ...getColumnSearchProps('name', "Search name", 'name'),
          width:'15%',
        },
        {
          title: 'Phone',
          dataIndex: 'mobileNumber',
          key: 'mobileNumber',
          ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          width:'14%',
       
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            width:'20%',
            // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
           
          },
          {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            width:'20%',
            // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width:'25%',
            filters: [
              {
                text: 'Pending',
                value: 'PENDING',
              },
              {
                text: "Irrelevant",
                value: "Irrelevant"
              },
              {
                text: "Follow-up",
                value: "Follow-up"
              },
              {
                text: "Repeat",
                value: "Repeat"
              },
              {
                text: "DNP",
                value: "DNP"
              },
              {
                text: "OPD Scheduled",
                value: "OPD_Scheduled"
              },
              {
                text: "OPD Done",
                value: "OPD_Done"
              },
              {
                text: "OPD Cancelled",
                value: "OPD_Cancelled"
              },
              {
                text: "OPD Rescheduled",
                value: "OPD_Rescheduled"
              },
              {
                text: "IPD Scheduled",
                value: "IPD_Scheduled"
              },
              {
                text: "IPD Done",
                value: "IPD_Done"
              },
              {
                text: "IPD Cancelled",
                value: "IPD_Cancelled"
              },
              {
                text: "IPD Rescheduled",
                value: "IPD_Rescheduled"
              },
              {
                text: "Low Budget",
                value: "Low_Budget"
              },
              {
                text: "Medication",
                value: "Medication"
              },
              {
                text: "Other Hospital",
                value: "Other_Hospital"
              },
              {
                text: "Mode of Payment",
                value: "Mode_of_Payment"
              },
              {
                text: "Remote Lead",
                value: "Remote_Lead"
              },
              {
                text: "Plan Dropped",
                value: "Plan_Dropped"
              },
              {
                text: "Already Visited",
                value: "Already_Visited"
              },
            ],
            filterMultiple: false,   
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
            render:(item, record) => {
                return <div className="u-align-center">
                    <Select value={record.status} style={{ width: 120 }} onChange={(val)=>update_status(val, record)}>
                      <Option disabled={record.status==="Irrelevant"} value="Irrelevant">Irrelevant</Option>
                      <Option disabled={record.status==="Follow-up"} value="Follow-up">Follow-up</Option>
                      <Option disabled={record.status==="Repeat"} value="Repeat">Repeat</Option>
                      <Option disabled={record.status==="DNP"} value="DNP">DNP</Option>
                      <Option disabled={record.status==="OPD_Scheduled"} value="OPD_Scheduled">OPD Scheduled</Option>
                      <Option disabled={record.status==="OPD_Done"} value="OPD_Done">OPD Done</Option>
                      <Option disabled={record.status==="OPD_Cancelled"} value="OPD_Cancelled">OPD Cancelled</Option>
                      <Option disabled={record.status==="OPD_Rescheduled"} value="OPD_Rescheduled">OPD Rescheduled</Option>
                      <Option disabled={record.status==="IPD_Scheduled"} value="IPD_Scheduled">IPD Scheduled</Option>
                      <Option disabled={record.status==="IPD_Done"} value="IPD_Done">IPD Done</Option>
                      <Option disabled={record.status==="IPD_Cancelled"} value="IPD_Cancelled">IPD Cancelled</Option>
                      <Option disabled={record.status==="IPD_Rescheduled"} value="IPD_Rescheduled">IPD Rescheduled</Option>
                      <Option disabled={record.status==="Low_Budget"} value="Low_Budget">Low Budget</Option>
                      <Option disabled={record.status==="Medication"} value="Medication">Medication</Option>
                      <Option disabled={record.status==="Other_Hospital"} value="Other_Hospital">Other Hospital</Option>
                      <Option disabled={record.status==="Mode_of_Payment"} value="Mode_of_Payment">Mode of Payment</Option>
                      <Option disabled={record.status==="Remote_Lead"} value="Remote_Lead">Remote Lead</Option>
                      <Option disabled={record.status==="Plan_Dropped"} value="Plan_Dropped">Plan Dropped</Option>
                      <Option disabled={record.status==="Already_Visited"} value="Already_Visited">Already Visited</Option>
                    </Select>
                </div>
            }
          },
          {
            title: 'Prospect',
            dataIndex: 'prospect',
            key: 'prospect',
            width:'25%',
            filters: [
              {
                text: 'Hot',
                value: 'HOT',
              },
              {
                text: "Warm",
                value: "WARM"
              },
              {
                text: "Cold",
                value: "COLD"
              }
            ],
            filterMultiple: false,   
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
            render:(item, record) => {
                return <div className="u-align-center">
                    <Select value={record.prospect} style={{ width: 120 }} onChange={(val)=> {
                      update_prospect(val, record)
                    }}>
                      <Option disabled={record.prospect==="HOT"} value="HOT">Hot</Option>
                      <Option disabled={record.prospect==="WARM"} value="WARM">Warm</Option>
                      <Option disabled={record.prospect==="COLD"} value="COLD">Cold</Option>
                    </Select>
                </div>
            }
          },
        {
            title: 'Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            filters: [
              {
                text: 'Today',
                value: 1,
              },
              {
                text: 'Yesterday',
                value: 2,
              },
              {
                text: 'Last 7 days',
                value: 7,
              },
              {
                text: 'Last 30 days',
                value: 30,
              },
              {
                text: 'Default Value',
                value: 1000,
              }
            ],
            filterMultiple: false,
            width:'10%',
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
           
          },
          {
            title: 'Action',
            key: 'action',
            width:'10%',
            render: (text, record) => (
              <Space size="middle">
                <a onClick = {()=>open_modal(record)}>Add/Update Note</a>
              </Space>
            ),
          }
      ]

      const expand = (record,a,b) => {
        console.log(record,a,b,"record,a,b")
        if(expandedRowKeys[0]!== a._id){
           set_expandedRowKeys([a._id])
           console.log(a,"a.varianceInfo")
        }else{
        set_expandedRowKeys([])
        }
      }

      const update_prospect = (val, record ) => {
        set_selected_row(record)
        set_selected_prospect(val)
            props.update_prospect({
              prospect:val,
              queryId:record._id,
              flag:"Non-Registered"
            })
    }
  
     const send_sms = (data) => {
          set_sms_select({
            queryId:data.queryId,
            message:data.sentMessage,
            sentMessage:data.sentMessage
          })
          props.send_sms({
            ...data
          })
     }

     const send_bulk_sms = (data) => {
      let userids = ""
      data.solutionIds.map((item,i)=>{
                  if(i!==0){
                    userids = `${userids},${item}`
                  }else{
                    userids = `${userids}${item}`
                  }
                  
                })
      props.send_sms({
        queryId:userids,
        sentMessage:data.messageBody,
        type:'covid'
      })
 }

//      const send_sms = (data) => {
//       set_sms_select({
//         queryId:data.queryId,
//         sentMessage:data.sentMessage,
//         type:'sms',
//          ...data
//       })
//       props.send_sms({
//         queryId:data.queryId,
//         sentMessage:data.sentMessage,
//         type:'sms'
//       })
//  }

     function showTotal(total) {
      return `Total ${total} items`;
    }

   if(!!professional_selected){
        return <Redirect to={`/dashboard/professionals?professional_id=${professional_selected._id}`} />
   }

   if(send_sms_flag){
    return <React.Fragment>
           <PageHeader
     className="site-page-header"
   onBack={() => {
     set_send_sms_flag(false)
     set_selected_queries([])
   }}
   title="Send Bulk Messages"
   // subTitle="This is a subtitle"
 >
          <BulkSms
            list = {selected_queries}
            send_sms = {send_bulk_sms}
            send_sms_ret = {props.send_sms_ret}
            send_sms_loading = {props.send_sms_loading}
            send_sms_loading_flag = {props.send_sms_loading_flag}
            global_error = {props.global_error}
          />

      </PageHeader>

    </React.Fragment>
  }



  if(bulk_sms_list){
    return <React.Fragment>
           <PageHeader
     className="site-page-header"
   onBack={() => {
     set_bulk_sms_list(false)
     set_selected_queries([])
   }}
   title="Previous Bulk Messages"
   // subTitle="This is a subtitle"
 >
          <BulkSmsList
            reportType = {"NON_REG_COVID_QUERIES_REPORT"}

            get_bulk_sms_list = {props.get_bulk_sms_list}
            get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
            get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
            get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

            global_error = {props.global_error}
          />

      </PageHeader>

    </React.Fragment>
  }

        return (<React.Fragment>


<Modal
          title="Update/Add"
          visible={visible}
          onOk={submit_note}
          onCancel={close_modal}
        >
    <div className="flex-parent-new">
       <span className="flex-child-1-new">
           <AntdInput label="Note"  
            value={note.note} 
            error_text={error.note.text} 
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error,note:{text:'',state:false}})} 
            error={error.note.state} 
            name="note" 
            onChange = {event=>set_note({[event.target.name]:event.target.value})} 
            placeholder="Enter note"
            type="textarea"
            rows= {5}
            />
      </span>
</div>
        </Modal>


        {(selected_queries.length >1) &&  <Button
                type="primary"
                onClick={() => set_send_sms_flag(true)}
                size="small"
                style={{ width: 90 }}
                className="u-margin-top-small"
              >
                Send Sms
                </Button>}



            <div className="u-margin-top-small">
              <Button
                    type="primary"
                    onClick={() => set_bulk_sms_list(true)}
                    size="small"
                    style={{ width: 200 }}
                    className="u-margin-top-small"
                  >
                    Previous Messages         
              </Button>
          </div>

    <div className="u-margin-top-small">             
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_covid_queries_loading_flag || props.update_covid_queries_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:covid_queries_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:covid_queries_params.page,
            total:covid_queries_params.total,
            showTotal:showTotal
          }}
          expandedRowRender= {record =>{
            console.log(record,"record")
            return <React.Fragment>
              <div key={record._id}>
                <SendSms
                    send_sms = {send_sms}
                    type="covid"
                    sms = {record.sms}
                    queryId={record._id}
                    send_sms_loading_flag = {props.send_sms_loading_flag}
                    get_message_templates = {props.get_message_templates}
                    get_message_templates_ret = {props.get_message_templates_ret}
                    send_template_sms = {send_template_sms}
                    send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                />
              </div>
      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record._id}
                rowSelection={{
                  selectedRowKeys:selectedRowKeys,
                  onChange: (e,b)=>{
                    setSelectedKeys([...e])
                    set_selected_queries([...b.map(item=>{
                      return {
                        name:item.name,
                        _id:item._id
                      }
                    })])
                  },
               }}
          />
       </div>
        </React.Fragment>)
}
export default Covidqueries