import React from "react"
import { Table, Input, Button, Space, Card,  Menu, Dropdown, Row, Col, Form  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AudioPlayer from "../../Components/AudioPlayer"
import  { Link } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
import SendSms from "./SendSms"

const { SubMenu }  = Menu


const UploadList = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [form] = Form.useForm();
      const [variance_info, set_variance_info] = React.useState([])
      const [selected_row , set_selected_row] = React.useState([])
      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [selected_professionals, set_selected_professionals] =React.useState([])
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [file, set_file] = React.useState('')
      const [get_upload_list, set_get_upload_list] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
           
           },
           filters:{
            
           }
      })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [selected_call, set_selected_call] = React.useState({})
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [error, set_error] = React.useState({
        location:{state:false, text:''}
      })
      const [sms_select, set_sms_select] = React.useState({})
    React.useEffect(()=>{
        props.get_upload_list(get_upload_list)
    },[])

    React.useEffect(()=>{
      if(!!props.get_upload_list_ret){
        // console.log(props.get_upload_list_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_upload_list_ret.success){
          set_data(modif_data([...props.get_upload_list_ret.data]))
          set_get_upload_list({...get_upload_list, total:props.get_upload_list_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_upload_list_ret.message,
            heading:'Professionals'
          })
         }
          props.get_upload_list_loading()
      }
     
         
  },[props.get_upload_list_ret])



 React.useEffect(()=>{
  if(!!props.send_ivr_message_ret){
        if(props.send_ivr_message_ret.success){
          props.global_error({
            success:true,
            message:props.send_ivr_message_ret.message,
            heading:'SMS'
          })

          // let arr = []
          // let new_arr = [...data]
          // arr   = new_arr.map(item=>{
          //   console.log(item, sms_select, "======================>")
          //       if(item._id===sms_select.queryId){
          //         console.log(sms_select,"send_sms")
          //         let new_sms = [...item.sms]
          //         new_sms.unshift({
          //           ...sms_select,
          //           messageBody:sms_select.message
          //         })
          //         return {
          //           ...item,
          //           sms:new_sms
          //         }   
          //       }
          //       else {
          //         return item
          //       }
          //  })

          // set_data(arr)

        }else{
          props.global_error({
            success:false,
            message:props.send_ivr_message_ret.message,
            heading:'SMS'
          })
        }
        props.send_ivr_message_loading()
  }
 },[props.send_ivr_message_ret])

  function handleButtonClick(e) {
    console.log('click left button', e);
  }
  
  function handleMenuClick(e) {
    console.log('click', e);
  }

  const send_sms = (data) => {
    set_sms_select({
      mobileNumber:selected_call.client_number,
      uuid:selected_call.uuid,
      message:data.sentMessage,
      // name:selected_call.user_name
      name:'user_name'
    })
    props.send_ivr_message({
      mobileNumber:selected_call.client_number,
      uuid:selected_call.uuid,
      message:data.sentMessage,
      // name:selected_call.user_name
      name:'user_name'
    })
}



     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_upload_list.filters}
       let updated_filters = {}
 
      
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === get_upload_list.page) && (pagination.pageSize === get_upload_list.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_upload_list({...get_upload_list, page:1, filters :{
           
            createdAt:filters.createdAt?filters.createdAt:''
          }})
          props.get_upload_list({...get_upload_list, page:1, filters :{
          
            createdAt:filters.createdAt?filters.createdAt:''        
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_upload_list({...get_upload_list, page:1,searchParams:{
           ...get_upload_list.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_upload_list({...get_upload_list, page:1,searchParams:{
          ...get_upload_list.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            createdAt: getDateFormat(item.createdAt),
            NumberOfQueries:item.NumberOfQueries,
            fileName:item.fileName,
            name:item.name,
            role:item.role,
            uploadedBy:item.uploadedBy,
            _id:item._id
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_upload_list({...get_upload_list, limit:b, page:1})
      props.get_upload_list({...get_upload_list, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_upload_list,"a and b get_upload_list")
      // if(get_upload_list.page !== a){
        if(true){
        set_get_upload_list({...get_upload_list, page:a, limit:b})
        props.get_upload_list({...get_upload_list, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_upload_list({...get_upload_list,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
      props.get_upload_list({...get_upload_list,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        locality:"All",
        userType:"",
        level:"",
        catalogue:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_upload_list({...get_upload_list, page:1, filters:{
        locality:"All",
        userType:"",
        catalogue:'',
        level:''
      }})
      props.get_upload_list({...get_upload_list, page:1, filters:{
        locality:"All",
        userType:"",
        catalogue:'',
        level:''
      }})
    }

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width:'15%',
          // ellipsis: true,
          ...getColumnSearchProps('name', "Search name", 'name'),
        },
        {
            title: 'File',
            dataIndex: 'fileName',
            key: 'fileName',
            width:'25%',
            ...getColumnSearchProps('fileName', "Search fileName", 'fileName'),
            // ellipsis: true,
          },
        {
          title: 'Queries',
          dataIndex: 'NumberOfQueries',
          key: 'NumberOfQueries',
          width:'15%%',
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          // ellipsis: true,
        },
        // {
        //     title: 'Sms Status',
        //     dataIndex: 'smsStatus',
        //     key: 'smsStatus',
        //     width:'20%',
        //   //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
        //     // ellipsis: true,
        //   },
          {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width:'15%',
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
          },
          {
            title: 'Uplaoded By',
            dataIndex: 'uploadedBy',
            key: 'specialities',
            width:'15%',
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
          },
          {
            title: 'Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'15%',
            filters: [
              {
                text: 'Today',
                value: '1',
              },
              {
                text: 'Yesterday',
                value: '2',
              },
              {
                text: 'Last week',
                value: '7',
              }
            ]
          //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
            // ellipsis: true,
          },
        ]



     const onSelectChange = data => {
        console.log('selectedRowKeys changed: ', data);
        // this.setState({ selectedRowKeys });
      }
      const rowSelection = {
        selectedRowKeys,
        onChange: (e,b)=>console.log(e,b),
      };

      const expand = (record,a,b) => {
        console.log(record,a,b,"record,a,b")
        if(expandedRowKeys[0]!== a.id){
           set_expandedRowKeys([a.id])
           console.log(a,"a.varianceInfo")
          
        }else{
        set_expandedRowKeys([])
        }
      }



    const debounce = (func, wait) => {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {

          timeout = null

          func(...args);
        }

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);
      }
    }
    const returnedFunction = debounce(function(data) {
     
      try{
         variance_change(data)
      }catch(e){
       console.log(e)
      }
      // setState({...state, selectPlace:data})
     
    }, 500);

    const variance_change = (data) => {
      set_get_upload_list({...get_upload_list,serviceName:data})
      props.get_upload_list({...get_upload_list,serviceName:data})
    }

    function showTotal(total) {
      return `Total ${total} items`;
    }

    const row_click = (r) => {
      console.log(r)
      props.file_change(r)
      set_file(r._id)
    }

    console.log(props.get_upload_list_ret,"get_upload_list_ret")


        return (<React.Fragment>
            <Card title="Facebook queries uploaded files">
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_upload_list_loading_flag}
          onChange={handleChange}
          rowClassName={(record, index)=>{
            if((record._id===file)){
                  return "higlighted_row"
            }
          }}
          pagination = {{
            showSizeChanger:true,
            limit:get_upload_list.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_upload_list.page,
            total:get_upload_list.total,
            showTotal:showTotal
          }}
          rowKey={record => record.id}
          onRow={(r) => ({
            onClick: (b,a) => row_click(r),
          })}
          />
        </Card>
        </React.Fragment>)
}
export default UploadList