import { 
    FUNNEL_CHECK_NUMBER, FUNNEL_CHECK_NUMBER_RET, FUNNEL_CHECK_NUMBER_LOADING, FUNNEL_SET_USER, 
    FUNNEL_REGISTER_USER_LOADING, FUNNEL_REGISTER_USER, FUNNEL_REGISTER_USER_RET,
    FUNNEL_GET_DOCTORS_LOADING, FUNNEL_MAKE_BOOKING_LOADING, FUNNEL_MAKE_BOOKING_RET, FUNNEL_MAKE_BOOKING,
    FUNNEL_GET_DOCTORS_RET, FUNNEL_GET_DOCTORS,  FUNNEL_GENERATE_LINK_LOADING, FUNNEL_GENERATE_LINK_RET, FUNNEL_GENERATE_LINK, FUNNEL_GET_BOOKINGS_LOADING,
    FUNNEL_GET_BOOKINGS_RET, FUNNEL_GET_BOOKINGS, FUNNEL_UPDATE_BOOKINGS_LOADING, FUNNEL_UPDATE_BOOKINGS_RET, FUNNEL_UPDATE_BOOKINGS, FUNNEL_GET_INSIGHTS,
    FUNNEL_GET_INSIGHTS_RET, FUNNEL_GET_INSIGHTS_LOADING, FUNNEL_GET_SOL_PROFESSIONALS, FUNNEL_GET_SOL_PROFESSIONALS_RET, FUNNEL_GET_SOL_PROFESSIONALS_LOADING,
    FUNNEL_UPDATE_SOL_PROFESSIONAL_LOADING, FUNNEL_UPDATE_SOL_PROFESSIONAL_RET, FUNNEL_UPDATE_SOL_PROFESSIONAL, FUNNEL_UPDATE_GLOBAL_BOOKING_LOADING, FUNNEL_UPDATE_GLOBAL_BOOKING_RET,
    FUNNEL_UPDATE_GLOBAL_BOOKING, FUNNEL_GLOBAL_BOOKINGS, FUNNEL_GLOBAL_BOOKINGS_RET, FUNNEL_GLOBAL_BOOKINGS_LOADING , FUNNEL_GET_REFUNDS_LOADING, FUNNEL_GET_REFUNDS_RET, FUNNEL_GET_REFUNDS,
    FUNNEL_GET_REPORTS_BYID_LOADING, FUNNEL_GET_REPORTS_BYID_RET, FUNNEL_GET_REPORTS_BYID,
    FUNNEL_UPLOAD_USER_REPORT, FUNNEL_UPLOAD_USER_REPORT_RET, FUNNEL_UPLOAD_USER_REPORT_LOADING, 
    FUNNEL_UPLOAD_INSURANCE_REPORT, FUNNEL_UPLOAD_INSURANCE_REPORT_RET, FUNNEL_UPLOAD_INSURANCE_REPORT_LOADING, 
    FUNNEL_SUBMIT_MEDICAL_DETAILS, FUNNEL_SUBMIT_MEDICAL_DETAILS_RET, FUNNEL_SUBMIT_MEDICAL_DETAILS_LOADING, 
    FUNNEL_EDIT_MEDICAL_DETAILS, FUNNEL_EDIT_MEDICAL_DETAILS_RET, FUNNEL_EDIT_MEDICAL_DETAILS_LOADING,
    FUNNEL_CREATE_CUSTOM_SOLUTION, FUNNEL_CREATE_CUSTOM_SOLUTION_LOADING, FUNNEL_CREATE_CUSTOM_SOLUTION_RET 
} from "../Constants"


export const get_reports_byid = (data) => {
    return {
        type:FUNNEL_GET_REPORTS_BYID,
        data
    }
}

export const get_reports_byid_ret = (data) => {
    return {
        type:FUNNEL_GET_REPORTS_BYID_RET,
        data
    }
}

export const get_reports_byid_loading = (data) => {
    return {
        type:FUNNEL_GET_REPORTS_BYID_LOADING,
        data
    }
}

export const get_refunds = (data) => {
    return {
        type:FUNNEL_GET_REFUNDS,
        data
    }
}


export const get_refunds_ret = (data) => {
    return {
        type:FUNNEL_GET_REFUNDS_RET,
        data
    }
}


export const get_refunds_loading = (data) => {
    return {
        type:FUNNEL_GET_REFUNDS_LOADING,
        data
    }
}
export const global_bookings = (data) => {
    return {
        type:FUNNEL_GLOBAL_BOOKINGS,
        data
    }
}

export const global_bookings_ret = (data) => {
    return {
        type:FUNNEL_GLOBAL_BOOKINGS_RET,
        data
    }
}
        
export const global_bookings_loading = (data) => {
    return {
        type:FUNNEL_GLOBAL_BOOKINGS_LOADING,
        data
    }
}


export const update_global_booking = (data) => {
    return {
        type:FUNNEL_UPDATE_GLOBAL_BOOKING,
        data
    }
}

export const update_global_booking_ret = (data) => {
    return {
        type:FUNNEL_UPDATE_GLOBAL_BOOKING_RET,
        data
    }
}
        
export const update_global_booking_loading = (data) => {
    return {
        type:FUNNEL_UPDATE_GLOBAL_BOOKING_LOADING,
        data
    }
}
        
export const update_sol_professional = (data) => {
    return {
        type:FUNNEL_UPDATE_SOL_PROFESSIONAL,
        data
    }
}

export const update_sol_professional_ret = (data) => {
    return {
        type:FUNNEL_UPDATE_SOL_PROFESSIONAL_RET,
        data
    }
}

export const update_sol_professional_loading = (data) => {
    return {
        type:FUNNEL_UPDATE_SOL_PROFESSIONAL_LOADING,
        data
    }
}
export const get_sol_professionals = (data) => {
    return {
        type:FUNNEL_GET_SOL_PROFESSIONALS,
        data
    }
}

export const  get_sol_professionals_ret = (data) => {
    return {
        type:FUNNEL_GET_SOL_PROFESSIONALS_RET,
        data
    }
}

export const get_sol_professionals_loading = (data) => {
    return {
        type:FUNNEL_GET_SOL_PROFESSIONALS_LOADING,
        data
    }
}

export const get_insights = (data) => {
    return {
        type:FUNNEL_GET_INSIGHTS,
        data
    }
}

export const  get_insights_ret = (data) => {
    return {
        type:FUNNEL_GET_INSIGHTS_RET,
        data
    }
}

export const get_insights_loading = (data) => {
    return {
        type:FUNNEL_GET_INSIGHTS_LOADING,
        data
    }
}

export const update_bookings = (data) => {
    return {
        type:FUNNEL_UPDATE_BOOKINGS,
        data
    }
}

export const update_bookings_ret = (data) => {
    return {
        type:FUNNEL_UPDATE_BOOKINGS_RET,
        data
    }
}

export const update_bookings_loading = (data) => {
    return {
        type:FUNNEL_UPDATE_BOOKINGS_LOADING,
        data
    }
}

export const get_bookings = (data) => {
    return {
        type:FUNNEL_GET_BOOKINGS,
        data
    }
}
export const get_bookings_ret = (data) => {
    return {
        type:FUNNEL_GET_BOOKINGS_RET,
        data
    }
}
export const get_bookings_loading = (data) => {
    return {
        type:FUNNEL_GET_BOOKINGS_LOADING,
        data
    }
}

export const generate_link = (data) => {
    return {
        type:FUNNEL_GENERATE_LINK,
        data
    }
}

export const generate_link_ret = (data) => {
    return {
        type:FUNNEL_GENERATE_LINK_RET,
        data
    }
}

export const generate_link_loading = (data) => {
    return {
        type:FUNNEL_GENERATE_LINK_LOADING,
        data
    }
}
export const make_booking = (data) => {
    return {
        type:FUNNEL_MAKE_BOOKING,
        data
    }
}
export const make_booking_ret = (data) => {
    return {
        type:FUNNEL_MAKE_BOOKING_RET,
        data
    }
}
export const make_booking_loading = (data) => {
    return {
        type:FUNNEL_MAKE_BOOKING_LOADING,
        data
    }
}
export const get_doctors = (data) => {
    return {
        type:FUNNEL_GET_DOCTORS,
        data
    }
}

export const get_doctors_ret = (data) => {
    return {
        type:FUNNEL_GET_DOCTORS_RET,
        data
    }
}

export const get_doctors_loading = (data) => {
    return {
        type:FUNNEL_GET_DOCTORS_LOADING,
        data
    }
}

export const set_user = (data) => {
    return {
        type:FUNNEL_SET_USER,
        data
    }
}

export const register_user = (data) => {
    return {
        type:FUNNEL_REGISTER_USER,
        data
    }
}

export const register_user_ret = (data) => {
    return {
        type:FUNNEL_REGISTER_USER_RET,
        data
    }
}

export const register_user_loading = (data) => {
    return {
        type:FUNNEL_REGISTER_USER_LOADING,
        data
    }
}


export const check_number = (data) => {
    return {
        type:FUNNEL_CHECK_NUMBER,
        data
    }
}
export const check_number_ret = (data) => {
    return {
        type:FUNNEL_CHECK_NUMBER_RET,
        data
    }
}
export const check_number_loading = (data) => {
    return {
        type:FUNNEL_CHECK_NUMBER_LOADING,
        data
    }
}

export const upload_user_report = (data) => {
    return {
        type:FUNNEL_UPLOAD_USER_REPORT,
        data
    }
}
export const upload_user_report_ret = (data) => {
    return {
        type:FUNNEL_UPLOAD_USER_REPORT_RET,
        data
    }
}
export const upload_user_report_loading = () => {
    return {
        type:FUNNEL_UPLOAD_USER_REPORT_LOADING,
    }
}

export const upload_insurance_report = (data) => {
    return {
        type:FUNNEL_UPLOAD_INSURANCE_REPORT,
        data
    }
}
export const upload_insurance_report_ret = (data) => {
    return {
        type:FUNNEL_UPLOAD_INSURANCE_REPORT_RET,
        data
    }
}
export const upload_insurance_report_loading = () => {
    return {
        type:FUNNEL_UPLOAD_INSURANCE_REPORT_LOADING,
    }
}

export const submit_medical_details = (data) => {
    return {
        type:FUNNEL_SUBMIT_MEDICAL_DETAILS,
        data
    }
}
export const submit_medical_details_ret = (data) => {
    return {
        type:FUNNEL_SUBMIT_MEDICAL_DETAILS_RET,
        data
    }
}
export const submit_medical_details_loading = () => {
    return {
        type:FUNNEL_SUBMIT_MEDICAL_DETAILS_LOADING,
    }
}

export const edit_medical_details = (data) => {
    return {
        type:FUNNEL_EDIT_MEDICAL_DETAILS,
        data
    }
}
export const edit_medical_details_ret = (data) => {
    return {
        type:FUNNEL_EDIT_MEDICAL_DETAILS_RET,
        data
    }
}
export const edit_medical_details_loading = () => {
    return {
        type:FUNNEL_EDIT_MEDICAL_DETAILS_LOADING,
    }
}

export const create_custom_solution = (data) => {
    return {
        type:FUNNEL_CREATE_CUSTOM_SOLUTION,
        data
    }
}
export const create_custom_solution_ret = (data) => {
    return {
        type:FUNNEL_CREATE_CUSTOM_SOLUTION_RET,
        data
    }
}
export const create_custom_solution_loading = () => {
    return {
        type:FUNNEL_CREATE_CUSTOM_SOLUTION_LOADING,
    }
}