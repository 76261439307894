import  React from "react"
import {  Card, Form, Button, Row, Col, Tag, Typography } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { error_form_check } from "../../utils/common_utilities"
import { Redirect } from "react-router-dom";
import SentVariance from "./SentVariance";

const { Title } = Typography;

const VarianceData = (props) => {
    const [form] = Form.useForm();
    const [data, set_data] = React.useState({
        variance_message:'',
        push_message:'',
        push_title:'',
        email_title:''
    })
    const [error, set_error] = React.useState({
        variance_message:{ state:false, text:"" },
        push_message:{ state:false, text:"" },
        push_title:{ state:false, text:"" },
        email_title:{ state:false, text:"" }
 })

 React.useEffect(()=>{
    if(props.send_variance_info_ret){
        if(props.send_variance_info_ret.success){
            props.global_error({
             success:true,
             message:props.send_variance_info_ret.message,
             heading:'Variance Info',
           })
        }else {
            props.global_error({
             success:false,
             message:props.send_variance_info_ret.message,
             heading:'Info'
           })
        }
        props.send_variance_info_loading()
        props.previous_variance_list({
          page:1,
          limit:10
        })
    }
}, [props.send_variance_info_ret])

React.useEffect(()=>{
  if(props.email_preview_ret){
    if(props.email_preview_ret.success){
      let screen = window.screen
      var win = window.open("", "Variance Email", `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${screen.width-400},height=${screen.height-400},top=`+(screen.height-100)+",left="+(screen.width-440));
      win.document.write(props.email_preview_ret.data)
        props.global_error({
         success:true,
         message:props.email_preview_ret.message,
         heading:'Preview Email',
       })
    }else {
        props.global_error({
         success:false,
         message:props.email_preview_ret.message,
         heading:'Preview Email'
       })
    }
 props.email_preview_loading()
}
 
},[props.email_preview_ret])


const send_email = () => {
    const ret_error = validate_email({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_variance_info({
            email:true,
            message:false,
            notification:false,
            textMessage:'',
            pushMessage:"",
            notificationTitle:"",
            emailTitle:data.email_title, 
                        professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_email  = (data) =>{
    const  error = {
        variance_message:{ state:false, text:"" },
        push_message:{ state:false, text:"" },
        push_title:{ state:false, text:"" },
        email_title:{ state:false, text:"" }
      }
      if(data.email_title===''){
        {
          error.email_title.text = "Please enter title"
          error.email_title.state = true
        }
      }
    return error
  }


 const send_text_message = () => {
    const ret_error = validate_text_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_variance_info({
            email:false,
            message:true,
            notification:false,
            textMessage:data.variance_message,
            pushMessage:"",
            notificationTitle:"",
            emailTitle:"", 
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_text_message  = (data) =>{
    const  error = {
        variance_message:{ state:false, text:"" },
        push_message:{ state:false, text:"" },
        push_title:{ state:false, text:"" },
        email_title:{ state:false, text:"" }
      }
      console.log(data)
      console.log(data.variance_message === '',"==>>>>>>>>")
      if(data.variance_message===''){
        {
          error.variance_message.text = "Please enter your message"
          error.variance_message.state = true
        }
      }
    return error
  }


  const send_push_message = () => {
    const ret_error = validate_push_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_variance_info({
            email:false,
            message:false,
            notification:true,
            textMessage:'',
            pushMessage:data.push_message,
            notificationTitle:data.push_title,
            emailTitle:"", 
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_push_message  = (data) =>{
    const  error = {
        variance_message:{ state:false, text:"" },
        push_message:{ state:false, text:"" },
        push_title:{ state:false, text:"" },
        email_title:{ state:false, text:"" }
      }
      if(data.push_message===''){
        {
          error.push_message.text = "Please enter your message"
          error.push_message.state = true
        }
      }
      if(data.push_title===''){
        {
          error.push_title.text = "Please enter title"
          error.push_title.state = true
        }
      }
    return error
  }

  console.log(error,"error in Variance Data")

  if(!!(props.keymanager_store.selected_professionals.length === 0)){
    return <Redirect to="/dashboard/keymanager?professionals_list"  />
  }

  console.log(props.keymanager_store.selected_professionals,"props.keymanager_store")
    return <React.Fragment>
          <Card >
            <Row>
              <Col span ={4}>
              <Title level={5}>Professionals Selected</Title>
                      {props.keymanager_store.selected_professionals.map(item=>{
                        return    <Tag  style={{margin:'.2rem'}} onClose={()=>props.set_key_manager_professionals(props.keymanager_store.selected_professionals.filter(val=>{
                           return (val._id !==item._id)
                        }))}>
                            {item.name}
                       </Tag>
                        })}
              </Col>
              <Col span ={20}>
              <Form
      form={form}
      layout="vertical"
    >
 
<div className="flex-parent-new">
  <span className="flex-child-1-new">
  <AntdInput label="Variance Update Text Message"  
            value={data.variance_message} 
            error_text={error.variance_message.text} 
            loading = {props.send_variance_info_loading_flag}
            disabled = {props.send_variance_info_loading_flag} 
            toggle_error = {()=>set_error({...error, variance_message:{text:'',state:false}})} 
            error={error.variance_message.state} 
            name="variance_message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
 <div>
     <Button loading={props.send_variance_info_loading_flag} onClick={()=>send_text_message()} type={"primary"}>Send</Button>
 </div>
</span>
<span className="flex-child-1-new">
    <AntdInput label="Variance Update Push Message Tite"  
        value={data.push_title} 
        error_text={error.push_title.text}
        loading = {props.send_variance_info_loading_flag}
        disabled = {props.send_variance_info_loading_flag} 
        toggle_error = {()=>set_error({...error, push_title:{text:'',state:false}})} 
        error={error.push_title.state} 
        name="push_title" 
        onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
        placeholder="Enter Title" 
    />

        <AntdInput label="Variance Update Push Message"  
            value={data.push_message} 
            error_text={error.push_message.text} 
            loading = {props.send_variance_info_loading_flag}
            disabled = {props.send_variance_info_loading_flag} 
            toggle_error = {()=>set_error({...error, push_message:{text:'',state:false}})} 
            error={error.push_message.state} 
            name="push_message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
 <div>
     <Button  loading={props.send_variance_info_loading_flag} onClick={()=>send_push_message()} type={"primary"}>Send</Button>
 </div>
</span>
</div>

<div className="flex-parent-new">
  <span className="flex-child-1-new">
     <AntdInput label="Variance Email Tite"  
        value={data.email_title} 
        error_text={error.email_title.text}
        loading = {props.send_variance_info_loading_flag}
        disabled = {props.send_variance_info_loading_flag} 
        toggle_error = {()=>set_error({...error, email_title:{text:'',state:false}})} 
        error={error.email_title.state} 
        name="email_title" 
        onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
        placeholder="Enter Email title" 
    />
    <div className="flex-parent-new">
    <span className="flex-child-1-new">
        <Button loading={props.send_variance_info_loading_flag} onClick={()=>send_email()} type={"primary"}>Send</Button>
    </span>
    <span className="flex-child-1-new">
        <Button loading={props.send_variance_info_loading_flag} onClick={()=>props.email_preview({type:"ABOUT_VARIANCE_TEMPLATE"})} type={"primary"}>View Template</Button>
    </span>
    </div>
  </span>
</div>
  </Form>
              </Col>
            </Row>

            <Title level={5}>Sent Data</Title>
                        <SentVariance
                            previous_variance_list = {props.previous_variance_list}
                            previous_variance_list_loading = {props.previous_variance_list_loading}
                            previous_variance_list_loading_flag = {props.previous_variance_list_loading_flag}
                            previous_variance_list_ret = {props.previous_variance_list_ret}
                        />
            </Card>
    </React.Fragment>
}

export default VarianceData