import React from "react"
import { Card, PageHeader, Form, Input, Button } from "antd"
import { Redirect } from "react-router-dom"
import { error_form_check } from "../utils/common_utilities"
import validator from "validator"
import AntdInput from "../Components/AntdInput"
import SearchLocation from "../Components/SearchLocation"
import BidsList from './BidsList'

const TrackBids = (props) => {
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = React.useState('optional');
    const [move_back, set_move_back] = React.useState(false)
    const [data, set_data] = React.useState({
        speciality:'',
        service:'',
        location:'',
        lng:'',
        lat:''
    })
    const [bids_list, set_bids_list] = React.useState(false)
    const [error, set_error] = React.useState({
        speciality:{ state:false, text:"" },
        service:{ state:false, text:"" },
        location:{ state:false, text:"" },
        lng:{ state:false, text:"" },
        lat:{ state:false, text:"" }
 })

 React.useEffect(()=>{
    if(props.get_bids_ret){
        if(props.get_bids_ret.success){
          // console.log(props.get_bids_ret,"props.get_bids_ret in TrackBids")
          //   props.global_error({
          //    success:true,
          //    message:props.get_bids_ret.message,
          //    heading:'Tracking Bids',
          //  })
           set_bids_list([...props.get_bids_ret.data])
          //  props.get_members()
        }else {
      
            props.global_error({
             success:false,
             message:props.get_bids_ret.message,
             heading:'Tracking Bids'
           })
        }
        props.get_bids_loading()
    }
}, [props.get_bids_ret])

    const validate  = (data) =>{
        const  error = {
              lat:{ state:false, text:"" },
              lng:{ state:false, text:"" },
              location:{ state:false, text:"" },
              speciality:{ state:false, text:"" },
              service:{ state:false, text:"" }
          }
          if(data.speciality===''){
            {
              error.speciality.text = "Please choose a speciality"
              error.speciality.state = true
            }
          }
      if(data.service===''){
        {
          error.service.text = "Please choose a service"
          error.service.state = true
        }
      }
      if(data.location===''){
        {
          error.location.text = "Please choose a location"
          error.location.state = true
        }
      }
        return error
      }
    const add_member = () => {
        const ret_error = validate({...data});
        set_error({...error, ...ret_error});
        // console.log(ret_error,"ret_error in add_member")
        if(!error_form_check(ret_error)){
            // console.log("Inside Eror_form_check")
        //    props.execute_captcha()
        const {lat, lng, service} = data
            props.get_bids(Object.assign({},
                {
                lat, lng, service
              }))
        }
    }

    const speciality_change = (val) => {
         props.get_services({speciality:val})
         set_data({...data,"speciality":val, service:''})
         set_bids_list(false)
    }
console.log(bids_list,"bids_list")
    if(move_back){
        return <Redirect to="/dashboard/members" />
    }
    // console.log(data,"data in Add Member")
    return (
        <React.Fragment>
            <Card>
                <PageHeader
                  className="site-page-header"
                    //  onBack={() => set_move_back(true)}
                  title="Mock Bidding"
                  subTitle=""
  />
   <Form
      form={form}
      layout="vertical"
    >
 
<div className="flex-parent-new">
  <span className="flex-child-1-new">
    <AntdInput 
      label="Speciality"  
      value={data.speciality} 
      disabled = {props.get_bids_loading_flag}
      options = {props.global_store.specialities}
      loading={props.global_store.get_specialities_loading}
      error_text={error.speciality.text} 
      toggle_error = {()=>set_error({...error,speciality:{text:'',state:false}})} 
      error={error.speciality.state}
      name="speciality" 
      type="select"
      onChange = {event=>speciality_change(event)} 
      placeholder="Choose speciality" />
</span>
<span className="flex-child-1-new">
    <AntdInput 
      label="Service"  
      value={data.service} 
      options = {props.global_store.services}
      loading={props.global_store.get_services_loading}
      disabled = {props.get_bids_loading_flag}
      error_text={error.service.text} 
      toggle_error = {()=>set_error({...error,service:{text:'',state:false}})} 
      error={error.service.state}
      name="service" 
      type="select"
      onChange = {event=>{
        set_bids_list(false)
        set_data({...data,"service":event})
      }} 
      placeholder="Choose service" />
</span>
</div>
<div className="flex-parent-new">
<span className="flex-child-1-new">
    <SearchLocation  
        label="Location"  
        value={data.location} 
        disabled = {props.get_bids_loading_flag}
        error_text={error.location.text} 
        toggle_error = {()=>set_error({...error,location:{text:'',state:false}})} 
        error={error.location.state}
        name="location" 
        set_long_lat = {val=>{
          set_data({...data,...val})
          set_bids_list(false)
         }}
        placeholder="Enter Location"
    />
  </span>
  </div>

  <div className="flex-parent-new">
<span className="flex-child-1-new">
      <Form.Item>
        <Button onClick={add_member} loading={props.get_bids_loading_flag} type="primary">Submit</Button>
      </Form.Item>
      </span>
 </div>
    </Form>
  </Card>

 {(!!bids_list) && <div className="u-margin-top-small">
    <BidsList   
        bids={bids_list}
    />
  </div>   } 
        </React.Fragment>
    )
}

export default TrackBids