import user_saga from "./user_saga"
import auth_saga from "./auth_saga"
import global_saga from "./global_saga"
import members_saga from "./members_saga"
import bidding_saga from "./bidding_saga"
import professionals_saga from "./professionals_saga"
import key_mananger_saga from "./keymanager_saga"
import salesfunnel_saga from "./salesfunnel_saga"
import insights_saga from "./insights_saga"
import bookings_saga from "./bookings_saga"
import catalogue_saga from "./catalogue_saga"
import catalogue_manager_saga from "./catalogue_manager_saga"
import users_saga from "./users_saga"
import follow_up_saga from "./follow_up_saga"
import insuranceBookingsSaga from "./insuranceBookingsSaga"
import notification_saga from "./notification_saga"
import { all } from 'redux-saga/effects';
import { funnel_saga } from "./funnel_saga"

function *rootSaga() {
    yield all([
        ...user_saga,
        ...auth_saga,
        ...global_saga,
        ...members_saga,
        ...bidding_saga,
        ...professionals_saga,
        ...key_mananger_saga,
        ...salesfunnel_saga,
        ...insights_saga,
        ...catalogue_saga,
        ...bookings_saga,
        ...catalogue_manager_saga,
        ...users_saga,
        ...follow_up_saga,
        ...notification_saga,
        ...funnel_saga,
        ...insuranceBookingsSaga
    ])
  }

export default rootSaga