import React from "react"
import { Table, Input, Button, Space, Card, Select, Modal, PageHeader } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import { update_status } from "../../actions/salesfunnel";
import AntdInput from "../../Components/AntdInput"
import SendSms from "./SendSms"
import BulkSms from "./BulkSms"
import BulkSmsList from "./BulkSmsList"
const { Option }  =  Select

const MergedQueriesList = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [public_queries_params, set_public_queries_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
             name:'',
             email:'',
             mobileNumber:props.mobileNumber
           },
           filters:{
            status:'',
            createdAt:1000
           }
      })

      const [data, set_data] = React.useState([])
      const [visible, set_visible] = React.useState(false)
      const [bulk_sms_list , set_bulk_sms_list] = React.useState(false)
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [selected_row, set_selected_row] = React.useState({})
      const [sms_select, set_sms_select] = React.useState({})
      const [selected_status, set_selected_status] = React.useState('')
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [selectedRowKeys, setSelectedKeys] =React.useState([])
      const [selected_queries, set_selected_queries] =React.useState([])
      const [send_sms_flag, set_send_sms_flag] = React.useState(false)
      const [note, set_note] = React.useState({
          note:''
    })
    const [error, set_error] = React.useState({
        note:{ state:false, text:"" }
    })
    const [template_sms_select, set_template_sms_select] = React.useState({})


 React.useEffect(()=>{
  if(!!props.send_sms_ret){
        if(props.send_sms_ret.success){
          props.global_error({
            success:true,
            message:props.send_sms_ret.message,
            heading:'SMS'
          })

          let arr = []
          let new_arr = [...data]
          arr = new_arr.map(item=>{
            console.log(item, sms_select, "======================>")
                if(item._id===sms_select.queryId){
                  console.log(sms_select,"send_sms")
                  let new_sms = [...item.sms]
                  new_sms.unshift({
                    ...sms_select,
                    messageBody:sms_select.message
                  })
                  return {
                    ...item,
                    sms:new_sms
                  }   
                }
                else {
                  return item
                }
           })

          set_data(arr)

        }else{
          props.global_error({
            success:false,
            message:props.send_sms_ret.message,
            heading:'SMS'
          })
        }
        props.send_sms_loading()
  }
 },[props.send_sms_ret])

 React.useEffect(()=>{
   console.log("SEND_SMS_TEMPLATE_RET", props.send_template_sms_ret)
  if(!!props.send_template_sms_ret){
        if(props.send_template_sms_ret.success){
          props.global_error({
            success:true,
            message:props.send_template_sms_ret.message,
            heading:'SMS'
          })

          let arr = []
          let new_arr = [...data]
          arr = new_arr.map(item=>{
                if(item._id===template_sms_select.queryId){
                  let new_sms = [...item.sms]
                  new_sms.unshift({
                    ...template_sms_select,
                    messageBody: template_sms_select.templateName
                  })
                  return {
                    ...item,
                    sms:new_sms
                  }   
                }
                else {
                  return item
                }
           })

          set_data(arr)

        }else{
          props.global_error({
            success:false,
            message:props.send_template_sms_ret.message,
            heading:'SMS'
          })
        }
        props.send_template_sms_loading()
  }
 },[props.send_template_sms_ret])

    React.useEffect(()=>{
        props.get_queries_by_number(public_queries_params)

        return () => set_data([])
    },[])

    React.useEffect(()=>{
      if(!!props.get_queries_by_number_ret){
        // console.log(props.get_queries_by_number_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_queries_by_number_ret.success){
          console.log("called again")
          set_data(modif_data([...props.get_queries_by_number_ret.data]))
          set_public_queries_params({...public_queries_params, total:props.get_queries_by_number_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_queries_by_number_ret.message,
            heading:'Professionals'
          })
         }
          props.get_queries_by_number_loading()
      }


      if(!!props.update_status_ret){
         if(props.update_status_ret.success){
          props.global_error({
            success:true,
            message:props.update_status_ret.message,
            heading:'Update Status'
          })
          if(visible){
             let new_data = data.map(item=>{
               console.log(item, selected_row,"item, selected_row")
               if(!!(item._id === selected_row._id)){
                 console.log(note.note,"True Called")
                 return {
                   ...item,
                   note:note.note
                 }
               }else{
                 return item
               }
             })
             console.log(new_data,"new_data")
            set_data([...new_data])
            set_visible(false)
          }else {
            let new_data = data.map(item=>{
              console.log(item, selected_row,"item, selected_row")
              if(!!(item._id === selected_row._id)){
                console.log(note.note,"True Called")
                return {
                  ...item,
                  status:selected_status
                }
              }else{
                return item
              }
            })
            set_data([...new_data])
          }
         }else{
          props.global_error({
            success:false,
            message:props.update_status_ret.message,
            heading:'Update Status'
          })
         }
          props.update_status_loading()
      }
     
         
  },[props.get_queries_by_number_ret, props.update_status_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...public_queries_params.filters}
       let updated_filters = {}
   
       updated_filters.status = filters.status
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === public_queries_params.page) && (pagination.pageSize === public_queries_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_public_queries_params({...public_queries_params, page:1, filters :{
           createdAt: filters.createdAt? filters.createdAt:'',
           status:filters.status?filters.status:''
          }})
          props.get_queries_by_number({...public_queries_params, page:1, filters :{
            createdAt: filters.createdAt? filters.createdAt:'',
            status:filters.status?filters.status:''    
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_public_queries_params({...public_queries_params, page:1,searchParams:{
           ...public_queries_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_queries_by_number({...public_queries_params, page:1,searchParams:{
          ...public_queries_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let query_list = []
        for_loop(arr, (item) => {
            let obj = {
            name: item.name,
            email: item.email,
            message:item.message,
            mobileNumber:item.mobileNumber,
            specialityName:item.specialityName?item.specialityName:'N/A',
            hospitalName:item.hospitalName?item.hospitalName:'N/A',
            requestedService:item.requestedService?item.requestedService:'N/A',
            note:item.note?item.note:"N/A",
            status:item.status,
            createdAt:getDateFormat(item.createdAt),
            sms:item.sentMessages,
            source: item.queryType,
            _id: item._id
            }
            query_list.push({
          ...obj
            })
           
        })

    return query_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_public_queries_params({...public_queries_params, limit:b, page:1})
      props.get_queries_by_number({...public_queries_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,public_queries_params,"a and b public_queries_params")
      // if(public_queries_params.page !== a){
        if(true){
        set_public_queries_params({...public_queries_params, page:a, limit:b})
        props.get_queries_by_number({...public_queries_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_public_queries_params({...public_queries_params,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:props.mobileNumber
      },
      filters:{
        status:'',
        createdAt:1000
      }})
      props.get_queries_by_number({...public_queries_params,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:props.mobileNumber
      },
      filters:{
        status:'',
        createdAt:1000
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_public_queries_params({...public_queries_params, page:1, filters:{
        status:'',
        createdAt:1000
      }})
      props.get_queries_by_number({...public_queries_params, page:1, filters:{
        status:'',
        createdAt:1000
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const update_status = (val, record ) => {
        set_selected_row(record)
        set_selected_status(val)
            props.update_status({
              status:val,
              queryId:record._id,
              flag:"Non-Registered"
            })
    }

    const close_modal = () => {
            set_visible(false)
    }

    const expand = (record,a,b) => {
      console.log(record,a,b,"record,a,b")
      if(expandedRowKeys[0]!== a._id){
         set_expandedRowKeys([a._id])
         console.log(a,"a.varianceInfo")
      }else{
      set_expandedRowKeys([])
      }
    }

   const send_sms = (data) => {
        set_sms_select({
          queryId:data.queryId,
          message:data.sentMessage,
          sentMessage:data.sentMessage
        })
        props.send_sms({
          ...data
        })
   }

   const send_template_sms = (data) => {
     set_template_sms_select(data)
     props.send_template_sms({...data})
   }

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        //   ...getColumnSearchProps('name', "Search name", 'name'),
          width:'20%',
          fixed:'left'
        },
        
        {
          title: 'Source',
          dataIndex: 'source',
          key: 'source',
        //   ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
          width:'20%',
          fixed:'left'
        },
        {
          title: 'Speciality',
          dataIndex: 'specialityName',
          key: 'specialityName',
          // ...getColumnSearchProps('name', "Search name", 'name'),
          width:'20%',
          // fixed:'left'
        },
        {
          title: 'Service',
          dataIndex: 'requestedService',
          key: 'requestedService',
          // ...getColumnSearchProps('name', "Search name", 'name'),
          width:'20%',
          // fixed:'left'
        },
        {
          title: 'Professional',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
          // ...getColumnSearchProps('name', "Search name", 'name'),
          width:'20%',
          // fixed:'left'
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            width:'20%'
            // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
           
          },
          {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            width:'20%',
            render:(text)=>{
              return <p title={text}>{text}</p>
            },
            // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber'),
           
          },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width:'25%',
          filters: [
            {
              text: 'Pending',
              value: 'PENDING',
            },
            {
              text: 'Done',
              value: 'DONE',
            },
            {
              text: 'Hot Lead',
              value: 'HOT_LEAD',
            },
            {
              text: 'Cold Lead',
              value: 'COLD_LEAD',
            },
            {
              text:"Callback Due",
              value:"CALLBACK_DUE"
            }
          ],
          filterMultiple: false,   
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
         
          render:(item, record) => {
              return <div className="u-align-center">
                  <Select value={record.status} style={{ width: 120 }} onChange={(val)=>update_status(val, record)}>
                            <Option disabled={record.status==="PENDING"} value="PENDING">Pending</Option>
                            <Option disabled={record.status==="DONE"} value="DONE">Done</Option>
                            <Option disabled={record.status==="HOT_LEAD"} value="HOT_LEAD">Hot Lead</Option>
                            <Option disabled={record.status==="COLD_LEAD"} value="COLD_LEAD">Cold Lead</Option>
                            <Option disabled={record.status==="CALLBACK_DUE"} value="CALLBACK_DUE">Callback Due</Option>
    </Select>
              </div>
          }
        },
        {
            title: 'Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'15%',
            filters: [
              {
                text: 'Today',
                value: 1,
              },
              {
                text: 'Yesterday',
                value: 2,
              },
              {
                text: 'Last 7 days',
                value: 7,
              },
              {
                text: 'Last 30 days',
                value: 30,
              },
              {
                text: 'Default Value',
                value: 1000,
              }
            ],
            filterMultiple: false,
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
            
          },
          {
            title: 'Action',
            key: 'action',
            width:'15%',
            render: (text, record) => (
              <Space size="middle">
                <a onClick = {()=>open_modal(record)}>Add/Update Note</a>
              </Space>
            ),
          }
      ]

      const open_modal = (record) => {
        set_selected_row(record)
        set_visible(true)
        set_note({
          note:record.note
        })
      }

      const submit_note = () => {
        props.update_status({
          note:note.note,
          queryId:selected_row._id,
          flag:"Non-Registered"
        })
      }

      const send_bulk_sms = (data) => {
        let userids = ""
        data.solutionIds.map((item,i)=>{
                    if(i!==0){
                      userids = `${userids},${item}`
                    }else{
                      userids = `${userids}${item}`
                    }
                    
                  })
        props.send_sms({
          queryId:userids,
          sentMessage:data.messageBody,
          type:'sms'
        })
   }

      function showTotal(total) {
        return `Total ${total} items`;
      }

      console.log(data,"data in public queries")

   if(!!professional_selected){
        return <Redirect to={`/dashboard/professionals?professional_id=${professional_selected._id}`} />
   }

   if(send_sms_flag){
    return <React.Fragment>
           <PageHeader
     className="site-page-header"
   onBack={() => {
     set_send_sms_flag(false)
     set_selected_queries([])
   }}
   title="Send Bulk Messages"
   // subTitle="This is a subtitle"
 >
          <BulkSms
            list = {selected_queries}
            send_sms = {send_bulk_sms}
            send_sms_ret = {props.update_facebook_data_ret}
            send_sms_loading = {props.update_facebook_data_loading}
            send_sms_loading_flag = {props.update_facebook_data_loading_flag}
            global_error = {props.global_error}
          />

      </PageHeader>

    </React.Fragment>
  }


  if(bulk_sms_list){
    return <React.Fragment>
           <PageHeader
     className="site-page-header"
   onBack={() => {
     set_bulk_sms_list(false)
     set_selected_queries([])
   }}
   title="Previous Bulk Messages"
   // subTitle="This is a subtitle"
 >
          <BulkSmsList
            reportType = {"NON_REG_PUBLIC_QUERIES_REPORT"}

            get_bulk_sms_list = {props.get_bulk_sms_list}
            get_bulk_sms_list_ret = {props.get_bulk_sms_list_ret}
            get_bulk_sms_list_loading = {props.get_bulk_sms_list_loading}
            get_bulk_sms_list_loading_flag = {props.get_bulk_sms_list_loading_flag}

            global_error = {props.global_error}
          />

      </PageHeader>

    </React.Fragment>
  }



  

        return (<React.Fragment>
          <Modal
          title="Update/Add"
          visible={visible}
          onOk={submit_note}
          onCancel={close_modal}
        >
    <div className="flex-parent-new">
       <span className="flex-child-1-new">
           <AntdInput label="Note"  
            value={note.note} 
            error_text={error.note.text} 
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error,note:{text:'',state:false}})} 
            error={error.note.state} 
            name="note" 
            onChange = {event=>set_note({[event.target.name]:event.target.value})} 
            placeholder="Enter note"
            type="textarea"
            rows= {5}
            />
      </span>
</div>
        </Modal>

        {(selected_queries.length >1) &&  <Button
                type="primary"
                onClick={() => set_send_sms_flag(true)}
                size="small"
                style={{ width: 90 }}
                className="u-margin-top-small"
              >
                Send Sms
            </Button>}

            <div className="u-margin-top-small">

              <Button
                type="primary"
                onClick={() => set_bulk_sms_list(true)}
                size="small"
                style={{ width: 200 }}
                className="u-margin-top-small"
              >
                Previous Messages
               
            </Button>
            </div>
          
            <div className="u-margin-top-small">
        <Table
          columns={columns}
          dataSource={data}
          key={props.mobileNumber}
          loading={props.get_queries_by_number_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          scroll={{ x: 1300 }}
          pagination = {{
            showSizeChanger:true,
            limit:public_queries_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:public_queries_params.page,
            showTotal:showTotal,
            total:public_queries_params.total
          }}
          expandedRowRender= {record =>{
            return <React.Fragment>
                            <div key={record._id}>
                <SendSms
                    send_sms={send_sms}
                    type="public"
                    sms={record.sms}
                    queryId={record._id}
                    send_sms_loading_flag={props.send_sms_loading_flag}
                    get_message_templates = {props.get_message_templates}
                    get_message_templates_ret = {props.get_message_templates_ret}
                    
                    send_template_sms = {send_template_sms}
                    send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
                />
                </div>
      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record._id}
                rowSelection={{
                  selectedRowKeys:selectedRowKeys,
                  onChange: (e,b)=>{
                    setSelectedKeys([...e])
                    set_selected_queries([...b.map(item=>{
                      return {
                        name:item.name,
                        _id:item._id
                      }
                    })])
                  },
               }}
          />
          </div>
       
        </React.Fragment>)
}
export default MergedQueriesList