import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { GET_COVID_QUERIES,  GET_PUBLIC_QUERIES, SUBMIT_QUERY, 
   UPDATE_CITY, UPDATE_STATUS, GET_REG_PUBLIC_QUERIES, SEND_SMS, GET_SOLUTIONS, SEND_SOLUTION_SMS, GET_IVR_CALLS, 
   UPDATE_COVID_QUERIES, GET_FACEBOOK_QUERIES, UPLOAD_FACEBOOK_QUERIES, SEND_IVR_MESSAGE, BULK_SEND, 
   UPDATE_FACEBOOK_DATA, GET_IVR_MESSAGES, GET_UPLOAD_LIST, GET_BULK_SMS_LIST, GET_MANUAL_BIDDINGS, GET_MESSAGE_TEMPLATES, SEND_TEMPLATE_SMS, SEND_IVR_TEMPLATE_SMS, GET_IVR_SMS_TOKEN, SEND_MANUAL_TEMPLATE_SMS, GET_MANUAL_SMS_LIST, GET_ALL_QUERIES, GET_QUERIES_BY_NUMBER, GET_MERGED_QUERIES, UPDATE_PROSPECT, UPDATE_QUERY, SYNC_KNOWLARITY_LEADS, DOWNLOAD_SALES_LEADS } from '../Constants'
import { get_public_queries_ret, get_covid_queries_ret, submit_query_ret, update_city_ret,
    update_status_ret, get_reg_public_queries_ret, send_sms_ret, get_solutions_ret, send_solution_sms_ret, 
    get_ivr_calls_ret, update_covid_queries_ret, get_facebook_queries_ret, upload_facebook_queries_ret, 
    send_ivr_message_ret, update_facebook_data_ret,
    bulk_send_ret, get_ivr_messages_ret, get_upload_list_ret, get_bulk_sms_list_ret,
    get_manual_biddings_ret,
    get_message_templates_ret,
    send_template_sms_ret,
    get_ivr_sms_token_ret,
    send_manual_template_sms_ret,
    get_manual_sms_list_ret,
    get_all_queries_ret,
    get_queries_by_number_ret,
    get_merged_queries_ret,
    update_prospect_ret,
    update_query_ret,
    sync_knowlarity_leads_ret,
    download_sales_leads_ret
       } from '../actions/salesfunnel'
import { global_loading_clr } from '../actions/global'
import api from '../utils/api_routes'
import { get_url_params } from "../utils/common_utilities"
import store from '../store'



function* get_bulk_sms_list_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_bulk_sms_list
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_bulk_sms_list, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_bulk_sms_list_ret({
              success:true,
              message:api_data.message ||'Queries successfully fetched.',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_bulk_sms_list_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_bulk_sms_list_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_bulk_sms_list_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_upload_list_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_upload_list
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_upload_list, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_upload_list_ret({
              success:true,
              message:api_data.message ||'Queries successfully fetched.',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_upload_list_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_upload_list_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_upload_list_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* get_ivr_messages_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_ivr_messages
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_ivr_messages, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_ivr_messages_ret({
              success:true,
              message:api_data.message ||'Queries successfully fetched.',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_ivr_messages_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_ivr_messages_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_ivr_messages_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* update_facebook_data_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.update_facebook_data
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      let type = data.type
      let api_data = false
      delete data.type
      if(type==="sms"){
         let queryId = data.queryId
         delete data.queryId
         api_data = yield call(api.salesfunnel.send_facebook_query_message, data, queryId, headers)
      }else {
         api_data = yield call(api.salesfunnel.update_facebook_data, data, headers)
      }

      console.log(api_data,"api_data")
   
     if(!!api_data){
        if(api_data.success){
           yield put(update_facebook_data_ret({
              success:true,
              message:api_data.message ||'Successfully send',
              type
              }))
       }else{
           yield put(update_facebook_data_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               type
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_facebook_data_ret({
           success:false,
           message:'Something went wrong try again later..'
           
          }))
     }catch(x){
       yield put(update_facebook_data_ret({
           success:false,
           message:'Something went wrong try again later..'
           
          }))
       }
  }
}


function* bulk_send_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.bulk_send
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.bulk_send, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(bulk_send_ret({
              success:true,
              message:api_data.message ||'Successfully send',
              }))
       }else{
           yield put(bulk_send_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(bulk_send_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(bulk_send_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* send_ivr_message_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.send_ivr_message
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.send_ivr_message, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(send_ivr_message_ret({
              success:true,
              message:api_data.message ||'Successfully uplaoded',
              }))
       }else{
           yield put(send_ivr_message_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(send_ivr_message_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(send_ivr_message_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* upload_facebook_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.upload_facebook_queries
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.upload_facebook_queries, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(upload_facebook_queries_ret({
              success:true,
              message:api_data.message ||'Successfully uplaoded',
              }))
       }else{
           yield put(upload_facebook_queries_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(upload_facebook_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(upload_facebook_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* get_facebook_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_facebook_queries
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_facebook_queries, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_facebook_queries_ret({
              success:true,
              message:api_data.message ||'Queries successfully fetched.',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_facebook_queries_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_facebook_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_facebook_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* update_covid_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.update_covid_queries
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.update_covid_queries, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(update_covid_queries_ret({
              success:true,
              message:api_data.message ||'Message Successfully sent'
              }))
       }else{
           yield put(update_covid_queries_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_covid_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_covid_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* get_ivr_calls_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_ivr_calls
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_ivr_calls_saga")
      const api_data = yield call(api.salesfunnel.get_ivr_calls, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_ivr_calls_ret({
              success:true,
              message:api_data.data.message ||'Message Successfully sent',
              data:api_data.data.objects,
              count:api_data.data.meta.total_count
              }))
       }else{
           yield put(get_ivr_calls_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_ivr_calls_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_ivr_calls_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* send_solution_sms_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.send_solution_sms
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in send_solution_sms_flag")
      const api_data = yield call(api.salesfunnel.send_solution_sms, data, headers)
      // const api_data = {
      //    success:false,
      //    message:"Some Random message"
      // }
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(send_solution_sms_ret({
              success:true,
              message:api_data.message ||'Message Successfully sent',
           
              }))
       }else{
           yield put(send_solution_sms_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(send_solution_sms_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(send_solution_sms_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* get_solutions_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_solutions
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_solutions, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_solutions_ret({
              success:true,
              message:api_data.message ||'Message Successfully sent',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_solutions_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_solutions_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_solutions_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* send_sms_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.send_sms
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      let type= data.type
      let api_data = {}
      delete data.type
      if(type==="covid"){
         api_data = yield call(api.salesfunnel.send_covid_sms, data, headers)
      }else if(type==="registered"){
         api_data = yield call(api.salesfunnel.send_registered_sms, data, headers)
      }else if(type==="solutions"){
         let solutionId = data.solutionId
         if(!!solutionId){
            delete data.solutionId
         }
         api_data = yield call(api.salesfunnel.send_solutions_sms, data, solutionId, headers)
      } else {
         api_data = yield call(api.salesfunnel.send_public_sms, data, headers)
      } 
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(send_sms_ret({
              success:true,
              message:api_data.message ||'Message Successfully sent',
            //   data:api_data.data,
            //   count:api_data.count
              }))
       }else{
           yield put(send_sms_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(send_sms_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(send_sms_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}



function* get_reg_public_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_reg_public_queries
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_reg_public_queries, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_reg_public_queries_ret({
              success:true,
              message:api_data.message ||'Professionals Services successfully fetched',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_reg_public_queries_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_reg_public_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_reg_public_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_public_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_public_queries
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_public_queries, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_public_queries_ret({
              success:true,
              message:api_data.message ||'Professionals Services successfully fetched',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_public_queries_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_public_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_public_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_covid_queries_saga() {
    console.log("Inside get_key_prof_saga")
 
   try {
      const  data = yield store.getState().salesfunnel_store.get_covid_queries
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
      
       console.log(data,"data in get_key_prof")
       const api_data = yield call(api.salesfunnel.get_covid_queries, data, headers)
      console.log(api_data,"api_data in get_public_queries_ret")
      if(!!api_data){
         if(api_data.success){
            yield put(get_covid_queries_ret({
               success:true,
               message:api_data.message ||' successfully fetched',
               data:api_data.data,
               count:api_data.count
               }))
        }else{
            yield put(get_covid_queries_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_covid_queries_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_covid_queries_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
 }

 function* submit_query_saga() {
    console.log("Inside get_key_prof_saga")
 
   try {
      const  data = yield store.getState().salesfunnel_store.submit_query
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
      
       console.log(data,"data in get_key_prof")
       const api_data = yield call(api.salesfunnel.submit_query, data, headers)
      console.log(api_data,"api_data in get_public_queries_ret")
      if(!!api_data){
         console.log(api_data,"apidata in submit query")
         if(api_data.success){
            yield put(submit_query_ret({
               success:true,
               message:api_data.message ||'Message sent Successfully',
            //    data:api_data.data,
            //    count:api_data.count
               }))
        }else{
            yield put(submit_query_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(submit_query_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(submit_query_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
 }

function* update_city_saga() {
   console.log("Inside get_key_prof_saga")

   try {
      const  data = yield store.getState().salesfunnel_store.update_city
      const headers = {
         headers: {
             Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
      }

      console.log(data,"data in update_city")
      let flag = data.flag
      delete data.flag

      const api_data = yield call(api.salesfunnel.update_city, data, flag, headers)
      console.log(api_data,"api_data in get_public_queries_ret")
      if(!!api_data){
         if(api_data.success){
            yield put(update_city_ret({
               success:true,
               message:api_data.message ||' successfully fetched',
               data: api_data.data,
               type:data.type
               }))
         }else{
            yield put(update_city_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
               }))
         }
      }
   } catch (e) {
      console.log(e,"e in get_act insigt saga")
      try{
         yield put(update_city_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
            }))
      }catch(x){
         yield put(update_city_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
            }))
         }
   }
}


 function*  update_status_saga() {
    console.log("Inside get_key_prof_saga")
 
   try {
      const  data = yield store.getState().salesfunnel_store.update_status
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
      
       console.log(data,"data in update_status")
       let flag = data.flag
       delete data.flag
       
       const api_data = yield call(api.salesfunnel.update_status, data, flag, headers)
      console.log(api_data,"api_data in get_public_queries_ret")
      if(!!api_data){
         if(api_data.success){
            yield put(update_status_ret({
               success:true,
               message:api_data.message ||' successfully fetched',
               data: api_data.data,
               type:data.type
               }))
        }else{
            yield put(update_status_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(update_status_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(update_status_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
 }


 function*  get_manual_biddings_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_manual_biddings
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in update_status")
      let flag = data.flag
      delete data.flag
      
      const api_data = yield call(api.salesfunnel.get_manual_biddings, data, flag, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_manual_biddings_ret({
              success:true,
              message:api_data.message ||' successfully fetched',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_manual_biddings_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_manual_biddings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_manual_biddings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


// SalesFunnel Message Templates
function* get_message_templates_saga() {
   try {
      const api_data = yield call(api.salesfunnel.get_message_templates)
      if(api_data.success){
         yield put(get_message_templates_ret({
            success:true,
            message:api_data.message ||' successfully fetched',
            data:api_data.data,
         }))
     }else{
         yield put(get_message_templates_ret({
             success:false,
             message:api_data.message ||'Something went wrong try again later..',
             data:[]
         }))
     }

   } catch (e) {
      yield put(get_message_templates_ret({
         success:false,
         message:'Something went wrong try again later..',
         data:[]
      }))
   }
}

function* send_template_sms_saga() {
   try {
      const data = yield store.getState().salesfunnel_store.send_template_sms
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
      }

      const queryId = data.queryId
      delete data.queryId

      const api_data = yield call(api.salesfunnel.send_template_sms, data, queryId, headers)

      if(!!api_data){
         if(api_data.success){
            yield put(send_template_sms_ret({
               success:true,
               message:api_data.message ||'Message Successfully sent',

            }))
        }else{
            yield put(send_template_sms_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
            }))
        }
      }
   } catch (e) {
      yield put(send_sms_ret({
         success:false,
         message:'Something went wrong try again later..',
         data:[]
      }))
   }
}

function* send_ivr_template_sms_saga() {
   try {
      const data = yield store.getState().salesfunnel_store.send_ivr_template_sms
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
      }

      const queryId = data.queryId
      delete data.queryId

      const api_data = yield call(api.salesfunnel.send_ivr_template_sms, data, headers)

      if(!!api_data){
         if(api_data.success){
            yield put(send_template_sms_ret({
               success:true,
               message:api_data.message ||'Message Successfully sent',

            }))
        }else{
            yield put(send_template_sms_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
            }))
        }
      }
   } catch (e) {
      yield put(send_template_sms_ret({
         success:false,
         message:'Something went wrong try again later..',
         data:[]
      }))
   }
}

function* get_ivr_sms_token_saga() {
   try {

      const api_data = yield call(api.salesfunnel.get_ivr_sms_token)

      if(!!api_data){
         if(api_data.success){
            yield put(get_ivr_sms_token_ret({
               success:true,
               token: api_data.data.token,
               message:api_data.message ||'Token Fetched Succesfully',

            }))
        }else{
            yield put(get_ivr_sms_token_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
            }))
        }
      }
   } catch (e) {
      yield put(get_ivr_sms_token_ret({
         success:false,
         message:'Something went wrong try again later..',
         data:[]
      }))
   }
}

function* send_manual_template_sms_saga() {
   try {
      const data = yield store.getState().salesfunnel_store.send_manual_template_sms
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
      }

      const api_data = yield call(api.salesfunnel.send_manual_template_sms, data, headers)

      if(!!api_data){
         if(api_data.success){
            yield put(send_manual_template_sms_ret({
               success:true,
               message:api_data.message ||'Token Fetched Succesfully',

            }))
        }else{
            yield put(send_manual_template_sms_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
            }))
        }
      }
   } catch (e) {
      yield put(send_manual_template_sms_ret({
         success:false,
         message:'Something went wrong try again later..',
         data:[]
      }))
   }
}

function* get_manual_sms_list_saga() {
   try {
      const data = yield store.getState().salesfunnel_store.get_manual_sms_list
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
      }

      const api_data = yield call(api.salesfunnel.get_manual_sms_list, data, headers)

      if(!!api_data){
         if(api_data.success){
            yield put(get_manual_sms_list_ret({
               success:true,
               data: api_data.data,
               message:api_data.message ||'Messages Fetched Succesfully',
               count:api_data.count
            }))
        }else{
            yield put(get_manual_sms_list_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
            }))
        }
      }
   } catch (e) {
      yield put(get_manual_sms_list_ret({
         success:false,
         message:'Something went wrong try again later..',
         data:[]
      }))
   }
}

function* get_all_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_all_queries
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_all_queries, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_all_queries_ret({
              success:true,
              message:api_data.message ||'Professionals Services successfully fetched',
              data:api_data.data,
              services: api_data.services,
              count:api_data.count
              }))
       }else{
           yield put(get_all_queries_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_all_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_all_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_merged_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_merged_queries
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_merged_queries, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_merged_queries_ret({
              success:true,
              message:api_data.message ||'Professionals Services successfully fetched',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_merged_queries_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_merged_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_merged_queries_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* get_queries_by_number_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.get_queries_by_number
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      console.log(data,"data in get_key_prof")
      const api_data = yield call(api.salesfunnel.get_queries_by_number, data, headers)
     console.log(api_data,"api_data in get_public_queries_ret")
     if(!!api_data){
        if(api_data.success){
           yield put(get_queries_by_number_ret({
              success:true,
              message:api_data.message ||'Professionals Services successfully fetched',
              data:api_data.data,
              count:api_data.count
              }))
       }else{
           yield put(get_queries_by_number_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_queries_by_number_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_queries_by_number_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function*  update_prospect_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.update_prospect
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      let flag = data.flag
      delete data.flag
      
      const api_data = yield call(api.salesfunnel.update_prospect, data, flag, headers)
     if(!!api_data){
        if(api_data.success){
           yield put(update_prospect_ret({
              success:true,
              message:api_data.message ||' successfully fetched',
              type:data.type
              }))
       }else{
           yield put(update_prospect_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_prospect_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_prospect_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function*  update_query_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.update_query
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      let flag = data.flag
      delete data.flag
      
      const api_data = yield call(api.salesfunnel.update_query, data, flag, headers)
     if(!!api_data){
        if(api_data.success){
           yield put(update_query_ret({
              success:true,
              message:api_data.message ||' Query updated',
              type:data.type
              }))
       }else{
           yield put(update_query_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_query_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_query_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function*  sync_knowlarity_leads_saga() {
  try {
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      
      const api_data = yield call(api.salesfunnel.sync_knowlarity_leads, headers)
     if(!!api_data){
        if(api_data.success){
           yield put(sync_knowlarity_leads_ret({
              success:true,
              message:api_data.message ||' Queries Synced',
              }))
       }else{
           yield put(sync_knowlarity_leads_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(sync_knowlarity_leads_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(sync_knowlarity_leads_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}


function* download_all_queries_saga() {
   console.log("Inside get_key_prof_saga")

  try {
     const  data = yield store.getState().salesfunnel_store.download_sales_leads
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     
      const api_data = yield call(api.salesfunnel.download_sales_leads, data, headers)
     if(!!api_data){
        if(api_data.success){
           yield put(download_sales_leads_ret({
              success:true,
              message:api_data.message ||'Queries Download Successfully',
              url: api_data.url,
              filename: api_data.filename
              }))
       }else{
           yield put(download_sales_leads_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(download_sales_leads_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(download_sales_leads_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

export const salesfunnel_saga = [
   takeLatest(GET_PUBLIC_QUERIES, get_public_queries_saga),
   takeLatest(GET_COVID_QUERIES, get_covid_queries_saga),
   takeLatest(SUBMIT_QUERY, submit_query_saga),
   takeLatest(UPDATE_CITY, update_city_saga),
   takeLatest(UPDATE_STATUS, update_status_saga),
   takeLatest(GET_REG_PUBLIC_QUERIES, get_reg_public_queries_saga),
   takeLatest(SEND_SMS, send_sms_saga),
   takeLatest(GET_SOLUTIONS, get_solutions_saga),
   takeLatest(SEND_SOLUTION_SMS, send_solution_sms_saga),
   takeLatest(GET_IVR_CALLS, get_ivr_calls_saga),
   takeLatest(UPDATE_COVID_QUERIES, update_covid_queries_saga),
   takeLatest(GET_FACEBOOK_QUERIES, get_facebook_queries_saga),
   takeLatest(UPLOAD_FACEBOOK_QUERIES, upload_facebook_queries_saga),
   takeLatest(SEND_IVR_MESSAGE, send_ivr_message_saga),
   takeLatest(BULK_SEND, bulk_send_saga),
   takeLatest(UPDATE_FACEBOOK_DATA, update_facebook_data_saga),
   takeLatest(GET_IVR_MESSAGES, get_ivr_messages_saga),
   takeLatest(GET_UPLOAD_LIST, get_upload_list_saga),
   takeLatest(GET_BULK_SMS_LIST, get_bulk_sms_list_saga),
   takeLatest(GET_MANUAL_BIDDINGS, get_manual_biddings_saga),
   takeLatest(GET_MESSAGE_TEMPLATES, get_message_templates_saga),
   takeLatest(SEND_TEMPLATE_SMS, send_template_sms_saga),
   takeLatest(SEND_IVR_TEMPLATE_SMS, send_ivr_template_sms_saga),
   takeLatest(GET_IVR_SMS_TOKEN, get_ivr_sms_token_saga),
   takeLatest(SEND_MANUAL_TEMPLATE_SMS, send_manual_template_sms_saga),
   takeLatest(GET_MANUAL_SMS_LIST, get_manual_sms_list_saga),
   takeLatest(GET_ALL_QUERIES, get_all_queries_saga),
   takeLatest(GET_MERGED_QUERIES, get_merged_queries_saga),
   takeLatest(GET_QUERIES_BY_NUMBER, get_queries_by_number_saga),
   takeLatest(UPDATE_PROSPECT, update_prospect_saga),
   takeLatest(SYNC_KNOWLARITY_LEADS, sync_knowlarity_leads_saga),
   takeLatest(UPDATE_QUERY, update_query_saga),
   takeLatest(DOWNLOAD_SALES_LEADS, download_all_queries_saga)
]

export default salesfunnel_saga