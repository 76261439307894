import {  CHECK_FOLLOW_UP, CHECK_FOLLOW_UP_RET, CHECK_FOLLOW_UP_LOADING,
           UPDATE_FOLLOW_UP_LOADING, UPDATE_FOLLOW_UP_RET, UPDATE_FOLLOW_UP,
            GET_FOLLOW_UPS_LOADING, GET_FOLLOW_UPS_RET, GET_FOLLOW_UPS   } from "../Constants"

const initialState = {

    check_follow_up:false,
    check_follow_up_ret:false,
    check_follow_up_loading:false,

    update_follow_up:false,
    update_follow_up_ret:false,
    update_follow_up_loading:false,

    get_follow_ups:false,
    get_follow_ups_ret:false,
    get_follow_ups_loading:false

}

export default function (state = initialState, action) {
    switch (action.type) {


    case GET_FOLLOW_UPS:
        return {
            ...state,
            get_follow_ups:action.data,
            get_follow_ups_loading:true
        }

    case GET_FOLLOW_UPS_RET:
        return {
            ...state,
            get_follow_ups_ret:action.data,
            get_follow_ups_loading:true
        }
    case GET_FOLLOW_UPS_LOADING:
        return {
            ...state,
            get_follow_ups_ret:false,
            get_follow_ups_loading:false
        }

    case UPDATE_FOLLOW_UP:
        return {
            ...state,
            update_follow_up:action.data,
            update_follow_up_loading:true
        }

    case UPDATE_FOLLOW_UP_RET:
        return {
            ...state,
            update_follow_up_ret:action.data,
            update_follow_up_loading:true
        }
    case UPDATE_FOLLOW_UP_LOADING:
        return {
            ...state,
            update_follow_up_ret:false,
            update_follow_up_loading:false
        }
    
    case CHECK_FOLLOW_UP:
        return {
            ...state,
            check_follow_up:action.data,
            check_follow_up_loading:true
        }

    case CHECK_FOLLOW_UP_RET:
        return {
            ...state,
            check_follow_up_ret:action.data,
            check_follow_up_loading:true
        }
    case CHECK_FOLLOW_UP_LOADING:
        return {
            ...state,
            check_follow_up_ret:false,
            check_follow_up_loading:false
        }

        default:
        return {...state}
    
        }
}