import React, { useEffect, useState } from "react";
import { DeleteFilled, DeleteOutlined, EditOutlined, PlusCircleTwoTone, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Image, Input, List, message, Modal, Row, Space, Upload } from "antd";
import { error_form_check, get_url_params } from "../../utils/common_utilities";
import AntdInput from "../../Components/AntdInput";
import Title from "antd/lib/typography/Title";
import Search from "antd/lib/input/Search";

const UpdateInsurance = (props) => {
  const [insuranceList, setInsuranceList] = useState([]);
  const [insuranceListNames, setInsuranceListNames] = useState([]);
  const [professional, setProfessional] = useState(null);

	const [visible, setVisible] = useState(false);

  const [insuranceListGlobal, setInsuranceListGlobal] = useState([]);

  useEffect(() => {
    if(props.get_insurance)
      props.get_insurance()
  }, [props.get_insurance])

  useEffect(() => {
    if (props.get_professional_info_ret) {
      if (props.get_professional_info_ret.success) {
        console.log("GET_PROFESSIONAL_INFO_RET", props.get_professional_info_ret)
        let insuranceProviders = []
        props.get_professional_info_ret.data.insurance.map(insurance => {
          insuranceProviders.push(insurance.insurancePartner)
        })
        setInsuranceListNames(insuranceProviders)
        setInsuranceList(props.get_professional_info_ret.data.insurance)
        setProfessional({
          name: props.get_professional_info_ret.data.name,
          id: props.get_professional_info_ret.data._id,
        })
      } else {
        props.global_error({
          success: false,
          message: props.get_professional_info_ret.message,
          heading: "Professional",
        });
      }
      props.get_professional_info_loading();
    }
  }, [props.get_professional_info_ret]);

  useEffect(() => {
    if (props.get_insurance_ret) {
      if (props.get_insurance_ret.success) {
        console.log("GET_INSURANCE_RET", props.get_insurance_ret)
        let insuranceProvidersGlobal = []
        props.get_insurance_ret.data.map(insurance => {
          insuranceProvidersGlobal.push(insurance.insurancePartner)
        })
        setInsuranceListGlobal(insuranceProvidersGlobal)
      } else {
        props.global_error({
          success: false,
          message: props.get_insurance_ret.message,
          heading: "Insurance",
        });
      }
      props.get_insurance_loading();
    }
  }, [props.get_insurance_ret]);

  useEffect(() => {
    if (props.add_insurance_to_professional_ret) {
      if (props.add_insurance_to_professional_ret.success) {
        console.log("ADD_INSURANCE_TO_PROFESSIONAL", props.add_insurance_to_professional_ret)
        let insuranceProviders = []
        props.add_insurance_to_professional_ret.data.insurancePartners.map(insurance => {
          insuranceProviders.push(insurance.insurancePartner)
        })
        setInsuranceListNames(insuranceProviders)
        setInsuranceList(props.add_insurance_to_professional_ret.data.insurancePartners)
        props.global_error({
          success: true,
          message: props.add_insurance_to_professional_ret.message,
          heading: "Insurance",
        });
      } else {
        props.global_error({
          success: false,
          message: props.add_insurance_to_professional_ret.message,
          heading: "Insurance",
        });
      }
      props.add_insurance_to_professional_loading();
    }
  }, [props.add_insurance_to_professional_ret]);

  useEffect(() => {
    if (props.remove_insurance_from_professional_ret) {
      if (props.remove_insurance_from_professional_ret.success) {
        console.log("REMOVE_INSURANCE_FROM_PROFESSIONAL", props.remove_insurance_from_professional_ret)
        let insuranceProviders = []
        props.remove_insurance_from_professional_ret.data.map(insurance => {
          insuranceProviders.push(insurance.insurancePartner)
        })
        setInsuranceListNames(insuranceProviders)
        setInsuranceList(props.remove_insurance_from_professional_ret.data)
        props.global_error({
          success: true,
          message: props.remove_insurance_from_professional_ret.message,
          heading: "Insurance",
        });
      } else {
        props.global_error({
          success: false,
          message: props.remove_insurance_from_professional_ret.message,
          heading: "Insurance",
        });
      }
      props.remove_insurance_from_professional_loading();
    }
  }, [props.remove_insurance_from_professional_ret]);

  const addInsurance = (index) => {
    props.add_insurance_to_professional({
      professionalId: professional.id,
      insuranceId: props.get_insurance_ret.data[index]._id
    })
  }

  const deleteInsurance = (index) => {
    console.log("NANI_THE_FUCK", insuranceList[index])
    props.remove_insurance_from_professional({
      professionalId: professional.id,
      insuranceId: insuranceList[index].insuranceId
    })
  }

  return (
    <Card loading={props.get_professional_info_loading_flag} style={{ marginTop: '10px' }}>
      <Row>
        <Col span={23}>
          <Title level={3}>Insurance Accepted In Facility</Title>
        </Col>
        <Col span={1}>
          <Button
            type="primary"
            onClick={() => setVisible(true)}
            icon={<EditOutlined />}
          >
            Add
          </Button>
          <Modal
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={1000}
          >
            <Col span={23}>
              {professional && <Title level={3}>Add Insurance to {professional.name}</Title>}
            </Col>
            <Col span={12}>
              <List
                size="large"
                // bordered
                loading={props.get_professional_info_loading_flag || props.get_insurance_loading_flag || props.add_insurance_to_professional_loading_flag}
                dataSource={insuranceListGlobal}
                renderItem={(item, index) => (
                  <List.Item>
                    <Row>
                      <Col span={23}>{item}</Col>
                      <Col span={1}>
                        <Button
                          type="link"
                          onClick={() => addInsurance(index)}
                          icon={<PlusOutlined />}
                        ></Button>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </Col>
          </Modal>
        </Col>
      </Row>
      <Col span={24} style={{ marginTop: 20 }}>
        <List
          size="large"
          loading={props.add_insurance_to_professional_loading_flag || props.remove_insurance_from_professional_loading_flag || props.get_professional_info_loading_flag}
          dataSource={insuranceListNames}
          renderItem={(item, index) => (
            <List.Item>
              <Row>
                <Col span={23}>{item}</Col>
                <Col span={1}>
                  {' '}
                  <Button
                    type="link"
                    danger
                    onClick={() => deleteInsurance(index)}
                    icon={<DeleteOutlined />}
                  ></Button>{' '}
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Col>
    </Card>
  );
};

export default UpdateInsurance;
