import  React from "react"
import {  Card, Form, Button, Row, Col, Tag, Typography } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { error_form_check } from "../../utils/common_utilities"
import { Redirect } from "react-router-dom";
import SentBusinessReports from "./SentBusinessReports";

const { Title } = Typography;

const VarianceData = (props) => {
    const [form] = Form.useForm();
    const [data, set_data] = React.useState({
        consultation_message:'',
        business_message:'',
        consultation_notification:'',
        business_notification:'',
        consultation_leads:'',
        business_leads:'',
    })
    const [error, set_error] = React.useState({
      consultation_message:{ state:false, text:"" },
      business_message:{ state:false, text:"" },
      consultation_notification:{ state:false, text:"" },
      business_notification:{ state:false, text:"" },
      consultation_leads:{ state:false, text:"" },
      business_leads:{ state:false, text:"" }
 })

 React.useEffect(()=>{
    if(props.send_business_reports_ret){
        if(props.send_business_reports_ret.success){
            props.global_error({
             success:true,
             message:props.send_business_reports_ret.message,
             heading:'Business Reports',
           })
        }else {
            props.global_error({
             success:false,
             message:props.send_business_reports_ret.message,
             heading:'Business Reports'
           })
        }
        props.send_business_reports_loading()
        props.previous_business_reports({
          page:1,
          limit:10
        })
    }
}, [props.send_business_reports_ret])


React.useEffect(()=>{
  if(props.email_preview_ret){
    if(props.email_preview_ret.success){
      let screen = window.screen
      var win = window.open("", "Variance Email", `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${screen.width-400},height=${screen.height-400},top=`+(screen.height-100)+",left="+(screen.width-440));
      win.document.write(props.email_preview_ret.data)
        props.global_error({
         success:true,
         message:props.email_preview_ret.message,
         heading:'Preview Email',
       })
    }else {
        props.global_error({
         success:false,
         message:props.email_preview_ret.message,
         heading:'Preview Email'
       })
    }
 props.email_preview_loading()
}
 
},[props.email_preview_ret])


const send_email = () => {
    const ret_error = validate_email({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_business_reports({
          email:true,
          message:false,
          notification:false,
          textMessage:'',
          businessMessage:'',
          pushMessage:"",
          businessNotification:"",
          consultationLeads:data.consultation_leads,
          businessLeads:data.business_leads,
          notificationTitle:"",
          emailTitle:"PLUNES | Daily Business Reports",
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_email  = (data) =>{
  const  error = {
    consultation_message:{ state:false, text:"" },
    business_message:{ state:false, text:"" },
    consultation_notification:{ state:false, text:"" },
    business_notification:{ state:false, text:"" },
    consultation_leads:{ state:false, text:"" },
    business_leads:{ state:false, text:"" }
    }
console.log(data,"data sdsdsdsd")
    if(data.consultation_leads===''){
      {
        error.consultation_leads.text = "Please enter value"
        error.consultation_leads.state = true
      }
    }
    if(data.business_leads===''){
      {
        error.business_leads.text = "Please enter value"
        error.business_leads.state = true
      }
    }
    return error
  }


 const send_text_message = () => {
    const ret_error = validate_text_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_business_reports({
          email:false,
          message:true,
          notification:false,
          textMessage:data.consultation_message,
          businessMessage:data.business_message,
          pushMessage:"",
          businessNotification:"",
          consultationLeads:'',
          businessLeads:'',
          notificationTitle:"",
          emailTitle:"",
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_text_message  = (data) =>{
    const  error = {
      consultation_message:{ state:false, text:"" },
      business_message:{ state:false, text:"" },
      consultation_notification:{ state:false, text:"" },
      business_notification:{ state:false, text:"" },
      consultation_leads:{ state:false, text:"" },
      business_leads:{ state:false, text:"" }
      }

      if(data.consultation_message===''){
        {
          error.consultation_message.text = "Please enter your message"
          error.consultation_message.state = true
        }
      }
      if(data.business_message===''){
        {
          error.business_message.text = "Please enter your message"
          error.business_message.state = true
        }
      }
    return error
  }


  const send_push_message = () => {
    const ret_error = validate_push_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_business_reports({
          email:false,
          message:false,
          notification:true,
          textMessage:'',
          businessMessage:'',
          pushMessage:data.consultation_notification,
          businessNotification:data.business_notification,
          consultationLeads:'',
          businessLeads:'',
          notificationTitle:'Daily Businesss Reports',
          emailTitle:"",
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_push_message  = (data) =>{
  const  error = {
    consultation_message:{ state:false, text:"" },
    business_message:{ state:false, text:"" },
    consultation_notification:{ state:false, text:"" },
    business_notification:{ state:false, text:"" },
    consultation_leads:{ state:false, text:"" },
    business_leads:{ state:false, text:"" }
    }

    if(data.consultation_notification===''){
      {
        error.consultation_notification.text = "Please enter your message"
        error.consultation_notification.state = true
      }
    }
    if(data.business_notification===''){
      {
        error.business_notification.text = "Please enter your message"
        error.business_notification.state = true
      }
    }
  return error
  }

  console.log(error,"error in Variance Data")

  if(!!(props.keymanager_store.selected_professionals.length === 0)){
    return <Redirect to="/dashboard/keymanager?professionals_list"  />
  }

  console.log(props.keymanager_store.selected_professionals,"props.keymanager_store")
    return <React.Fragment>
          <Card >
            <Row>
              <Col span ={4}>
              <Title level={5}>Professionals Selected</Title>
                      {props.keymanager_store.selected_professionals.map(item=>{
                        return    <Tag  style={{margin:'.2rem'}} onClose={()=>props.set_key_manager_professionals(props.keymanager_store.selected_professionals.filter(val=>{
                           return (val._id !==item._id)
                        }))}>
                            {item.name}
                       </Tag>
                        })}
              </Col>
              <Col span ={20}>
              <Form
      form={form}
      layout="vertical"
    >
 
<div className="flex-parent-new">
  <span className="flex-child-1-new">
  <Title level={5}>Send Message</Title>
  <AntdInput label="Consultaion Message"  
            value={data.consultation_message} 
            error_text={error.consultation_message.text} 
            loading = {props.send_business_reports_loading_flag}
            disabled = {props.send_business_reports_loading_flag} 
            toggle_error = {()=>set_error({...error, consultation_message:{text:'',state:false}})} 
            error={error.consultation_message.state} 
            name="consultation_message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
   <AntdInput label="Business Message"  
            value={data.business_message} 
            error_text={error.business_message.text} 
            loading = {props.send_business_reports_loading_flag}
            disabled = {props.send_business_reports_loading_flag} 
            toggle_error = {()=>set_error({...error, business_message:{text:'',state:false}})} 
            error={error.business_message.state} 
            name="business_message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
 <div>
     <Button loading={props.send_business_reports_loading_flag} onClick={()=>send_text_message()} type={"primary"}>Send</Button>
 </div>
</span>
<span className="flex-child-1-new">
<Title level={5}>Send Notifcation</Title>
<AntdInput label="Consultaion Notification"  
            value={data.consultation_notification} 
            error_text={error.consultation_notification.text} 
            loading = {props.send_business_reports_loading_flag}
            disabled = {props.send_business_reports_loading_flag} 
            toggle_error = {()=>set_error({...error, consultation_notification:{text:'',state:false}})} 
            error={error.consultation_notification.state} 
            name="consultation_notification" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
   <AntdInput label="Business Notification"  
            value={data.business_notification} 
            error_text={error.business_notification.text} 
            loading = {props.send_business_reports_loading_flag}
            disabled = {props.send_business_reports_loading_flag} 
            toggle_error = {()=>set_error({...error, business_notification:{text:'',state:false}})} 
            error={error.business_notification.state} 
            name="business_notification" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
 <div>
     <Button  loading={props.send_business_reports_loading_flag} onClick={()=>send_push_message()} type={"primary"}>Send</Button>
 </div>
</span>
</div>

<div className="flex-parent-new ">
  <span className="flex-child-1-new u-margin-top-medium">
  <Title level={5}>Send Email</Title>
     <AntdInput label="Consultaion Leads"  
        value={data.consultation_leads} 
        error_text={error.consultation_leads.text}
        loading = {props.send_business_reports_loading_flag}
        disabled = {props.send_business_reports_loading_flag} 
        toggle_error = {()=>set_error({...error, consultation_leads:{text:'',state:false}})} 
        error={error.consultation_leads.state} 
        name="consultation_leads" 
        onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
        placeholder="Enter value" 
        validation = "positive_integer"
    />
     <AntdInput label="Business Leads"  
        value={data.business_leads} 
        error_text={error.business_leads.text}
        loading = {props.send_business_reports_loading_flag}
        disabled = {props.send_business_reports_loading_flag} 
        toggle_error = {()=>set_error({...error, business_leads:{text:'',state:false}})} 
        error={error.business_leads.state} 
        name="business_leads" 
        onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
        placeholder="Enter value" 
        validation = "positive_integer"
    />
    <div className="flex-parent-new">
    <span className="flex-child-1-new">
       <Button loading={props.send_business_reports_loading_flag} onClick={()=>send_email()} type={"primary"}>Send</Button>
    </span>
    <span className="flex-child-1-new">
        <Button loading={props.send_business_reports_loading_flag} onClick={()=>props.email_preview({type:"BUSINESS_REPORT_TEMPLATE"})} type={"primary"}>View Template</Button>
    </span>
    </div>
  </span>
</div>
  </Form>
              </Col>
            </Row>

            <Title level={5}>Sent Data</Title>
                        <SentBusinessReports
                            previous_business_reports = {props.previous_business_reports}
                            previous_business_reports_loading = {props.previous_business_reports_loading}
                            previous_business_reports_loading_flag = {props.previous_business_reports_loading_flag}
                            previous_business_reports_ret = {props.previous_business_reports_ret}
                        />
            </Card>
    </React.Fragment>
}

export default VarianceData