import { GET_USERS, GET_USERS_RET, GET_USERS_LOADING } from "../Constants"
import { act } from "react-dom/test-utils"

const initialState = {
    
        get_users:false,
        get_users_ret:false,
        get_users_loading:false
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_USERS: {
            return {
                ...state,
                get_users:{...action.data},
                get_users_loading:true
            }
        }

        case GET_USERS_RET: {
            return {
                ...state,
                get_users_ret:{...action.data},
                get_users_loading:true
            }
        }

        case GET_USERS_LOADING: {
            return {
                ...state,
                get_users_ret:false,
                get_users_loading:false
            }
        }

        default:
        return {...state}
    
        }
}