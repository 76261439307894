import React from "react"
import { Table, Input, Button, Space, Select, Modal, PageHeader } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals, getDateFormat} from '../../utils/common_utilities'
import AntdInput from "../../Components/AntdInput"

const { Option }  =  Select


const ManualBidding = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [public_queries_params, set_public_queries_params] = React.useState({
           page:1,
           limit:10,
           total:10,
           searchParams:{
             name:'',
             email:'',
             mobileNumber:''
           },
           filters:{
            status:'',
            createdAt:1000
           }
      })

      const [data, set_data] = React.useState([])
      const [visible, set_visible] = React.useState(false)
      const [selected_row, set_selected_row] = React.useState({})
      const [sms_select, set_sms_select] = React.useState({})
      const [selected_status, set_selected_status] = React.useState('')
      const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
      const [note, set_note] = React.useState({
          note:''
    })
    const [error, set_error] = React.useState({
        note:{ state:false, text:"" }
 })


 React.useEffect(()=>{
  if(!!props.send_sms_ret){
        if(props.send_sms_ret.success){
          props.global_error({
            success:true,
            message:props.send_sms_ret.message,
            heading:'SMS'
          })

          let arr = []
          let new_arr = [...data]
          arr   = new_arr.map(item=>{
            console.log(item, sms_select, "======================>")
                if(item._id===sms_select.queryId){
                  console.log(sms_select,"send_sms")
                  let new_sms = [...item.sms]
                  new_sms.unshift({
                    ...sms_select,
                    messageBody:sms_select.message
                  })
                  return {
                    ...item,
                    sms:new_sms
                  }   
                }
                else {
                  return item
                }
           })

          set_data(arr)

        }else{
          props.global_error({
            success:false,
            message:props.send_sms_ret.message,
            heading:'SMS'
          })
        }
        props.send_sms_loading()
  }
 },[props.send_sms_ret])

    React.useEffect(()=>{
        props.get_manual_biddings(public_queries_params)
    },[])

    React.useEffect(()=>{
      if(!!props.get_manual_biddings_ret){
         if(props.get_manual_biddings_ret.success){
          console.log("called again")
          set_data(modif_data([...props.get_manual_biddings_ret.data]))
          set_public_queries_params({...public_queries_params, total:props.get_manual_biddings_ret.count})
         }else{
          
          

         }
          props.get_manual_biddings_loading()
      }
         
  },[props.get_manual_biddings_ret])

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...public_queries_params.filters}
       let updated_filters = {}
   
       updated_filters.status = filters.status
       updated_filters.createdAt = filters.createdAt
      if(!!((pagination.current === public_queries_params.page) && (pagination.pageSize === public_queries_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_public_queries_params({...public_queries_params, page:1, filters :{
           createdAt: filters.createdAt? filters.createdAt:'',
           status:filters.status?filters.status:''
          }})
          props.get_manual_biddings({...public_queries_params, page:1, filters :{
            createdAt: filters.createdAt? filters.createdAt:'',
            status:filters.status?filters.status:''    
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_public_queries_params({...public_queries_params, page:1,searchParams:{
           ...public_queries_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_manual_biddings({...public_queries_params, page:1,searchParams:{
          ...public_queries_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' });
      };


    const modif_data = (arr) => {
    let query_list = []
        for_loop(arr, (item) => {
            let obj = {
            name: item.name,
            email: item.email,
            createdAt:getDateFormat(item.createdAt),
            facilities:item.facilities,
            queryDetails:item.queryDetails,
            userMobileNumber:item.userMobileNumber,
            userId:item.userId,
            _id: item._id
            }
            query_list.push({
          ...obj
            })
           
        })

    return query_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_public_queries_params({...public_queries_params, limit:b, page:1})
      props.get_manual_biddings({...public_queries_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,public_queries_params,"a and b public_queries_params")
      // if(public_queries_params.page !== a){
        if(true){
        set_public_queries_params({...public_queries_params, page:a, limit:b})
        props.get_manual_biddings({...public_queries_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_public_queries_params({...public_queries_params,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        status:'',
        createdAt:1000
      }})
      props.get_manual_biddings({...public_queries_params,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
        status:'',
        createdAt:1000
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_public_queries_params({...public_queries_params, page:1, filters:{
        status:'',
        createdAt:1000
      }})
      props.get_manual_biddings({...public_queries_params, page:1, filters:{
        status:'',
        createdAt:1000
      }})
    }


    const update_status = (val, record ) => {
        set_selected_row(record)
        set_selected_status(val)
            props.update_status({
              status:val,
              queryId:record._id,
              flag:"Non-Registered"
            })
    }

    const close_modal = () => {
            set_visible(false)
    }

    const expand = (record,a,b) => {
      console.log(record,a,b,"record,a,b")
      if(expandedRowKeys[0]!== a._id){
         set_expandedRowKeys([a._id])
         console.log(a,"a.varianceInfo")
      }else{
      set_expandedRowKeys([])
      }
    }

   const send_sms = (data) => {
        set_sms_select({
          queryId:data.queryId,
          message:data.sentMessage,
          sentMessage:data.sentMessage
        })
        props.send_sms({
          ...data
        })
   }


   const facilities_collumn = [
    {
      title: 'Facility',
      dataIndex: 'facilityName',
      key: 'facilityName',
      width:'25%',
      // fixed:'left'
    },
    {
      title: 'Number',
      dataIndex: 'facilityNumber',
      key: 'facilityNumber',
      width:'25%',
      // fixed:'left'
    },
    {
      title: 'Address',
      dataIndex: 'facilityAddress',
      key: 'facilityAddress',
      width:'25%',
      // fixed:'left'
    },
    {
      title: 'Email',
      dataIndex: 'facilityEmail',
      key: 'facilityEmail',
      width:'25%',
      // fixed:'left'
    }
  ]

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          ...getColumnSearchProps('name', "Search name", 'name'),
          width:'20%',
          // fixed:'left'
        },
        
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          ...getColumnSearchProps('email', "Search email", 'email'),
          width:'20%',
          // fixed:'left'
        },
        
        {
          title: 'Phone',
          dataIndex: 'userMobileNumber',
          key: 'userMobileNumber',
          ...getColumnSearchProps('userMobileNumber', "Search Phonenumber", 'userMobileNumber'),
          width:'20%',
          // fixed:'left'
        },
        {
          title: 'Query',
          dataIndex: 'queryDetails',
          key: 'queryDetails',
          // ...getColumnSearchProps('name', "Search name", 'name'),
          width:'20%',
          // fixed:'left'
        },
        {
            title: 'Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'20%',
            filters: [
              {
                text: 'Today',
                value: 1,
              },
              {
                text: 'Yesterday',
                value: 2,
              },
              {
                text: 'Last 7 days',
                value: 7,
              },
              {
                text: 'Last 30 days',
                value: 30,
              },
              {
                text: 'Default Value',
                value: 1000,
              }
            ],
            filterMultiple: false,
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter"),
            
          }
      ]

      const open_modal = (record) => {
        set_selected_row(record)
        set_visible(true)
        set_note({
          note:record.note
        })
      }

      const submit_note = () => {
        props.update_status({
          note:note.note,
          queryId:selected_row._id,
          flag:"Non-Registered"
        })
      }

      const send_bulk_sms = (data) => {
        let userids = ""
        data.solutionIds.map((item,i)=>{
                    if(i!==0){
                      userids = `${userids},${item}`
                    }else{
                      userids = `${userids}${item}`
                    }
                    
                  })
        props.send_sms({
          queryId:userids,
          sentMessage:data.messageBody,
          type:'sms'
        })
   }

      function showTotal(total) {
        return `Total ${total} items`;
      }

      console.log(data,"data in public queries")








  

        return (<React.Fragment>
          <Modal
          title="Update/Add"
          visible={visible}
          onOk={submit_note}
          onCancel={close_modal}
        >
    <div className="flex-parent-new">
       <span className="flex-child-1-new">
           <AntdInput label="Note"  
            value={note.note} 
            error_text={error.note.text} 
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error,note:{text:'',state:false}})} 
            error={error.note.state} 
            name="note" 
            onChange = {event=>set_note({[event.target.name]:event.target.value})} 
            placeholder="Enter note"
            type="textarea"
            rows= {5}
            />
      </span>
</div>
        </Modal>

      
            <div className="u-margin-top-small">
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_manual_biddings_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          // scroll={{ x: 1300 }}
          pagination = {{
            showSizeChanger:true,
            limit:public_queries_params.limit,
            onChange:(a,c)=>page_change(a,c),
            defaultCurrent:public_queries_params.page,
            showTotal:showTotal,
            total:public_queries_params.total
          }}
          expandedRowRender= {record =>{
            console.log(record,"record")
            return <React.Fragment>
                      <div key={record._id}>
                            <Table
                                columns={facilities_collumn}
                                dataSource={record.facilities}
                                loading={props.get_professionals_loading_flag}
                                // onChange={handleChange}
                                rowClassName="cursor-pointer"
                                // pagination = {{
                                //   showSizeChanger:true,
                                //   limit:get_professionals_params.limit,
                                //   onChange:(a,c)=>page_change(a,c),
                                //   // onShowSizeChange:(a,b)=>change_limit(a,b),
                                //   defaultCurrent:get_professionals_params.page,
                                //   total:get_professionals_params.total,
                                //   showTotal:showTotal
                                // }}
                                // onRow={(r) => ({
                                //   onClick: (b,a) => row_click(r),
                                // })}
                      />
                </div>
      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                rowKey={record => record._id}
          />
          </div>
       
        </React.Fragment>)
}
export default ManualBidding