import React, { useEffect } from "react"
import { Button, Card, Tabs, Form, Row, Col, Descriptions, Typography, Alert, PageHeader } from 'antd';
import { EditOutlined, AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { error_form_check, get_url } from '../../utils/common_utilities'
import AntdInput from "../../Components/AntdInput"
import EditFaqs from "./EditFaqs"
import UploadImage from "../../Components/UploadImage"
import axios from "axios"

const { Title } = Typography;

const { TabPane } = Tabs;

const changeToString = (arr = []) => {
   if(Array.isArray(arr)){
    let str = ``;
    arr.forEach(item => {
        str += item;
        str += '\n';
    })
    return str;
   }
   return arr;
}

const ServiceDataHandler = (props) => {
    const [editInstruction, set_editInstruction] = React.useState([])

    const [add_service, set_add_service] = React.useState({
        common_specaility_id: "",
        service_family_name: ''
    })

    const [specialities, set_specialities] = React.useState([])
    const [services, setServices] = React.useState([])

    const [error_add_service, set_error_add_service] = React.useState({
        common_specaility_id: { state: false, text: "" },
        service_family_name: { state: false, text: "" },
    })

    const [add_service_flag, set_add_service_flag] = React.useState(false)


    const [note, set_note] = React.useState(["- Add the word between span tag i.e. <span style=\"color: rgb(37, 178, 129);\">Example</span>.", "- For Add new Line add \"<br/ >\" to start the new line with in the paragraph.", "-Press Enter to give  bullet to new Line."])


    const [state, set_state] = React.useState({
        check_status: "PENDING"
    })
    const [active_tab, set_active_tab] = React.useState(1)
    const [edit_flag, set_edit_flag] = React.useState(false)
    const [confirm_booking, set_confirm_booking] = React.useState(false)
    const [fetch_error, set_fetch_error] = React.useState(false)
    const [service_family_list, set_service_family_list] = React.useState({
        familyList: '',
    })
    const [initial_data, set_initial_data] = React.useState({
        id: '',
        speciality: '',
        heading: '',
        subHeading: '',
        definition: '',
        duration: '',
        discharge: '',
        treatments: '',
        treatmentInfo: '',
        riskInfo: '',
        advantageInfo: '',
        causeInfo: '',
        onLinerInfo: '',
        symptomInfo: '',

        precaution_data: '',
        process_data: '',
        reason_data: '',
        reason_heading: '',
        symptom_data: '',
        symptom_heading: '',
        types_data: '',
        types_heading: '',

        meta_title: '',
        meta_description: '',


        treatment_heading: '',
        risk_heading: '',
        advantage_heading: '',
        cause_heading: '',
        precaution_heading: '',
        process_heading: '',
        usesAndNeeds_data: '',
        usesAndNeeds_heading: '',
        condition_data: '',
        condition_heading: '',

        aboutService_description: '',
        aboutService_heading: '',
        aboutService_subHeading: '',

        bigChainPricePlunes: '',
        bigChainPriceWithoutPlunes: '',

        multiSpecialityPricePlunes: '',
        multiSpecialityPriceWithoutPlunes: '',

        regionalBrandsPricePlunes: '',
        regionalBrandsPriceWithoutPlunes: '',

        stages_data: '',
        stages_heading: '',

        benefits_data: '',
        benefits_heading: '',

        facts_data: '',
        facts_heading: '',

        dos_data: '',
        dos_heading: '',

        donts_data: '',
        donts_heading: ''

    })
    const [data, set_data] = React.useState({
        meta_title: '',
        meta_description: '',

        serviceFamily: '',
        _id: '',
        speciality: '',
        familyList: '',
        heading: '',
        subHeading: '',
        definition: '',
        duration: '',
        discharge: '',
        treatments: '',
        treatmentInfo: '',
        riskInfo: '',
        advantageInfo: '',
        causeInfo: '',
        onLinerInfo: '',

        symptomInfo: '',
        precaution_data: '',
        process_data: '',
        reason_data: '',
        reason_heading: '',
        symptom_data: '',
        symptom_heading: '',
        types_data: '',
        types_heading: '',

        treatment_heading: '',
        risk_heading: '',
        advantage_heading: '',
        cause_heading: '',
        precaution_heading: '',
        process_heading: '',
        usesAndNeeds_data: '',
        usesAndNeeds_heading: '',
        condition_data: '',
        condition_heading: '',

        aboutService_description: '',
        aboutService_heading: '',
        aboutService_subHeading: '',

        bigChainPricePlunes: '',
        bigChainPriceWithoutPlunes: '',

        multiSpecialityPricePlunes: '',
        multiSpecialityPriceWithoutPlunes: '',

        regionalBrandsPricePlunes: '',
        regionalBrandsPriceWithoutPlunes: '',

        stages_data: '',
        stages_heading: '',

        benefits_data: '',
        benefits_heading: '',

        facts_data: '',
        facts_heading: '',

        dos_data: '',
        dos_heading: '',

        donts_data: '',
        donts_heading: ''

    })
    const [error, set_error] = React.useState({
        serviceFamily: { state: false, text: "" },
        speciality: { state: false, text: "" },
        heading: { state: false, text: "" },
        subHeading: { state: false, text: "" },
        definition: { state: false, text: "" },
        duration: { state: false, text: "" },
        discharge: { state: false, text: "" },
        treatments: { state: false, text: "" },
        treatmentInfo: { state: false, text: "" },
        riskInfo: { state: false, text: "" },
        advantageInfo: { state: false, text: "" },
        causeInfo: { state: false, text: "" },
        onLinerInfo: { state: false, text: "" },
        symptomInfo: { state: false, text: "" },

        precaution_data: { state: false, text: "" },
        process_data: { state: false, text: "" },
        reason_data: { state: false, text: "" },
        reason_heading: { state: false, text: "" },
        symptom_data: { state: false, text: "" },
        symptom_heading: { state: false, text: "" },
        types_data: { state: false, text: "" },
        types_heading: { state: false, text: "" },

        treatment_heading: { state: false, text: "" },
        risk_heading: { state: false, text: "" },
        advantage_heading: { state: false, text: "" },
        cause_heading: { state: false, text: "" },
        precaution_heading: { state: false, text: "" },
        process_heading: { state: false, text: "" },
        usesAndNeeds_data: { state: false, text: "" },
        usesAndNeeds_heading: { state: false, text: "" },

        condition_data: { state: false, text: "" },
        condition_heading: { state: false, text: "" },
        aboutService_description: { state: false, text: "" },
        aboutService_heading: { state: false, text: "" },
        aboutService_subHeading: { state: false, text: "" }
    })

    const [form] = Form.useForm()

    const initialize_data = () => {
        set_data({

            meta_title: '',
            meta_description: '',
            serviceFamily: '',
            _id: '',
            speciality: '',
            familyList: '',
            heading: '',
            subHeading: '',
            definition: '',
            duration: '',
            discharge: '',
            treatments: '',
            treatmentInfo: '',
            riskInfo: '',
            advantageInfo: '',
            causeInfo: '',
            onLinerInfo: '',
            symptomInfo: '',
            precaution_data: '',
            process_data: '',
            reason_data: '',
            reason_heading: '',
            symptom_data: '',
            symptom_heading: '',
            types_data: '',
            types_heading: '',
            treatment_heading: '',
            risk_heading: '',
            advantage_heading: '',
            cause_heading: '',
            precaution_heading: '',
            process_heading: '',
            usesAndNeeds_data: '',
            usesAndNeeds_heading: '',
            condition_data: '',
            condition_heading: '',
            aboutService_description: '',
            aboutService_heading: '',
            aboutService_subHeading: '',
            bigChainPricePlunes: '',
            bigChainPriceWithoutPlunes: '',
            multiSpecialityPricePlunes: '',
            multiSpecialityPriceWithoutPlunes: '',
            regionalBrandsPricePlunes: '',
            regionalBrandsPriceWithoutPlunes: '',
            stages_data: '',
            stages_heading: '',
            benefits_data: '',
            benefits_heading: '',
            facts_data: '',
            facts_heading: '',
            dos_data: '',
            dos_heading: '',
            donts_data: '',
            donts_heading: ''
        })
    }

    const add_service_button = () => {
        try {
            set_add_service_flag(true)
            set_edit_flag(true)
            axios
                .get(`${get_url()}/mcmCatalogue/getCommonSpecialities`)
                .then((res) => {
                    console.log(res, 'res in add_service_button');
                    let data = res.data.data.map(item => {
                        return {
                            name: item.speciality,
                            value: item._id
                        }
                    })
                    set_specialities([...data])
                })
        } catch (error) {

        }
    }

    React.useEffect(() => {
        try {
            axios
                .get(`${get_url()}/mcmCatalogue/getCommonSpecialities`)
                .then((res) => {
                    console.log(res, 'res in add_service_button');
                    let data = res.data.data.map(item => {
                        return {
                            name: item.speciality,
                            value: item.specialityId
                        }
                    })
                    set_specialities([...data])
                })
        } catch (error) {

        }
    }, [])

    console.log("service family", props.get_service_family_byid_ret)

    React.useEffect(() => {

        if (props.get_service_family_list_by_specialityid_ret) {
            if (props.get_service_family_list_by_specialityid_ret.success) {
                try {
                    console.log("get_service_family_list_buy", props.get_service_family_list_by_specialityid_ret)
                    let data = props.get_service_family_list_by_specialityid_ret.data
                    set_initial_data({
                        familyList: data,
                    })
                    let serviceData = props.get_service_family_list_by_specialityid_ret.data.map(item => {
                        return {
                            name: item.familyName,
                            value: item._id
                        }
                    })
                    setServices([...serviceData])

                } catch (error) {
                    console.log(error)
                }

            } else {
                set_fetch_error(true)
                props.global_error({
                    success: false,
                    message: props.get_service_family_list_by_specialityid_ret.message,
                    heading: 'Service Family List'
                })
            }
            props.get_service_family_list_by_specialityid_loading()
        }
        if (props.get_service_family_byid_ret) {
            if (props.get_service_family_byid_ret.success) {
                try {
                    let data = props.get_service_family_byid_ret.data
                    // console.log("dasfsda", props.get_service_family_byid_ret)

                    set_editInstruction([...props.get_service_family_byid_ret.editInstruction])
                    
                    set_initial_data({
                        id: (data._id),
                        heading: (data.heading),
                        subHeading: (data.subHeading),
                        definition: (data.definition),
                        duration: (data.duration),
                        discharge: (data.discharge),
                        treatments: changeToString(data.treatments),
                        treatmentInfo: changeToString(data.treatmentInfo),
                        riskInfo: changeToString(data.riskInfo),
                        advantageInfo: changeToString(data.advantageInfo),
                        causeInfo: changeToString(data.causeInfo),
                        symptomInfo: changeToString(data.symptomInfo),
                        onLinerInfo: changeToString(data.onLinerInfo),
                        testimonials: (data.testimonials),
                        FAQ: data.FAQ,

                        precaution_data: changeToString(data.precaution_data),
                        process_data: changeToString(data.process_data),
                        reason_data: changeToString(data.reason_data),
                        reason_heading: changeToString(data.reason.heading),
                        // symptom_data:dchangeToString(ata.symptom.symptomData),
                        symptom_heading: changeToString(data.symptom.heading),
                        types_data: changeToString(data.types_data),
                        types_heading: changeToString(data.types.heading),


                        treatment_heading: changeToString(data.treatment_heading),
                        risk_heading: changeToString(data.risk_heading),
                        advantage_heading: changeToString(data.advantage_heading),
                        cause_heading: changeToString(data.cause_heading),
                        precaution_heading: changeToString(data.precaution_heading),
                        process_heading: changeToString(data.process_heading),
                        usesAndNeeds_data: changeToString(data.usesAndNeeds_data),
                        usesAndNeeds_heading: changeToString(data.usesAndNeeds_heading),

                        condition_data: changeToString(data.condition_data),
                        condition_heading: changeToString(data.condition_heading),
                        aboutService_description: changeToString(data.aboutService_description),
                        aboutService_heading: changeToString(data.aboutService_heading),
                        aboutService_subHeading: changeToString(data.aboutService_subHeading),

                        bigChainPricePlunes: data.priceWithPlunes ? data.priceWithPlunes.bigChains : null,
                        bigChainPriceWithoutPlunes: data.priceWithoutPlunes ? data.priceWithoutPlunes.bigChains : null,

                        multiSpecialityPricePlunes: data.priceWithPlunes ? data.priceWithPlunes.multiSpecialities : null,
                        multiSpecialityPriceWithoutPlunes: data.priceWithoutPlunes ? data.priceWithoutPlunes.multiSpecialities : null,

                        regionalBrandsPricePlunes: data.priceWithPlunes ? data.priceWithPlunes.regionalBrands : null,
                        regionalBrandsPriceWithoutPlunes: data.priceWithoutPlunes ? data.priceWithoutPlunes.regionalBrands : null,

                        stages_data: changeToString(data.stages.data),
                        stages_heading: changeToString(data.stages.heading),

                        benefits_data: changeToString(data.benefits.data),
                        benefits_heading: changeToString(data.benefits.heading),

                        facts_data: changeToString(data.facts.data),
                        facts_heading: changeToString(data.facts.heading),

                        dos_data: changeToString(data.dos.data),
                        dos_heading: changeToString(data.dos.heading),

                        donts_data: changeToString(data.donts.data),
                        donts_heading: changeToString(data.donts.heading),
                        meta_title: changeToString(data?.metaData?.title),
                        meta_description: changeToString(data?.metaData?.desc),

                    })

                } catch (error) {
                    console.log(error)
                }

            } else {
                set_fetch_error(true)
                props.global_error({
                    success: false,
                    message: props.get_service_family_byid_ret.message,
                    heading: 'Service Family'
                })
            }
            props.get_service_family_byid_loading()
        }
        if (props.service_data_handler_ret) {
            set_edit_flag(!edit_flag)
            set_initial_data({
                ...data
            })
            if (props.service_data_handler_ret.success) {
                props.global_error({
                    success: true,
                    message: props.service_data_handler_ret.message,
                    heading: "Service Data Handler",
                })
            } else {
                props.global_error({
                    success: false,
                    message: props.service_data_handler_ret.message,
                    heading: "Service Data Handle",
                })
            }
            props.service_data_handler_loading()
        }

    }, [props.get_service_family_byid_ret, props.get_service_family_list_by_specialityid_ret, props.service_data_handler_ret])

    const edit_cllck = () => {
        set_edit_flag(!edit_flag)
        set_data({
            ...initial_data
        })
    }

    const speciality_change = (val) => {
        console.log("specililitId", val)
        props.get_service_family_list_by_specialityid({ specialityId: val })
        set_data({ ...data, "speciality": val, category: '' })
    }

    const serviceFamilies_change = (val) => {
        console.log("service family", val)
        props.get_service_family_byid({ id: val })
        set_data({ ...data, "serviceFamily": val, category: '' })
    }


    const validateHeading = (data) => {
        const error = {
            heading: { state: false, text: "" }
        }
        if (data.heading === '') {
            {
                error.heading.text = "Enter Heading"
                error.heading.state = true
            }
        }
        return error
    }

    const validateSubHeading = (data) => {
        const error = {
            subHeading: { state: false, text: "" }
        }
        if (data.subHeading === '') {
            {
                error.subHeading.text = "Enter subHeading"
                error.subHeading.state = true
            }
        }
        return error
    }

    const validateDuration = (data) => {
        const error = {
            duration: { state: false, text: "" }
        }
        if (data.duration === '') {
            {
                error.duration.text = "Enter duration"
                error.duration.state = true
            }
        }
        return error
    }

    const validateDefinition = (data) => {
        const error = {
            definition: { state: false, text: "" }
        }
        if (data.definition === '') {
            {
                error.definition.text = "Enter definition"
                error.definition.state = true
            }
        }
        return error
    }

    const validateDischarge = (data) => {
        const error = {
            discharge: { state: false, text: "" }
        }
        if (data.discharge === '') {
            {
                error.discharge.text = "Enter On liner"
                error.discharge.state = true
            }
        }
        return error
    }

    const validateTreatment = (data) => {
        const error = {
            treatmentInfo: { state: false, text: "" }
        }
        if (data.treatmentInfo === '') {
            {
                error.treatmentInfo.text = "Enter treatment"
                error.treatmentInfo.state = true
            }
        }
        return error
    }

    const validateRisk = (data) => {
        const error = {
            riskInfo: { state: false, text: "" }
        }
        if (data.riskInfo === '') {
            {
                error.riskInfo.text = "Enter Risk"
                error.riskInfo.state = true
            }
        }
        return error
    }

    const validateAdvantages = (data) => {
        const error = {
            advantageInfo: { state: false, text: "" }
        }
        if (data.advantageInfo === '') {
            {
                error.advantageInfo.text = "Enter Advantages"
                error.advantageInfo.state = true
            }
        }
        return error
    }

    const validateCause = (data) => {
        const error = {
            causeInfo: { state: false, text: "" }
        }
        if (data.causeInfo === '') {
            {
                error.causeInfo.text = "Enter Causes"
                error.causeInfo.state = true
            }
        }
        return error
    }

    const validateSymptom = (data) => {
        const error = {
            symptomInfo: { state: false, text: "" }
        }
        if (data.symptomInfo === '') {
            {
                error.symptomInfo.text = "Enter Symptom"
                error.symptomInfo.state = true
            }
        }
        return error
    }
    const validateOnliner = (data) => {
        const error = {
            onLinerInfo: { state: false, text: "" }
        }
        if (data.onLinerInfo === '') {
            {
                error.onLinerInfo.text = "Enter On liner"
                error.onLinerInfo.state = true
            }
        }
        return error
    }
    const submitOnliner = () => {
        const ret_error = validateOnliner({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        console.log("on liner", data.onLinerInfo)
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                onLinerInfo: data.onLinerInfo,
            })
        }
    }
    const submitSymptom = () => {
        const ret_error = validateSymptom({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        console.log("Symptom", data.symptomInfo)
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                symptomInfo: data.symptomInfo,
            })
        }
    }
    const submitCauses = () => {
        const ret_error = validateCause({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        console.log("causes", data.causeInfo)
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                causeInfo: data.causeInfo,
            })
        }
    }
    const submitAdvantages = () => {
        const ret_error = validateAdvantages({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        console.log("advantages", data.advantageInfo)
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                advantageInfo: data.advantageInfo,
            })
        }
    }
    const submitRisk = () => {
        const ret_error = validateRisk({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        console.log("risk", data.riskInfo)
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                riskInfo: data.riskInfo,
            })
        }
    }

    const submitTreatment = () => {
        const ret_error = validateTreatment({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        console.log("treatment", data.treatmentInfo)
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                treatmentInfo: data.treatmentInfo,
            })
        }
    }

    const submitHeading = () => {
        const ret_error = validateHeading({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                heading: data.heading,
            })
        }
    }

    const submitSubHeading = () => {
        const ret_error = validateSubHeading({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                subHeading: data.subHeading,
            })
        }
    }
    const submitDuration = () => {
        const ret_error = validateDuration({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                duration: data.duration,
            })
        }
    }
    const submitDefinition = () => {
        const ret_error = validateDefinition({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                definition: data.definition,
            })
        }
    }

    const submitDischarge = () => {
        const ret_error = validateDischarge({ ...data });
        set_error({ ...error, ...ret_error });
        console.log(ret_error, "ret_error in add_member")
        if (!error_form_check(ret_error)) {
            props.service_data_handler({
                id: initial_data.id,
                discharge: data.discharge,
            })
        }
    }

    const submit_all = () => {
        const ret_error = valid_submit_all({ ...data })
        set_error({ ...error, ...ret_error })
        console.log(ret_error, "ret_error in add_member")
        if (!error_form_check(ret_error)) {
            console.log("inside submit_all")

            if (add_service_flag) {
                props.service_data_handler({
                    ...data,
                    commonSpecialityId: add_service.common_specaility_id,
                    familyName: add_service.service_family_name,
                    type: "add_service"
                })
            } else {
                props.service_data_handler({
                    id: initial_data.id,
                    ...data
                })
            }

        } else {
            props.global_error({
                message: "Please check that all fields are filled",
                success: false,
                heading: "Service Data Uploader"

            })
        }
    }

    const valid_submit_all = (data) => {
        const error = {
            serviceFamily: { state: false, text: "" },
            speciality: { state: false, text: "" },
            heading: { state: false, text: "" },
            subHeading: { state: false, text: "" },
            definition: { state: false, text: "" },
            duration: { state: false, text: "" },
            discharge: { state: false, text: "" },
            treatments: { state: false, text: "" },
            treatmentInfo: { state: false, text: "" },
            riskInfo: { state: false, text: "" },
            advantageInfo: { state: false, text: "" },
            causeInfo: { state: false, text: "" },
            onLinerInfo: { state: false, text: "" },
            symptomInfo: { state: false, text: "" },


            precaution_data: { state: false, text: "" },
            process_data: { state: false, text: "" },
            reason_data: { state: false, text: "" },
            reason_heading: { state: false, text: "" },
            symptom_data: { state: false, text: "" },
            symptom_heading: { state: false, text: "" },
            types_data: { state: false, text: "" },
            types_heading: { state: false, text: "" },


        }
        // if (data.onLinerInfo === '') {
        //     {
        //         error.onLinerInfo.text = "Enter On liner"
        //         error.onLinerInfo.state = true
        //     }
        // }
        // if (data.heading === '') {
        //     {
        //         error.heading.text = "Enter Heading"
        //         error.heading.state = true
        //     }
        // }

        // if (data.subHeading === '') {
        //     {
        //         error.subHeading.text = "Enter Sub heading"
        //         error.subHeading.state = true
        //     }
        // }
        // if (data.definition === '') {
        //     {
        //         error.definition.text = "Enter Definition"
        //         error.definition.state = true
        //     }
        // }
        // if (data.duration === '') {
        //     {
        //         error.duration.text = "Enter Duration"
        //         error.duration.state = true
        //     }
        // }
        // if (data.discharge === '') {
        //     {
        //         error.discharge.text = "Enter Discharge"
        //         error.discharge.state = true
        //     }
        // }

        // if (data.treatments === '') {
        //     {
        //         error.treatments.text = "Enter Treatment"
        //         error.treatments.state = true
        //     }
        // }
        // if (data.treatmentInfo === '') {
        //     {
        //         error.treatmentInfo.text = "Enter Info"
        //         error.treatmentInfo.state = true
        //     }
        // }
        // if (data.riskInfo === '') {
        //     {
        //         error.riskInfo.text = "Enter Info"
        //         error.riskInfo.state = true
        //     }
        // }
        // if (data.advantageInfo === '') {
        //     {
        //         error.advantageInfo.text = "Enter Info"
        //         error.advantageInfo.state = true
        //     }
        // }
        // if (data.causeInfo === '') {
        //     {
        //         error.causeInfo.text = "Enter Info"
        //         error.causeInfo.state = true
        //     }
        // }
        // if (data.onLinerInfo === '') {
        //     {
        //         error.onLinerInfo.text = "Enter Info"
        //         error.onLinerInfo.state = true
        //     }
        // }
        // if (data.symptomInfo === '') {
        //     {
        //         error.symptomInfo.text = "Enter Info"
        //         error.symptomInfo.state = true
        //     }
        // }

        // if (data.precaution_data === '') {
        //     {
        //         error.precaution_data.text = "Enter Info"
        //         error.precaution_data.state = true
        //     }
        // }
        // if (data.process_data === '') {
        //     {
        //         error.process_data.text = "Enter Info"
        //         error.process_data.state = true
        //     }
        // }
        // if (data.reason_data === '') {
        //     {
        //         error.reason_data.text = "Enter Info"
        //         error.reason_data.state = true
        //     }
        // }
        // if (data.reason_heading === '') {
        //     {
        //         error.reason_heading.text = "Enter Info"
        //         error.reason_heading.state = true
        //     }
        // }

        // if (data.symptom_heading === '') {
        //     {
        //         error.symptom_heading.text = "Enter Info"
        //         error.symptom_heading.state = true
        //     }
        // }

        // if (data.symptom_data === '') {
        //     {
        //         error.symptom_data.text = "Enter Info"
        //         error.symptom_data.state = true
        //     }
        // }

        // if (data.types_data === '') {
        //     {
        //         error.types_data.text = "Enter Info"
        //         error.types_data.state = true
        //     }
        // }

        // if (data.types_heading === '') {
        //     {
        //         error.types_heading.text = "Enter Info"
        //         error.types_heading.state = true
        //     }
        // }



        console.log(error, "error in Validate")

        return error
    }

    const getTreatmentData = () => {
        let temp = ''

        if (initial_data.treatments) {
            initial_data.treatments.treatmentData.forEach(element => {
                temp = temp + '\n' + element
            })
            return temp
        }
    }


    const tabs_change = (val) => {
        console.log(val, "val in tabs_change")
        set_active_tab(val)
    }
    console.log({ data, benefits: data?.benefits?.data }, "DATATATa")

    console.log(active_tab, "active_tab")

    if (!!edit_flag) {
        return (<React.Fragment>
            <Card title="">
                <PageHeader
                    className="site-page-header"
                    onBack={() => {
                        set_edit_flag(false)
                        set_add_service_flag(false)
                        initialize_data()
                    }}
                    title="Service Data Handler"

                />
                <Row>
                    <div className="u-margin-top-small">
                        <div className="u-margin-bottom-small">
                            <Title level={5}>Note</Title>
                        </div>
                        {editInstruction.length !== 0 ? editInstruction.map(item => {
                            return <div className="u-margin-top-small">
                                <Alert message={item} type="success" />
                            </div>
                        }) : note.map(item => {
                            return <div className="u-margin-top-small">
                                <Alert message={item} type="success" />
                            </div>
                        })}
                    </div>
                </Row>
                <Row >


                    {add_service_flag && <Row >

                        <Col span={24}>
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                <div className="flex-parent-new">
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            label="Speciality"
                                            value={add_service.common_specaility_id}
                                            disabled={props.get_bids_loading_flag || props.add_service_loading_flag}
                                            options={specialities}
                                            loading={props.global_store.get_specialities_loading || props.add_service_loading_flag}
                                            error_text={error_add_service.common_specaility_id.text}
                                            toggle_error={() => set_error_add_service({ ...error_add_service, common_specaility_id: { text: '', state: false } })}
                                            error={error_add_service.common_specaility_id.state}
                                            name="common_specaility_id"
                                            type="select"
                                            onChange={event => {
                                                set_add_service({ ...add_service, common_specaility_id: event })
                                            }
                                            }
                                            placeholder="Choose speciality" />
                                    </span>
                                    <span className="flex-child-1-new">
                                        <AntdInput
                                            label="Service Family name"
                                            value={add_service.service_family_name}
                                            disabled={props.get_bids_loading_flag || props.add_service_loading_flag}
                                            loading={props.global_store.get_specialities_loading || props.add_service_loading_flag}
                                            error_text={error_add_service.service_family_name.text}
                                            toggle_error={() => set_error_add_service({ ...error_add_service, service_family_name: { text: '', state: false } })}
                                            error={error_add_service.service_family_name.state}
                                            name="service_family_name"
                                            onChange={event => set_add_service({ ...add_service, service_family_name: event.target.value })}
                                            placeholder="Type Service Name" />
                                    </span>
                                </div>
                            </Form>
                        </Col>
                    </Row>}



                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Heading"
                                        value={data.heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.heading.text}
                                        toggle_error={() => set_error({ ...error, heading: { text: '', state: false } })}
                                        error={error.heading.state}
                                        name="heading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, heading: event.target.value })
                                        }}
                                        placeholder="Enter Heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Sub Heading"
                                        value={data.subHeading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.subHeading.text}
                                        toggle_error={() => set_error({ ...error, subHeading: { text: '', state: false } })}
                                        error={error.subHeading.state}
                                        name="subHeading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, subHeading: event.target.value })
                                        }}
                                        placeholder="Enter Subheading" />
                                </span>


                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Definition"
                                        value={data.definition}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.definition.text}
                                        toggle_error={() => set_error({ ...error, definition: { text: '', state: false } })}
                                        error={error.definition.state}
                                        name="definition"
                                        onChange={event => {
                                            console.log(event, "event in Add definition")
                                            set_data({ ...data, definition: event.target.value })
                                        }}
                                        placeholder="Enter Definition" />
                                </span>


                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Duration"
                                        value={data.duration}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.duration.text}
                                        toggle_error={() => set_error({ ...error, duration: { text: '', state: false } })}
                                        error={error.duration.state}
                                        name="duration"
                                        onChange={event => {
                                            console.log(event, "event in Add duration")
                                            set_data({ ...data, duration: event.target.value })
                                        }}
                                        placeholder="Enter Duration" />
                                </span>

                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Discharge"
                                        value={data.discharge}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.discharge.text}
                                        toggle_error={() => set_error({ ...error, discharge: { text: '', state: false } })}
                                        error={error.discharge.state}
                                        name="discharge"
                                        onChange={event => {
                                            console.log(event, "event in discharge")
                                            set_data({ ...data, discharge: event.target.value })
                                        }}
                                        placeholder="Enter Discharge" />
                                </span>


                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="One Liner"
                                        type="textarea"
                                        rows={8}
                                        value={data.onLinerInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.onLinerInfo.text}
                                        toggle_error={() => set_error({ ...error, onLinerInfo: { text: '', state: false } })}
                                        error={error.onLinerInfo.state}
                                        name="onLinerInfo"
                                        onChange={event => {
                                            console.log(event, "event in Onliner")
                                            set_data({ ...data, onLinerInfo: event.target.value })
                                        }}
                                        placeholder="Enter One Liner" />
                                </span>


                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Risk"
                                        type="textarea"
                                        rows={8}
                                        value={data.riskInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.riskInfo.text}
                                        toggle_error={() => set_error({ ...error, riskInfo: { text: '', state: false } })}
                                        error={error.riskInfo.state}
                                        name="riskInfo"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, riskInfo: event.target.value })
                                        }}
                                        placeholder="Enter risk" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Advantages"
                                        type="textarea"
                                        rows={8}
                                        value={data.advantageInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.advantageInfo.text}
                                        toggle_error={() => set_error({ ...error, advantageInfo: { text: '', state: false } })}
                                        error={error.advantageInfo.state}
                                        name="advantageInfo"
                                        onChange={event => {
                                            console.log(event, "event in advantages")
                                            set_data({ ...data, advantageInfo: event.target.value })
                                        }}
                                        placeholder="Enter Advantages" />
                                </span>


                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Causes"
                                        type="textarea"
                                        rows={8}
                                        value={data.causeInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.causeInfo.text}
                                        toggle_error={() => set_error({ ...error, causeInfo: { text: '', state: false } })}
                                        error={error.causeInfo.state}
                                        name="causeInfo"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, causeInfo: event.target.value })
                                        }}
                                        placeholder="Enter Causes" />
                                </span>


                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Symptoms"
                                        type="textarea"
                                        rows={8}
                                        value={data.symptomInfo}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.symptomInfo.text}
                                        toggle_error={() => set_error({ ...error, symptomInfo: { text: '', state: false } })}
                                        error={error.symptomInfo.state}
                                        name="symptomInfo"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, symptomInfo: event.target.value })
                                        }}
                                        placeholder="Enter Symptoms" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Precaution Data"
                                        type="textarea"
                                        rows={8}
                                        value={data.precaution_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.precaution_data.text}
                                        toggle_error={() => set_error({ ...error, precaution_data: { text: '', state: false } })}
                                        error={error.precaution_data.state}
                                        name="precaution_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, precaution_data: event.target.value })
                                        }}
                                        placeholder="Enter Precaution Data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>



                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Process Data"
                                        type="textarea"
                                        rows={8}
                                        value={data.process_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.precaution_data.text}
                                        toggle_error={() => set_error({ ...error, process_data: { text: '', state: false } })}
                                        error={error.process_data.state}
                                        name="process_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, process_data: event.target.value })
                                        }}
                                        placeholder="Enter Process Data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Reason Data"
                                        type="textarea"
                                        rows={8}
                                        value={data.reason_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.reason_data.text}
                                        toggle_error={() => set_error({ ...error, reason_data: { text: '', state: false } })}
                                        error={error.reason_data.state}
                                        name="reason_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, reason_data: event.target.value })
                                        }}
                                        placeholder="Enter Reason Data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Reason heading"
                                        value={data.reason_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.reason_heading.text}
                                        toggle_error={() => set_error({ ...error, reason_heading: { text: '', state: false } })}
                                        error={error.reason_heading.state}
                                        name="reason_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, reason_heading: event.target.value })
                                        }}
                                        placeholder="Enter Reason Heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Symptom heading"
                                        value={data.symptom_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.symptom_heading.text}
                                        toggle_error={() => set_error({ ...error, symptom_heading: { text: '', state: false } })}
                                        error={error.symptom_heading.state}
                                        name="symptom_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, symptom_heading: event.target.value })
                                        }}
                                        placeholder="Enter Symptom Heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Types Heading"

                                        value={data.types_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.types_heading.text}
                                        toggle_error={() => set_error({ ...error, types_heading: { text: '', state: false } })}
                                        error={error.types_heading.state}
                                        name="types_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, types_heading: event.target.value })
                                        }}
                                        placeholder="Enter Types Heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>



                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Types Data"
                                        type="textarea"
                                        rows={8}
                                        value={data.types_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.types_data.text}
                                        toggle_error={() => set_error({ ...error, types_data: { text: '', state: false } })}
                                        error={error.types_data.state}
                                        name="types_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, types_data: event.target.value })
                                        }}
                                        placeholder="Enter Types Data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Treatment Heading"
                                        value={data.treatment_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.treatment_heading.text}
                                        toggle_error={() => set_error({ ...error, treatment_heading: { text: '', state: false } })}
                                        error={error.treatment_heading.state}
                                        name="treatment_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, treatment_heading: event.target.value })
                                        }}
                                        placeholder="Enter treatment heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Risk Heading"
                                        value={data.risk_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.risk_heading.text}
                                        toggle_error={() => set_error({ ...error, risk_heading: { text: '', state: false } })}
                                        error={error.risk_heading.state}
                                        name="risk_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, risk_heading: event.target.value })
                                        }}
                                        placeholder="Enter Risk heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Advantage Heading"
                                        value={data.advantage_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.advantage_heading.text}
                                        toggle_error={() => set_error({ ...error, advantage_heading: { text: '', state: false } })}
                                        error={error.advantage_heading.state}
                                        name="advantage_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, advantage_heading: event.target.value })
                                        }}
                                        placeholder="Enter Advantage heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Cause Heading"
                                        value={data.cause_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.cause_heading.text}
                                        toggle_error={() => set_error({ ...error, cause_heading: { text: '', state: false } })}
                                        error={error.cause_heading.state}
                                        name="cause_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, cause_heading: event.target.value })
                                        }}
                                        placeholder="Enter Cause heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Process Heading"
                                        value={data.process_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.process_heading.text}
                                        toggle_error={() => set_error({ ...error, process_heading: { text: '', state: false } })}
                                        error={error.process_heading.state}
                                        name="process_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, process_heading: event.target.value })
                                        }}
                                        placeholder="Enter Process heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Precaution Heading"
                                        value={data.precaution_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.precaution_heading.text}
                                        toggle_error={() => set_error({ ...error, precaution_heading: { text: '', state: false } })}
                                        error={error.precaution_heading.state}
                                        name="precaution_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, precaution_heading: event.target.value })
                                        }}
                                        placeholder="Enter Precation heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Uses and Needs Heading"
                                        value={data.usesAndNeeds_heading}

                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.usesAndNeeds_heading.text}
                                        toggle_error={() => set_error({ ...error, usesAndNeeds_heading: { text: '', state: false } })}
                                        error={error.usesAndNeeds_heading.state}
                                        name="usesAndNeeds_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, usesAndNeeds_heading: event.target.value })
                                        }}
                                        placeholder="Enter Uses And Nedds heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>



                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Uses and Needs Data"
                                        value={data.usesAndNeeds_data}
                                        type="textarea"
                                        rows={8}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.usesAndNeeds_data.text}
                                        toggle_error={() => set_error({ ...error, usesAndNeeds_data: { text: '', state: false } })}
                                        error={error.usesAndNeeds_data.state}
                                        name="usesAndNeeds_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, usesAndNeeds_data: event.target.value })
                                        }}
                                        placeholder="Enter Uses And Nedds data" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Condition heading"
                                        value={data.condition_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.condition_heading.text}
                                        toggle_error={() => set_error({ ...error, condition_heading: { text: '', state: false } })}
                                        error={error.condition_heading.state}
                                        name="condition_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, condition_heading: event.target.value })
                                        }}
                                        placeholder="Enter condition heading " />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Condition Data"
                                        value={data.condition_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.condition_data.text}
                                        toggle_error={() => set_error({ ...error, condition_data: { text: '', state: false } })}
                                        error={error.condition_data.state}
                                        name="condition_data"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, condition_data: event.target.value })
                                        }}
                                        placeholder="Enter condition Data " />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Stages"
                                        type="textarea"
                                        rows={8}
                                        value={data.stages_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="stages"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, stages_data: event.target.value })
                                        }}
                                        placeholder="Enter stages" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Stages Heading"
                                        rows={8}
                                        value={data.stages_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="stages"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, stages_heading: event.target.value })
                                        }}
                                        placeholder="Enter stages heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Benefits"
                                        type="textarea"
                                        rows={8}
                                        value={data.benefits_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="stages"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, benefits_data: event.target.value })
                                        }}
                                        placeholder="Enter benefits" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Benefits Heading"
                                        rows={8}
                                        value={data.benefits_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="stages"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, benefits_heading: event.target.value })
                                        }}
                                        placeholder="Enter benefits heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Facts"
                                        type="textarea"
                                        rows={8}
                                        value={data.facts_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="facts"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, facts_data: event.target.value })
                                        }}
                                        placeholder="Enter facts" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Facts Heading"
                                        rows={8}
                                        value={data.facts_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="facts"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, facts_heading: event.target.value })
                                        }}
                                        placeholder="Enter facts heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Do's"
                                        type="textarea"
                                        rows={8}
                                        value={data.dos_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="do's"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, dos_data: event.target.value })
                                        }}
                                        placeholder="Enter do's" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Do's Heading"
                                        rows={8}
                                        value={data.dos_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="stages"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, dos_heading: event.target.value })
                                        }}
                                        placeholder="Enter do's heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Dont's"
                                        type="textarea"
                                        rows={8}
                                        value={data.donts_data}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="dont's"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, donts_data: event.target.value })
                                        }}
                                        placeholder="Enter dont's" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Dont's Heading"
                                        rows={8}
                                        value={data.donts_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="dont's heading"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, donts_heading: event.target.value })
                                        }}
                                        placeholder="Enter dont's heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="About service heading"
                                        value={data.aboutService_heading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.aboutService_heading.text}
                                        toggle_error={() => set_error({ ...error, aboutService_heading: { text: '', state: false } })}
                                        error={error.aboutService_heading.state}
                                        name="aboutService_heading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, aboutService_heading: event.target.value })
                                        }}
                                        placeholder="Enter Aboutservice heading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="About service sub heading"
                                        value={data.aboutService_subHeading}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.aboutService_subHeading.text}
                                        toggle_error={() => set_error({ ...error, aboutService_subHeading: { text: '', state: false } })}
                                        error={error.aboutService_subHeading.state}
                                        name="aboutService_subHeading"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, aboutService_subHeading: event.target.value })
                                        }}
                                        placeholder="Enter Aboutservice subheading" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>



                <Row >
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="About service sub heading"
                                        value={data.aboutService_description}
                                        rows={8}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        error_text={error.aboutService_description.text}
                                        toggle_error={() => set_error({ ...error, aboutService_description: { text: '', state: false } })}
                                        error={error.aboutService_description.state}
                                        name="aboutService_description"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, aboutService_description: event.target.value })
                                        }}
                                        placeholder="Enter Aboutservice description" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Big Chains Price with Plunes"
                                        value={data.bigChainPricePlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="aboutService_description"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, bigChainPricePlunes: event.target.value })
                                        }}
                                        placeholder="Enter big chains price with plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Big Chains Price without Plunes"
                                        value={data.bigChainPriceWithoutPlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="aboutService_description"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, bigChainPriceWithoutPlunes: event.target.value })
                                        }}
                                        placeholder="Enter big chains price without plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Multi Speciality Price with Plunes"
                                        value={data.multiSpecialityPricePlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Multi Speciality Price with Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, multiSpecialityPricePlunes: event.target.value })
                                        }}
                                        placeholder="Enter multi speciality price with plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Multi Speciality Price without Plunes"
                                        value={data.multiSpecialityPriceWithoutPlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Multi Speciality Price Without Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, multiSpecialityPriceWithoutPlunes: event.target.value })
                                        }}
                                        placeholder="Enter multi speciality price without plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Regional Brands Price with Plunes"
                                        value={data.regionalBrandsPricePlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Regional Brands With Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, regionalBrandsPricePlunes: event.target.value })
                                        }}
                                        placeholder="Enter regional brands price with plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Regional Brands Price without Plunes"
                                        value={data.regionalBrandsPriceWithoutPlunes}
                                        rows={1}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="Regional Brands Wihtout Plunes"
                                        onChange={event => {
                                            console.log(event, "event in symptoms")
                                            set_data({ ...data, regionalBrandsPriceWithoutPlunes: event.target.value })
                                        }}
                                        placeholder="Enter regional brands price without plunes" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Metadata Title"
                                        value={data.meta_title}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="metaTitle"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, meta_title: event.target.value })
                                        }}
                                        placeholder="Enter MetaData Title" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Metadata Description"
                                        rows={8}
                                        value={data.meta_description}
                                        loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                        disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                        name="metaDesc"
                                        onChange={event => {
                                            console.log(event, "event in Add Service")
                                            set_data({ ...data, meta_description: event.target.value })
                                        }}
                                        placeholder="Enter Metadata Description" />
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>


                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical">
                        <div className="flex-parent-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="Treatment"
                                    type="textarea"
                                    rows={10}
                                    value={data.treatmentInfo}
                                    loading={props.global_store.get_services_loading || props.service_data_handler_loading_flag}
                                    disabled={props.get_bids_loading_flag || props.service_data_handler_loading_flag}
                                    error_text={error.treatmentInfo.text}
                                    toggle_error={() => set_error({ ...error, treatmentInfo: { text: '', state: false } })}
                                    error={error.treatmentInfo.state}
                                    name="treatmentInfo"
                                    onChange={event => {
                                        console.log(event, "event in Add duration")
                                        set_data({ ...data, treatmentInfo: event.target.value })
                                    }}
                                    placeholder="Enter Treatment" />
                            </span>
                        </div>
                        {!add_service_flag && <div className="u-margin-top-small">
                            <div className="flex-parent-1">
                                <span className="flex-child-1">
                                    <Title level={5}>Service Family Image</Title>
                                    <UploadImage id={initial_data.id} type={1} />
                                </span>
                                <span className="flex-child-1">
                                    <Title level={5}>About Image</Title>
                                    <UploadImage id={initial_data.id} type={2} />
                                </span>
                            </div>
                        </div>}
                    </Form>
                    <div className="u-margin-top-small text-center">
                        <Button
                            type="primary"
                            loading={props.service_data_handler_loading_flag}
                            disabled={props.service_data_handler_loading_flag}
                            onClick={() => submit_all()}
                            style={{ width: 'auto', margin: 'auto' }}
                        >
                            Submit
                        </Button>
                    </div>
                </Col>

                {!add_service_flag && <Col span={24}>
                    <Tabs defaultActiveKey={active_tab} onChange={tabs_change}>
                        <TabPane
                            tab={
                                <span>
                                    <AppleOutlined />
                                    FAQs
                                </span>
                            }
                            key="1"
                        >
                            {active_tab == '1' && <EditFaqs
                                get_service_family_byid={props.get_service_family_byid}
                                family_id={initial_data.id}
                                update_faq={props.update_faq}
                                update_faq_loading={props.update_faq_loading}
                                update_faq_ret={props.update_faq_ret}
                                update_faq_loading_flag={props.update_faq_loading_flag}

                                global_error={props.global_error}

                                type="FAQ" typeA="Question" typeB="Answer" data={initial_data.FAQ} />}
                        </TabPane>
                        <TabPane
                            tab={
                                <span>
                                    <AndroidOutlined />
                                    Testimonials
                                </span>
                            }
                            key="2"
                        >
                            {active_tab == '2' && <EditFaqs
                                get_service_family_byid={props.get_service_family_byid}
                                family_id={initial_data.id}
                                update_faq={props.update_faq}
                                update_faq_loading={props.update_faq_loading}
                                update_faq_ret={props.update_faq_ret}
                                update_faq_loading_flag={props.update_faq_loading_flag}

                                global_error={props.global_error}

                                type="testimonial" typeA="User" typeB="Review" data={initial_data.testimonials} />}
                        </TabPane>
                    </Tabs>

                </Col>
                }

            </Card>
        </React.Fragment>)
    } else {
        return <React.Fragment>
            <Card title="Upload Service Data">
                <Row >

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <div className="flex-parent-new">
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Speciality"
                                        value={data.speciality}
                                        disabled={props.get_bids_loading_flag || props.add_service_loading_flag}
                                        options={specialities}
                                        loading={props.global_store.get_specialities_loading || props.add_service_loading_flag}
                                        error_text={error.speciality.text}
                                        toggle_error={() => set_error({ ...error, speciality: { text: '', state: false } })}
                                        error={error.speciality.state}
                                        name="speciality"
                                        type="select"
                                        onChange={event => speciality_change(event)}
                                        placeholder="Choose speciality" />
                                </span>
                                <span className="flex-child-1-new">
                                    <AntdInput
                                        label="Service Family"
                                        value={data.serviceFamily}
                                        options={services}
                                        loading={props.get_service_family_list_by_specialityid_loading_flag}
                                        disabled={props.get_bids_loading_flag}
                                        error_text={error.serviceFamily.text}
                                        toggle_error={() => set_error({ ...error, serviceFamily: { text: '', state: false } })}
                                        error={error.serviceFamily.state}
                                        name="serviceFamily"
                                        type="select"
                                        onChange={event => serviceFamilies_change(event)}
                                        placeholder="Type Service Name" />
                                </span>
                            </div>
                        </Form>
                    </Col>


                    <Title level={5} >Add New Service on Website</Title>

                    <div className="u-margin-top-medium">
                        <Button
                            type="primary"
                            loading={false}
                            disabled={false}
                            onClick={(add_service_button)}
                            style={{ width: 'auto', margin: 'auto' }}
                        >
                            Add New Service
                        </Button>
                    </div>

                </Row>


            </Card>

            <Descriptions column={1} bordered={true} title="Service Family">
                <Descriptions.Item layout="horizontal" label="Heading">{initial_data.heading}</Descriptions.Item>
                <Descriptions.Item layout="horizontal" label="Sub Heading">{initial_data.subHeading}</Descriptions.Item>
                <Descriptions.Item label="Definition">{initial_data.definition}</Descriptions.Item>
                <Descriptions.Item label="Duration">{initial_data.duration}</Descriptions.Item>
                <Descriptions.Item label="Discharge">{initial_data.discharge}</Descriptions.Item>
                <Descriptions.Item label="Treatment">{initial_data.treatmentInfo}</Descriptions.Item>
                <Descriptions.Item label="Risk">{initial_data.riskInfo}</Descriptions.Item>
                <Descriptions.Item label="Advantages">{initial_data.advantageInfo}</Descriptions.Item>
                <Descriptions.Item label="Symptom">{initial_data.symptomInfo}</Descriptions.Item>
                <Descriptions.Item label="On Liner">{initial_data.onLinerInfo}</Descriptions.Item>
            </Descriptions>

            <div className="u-margin-top-small">
                <Button
                    type="primary"
                    onClick={() => edit_cllck()}
                    icon={<EditOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >Edit</Button>
            </div>
        </React.Fragment>
    }
}
export default ServiceDataHandler