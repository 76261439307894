import React from "react";
import { Table, Input, Button, Space, Card, Form, Modal, Col, Row } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import {
  for_loop,
  objectEquals,
  getDateFormat,
  getTime,
  error_form_check,
} from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
// import Map from "./Map.js"

const SolProfessionals = (props) => {
  const { form } = Form.useForm();
  const [state, set_state] = React.useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: "",
    searchedColumn: "",
  });
  const [
    get_sol_professionals_params,
    set_get_sol_professionals_params,
  ] = React.useState({
    page: 1,
    limit: 10,
    solutionId: props.solutionId,
    total: 10,
    searchParams: {},
    filters: {},
  });

  const [professionals_amounts, set_professionals_amounts] = React.useState([]);
  const [professionalsPrice, setProfessionalsPrice] = React.useState([]);
  const [isPirceAll, setIsPirceAll] = React.useState([]);
  const [
    professionals_amounts_error,
    set_professionals_amounts_error
  ] = React.useState([]);
  const [
    professionalsPriceError,
    setProfessionalsPriceError
  ] = React.useState([]);
  const [data, set_data] = React.useState([]);
  const [edit_modal, set_edit_modal] = React.useState(false);
  const [selected_row, set_selected_row] = React.useState({});
  const [expandedRowKeys, set_expandedRowKeys] = React.useState([]);
  const [visible, set_visible] = React.useState(false);
  const [up_data, set_up_data] = React.useState({
    price: "",
    amount: "",
    sp_offer: "",
    add_on: "",
    tech: "",
  });
  const [error, set_error] = React.useState({
    price: { state: false, text: "" },
    amount: { state: false, text: "" },
    sp_offer: { state: false, text: "" },
    add_on: { state: false, text: "" },
    tech: { state: false, text: "" },
  });

  const [edit_price, set_edit_price] = React.useState({ amount: "" });
  const [error_edit_price, set_error_edit_price] = React.useState({
    amount: { state: false, text: "" },
  });

  const handleCancel = (e) => {
    set_visible(false);
    set_up_data({ price: "" ,amount: "", sp_offer: "", add_on: "", tech: "" });
  };

  const handleCancel_edit_price = (e) => {
    set_edit_modal(false);
    set_edit_price({ amount: "" });
  };

  React.useEffect(() => {
    props.get_sol_professionals(get_sol_professionals_params);
  }, []);

  React.useEffect(() => {
    if (!!props.get_sol_professionals_ret) {
      // console.log(props.get_sol_professionals_ret,"get_professional_ret")
      // set_bids(modif_data(props.bids))
      if (props.get_sol_professionals_ret.success) {
        set_data(modif_data([...props.get_sol_professionals_ret.data]));
        set_get_sol_professionals_params({
          ...get_sol_professionals_params,
          total: props.get_sol_professionals_ret.total,
        });
        let arr = [];
        let arrPrice = [];
        let arrisPrice = [];
        props.get_sol_professionals_ret.data.map((item) => {
          arr[item.id] = item.newPrice;
          arrPrice[item.id] = item.price;
          arrisPrice[item.id] = item.isPrice;
        });
        let arr_error = {};
        let arr_errorPrice = {};
        props.get_sol_professionals_ret.data.map((item) => {
          arr_error[item.id] = { state: false, text: "" };
          arr_errorPrice[item.id] = { state: false, text: "" };
        });

        set_professionals_amounts_error(arr_error);
        set_professionals_amounts(arr);
        setProfessionalsPriceError(arr_errorPrice);
        setProfessionalsPrice(arrPrice);
        setIsPirceAll(arrisPrice);

      } else {
        props.global_error({
          success: false,
          message: props.get_sol_professionals_ret.message,
          heading: "Professionals",
        });
      }
      props.get_sol_professionals_loading();
    }

    if (!!props.update_sol_professional_ret) {
      // console.log(props.get_insights_ret,"get_professional_ret")
      // set_bids(modif_data(props.bids))
      if (props.update_sol_professional_ret.success) {
        props.global_error({
          success: true,
          message: props.update_sol_professional_ret.message,
          heading: "Update solution",
        });
        props.get_sol_professionals(get_sol_professionals_params);
        handleCancel();
        handleCancel_edit_price();
      } else {
        props.global_error({
          success: false,
          message: props.update_sol_professional_ret.message,
          heading: "Update solution",
        });
      }
      props.update_sol_professional_loading();
    }
  }, [props.get_sol_professionals_ret, props.update_sol_professional_ret]);

  const handleChange = (pagination, filters, sorter) => {
    // console.log(pagination, "pagination filter sorter")
    let state_filters = { ...get_sol_professionals_params.filters };
    let updated_filters = {};

    updated_filters.level = filters.level;
    updated_filters.catalogue = filters.catalogue;
    updated_filters.userType = filters.userType;
    if (
      !!(
        pagination.current === get_sol_professionals_params.page &&
        pagination.pageSize === get_sol_professionals_params.limit
      )
    ) {
      if (objectEquals(state_filters, updated_filters)) {
        console.log("Objects are equal");
      } else {
        // console.log(state_filters, updated_filters,"state_filters, updated_filters")
        console.log("Objects are not equal , call filter operation");
        set_get_sol_professionals_params({
          ...get_sol_professionals_params,
          page: 1,
          filters: {
            //    userType: filters.userType? filters.userType:'',
            //    level:filters.level?filters.level:'',
            //    catalogue:filters.catalogue?filters.catalogue:''
          },
        });
        props.get_sol_professionals({
          ...get_sol_professionals_params,
          page: 1,
          filters: {
            //    userType: filters.userType? filters.userType:'',
            //    level:filters.level?filters.level:'',
            //    catalogue:filters.catalogue?filters.catalogue:''
          },
        });
      }
    }
  };

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};

  const getColumnSearchProps = (dataIndex, placeholder, searchProp, type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, searchProp)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, searchProp)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handle_reset(clearFilters, data, dataIndex, searchProp)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
          title={text}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
    confirm();
    set_get_sol_professionals_params({
      ...get_sol_professionals_params,
      page: 1,
      searchParams: {
        ...get_sol_professionals_params.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    props.get_sol_professionals({
      ...get_sol_professionals_params,
      page: 1,
      searchParams: {
        ...get_sol_professionals_params.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    set_state({
      ...state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    set_state({ searchText: "" });
  };

  const modif_data = (arr) => {
    let prof_list = [];
    for_loop(arr, (item) => {
      let obj = {
        distance: Math.floor(item.distance),
        negotiate: item.negotiate,
        professionalId: item.professionalId,
        newPrice: item.newPrice === 0 || item.newPrice === -1 ? "N/A" : item.newPrice,
        // discount:item.discount.toFixed(2),
        price: item.price === 0 || item.price === -1 ? "N/A" : item.price,
        isPrice: item.isPrice,
        professional: item.professional,
        type: item.suggested ? "Suggested" : "Original",
        userType: item.userType,
        id: item.id,
        negotiate: item.negotiate ? "Yes" : "No",
        priceUpdated: item.priceUpdated,
        addOn: item.addOn ? item.addOn : "N/A",
        specialOffer: item.specialOffer ? item.specialOffer : "N/A",
        technique: item.technique ? item.technique : "N/A",
      };
      prof_list.push({
        ...obj,
      });
    });

    return prof_list;
  };

  const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
    set_get_sol_professionals_params({
      ...get_sol_professionals_params,
      limit: b,
      page: 1,
    });
    props.get_sol_professionals({
      ...get_sol_professionals_params,
      limit: b,
      page: 1,
    });
  };

  const page_change = (a, b) => {
    // console.log(a,b,get_sol_professionals_params,"a and b get_sol_professionals_params")
    // if(get_sol_professionals_params.page !== a){
    if (true) {
      set_get_sol_professionals_params({
        ...get_sol_professionals_params,
        page: a,
        limit: b,
      });
      props.get_sol_professionals({
        ...get_sol_professionals_params,
        page: a,
        limit: b,
      });
    }
  };

  const handle_reset = (a, b, c) => {
    handleReset(a);
    set_state({ filteredInfo: null });
    set_get_sol_professionals_params({
      ...get_sol_professionals_params,
      page: 1,
      searchParams: {
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters: {
        // userType:"",
        // level:"",
        // catalogue:''
      },
    });
    props.get_sol_professionals({
      ...get_sol_professionals_params,
      page: 1,
      searchParams: {
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters: {
        // userType:"",
        // level:"",
        // catalogue:''
      },
    });
  };
  const clear_filters = () => {
    // handleReset()
    set_state({ filteredInfo: null });
    // clearFilters()
    set_state({ searchText: "" });
    set_get_sol_professionals_params({
      ...get_sol_professionals_params,
      page: 1,
      filters: {
        // userType:"",
        // catalogue:'',
        // level:''
      },
    });
    props.get_sol_professionals({
      ...get_sol_professionals_params,
      page: 1,
      filters: {
        // userType:"",
        // catalogue:'',
        // level:''
      },
    });
  };

  const expand = (record, a, b) => {
    console.log(record, a, b, "record,a,b");
    if (expandedRowKeys[0] !== a._id) {
      set_expandedRowKeys([a._id]);
    } else {
      set_expandedRowKeys([]);
    }
  };

  const columns = [
    {
      title: "Professional",
      dataIndex: "professional",
      key: "professional",
      width: "10%",
      // ...getColumnSearchProps('name', "Search name", 'name'),
    },
    {
      title: (record) => {
        return (
          <React.Fragment>
            <div>
              Price
              <EditOutlined onClick={edit_price_open} />
            </div>
          </React.Fragment>
        );
      },
      dataIndex: "price",
      key: "price",
      width: "10%",
      // ...getColumnSearchProps('email', "Search email", 'email'),
    },
    {
      title: (record) => {
        console.log(record, "record in Title of new price");
        return (
          <React.Fragment>
            <div>
              New Price
              <EditOutlined onClick={edit_price_open} />
            </div>
          </React.Fragment>
        );
      },
      dataIndex: "newPrice",
      key: "newPrice",
      width: "10%",
      // ...getColumnSearchProps('email', "Search email", 'email'),
    },
    // {
    //   title: 'Discount',
    //   dataIndex: 'discount',
    //   key: 'discount',
    //   width: '9%'
    // },
    // {
    //   title: "Negotiate",
    //   dataIndex: "negotiate",
    //   key: "negotiate",
    //   width: "10%",
    //   // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
    // },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      width: "10%",
      // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
    },
    // {
    //   title: 'User Type',
    //   dataIndex: 'userType',
    //   key: 'userType',
    //   width:'10%'
    //   // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   width: "10%",
    //   // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
    // },
    {
      title: "Addon",
      dataIndex: "addOn",
      key: "addOn",
      width: "10%",
      // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
    },
    {
      title: "Special Offer",
      dataIndex: "specialOffer",
      key: "specialOffer",
      width: "10%",
      // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
    },
    {
      title: "Technique",
      dataIndex: "technique",
      key: "technique",
      width: "10%",
      // ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => update_click(record)}>Update Price</a>
        </Space>
      ),
    },
  ];
  function showTotal(total) {
    return `Total ${total} items`;
  }

  const update_click = (data) => {
    set_selected_row(data);
    set_visible(true);
  };

  const validate = (data) => {
    console.log("data", data);
    const error = {
      price: { state: false, text: "" },
      amount: { state: false, text: "" },
      sp_offer: { state: false, text: "" },
      add_on: { state: false, text: "" },
      tech: { state: false, text: "" },
    };
    if (data.amount === "") {
      {
        error.amount.text = "Please enter amount";
        error.amount.state = true;
      }
    }
    if (data.price === "") {
      {
        error.price.text = "Please enter price";
        error.price.state = true;
      }
    }
    if (isNaN(data.amount)) {
      error.amount.text = "Please enter a valid amount";
      error.amount.state = true;      
    }
    if (isNaN(data.price)) {
      {
        error.price.text = "Please enter a valid price";
        error.price.state = true;
      }
    }
    // if (data.tech === '') {
    //   {
    //     error.tech.text = 'Please enter technology';
    //     error.tech.state = true;
    //   }
    // }
    return error;
  };

  const submit = () => {
    console.log("in SUBMIT_UPDATE_PRICE:", up_data);
    const ret_error = validate({ ...up_data });
    set_error({ ...error, ...ret_error });
    console.log(ret_error, "ret_error in add_member");
    if (!error_form_check(ret_error)) {
      props.update_sol_professional({
        solutionId: props.solutionId,
        id: selected_row.id,
        updatedPrice: up_data.amount,
        technology: up_data.tech,
        add_on: up_data.add_on,
        special_offer: up_data.sp_offer,
        price: up_data.price,
        isPrice: selected_row.isPrice
      });
    }
  };

  const validate_edit_price = () => {
    console.log("data", data);
    let error = {};
    data.map((item) => {
      error[item.id] = { state: false, text: "" };
    });

    Object.keys(professionals_amounts).map(function (item, index) {
      console.log(item, "item in validate_edit_price");
      if (professionals_amounts[item] < 1 || isNaN(professionals_amounts[item])) {
        error[item] = { state: true, text: "Invalid Amount" };
      }
    });

    return error;
  };

  const validate_MRP_price = () => {
    console.log("data", data);
    let error = {};
    data.map((item) => {
      error[item.id] = { state: false, text: "" };
    });

    Object.keys(professionalsPrice).map(function (item, index) {
      console.log(item, "item in validate_MRP_price");
      if (professionalsPrice[item] < 1 || isNaN(professionalsPrice[item])) {
        error[item] = { state: true, text: "Invalid Price" };
      }
    });

    return error;
  };

  const submit_edit_price = () => {
    const ret_error = validate_edit_price();
    set_professionals_amounts_error({
      ...professionals_amounts_error,
      ...ret_error,
    });

    const ret_errorMRP = validate_MRP_price();
    setProfessionalsPriceError({
      ...professionalsPriceError,
      ...ret_errorMRP,
    });

    let flag = false;
    Object.keys(ret_error).map(function (item, index) {
      if (ret_error[item].state) {
        flag = true;
      }
    });

    Object.keys(ret_errorMRP).map(function (item, index) {
      if (ret_errorMRP[item].state) {
        flag = true;
      }
    });

    if (!flag) {
      let professionals = [];
      Object.keys(professionals_amounts).map(function (item, index) {
        professionals.push({
          id: item,
          updatedPrice: professionals_amounts[item],
          price: professionalsPrice[item],
          isPrice: isPirceAll[item]
        });
      });
      console.log(
        professionals,
        "professionals array inside submit_edit_price"
      );
      props.update_sol_professional({
        solutionId: props.solutionId,
        professionals: professionals,
        type: "update_all",
        // updatedPrice:edit_price.amount,
        // updatedPrice:amount
      });

      props.handleAllPricesUpdated(props.solutionId);
    }
  };

  const edit_price_open = () => {
    set_edit_modal(true);
  };

  const get_label = (key) => {
    let label = "";
    data.map((item) => {
      if (item.id === key) {
        label = `${item.professional} (Original Price - ${item.price}) (Original New Price - ${item.newPrice})`;
      }
    });
    return label;
  };

  console.log(professionals_amounts, "professionals_amount");
  console.log(professionalsPrice, "professionalPrice");

  return (
    <React.Fragment>
      <Card title="Professionals">
        {/* <Form
            form={form}
            layout="vertical"
            >
        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput 
              label="Speciality"  
              value={speciality} 
              disabled = {props.get_bids_loading_flag}
              options = {props.global_store.specialities}
              loading={props.global_store.get_specialities_loading}
              error_text={""} 
              toggle_error = {()=>console.log()} 
              error={false}
              name="speciality" 
              type="select"
              mode="multiple"
              onChange = {event=>{
                console.log(event)
                let specialityId = ""
                event.map((item,i)=>{
                  if(i!==0){
                    specialityId = `${specialityId},${item}`
                  }else{
                    specialityId = `${specialityId}${item}`
                  }
                  
                })
                set_get_sol_professionals_params({...get_sol_professionals_params, specialityId:specialityId, page:1})
                props.get_sol_professionals({...get_sol_professionals_params, specialityId:specialityId, page:1})
                set_speciality(event)
              }} 
             
              placeholder="Choose speciality" />
          </span>
        </div>
      </Form> */}
        <div key={props.solutionId}>
          <Table
            columns={columns}
            dataSource={data}
            loading={props.get_sol_professionals_loading_flag}
            onChange={handleChange}
            // rowClassName="cursor-pointer"
            rowClassName={(record, index) => {
              if (props.category === "Procedure") {
                if (record.priceUpdated) {
                  return "green_row";
                } else {
                  return "red_row";
                }
              } else {
                // console.log(record.priceUpdated, "Price is updated");
                // if (record.priceUpdated) {
                //   return "green_row";
                // }
                // else {
                  return "";
                // }
              }
            }}
            pagination={{
              showSizeChanger: true,
              limit: get_sol_professionals_params.limit,
              onChange: (a, c) => page_change(a, c),
              // onShowSizeChange:(a,b)=>change_limit(a,b),
              defaultCurrent: get_sol_professionals_params.page,
              total: get_sol_professionals_params.total,
              showTotal: showTotal,
            }}
          />
          <Modal
            title="Update Price"
            visible={edit_modal}
            onOk={submit_edit_price}
            onCancel={handleCancel_edit_price}
            width={1000}
          >
            <Row gutter={[16,0]} style={{marginBottom: "2rem", fontWeight: "900"}}>
              <Col span={16}>
                Professional
              </Col>
              <Col span={4}>
                Price
              </Col>
              <Col span={4}>
                New Price
              </Col>
            </Row>
            <div>
              {Object.keys(professionals_amounts).map((item, index) => 
                  <Row gutter={[16,0]}>
                    <Col span={16}>
                      {get_label(item)}
                    </Col>
                     <Col span={4}>
                      <AntdInput
                        value={[professionalsPrice[item]]}
                        error_text={professionalsPriceError[item].text}
                        loading={props.check_number_loading_flag}
                        disabled={props.check_number_loading_flag}
                        toggle_error={() =>
                          setProfessionalsPriceError({
                            ...professionalsPriceError,
                            [item]: { text: "", state: false },
                          })
                        }
                        error={professionalsPriceError[item].state}
                        name={item}
                        onChange={(event) => {
                          setProfessionalsPrice({
                            ...professionalsPrice,
                            [event.target.name]: event.target.value,
                          })}
                        }
                        placeholder="Enter Price"
      
                        key={index}
                      />
                    </Col>
                    <Col span={4}>
                      <AntdInput
                        value={[professionals_amounts[item]]}
                        error_text={professionals_amounts_error[item].text}
                        loading={props.check_number_loading_flag}
                        disabled={props.check_number_loading_flag}
                        toggle_error={() =>
                          set_professionals_amounts_error({
                            ...professionals_amounts_error,
                            [item]: { text: "", state: false },
                          })
                        }
                        error={professionals_amounts_error[item].state}
                        name={item}
                        onChange={(event) =>{
                          set_professionals_amounts({
                            ...professionals_amounts,
                            [event.target.name]: event.target.value,
                          })}
                        }
                        placeholder="Enter amount"
      
                        key={item}
                      />
                    </Col>
                  </Row>
              )}
            </div>
          </Modal>

          <Modal
            title="Update Price"
            visible={visible}
            onOk={submit}
            onCancel={handleCancel}
          >
            <div>
              <AntdInput
                label="Enter Price"
                className="alignment"
                value={up_data.price}
                error_text={error.price.text}
                loading={props.check_number_loading_flag}
                disabled={props.check_number_loading_flag}
                toggle_error={() =>
                  set_error({ ...error, price: { text: "", state: false } })
                }
                error={error.price.state}
                name="price"
                onChange={(event) =>
                  set_up_data({
                    ...up_data,
                    [event.target.name]: event.target.value,
                  })
                }
                placeholder="Enter amount"
              />
              <AntdInput
                label="Enter new price"
                className="alignment"
                value={up_data.amount}
                error_text={error.amount.text}
                loading={props.check_number_loading_flag}
                disabled={props.check_number_loading_flag}
                toggle_error={() =>
                  set_error({ ...error, amount: { text: "", state: false } })
                }
                error={error.amount.state}
                name="amount"
                onChange={(event) =>
                  set_up_data({
                    ...up_data,
                    [event.target.name]: event.target.value,
                  })
                }
                placeholder="Enter amount"
              />
              {props.category === "Procedure" && (
                <React.Fragment>
                  {" "}
                  <AntdInput
                    label="Technology/Technique :"
                    className="alignment"
                    value={up_data.tech}
                    error_text={error.tech.text}
                    loading={props.check_number_loading_flag}
                    disabled={props.check_number_loading_flag}
                    toggle_error={() =>
                      set_error({ ...error, tech: { text: "", state: false } })
                    }
                    error={error.tech.state}
                    name="tech"
                    onChange={(event) =>
                      set_up_data({
                        ...up_data,
                        [event.target.name]: event.target.value,
                      })
                    }
                    placeholder="Enter technology"
                  />
                  <AntdInput
                    label="Add on's :"
                    className="alignment"
                    value={up_data.add_on}
                    error_text={error.add_on.text}
                    loading={props.check_number_loading_flag}
                    disabled={props.check_number_loading_flag}
                    toggle_error={() =>
                      set_error({
                        ...error,
                        add_on: { text: "", state: false },
                      })
                    }
                    error={error.add_on.state}
                    name="add_on"
                    onChange={(event) =>
                      set_up_data({
                        ...up_data,
                        [event.target.name]: event.target.value,
                      })
                    }
                    placeholder="Enter add on"
                  />{" "}
                </React.Fragment>
              )}

              <AntdInput
                label="special offers :"
                className="alignment"
                value={up_data.sp_offer}
                error_text={error.sp_offer.text}
                loading={props.check_number_loading_flag}
                disabled={props.check_number_loading_flag}
                toggle_error={() =>
                  set_error({ ...error, sp_offer: { text: "", state: false } })
                }
                error={error.sp_offer.state}
                name="sp_offer"
                onChange={(event) =>
                  set_up_data({
                    ...up_data,
                    [event.target.name]: event.target.value,
                  })
                }
                placeholder="Enter special offers"
              />
            </div>
          </Modal>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default SolProfessionals;
