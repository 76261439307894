import React from "react";
import {
  Button,
  Card,
  Menu,
  Form,
  DatePicker,
  Row,
  Col,
  Descriptions,
  Tag,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import {
  error_form_check,
  isEmpty,
  get_month,
} from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
//import Servicelist from "./Servicelist"
import SearchLocation from "../../Components/SearchLocation";
import { Link } from "react-router-dom";
import { set_services } from "../../actions/global";
import validator from "validator";
import moment from "moment";

import User from "./User";
import { CheckCircleOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

const options = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Other", value: "O" },
];

const Booking = (props) => {
  const [state, set_state] = React.useState({
    check_status: "PENDING",
  });
  const [confirm_booking, set_confirm_booking] = React.useState(false);
  const [data, set_data] = React.useState({
    speciality: "",
    service: "",
    doctor: "",
    doctors: [],
    date: "",
    date_string: "",
    amount: "",
    slots: [],
    timeSlots: [],
    slot: "",
    max_dicount: 0,
    applied: false,
    timestamp: "",
  });
  const [error, set_error] = React.useState({
    speciality: { state: false, text: "" },
    service: { state: false, text: "" },
    doctor: { state: false, text: "" },
    amount: { state: false, text: "" },
    date_string: { state: false, text: "" },
    slot: { state: false, text: "" },
  });
  const [initial_data, set_initial_data] = React.useState({});
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (props.user) {
      set_initial_data({
        ...props.user.data,
      });
    }
  }, []);

  const check_professional = (id) => {
    let professional = [...data.doctors].filter((item) => {
      if (item._id === id) {
        if (item.isHospitalDoctor) {
          return true;
        }
        return false;
      }
      return false;
    });

    if (!!professional[0]) {
      let item = professional[0];
      return {
        hospitalId: item.hospitalId,
        doctors: item.doctors,
        isHospitalDoctor: item.isHospitalDoctor,
      };
    }

    return false;
  };

  React.useEffect(() => {
    if (props.get_doctors_ret) {
      if (props.get_doctors_ret.success) {
        set_data({
          ...data,
          doctors: [...props.get_doctors_ret.data].map((item) => {
            return {
              ...item,
              name: item.name,
              value: item._id,
              hospitalId: item.hospitalId,
              doctors: item.doctors,
              isHospitalDoctor: item.isHospitalDoctor,
            };
          }),
        });
      } else {
        props.global_error({
          success: false,
          message: props.get_doctors_ret.message,
          heading: "No Doctor Available",
        });
      }
      props.get_doctors_loading();
    }

    if (props.make_booking_ret) {
      if (props.make_booking_ret.success) {
        props.global_error({
          success: true,
          message: props.make_booking_ret.message,
          heading: "Booking",
        });
        set_state({
          check_status: "DONE",
        });
      } else {
        props.global_error({
          success: false,
          message: props.make_booking_ret.message,
          heading: "Booking",
        });
      }
      props.make_booking_loading();
    }
  }, [props.get_doctors_ret, props.make_booking_ret]);

  const speciality_change = (val) => {
    props.get_services({ speciality: val });
    set_data({
      ...data,
      applied: false,
      timeSlots: [],
      doctor: "",
      slot: "",
      slots: [],
      date_string: "",
      amount: "",
      max_dicount: 0,
      doctors: [],
      date: "",
      speciality: val,
      service: "",
    });
  };

  const select_doctor = (id) => {
    console.log(id, "id");
    let doctor = data.doctors.filter((item) => item.value === id)[0];
    console.log(doctor, "doctor in time slot");
    set_data({
      ...data,
      timeSlots: [...doctor.timeSlots],
      doctor: id,
      amount: doctor.specialities.services?.price[0],
      max_dicount: doctor.specialities.services.variance,
      slot: "",
      slots: [],
      date_string: "",
      date: "",
      applied: false,
    });
  };

  const max_dicount_click = () => {
    if (!data.applied) {
      set_data({
        ...data,
        amount: Math.floor(
          data.amount - data.amount * (data.max_dicount / 100)
        ),
        applied: true,
        // max_dicount:0
      });
    }
  };

  const validate = (data) => {
    const error = {
      speciality: { state: false, text: "" },
      service: { state: false, text: "" },
      doctor: { state: false, text: "" },
      amount: { state: false, text: "" },
      date_string: { state: false, text: "" },
      slot: { state: false, text: "" },
    };
    if (data.speciality === "") {
      {
        error.speciality.text = "Select speciality";
        error.speciality.state = true;
      }
    }
    if (data.service === "") {
      {
        error.service.text = "Select service";
        error.service.state = true;
      }
    }
    if (data.doctor === "") {
      {
        error.doctor.text = "Select Doctor";
        error.doctor.state = true;
      }
    }
    if (data.date_string === "") {
      {
        error.date_string.text = "Select date";
        error.date_string.state = true;
      }

      props.global_error({
        success: false,
        message: "Select appointment date",
        heading: "Date",
      });
    }
    if (data.slot === "") {
      {
        error.slot.text = "Select slot";
        error.slot.state = true;
      }
    }
    if (data.amount === "") {
      {
        error.amount.text = "Enter amount";
        error.amount.state = true;
      }
    }
    return error;
  };
  const submit = () => {
    const ret_error = validate({ ...data });
    set_error({ ...error, ...ret_error });
    console.log(ret_error, "ret_error in add_member");
    if (!error_form_check(ret_error)) {
      set_confirm_booking(true);
    }
  };

  const make_booking = () => {
    if (!!check_professional(data.doctor)) {
      props.make_booking({
        serviceId: data.service,
        paymentPercent: 100,
        timeSlot: data.slot,
        date: data.date_string,
        professionalId: data.doctor,
        userId: initial_data._id,
        creditsUsed: false,
        coupon: "",
        bookIn: null,
        doctorId: null,
        bookingStatus: "Requested",
        price: data.amount,
        ...check_professional(data.doctor),
      });
    } else {
      props.make_booking({
        serviceId: data.service,
        paymentPercent: 100,
        timeSlot: data.slot,
        date: data.date_string,
        professionalId: data.doctor,
        userId: initial_data._id,
        creditsUsed: false,
        coupon: "",
        bookIn: null,
        doctorId: null,
        bookingStatus: "Requested",
        price: data.amount,
      });
    }
  };

  const stringToTime = (str) => {
    let arr = str.split("-");
    let fromMinute = arr[0].split(" ")[0].split(":")[1];
    let fromHour = arr[0].split(" ")[0].split(":")[0];
    let fromAmpm = arr[0].split(" ")[1];
    let toMinutes = arr[1].split(" ")[0].split(":")[1];
    let toHour = arr[1].split(" ")[0].split(":")[0];
    let toAmPm = arr[1].split(" ")[1];
    console.log(fromHour === "12", "fromHour");
    let obj = {
      from: {
        hour:
          fromAmpm === "PM"
            ? fromHour === "12"
              ? 12
              : 12 + parseInt(fromHour, 10)
            : fromHour === "12"
            ? 0
            : parseInt(fromHour, 10),
        minutes: parseInt(fromMinute, 10),
        timestamp: new Date(
          2020,
          1,
          1,
          fromAmpm === "PM"
            ? fromHour === "12"
              ? 12
              : 12 + parseInt(fromHour, 10)
            : fromHour === "12"
            ? 0
            : parseInt(fromHour, 10),
          parseInt(fromMinute, 10),
          0,
          0
        ).getTime()
          ? new Date(
              2020,
              1,
              1,
              fromAmpm === "PM"
                ? fromHour === "12"
                  ? 12
                  : 12 + parseInt(fromHour, 10)
                : fromHour === "12"
                ? 0
                : parseInt(fromHour, 10),
              parseInt(fromMinute, 10),
              0,
              0
            ).getTime()
          : 0,
      },
      to: {
        hour:
          toAmPm === "PM"
            ? toHour === "12"
              ? 12
              : 12 + parseInt(toHour, 10)
            : toHour === "12"
            ? 0
            : parseInt(toHour, 10),
        minutes: parseInt(toMinutes, 10),
        timestamp: new Date(
          2020,
          1,
          1,
          toAmPm === "PM"
            ? toHour === "12"
              ? 12
              : 12 + parseInt(toHour, 10)
            : toHour === "12"
            ? 0
            : parseInt(toHour, 10),
          parseInt(toMinutes, 10),
          0,
          0
        ).getTime()
          ? new Date(
              2020,
              1,
              1,
              toAmPm === "PM"
                ? toHour === "12"
                  ? 12
                  : 12 + parseInt(toHour, 10)
                : toHour === "12"
                ? 0
                : parseInt(toHour, 10),
              parseInt(toMinutes, 10),
              0,
              0
            ).getTime()
          : 0,
      },
    };
    return obj;
  };

  console.log(initial_data, "initial_data in Add User");
  console.log(data, "data in Bookings");

  console.log(props.check_number_ret, "props.check_number_ret");
  const onChange = (e) => {
    const { value } = e.target;

    const reg = /^-?\d*(\.\d*)?$/;
    if (value.length < 11) {
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        set_data({
          ...data,
          number: value,
        });
        set_state({
          ...state,
          check_status: "PENDING",
        });
        if (value.length > 9) {
          let flag = validator.isMobilePhone(value, "en-IN");
          if (!flag) {
            set_error({
              ...error,
              number: { state: true, text: "Enter a valid mobile number" },
            });
            set_state({
              ...state,
              check_status: "PENDING",
            });
          } else {
            set_data({
              ...data,
              number: value,
            });
          }
        }
      }
    } else {
      if (value.length > 10) {
        if (!validator.isMobilePhone(data.number, "en-IN")) {
          set_error({
            ...error,
            number: { state: true, text: "Enter a valid mobile number" },
          });
          set_state({
            ...state,
            check_status: "PENDING",
          });
        }
      }
    }
  };

  console.log(
    props.global_store.specialities,
    "props.global_store.specialities"
  );

  if (isEmpty(props.user)) {
    return <Redirect to="/dashboard/bookings?add-user" />;
  }

  if (confirm_booking) {
    return (
      <React.Fragment>
        <Card title="User Booking">
          <Row>
            <Col span={10}>
              <Descriptions
                title="User info"
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Mobile no">
                  {initial_data.mobileNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Name">
                  {initial_data.name}
                </Descriptions.Item>
                <Descriptions.Item label="Address">
                  {initial_data.address}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {initial_data.email}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">
                  {" "}
                  {initial_data.gender === "M"
                    ? "Male"
                    : initial_data.gender === "F"
                    ? "Female"
                    : "Other"}
                </Descriptions.Item>
                <Descriptions.Item label="DOB(date of birth)">
                  {initial_data.birthDate}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={14}>
              <Descriptions
                title="Booking info"
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Doctor">
                  {
                    data.doctors.filter((item) => item.value === data.doctor)[0]
                      .name
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Speciality">
                  {
                    props.global_store.specialities.filter(
                      (item) => item.value === data.speciality
                    )[0].name
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Service">
                  {
                    props.global_store.services.filter(
                      (item) => item.value === data.service
                    )[0].name
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Appointment Date">
                  {data.date_string}
                </Descriptions.Item>
                <Descriptions.Item label="Slot"> {data.slot}</Descriptions.Item>
                <Descriptions.Item label="Amount">
                  {data.amount}
                </Descriptions.Item>
              </Descriptions>

              {state.check_status === "DONE" ? (
                <div className="flex-parent-new text-center u-margin-top-small">
                  <span className="flex-child-1-new text-center">
                    <Link to="/dashboard/bookings?previous-bookings">
                      <Button
                        type="primary"
                        onClick={() => console.log()}
                        style={{ width: "auto", margin: "auto" }}
                      >
                        Bookings
                      </Button>
                    </Link>
                  </span>
                  <span className="flex-child-1-new text-center">
                    <Button
                      type="primary"
                      onClick={() =>
                        props.update_bookings({
                          user_id: initial_data._id,
                          type: "send_credentials",
                        })
                      }
                      style={{ width: "auto", margin: "auto" }}
                    >
                      Send Credentials
                    </Button>
                  </span>
                </div>
              ) : (
                <div className="flex-parent-new text-center u-margin-top-small">
                  <span className="flex-child-1-new ">
                    <Button
                      type="primary"
                      onClick={() => set_confirm_booking(false)}
                      style={{ width: "auto", margin: "auto" }}
                    >
                      Edit
                    </Button>
                  </span>
                  <span className="flex-child-1-new">
                    <Button
                      type="primary"
                      onClick={() => make_booking()}
                      style={{ width: "auto", margin: "auto" }}
                    >
                      Confirm Booking
                    </Button>
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Card title="User Booking">
        <Row>
          <Col span={10}>
            <Descriptions
              title="User info"
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Mobile no">
                {initial_data.mobileNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {initial_data.name}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {initial_data.address}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {initial_data.email}
              </Descriptions.Item>
              <Descriptions.Item label="Gender">
                {" "}
                {initial_data.gender === "M"
                  ? "Male"
                  : initial_data.gender === "F"
                  ? "Female"
                  : "Other"}
              </Descriptions.Item>
              <Descriptions.Item label="DOB(date of birth)">
                {initial_data.birthDate}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={14}>
            <Form form={form} layout="vertical">
              <div className="flex-parent-new">
                <span className="flex-child-1-new">
                  <AntdInput
                    label="Speciality"
                    value={data.speciality}
                    disabled={props.get_bids_loading_flag}
                    options={props.global_store.specialities}
                    loading={props.global_store.get_specialities_loading}
                    error_text={error.speciality.text}
                    toggle_error={() =>
                      set_error({
                        ...error,
                        speciality: { text: "", state: false },
                      })
                    }
                    error={error.speciality.state}
                    name="speciality"
                    type="select"
                    onChange={(event) => speciality_change(event)}
                    placeholder="Choose speciality"
                  />
                </span>
                <span className="flex-child-1-new">
                  <AntdInput
                    label="Service"
                    value={data.service}
                    options={props.global_store.services}
                    loading={props.global_store.get_services_loading}
                    disabled={props.get_bids_loading_flag}
                    error_text={error.service.text}
                    toggle_error={() =>
                      set_error({
                        ...error,
                        service: { text: "", state: false },
                      })
                    }
                    error={error.service.state}
                    name="service"
                    type="select"
                    onChange={(event) => {
                      set_data({
                        ...data,
                        applied: false,
                        doctor: "",
                        slot: "",
                        slots: [],
                        date_string: "",
                        amount: "",
                        max_dicount: 0,
                        date: "",
                        service: event,
                      });
                      props.get_doctors({
                        specialityId: data.speciality,
                        serviceId: event,
                      });
                    }}
                    placeholder="Choose service"
                  />
                </span>
              </div>

              <div className="flex-parent-new">
                <span className="flex-child-1-new">
                  <AntdInput
                    label="Hospital/Doctor/Labs"
                    value={data.doctor}
                    options={data.doctors}
                    loading={props.get_doctors_loading_flag}
                    disabled={props.get_doctors_loading_flag}
                    error_text={error.doctor.text}
                    toggle_error={() =>
                      set_error({
                        ...error,
                        doctor: { text: "", state: false },
                      })
                    }
                    error={error.doctor.state}
                    name="doctor"
                    type="select"
                    onChange={(event) => {
                      console.log(event, "event in doctors dropdown");
                      select_doctor(event);
                      // set_data({...data,"service":event})
                      // props.get_doctors({
                      //     specialityId:data.speciality,
                      //     serviceId:event
                      // })
                    }}
                    placeholder="Choose doctor"
                  />
                </span>
              </div>

              <div className="flex-parent-new">
                <span className="flex-child-3-new">
                  <AntdInput
                    label="Amount"
                    value={data.amount}
                    error_text={error.amount.text}
                    loading={props.check_number_loading_flag}
                    disabled={props.check_number_loading_flag}
                    toggle_error={() =>
                      set_error({
                        ...error,
                        amount: { text: "", state: false },
                      })
                    }
                    error={error.amount.state}
                    name="amount"
                    onChange={(event) =>
                      set_data({ ...data, amount: event.target.value })
                    }
                    placeholder="Enter amount"
                    validation="positive_integer"
                  />
                  <Tag
                    onClick={max_dicount_click}
                    style={{ cursor: "pointer" }}
                    icon={<CheckCircleOutlined />}
                    color="success"
                  >
                    Min Price
                  </Tag>
                </span>
                <span className="flex-child-3-new">
                  <Form.Item label={"Appointment date"}>
                    <DatePicker
                      style={{ width: "100%" }}
                      value={data.date}
                      onChange={(val) => {
                        try {
                          let today = new Date();
                          today.setDate(today.getDate() - 1);
                          let date = val;
                          // if(date>=today){ to make the past available dates to be selected dates
                          let year = date.year();
                          let month = date.month();
                          let day = date.day();
                          let day_string = moment(val).date();

                          console.log({ year, month, day }, "year");
                          let slot_code = day;
                          let slot_day = false;
                          let slots = false;
                          if (day === 0) {
                            slot_day = "Sunday";
                          } else if (day === 1) {
                            slot_day = "Monday";
                          } else if (day === 2) {
                            slot_day = "Tuesday";
                          } else if (day === 3) {
                            slot_day = "Wednesday";
                          } else if (day === 4) {
                            slot_day = "Thursday";
                          } else if (day === 5) {
                            slot_day = "Friday";
                          } else if (day === 6) {
                            slot_day = "Saturday";
                          }
                          let open = true;
                          console.log(slot_day, "slot_day");
                          if (!!data.timeSlots.length) {
                            slots = data.timeSlots
                              .filter((item) => {
                                if (item.day === slot_day) {
                                  open = !item.closed;
                                }
                                return (
                                  item.day.toLowerCase() ===
                                  slot_day.toLowerCase()
                                );
                              })[0]
                              .slotArray.reduce((accumulator, currentValue) => {
                                let arr = [...accumulator];
                                arr.push(currentValue);
                                return arr;
                              }, [])
                              .map((x) => {
                                return {
                                  name: x,
                                  value: x,
                                };
                              });
                          }
                          console.log(slots, "slots in Date Selector");
                          if (!!slots) {
                            if (open) {
                              set_data({
                                ...data,
                                slot: "",
                                slots: slots,
                                date: moment(val),
                                date_string: `${day_string}-${
                                  month + 1
                                }-${year}`,
                              });
                            } else {
                              props.global_error({
                                success: false,
                                message: "Doctor not available on selected day",
                                heading: "Appointment date",
                              });
                            }
                          }

                          // }
                          throw new Error("Invalid Date");
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    />
                  </Form.Item>
                </span>
                <span className="flex-child-3-new">
                  <AntdInput
                    label="Choose slots"
                    value={data.slot}
                    options={data.slots}
                    loading={props.get_doctors_loading_flag}
                    disabled={props.get_doctors_loading_flag}
                    error_text={error.slot.text}
                    toggle_error={() =>
                      set_error({ ...error, slot: { text: "", state: false } })
                    }
                    error={error.slot.state}
                    name="slot"
                    type="select"
                    onChange={(event) => {
                      console.log(event);
                      // let time = stringToTime(event)

                      // console.log(stringToTime(event),"stringToTime")
                      set_data({ ...data, slot: event });
                    }}
                    placeholder="Choose slot"
                  />
                </span>
              </div>

              <div className=" u-margin-top-small text-center">
                <Button
                  type="primary"
                  onClick={() => submit()}
                  style={{ width: "auto", margin: "auto" }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};
export default Booking;
