import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_NOTIFICATIONS , CLEAR_COUNT  } from '../Constants'
import { get_notifications_ret } from '../actions/notifications'
import api from '../utils/api_routes'
import store from '../store'
import { notification } from 'antd'


 function* get_notification_saga() {

  try {
     const  data = yield store.getState().notification_store.get_notifications
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")

      let type = data.type
      if(!!type){
        delete data.type
      }

      const api_data = yield call(api.notification.get_notification, data, headers)
    //   let api_data
    //   if(type==="refund_booking"){
    //     api_data = yield call(api.bookings.check_follow_up, data, headers)
    //   }else {
    //     api_data = yield call(api.bookings.update_global_booking, data, headers)
    //   }
     
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_notifications_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.count,
               un_read_count:api_data.unreadCount
              }))
       }else{
           yield put(get_notifications_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_notifications_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_notifications_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }


 function* clear_count_saga() {

  try {
    
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    

      const api_data = yield call(api.notification.clear_count, headers)

  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_notifications_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_notifications_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }



export const notification_saga = [
   takeLatest(GET_NOTIFICATIONS, get_notification_saga),
   takeLatest(CLEAR_COUNT, clear_count_saga)
]

export default notification_saga

