import {GET_CATALOGUE,GET_CATALOGUE_RET,GET_CATALOGUE_BYID,GET_CATALOGUE_BYID_RET,CATALOGUE_VIEW, GET_ACTIONABLE_INSIGHTS} from '../Constants';

const InitialState = {catalogue:[],catalogue_byid:{},id:'',view:false};

export default function (state = InitialState, action) {
    switch(action.type) {
        // case GET_CATALOGUE: {
        //     return {
        //         ...state,
        //         get_catalogue: {...action.data},
        //         // get_catalogue_loading: true
        //     }
        // }

        // case GET_CATALOGUE_RET: {
        //     return {
        //         ...state,
        //         get_catalogue_ret:{...action.data},
        //         get_catalogue_loading:true
        //     }
        // }

        case GET_CATALOGUE_RET: {
            // const data = [];
            // for(let i=0;i<action.data.length;i++){
            //     data.push(action.data[i]);
            // }
            return {...state,catalogue:[...action.data]};
        }

        case GET_CATALOGUE_BYID: {
            // const data = [];
            // for(let i=0;i<action.data.length;i++){
            //     data.push(action.data[i]);
            // }
            return {...state,id:action.data};
        }

        case GET_CATALOGUE_BYID_RET: {
            // const data = [];
            // for(let i=0;i<action.data.length;i++){
            //     data.push(action.data[i]);
            // }
            return {...state,catalogue_byid:{...action.data}};
        }

        case CATALOGUE_VIEW: {
            return {...state,view:action.data};
        }

        // case GET_CATALOGUE_LOADING: {
        //     return {
        //         ...state,
        //         get_catalogue_ret:false,
        //         get_catalogue_loading:false
        //     }
        // }

        default:
        return state;
    
    }
}