import React from "react";

import { Tabs, Card, PageHeader } from "antd";
import { SolutionOutlined, FolderOpenOutlined } from "@ant-design/icons";

import {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
} from "../actions/global";

import { connect } from "react-redux";
import ListBooking from "../Views/InsuranceBookings/ListBookings";
import { 
    get_insurance_bookings, get_insurance_bookings_loading, 
    update_booking_session, update_booking_session_loading, 
    add_booking_session, add_booking_session_loading, 
    update_booking, update_booking_loading, 
    upload_patient_insurance, upload_patient_insurance_loading, 
    upload_patient_report, upload_patient_report_loading } from "../actions/insuranceBookings";
import AddUser from "../Views/InsuranceBookings/AddUser";
import CreateBooking from "../Views/InsuranceBookings/CreateBooking";
import ViewBooking from "../Views/InsuranceBookings/ViewBooking";
import { check_number, check_number_loading, get_doctors, get_doctors_loading, register_user, register_user_loading } from "../actions/bookings";
import { get_url_params } from "../utils/common_utilities";

const { TabPane } = Tabs;

class InsuranceBookings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
    };
  }

  render() {
    console.log(this.props, "this.props  in Professional_container");
    return (
      <React.Fragment>
        <div>
          {this.props.location.search === "?list" ? (
            <React.Fragment>
              <ListBooking
                global_error={this.props.global_error}
                global_store={this.props.global_store}

                get_insurance_bookings={this.props.get_insurance_bookings}
                get_insurance_bookings_ret={this.props.insurance_bookings_store.get_insurance_bookings_ret}
                get_insurance_bookings_loading={this.props.get_insurance_bookings_loading}
                get_insurance_bookings_loading_flag={this.props.insurance_bookings_store.get_insurance_bookings_loading}

                update_booking_session = {this.props.update_booking_session}
                update_booking_session_ret = {this.props.insurance_bookings_store.update_booking_session_ret}
                update_booking_session_loading = {this.props.update_booking_session_loading}
                update_booking_session_loading_flag = {this.props.insurance_bookings_store.update_booking_session_loading}

                update_booking = {this.props.update_booking}
                update_booking_ret = {this.props.insurance_bookings_store.update_booking_ret}
                update_booking_loading = {this.props.update_booking_loading}
                update_booking_loading_flag = {this.props.insurance_bookings_store.update_booking_loading}

                add_booking_session = {this.props.add_booking_session}
                add_booking_session_ret = {this.props.insurance_bookings_store.add_booking_session_ret}
                add_booking_session_loading = {this.props.add_booking_session_loading}
                add_booking_session_loading_flag = {this.props.insurance_bookings_store.add_booking_session_loading}
              />
            </React.Fragment>
          ) : this.props.location.search === "?check-user" ? (
            <React.Fragment>
              <AddUser
                check_number_loading={this.props.check_number_loading}
                check_number={this.props.check_number}
                check_number_ret={this.props.bookings_store.check_number_ret}
                check_number_loading_flag={
                  this.props.bookings_store.check_number_loading
                }

                global_error={this.props.global_error}
                global_store={this.props.global_store}

                get_services={this.props.get_services}
                
                register_user_loading={this.props.register_user_loading}
                register_user_loading_flag={
                  this.props.bookings_store.register_user_loading
                }
                register_user_ret={this.props.bookings_store.register_user_ret}
                register_user={this.props.register_user}
                get_bookings={this.props.get_bookings}
                get_bookings_loading={this.props.get_bookings_loading}
                get_bookings_loading_flag={
                  this.props.bookings_store.get_bookings_loading
                }
                get_bookings_ret={this.props.bookings_store.get_bookings_ret}
                set_user={this.props.set_user}
              />
            </React.Fragment>
          ) : this.props.location.search === "?create-booking" ? (
            <React.Fragment>
              <CreateBooking
                check_number_loading={this.props.check_number_loading}
                check_number={this.props.check_number}
                check_number_ret={this.props.bookings_store.check_number_ret}
                check_number_loading_flag={
                  this.props.bookings_store.check_number_loading
                }

                upload_patient_report = {this.props.upload_patient_report}
                upload_patient_report_ret = {this.props.insurance_bookings_store.upload_patient_report_ret}
                upload_patient_report_loading = {this.props.upload_patient_report_loading}
                upload_patient_report_loading_flag = {this.props.insurance_bookings_store.upload_patient_report_loading}

                upload_patient_insurance = {this.props.upload_patient_insurance}
                upload_patient_insurance_ret = {this.props.insurance_bookings_store.upload_patient_insurance_ret}
                upload_patient_insurance_loading = {this.props.upload_patient_insurance_loading}
                upload_patient_insurance_loading_flag = {this.props.insurance_bookings_store.upload_patient_insurance_loading}

                user={this.props.bookings_store.user}

                global_error={this.props.global_error}
                global_store={this.props.global_store}

                get_services={this.props.get_services}

                get_doctors_loading = {this.props.get_doctors_loading}
                get_doctors_loading_flag  = {this.props.bookings_store.get_doctors_loading}
                get_doctors_ret = {this.props.bookings_store.get_doctors_ret}
                get_doctors = {this.props.get_doctors} 

                register_user_loading={this.props.register_user_loading}
                register_user_loading_flag={
                  this.props.bookings_store.register_user_loading
                }
                register_user_ret={this.props.bookings_store.register_user_ret}
                register_user={this.props.register_user}

                get_bookings={this.props.get_bookings}
                get_bookings_loading={this.props.get_bookings_loading}
                get_bookings_loading_flag={
                  this.props.bookings_store.get_bookings_loading
                }
                get_bookings_ret={this.props.bookings_store.get_bookings_ret}

                set_user={this.props.set_user}
              />
            </React.Fragment>
              ) : (!!get_url_params("booking_id")) ? <React.Fragment>
              <ViewBooking
                user={this.props.bookings_store.user}
                
                global_error={this.props.global_error}
                global_store={this.props.global_store}
                
                get_services={this.props.get_services}
                    
                update_booking_session = {this.props.update_booking_session}
                update_booking_session_ret = {this.props.insurance_bookings_store.update_booking_session_ret}
                update_booking_session_loading = {this.props.update_booking_session_loading}
                update_booking_session_loading_flag = {this.props.insurance_bookings_store.update_booking_session_loading}

                update_booking = {this.props.update_booking}
                update_booking_ret = {this.props.insurance_bookings_store.update_booking_ret}
                update_booking_loading = {this.props.update_booking_loading}
                update_booking_loading_flag = {this.props.insurance_bookings_store.update_booking_loading}
              />
          </React.Fragment> : <></> }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth_store: state.auth_store,
  insurance_bookings_store: state.insurance_bookings_store,
  bookings_store: state.bookings_store,
  global_error_ret: state.global_store.global_error_ret,
  global_store: state.global_store,
});

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_insurance_bookings,
  get_insurance_bookings_loading,
  update_booking_session,
  update_booking_session_loading,
  add_booking_session,
  add_booking_session_loading,
  update_booking,
  update_booking_loading,
  check_number,
  check_number_loading,
  register_user,
  register_user_loading,
  upload_patient_report,
  upload_patient_report_loading,
  upload_patient_insurance,
  upload_patient_insurance_loading,
  get_services,
  get_doctors,
  get_doctors_loading
})(InsuranceBookings);
