import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { GET_BIDS } from '../Constants'
import { get_bids_ret, get_bids_loading } from '../actions/bidding'
import api from '../utils/api_routes'
import store from '../store'

function* get_bids_saga() {
    console.log("Inside get_members_saga")

   try {
      const  data = yield store.getState().bidding_store.get_bids
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
       console.log(data,"data in get_bids_saga")
       const api_data = yield call(api.bids.get_bids, data, headers)
      console.log(api_data,"api_data in get_usersaga")
      if(!!api_data){
         if(api_data.success){
            yield put(get_bids_ret({
                success:true,
                message:api_data.message ||'Bids successfully fetched.',
                data:api_data.solution?.services
               }))
        }else{
            yield put(get_bids_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_bids_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_bids_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
}



export const bidding_saga = [
   takeLatest(GET_BIDS, get_bids_saga)
]

export default bidding_saga