import React from "react"
import { Table, Button, Card, Alert } from 'antd';
import {for_loop} from '../utils/common_utilities'


const BidsList = (props) => {
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
      })
      const [bids, set_bids] = React.useState([])

    React.useEffect(()=>{
        if(props.bids){
            set_bids(modif_data(props.bids))
        }
           
    },[props.bids])


     const handleChange = (pagination, filters, sorter) => {
        set_state({
          filteredInfo: filters,
          sortedInfo: sorter,
        });
      };
    
      const  clearFilters = () => {
        set_state({ filteredInfo: null });
      };
    
      const  clearAll = () => {
        set_state({
          filteredInfo: null,
          sortedInfo: null,
        });
      };
    
      const  setAgeSort = () => {
        set_state({
          sortedInfo: {
            order: 'descend',
            columnKey: 'age',
          },
        });
      };


      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}

    const modif_data = (arr) => {
    let bids_arr = []
        for_loop(arr, (item) => {
            let obj = {
            name: item.name,
            discount: item.discount,
            address:item.address,
            distance: Math.floor(item.distance),
            original_price: item?.price[0],
            offered_price: item?.newPrice[0],
            userType: item.userType,
            suggested:item.suggested,
            _id: item._id
            }
            bids_arr.push({
          ...obj
            })
           
        })

    return bids_arr;
    }

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          filteredValue: filteredInfo.name || null,
          onFilter: (value, record) => record.name.includes(value),
          sorter: (a, b) => a.name.length - b.name.length,
          sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
          width:'15%'
        
        },
        {
            title: 'Type',
            dataIndex: 'userType',
            key: 'userType',
            sorter: (a, b) => a.userType - b.userType,
            sortOrder: sortedInfo.columnKey === 'userType' && sortedInfo.order,
            width:'15%'
            
          },
          {
            title: 'Suggested',
            dataIndex: 'suggested',
            key: 'suggested',
            width:'10%',
            render: (data)=><div>{data?"True":"False"}</div>
          },
        {
          title: 'Original Price',
          dataIndex: 'original_price',
          key: 'original_price',
          width:'15%',
          sorter: (a, b) => a.original_price - b.original_price,
          sortOrder: sortedInfo.columnKey === 'original_price' && sortedInfo.order,
         
        },
        {
          title: 'Offered Price',
          dataIndex: 'offered_price',
          key: 'offered_price',
          width:'15%',
          sorter: (a, b) => a.offered_price - b.offered_price,
          sortOrder: sortedInfo.columnKey === 'offered_price' && sortedInfo.order,
         
        },
        {
            title: 'Distance',
            dataIndex: 'distance',
            key: 'distance',
            width:'12%',
            sorter: (a, b) => a.distance - b.distance,
            sortOrder: sortedInfo.columnKey === 'distance' && sortedInfo.order,
            
          },
          {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width:'18%',
            sorter: (a, b) => a.address.length - b.address.length,
            sortOrder: sortedInfo.columnKey === 'address' && sortedInfo.order,
          
          }
      ];


  if(props.bids.length === 0){
     return <React.Fragment>
          <Card title="Bids">
                <Alert
                message="Service not available"
                description="Currently we don't have professionals in this area, we will update you soon"
                type="info"
                showIcon
            />
  </Card>
     </React.Fragment>
  }
    
        return (<React.Fragment>
            <Card title="Bids">
        <Table
        rowClassName={(record, index)=>{
          if(!!record.suggested){
                return "higlighted_row"
          }
        }}
         columns={columns} 
         dataSource={bids} 
         loading={props.get_bids_loading_flag} 
         onChange={handleChange} />
        </Card>
        </React.Fragment>)
}
export default BidsList