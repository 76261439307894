import { call, put, takeLatest } from 'redux-saga/effects'
import {  FUNNEL_CHECK_NUMBER, FUNNEL_REGISTER_USER, FUNNEL_GET_DOCTORS, FUNNEL_MAKE_BOOKING, FUNNEL_GENERATE_LINK, FUNNEL_GET_BOOKINGS, FUNNEL_UPDATE_BOOKINGS,
   FUNNEL_GET_INSIGHTS, FUNNEL_GET_SOL_PROFESSIONALS , FUNNEL_UPDATE_SOL_PROFESSIONAL, FUNNEL_GLOBAL_BOOKINGS, FUNNEL_UPDATE_GLOBAL_BOOKING, FUNNEL_GET_REFUNDS, FUNNEL_GET_REPORTS_BYID, FUNNEL_CREATE_CUSTOM_SOLUTION, FUNNEL_UPLOAD_USER_REPORT, FUNNEL_UPLOAD_INSURANCE_REPORT, FUNNEL_SUBMIT_MEDICAL_DETAILS, FUNNEL_EDIT_MEDICAL_DETAILS } from '../Constants'
import { check_number, check_number_ret, register_user_ret, set_user, get_doctors_ret, make_booking_ret,
   generate_link_ret, get_bookings_ret, update_bookings_ret, get_insights_ret, global_bookings_ret
   , get_sol_professionals_ret, update_sol_professional_ret, update_global_booking_ret, get_refunds_ret, get_reports_byid_ret, create_custom_solution_ret, upload_user_report_ret, upload_insurance_report_ret, submit_medical_details_ret, edit_medical_details_ret } from '../actions/funnel'
import api from '../utils/api_routes'
import store from '../store'
import customBookingsApiRoutes from '../utils/customBookingsApiRoutes'


function* update_bookings_saga() {

  try {
     const  data = yield store.getState().funnel_store.update_bookings
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in update_bookings")
      let type = data.type
      let api_data    
      if(type==='cancel'){
        api_data = yield call(api.funnel.cancel_booking, data, headers)
      }else if(type==="UPDATE_PAYMENT"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        api_data = yield call(api.funnel.update_payment, data, booking_id,headers)
      }else if(type==="transfer"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        data.bookingId = booking_id
        api_data = yield call(api.funnel.transfer_booking, data, headers)
      }else if(type==="send_credentials"){
        let user_id  = data.user_id
        delete data.user_id
        delete data.type
        data.userId = user_id
        api_data = yield call(api.funnel.send_credentials, data, headers)
      } else if (type==="update_city"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        data.bookingId = booking_id
        api_data = yield call(api.funnel.update_city, data, headers)
      } else if(type==="update_status"){
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        data.bookingId = booking_id
        api_data = yield call(api.funnel.update_status, data, headers)
      } else {
        let booking_id  = data.booking_id
        delete data.booking_id
        delete data.type
        api_data = yield call(api.funnel.reschedule_booking, data, booking_id, headers)
      }
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_bookings_ret({
               success:true,
               message:api_data.message ||'Success'
              }))
       }else{
           yield put(update_bookings_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }


 function* update_global_booking_saga() {

  try {
     const  data = yield store.getState().funnel_store.update_global_booking
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")

      let type = data.type
      if(!!type){
        delete data.type
      }
      let api_data
      if(type==="refund_booking"){
        api_data = yield call(api.funnel.update_refund_booking, data, headers)
      }else {
        api_data = yield call(api.funnel.update_global_booking, data, headers)
      }
     
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_global_booking_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(update_global_booking_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_global_booking_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_global_booking_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }



 function* get_reports_byid_saga() {

  try {
     const  data = yield store.getState().funnel_store.get_reports_byid
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.funnel.get_reports_byid, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_reports_byid_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data
              }))
       }else{
           yield put(get_reports_byid_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_reports_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_reports_byid_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* get_refunds_saga() {

  try {
     const  data = yield store.getState().funnel_store.get_refunds
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.funnel.get_refunds, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_refunds_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(get_refunds_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_refunds_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_refunds_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* global_bookings_saga() {

  try {
     const  data = yield store.getState().funnel_store.global_bookings
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.funnel.global_bookings, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(global_bookings_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(global_bookings_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(global_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(global_bookings_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }


 function* get_sol_professionals_saga() {

  try {
     const  data = yield store.getState().funnel_store.get_sol_professionals
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.funnel.get_sol_professionals, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_sol_professionals_ret({
               success:true,
               message:api_data.msg ||'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(get_sol_professionals_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_sol_professionals_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_sol_professionals_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* get_insights_saga() {

  try {
     const  data = yield store.getState().funnel_store.get_insights
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")
     const api_data = yield call(api.funnel.get_insights, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_insights_ret({
               success:true,
               message:api_data.msg ||'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.size,
               total:api_data.count
              }))
       }else{
           yield put(get_insights_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_insights_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

function* get_bookings_saga() {

 try {
    const  data = yield store.getState().funnel_store.get_bookings
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
     console.log(data,"data in get_bookings")
    const api_data = yield call(api.funnel.get_bookings, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success || !!api_data.sucess){
          yield put(get_bookings_ret({
              success:true,
              message:api_data.msg ||'Something went wrong try again later..',
              data:api_data.data,
              count:api_data.size,
              total:api_data.count
             }))
      }else{
          yield put(get_bookings_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(get_bookings_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(get_bookings_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* generate_link_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().funnel_store.generate_link
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
    const api_data = yield call(api.funnel.generate_link, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(generate_link_ret({
              success:true,
              message:api_data.msg ||'Payment link sent to the user phone number',
              data:api_data.data.short_url
             }))
      }else{
          yield put(generate_link_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(generate_link_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(generate_link_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* make_booking_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().funnel_store.make_booking
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
    const api_data = yield call(api.funnel.make_booking, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(make_booking_ret({
              success:true,
              message:api_data.msg ||'Booking Created',
              data:api_data.data
             }))
      }else{
          yield put(make_booking_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(make_booking_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(make_booking_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* get_doctors_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().funnel_store.get_doctors
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
    const api_data = yield call(api.funnel.get_doctors, data, headers)
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(get_doctors_ret({
              success:true,
              message:api_data.message ||'Something went wrong try again later..',
              data:api_data.data
             }))
      }else{
          yield put(get_doctors_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(get_doctors_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(get_doctors_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}


function* check_number_saga() {
   console.log("Inside get_members_saga")

  try {
     const  data = yield store.getState().funnel_store.check_number
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
     const api_data = yield call(api.funnel.check_number, data, headers)
         console.log(api_data,"api_data in check_number_saga host")
     if(!!api_data){
        if(!!api_data.success){
           yield put(check_number_ret({
               success:true,
               message:api_data.message ||'Success',
               data:api_data.data
              }))
            yield put(set_user({
              data:{...api_data.data}
            }))
       }else{
           yield put(check_number_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(check_number_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(check_number_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* update_sol_professional_saga() {
  console.log("Inside get_members_saga")

 try {
    const  data = yield store.getState().funnel_store.update_sol_professional
    const headers = {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
       },
     }
     console.log(data,"data in register_user_saga")

     let type = data.type
     if(!!type){
       delete data.type
     }
     let api_data = {}
     
     if(type==="update_all"){
         api_data = yield call(api.funnel.update_all_sol_professional, data, headers)
     }else {
         api_data = yield call(api.funnel.update_sol_professional, data, headers)
     }
    
        console.log(api_data,"api_data in check_number_saga")
    if(!!api_data){
       if(!!api_data.success){
          yield put(update_sol_professional_ret({
              success:true,
              message:api_data.message ||'User successfully registered',
              data:api_data.data
             }))
      }else{
          yield put(update_sol_professional_ret({
              success:false,
              message:api_data.message ||'Something went wrong try again later..',
              data:[]
             }))
      }
    }
 } catch (e) {
  console.log(e,"e in get_act insigt saga")
  try{
      yield put(update_sol_professional_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
    }catch(x){
      yield put(update_sol_professional_ret({
          success:false,
          message:'Something went wrong try again later..',
          data:[]
         }))
      }
 }
}

function* register_user_saga() {
   console.log("Inside get_members_saga")

  try {
     const  data = yield store.getState().funnel_store.register_user
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in register_user_saga")
     const api_data = yield call(api.funnel.register_user, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success){
           yield put(register_user_ret({
               success:true,
               message:api_data.message ||'User successfully registered',
               data:api_data.data
              }))
       }else{
           yield put(register_user_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(register_user_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(register_user_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}

function* upload_user_report_sage() {

  try {
     const  data = yield store.getState().funnel_store.upload_user_report
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "multipart/form-data"
        },
      }
      console.log(data,"data in register_user_saga")
      let formData = new FormData()
      formData.set("file", data.file)
      let finalData = {
        formData: formData,
        data: data
      }

     const api_data = yield call(customBookingsApiRoutes.customSolutions.uploadUserReport, finalData, headers)
         console.log(api_data,"api_data in UPLOAD_USER_REPORT")
     if(!!api_data){
        if(!!api_data.success){
           yield put(upload_user_report_ret({
               success:true,
               message:api_data.message ||'User Report Uploaded Succesfully',
               data:api_data.data
              }))
       }else{
           yield put(upload_user_report_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   yield put(upload_user_report_ret({
    success:false,
    message:'Something went wrong try again later..',
    data:[]
   }))
  }
}

function* upload_insurance_report_sage() {

  try {
     const  data = yield store.getState().funnel_store.upload_insurance_report
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "multipart/form-data"
        },
      }
      console.log(data,"data in upload_insurance_report_sage")
      let formData = new FormData()
      formData.set("file", data.file)
      let finalData = {
        formData: formData,
        data: data
      }

     const api_data = yield call(customBookingsApiRoutes.customSolutions.uploadInsuranceReport, finalData, headers)
         console.log(api_data,"api_data in UPLOAD_INSURANCE_REPORT")
     if(!!api_data){
        if(!!api_data.success){
           yield put(upload_insurance_report_ret({
               success:true,
               message:api_data.message ||'Insurance Report Uploaded Succesfully',
               data:api_data.data
              }))
       }else{
           yield put(upload_insurance_report_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   yield put(upload_insurance_report_ret({
    success:false,
    message:'Something went wrong try again later..',
    data:[]
   }))
  }
}

function* submit_medical_details_sage() {

  try {
     const  data = yield store.getState().funnel_store.submit_medical_details
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in submit_medical_details_sage")

      const api_data = yield call(customBookingsApiRoutes.customSolutions.submitMedicalDetails, data, headers)
         console.log(api_data,"api_data in UPLOAD_INSURANCE_REPORT")
     if(!!api_data){
        if(!!api_data.success){
           yield put(submit_medical_details_ret({
               success:true,
               message:api_data.message ||'Medical Details Uploaded Succesfully',
               data:api_data.data
              }))
       }else{
           yield put(submit_medical_details_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   yield put(submit_medical_details_ret({
    success:false,
    message:'Something went wrong try again later..',
    data:[]
   }))
  }
}

function* submit_edit_details_sage() {
  try {
     const  data = yield store.getState().funnel_store.edit_medical_details
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in edit_medical_details_sage")

      const api_data = yield call(customBookingsApiRoutes.customSolutions.editMedicalDetails, data, headers)
         console.log(api_data,"api_data in EDIT_MEDICAL_DETAILS")
     if(!!api_data){
        if(!!api_data.success){
           yield put(edit_medical_details_ret({
               success:true,
               message:api_data.message ||'Medical Details Updated Succesfully',
               data:api_data.data
              }))
       }else{
           yield put(edit_medical_details_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   yield put(submit_medical_details_ret({
    success:false,
    message:'Something went wrong try again later..',
    data:[]
   }))
  }
}

function* create_custom_solution_saga() {
   console.log("Inside create_custom_solution saga", true)

  try {
     const  data = yield store.getState().funnel_store.create_custom_solution
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in register_user_saga")
     const api_data = yield call(customBookingsApiRoutes.customSolutions.createCustomSolution, data, headers)
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success){
           yield put(create_custom_solution_ret({
               success:true,
               message:api_data.message ||'Solution successfully created',
               data:api_data.data
              }))
       }else{
           yield put(create_custom_solution_ret({
               success:false,
               message:api_data.message ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   yield put(create_custom_solution_ret({
    success:false,
    message:'Something went wrong try again later..',
    data:[]
   }))
  }
}


export const funnel_saga = [
   takeLatest(FUNNEL_CHECK_NUMBER, check_number_saga),
   takeLatest(FUNNEL_REGISTER_USER, register_user_saga),
   takeLatest(FUNNEL_GET_DOCTORS, get_doctors_saga),
   takeLatest(FUNNEL_MAKE_BOOKING, make_booking_saga),
   takeLatest(FUNNEL_GENERATE_LINK, generate_link_saga),
   takeLatest(FUNNEL_GET_BOOKINGS, get_bookings_saga),
   takeLatest(FUNNEL_UPDATE_BOOKINGS, update_bookings_saga),
   takeLatest(FUNNEL_GET_INSIGHTS, get_insights_saga),
   takeLatest(FUNNEL_GET_SOL_PROFESSIONALS, get_sol_professionals_saga),
   takeLatest(FUNNEL_UPDATE_SOL_PROFESSIONAL, update_sol_professional_saga),
   takeLatest(FUNNEL_GLOBAL_BOOKINGS, global_bookings_saga),
   takeLatest(FUNNEL_UPDATE_GLOBAL_BOOKING, update_global_booking_saga),
   takeLatest(FUNNEL_GET_REFUNDS, get_refunds_saga),
   takeLatest(FUNNEL_GET_REPORTS_BYID, get_reports_byid_saga),
   takeLatest(FUNNEL_UPLOAD_USER_REPORT, upload_user_report_sage),
   takeLatest(FUNNEL_UPLOAD_INSURANCE_REPORT, upload_insurance_report_sage),
   takeLatest(FUNNEL_SUBMIT_MEDICAL_DETAILS, submit_medical_details_sage),
   takeLatest(FUNNEL_EDIT_MEDICAL_DETAILS, submit_edit_details_sage),
   takeLatest(FUNNEL_CREATE_CUSTOM_SOLUTION, create_custom_solution_saga)
]