import { GET_USER, GET_USER_RET, GET_USER_LOADING, SET_USER_DATA, GET_TPA_USER_DETAILS, GET_TPA_USER_DETAILS_RET, GET_TPA_USER_DETAILS_LOADING, DOWNLOAD_TPA_USER_DETAILS, DOWNLOAD_TPA_USER_DETAILS_LOADING, DOWNLOAD_TPA_USER_DETAILS_RET } from "../Constants"
import { act } from "react-dom/test-utils"

const initialState = {
    get_user:false,
    get_user_ret:false,
    get_user_loading:false,

    get_tpa_user_details:false,
    get_tpa_user_details_ret:false,
    get_tpa_user_details_loading:false,

    download_tpa_user_details:false,
    download_tpa_user_details_ret:false,
    download_tpa_user_details_loading:false,

    user_data:{
                
    }
}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_USER_DATA:{
            return {
                ...state,
                user_data:{...action.data},
                get_user_loading:false
            }
        }
        case GET_USER:
             return {
               ...state,
               get_user:action.data,
               get_user_loading:true
             }

        case GET_USER_RET:
        
            return {
                ...state,
                get_user_ret:action.data,
                get_user_loading:false
            }

        case GET_USER_LOADING:
            return {
                ...state,
                get_user_ret:false,
                get_user_loading:false
        }

        case GET_TPA_USER_DETAILS:
             return {
               ...state,
               get_tpa_user_details:action.data,
               get_tpa_user_details_loading:true
             }

        case GET_TPA_USER_DETAILS_RET:
            return {
                ...state,
                get_tpa_user_details_ret:action.data,
                get_tpa_user_details_loading:false
            }

        case GET_TPA_USER_DETAILS_LOADING:
            return {
                ...state,
                get_tpa_user_details_ret:false,
                get_tpa_user_details_loading:false
        }

        case DOWNLOAD_TPA_USER_DETAILS:
             return {
               ...state,
               download_tpa_user_details:action.data,
               download_tpa_user_details_loading:true
             }

        case DOWNLOAD_TPA_USER_DETAILS_RET:
            return {
                ...state,
                download_tpa_user_details_ret:action.data,
                download_tpa_user_details_loading:false
            }

        case DOWNLOAD_TPA_USER_DETAILS_LOADING:
            return {
                ...state,
                download_tpa_user_details_ret:false,
                download_tpa_user_details_loading:false
        }

        default:
        return {...state}
    
        }
}