import React from "react"
import { Collapse,  Popover, Modal, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { error_form_check } from '../../utils/common_utilities'
import AntdInput from "../../Components/AntdInput"

const { Panel } = Collapse

const EditFaqs  = (props) => {
   const [state, set_state] = React.useState({
    expandIconPosition:'left'
   })

   const [visible, set_visible] = React.useState(false)
   const [update, set_update] = React.useState({
       _id:'',
       type:'',
       state:false
   })

   const [update_faq, set_update_faq] = React.useState({
    typeA: "",
    typeB: ""
  });
  const [update_faq_error, set_update_faq_error] = React.useState({
    typeA: { state: false, text: "" },
    typeB: { state: false, text: "" },
  })

  React.useEffect(()=>{
            if(props.update_faq_ret){
                if(props.update_faq_ret.success){
                  props.get_service_family_byid && props.get_service_family_byid({id:props.family_id})
                    props.global_error({
                        success: true,
                        message: props.update_faq_ret.message,
                        heading: 'Edit Faq'
                       
                    })
                     handleCancel()
                }else {
                    props.global_error({
                        success: false,
                        message: props.update_faq_ret.message,
                        heading: 'Edit Faq'
                    })
                }
                props.update_faq_loading()
            }
  },[props.update_faq_ret])

   const  callback = (key)=> {
      console.log(key);
    }
      
      const submit = () => {
        const ret_error = validate({ ...update_faq });
        set_update_faq_error({ ...ret_error });
        console.log(ret_error, "ret_error in add_member");
        if (!error_form_check(ret_error)) {
            if(update.type === "add"){
                props.update_faq({
                    type: "add_faq",
                    family_id:props.family_id,
                    [props.type==="testimonial"?'review':'answer']:update_faq.typeB,
                    [props.type==="testimonial"?'user':'question']:update_faq.typeA,
                    variant:props.type
                  })
            }else {
                props.update_faq({
                    type: "edit_faq",
                    family_id:props.family_id,
                    faq_id:update._id,
                    [props.type==="testimonial"?'review':'answer']:update_faq.typeB,
                    [props.type==="testimonial"?'user':'question']:update_faq.typeA,
                    variant:props.type
                  })
            }
          
        }
      }

      const validate = (data) => {
        const error = {
          typeA: { state: false, text: "" },
          typeB: { state: false, text: "" }
        };
        if (update_faq.typeA === "") {
          {
            error.typeA.text =`Please enter ${props.typeA}`;
            error.typeA.state = true;
          }
        }
        if (update_faq.typeB === "") {
          {
            error.typeB.text =`Please enter ${props.typeB}`;
            error.typeB.state = true;
          }
        }
        return error;
      };
    
      const handleCancel = (e) => {
        console.log(e);
        set_visible(false);
        set_update_faq({
          typeA: "",
          typeB: "",
         
        
        });
        set_update_faq_error({
          typeA: { state: false, text: "" },
          typeB: { state: false, text: "" },
        
        });
      };

      const content = (id) =>   (
        <div>
          <p className="u-cursor-pointer" onClick={(event)=>{
                        set_update({_id:id, type:'edit', state:true})
                        let data = [...props.data]
                        data = data.filter(item => {
                         return item._id === id
                       })
                       set_update_faq({
                           typeA: props.type!=="testimonial"?data[0].Q:data[0].user,
                           typeB: props.type!=="testimonial"?data[0].A:data[0].review
                       })
                       set_visible(true)
                        event.stopPropagation()
          }}>Edit</p>
          <p className="u-cursor-pointer" onClick={(event)=>{
                set_update({_id:id, type:'delete', state:true})   
                props.update_faq({
                    type: "delete_faq",
                    family_id:props.family_id,
                    faq_id:id,
                    variant:props.type
                  })          
                event.stopPropagation()
          }} >Delete</p>
        </div>
      );
      
      const genExtra = (id) => (
        <Popover content={content(id)} title="Update" trigger="click">
                <SettingOutlined
                onClick={event => {
                    event.stopPropagation()
                    console.log("genExtra getting called")
                }}
                />
      </Popover>
      
      )

      const  onPositionChange = expandIconPosition => {
        set_state({expandIconPosition})
      };

      const { expandIconPosition } = state;
      

    return (<React.Fragment>

<Collapse
          defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition={expandIconPosition}
        >
          {!!props.data?props.data.map((item,i)=>{
              return  <Panel header={props.type!=="testimonial"?item.Q:item.user} key={item._id} extra={genExtra(item._id)}>
              <div>{props.type!=="testimonial"?item.A:item.review}</div>
            </Panel>
          }):''}
          <div className="text-center u-margin-top-small">
              <Button
                  type="primary"
                 onClick={() =>  {
                    set_update({ type:'add', state:true})
                   set_update_faq({
                       typeA:"",
                       typeB:""
                   })
                   set_visible(true)
                 }}
                 disabled = {props.update_bookings_loading_flag}
                 loading={props.update_bookings_loading_flag}
                 style={{ width: 'auto', margin:'auto' }}
               >
                    {`Add ${props.type}`}
            </Button>
          </div>
        </Collapse>

        <Modal
        title={`Update ${props.type}`}
        visible={visible}
        onOk={submit}
        onCancel={handleCancel}
      >
        <div className="flex-parent-new u-margin-top-small">
          <span className="flex-child-1-new">
            <AntdInput
              label={props.typeA}
              value={update_faq.typeA}
              error_text={update_faq_error.typeA.text}
              loading={props.update_faq_loading_flag}
              disabled={props.update_faq_loading_flag}
              toggle_error={() =>
                set_update_faq_error({
                  ...update_faq_error,
                  typeA: { text: "", state: false },
                })
              }
              error={update_faq_error.typeA.state}
              name="typeA"
              onChange={(event) =>
                set_update_faq({
                  ...update_faq,
                  typeA: event.target.value,
                })
              }
              placeholder={`Enter ${props.typeA}`}
            />
          </span>
        </div>
        <div className="flex-parent-new u-margin-top-small">
          <span className="flex-child-1-new">
                <AntdInput
                label={props.typeB}
                value={update_faq.typeB}
                error_text={update_faq_error.typeB.text}
                loading={props.update_faq_loading_flag}
                disabled={props.update_faq_loading_flag}
                toggle_error={() =>
                    set_update_faq_error({
                    ...update_faq_error,
                    typeB: { text: "", state: false },
                    })
                }
                error={update_faq_error.typeB.state}
                name="description"
                rows ="7"
                onChange={(event) => {
                    set_update_faq({
                        ...update_faq,
                        typeB: event.target.value,
                    })
                }}
                placeholder={`Enter ${props.typeB}`}
                type="textarea"
                />
           </span>
        </div>

        {/* <div className="text-center">
          <Button
                  type="primary"
                 onClick={() => submit()}
                 disabled = {props.update_bookings_loading_flag}
                 loading={props.update_bookings_loading_flag}
                 style={{ width: 'auto', margin:'auto' }}
      >
       Submit
    </Button>
        </div> */}
      </Modal>


    </React.Fragment>)
}

export default EditFaqs