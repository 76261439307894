import { GLOBAL_ERROR, GLOBAL_ERROR_RET, GLOBAL_ERROR_CLR, GLOBAL_LOADING, GLOBAL_LOADING_CLR, SET_SPECIALITIES,
        SET_USER_DATA, GET_USER_DATA, SET_MOUNT , GET_SPECIALITIES_LOADING, GET_SPECIALITIES_RET,
         GET_SPECIALITIES, GET_SERVICES, GET_SERVICES_RET, GET_SERVICES_LOADING, SET_SERVICES, SET_GLOBAL_UPLOAD, 
         UPLOAD_PROGRESS_TOGGLE, UPLOAD_PROGRESS_DATA,GET_AGENT_LIST, GET_AGENT_LIST_RET, GET_AGENT_LIST_LOADING, GET_SERVICE_FAMILIES, GET_SERVICE_FAMILIES_RET, SET_SERVICE_FAMILIES, GET_SERVICE_FAMILIES_LOADING} from "../Constants"

         

export const get_agent_list = (data) => {
    console.log("Inside Uplaod Progress toggle Action")
    return {
        type:GET_AGENT_LIST,
        data
    }
}


export const get_agent_list_ret = (data) => {
    console.log("Inside Uplaod Progress toggle Action")
    return {
        type:GET_AGENT_LIST_RET,
        data
    }
}


export const get_agent_list_loading = (data) => {
    console.log("Inside Uplaod Progress toggle Action")
    return {
        type:GET_AGENT_LIST_LOADING,
        data
    }
}
export const upload_progress_data = (data) => {
    console.log("Inside Uplaod Progress toggle Action")
    return {
        type:UPLOAD_PROGRESS_DATA,
        data
    }
}


export const upload_progress_toggle = (data) => {
    console.log("Inside Uplaod Progress toggle Action")
    return {
        type:UPLOAD_PROGRESS_TOGGLE,
        data
    }
}

export const set_global_upload = (data) => {
    return {
        type:SET_GLOBAL_UPLOAD,
        data
    }
}
export const global_loading_set = (data) => {
    return {
        type:GLOBAL_LOADING,
        data
    }
}
export const global_loading_clr = (data) => {
    return {
        type:GLOBAL_LOADING_CLR,
        data
    }
}
export const global_error = (data) => {
    return {
        type:GLOBAL_ERROR,
        data
    }
}
export const global_error_ret = (data) => {
    return {
        type:GLOBAL_ERROR_RET,
        data
    }
}
export const global_error_clr = (data) => {
    return {
        type:GLOBAL_ERROR_CLR,
        data
    }
}

export const set_user_data = (data) => {
    return {
        type:SET_USER_DATA,
        data
    }
}

export const get_user_data = (data) => {
    return {
        type:GET_USER_DATA,
        data
    }
}

export const set_mount = (data) => {
    return {
        type:SET_MOUNT,
        data
    }
}

export const get_specialities = (data) => {
    return {
        type:GET_SPECIALITIES,
        data
    }
}
export const set_specialities = (data) => {
    console.log("Inside set_specialities action")
    return {
        type:SET_SPECIALITIES,
        data
    }
}
export const get_specialities_ret = (data) => {
    return {
        type:GET_SPECIALITIES_RET,
        data
    }
}
export const get_specialities_loading = (data) => {
    return {
        type:GET_SPECIALITIES_LOADING,
        data
    }
}


export const get_services = (data) => {
    return {
        type:GET_SERVICES,
        data
    }
}
export const set_services = (data) => {
    return {
        type:SET_SERVICES,
        data
    }
}
export const get_services_ret = (data) => {
    return {
        type:GET_SERVICES_RET,
        data
    }
}
export const get_services_loading = (data) => {
    return {
        type:GET_SERVICES_LOADING,
        data
    }
}

export const get_service_families = (data) => {
    return {
        type:GET_SERVICE_FAMILIES,
        data
    }
}

export const get_service_families_ret = (data) => {
    return {
        type:GET_SERVICE_FAMILIES_RET,
        data
    }
}

export const get_service_families_loading = (data) => {
    return {
        type:GET_SERVICE_FAMILIES_LOADING,
        data
    }
}
export const set_service_families = (data) => {
    return {
        type:SET_SERVICE_FAMILIES,
        data
    }
}