import { CHECK_NUMBER, CHECK_NUMBER_RET, CHECK_NUMBER_LOADING, SET_USER, 
    REGISTER_USER_LOADING, REGISTER_USER, REGISTER_USER_RET,
     GET_DOCTORS_LOADING, MAKE_BOOKING_LOADING, MAKE_BOOKING_RET, MAKE_BOOKING,
GET_DOCTORS_RET, GET_DOCTORS,  GENERATE_LINK_LOADING, GENERATE_LINK_RET, GENERATE_LINK, GET_BOOKINGS_LOADING,
GET_BOOKINGS_RET, GET_BOOKINGS, UPDATE_BOOKINGS_LOADING, UPDATE_BOOKINGS_RET, UPDATE_BOOKINGS, GET_INSIGHTS,
GET_INSIGHTS_RET, GET_INSIGHTS_LOADING, GET_SOL_PROFESSIONALS, GET_SOL_PROFESSIONALS_RET, GET_SOL_PROFESSIONALS_LOADING,
UPDATE_SOL_PROFESSIONAL_LOADING, UPDATE_SOL_PROFESSIONAL_RET, UPDATE_SOL_PROFESSIONAL, UPDATE_GLOBAL_BOOKING_LOADING, UPDATE_GLOBAL_BOOKING_RET,
UPDATE_GLOBAL_BOOKING, GLOBAL_BOOKINGS, GLOBAL_BOOKINGS_RET, GLOBAL_BOOKINGS_LOADING , GET_REFUNDS_LOADING, GET_REFUNDS_RET, GET_REFUNDS,
GET_REPORTS_BYID_LOADING, GET_REPORTS_BYID_RET, GET_REPORTS_BYID, GET_MEDICAL_DETAILS, GET_MEDICAL_DETAILS_LOADING, GET_MEDICAL_DETAILS_RET, 
APPROVE_BOOKING_INSURANCE_UPDATE, APPROVE_BOOKING_INSURANCE_UPDATE_RET, APPROVE_BOOKING_INSURANCE_UPDATE_LOADING, UPDATE_BOOKING_PROCESS, UPDATE_BOOKING_PROCESS_RET, UPDATE_BOOKING_PROCESS_LOADING, UPDATE_USER_DETAILS, UPDATE_USER_DETAILS_RET, UPDATE_USER_DETAILS_LOADING  } from "../Constants"
import { act } from "react-dom/test-utils"

const initialState = {
    
        check_number:false,
        check_number_ret:false,
        check_number_loading:false,

        register_user:false,
        register_user_ret:false,
        register_user_loading:false,

        get_doctors:false,
        get_doctors_ret:false,
        get_doctors_loading:false,

        make_booking:false,
        make_booking_ret:false,
        make_booking_loading:false,

        generate_link:false,
        generate_link_ret:false,
        generate_link_loading:false,

        get_bookings:false,
        get_bookings_ret:false,
        get_bookings_loading:false,

        update_bookings:false,
        update_bookings_ret:false,
        update_bookings_loading:false,

        get_insights:false,
        get_insights_ret:false,
        get_insights_loading:false,

        get_sol_professionals:false,
        get_sol_professionals_ret:false,
        get_sol_professionals_loading:false,

        update_sol_professional:false,
        update_sol_professional_ret:false,
        update_sol_professional_loading:false,

        global_bookings:false,
        global_bookings_ret:false,
        global_bookings_loading:false,

        update_global_booking:false,
        update_global_booking_ret:false,
        update_global_booking_loading:false,

        get_refunds:false,
        get_refunds_ret:false,
        get_refunds_loading:false,

        get_reports_byid:false,
        get_reports_byid_ret:false,
        get_reports_byid_loading:false,

        get_medical_details: false,
        get_medical_details_ret: false,
        get_medical_details_loading: false,

        approve_booking_insurance_update:false,
        approve_booking_insurance_update_ret:false,
        approve_booking_insurance_update_loading:false,

        update_booking_process:false,
        update_booking_process_ret:false,
        update_booking_process_loading:false,

        update_user_details:false,
        update_user_details_ret:false,
        update_user_details_loading:false,
        
        user: {}
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_REPORTS_BYID: {
            return {
                ...state,
                get_reports_byid:{...action.data},
                get_reports_byid_loading:true
            }
        }

        case GET_REPORTS_BYID_RET: {
            return {
                ...state,
                get_reports_byid_ret:{...action.data},
                get_reports_byid_loading:true
            }
        }

        case GET_REPORTS_BYID_LOADING: {
            return {
                ...state,
                get_reports_byid_ret:false,
                get_reports_byid_loading:false
            }
        }


        case GLOBAL_BOOKINGS: {
            return {
                ...state,
                global_bookings:{...action.data},
                global_bookings_loading:true
            }
        }

        case GLOBAL_BOOKINGS_RET: {
            return {
                ...state,
                global_bookings_ret:{...action.data},
                global_bookings_loading:true
            }
        }

        case GLOBAL_BOOKINGS_LOADING: {
            return {
                ...state,
                global_bookings_ret:false,
                global_bookings_loading:false
            }
        }

        case GET_REFUNDS: {
            return {
                ...state,
                get_refunds:{...action.data},
                get_refunds_loading:true
            }
        }

        case GET_REFUNDS_RET: {
            return {
                ...state,
                get_refunds_ret:{...action.data},
                get_refunds_loading:true
            }
        }

        case GET_REFUNDS_LOADING: {
            return {
                ...state,
                get_refunds_ret:false,
                get_refunds_loading:false
            }
        }


        case UPDATE_GLOBAL_BOOKING: {
            return {
                ...state,
                update_global_booking:{...action.data},
                update_global_booking_loading:true
            }
        }

        case UPDATE_GLOBAL_BOOKING_RET: {
            return {
                ...state,
                update_global_booking_ret:{...action.data},
                update_global_booking_loading:true
            }
        }

        case UPDATE_GLOBAL_BOOKING_LOADING: {
            return {
                ...state,
                update_global_booking_ret:false,
                update_global_booking_loading:false
            }
        }


        case UPDATE_SOL_PROFESSIONAL: {
            return {
                ...state,
                update_sol_professional:{...action.data},
                update_sol_professional_loading:true
            }
        }

        case UPDATE_SOL_PROFESSIONAL_RET: {
            return {
                ...state,
                update_sol_professional_ret:{...action.data},
                update_sol_professional_loading:true
            }
        }

        case UPDATE_SOL_PROFESSIONAL_LOADING: {
            return {
                ...state,
                update_sol_professional_ret:false,
                update_sol_professional_loading:false
            }
        }


        case GET_SOL_PROFESSIONALS: {
            return {
                ...state,
                get_sol_professionals:{...action.data},
                get_sol_professionals_loading:true
            }
        }

        case GET_SOL_PROFESSIONALS_RET: {
            return {
                ...state,
                get_sol_professionals_ret:{...action.data},
                get_sol_professionals_loading:true
            }
        }

        case GET_SOL_PROFESSIONALS_LOADING: {
            return {
                ...state,
                get_sol_professionals_ret:false,
                get_sol_professionals_loading:false
            }
        }

        case GET_INSIGHTS: {
            return {
                ...state,
                get_insights:{...action.data},
                get_insights_loading:true
            }
        }

        case GET_INSIGHTS_RET: {
            return {
                ...state,
                get_insights_ret:{...action.data},
                get_insights_loading:true
            }
        }

        case GET_INSIGHTS_LOADING: {
            return {
                ...state,
                get_insights_ret:false,
                get_insights_loading:false
            }
        }


        case UPDATE_BOOKINGS: {
            return {
                ...state,
                update_bookings:{...action.data},
                update_bookings_loading:true
            }
        }

        case UPDATE_BOOKINGS_RET: {
            return {
                ...state,
                update_bookings_ret:{...action.data},
                update_bookings_loading:true
            }
        }

        case UPDATE_BOOKINGS_LOADING: {
            return {
                ...state,
                update_bookings_ret:false,
                update_bookings_loading:false
            }
        }

        case GET_BOOKINGS: {
            return {
                ...state,
                get_bookings:{...action.data},
                get_bookings_loading:true
            }
        }

        case GET_BOOKINGS_RET: {
            return {
                ...state,
                get_bookings_ret:{...action.data},
                get_bookings_loading:true
            }
        }

        case GET_BOOKINGS_LOADING: {
            return {
                ...state,
                get_bookings_ret:false,
                get_bookings_loading:false
            }
        }

        case GENERATE_LINK: {
            return {
                ...state,
                generate_link:{...action.data},
                generate_link_loading:true
            }
        }

        case GENERATE_LINK_RET: {
            console.log("Inside GENERATE_LINK_RET", action.data)
            return {
                ...state,
                generate_link_ret:{...action.data},
                generate_link_loading:true
            }
        }

        case GENERATE_LINK_LOADING: {
            return {
                ...state,
                generate_link_ret:false,
                generate_link_loading:false
            }
        }


        case MAKE_BOOKING: {
            return {
                ...state,
                make_booking:{...action.data},
                make_booking_loading:true
            }
        }

        case MAKE_BOOKING_RET: {
            return {
                ...state,
                make_booking_ret:action.data,
                make_booking_loading:true
            }
        }

        case MAKE_BOOKING_LOADING:
            return {
            ...state,
            make_booking_ret:false,
            make_booking_loading:false
            }


        case GET_DOCTORS: {
            return {
                ...state,
                get_doctors:{...action.data},
                get_doctors_loading:true
            }
        }

        case GET_DOCTORS_RET: {
            return {
                ...state,
                get_doctors_ret:action.data,
                get_doctors_loading:true
            }
        }

        case GET_DOCTORS_LOADING:
            return {
            ...state,
            get_doctors_ret:false,
            get_doctors_loading:false
            }

        case SET_USER: {
            return {
                ...state,
                user:{...action.data}
            }
        }

        case CHECK_NUMBER: {
            return {
                ...state,
                check_number:action.data,
                check_number_loading:true
            }
        }

        case CHECK_NUMBER_RET:
             return {
               ...state,
               check_number_ret:action.data,
               check_number_loading:true
             }

        case REGISTER_USER: {
            return {
                ...state,
                register_user:{...action.data},
                register_user_loading:true
            }
        }

        case REGISTER_USER_RET: {
            return {
                ...state,
                register_user_ret:action.data,
                register_user_loading:true
            }
        }

        case REGISTER_USER_LOADING:
            return {
            ...state,
            register_user_ret:false,
            register_user_loading:false
            }

        case CHECK_NUMBER_LOADING:
            console.log("Inside check Number Reducer")
            return {
            ...state,
            check_number_ret:false,
            check_number_loading:false
            }

        case GET_MEDICAL_DETAILS:
            return {
            ...state,
            get_medical_details: action.data,
            get_medical_details_loading: true
            }
        
        case GET_MEDICAL_DETAILS_RET: 
            return {
                ...state,
                get_medical_details_ret: action.data,
                get_medical_details_loading: true
            }

        case GET_MEDICAL_DETAILS_LOADING:
            return {
            ...state,
            get_medical_details_loading:false
            }
        
        case APPROVE_BOOKING_INSURANCE_UPDATE: {
            return {
                ...state,
                approve_booking_insurance_update:{...action.data},
                approve_booking_insurance_update_loading:true
            }
        }

        case APPROVE_BOOKING_INSURANCE_UPDATE_RET: {
            return {
                ...state,
                approve_booking_insurance_update_ret:{...action.data},
                approve_booking_insurance_update_loading:true
            }
        }

        case APPROVE_BOOKING_INSURANCE_UPDATE_LOADING: {
            return {
                ...state,
                approve_booking_insurance_update_ret:false,
                approve_booking_insurance_update_loading:false
            }
        }
        
        case UPDATE_BOOKING_PROCESS: {
            return {
                ...state,
                update_booking_process:{...action.data},
                update_booking_process_loading:true
            }
        }

        case UPDATE_BOOKING_PROCESS_RET: {
            return {
                ...state,
                update_booking_process_ret:{...action.data},
                update_booking_process_loading:true
            }
        }

        case UPDATE_BOOKING_PROCESS_LOADING: {
            return {
                ...state,
                update_booking_process_ret:false,
                update_booking_process_loading:false
            }
        }
        
        case UPDATE_USER_DETAILS: {
            return {
                ...state,
                update_user_details:{...action.data},
                update_user_details_loading:true
            }
        }

        case UPDATE_USER_DETAILS_RET: {
            return {
                ...state,
                update_user_details_ret:{...action.data},
                update_user_details_loading:true
            }
        }

        case UPDATE_USER_DETAILS_LOADING: {
            return {
                ...state,
                update_user_details_ret:false,
                update_user_details_loading:false
            }
        }

        default:
        return {...state}
    
        }
}