import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services } from "../actions/global"
import { check_follow_up, check_follow_up_loading, update_follow_up_loading, update_follow_up,
         get_follow_ups, get_follow_ups_loading } from "../actions/follow_up"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import { get_url_params } from "../utils/common_utilities";
import FollowupList from "../Views/FollowUp/FollowupList";
import PreviousFollowups from "../Views/FollowUp/PreviousFollowups";
import YourFollowups from "../Views/FollowUp/YourFollowups";

class FollowUps extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      const catalogue_id = get_url_params('catalogue_id')
      console.log(this.props.location.search,"this.props  in Professional_container")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?list") ? <React.Fragment>
                    <FollowupList
                             check_follow_up = {this.props.check_follow_up}
                             check_follow_up_ret = {this.props.follow_up_store.check_follow_up_ret}
                             check_follow_up_loading = {this.props.check_follow_up_loading}
                             check_follow_up_loading_flag = {this.props.follow_up_store.check_follow_up_loading}

                             update_follow_up_loading = {this.props.update_follow_up_loading}
                             update_follow_up_loading_flag = {this.props.follow_up_store.update_follow_up_loading}
                             update_follow_up = {this.props.update_follow_up}
                             update_follow_up_ret = {this.props.follow_up_store.update_follow_up_ret}

                             global_error = {this.props.global_error}
                             global_store  = {this.props.global_store}

                        />
                  
                  </React.Fragment>:(this.props.location.search==="?previous-followups")   ?   <React.Fragment>
                     <PreviousFollowups


                          get_follow_ups = {this.props.get_follow_ups}  
                          get_follow_ups_loading_flag = {this.props.follow_up_store.get_follow_ups_loading}
                          get_follow_ups_loading = {this.props.get_follow_ups_loading}
                          get_follow_ups_ret = {this.props.follow_up_store.get_follow_ups_ret}

                          update_follow_up_loading = {this.props.update_follow_up_loading}
                          update_follow_up_loading_flag = {this.props.follow_up_store.update_follow_up_loading}
                          update_follow_up = {this.props.update_follow_up}
                          update_follow_up_ret = {this.props.follow_up_store.update_follow_up_ret}


                          global_error = {this.props.global_error}
                          global_store  = {this.props.global_store}
                      />
                               
                  </React.Fragment>:(this.props.location.search==="?your-followups")?<React.Fragment>
                        <YourFollowups
                              get_follow_ups = {this.props.get_follow_ups}  
                              get_follow_ups_loading_flag = {this.props.follow_up_store.get_follow_ups_loading}
                              get_follow_ups_loading = {this.props.get_follow_ups_loading}
                              get_follow_ups_ret = {this.props.follow_up_store.get_follow_ups_ret}

                              update_follow_up_loading = {this.props.update_follow_up_loading}
                              update_follow_up_loading_flag = {this.props.follow_up_store.update_follow_up_loading}
                              update_follow_up = {this.props.update_follow_up}
                              update_follow_up_ret = {this.props.follow_up_store.update_follow_up_ret}


                              global_error = {this.props.global_error}
                              global_store  = {this.props.global_store}
                        />
                     
                  </React.Fragment>:<React.Fragment></React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  follow_up_store:state.follow_up_store,
  global_error_ret:state.global_store.global_error_ret,
  global_store:state.global_store
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,

  check_follow_up,
  check_follow_up_loading,

  update_follow_up,
  update_follow_up_loading,
  get_follow_ups_loading,
  get_follow_ups
  
 })(FollowUps)
