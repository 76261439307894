import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Tag, Switch, Badge } from 'antd';
import {
  CheckCircleOutlined,
  LoginOutlined,
  QuestionCircleOutlined,
  ProfileOutlined,
  CheckOutlined,
  CloseOutlined,
  NotificationOutlined
} from '@ant-design/icons';

const Header = (props) => {
  const [state, setState] = useState({
    width:''
})

const resize = () => {
    setState({
        width:window.screen.width,
        pathname:window.location.pathname
    })
}
useEffect(()=>{
    window.addEventListener('resize', resize)
    setState({
        width:window.screen.width,
        pathname:window.location.pathname
    })
},[])
console.log(props.un_read_count,"props in Header component")
    return (
        <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center">
    
          <div className="logo mr-auto">
          {(!!localStorage.getItem('token')) && <React.Fragment> 
             <li className=" drop-down">
              <Switch
                 checkedChildren={<CheckOutlined />}
                 unCheckedChildren={<CloseOutlined />}
                 defaultChecked={props.sidebar_toggle}
                 onChange = {()=>props.set_sidebar_toggle(!props.sidebar_toggle)}
             />
              </li>
            </React.Fragment>
          }
          </div>
    
          <nav className={state.width>767?"nav-menu d-lg-block":'d-none '}>
            <ul>
              {/* <li className="active"><a href="#hero">Home</a></li> */}
              {(!!localStorage.getItem('token')) && <React.Fragment>     <li className="drop-down">
               
               <a href="">
               {props.user?.name}
               <Tag   icon={<CheckCircleOutlined />} color="success" >{props.user?.role} </Tag>
               </a>
               </li>
               <li className="drop-down"><a href="">Settings</a>
                <ul>
                  <li><Link to="/dashboard/notifications?list">Notifications</Link></li>
                  <li><a href="#cta">Profile <ProfileOutlined  style={{marginLeft:'10px'}}  /></a></li>
                  <li><a href="#">Help  <QuestionCircleOutlined style={{marginLeft:'10px'}} /></a></li>
                  <li><a onClick={()=>props.logout()} href="#">Logout <LoginOutlined style={{marginLeft:'10px'}} /></a></li>
                </ul>
              </li>

              <li>
              <Link to="/dashboard/notifications?list"> 
               <Badge count={props.un_read_count} showZero={false}>
                      <NotificationOutlined onClick={()=>props.clear_count()} style={{color:'#000', fontSize:'20px'}} />
                </Badge></Link>
              
              </li>
              </React.Fragment>}
              {
              (!localStorage.getItem('token')) ?<li className={state.pathname==="/signin"?"blue_text":''}><Link to="/signin">Login</Link></li>:
                 <React.Fragment></React.Fragment>}
             
            </ul>
          </nav>
        </div>
      </header>
    )
}

export default Header