import  React from "react"
import {  Card, Form, Button, Row, Col, Tag, Typography } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { error_form_check } from "../../utils/common_utilities"
import { Redirect } from "react-router-dom";
import SentCatalogue from "./SentCatalogue";

const { Title } = Typography;

const CatalogueData = (props) => {
    const [form] = Form.useForm();
    const [data, set_data] = React.useState({
        consultation_message:'',
        // message_speciality:[],
        // message_service:[],
        consultation_notification:'',
        // notification_speciality:[],
        notification_title:'',
        // notification_service:[],
        // business_leads:'',
        email_title:'',
        // business_speciality:[],
        // business_service:[]
    })
    const [error, set_error] = React.useState({
      consultation_message:{ state:false, text:"" },
      // message_speciality:{ state:false, text:"" },
      // message_service:{ state:false, text:"" },
      consultation_notification:{ state:false, text:"" },
      // notification_speciality:{ state:false, text:"" },
      notification_title:{ state:false, text:"" },
      // notification_service:{ state:false, text:"" },
      // business_leads:{ state:false, text:"" },
      // business_speciality:{ state:false, text:"" },
      // business_service:{ state:false, text:"" },
      email_title:{ state:false, text:"" }
 })

 React.useEffect(()=>{
    if(props.send_catalogue_report_ret){
        if(props.send_catalogue_report_ret.success){
            props.global_error({
             success:true,
             message:props.send_catalogue_report_ret.message,
             heading:'Catalogue Reports',
           })
        }else {
            props.global_error({
             success:false,
             message:props.send_catalogue_report_ret.message,
             heading:'Catalogue Reports'
           })
        }
        props.send_catalogue_report_loading()
        props.sent_catalogue_reports({
          page:1,
          limit:10
        })
    }
}, [props.send_catalogue_report_ret])

React.useEffect(()=>{
  if(props.email_preview_ret){
    if(props.email_preview_ret.success){
      let screen = window.screen
      var win = window.open("", "Variance Email", `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${screen.width-400},height=${screen.height-400},top=`+(screen.height-100)+",left="+(screen.width-440));
      win.document.write(props.email_preview_ret.data)
        props.global_error({
         success:true,
         message:props.email_preview_ret.message,
         heading:'Preview Email',
       })
    }else {
        props.global_error({
         success:false,
         message:props.email_preview_ret.message,
         heading:'Preview Email'
       })
    }
 props.email_preview_loading()
}
 
},[props.email_preview_ret])


const send_email = () => {
    const ret_error = validate_email({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_catalogue_report({
          email:true,
          message:false,
          notification:false,
          // businessLeads:'',
          // specialityList:data.message_speciality,
          // servicesList:data.message_service,
          textMessage:'',
          pushMessage:"",
          // businessLeads:data.business_leads,
          notificationTitle:"",
          emailTitle:data.email_title,
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_email  = (data) =>{
  const  error = {
    consultation_message:{ state:false, text:"" },
    // message_speciality:{ state:false, text:"" },
    // message_service:{ state:false, text:"" },
    consultation_notification:{ state:false, text:"" },
    // notification_speciality:{ state:false, text:"" },
    notification_title:{ state:false, text:"" },
    // notification_service:{ state:false, text:"" },
    // business_leads:{ state:false, text:"" },
    // business_speciality:{ state:false, text:"" },
    // business_service:{ state:false, text:"" },
    email_title:{ state:false, text:"" }
    }
console.log(data,"data sdsdsdsd")
    // if(data.business_speciality.length === 0){
    //   {
    //     error.business_speciality.text = "Please select speciality"
    //     error.business_speciality.state = true
    //   }
    // }
    // if(data.business_service.length === 0){
    //   {
    //     error.business_service.text = "Please select service"
    //     error.business_service.state = true
    //   }
    // }
    // if(data.business_leads===''){
    //   {
    //     error.business_leads.text = "Please enter value"
    //     error.business_leads.state = true
    //   }
    // }
    if(data.email_title===''){
      {
        error.email_title.text = "Please enter title"
        error.email_title.state = true
      }
    }
    return error
  }


 const send_text_message = () => {
    const ret_error = validate_text_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_catalogue_report({
          email:false,
          message:true,
          notification:false,
          // businessLeads:'',
          // specialityList:data.message_speciality,
          // servicesList:data.message_speciality,
          textMessage:data.consultation_message,
          pushMessage:"",
         
          notificationTitle:"",
          emailTitle:"",
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_text_message  = (data) =>{
    const  error = {
      consultation_message:{ state:false, text:"" },
      // message_speciality:{ state:false, text:"" },
      // message_service:{ state:false, text:"" },
      consultation_notification:{ state:false, text:"" },
      // notification_speciality:{ state:false, text:"" },
      notification_title:{ state:false, text:"" },
      // notification_service:{ state:false, text:"" },
      // business_leads:{ state:false, text:"" },
      // business_speciality:{ state:false, text:"" },
      // business_service:{ state:false, text:"" },
      email_title:{ state:false, text:"" }
      }

      if(data.consultation_message===''){
        {
          error.consultation_message.text = "Please enter your message"
          error.consultation_message.state = true
        }
      }
      // if(data.message_speciality.length === 0){
      //   {
      //     error.message_speciality.text = "Please select speciality"
      //     error.message_speciality.state = true
      //   }
      // }
      // if(data.message_service.length === 0){
      //   {
      //     error.message_service.text = "Please select service"
      //     error.message_service.state = true
      //   }
      // }
    return error
  }


  const send_push_message = () => {
    const ret_error = validate_push_message({...data});
    set_error({...error, ...ret_error});
    // console.log(ret_error,"ret_error in add_member")
    if(!error_form_check(ret_error)){
        // console.log("Inside Eror_form_check")
        props.send_catalogue_report({
          email:false,
          message:false,
          notification:true,
          // businessLeads:'',
          // specialityList:data.notification_speciality,
          // servicesList:data.notification_service,
          textMessage:'',
          pushMessage:data.consultation_message,
        
          notificationTitle:data.notification_title,
          emailTitle:"",
            professionalIds:props.keymanager_store.selected_professionals.map(item=>{
              return item._id
            })
        })
    }
 }

 const validate_push_message  = (data) =>{
  const  error = {
    consultation_message:{ state:false, text:"" },
    // message_speciality:{ state:false, text:"" },
    // message_service:{ state:false, text:"" },
    consultation_notification:{ state:false, text:"" },
    // notification_speciality:{ state:false, text:"" },
    notification_title:{ state:false, text:"" },
    // notification_service:{ state:false, text:"" },
    // business_leads:{ state:false, text:"" },
    // business_speciality:{ state:false, text:"" },
    // business_service:{ state:false, text:"" },
    email_title:{ state:false, text:"" }
    }

    if(data.consultation_notification===''){
      {
        error.consultation_notification.text = "Please enter your message"
        error.consultation_notification.state = true
      }
    }
    if(data.notification_title===''){
      {
        error.notification_title.text = "Please enter title"
        error.notification_title.state = true
      }
    }
    // if(data.notification_speciality.length === 0){
    //   {
    //     error.notification_speciality.text = "Please select speciality"
    //     error.notification_speciality.state = true
    //   }
    // }
    // if(data.notification_service.length === 0){
    //   {
    //     error.notification_service.text = "Please select service"
    //     error.notification_service.state = true
    //   }
    // }
  return error
  }

  console.log(error,"error in Variance Data")

  if(!!(props.keymanager_store.selected_professionals.length === 0)){
    return <Redirect to="/dashboard/keymanager?professionals_list"  />
  }

  console.log(props.keymanager_store.selected_professionals,"props.keymanager_store")
    return <React.Fragment>
          <Card >
            <Row>
              <Col span ={4}>
              <Title level={5}>Professionals Selected</Title>
                      {props.keymanager_store.selected_professionals.map(item=>{
                        return    <Tag  style={{margin:'.2rem'}} onClose={()=>props.set_key_manager_professionals(props.keymanager_store.selected_professionals.filter(val=>{
                           return (val._id !==item._id)
                        }))}>
                            {item.name}
                       </Tag>
                        })}
              </Col>
              <Col span ={20}>
              <Form
      form={form}
      layout="vertical"
    >
 
<div className="flex-parent-new">
  <span className="flex-child-1-new">
  <Title level={5}>Send Message</Title>
 
{/* 
            <AntdInput 
              label="Speciality"  
              value={data.message_speciality} 
              disabled = {props.get_bids_loading_flag}
              options = {props.global_store.specialities}
              loading={props.global_store.get_specialities_loading}
              error_text={error.message_speciality.text} 
              toggle_error = {()=>set_error({...error,message_speciality:{text:'',state:false}})} 
              error={error.message_speciality.state}
              name="speciality" 
              type="select"
              mode="multiple"
              onChange = {event=>{
                console.log(event)
                let specialityId = ""
                event.map((item,i)=>{
                  if(i!==0){
                    specialityId = `${specialityId},${item}`
                  }else{
                    specialityId = `${specialityId}${item}`
                  }
                  
                })
                props.get_services({speciality:specialityId})
                // set_get_professionals_params({...get_professionals_params, specialityId:specialityId, page:1})
                // props.get_professionals({...get_professionals_params, specialityId:specialityId, page:1})
                // set_speciality(event)
                set_data({...data, message_speciality:event})
              }} 
             
              placeholder="Choose speciality" /> */}

    {/* <AntdInput 
      label="Service"  
      value={data.message_service} 
      options = {props.global_store.services}
      loading={props.global_store.get_services_loading}
      disabled = {props.get_bids_loading_flag}
      error_text={error.message_service.text} 
      toggle_error = {()=>set_error({...error,message_service:{text:'',state:false}})} 
      error={error.message_service.state}
      name="service" 
      type="select"
      mode="multiple"
      onChange = {event=>{
        // set_bids_list(false)
        set_data({...data,message_service:event})
      }} 
      placeholder="Choose service" /> */}

<AntdInput label="Consultaion Message"  
            value={data.consultation_message} 
            error_text={error.consultation_message.text} 
            loading = {props.send_catalogue_report_loading_flag}
            disabled = {props.send_catalogue_report_loading_flag} 
            toggle_error = {()=>set_error({...error, consultation_message:{text:'',state:false}})} 
            error={error.consultation_message.state} 
            name="consultation_message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
 <div>
     <Button loading={props.send_catalogue_report_loading_flag} onClick={()=>send_text_message()} type={"primary"}>Send</Button>
 </div>
</span>
<span className="flex-child-1-new">
<Title level={5}>Send Notifcation</Title>

   {/* <AntdInput 
              label="Speciality"  
              value={data.notification_speciality} 
              disabled = {props.get_bids_loading_flag}
              options = {props.global_store.specialities}
              loading={props.global_store.get_specialities_loading}
              error_text={error.notification_speciality.text} 
              toggle_error = {()=>set_error({...error,notification_speciality:{text:'',state:false}})} 
              error={error.notification_speciality.state}
              name="speciality" 
              type="select"
              mode="multiple"
              onChange = {event=>{
                console.log(event)
                let specialityId = ""
                event.map((item,i)=>{
                  if(i!==0){
                    specialityId = `${specialityId},${item}`
                  }else{
                    specialityId = `${specialityId}${item}`
                  }
                  
                })
                props.get_services({speciality:specialityId})
                // set_get_professionals_params({...get_professionals_params, specialityId:specialityId, page:1})
                // props.get_professionals({...get_professionals_params, specialityId:specialityId, page:1})
                // set_speciality(event)
                set_data({...data, notification_speciality:event})
              }} 
             
              placeholder="Choose speciality" />

    <AntdInput 
      label="Service"  
      value={data.notification_service} 
      options = {props.global_store.services}
      loading={props.global_store.get_services_loading}
      disabled = {props.get_bids_loading_flag}
      error_text={error.notification_service.text} 
      toggle_error = {()=>set_error({...error,notification_service:{text:'',state:false}})} 
      error={error.notification_service.state}
      name="service" 
      type="select"
      mode="multiple"
      onChange = {event=>{
        // set_bids_list(false)
        set_data({...data, notification_service:event})
      }} 
      placeholder="Choose service" /> */}

<AntdInput label="Notification Title"  
            value={data.notification_title} 
            error_text={error.notification_title.text} 
            loading = {props.send_catalogue_report_loading_flag}
            disabled = {props.send_catalogue_report_loading_flag} 
            toggle_error = {()=>set_error({...error, notification_title:{text:'',state:false}})} 
            error={error.notification_title.state} 
            name="notification_title" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter title"
           
            />

        <AntdInput label="Notification"  
            value={data.consultation_notification} 
            error_text={error.consultation_notification.text} 
            loading = {props.send_catalogue_report_loading_flag}
            disabled = {props.send_catalogue_report_loading_flag} 
            toggle_error = {()=>set_error({...error, consultation_notification:{text:'',state:false}})} 
            error={error.consultation_notification.state} 
            name="consultation_notification" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
 <div>
     <Button  loading={props.send_catalogue_report_loading_flag} onClick={()=>send_push_message()} type={"primary"}>Send</Button>
 </div>
</span>
</div>

<div className="flex-parent-new ">
  <span className="flex-child-1-new u-margin-top-medium">
  <Title level={5}>Send Email</Title>
    
     {/* <AntdInput 
              label="Speciality"  
              value={data.business_speciality} 
              disabled = {props.get_bids_loading_flag}
              options = {props.global_store.specialities}
              loading={props.global_store.get_specialities_loading}
              error_text={error.business_speciality.text} 
              toggle_error = {()=>set_error({...error,business_speciality:{text:'',state:false}})} 
              error={error.business_speciality.state}
              name="speciality" 
              type="select"
              mode="multiple"
              onChange = {event=>{
                console.log(event)
                let specialityId = ""
                event.map((item,i)=>{
                  if(i!==0){
                    specialityId = `${specialityId},${item}`
                  }else{
                    specialityId = `${specialityId}${item}`
                  }
                  
                })
                props.get_services({speciality:specialityId})
                // set_get_professionals_params({...get_professionals_params, specialityId:specialityId, page:1})
                // props.get_professionals({...get_professionals_params, specialityId:specialityId, page:1})
                // set_speciality(event)
                set_data({...data, business_speciality:event})
              }} 
             
              placeholder="Choose speciality" />

    <AntdInput 
      label="Service"  
      value={data.business_service} 
      options = {props.global_store.services}
      loading={props.global_store.get_services_loading}
      disabled = {props.get_bids_loading_flag}
      error_text={error.business_service.text} 
      toggle_error = {()=>set_error({...error,business_service:{text:'',state:false}})} 
      error={error.business_service.state}
      name="service" 
      type="select"
      mode="multiple"
      onChange = {event=>{
        // set_bids_list(false)
        set_data({...data, business_service:event})
      }} 
      placeholder="Choose service" /> */}

<AntdInput label="Email Title"  
            value={data.email_title} 
            error_text={error.email_title.text} 
            loading = {props.send_catalogue_report_loading_flag}
            disabled = {props.send_catalogue_report_loading_flag} 
            toggle_error = {()=>set_error({...error, email_title:{text:'',state:false}})} 
            error={error.email_title.state} 
            name="email_title" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter title"
           
            />
       {/* <AntdInput label="Leads"  
        value={data.business_leads} 
        error_text={error.business_leads.text}
        loading = {props.send_catalogue_report_loading_flag}
        disabled = {props.send_catalogue_report_loading_flag} 
        toggle_error = {()=>set_error({...error, business_leads:{text:'',state:false}})} 
        error={error.business_leads.state} 
        name="business_leads" 
        onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
        placeholder="Enter value" 
        validation = "positive_integer"
    /> */}
    <div className="flex-parent-new">
      <span className="flex-child-1-new">
        <Button loading={props.send_catalogue_report_loading_flag} onClick={()=>send_email()} type={"primary"}>Send</Button>
      </span>
    <span className="flex-child-1-new">
        <Button loading={props.send_business_reports_loading_flag} onClick={()=>props.email_preview({type:"ABOUT_CATALOGUE_TEMPLATE"})} type={"primary"}>View Template</Button>
    </span>
    </div>
  </span>
</div>
  </Form>
              </Col>
            </Row>

            <Title level={5}>Sent Data</Title>
                        <SentCatalogue
                            sent_catalogue_reports = {props.sent_catalogue_reports}
                            sent_catalogue_reports_loading = {props.sent_catalogue_reports_loading}
                            sent_catalogue_reports_loading_flag = {props.sent_catalogue_reports_loading_flag}
                            sent_catalogue_reports_ret = {props.sent_catalogue_reports_ret}
                            
                            global_error = {props.global_error}
                        />
            </Card>
    </React.Fragment>
}

export default CatalogueData