import React from "react"
import { Table, Input, Button, Space, Card, Form, Select, Modal, Calendar, TimePicker, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CalendarOutlined , EditOutlined, FormOutlined} from '@ant-design/icons';
import {for_loop, objectEquals, get_time} from '../../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../../Components/AntdInput"
import SendSms from "../Querylist/SendSms"


const { Option } = Select

// import Map from "./Map.js"


const PreviousFollowups = (props) => {
  const {form} = Form.useForm()
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [get_follow_ups_params, set_get_follow_ups_params] = React.useState({
           page:1,
           limit:10,
           searchParams:{
           
           },
           filters:{
            queryType:'',
            status:'',
            paymentStatus:'',
            agentName:''
           }
      })
      const [user_data, set_user_data] = React.useState({
        number:''
      })
      const [error, set_error] = React.useState({
       number:{ state:false, text:"" }
     })
     const [expandedRowKeys, set_expandedRowKeys] = React.useState([])
     const [reminderTime_state, set_reminderTime_state] = React.useState('')
     const [sms_select, set_sms_select] = React.useState({})
      const [data, set_data] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)
      const [visible, set_visible] = React.useState(false)
      const [edit_visible, set_edit_visible] = React.useState(false)
      const [message_modal, set_message_modal] = React.useState(false)
      const [note, set_note] = React.useState('')
      const [note_error, set_note_error] = React.useState({
          note:{ state:false, text:"" },
      })
      const [selected_follow_up, set_selected_follow_up] = React.useState({

      })

        const [update_follow_up_data, set_update_follow_up_data] = React.useState({
          date:'',
          time:'',
          note:''
         })
         const [edit_follow_up_data, set_edit_follow_up_data] = React.useState({
          address:'',
          treatment:'',
          note:'',
          amount_paid:'',
          amount_remaining:'',
          facility:''
        })
          const [update_follow_up_error, set_update_follow_up_error] = React.useState({
            date:{ state:false, text:"" },
            time:{ state:false, text:"" },
            note:{state:false, text:''}
          })

          const [edit_follow_up_error, set_edit_follow_up_error] = React.useState({
            address:{ state:false, text:"" },
            treatment:{ state:false, text:"" },
            note:{ state:false, text:"" },
            amount_paid:{ state:false, text:"" },
            amount_remaining:{ state:false, text:"" },
            facility:{ state:false, text:"" }
          })
    React.useEffect(()=>{
       props.get_follow_ups({...get_follow_ups_params})
       let date  = new Date().getTime() / 1000
          console.log(date,"date in react .useEffect")
          set_update_follow_up_data({
            date:date,
            time:"",
            note:''
          })
    },[])

    React.useEffect(()=>{
      if(!!props.get_follow_ups_ret){
     
        if(props.get_follow_ups_ret.success){
              console.log(props.get_follow_ups_ret,"get_follow_ups_ret")
              set_data(modif_data([...props.get_follow_ups_ret.data]))
              set_get_follow_ups_params({...get_follow_ups_params,limit:props.get_follow_ups_ret.data.length,  total:props.get_follow_ups_ret.count})
      
        }else{
         props.global_error({
           success:false,
           message:props.get_follow_ups_ret.message,
           heading:'Follow-up'
         })
        }
         props.get_follow_ups_loading()
     }
     if(!!props.update_follow_up_ret){

        
      if(props.update_follow_up_ret.success){
console.log(props.update_follow_up_ret,"props.update_follow_up_ret")
        if(props.update_follow_up_ret.type==="send_sms"){
          props.global_error({
            success:true,
            message:props.update_follow_up_ret.message,
            heading:'Update Follow up'
          })
          // let arr = []
          // let new_arr = [...data]
          // arr   = new_arr.map(item=>{
          //   console.log(item, sms_select, "======================>")
          //       if(item._id===sms_select.queryId){
          //         console.log(sms_select,"send_sms")
          //         let new_sms = [...item.sms]
          //         new_sms.unshift({
          //           ...sms_select,
          //           messageBody:sms_select.message
          //         })
          //         console.log(new_sms,"new_sms in update_ret")
          //         return {
          //           ...item,
          //           // sms:new_sms
          //         }   
          //       }
          //       else {
          //         return item
          //       }
          //  })

          // set_data(arr)
          // window.alert("alreer")
          message_handleCancel()
          props.get_follow_ups({...get_follow_ups_params})

        }else{
          message_handleCancel()
          handleCancel()
          edit_handleCancel()
          // props.get_follow_ups({mobile_number:user_data.number})
          props.get_follow_ups({...get_follow_ups_params})
        
         props.global_error({
           success:true,
           message:props.update_follow_up_ret.message,
           heading:'Update Follow up'
         })
        }
       
      
      }else{
       props.global_error({
         success:false,
         message:props.update_follow_up_ret.message,
         heading:'Update Follow up'
       })
      }
       props.update_follow_up_loading()
   }
     
         
  },[props.update_follow_up_ret, props.update_global_booking_ret, props.get_follow_ups_ret])

     const handleChange = (pagination, filters, sorter) => {
       console.log(pagination, filters, "pagination and  filters in handekChange")
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_follow_ups_params.filters}
       let updated_filters = {}
      console.log(state_filters,"state_filters")
      
       updated_filters.queryType = filters.queryType
       updated_filters.status = filters.status
       updated_filters.paymentStatus = filters.paymentStatus
       updated_filters.agentName = filters.agentName
    
      if(!!((pagination.current === get_follow_ups_params.page) && (pagination.pageSize === get_follow_ups_params.limit))){
        console.log(state_filters, updated_filters,"state_filters and updated_filters")
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          // window.alert("Inside objects are not equal")
          console.log("Objects are not equal , call filter operation")
          set_get_follow_ups_params({...get_follow_ups_params, page:1,filters :{
            status: filters.status? filters.status:'',
            queryType: filters.queryType? filters.queryType:'',
            paymentStatus: filters.paymentStatus? filters.paymentStatus:'',
            agentName: filters.agentName?filters.agentName:''
          }})
          props.get_follow_ups({...get_follow_ups_params, page:1, filters :{
            status: filters.status? filters.status:'',
            queryType: filters.queryType? filters.queryType:'',
            paymentStatus: filters.paymentStatus? filters.paymentStatus:'',
            agentName: filters.agentName?filters.agentName:''
  }})
        }
      }else {
        // set_get_follow_ups_params({...get_follow_ups_params, page:1, limit:pagination.pageSize})
        //     props.get_follow_ups({...get_follow_ups_params, page:1, limit:pagination.pageSize})
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) =>  {
          if(type==="date"){
              return  ({
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                  <div style={{ padding: 8 }}>
                    <DatePicker
                      ref={node => {
                        state.searchInput = node;
                      }}
                      placeholder={`Search ${dataIndex}`}
                      value={selectedKeys[0]}
                      // onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                      onChange = {e=>{
                        try {
                          set_reminderTime_state(e.format("YYYY-MM-DD"))
                        } catch (error) {
                          console.log(error,"error in Selecting time")
                        }
                      }}
                      onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                      style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => {
                          set_get_follow_ups_params({...get_follow_ups_params, page:1, limit:10,filters :{
                              ...get_follow_ups_params.filters,
                              reminderTime:reminderTime_state
                          }})
                          props.get_follow_ups({...get_follow_ups_params, page:1, limit:10, filters :{
                            ...get_follow_ups_params.filters,
                            reminderTime:reminderTime_state
                  }})
                        } }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                      >
                        Search
                      </Button>
                      <Button onClick={() => {
                          set_get_follow_ups_params({...get_follow_ups_params, page:1, limit:10, filters :{
                            ...get_follow_ups_params.filters,
                            reminderTime:""
                        }})
                        props.get_follow_ups({...get_follow_ups_params, page:1, limit:10, filters :{
                          ...get_follow_ups_params.filters,
                          reminderTime:""
                }})
                set_state({ searchInput: '' })
                clearFilters()
                      }} size="small" style={{ width: 90 }}>
                        Reset
                      </Button>
                    </Space>
                  </div>
                ),
                filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                onFilter: (value, record) =>
                  record[dataIndex]
                    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
                onFilterDropdownVisibleChange: visible => {
                  if (visible) {
                    // setTimeout(() => state.searchInput.select(), 100);
                  }
                },
                render: text =>
                  state.searchedColumn === dataIndex ? (
                    <Highlighter
                      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                      searchWords={[state.searchText]}
                      autoEscape
                      textToHighlight={text ? text.toString() : ''}
                      title={text}
                    />
                  ) : (
                    text
                  ),
              });
           }else {
             return ({
              filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                  <Input
                    ref={node => {
                      state.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                  />
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                      icon={<SearchOutlined />}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Search
                    </Button>
                    <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                      Reset
                    </Button>
                  </Space>
                </div>
              ),
              filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
              onFilter: (value, record) =>
                record[dataIndex]
                  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => state.searchInput.select(), 100);
                }
              },
              render: text =>
                state.searchedColumn === dataIndex ? (
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                    title={text}
                  />
                ) : (
                  text
                ),
            });
          }

     }
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_follow_ups_params({...get_follow_ups_params, page:1,searchParams:{
           ...get_follow_ups_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_follow_ups({...get_follow_ups_params, page:1,searchParams:{
          ...get_follow_ups_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            createdAt: get_time(item.createdAt),
            message: item.message,
            mobileNumber:item.mobileNumber,
            name:item.name,
            queryType:item.queryType,
            _id:item._id,
            status:item.status,
            note:item.note,
            sentMessages:item.sentMessages,
            sms:item.sms,
            updatedAt:item.updatedAt,
            reminderTime:item.reminderTime,
            paymentStatus:item.paymentStatus,
            paymentPaid:item.paymentPaid,
            paymentDue:item.paymentDue,
            address:item.address,
            treatment:item.treatment,
            agentName:item.agentName,
            facility:item.facility,
            query:item.query,
            requestedService:item.requestedService
            }
            prof_list.push({
          ...obj
            })
        })

    return prof_list;
    }


    const update_status = (val, data) => {
      props.update_global_booking({
        bookingId:data._id,
        refundStatus:val,
        type:'refund_booking'
      })
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_follow_ups_params({...get_follow_ups_params, limit:b, page:1})
      props.get_follow_ups({...get_follow_ups_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      console.log(a,b,get_follow_ups_params,"inside page change")
      // console.log(a,b,get_follow_ups_params,"a and b get_follow_ups_params")
      if(true){
        set_get_follow_ups_params({...get_follow_ups_params, page:a, limit:b})
        props.get_follow_ups({...get_follow_ups_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      // window.alert("THis called")
      set_get_follow_ups_params({...get_follow_ups_params,page:1,  searchParams:{
      
      },
      filters:{
        queryType:'',
        status:'',
        paymentStatus:'',
        agentName:''
      }})
      props.get_follow_ups({...get_follow_ups_params,page:1,  searchParams:{
     
      },
      filters:{
        queryType:'',
        status:'',
        paymentStatus:'',
        agentName:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_follow_ups_params({...get_follow_ups_params, page:1, filters:{

        queryType:'',
        status:'',
        paymentStatus:'',
        agentName:''
      }})
      props.get_follow_ups({...get_follow_ups_params, page:1, filters:{
            queryType:'',
            status:'',
            paymentStatus:'',
            agentName:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const update_query_type = (val, data) => {
      props.update_follow_up({
        queryId:data._id,
        queryType:val,
        type:'update_query_typw'
      })
    }

    const update_query_status = (val, data) => {
      props.update_follow_up({
        queryId:data._id,
        queryType:data.queryType,
        status:val,
        type:'update_query_status'
      })
    }
    
    const update_payment_status = (val, data) => {
      props.update_follow_up({
        queryId:data._id,
        queryType:data.queryType,
        paymentStatus:val,
        type:'update_payment_status'
      })
    }
    
    const update_agent_name = (val, data) => {
      console.log(val,data,"val, data in update_agent_name")
      props.update_follow_up({
        queryId:data._id,
        agentId:val,
        type:'update_agent_name'
      })
    }

    const update_note_value = ( data) => {
      console.log(data,"val, data in update_agent_name")
      props.update_follow_up({
        queryId:data._id,
        note:note,
        queryType:data.queryType,
        type:'update_payument_status'
      })
    }

    const columns = [

      {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width:'35%',
          fixed:'left'
      },
      {
        title: 'Phone',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        width:'35%',
        fixed:'left',
        ...getColumnSearchProps('mobileNumber', "Search mobileNumber", 'mobileNumber')
    },

    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width:'40%',
      fixed:'left'
  },

  {
    title: 'Treatment',
    dataIndex: 'treatment',
    key: 'treatment',
    width:'35%',
},
     
  {
      title: 'Type',
      dataIndex: 'queryType',
      key: 'queryType',
      width:'35%',
      filterMultiple: false,   
      filters: [
        {
          text: 'PUBLIC_QUERY',
          value: 'PUBLIC_QUERY',
        },
        {
          text: 'IVR_QUERY',
          value: 'IVR_QUERY',
        },
        {
          text: 'MEDIA_QUERY',
          value: 'MEDIA_QUERY',
        },
        {
          text: 'COVID_QUERY',
          value: 'COVID_QUERY',
        },
        {
          text: 'REGISTERED_QUERY',
          value: 'REGISTERED_QUERY',
        }
      ]
      },
      {
        title: 'Agent',
        dataIndex: 'agentName',
        key: 'agentName',
        width:'35%',
        filters: props.global_store.get_agent_list_ret.data.map((item)=>{
              return {
                text:item.name,
                value:item._id
              }
        }),
        render:(item, record) => {
          console.log(props.global_store.get_agent_list_ret.data,"props.global_store")
          return <div>
             <div className="u-align-center">
                    <Select value={record.agentName} style={{ width: 120 }} onChange={(val)=>update_agent_name(val, record)}>
                    {props.global_store.get_agent_list_ret.data.map((item)=>{
              return       <Option disabled={record.agentName===item._id} value={item._id}>{item.name}</Option>
                      })}
                   </Select>
                </div>
          </div>
        }
        // fixed:'left'
    },
    {
      title: 'Service/Speciality',
      dataIndex: 'requestedService',
      key: 'requestedService',
      width:'45%',
          // ...getColumnSearchProps('mobileNumber', "Search phone", 'mobileNumber'),
    },
  
      {
        title: 'Query',
        dataIndex: 'message',
        key: 'message',
        width:'35%',
            // ...getColumnSearchProps('mobileNumber', "Search phone", 'mobileNumber'),
      },
      {
        title: 'facility',
        dataIndex: 'facility',
        key: 'facility',
        width:'35%',
            // ...getColumnSearchProps('mobileNumber', "Search phone", 'mobileNumber'),
      },
      {
        title:'Create At',
        dataIndex:'createdAt',
        key:'createdAt',
        width:'35%',
      },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width:'35%',
      filterMultiple: false,   
      filters: [
        {
          text: 'PENDING',
          value: 'PENDING',
        },
        {
          text: 'HOT_LEAD',
          value: 'HOT_LEAD',
        },
        {
          text: 'COLD_LEAD',
          value: 'COLD_LEAD',
        },
        {
          text: 'CALLBACK_DUE',
          value: 'CALLBACK_DUE',
        },
        {
          text: 'DONE',
          value: 'DONE',
        }
      ],
      render:(item, record) => {
        return <div>
           <div className="u-align-center">
                  <Select value={record.status} style={{ width: 120 }} onChange={(val)=>update_query_status(val, record)}>
                    <Option disabled={record.status==="PENDING"} value="PENDING">PENDING</Option>
                    <Option disabled={record.status==="HOT_LEAD"} value="HOT_LEAD">HOT_LEAD</Option>
                    <Option disabled={record.status==="COLD_LEAD"} value="COLD_LEAD">COLD_LEAD</Option>
                    <Option disabled={record.status==="CALLBACK_DUE"} value="CALLBACK_DUE">CALLBACK_DUE</Option>
                    <Option disabled={record.status==="DONE"} value="DONE">DONE</Option>
                 </Select>
              </div>
        </div>
      }
  },
  {
    title: 'Followup Date',
    dataIndex: 'reminderTime',
    key: 'reminderTime',
    width:'50%',
    ...getColumnSearchProps('reminderTime', "Search reminderTime", 'reminderTime', 'date'),
    render:(text, record) => {
      return get_time(record.reminderTime)
    }
  },
  {
    title: 'Paid',
    dataIndex: 'paymentPaid',
    key: 'paymentPaid',
    width:'35%'
  },
  {
    title: 'Due',
    dataIndex: 'paymentDue',
    key: 'paymentDue',
    width:'35%'
  },
  {
    title: 'Payment',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
    width:'35%',
    render:(item, record) => {
      return <div>
         <div className="u-align-center">
                <Select value={record.paymentStatus} style={{ width: 120 }} onChange={(val)=>update_payment_status(val, record)}>
                  <Option disabled={record.paymentStatus==="PENDING"} value="PENDING">PENDING</Option>
                  <Option disabled={record.paymentStatus==="NOT_REQUESTED"} value="NOT_REQUESTED">NOT_REQUESTED</Option>
                  <Option disabled={record.paymentStatus==="REQUESTED"} value="REQUESTED">REQUESTED</Option>
                  <Option disabled={record.paymentStatus==="DONE"} value="DONE">DONE</Option>
               </Select>
            </div>
      </div>
    },
    // filterMultiple: false,   
    // filters: [
    //   {
    //     text: 'PENDING',
    //     value: 'PENDING',
    //   },
    //   {
    //     text: 'NOT_REQUESTED',
    //     value: 'NOT_REQUESTED',
    //   },
    //   {
    //     text: 'REQUESTED',
    //     value: 'REQUESTED',
    //   },
    //   {
    //     text: 'DONE',
    //     value: 'DONE',
    //   }
    // ],
},
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '35%',
    render: (text,record) => {
        // console.log(record._id)
        return <div className="text-center">
            <CalendarOutlined style={{cursor:'pointer'}} title="Add Reminder" onClick={()=>{
              set_visible(true)
              set_selected_follow_up(record)
              set_update_follow_up_data({
                ...update_follow_up_data,
                note:record.note
              })
            }
              } />

          <div >
          <EditOutlined  style={{cursor:'pointer'}} title="Edit Information" onClick={()=>{
              set_edit_visible(true)
              set_selected_follow_up(record)
              set_edit_follow_up_data({
                treatment:record.treatment==="N/A"?"":record.treatment,
                address:record.address==="N/A"?"":record.address,
                note:record.note==="N/A"?"":record.note,
                amount_paid:record.paymentPaid==="N/A"?"":record.paymentPaid,
                amount_remaining:record.paymentDue==="N/A"?"":record.paymentDue,
                facility:record.facility==="NOT_DEFINED"?"":record.facility
              })
            }     
              }  
          />
        <div className="text-center">
          <FormOutlined  style={{cursor:'pointer'}} title="Messages" onClick={()=>{
                set_selected_follow_up(record)
                set_message_modal(true)
              }
                }  
            />  
        </div>
          </div>
        </div>
    }
}
  ]


const  handleCancel = e => {
  console.log("Inside handleCancel")
  console.log(e);
  set_visible(false)
  set_update_follow_up_data({
    date:update_follow_up_data.date,
    time:'',
    note:''
  })
  set_update_follow_up_error({
    date:{ state:false, text:"" },
    time:{ state:false, text:"" },
    note:{state:false, text:''}
  })
}


const  edit_handleCancel = e => {
  console.log("Inside handleCancel")
  console.log(e);
  set_edit_visible(false)
  set_edit_follow_up_data({
    address:'',
    treatment:'',
    note:'',
    amount_paid:'',
    amount_remaining:'',
    facility:''
  })
  set_edit_follow_up_error({
    address:{ state:false, text:"" },
    treatment:{ state:false, text:"" },
    note:{ state:false, text:"" },
    amount_paid:{ state:false, text:"" },
    amount_remaining:{ state:false, text:"" },
    facility:{ state:false, text:"" }
  })
}

const submit = () => {
  console.log(update_follow_up_data,"update_follow_up_data")
    try {
      if(update_follow_up_data.time===0  || update_follow_up_data.date===""){
        set_error({...error, date:{state:true, text:'Select time to set reminder'}})
        throw new Error("Select time to set reminder")
      }

      if(false){
        set_error({...error, node:{state:true, text:'Enter Note'}})
      
      }else {
        props.update_follow_up({
          queryId:selected_follow_up._id,
          queryType:selected_follow_up.queryType,
          timeSlot: update_follow_up_data.time*1000,
          date: update_follow_up_data.date,
          note:update_follow_up_data.note,
          status:selected_follow_up.status,
          type:'add_reminder'
        })
      }
    } catch (error) {
      console.log(error)
      props.global_error({
        success:false,
        message:error.message,
        heading:"Set Reminder",
      })
    }
  
}

const edit_submit = () => {
  console.log(edit_follow_up_data,"edit_follow_up_data")
    try {
      // if(update_follow_up_data.address===0  || update_follow_up_data.date===""){
      //   set_error({...error, date:{state:true, text:'Select time to set reminder'}})
      //   throw new Error("Select time to set reminder")
      // }

      if(false){
        set_error({...error, node:{state:true, text:'Enter Note'}})
      
      }else {
        props.update_follow_up({
          queryId:selected_follow_up._id,
          queryType:selected_follow_up.queryType,
          address:edit_follow_up_data.address,
          treatment:edit_follow_up_data.treatment,
          note:edit_follow_up_data.note,
          paymentPaid:edit_follow_up_data.amount_paid,
          paymentDue:edit_follow_up_data.amount_remaining,
          facility:edit_follow_up_data.facility,
          type:'edit_info'
        })
      }
    } catch (error) {
      console.log(error)
      props.global_error({
        success:false,
        message:error.message,
        heading:"Set Reminder",
      })
    }
  
}

const expand = (record,a,b) => {
  console.log(record,a,b,"record,a,b")
  set_note(a.note)
  if(expandedRowKeys[0]!== a._id){
     set_expandedRowKeys([a._id])
    //  console.log(a,"a.varianceInfo")
    //  set_variance_info(a.varianceInfo)
  }else{
  set_expandedRowKeys([])
  }
}

const send_sms = (data) => {
  console.log(data,"data in send_sms")
  set_sms_select({
    queryId:data.queryId,
    message:data.sentMessage,
    sentMessage:data.sentMessage
  })
  props.update_follow_up({
    queryId:data.queryId,
    sentMessage:data.sentMessage,
    type:'send_sms'
  })
}

const message_submit = () => {

}

const message_handleCancel = () => {
      set_message_modal(false)
}


  console.log(selected_follow_up,"selected_follow_up")
  console.log(reminderTime_state,"reminderTime_state in Previous Followups")
      function showTotal(total) {
        return `Total ${total} items`;
      }

   if(!!professional_selected){
        return <Redirect to={`/dashboard/catalogue-manager?catalogue_id=${professional_selected._id}`} />
   }

        return (<React.Fragment>
            <Card>
       
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_follow_ups_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          scroll={{ x: 2000 }}
         
          rowKey={record => record._id}
          expandedRowRender= {record =>{
            console.log(record,"record")
            return <React.Fragment>
                              <div className="flex-child-1-new">
                              <span className="flex-child-1-new">
                                    <AntdInput label="Note"  
                                  value={note} 
                                  error_text={note_error.note.text} 
                                  loading = {props.edit_follow_up_loading_flag}
                                  disabled = {props.edit_follow_up_loading_flag} 
                                  toggle_error = {()=>set_note_error({...error, note:{text:'',state:false}})} 
                                  error={note_error.note.state} 
                                  name="note" 
                                  onChange = {event=>set_note(event.target.value)} 
                                  placeholder="Enter note"
                                  type="textarea"
                                  rows= {10}
                                    /> 
                </span>
                              </div>
                              <div className="text-center">
                                <Button onClick={()=>update_note_value(record)}>Submit</Button>
                              </div>

      </React.Fragment>
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                onExpand = {(a,b,c) =>expand(a,b,c)}
                pagination = {{
                  showSizeChanger:true,
                  limit:get_follow_ups_params.limit,
                  onChange:(a,c)=>page_change(a,c),
                  defaultCurrent:get_follow_ups_params.page,
                  total:get_follow_ups_params.total,
                  showTotal:showTotal
                }}
          />


        <Modal
          title="Set Reminder"
          visible={visible}
          onOk={submit}
          onCancel={handleCancel}
          width={600}
        >
           {visible && <div className="flex-parent-new u-margin-top-small">
                <span className="flex-child-1-new">
                <div className="site-calendar-demo-card">
                    <Calendar fullscreen={false}
                     onPanelChange={(e,r)=>console.log(e, r ,"e, r in calendat")}
                     onSelect={(val,e)=>{
                      console.log(val,e,"val in Calendar")
                      console.log( val.unix())
                        set_update_follow_up_data({
                          ...update_follow_up_data, date:val.unix()
                        })
                     }}  
                     />
                  </div>
                </span>
                <span className="flex-child-1-new">
                     <div className="">
                     <TimePicker onChange={(val,r)=>{
                        set_update_follow_up_data({
                          ...update_follow_up_data, time:val.unix()
                         })
                     }} />
                     <div className="u-margin-top-small">
                        <AntdInput label="Note"  
                        value={update_follow_up_data.note} 
                        error_text={update_follow_up_error.note.text} 
                        loading = {props.send_sms_loading_flag}
                        disabled = {props.send_sms_loading_flag} 
                        toggle_error = {()=>set_update_follow_up_error({...error, note:{text:'',state:false}})} 
                        error={update_follow_up_error.note.state} 
                        name="note" 
                        onChange = {event=>set_update_follow_up_data({...update_follow_up_data,[event.target.name]:event.target.value})} 
                        placeholder="Enter note"
                        type="textarea"
                        rows= {10}
                        /> 
                     </div>
                     </div>
                </span>
          </div>}
          <div className="text-center">
          </div>
        </Modal>

        <Modal
          title="Messages"
          visible={message_modal}
          footer={null}
          onCancel={message_handleCancel}
          width={800}
        >
          {message_modal &&   <div key={selected_follow_up._id}>
                <SendSms
                    send_sms = {send_sms}
                    type="public"
                    sms = {selected_follow_up.sentMessages}
                    queryId={selected_follow_up._id}
                    send_sms_loading_flag = {props.send_sms_loading_flag}
                />
              </div> }
        </Modal>


        <Modal
          title="Edit Followup Informamtion"
          visible={edit_visible}
          onOk={edit_submit}
          onCancel={edit_handleCancel}
          width={600}
        >
           {edit_visible &&
           <React.Fragment>
           <div className="flex-parent-new u-margin-top-small">
                <span className="flex-child-1-new">
                    <AntdInput label="Address"  
                            value={edit_follow_up_data.address} 
                            error_text={edit_follow_up_error.address.text} 
                            loading = {props.edit_follow_up_loading_flag}
                            disabled = {props.edit_follow_up_loading_flag} 
                            toggle_error = {()=>set_edit_follow_up_error({...error, address:{text:'',state:false}})} 
                            error={edit_follow_up_error.address.state} 
                            name="address" 
                            onChange = {event=>set_edit_follow_up_data({...edit_follow_up_data,[event.target.name]:event.target.value})} 
                            placeholder="Enter address"
                            type="textarea"
                            rows= {5}
                            /> 
                 </span>
                <span className="flex-child-1-new">
                        <AntdInput label="Treatment"  
                       value={edit_follow_up_data.treatment} 
                       error_text={edit_follow_up_error.treatment.text} 
                       loading = {props.edit_follow_up_loading_flag}
                       disabled = {props.edit_follow_up_loading_flag} 
                       toggle_error = {()=>set_edit_follow_up_error({...error, treatment:{text:'',state:false}})} 
                       error={edit_follow_up_error.treatment.state} 
                       name="treatment" 
                       onChange = {event=>set_edit_follow_up_data({...edit_follow_up_data,[event.target.name]:event.target.value})} 
                       placeholder="Enter treatment"
                       type="textarea"
                       rows= {5}
                        /> 
                </span>
              
                </div>
                <div className="flex-parent-new u-margin-top-small">
                <span className="flex-child-1-new">
                        <AntdInput label="Note"  
                       value={edit_follow_up_data.note} 
                       error_text={edit_follow_up_error.note.text} 
                       loading = {props.edit_follow_up_loading_flag}
                       disabled = {props.edit_follow_up_loading_flag} 
                       toggle_error = {()=>set_edit_follow_up_error({...error, note:{text:'',state:false}})} 
                       error={edit_follow_up_error.note.state} 
                       name="note" 
                       onChange = {event=>set_edit_follow_up_data({...edit_follow_up_data,[event.target.name]:event.target.value})} 
                       placeholder="Enter note"
                       type="textarea"
                       rows= {5}
                        /> 
                </span>
                <span className="flex-child-1-new">
                              <AntdInput label="Amount Paid"  
                      value={edit_follow_up_data.amount_paid} 
                      error_text={edit_follow_up_error.amount_paid.text}
                      loading = {props.send_business_reports_loading_flag}
                      disabled = {props.send_business_reports_loading_flag} 
                      toggle_error = {()=>set_edit_follow_up_error({...edit_follow_up_error, amount_paid:{text:'',state:false}})} 
                      error={edit_follow_up_error.amount_paid.state} 
                      name="amount_paid" 
                      onChange = {event=>set_edit_follow_up_data({...edit_follow_up_data,[event.target.name]:event.target.value})} 
                      placeholder="Enter value" 
                      validation = "positive_integer"
                  />
                     <AntdInput label="Amount Due"  
                        value={edit_follow_up_data.amount_remaining} 
                        error_text={edit_follow_up_error.amount_remaining.text}
                        loading = {props.send_business_reports_loading_flag}
                        disabled = {props.send_business_reports_loading_flag} 
                        toggle_error = {()=>set_edit_follow_up_error({...edit_follow_up_error, amount_remaining:{text:'',state:false}})} 
                        error={edit_follow_up_error.amount_remaining.state} 
                        name="amount_remaining" 
                        onChange = {event=>set_edit_follow_up_data({...edit_follow_up_data,[event.target.name]:event.target.value})} 
                        placeholder="Enter value" 
                        validation = "positive_integer"
                    />
                   
                        <AntdInput label="Facility"  
                       value={edit_follow_up_data.facility} 
                       error_text={edit_follow_up_error.facility.text} 
                       loading = {props.edit_follow_up_loading_flag}
                       disabled = {props.edit_follow_up_loading_flag} 
                       toggle_error = {()=>set_edit_follow_up_error({...error, facility:{text:'',state:false}})} 
                       error={edit_follow_up_error.facility.state} 
                       name="facility" 
                       onChange = {event=>set_edit_follow_up_data({...edit_follow_up_data,[event.target.name]:event.target.value})} 
                       placeholder="Enter Facility"
                       type="text"
                      
                        /> 
             
                </span>
                </div>
          </React.Fragment>}
          <div className="text-center">
          </div>
        </Modal>
   


        </Card>
        </React.Fragment>)
}
export default PreviousFollowups