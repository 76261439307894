import { call, put, takeLatest } from 'redux-saga/effects'
import { CHECK_FOLLOW_UP, UPDATE_FOLLOW_UP , GET_FOLLOW_UPS  } from '../Constants'
import {  check_follow_up_ret, get_follow_ups_ret, update_follow_up_ret } from '../actions/follow_up'
import api from '../utils/api_routes'
import store from '../store'

function* get_follow_ups_saga() {

  try {
     const  data = yield store.getState().follow_up_store.get_follow_ups
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")

      let type = data.type
      if(!!type){
        delete data.type
      }

      // const api_data = yield call(api.follow_up.get_follow_ups, data, headers)
      let api_data
      if(type==="your_followups"){
        api_data = yield call(api.follow_up.your_follow_ups, data, headers)
      }else {
        api_data = yield call(api.follow_up.get_follow_ups, data, headers)
      }
     
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(get_follow_ups_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(get_follow_ups_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(get_follow_ups_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(get_follow_ups_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }

 function* check_follow_up_saga() {

  try {
     const  data = yield store.getState().follow_up_store.check_follow_up
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")

      let type = data.type
      if(!!type){
        delete data.type
      }

      const api_data = yield call(api.follow_up.check_follow_up, data, headers)
    //   let api_data
    //   if(type==="refund_booking"){
    //     api_data = yield call(api.bookings.check_follow_up, data, headers)
    //   }else {
    //     api_data = yield call(api.bookings.update_global_booking, data, headers)
    //   }
     
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(check_follow_up_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data,
               count:api_data.count
              }))
       }else{
           yield put(check_follow_up_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(check_follow_up_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(check_follow_up_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }


 function* update_follow_up_saga() {

  try {
     const  data = yield store.getState().follow_up_store.update_follow_up
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      console.log(data,"data in get_bookings")

      let type = data.type
      if(!!type){
        delete data.type
      }
        let  api_data
     

      if(type === "add_reminder"){
        api_data = yield call(api.follow_up.add_reminder, data, headers)
      }else if(type === "edit_info"){
        api_data = yield call(api.follow_up.edit_follow_up, data, headers)
      }else if(type === "update_agent_name"){
        api_data = yield call(api.follow_up.update_agent_name, data, headers)
      }else if(type === "update_status_incomeGroup"){
        api_data = yield call(api.follow_up.update_status_incomeGroup, data, headers)
      }else if(type === "update_status_mop"){
        api_data = yield call(api.follow_up.update_status_mop, data, headers)
      }else if(type === "update_status_stage"){
        api_data = yield call(api.follow_up.update_status_stage, data, headers)
      }else if(type==="send_sms"){
        console.log(data,"data in saga")
        let id = data.queryId
        if(id){
          delete data.queryId
        }
          api_data = yield call(api.follow_up.send_sms, data, id, headers)

          api_data.type = "send_sms"
      }
      else {
        api_data = yield call(api.follow_up.update_follow_up, data, headers)
      }

     
    //   let api_data
    //   if(type==="refund_booking"){
    //     api_data = yield call(api.bookings.check_follow_up, data, headers)
    //   }else {
    //     api_data = yield call(api.bookings.update_global_booking, data, headers)
    //   }
     
         console.log(api_data,"api_data in check_number_saga")
     if(!!api_data){
        if(!!api_data.success || !!api_data.sucess){
           yield put(update_follow_up_ret({
               success:true,
               message:api_data.msg ||  api_data.message || 'Something went wrong try again later..',
               data:api_data.data
              }))
       }else{
           yield put(update_follow_up_ret({
               success:false,
               message:api_data.message || api_data.msg ||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(update_follow_up_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(update_follow_up_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
 }


export const follow_up_saga = [
   takeLatest(CHECK_FOLLOW_UP, check_follow_up_saga),
   takeLatest(UPDATE_FOLLOW_UP, update_follow_up_saga),
   takeLatest(GET_FOLLOW_UPS, get_follow_ups_saga)
]

export default follow_up_saga

