import React, { useState } from 'react'
import {
    Row,
    Col,
    Button,
    Card
  } from "antd";

function TimeSlots(props) {

    const [day, setDay] = useState(-1)

    return (
        <div className="funnel-timeslots-container">
            <Row justify="center">
                {props.timeSlots.map((timeslot, index) => (
                    !timeslot.closed && <Col key={index}><Button className="day-selector-btn" onClick={() => setDay(index)}>{timeslot.day.slice(0,3)}</Button></Col>
                    )
                )}
            </Row>
            {day > -1 && <Card>
                <Row className="fade-in" justify="center">
                    {props.timeSlots[day].slotArray.map((slot, index) => (
                        <Col span={6} key={index}><Button className="time-slot-item">{slot}</Button></Col>
                    ))}
                </Row>
            </Card>}
        </div>
    )
}

export default TimeSlots
