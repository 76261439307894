import   { GET_MEMBERS, GET_MEMBERS_RET, GET_MEMBERS_LOADING, SET_MEMBERS_DATA, ADD_MEMBER, ADD_MEMBER_RET, ADD_MEMBER_LOADING } from "../Constants"

export const get_members = (data) => {
    return {
        type:GET_MEMBERS,
        data
    }
}

export const get_members_ret = (data) => {
    return {
        type:GET_MEMBERS_RET,
        data
    }
}

export const get_members_loading = (data) => {
    return {
        type:GET_MEMBERS_LOADING,
        data
    }
}

export const add_member = (data) => {
    return {
        type:ADD_MEMBER,
        data
    }
}
export const add_member_ret = (data) => {
    return {
        type:ADD_MEMBER_RET,
        data
    }
}
export const add_member_loading = (data) => {
    return {
        type:ADD_MEMBER_LOADING,
        data
    }
}

export const set_members_data = (data) => {
    return {
        type:SET_MEMBERS_DATA,
        data
    }
}