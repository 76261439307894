import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Select from "react-select"
import Axios from 'axios';
import { Input, DatePicker, Card, Row, Col, Spin, notification } from 'antd';
import dayjs from 'dayjs';
import { get_url, isEmpty } from '../../utils/common_utilities';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import AntdInput from '../../Components/AntdInput';
import { LoadingOutlined } from '@ant-design/icons';


function CreateBooking(props) {

    const dateFormat = 'DD-MMM-YYYY';
    const [insuranceList, setInsuranceList] = useState([])
    const [reportImages, setReportImages] = useState([])
    const [insuranceImages, setInsuranceImages] = useState([])
    const [insurancePdf, setInsurancePdf] = useState("")
    const [reportPdfs, setReportPdfs] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, set_message] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const [userDetails, setUserDetails] = useState({
        userID: "",
        mobileNumber: "",
        name: "",
        address: "",
        emailId: "",
        gender: "",
        dob: "",
        speciality: "",
        service: "",
        hospitalId: "",
        doctors: [],
        treatmentDetails: "",
    })

    const [error, set_error] = React.useState({
        speciality: { state: false, text: "" },
        service: { state: false, text: "" },
        doctor: { state: false, text: "" },
        amount: { state: false, text: "" },
        date_string: { state: false, text: "" },
        slot: { state: false, text: "" },
      });

    const [policyDetails, setPolicyDetails] = useState({
        policyNumner: "",
        insurancePartner: "",
        ipNumber: "",
        cardNumber: "",
        doa: "",
        costEstimation: "",
        dod: "",
        roomRent: "",
        iCURent: "",
        nursingCharges: "",
        superSpecialityCharges: "",
        cvc: "",
        soa: "",
        others: "",
        remarks: ""
    })

    const getInsuranceList = async () => {
        try {
            const insuranceList = await Axios.get(`${get_url()}/insurance`)
            let iArray = [ {
                label: "Cash",
                value: "Cash"
            }]
            if(insuranceList.data.success)
                insuranceList.data.data.map((insurance) => {
                    iArray.push({
                        label: insurance.insurancePartner,
                        value: insurance.insurancePartner
                    })
                })
            setInsuranceList(iArray)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getInsuranceList()
    }, [])
    
    useEffect(() => {
        return () => {
            setInsuranceList(null)
        }
    }, [])

    const uploadDocs = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        for (var i = 0; i < e.target.files.length; i++) {
            formData.append('file', e.target.files[i])
        }
        props.upload_patient_report({
            files: formData,
            type: "REPORT"
        })
    }

    const uploadInsuranceDoc = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        props.upload_patient_insurance({
            files: formData,
            type: "POLICY"
        })
    }

    const uploadReportImages = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()

        for (var i = 0; i < e.target.files.length; i++) {
            formData.append('file', e.target.files[i])
        }
        props.upload_patient_report({
            files: formData,
            type: "IMAGE"
        })
    }

    const uploadInsuranceImages = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()

        for (var i = 0; i < e.target.files.length; i++) {
            formData.append('file', e.target.files[i])
        }
        props.upload_patient_insurance({
            files: formData,
            type: "CARD"
        })
    }

    useEffect(() => {
        if(!isEmpty(props.user)) {
            const user = props.user.data
            setUserDetails({
                ...userDetails,
                userID: user._id,
                mobileNumber: user.mobileNumber,
                name: user.name,
                address: user.address,
                emailId: user.email,
                gender: user.gender,
                dob: user.birthDate
            })
        }
    }, [props.user])

    useEffect(()=>{
        if(!!props.upload_patient_report_ret){
                if(props.upload_patient_report_ret.success){
                if(props.upload_patient_report_ret.data.reportType === "IMAGE") {
                    let imageURLS = []
                    props.upload_patient_report_ret.data.reports.map((report) => {
                        imageURLS.push(report)
                    })
                    setReportImages(imageURLS)
                } else {
                    let pdfURLS = []
                    props.upload_patient_report_ret.data.reports.map((report) => {
                        pdfURLS.push(report)
                    })
                    setReportPdfs(pdfURLS)  
                }
            } else {
                set_message({
                    message: "Upload Failed",
                    success:false
                })
            }
        }
        setLoading(false)
        props.upload_patient_report_loading()

    },[props.upload_patient_report_ret])

    useEffect(()=>{
        if(!!props.upload_patient_insurance_ret){
                if(props.upload_patient_insurance_ret.success){
                if(props.upload_patient_insurance_ret.data.reportType === "INSURANCE_CARD") {
                    let imageURLS = []
                    props.upload_patient_insurance_ret.data.reports.map((report) => {
                        imageURLS.push(report.url)
                    })
                    setInsuranceImages(imageURLS)
                } else {
                    setInsurancePdf(props.upload_patient_insurance_ret.data.reports.url)  
                }
            } else {
                set_message({
                    message: "Upload Failed",
                    success:false
                })
            }
        }
        setLoading(false)
        props.upload_patient_insurance_loading()

    },[props.upload_patient_insurance_ret])

    useEffect(() => {
        if (props.get_doctors_ret) {
          if (props.get_doctors_ret.success) {
            setUserDetails({
              ...userDetails,
              doctors: [...props.get_doctors_ret.data].map((item) => {
                return {
                  ...item,
                  name: item.name,
                  value: item._id,
                  hospitalId: item.hospitalId,
                  doctors: item.doctors,
                  isHospitalDoctor: item.isHospitalDoctor,
                };
              }),
            });
          } else {
            props.global_error({
              success: false,
              message: props.get_doctors_ret.message,
              heading: "No Doctor Available",
            });
          }
          props.get_doctors_loading();
        }
      }, [props.get_doctors_ret]);

    const getDateString = (date) => {
        let ts = date.split("-")
        ts = ts[0] + "-" + ts[1].charAt(0).toUpperCase() + ts[1].charAt(1).toLowerCase() + ts[1].charAt(2).toLowerCase() + "-" + ts[2]
        return ts
    }

    const submit = async () => {

        let flag = true
        if(userDetails.name === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Name cannot be empty",
            })
        }
        else if(userDetails.mobileNumber === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Mobile Number cannot be empty",
            })
        }
        else if(userDetails.address === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Address cannot be empty",
            })
        }
        else if(userDetails.emailId === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Email cannot be empty",
            })
        }
        else if(userDetails.dob === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Date of Birth cannot be empty",
            })
        }
        else if(userDetails.gender === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Please select Gender",
            })
        }

        else if(policyDetails.policyNumner === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Policy Number cannot be empty",
            })
        }

        else if(policyDetails.insurancePartner === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Please select Insurance",
            })
        }

        else if(policyDetails.ipNumber === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "IP Number cannot be empty",
            })
        }

        else if(policyDetails.cardNumber === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Card Number cannot be empty",
            })
        }

        else if(policyDetails.doa === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Date of arrival cannot be empty",
            })
        }

        else if(policyDetails.dod === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Date of Discharge cannot be empty",
            })
        }

        else if(policyDetails.costEstimation === "") {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Cost Estimation cannot be empty",
            })
        }

        else if(policyDetails.costEstimation < 0) {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description: "Cost Estimation cannot be negetive",
            })
        }

        else if(dayjs(policyDetails.dod).isBefore(dayjs(policyDetails.doa))) {
            flag = false
            notification["error"]({
                message: 'Invalid Data',
                description:'Date of Arrival Cannot be before Date of Discharge',
            })
        }

        if(flag) {
            setLoading(true)
            let verifiedUser = await getUserDetails()
            let reportData = await createUserReport(verifiedUser)
            createBooking(reportData.data.data)
        }
    }

    const getUserDetails = async () => {
        const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
        let obj =  {
            name: userDetails.name,
            mobileNumber: userDetails.mobileNumber,
            userType: "User",
            email: userDetails.emailId,
            fromPanel:true,
            gender:userDetails.gender,
            dob:userDetails.birthDate,
            address:userDetails.address
        }
        try {
            const userData = await Axios.post(`${get_url()}/booking/validateUser`, obj, headers)
            return userData.data.data
        } catch (error) {
            notification["error"]({
                message: 'Error',
                description: "An Error Occurred!! Please try again later.",
            })
        }
    }

    const createUserReport = async (user) => {
        const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
        const report = {
            userId: userDetails.userID,
            imageUrls: reportImages,
            reportUrls: reportPdfs,
            description: userDetails.treatmentDetails,
            additionalDetail: policyDetails.remarks,
            insurance: policyDetails.insurancePartner !== "Cash" ? true : false
        }

        try {
            const reportData = await Axios.post(`${get_url()}/booking/submitReport`, report, headers)
            return reportData
        } catch (error) {
            notification["error"]({
                message: 'Error',
                description: "An Error Occurred!! Please try again later.",
            })
        }
    }

    const createBooking = async (reportData) => {
        const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
        const booking = {
            professionalId: userDetails.doctor,
            serviceId: userDetails.service,
            date: policyDetails.doa,
            timeSlot: "9:00 AM",
            bookingStatus: "Requested",
            creditsUsed: false,
            doctorId: null,
            paymentPercent: 100,
            price: policyDetails.costEstimation,
            userId: reportData.userId,
            userReportId: reportData._id, 
            ipNumber: policyDetails.ipNumber,
            cardNumber: policyDetails.cardNumber,
            doa: policyDetails.doa,
            dod: policyDetails.dod,
            paymentDetails: {
                roomRent: policyDetails.roomRent,
                iCURent: policyDetails.iCURent,
                nursingCharges: policyDetails.nursingCharges,
                superSpecialityCharges: policyDetails.superSpecialityCharges,
                cvc: policyDetails.cvc,
                soa: policyDetails.soa,
                others: policyDetails.others,
            },
        }
        if(policyDetails.insurancePartner !== "Cash") {
            booking.haveInsurance = true
            booking.insuranceDetails = {
                policyNumber: policyDetails.policyNumner,
                insuranceCard: insuranceImages,
                insurancePolicy: insurancePdf,
                insurancePartner: policyDetails.insurancePartner,
            }
        }
        try {
            let bookingResult = await Axios.post("${get_url()}/booking/createManualInsuranceBooking", booking, headers)
            if(bookingResult.data.success === true) {
                notification["success"]({
                    message: 'Success',
                    description: "Booking Created Successfully",
                })
                setLoading(false)
                return <Redirect to="/dashboard/insurance-bookings?check-user" />
            }
        } catch (error) {
            notification["error"]({
                message: 'Error',
                description: "An Error Occurred!! Please try again later.",
            })
        }

        setLoading(false)
        
    }


    const speciality_change = (val) => {
        props.get_services({ speciality: val });
        setUserDetails({
            ...userDetails,
            speciality: val,
            service: "",
        });
    };

    const select_doctor = (id) => {
        let doctor = userDetails.doctors.filter((item) => item.value === id)[0];
        console.log(doctor, "doctor in time slot");
        setUserDetails({
            ...userDetails,
            doctor: id,
        });
    };


    if (isEmpty(props.user)) {
        return <Redirect to="/dashboard/insurance-bookings?check-user" />;
    }

    return (
            <React.Fragment>
            <Card>
            {(loading || props.upload_patient_insurance_loading_flag || props.upload_patient_report_ret_flag) && <Spin tip="Loading" size="large"><div className="content" /></Spin>}
                <div>
                    <div>
                        <div>
                            <div className="add-user-section">
                                <div className="insurance-booking-session">
                                    <Row>
                                        <Col className="details-box" span={12}>
                                            <h4>User Details</h4>
                                            <div className="user-details-box">
                                                <div className="container">
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Mobile No</label>
                                                            <AntdInput value={userDetails.mobileNumber} disabled={true} onChange={(e)=> setUserDetails({...userDetails, mobileNumber:e.target.value})} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Patient Name</label>
                                                            <AntdInput value={userDetails.name} onChange={(e)=> setUserDetails({...userDetails, name:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-12">
                                                            <label>Address</label>
                                                            <AntdInput value={userDetails.address} onChange={(e)=> setUserDetails({...userDetails, address:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-12">
                                                            <label>Email Id</label>
                                                            <AntdInput value={userDetails.emailId} onChange={(e)=> setUserDetails({...userDetails, emailId:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Gender</label>
                                                                <Select
                                                                    options = {[
                                                                        {
                                                                            label:'Male',
                                                                            value:'M'
                                                                        },
                                                                        {
                                                                            label:'Female',
                                                                            value:'F'
                                                                        },
                                                                        {
                                                                            label:'Other',
                                                                            value:'O'
                                                                        },
                                                                    ]}
                                                                    onChange={(e)=> {
                                                                        setUserDetails({...userDetails, gender:e.value})
                                                                    }
                                                                    }
                                                                    isMulti ={false}
                                                                    value={userDetails.gender === "M" ? {label:'Male',value:'M'} : userDetails.gender === "F" ? {label:'Female',value:'F'} : {label:'Other',value:'O'}}
                                                                    blurInputOnSelect={true}
                                                                    closeMenuOnSelect={true}
                                                                    className="booking-dropdown-container"
                                                                    classNamePrefix="booking-dropdown"
                                                                    // value = {data.addon}
                                                                    label = "Gender" />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>D.O.B</label>
                                                            <DatePicker 
                                                                defaultValue={userDetails.dob && dayjs(getDateString(userDetails.dob), dateFormat)}
                                                                className="dob-date-picker"
                                                                disabledDate={(current) => current.isAfter(dayjs(dayjs(), dateFormat))} 
                                                                onChange={(date) => setUserDetails({...userDetails, dob: date.format(dateFormat)})} 
                                                                format={dateFormat}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-12">
                                                            <label>Treatment Details</label>
                                                            <AntdInput type="textarea" onChange={(e)=> setUserDetails({...userDetails, treatmentDetails:e.target.value})}  rows={5} />
                                                        </div>
                                                    </div>
                                                    <div className="row upload-docs-container">
                                                        <div className="col-12">
                                                            <label className="upload-docs-link">Attach Reports and Prescriptions</label><i className="fas fa-paperclip"></i>
                                                            <div className="row insurance-booking-session">
                                                                <div className="col-6">
                                                                    <label>Upload Images</label>
                                                                    <Input type="file" accept="image/png, image/gif, image/jpeg" multiple onChange={(e) => uploadReportImages(e)}  placeholder="Click To Upload" />
                                                                </div>
                                                                <div className="col-6">
                                                                    <label>Upload Pdfs</label>
                                                                    <Input type="file" accept="application/pdf" multiple onChange={(e) => uploadDocs(e)} placeholder="Click To Upload" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Speciality</label>
                                                            <AntdInput
                                                                value={userDetails.speciality}
                                                                disabled={props.get_bids_loading_flag}
                                                                options={props.global_store.specialities}
                                                                loading={props.global_store.get_specialities_loading}
                                                                error_text={error.speciality.text}
                                                                toggle_error={() =>
                                                                set_error({
                                                                    ...error,
                                                                    speciality: { text: "", state: false },
                                                                })
                                                                }
                                                                error={error.speciality.state}
                                                                name="speciality"
                                                                type="select"
                                                                onChange={(event) => speciality_change(event)}
                                                                placeholder="Choose speciality"
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Service</label>
                                                            <AntdInput
                                                                value={userDetails.service}
                                                                options={props.global_store.services}
                                                                loading={props.global_store.get_services_loading}
                                                                disabled={props.get_bids_loading_flag}
                                                                error_text={error.service.text}
                                                                toggle_error={() =>
                                                                set_error({
                                                                    ...error,
                                                                    service: { text: "", state: false },
                                                                })
                                                                }
                                                                error={error.service.state}
                                                                name="service"
                                                                type="select"
                                                                onChange={(event) => {
                                                                setUserDetails({
                                                                    ...userDetails,
                                                                    doctor: "",
                                                                    service: event,
                                                                });
                                                                props.get_doctors({
                                                                    specialityId: userDetails.speciality,
                                                                    serviceId: event,
                                                                });
                                                                }}
                                                                placeholder="Choose service"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row insurance-booking-session">
                                                        <div className="col-12">
                                                        <label>Hospital/Doctor/Labs</label>
                                                        <AntdInput
                                                            value={userDetails.doctor}
                                                            options={userDetails.doctors}
                                                            loading={props.get_doctors_loading_flag}
                                                            disabled={props.get_doctors_loading_flag}
                                                            error_text={error.doctor.text}
                                                            toggle_error={() =>
                                                            set_error({
                                                                ...error,
                                                                doctor: { text: "", state: false },
                                                            })
                                                            }
                                                            error={error.doctor.state}
                                                            name="doctor"
                                                            type="select"
                                                            onChange={(event) => {
                                                            console.log(event, "event in doctors dropdown");
                                                            select_doctor(event);
                                                            // set_data({...data,"service":event})
                                                            // props.get_doctors({
                                                            //     specialityId:data.speciality,
                                                            //     serviceId:event
                                                            // })
                                                            }}
                                                            placeholder="Choose doctor"
                                                        />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="details-box" span={12}>
                                            <h4>Policy Details</h4>
                                            <div className="policy-details-box">
                                                <div className="container">
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Policy No</label>
                                                            <AntdInput onChange={(e)=> setPolicyDetails({...policyDetails, policyNumner:e.target.value})} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Insurance Partner/Cash</label>
                                                            <Select
                                                                options = {insuranceList}
                                                                onChange={(e)=> setPolicyDetails({...policyDetails, insurancePartner:e.value})}
                                                                isMulti ={false}
                                                                blurInputOnSelect={true}
                                                                closeMenuOnSelect={true}
                                                                className="booking-dropdown-container"
                                                                classNamePrefix="booking-dropdown"
                                                                // value = {data.addon}
                                                                label = "Gender"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-12">
                                                            <label>IP Number</label>
                                                            <AntdInput onChange={(e)=> setPolicyDetails({...policyDetails, ipNumber:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row upload-docs-container">
                                                        <div className="col-12">
                                                            <label className="upload-docs-link">Attached Insurance Card & KYC</label><i className="fas fa-paperclip"></i>
                                                            <div className="row insurance-booking-session">
                                                                <div className="col-6">
                                                                    <label>Upload Images</label>
                                                                    <Input type="file" accept="image/png, image/gif, image/jpeg" multiple onChange={(e) => uploadInsuranceImages(e)}  placeholder="Click To Upload" />
                                                                </div>
                                                                <div className="col-6">
                                                                    <label>Upload Policy</label>
                                                                    <Input type="file" accept="application/pdf" onChange={(e) => uploadInsuranceDoc(e)} placeholder="Click To Upload" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Card Number</label>
                                                            <AntdInput onChange={(e)=> setPolicyDetails({...policyDetails, cardNumber:e.target.value})} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Expected DOA</label>
                                                            <DatePicker 
                                                                className="dob-date-picker"
                                                                disabledDate={(current) => current.isBefore(dayjs(dayjs(), dateFormat))}
                                                                onChange={(date) => setPolicyDetails({...policyDetails, doa: date.format(dateFormat)})} 
                                                                format={dateFormat}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Cost Estimation (Rs)</label>
                                                            <AntdInput type='number' onChange={(e)=> setPolicyDetails({...policyDetails, costEstimation:e.target.value})} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Date of Discharge</label>
                                                            <DatePicker 
                                                                className="dob-date-picker"
                                                                disabledDate={(current) => {
                                                                    return policyDetails.doa ?
                                                                        current.isBefore(dayjs(getDateString(policyDetails.doa), dateFormat))
                                                                    : current.isBefore(dayjs(dayjs(), dateFormat))
                                                                }}
                                                                onChange={(date) => setPolicyDetails({...policyDetails, dod: date.format(dateFormat)})}
                                                                format={dateFormat}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Room Rent</label>
                                                            <AntdInput type="number" onChange={(e)=> setPolicyDetails({...policyDetails, roomRent:e.target.value})} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>ICU Rent</label>
                                                            <AntdInput type="number" onChange={(e)=> setPolicyDetails({...policyDetails, iCURent:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Nursing Charges</label>
                                                            <AntdInput type="number" onChange={(e)=> setPolicyDetails({...policyDetails, nursingCharges:e.target.value})} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Consultant Visit Charges</label>
                                                            <AntdInput type="number" onChange={(e)=> setPolicyDetails({...policyDetails, cvc:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-6">
                                                            <label>Super Specialist Charges</label>
                                                            <AntdInput type="number" onChange={(e)=> setPolicyDetails({...policyDetails, superSpecialityCharges:e.target.value})} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Surgeon Fee/OT/ Anaestheist</label>
                                                            <AntdInput type="number" onChange={(e)=> setPolicyDetails({...policyDetails, soa:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-12">
                                                            <label>Others</label>
                                                            <AntdInput type="number" onChange={(e)=> setPolicyDetails({...policyDetails, others:e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row insurance-booking-session">
                                                        <div className="col-12">
                                                            <label>Remarks</label>
                                                            <AntdInput type="textarea" onChange={(e)=> setPolicyDetails({...policyDetails, remarks:e.target.value})} rows={5} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="submit-btn">
                                    <button onClick={(e) => {
                                        e.preventDefault()
                                        submit()
                                    }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            </React.Fragment>
    )
}
    
export default CreateBooking;
