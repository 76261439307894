import { call, put, takeLatest } from 'redux-saga/effects'
import {  
    ADD_BOOKING_SESSION, 
    GET_INSURANCE_BOOKINGS, 
    UPDATE_BOOKING, 
    UPDATE_BOOKING_SESSION,
    UPLOAD_PATIENT_INSURANCE,
    UPLOAD_PATIENT_REPORT
} from '../Constants'

import api from '../utils/api_routes'
import store from '../store'
import { add_booking_session_ret, 
    get_insurance_bookings_ret, 
    update_booking_ret, 
    update_booking_session_ret, 
    upload_patient_insurance_ret,
    upload_patient_report_ret} from '../actions/insuranceBookings'

console.log(store,"store in saga file")

// worker Saga: will be fired on USER_FETCH_REQUESTED actions

function* get_insurance_bookings_saga() {
    console.log("Inside get_real_insight_saga")

    try {
        const  data  = yield store.getState().insurance_bookings_store.get_insurance_bookings
        const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
        const api_data = yield call(api.insuranceBookings.getInsuranceBookings, data, headers)
        if(!!api_data){
          if (api_data.status === 200) {
              yield put(get_insurance_bookings_ret({
                  success:true,
                  message:'Data fetched successfully',
                  data:api_data.data
                }))
            }else{
              yield put(get_insurance_bookings_ret({
                  success:false,
                  message:'Something went wrong try again later..',
                  data:[]
                }))
            }
        }
    } catch (e) {
      console.log(e,"e in get_act insigt saga")
      try{
          yield put(get_insurance_bookings_ret({
              success:false,
              message:'Something went wrong try again later..',
              data:[]
            }))
        }catch(x){
          yield put(get_insurance_bookings_ret({
              success:false,
              message:'Something went wrong try again later..',
              data:[]
            }))
          }
    }
}


function* update_booking_session_saga() {
    try {
        const  data  = yield store.getState().insurance_bookings_store.update_booking_session
        const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
        const api_data = yield call(api.insuranceBookings.updateBookingSession, data, headers)
        if(!!api_data){
          if (api_data.status === 200) {
              yield put(update_booking_session_ret({
                  success:true,
                  message:'Session updated successfully',
                  data:api_data.data.data
                }))
            }else{
              yield put(update_booking_session_ret({
                  success:false,
                  message:'Something went wrong try again later..',
                  data:[]
                }))
            }
        }
    } catch (e) {
      console.log(e,"e in get_act insigt saga")
      try{
          yield put(update_booking_session_ret({
              success:false,
              message:'Something went wrong try again later..',
              data:[]
            }))
        }catch(x){
          yield put(update_booking_session_ret({
              success:false,
              message:'Something went wrong try again later..',
              data:[]
            }))
          }
    }
}

function* add_booking_session_saga() {
    console.log("Inside get_real_insight_saga")

    try {
        const  data  = yield store.getState().insurance_bookings_store.add_booking_session
        const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
        const api_data = yield call(api.insuranceBookings.addBookingSession, data, headers)
        if(!!api_data){
          if (api_data.status === 200) {
              yield put(add_booking_session_ret({
                  success:true,
                  message:'Session updated successfully',
                  data:api_data.data.data
                }))
            }else{
              yield put(add_booking_session_ret({
                  success:false,
                  message:'Something went wrong try again later..',
                  data:[]
                }))
            }
        }
    } catch (e) {
      console.log(e,"e in get_act insigt saga")
      try{
          yield put(add_booking_session_ret({
              success:false,
              message:'Something went wrong try again later..',
              data:[]
            }))
        }catch(x){
          yield put(add_booking_session_ret({
              success:false,
              message:'Something went wrong try again later..',
              data:[]
            }))
          }
    }
}


function* update_booking_saga() {

  try {
      const  data  = yield store.getState().insurance_bookings_store.update_booking
      const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`} }
      const api_data = yield call(api.insuranceBookings.updateBooking, data, headers)
      if(!!api_data){
        if (api_data.status === 200) {
            yield put(update_booking_ret({
                success:true,
                message:'Session updated successfully',
                data:api_data.data.data
              }))
          }else{
            yield put(update_booking_ret({
                success:false,
                message:'Something went wrong try again later..',
                data:[]
              }))
          }
      }
  } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(update_booking_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
          }))
      }catch(x){
        yield put(update_booking_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
          }))
        }
  }
}


function* upload_patient_report_saga() {
  try {
      const  data  = yield store.getState().insurance_bookings_store.upload_patient_report
      const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`, "Content-Type": "multipart/form-data"} }
      const api_data = yield call(api.insuranceBookings.uploadReport, data, headers)
      if(!!api_data){
        if (api_data.status === 200) {
            yield put(upload_patient_report_ret({
                success:true,
                message:'Image uploaded successfully',
                data:api_data.data.data
              }))
          }else{
            yield put(upload_patient_report_ret({
                success:false,
                message:'Something went wrong try again later..',
                data:[]
              }))
          }
      }
  } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(upload_patient_report_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
          }))
      }catch(x){
        yield put(upload_patient_report_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
          }))
        }
  }
}


function* upload_patient_insurance_saga() {
  try {
      const  data  = yield store.getState().insurance_bookings_store.upload_patient_insurance
      const headers  = { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token') }`, "Content-Type": "multipart/form-data"} }
      const api_data = yield call(api.insuranceBookings.uploadInsurance, data, headers)
      if(!!api_data){
        if (api_data.status === 200) {
            yield put(upload_patient_insurance_ret({
                success:true,
                message:'Image uploaded successfully',
                data:api_data.data.data
              }))
          }else{
            yield put(upload_patient_insurance_ret({
                success:false,
                message:'Something went wrong try again later..',
                data:[]
              }))
          }
      }
  } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(upload_patient_insurance_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
          }))
      }catch(x){
        yield put(upload_patient_insurance_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
          }))
        }
  }
}


export const user_saga = [
   takeLatest(GET_INSURANCE_BOOKINGS, get_insurance_bookings_saga),
   takeLatest(UPDATE_BOOKING_SESSION, update_booking_session_saga),
   takeLatest(UPDATE_BOOKING, update_booking_saga),
   takeLatest(UPLOAD_PATIENT_REPORT, upload_patient_report_saga),
   takeLatest(UPLOAD_PATIENT_INSURANCE, upload_patient_insurance_saga),
   takeLatest(ADD_BOOKING_SESSION, add_booking_session_saga)
]

export default user_saga