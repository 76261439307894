import React from "react";
import { DeleteFilled, PlusCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Form, Image, message, Modal, Upload } from "antd";
import { error_form_check, get_url_params } from "../../utils/common_utilities";
import AntdInput from "../../Components/AntdInput";

const UploadImageLinks = (props) => {
  const [images, set_images] = React.useState([]);
  const [fileList, set_fileList] = React.useState([]);
  const [add_flag, set_add_flag] = React.useState(false);

  const [data, set_data] = React.useState({
    imageType: "",
    file: null,
  });

  const [selected_image_data, set_selected_image_data] = React.useState({
    imageType: "",
    file: null,
  });

  const [error, set_error] = React.useState({
    imageType: { state: false, text: "" },
    file: { state: false, text: "" },
  });

  const getImages = () => {
    if (props.get_image_title === "Profile") {
      set_images([
        { imageUrl: props.get_professional_info_ret.data.imageUrl, _id: "" },
      ]);
    } else if (props.get_image_title === "Facility") {
      set_images([...props.get_professional_info_ret.data.hospitalPhotos]);
    } else if (props.get_image_title === "Achievements") {
      set_images([...props.get_professional_info_ret.data.achievements]);
    }
  };
  React.useEffect(() => {
    if (props.get_professional_info_ret) {
      if (props.get_professional_info_ret.success) {
        let type = props.get_professional_info_ret.type;
        console.log(
          props.get_professional_info_ret.type,
          "get Professional return"
        );

        if (type === "get_info") {
        } else {
          props.global_error({
            success: true,
            message: props.get_professional_info_ret.message,
            heading: "Professional",
          });
        }

        if (type === "get_info") {
          getImages();
        } else if (type === "add_image") {
          props.get_professional_info({
            professional_id: get_url_params("professional_id"),
            type: "get_info",
          });
          set_add_flag(false);
          set_fileList([]);
        } else if (type === "delete_image") {
          set_selected_image_data({
            imageType: "",
            file: null,
          });
          props.get_professional_info({
            professional_id: get_url_params("professional_id"),
            type: "get_info",
          });
        }
      } else {
        props.global_error({
          success: false,
          message: props.get_professional_info_ret.message,
          heading: "Professional",
        });
      }
      props.get_professional_info_loading();
    }
  }, [props.get_professional_info_ret]);

  const handle_change = (event) => {
    const isJpgOrPng = event.file.type === "image/jpeg" || event.file.type === "image/png";
    if (event.fileList.length >= 0) {
      // Get this url from response in real world.
      set_data({
        ...data,
        imageType: props.image_type,
        file: event.fileList[0]?.originFileObj,
      });

      set_fileList(event.fileList);

      if (
        event.fileList.length > 1 || !isJpgOrPng
      ) {
        let removeFileList = [...fileList];
        removeFileList =
          !isJpgOrPng
            ? fileList.slice(0)
            : fileList.slice(-1);
        set_fileList(removeFileList);
        message.error(!isJpgOrPng ? "You can only upload JPG/PNG file!" : "You cannot upload multiple files");
      }
    } else {
      set_data({
        ...data,
        [event.target.name]: event.target.value,
      });
    }

    console.log(event, "data in add images");
    console.log(event.file.originFileObj, "file in add images");
  };

  const validate = (data) => {
    console.log(fileList, "fileList");
    const error = {
      imageType: { state: false, text: "" },
      file: { state: false, text: "" },
    };

    if (fileList.length === 0) {
      console.log(fileList, "fileList");
      message.error("Please upload file");
      error.file.state = true;
    }

    // if (data.imageType === "") {
    //   error.imageType.text = "Please enter value";
    //   error.imageType.state = true;
    // }
    return error;
  };

  const handle_submit = (event) => {
    let ret_error = {};
    ret_error = validate({ ...data });
    set_error({ ...error, ...ret_error });

    if (!error_form_check(ret_error)) {
      props.get_professional_info({
        ...data,
        professional_id: get_url_params("professional_id"),
        type: "add_image",
      });

      set_data({
        imageType: "",
        file: null,
      });

      console.log(event, "fileList event");
    }
  };

  const delete_click = (item) => {
    set_selected_image_data({
      ...item,
      imageType:
        props.image_type === "ACHEIVEMENTS" ? "ACHIEVEMENTS" : props.image_type,
    });
    props.get_professional_info({
      ...item,
      imageType:
        props.image_type === "ACHEIVEMENTS" ? "ACHIEVEMENTS" : props.image_type,
      professional_id: get_url_params("professional_id"),
      type: "delete_image",
    });
  };

  return (
    <Card title={props.get_image_title}>
      <Form layout="vertical">
        <Form.Item>
          <div className="flex-parent-new">
            {images.map((item, index) => {
              return (
                <div className="flex-child-3" key={index}>
                  <Image className="img-cover" src={item.imageUrl} style={{ height: "15rem !important", width: "15rem !important"}} />
                  <Button
                    danger
                    type="text"
                    size="middle"
                    style={{
                      display: `${item._id === "" ? "none" : "block"}`,
                      width: "15rem",
                      margin: "0",
                      fontSize: "1rem",
                    }}
                    icon={<DeleteFilled />}
                    onClick={() => delete_click(item)}
                  />
                </div>
              );
            })}

            <Button
              type="dashed"
              size="middle"
              style={{ width: "15rem", height: "15rem" }}
              icon={<PlusCircleTwoTone />}
              onClick={() => set_add_flag(true)}
            >
              Add Photo
            </Button>
          </div>

          <Modal
            width="60rem"
            title="Add Image"
            visible={add_flag}
            onOk={() => set_add_flag(false)}
            onCancel={() => set_add_flag(false)}
            footer={[
              <Button key="back" onClick={() => set_add_flag(false)}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handle_submit}>
                Submit
              </Button>,
            ]}
          >
            <div key={"1221"}>
              <div className="flex-parent-new ">
                {/* <span className="flex-child-1-new">
                  <Form.Item label="Image Type">
                    <AntdInput
                      type="transparent"
                      value={data.imageType}
                      name="imageType"
                      options={[
                        {
                          name: "PROFILE",
                          value: "PROFILE",

                          name: "ABOUT_HOSPITAL",
                          value: "ABOUT_HOSPITAL",
                        },
                        {
                          name: "ACHEIVEMENTS",
                          value: "ACHEIVEMENTS",
                        },
                        {
                          name: "OTHER",
                          value: "OTHER",
                        },
                      ]}
                      type="select"
                      error_text={error.imageType.text}
                      toggle_error={() =>
                        set_error({
                          ...error,
                          imageType: { text: "", state: false },
                        })
                      }
                      error={error.imageType.state}
                      onChange={(val) => handle_dropdown_change(val)}
                    />
                  </Form.Item>
                </span> */}

                <span className="flex-child-1-new">
                  <Form.Item label="Upload image">
                    <div className="flex-parent-new">
                      <span className="flex-child-1-new">
                        <AntdInput
                          name="file"
                          type="upload"
                          maxCount="1"
                          multiple={false}
                          fileList={fileList}
                          onChange={(event) => handle_change(event)}
                        />
                      </span>
                    </div>
                  </Form.Item>
                </span>
              </div>
            </div>
          </Modal>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UploadImageLinks;
