import {  GLOBAL_ERROR , GLOBAL_ERROR_RET, GLOBAL_ERROR_CLR, GLOBAL_LOADING, GLOBAL_LOADING_CLR,
     SET_MOUNT, SET_SPECIALITIES, GET_SPECIALITIES, GET_SPECIALITIES_RET, GET_SPECIALITIES_LOADING,
      GET_SERVICES, GET_SERVICES_RET, SET_SERVICES, GET_SERVICES_LOADING, SET_GLOBAL_UPLOAD, CHECK_UPLOAD_STATUS,
       CHECK_UPLOAD_STATUS_LOADING, CHECK_UPLOAD_STATUS_RET, UPLOAD_PROGRESS_TOGGLE, UPLOAD_PROGRESS_DATA,
       GET_AGENT_LIST_RET, GET_AGENT_LIST, GET_AGENT_LIST_LOADING, GET_SERVICE_FAMILIES, SET_SERVICE_FAMILIES, GET_SERVICE_FAMILIES_RET, GET_SERVICE_FAMILIES_LOADING} from "../Constants"

import { act } from "react-dom/test-utils"

const initialState = {
    global_error:false,
    global_error_ret:false,
    global_error_loading:false,
    user_data:false,
    global_loading:false,

    specialities:[],
    services:[],
    serviceFamilies:[],

    get_services:false,
    get_services_ret:false,
    get_services_loading:false,

    get_specialities:false,
    get_specialities_ret:false,
    get_specialities_loading:false,

    get_service_families:false,
    get_service_families_ret:false,
    get_service_families_loading:false,

    check_upload_status:false,
    check_upload_status_ret:false,
    check_upload_status_loading:false,

    get_agent_list:false,
    get_agent_list_ret: {
        data:[]
    },
    get_agent_list_loading:false,

    upload_progress_toggle:false,
    upload_progress:false,
    upload_progress_data:{
        errors:[],
        matchedServices:[],
        notMatchedServices:[]
    },

    mount:{
        dash_mount:false,
        members_list_mount:false
    }
}

export default function (state = initialState, action) {
    switch (action.type) {

        case UPLOAD_PROGRESS_TOGGLE:  
        return {
            ...state,
            upload_progress_toggle:!state.upload_progress_toggle
        }

        case UPLOAD_PROGRESS_DATA:  
        console.log(state.upload_progress_data.errors,action.data,  "action.data ============>>>>>>>>>>>>")
        return {
            ...state,
            upload_progress_data:{
                errors:[...state.upload_progress_data.errors, ...[action.data.errors?action.data.errors:[]]],
                notMatchedServices:[...state.upload_progress_data.notMatchedServices, ...[action.data.notMatchedServices?action.data.notMatchedServices:[]]],
                matchedServices:[...state.upload_progress_data.matchedServices, ...[action.data.matchedServices?action.data.matchedServices:[]]]
            }
        }

        case CHECK_UPLOAD_STATUS: {
            return {
                ...state,
                upload_progress:true,
                check_upload_status:{...action.data},
                check_upload_status_loading:true
            }
        }

        case CHECK_UPLOAD_STATUS_RET: {
            return {
                ...state,
                check_upload_status_ret:{...action.data},
                check_upload_status_loading:true
            }
        }

        case CHECK_UPLOAD_STATUS_LOADING: {
            return {
                ...state,
                check_upload_status_ret:false,
                check_upload_status_loading:false
            }
        }


        case GET_AGENT_LIST: {
            return {
                ...state,
                get_agent_list:true,
                get_agent_list_loading:{...action.data}
            }
        }

        case GET_AGENT_LIST_RET: {
            return {
                ...state,
                get_agent_list_ret:{...action.data},
                get_agent_list_loading:true
            }
        }

        case GET_AGENT_LIST_LOADING: {
            return {
                ...state,
                get_agent_list_ret:false,
                get_agent_list_loading:false
            }
        }


        case SET_GLOBAL_UPLOAD:
            return {
                ...state,
                upload_file:action.data
            }
        case GET_SPECIALITIES:
            return {
                ...state,
                get_specialities:action.data,
                get_specialities_loading:true
            }
        case GET_SPECIALITIES_RET:
         
            return {
                ...state,
                get_specialities_ret:action.data,
                get_specialities_loading:false
            }
        case GET_SPECIALITIES_LOADING:
            return {
                ...state,
                get_specialities_loading:false
            }
        case SET_SPECIALITIES:
            return {
                ...state,
                get_specialities_loading:false,
                specialities:[...action.data.data].map(item=>{ return {name:item.speciality, value:item._id}})
            }


        case GET_SERVICES:
            return {
                ...state,
                get_services:action.data,
                get_services_loading:true
            }
        case GET_SERVICES_RET:
            return {
                ...state,
                get_services_ret:action.data,
                get_services_loading:false
            }
        case GET_SERVICES_LOADING:
            return {
                ...state,
                get_services_loading:false
            }
        case SET_SERVICES:
            return {
                ...state,
                get_services_loading:false,
                services:[...action.data.data].map(item=>{ return {name:item.service, value:item.serviceId}})
            }
        case GET_SERVICE_FAMILIES:
            return {
                ...state,
                get_service_families:action.data,
                get_service_families_loading:true
            }
        case GET_SERVICE_FAMILIES_RET:
            return {
                ...state,
                get_service_families_ret:action.data,
                get_service_families_loading:false
            }
        case GET_SERVICE_FAMILIES_LOADING:
            return {
                ...state,
                get_service_families_loading:false
            }
        case SET_SERVICE_FAMILIES:
            return {
                ...state,
                get_service_families_loading:false,
                serviceFamilies:[...action.data.data].map(item=>{ return {name:item.familyName, value:item._id}})
            }

        case GLOBAL_LOADING:
            return {
                ...state,
                global_loading:true
            }
        case GLOBAL_LOADING_CLR:
            return {
                ...state,
                global_loading:false
            }
        case GLOBAL_ERROR:
             return {
               ...state,
               global_error:action.data,
               global_error_loading:true
             }
        case GLOBAL_ERROR_RET:
        return {
            ...state,
            global_error_ret:action.data,
            // signup_loading:true,
            global_error_loading:false
        }
        case GLOBAL_ERROR_CLR:
            return {
                ...state,
                global_error_ret:false,
                global_error_loading:false
            }

        case SET_MOUNT:
            return {
                ...state,
                mount: {
                    ...action.data
                }
            }
        default:
        return {...state}
    
        }
}