import React from "react"
import { Table, Input, Button, Space, Card, Form, Switch, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {for_loop, objectEquals} from '../utils/common_utilities'
import { Redirect } from "react-router-dom"
import AntdInput from "../Components/AntdInput"
// import Map from "./Map.js"

const { Title } = Typography


const ProfessionalsList = (props) => {
  const {form} = Form.useForm()
  const [video_toggle, set_video_toggle] = React.useState(false)
  const [image_toggle, set_image_toggle] = React.useState(false)
      const [state, set_state] = React.useState({
        filteredInfo: null,
        sortedInfo: null,
        searchText: '',
        searchedColumn: ''
      })
      const [get_professionals_params, set_get_professionals_params] = React.useState({
           page:1,
           limit:10,
           userType:"Hospital",
           total:10,
           specialityId:'',
           searchParams:{
             name:'',
             email:'',
             mobileNumber:''
           },
           filters:{
             userType:"",
             level:"",
             catalogue:'',
             isVideos:false,
             isPhotos:false
           }
      })

      const [professionals_list, set_professionals_list] = React.useState([])
      const [data, set_data] = React.useState([])
      const [speciality, set_speciality] = React.useState([])
      const [list, set_list] = React.useState([])
      const [professional_selected, set_professional_selected] = React.useState(false)

    React.useEffect(()=>{
        props.get_professionals(get_professionals_params)
    },[])

    React.useEffect(()=>{
      if(!!props.get_professionals_ret){
        // console.log(props.get_professionals_ret,"get_professional_ret")
          // set_bids(modif_data(props.bids))
         if(props.get_professionals_ret.success){
          set_data(modif_data([...props.get_professionals_ret.data]))
          set_get_professionals_params({...get_professionals_params, total:props.get_professionals_ret.count})
         }else{
          props.global_error({
            success:false,
            message:props.get_professionals_ret.message,
            heading:'Professionals'
          })
         }
          props.get_professionals_loading()
      }
     
         
  },[props.get_professionals_ret])

  const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

     const handleChange = (pagination, filters, sorter) => {
      // console.log(pagination, "pagination filter sorter")
       let state_filters = {...get_professionals_params.filters}
       let updated_filters = {}
      
       updated_filters.level = filters.level
       updated_filters.catalogue = filters.catalogue
       updated_filters.userType = filters.userType
       updated_filters.isPhotos = filters.isPhotos
       updated_filters.isVideos = filters.isVideos
      if(!!((pagination.current === get_professionals_params.page) && (pagination.pageSize === get_professionals_params.limit))){
        if(objectEquals(state_filters, updated_filters)){
          console.log("Objects are equal")
        }else{
          // console.log(state_filters, updated_filters,"state_filters, updated_filters")
          console.log("Objects are not equal , call filter operation")
          set_get_professionals_params({...get_professionals_params, page:1, filters :{
         
           userType: filters.userType? filters.userType:'',
           level:filters.level?filters.level:'',
           catalogue:filters.catalogue?filters.catalogue:'',
           isPhotos:filters.isPhotos,
           isVideos:filters.isVideos
          }})
          props.get_professionals({...get_professionals_params, page:1, filters :{
           
           userType: filters.userType? filters.userType:'',
           level:filters.level?filters.level:'',
           catalogue:filters.catalogue?filters.catalogue:'',
           isPhotos:filters.isPhotos,
           isVideos:filters.isVideos  
  }})
        }
      }
      };
  

      let { sortedInfo, filteredInfo } = state
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}



     const  getColumnSearchProps = (dataIndex,placeholder, searchProp, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                state.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => state.searchInput.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
              title={text}
            />
          ) : (
            text
          ),
      });
    
       const  handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
        confirm();
         set_get_professionals_params({...get_professionals_params, page:1,searchParams:{
           ...get_professionals_params.searchParams,[searchProp]:selectedKeys[0]
         }})
         props.get_professionals({...get_professionals_params, page:1,searchParams:{
          ...get_professionals_params.searchParams,[searchProp]:selectedKeys[0]
        }})
        set_state({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        set_state({ searchText: '' })
      }


    const modif_data = (arr) => {
    let prof_list = []
        for_loop(arr, (item) => {
            let obj = {
            name: item.name,
            email: item.email,
            mobileNumber:item.mobileNumber,
            registrationNumber: item.registrationNumber,
            level:item.level,
            userType: item.userType,
            catalogue:item.catalogue,
            specialityNames:item.specialityNames,
            _id: item._id,
            active:item.active ? "Yes" : "No"
            }
            prof_list.push({
          ...obj
            })
           
        })

    return prof_list;
    }

   const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
      set_get_professionals_params({...get_professionals_params, limit:b, page:1})
      props.get_professionals({...get_professionals_params, limit:b, page:1})
    }

    const page_change = (a, b) => {
      // console.log(a,b,get_professionals_params,"a and b get_professionals_params")
      // if(get_professionals_params.page !== a){
        if(true){
        set_get_professionals_params({...get_professionals_params, page:a, limit:b})
        props.get_professionals({...get_professionals_params, page:a, limit:b})
      }
    }

    const handle_reset = (a, b, c) => {
      handleReset(a)
      set_state({ filteredInfo: null });
      set_get_professionals_params({...get_professionals_params,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
    
        userType:"",
        level:"",
        catalogue:'',
        isVideos:'',
        isPhotos:''
      }})
      props.get_professionals({...get_professionals_params,page:1,  searchParams:{
        name:'',
        email:'',
        mobileNumber:''
      },
      filters:{
   
        userType:"",
        level:"",
        catalogue:'',
        isVideos:'',
        isPhotos:''
      }})
    }
    const clear_filters = () => {
      // handleReset()
      set_state({ filteredInfo: null });
      // clearFilters()
      set_state({ searchText: '' });
      set_get_professionals_params({...get_professionals_params, page:1, filters:{
       
        userType:"",
        catalogue:'',
        level:'',
        isVideos:'',
        isPhotos:''
      }})
      props.get_professionals({...get_professionals_params, page:1, filters:{
      
        userType:"",
        catalogue:'',
        level:'',
        isVideos:'',
        isPhotos:''
      }})
    }

   const row_click = (r) => {
          console.log(r,"r in row_click")
          set_professional_selected(r)
    }

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
         
          ...getColumnSearchProps('name', "Search name", 'name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email', "Search email", 'email'),
           
          },
        {
          title: 'Phonenumber',
          dataIndex: 'mobileNumber',
          key: 'mobileNumber',
          ...getColumnSearchProps('mobileNumber', "Search Phonenumber", 'mobileNumber')
        },
        {
          title: 'Level',
          dataIndex: 'level',
          key: 'level',
          filters: [
            {
              text: 'Level 1',
              value: '1',
            },
            {
              text: 'Level 2',
              value: '2',
            },
            {
              text: 'Level 3',
              value: '3',
            }
          ],
          filterMultiple: false,   
          onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter")
        },
        {
            title: 'User type',
            dataIndex: 'userType',
            key: 'userType',
            filters: [
              {
                text: 'Hospitals',
                value: 'Hospital',
              },
              {
                text: 'Labs',
                value: 'Lab',
              },
              {
                text: 'Doctors',
                value: 'Doctor',
              }
            ],
            filterMultiple: false,
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter")
          },
          {
            title: 'Catalogue',
            dataIndex: 'catalogue',
            key: 'catalogue',
            filters: [
              {
                text: 'Yes',
                value: 'Yes',
              },
              {
                text: 'No',
                value: 'No',
              }
            ],
            filterMultiple: false,
            onChange :(a,b,c)=>console.log(a,b,c,"a,b,c in onFilter")
          },
          {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            // sorter: (a, b) => sorter(a.active, b.active),
            filterMultiple: false,
          }
      ]
      function showTotal(total) {
        return `Total ${total} items`;
      }


      const no_video_toggle = () => {
        set_get_professionals_params({
          ...get_professionals_params,
          page: 1,
          filters: {
           ...get_professionals_params.filters,
            isVideos:!video_toggle
          },
        })
        props.get_professionals({
          ...get_professionals_params,
          page: 1,
          filters: {
           ...get_professionals_params.filters,
            isVideos:!video_toggle
          }
        })
        set_video_toggle(!video_toggle)
      }
    
      const no_image_toggle = () => {
        set_get_professionals_params({
          ...get_professionals_params,
          page: 1,
          filters: {
           ...get_professionals_params.filters,
            isPhotos:!image_toggle
          },
        })
        props.get_professionals({
          ...get_professionals_params,
          page: 1,
          filters: {
           ...get_professionals_params.filters,
           isPhotos:!image_toggle
          }
        })
        set_image_toggle(!image_toggle)
      }

   if(!!professional_selected){
        return <Redirect to={`/dashboard/professionals?professional_id=${professional_selected._id}`} />
   }

        return (<React.Fragment>
            <Card title="Professionals">
            <Form
      form={form}
      layout="vertical"
    >
        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput 
              label="Speciality"  
              value={speciality} 
              disabled = {props.get_bids_loading_flag}
              options = {props.global_store.specialities}
              loading={props.global_store.get_specialities_loading}
              error_text={""} 
              toggle_error = {()=>console.log()} 
              error={false}
              name="speciality" 
              type="select"
              mode="multiple"
              onChange = {event=>{
                console.log(event)
                let specialityId = ""
                event.map((item,i)=>{
                  if(i!==0){
                    specialityId = `${specialityId},${item}`
                  }else{
                    specialityId = `${specialityId}${item}`
                  }
                  
                })
                set_get_professionals_params({...get_professionals_params, specialityId:specialityId, page:1})
                props.get_professionals({...get_professionals_params, specialityId:specialityId, page:1})
                set_speciality(event)
              }} 
             
              placeholder="Choose speciality" />
        </span>
        </div>
</Form>


          <div style={{ width: "100%", marginBottom:'2rem' }} className="flex-parent-new">
              <span className="flex-child-1-new u-display-flex u-margin-bottom-small">
                    <Switch checked={!image_toggle}  onChange={()=>no_image_toggle()} />
                    <Title level={5}>No Images</Title>
                </span>
                <span className="flex-child-1-new u-display-flex u-margin-bottom-small">
                    <Switch  checked={!video_toggle} onChange={()=>no_video_toggle()} />
                    <Title level={5}>No Videos</Title>
                </span>
            </div>  



        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_professionals_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          pagination = {{
            showSizeChanger:true,
            limit:get_professionals_params.limit,
            onChange:(a,c)=>page_change(a,c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent:get_professionals_params.page,
            total:get_professionals_params.total,
            showTotal:showTotal
          }}
          onRow={(r) => ({
            onClick: (b,a) => row_click(r),
          })}
          />
        </Card>
        </React.Fragment>)
}
export default ProfessionalsList