import axios from "axios";
import { get_string_params, get_url_params } from "../utils/common_utilities";
// import { connect } from 'react-redux';

let base_url = "https://devapi.plunes.com/v10";
let base_url_without_v5 = "https://devapi.plunes.com";

const pathLocation = window.location.host;
if (!!pathLocation) {
  console.log("pathLocation : ", pathLocation);
  if (
    pathLocation === "admin.plunes.com" ||
    pathLocation === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    // if(true){
    // Production baseUrl
    base_url = "https://api.plunes.com/v10";
  } else {
    base_url = "https://devapi.plunes.com/v10";
  }
}

let admin_url = "https://devapi.plunes.com/admin";
let prod_admin_url = "https://api.plunes.com/admin";

const admin_location = window.location.host;
if (!!admin_location) {
  console.log("pathLocation : ", admin_location);
  // if(admin_location === "s3.console.aws.amazon.com") {
  if (
    admin_location === "admin.plunes.com" ||
    admin_location === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    console.log("Prod");
    // Production baseUrl
    admin_url = "https://api.plunes.com/admin";
  } else {
    admin_url = "https://devapi.plunes.com/admin";
  }
}

let new_url = "https://devapi.plunes.com/mcm";
// let prod_new_url = "https://api.plunes.com/mcm"

const location = window.location.host;
if (!!location) {
  console.log("pathLocation : ", admin_location);
  // if(admin_location === 's3.console.aws.amazon.com') {
  if (
    location === "admin.plunes.com" ||
    location === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    console.log("PROD");
    // Production baseUrl
    new_url = "https://api.plunes.com/mcm";
  } else {
    new_url = "https://devapi.plunes.com/mcm";
  }
}

let new_url_2 = "https://devapi.plunes.com";
// let prod_new_url = "https://api.plunes.com/mcm"

const new_location = window.location.host;
if (!!new_location) {
  console.log("pathLocation : ", new_location);
  // if(admin_location === 's3.console.aws.amazon.com') {
  if (
    new_location === "admin.plunes.com" ||
    new_location === "plunestestbucket.s3-website.ap-south-1.amazonaws.com"
  ) {
    console.log("PROD");
    // Production baseUrl
    new_url_2 = "https://api.plunes.com";
  } else {
    new_url_2 = "https://devapi.plunes.com";
  }
}

export default {
  notification: {
    clear_count: (headers) =>
      axios
        .get(`${new_url}/notification/readScheduledNotifications`, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    get_notification: (data, headers) =>
      axios
        .get(
          `${new_url}/notification?page=${data.page}&limit=${data.limit}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
  },
  follow_up: {
    your_follow_ups: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/followUpQueries?page=${data.page}&limit=${data.limit}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_follow_ups: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries?page=${data.page}&limit=${data.limit}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    check_follow_up: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/queryByContact?mobileNumber=${data.mobile_number}&page=${data.page}&limit=${data.limit}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },

    // check_follow_up: (data,  headers) =>
    // axios
    //     .get(`${new_url}/queries/queryByContact?mobileNumber=${data.mobile_number}`, headers)
    //     .then(res => {
    //       console.log(res,"res in check_number")
    //           return res.data
    //     })
    //     .catch(err =>{
    //       return err
    //     }),https://devapi.plunes.com/mcm/queries/sent-sms?queryId=idsfsdafs

    edit_follow_up: (data, headers) =>
      axios
        .put(`${new_url}/queries/updateQueries`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    update_agent_name: (data, headers) =>
      axios
        .put(`${new_url}/queries/assignAgent`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    update_status_incomeGroup: (data, headers) =>
      axios
        .put(`${new_url}/queries/updateIncomeGroup`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    update_status_mop: (data, headers) =>
      axios
        .put(`${new_url}/queries/updateMop`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    update_status_stage: (data, headers) =>
      axios
        .put(`${new_url}/queries/updateStage`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    send_sms: (message, id, headers) =>
      axios
        .put(`${new_url}/queries/sent-sms?queryId=${id}`, message, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),

    update_follow_up: (data, headers) =>
      axios
        .put(`${new_url}/queries/updateQueries`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    add_reminder: (data, headers) =>
      axios
        .put(`${new_url}/queries/addReminder`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
  },
  catalogue_manager: {
    get_catalogue_by_id: (data, headers) =>
      axios
        .get(
          `${new_url}/catalogueManager/catalogueById?catalogueId=${data.catalogue_id}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in get_catalogue_by_id");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    get_records: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/insights/serviceTrack/${data.professional_id}?page=${data.page}&limit=${data.limit}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },

		get_price_comparision: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/professional/getServicesOfProfessionals?id=${data.professional_id}&page=${data.page}&limit=${data.limit}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_catalogue_list: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/catalogueManager/catalogueList?page=${data.page}&limit=${data.limit}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		update_unmatched: (data, headers) =>
			axios
				.post(
					`${new_url}/catalogueManager/addCatalogueToHospital`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in upload_catalogue');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		check_upload_status: (data, headers) =>
			axios
				.get(
					`${new_url}/catalogueManager/catalogueProgress/${data.file_name}`,
					headers
				)
				.then((res) => {
					console.log(res, 'res in upload_catalogue');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		update_price_catalogue: (data, professional_id, headers) =>
			axios
				.patch(
					`${new_url}/professional/updateServicesToProfessional/${professional_id}`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in upload_catalogue');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		upload_catalogue: (data, headers) =>
			axios
				.post(`${new_url}/catalogueManager/submitCatalogue`, data, headers)
				.then((res) => {
					console.log(res, 'res in upload_catalogue');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		get_bar_chart_data: (data, headers) =>
			axios
				.get(
					`${new_url}/bidding/professionalServiceAnalysis?serviceId=${
						data.serviceId
					}&latitude=${data.lat}&longitude=${data.lng}&level=${
						data.level ? data.level : ''
					}`,
					headers
				)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		get_prof_info: (data, headers) =>
			axios
				.get(
					`${new_url}/professional/getProfileInfo/${data.professional_id}`,
					headers
				)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
	},
	bookings: {
    update_sol_professional: (data, headers) => {
      console.log(data, 'res in Update_Sol_Professional DATA');

			return axios
				.put(`${new_url}/regEnquiries/updateSolutionPrice`, data, headers)
				.then((res) => {
					console.log(res, 'res in Update_Sol_Professional RES');
					return res.data;
				})
				.catch((err) => {
          console.log(err.response, 'res in Update_Sol_Professional ERR');
					return err;
				})},
		get_doctors: (data, headers) =>
		axios
			.get(
				`${new_url}/professional/getProfessionalsBySpecialityIdAndSeviceId?serviceId=${data.serviceId}&specialityId=${data.specialityId}`,
				headers
			)
			.then((res) => {
				console.log(res, 'res in check_number');
				return res.data;
			})
			.catch((err) => {
				return err;
		  }),
		get_reports_byid: (data, headers) =>
			axios
				.get(`${base_url}/user/getReportByReportId?userReportId=${data.userReportId}`,  headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		update_refund_booking: (data, headers) =>
			axios
				.put(`${new_url}/booking/udpateRefundStatus`, data, headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		update_global_booking: (data, headers) =>
			axios
				.post(`${new_url}/booking/confirmBooking`, data, headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		get_refunds: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/booking/refundBookings?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		global_bookings: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/booking/bookings?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		update_payment: (data, booking_id, headers) =>
			axios
				.put(`${new_url}/booking/updatePayment/${booking_id}`, data, headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		
		update_all_sol_professional:(data, headers) => {
      console.log(data, "res in UPDATE_ALL_SOL_PROF DATA")
      return axios
        .put(`${new_url}/regEnquiries/updateSolutionPriceAll`, data, headers)
        .then((res) => {
          console.log(res, "res in UPDATE_ALL_SOL_PROF RES")
          return res.data;
        })
        .catch((err) => {
          console.log(err, "res in UPDATE_ALL_SOL_PROF RES")
          return err;
        })
    },
    get_sol_professionals: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/solutionProfessionals?solutionId=${data.solutionId}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },

    get_medical_details: (userId, headers) => {
      console.log(userId, "userId in get_medical_details");
      return axios
				.get(`${base_url}/user/getReportByUserId?userId=${userId}`, headers)
				.then((res) => {
					console.log(res, 'res in get_medical_detailsr');
					return res.data;
				})
				.catch((err) => {
					return err;
				})
      },

    get_insights: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/registeredSearchedSolution?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    update_bookings_status: (data, headers) =>
      axios
        .post(`${new_url}/booking/confirmBooking`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    send_credentials: (data, headers) =>
      axios
        .post(`${new_url}/mcmUser/sendUserCredentials`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    transfer_booking: (data, headers) =>
      axios
        .post(`${new_url}/booking/transferBooking`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    reschedule_booking: (data, booking_id, headers) =>
      axios
        .put(`${new_url}/booking/reschedule/${booking_id}`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    cancel_booking: (data, headers) =>
      axios
        .put(`${new_url}/booking/cancel/${data.booking_id}`, {}, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    get_bookings: async (data, headers) =>
      await axios
        .get(
          // `${new_url}/booking/bookings?page=${data.page}&limit=${data.limit}&userName=${data.name}`,

          // `${new_url}/mcmUser/userByMobileNumber?mobileNumber=${data.number}`,
          `${new_url}/booking/activeBookings?page=${data.page}&limit=${data.limit}&mobileNumber=${data.number}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    get_booking_by_id: (data, headers) =>
      axios
        .get(`${new_url}/booking/getBookingById?bookingId=${data.booking_id}`, headers)
        .then((res) => {
          console.log(res, "res in get_booking_by_id");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    generate_link: (data, headers) =>
      axios
        .post(`${new_url_2}/paymentControl/generatePaymentLink`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    make_booking: (data, headers) =>
      axios
        .post(`${new_url}/booking/createManualBooking`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    register_user: (data, headers) =>
      axios
        .post(`${new_url}/mcmUser/userSignup`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    check_number: (data, headers) =>
      axios
        .get(
          `${new_url}/mcmUser/userByMobileNumber?mobileNumber=${data.mobile_number}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),

    update_covid_queries: (data, headers) =>
      axios
        .put(`${new_url}/queries/covidStatusUpdate`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_ivr_calls: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getCallRecords?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_solution_sms: (data, headers) =>
      axios
        .put(`${new_url}/regEnquiries/sendBulkMessages`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_catalogue_report: (data, headers) =>
      axios
        .put(`${new_url}/sales/catalogueUpdateReport`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_solutions: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/registeredSearchedSolution?specialityId=${
        data.specialityId
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }&serviceId=${data.serviceId}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_covid_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/queries/sendCovidMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_solutions_sms: (data, solutionId, headers) =>
      axios
        .put(
          `${new_url}/regEnquiries/sendSolutionMessages?solutionId=${solutionId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_registered_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/regEnquiries/sendEnquiryMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_public_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/queries/sendQueryMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    submit_query: (data, headers) =>
      axios
        .post(`${new_url}/queries/ivrCallQueries`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    update_city: (data, flag, headers) => {
      console.log(data, "data in update_city");
      return axios
        .put(
          `${new_url}/${
            !(flag === "Registered") ? "queries" : "regEnquiries"
          }/updateCity`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_city");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        });
    },
    update_status: (data, flag, headers) =>
      axios
        .put(
          `${new_url}/${
            !(flag === "Registered") ? "queries" : "regEnquiries"
          }/updateNotesAndStatus`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in updateNotesAndStatus");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_public_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getAllQueries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_reg_public_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/getAllEnquiries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_covid_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/covidQueries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    update_booking_process: (data, headers) =>
    axios
      .post(`${new_url}/booking/updateBookingProcess`, data, headers)
      .then((res) => {
        console.log(res, 'res in check_number');
        return res.data;
      })
      .catch((err) => {
        return err;
      }),
    approve_booking_insurance_update: (data, headers) => 
      axios.put(`${new_url}/booking/approveInsuranceUpdate`, data, headers)
        .then((res) => res.data)
        .catch((err) => err.response.data)
  },

  funnel: {
    update_sol_professional: (data, headers) =>
			axios
				.put(`${new_url}/regEnquiries/updateSolutionPrice`, data, headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		get_doctors: (data, headers) =>
		axios
			.get(
				`${new_url}/professional/getProfessionalsBySpecialityIdAndSeviceId?serviceId=${data.serviceId}&specialityId=${data.specialityId}`,
				headers
			)
			.then((res) => {
				console.log(res, 'res in check_number');
				return res.data;
			})
			.catch((err) => {
				return err;
		  }),
		get_reports_byid: (data, headers) =>
			axios
				.get(`${base_url}/user/getReportByReportId?userReportId=${data.userReportId}`,  headers)
				.then((res) => {
					console.log(res, 'res in GET_REPORT_BY_ID');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		update_refund_booking: (data, headers) =>
			axios
				.put(`${new_url}/booking/udpateRefundStatus`, data, headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		update_global_booking: (data, headers) =>
			axios
				.post(`${new_url}/booking/confirmBooking`, data, headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		get_refunds: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/booking/refundBookings?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		global_bookings: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/booking/bookings?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		update_payment: (data, booking_id, headers) =>
			axios
				.put(`${new_url}/booking/updatePayment/${booking_id}`, data, headers)
				.then((res) => {
					console.log(res, 'res in check_number');
					return res.data;
				})
				.catch((err) => {
					return err;
				}),
		
		update_all_sol_professional:(data, headers) =>
		axios
			.put(`${new_url}/regEnquiries/updateSolutionPriceAll`, data, headers)
			.then((res) => {
				console.log(res, 'res in check_number');
				return res.data;
			})
			.catch((err) => {
				return err;
			}),

    get_sol_professionals: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/solutionProfessionals?solutionId=${data.solutionId}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },

    get_insights: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/registeredSearchedSolution?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    update_city: (data, headers) => {
      console.log(data, "data in update_city");
      return axios
        .put(`${new_url}/queries/updateCity`, data, headers)
        .then((res) => {
          console.log(res, "res in update_city");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        });
    },
    update_status: (data, headers) =>
      axios
        .post(`${new_url}/booking/confirmBooking`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    send_credentials: (data, headers) =>
      axios
        .post(`${new_url}/mcmUser/sendUserCredentials`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    transfer_booking: (data, headers) =>
      axios
        .post(`${new_url}/booking/transferBooking`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    reschedule_booking: (data, booking_id, headers) =>
      axios
        .put(`${new_url}/booking/reschedule/${booking_id}`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    cancel_booking: (data, headers) =>
      axios
        .put(`${new_url}/booking/cancel/${data.booking_id}`, {}, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    get_bookings: async (data, headers) =>
      await axios
        .get(
          `${new_url}/booking/activeBookings?page=${data.page}&limit=${data.limit}&mobileNumber=${data.number}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    generate_link: (data, headers) =>
      axios
        .post(`${new_url_2}/paymentControl/generatePaymentLink`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    make_booking: (data, headers) =>
      axios
        .post(`${new_url}/booking/createManualBooking`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    register_user: (data, headers) =>
      axios
        .post(`${new_url}/mcmUser/userSignup`, data, headers)
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    check_number: (data, headers) =>
      axios
        .get(
          `${new_url}/mcmUser/userByMobileNumber?mobileNumber=${data.mobile_number}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),

    update_covid_queries: (data, headers) =>
      axios
        .put(`${new_url}/queries/covidStatusUpdate`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_ivr_calls: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getCallRecords?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_solution_sms: (data, headers) =>
      axios
        .put(`${new_url}/regEnquiries/sendBulkMessages`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_catalogue_report: (data, headers) =>
      axios
        .put(`${new_url}/sales/catalogueUpdateReport`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_solutions: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/registeredSearchedSolution?specialityId=${
        data.specialityId
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }&serviceId=${data.serviceId}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_covid_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/queries/sendCovidMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_solutions_sms: (data, solutionId, headers) =>
      axios
        .put(
          `${new_url}/regEnquiries/sendSolutionMessages?solutionId=${solutionId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_registered_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/regEnquiries/sendEnquiryMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_public_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/queries/sendQueryMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    submit_query: (data, headers) =>
      axios
        .post(`${new_url}/queries/ivrCallQueries`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    update_city: (data, frag, headers) => {
      console.log(data, "data in update_city");
      return axios
        .put(`${new_url}/queries/updateCity`, data, headers)
        .then((res) => {
          console.log(res, "res in update_city");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        });
    },
    update_status: (data, flag, headers) =>
      axios
        .put(
          `${new_url}/${
            !(flag === "Registered") ? "queries" : "regEnquiries"
          }/updateNotesAndStatus`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in updateNotesAndStatus");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_public_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getAllQueries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_reg_public_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/getAllEnquiries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_covid_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/covidQueries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
  },
  professionals: {
    update_catalogue: (data, professional_id, headers) =>
      axios
        .patch(
          `${new_url}/professional/addServicesToProfessional/${professional_id}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    // update_catalogue: (data, professional_id, headers) =>{
    // axios
    //     .patch(`${new_url}/professional/addServicesToProfessional/${professional_id}`, data,  headers)
    //     .then(res => {
    //       console.log(res.data,"res in update_catalogue")
    //           return res.data
    //     })
    //     .catch(err => err.response.data)
    //   },
    get_prof_services: (data, speciality_id, userId, headers) => {
      let url = `${new_url}/professional/getServicesOfProfessionals?id=${userId}&specialityId=${
        data.specialities
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_prof_specialities: (data, headers) =>
      axios
        .get(
          `${new_url}/professional/getSpecialityOfProfessional/${data.professional_id}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    update_professional_byid: (data, headers) =>
      axios
        .patch(`${new_url}/professional/updateProfessionalInfo`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_professional_byid: (data, headers) =>
      axios
        .get(
          `${new_url}/professional/getProfileInfo/${data.professional_id}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in get_professional_by_id");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_professionals: (data, headers) => {
      let url = `${new_url}/professional/getProfessionals?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&specialityId=${
        data.specialityId
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
  },
  auth: {
    login: (data, headers) =>
      axios
        .post(`${new_url}/mcmUser/login`, data)
        .then((res) => {
          console.log(res, "res in login api");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in login api");
          return err;
        }),
    logout: (data, headers) =>
      axios
        .get(`${base_url_without_v5}/mcm/mcmUser/logout`, headers)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
  },
  bids: {
    get_bids: (data, headers) =>
      axios
        .get(
          `${new_url}/bidding/createSolution?serviceId=${data.service}&latitude=${data.lat}&longitude=${data.lng}`,
          headers
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err.response, "err in get_bids api");
          let data = {
            success: false,
            message: "Unable to fetch bids for now , try again later",
          };
          return data;
        }),
  },
  user: {
    get_users: (data, headers) => {
      let url = `${new_url}/mcmUser/registeredUsers?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&specialityId=${
        data.specialityId
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_user: (data, headers) =>
      axios
        .get(`${new_url}/mcmUser`, headers)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err.response, "err in get_user api");
          return err.response;
        }),
  },
  global: {
    get_agent_list: (data, headers) =>
      axios
        .get(`${new_url}/mcmUser/sales`, headers)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in get_user api");
          return err;
        }),
    get_specialities: (data, headers) =>
      axios
        .get(`${new_url}/mcmCatalogue/getSpecialitiesList`, headers)
        .then((res) => {
          console.log(res, "GET_SPEICIALITIES_LIST_RES DATA")
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in get_user api");
          return err;
        }),
    get_services: (data, headers) =>
      axios
        .get(
          `${new_url}/mcmCatalogue/getServicesBySpecialityId?specialityId=${data.speciality}`,
          headers
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in get_user api");
          return err;
        }),
    get_service_families: (data, headers) =>
      axios
        .get(
          `${new_url}/mcmCatalogue/getServicefamilybySpecialityId?specialityId=${data.speciality}`,
          headers
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in get_user api");
          return err;
        }),
  },
  members: {
    get_members: (data, headers) =>
      axios
        .get(`${new_url}/mcmUser/getAllmcmUsers `, headers)
        .then((res) => {
          let obj = res.data;
          obj.success = true;
          return obj;
        })
        .catch((err) => {
          console.log(err, "err in get_user api");
          return err;
        }),
    add_member: (data, headers) =>
      axios
        .post(`${new_url}/mcmUser/signup`, data, headers)
        .then((res) => {
          return { data: res.data, status: res.status };
        })
        .catch((err) => {
          console.log(err.response, "err in add_member api");
          return { ...err.response };
        }),
  },
  // catalogue: {
  //   get_catalogue: (data,  headers) =>
  //   axios
  //     .get(`${new_url}/admin/catalogue_manager/hospitalCatalogueList`, headers)
  //     .then(res => {
  //       console.log(res,"catalogue")
  //           return res.data
  //     })
  //     .catch(err =>{
  //       return err
  //     }),
  // }
  catalogue: {
    get_catalogue: () =>
      axios
        .get(
          `https://devapi.plunes.com/admin/catalogue_manager/hospitalCatalogueList`
        )
        .then((res) => {
          console.log(res, "catalogue");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    get_catalogue_byid: (data) =>
      axios
        .get(
          `https://devapi.plunes.com/admin/catalogue_manager/getHospitalCatalogue/?catalogueId=${data}`
        )
        .then((res) => {
          console.log(res, "catalogue_byid");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
    get_doctors: (data, headers) =>
      axios
        .get(
          `${new_url}/professional/getProfessionalsBySpecialityIdAndSeviceId?serviceId=${data.serviceId}&specialityId=${data.specialityId}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in check_number");
          return res.data;
        })
        .catch((err) => {
          return err;
        }),
  },
  insights: {
    get_suggested_insights: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/insights/realtimeInsights?insights=SUGGESTED&page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&specialityId=${
        data.speciality
      }&serviceId=${data.serviceName}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_actionable_insights: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/insights/actionableInsigts?&page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&specialityId=${
        data.speciality
      }&serviceId=${data.serviceName}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_real_time_insights: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/insights/realtimeInsights?insights=REALTIME&page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&specialityId=${
        data.speciality
      }&serviceId=${data.serviceName}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
  },

  salesfunnel: {
    get_manual_biddings: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/manualBidding?&page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_bulk_sms_list: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/sales/getEmailsReports?reportType=${
        data.reportType
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_upload_list: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getUploadedFiles?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_ivr_messages: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getIVRQueries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_facebook_query_message: (data, queryId, headers) =>
      axios
        .put(
          `${new_url}/queries/sendMssageTosocialMediaQueries?queryId=${queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    update_facebook_data: (data, headers) =>
      axios
        .put(`${new_url}/queries/updateNotesAndStatusSMQ`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    bulk_send: (data, headers) =>
      axios
        .put(`${new_url}/sales/sendMessageToRegisteredUsers`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_ivr_message: (data, headers) =>
      axios
        .post(`${new_url}/queries/SendIVRCallMessages`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_ivr_message: (data, headers) =>
      axios
        .post(`${new_url}/queries/SendIVRCallMessages`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_facebook_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/socialMediaQueries?fileId=${
        data.fileId
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    upload_facebook_queries: (data, headers) =>
      axios
        .put(`${new_url}/queries/covidStatusUpdate`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),

    update_covid_queries: (data, headers) =>
      axios
        .put(`${new_url}/queries/covidStatusUpdate`, data, headers)
        .then((res) => {
          console.log(res, "res in covidStatusUpdate");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_ivr_calls: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getCallRecords?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_solution_sms: (data, headers) =>
      axios
        .put(`${new_url}/regEnquiries/sendBulkMessages`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_catalogue_report: (data, headers) =>
      axios
        .put(`${new_url}/sales/catalogueUpdateReport`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_solutions: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/registeredSearchedSolution?specialityId=${
        data.specialityId
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }&serviceId=${data.serviceId}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_covid_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/queries/sendCovidMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_solutions_sms: (data, solutionId, headers) =>
      axios
        .put(
          `${new_url}/regEnquiries/sendSolutionMessages?solutionId=${solutionId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_registered_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/regEnquiries/sendEnquiryMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_public_sms: (data, headers) =>
      axios
        .put(
          `${new_url}/queries/sendQueryMessage?queryId=${data.queryId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    submit_query: (data, headers) =>
      axios
        .post(`${new_url}/queries/ivrCallQueries`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    update_city: (data, flag, headers) => {
      console.log(data, "data in update_city");
      return axios
        .put(`${new_url}/cities/${data.id}`, data, headers)
        .then((res) => {
          console.log(res, "res in update_city");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in update_city");
          return err;
        });
    },
    update_status: (data, flag, headers) =>
      axios
        .put(
          `${new_url}/${
            !(flag === "Registered") ? "queries" : "regEnquiries"
          }/updateNotesAndStatus`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in updateNotesAndStatus");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_public_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/getAllQueries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_reg_public_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/regEnquiries/getAllEnquiries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ""
              }`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_covid_queries: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/queries/covidQueries?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
  },
  key_manager:{
	edit_testimonial:(data, family_id, faq_id, headers) =>
	axios
		.put(`${new_url}/mcmCatalogue/updateTestimonial?id=${family_id}&testimonialId=${faq_id}`, data, headers)
		.then(res => {
			console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
				return res.data
		})
		.catch(err =>{
			console.log(err,"err in logut api")
			return err
		}),
	add_testimonial: (data, family_id, headers) =>
	axios
		.post(`${new_url}/mcmCatalogue/addTestimonial?id=${family_id}`, data, headers)
		.then(res => {
			console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
				return res.data
		})
		.catch(err =>{
			console.log(err,"err in logut api")
			return err
		}),
	delete_testimonial: ( family_id, faq_id, headers) =>
	axios
		.get(`${new_url}/mcmCatalogue/deleteTestimonial?id=${family_id}&testimonialId=${faq_id}`, headers)
		.then(res => {
			console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
				return res.data
		})
		.catch(err =>{
			console.log(err,"err in logut api")
			return err
		}),
	edit_faq: (data, family_id, faq_id, headers) =>
	axios
		.post(`${new_url}/mcmCatalogue/updateFAQs?id=${family_id}&faqId=${faq_id}`, data, headers)
		.then(res => {
			console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
				return res.data
		})
		.catch(err =>{
			console.log(err,"err in logut api")
			return err
		}),
	add_faq: (data, family_id, headers) =>
	axios
		.post(`${new_url}/mcmCatalogue/addFAQs?id=${family_id}`, data, headers)
		.then(res => {
			console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
				return res.data
		})
		.catch(err =>{
			console.log(err,"err in logut api")
			return err
		}),
	delete_faq: ( family_id, faq_id, headers) =>
	axios
		.get(`${new_url}/mcmCatalogue/deleteFaqs?id=${family_id}&faqId=${faq_id}`, headers)
		.then(res => {
			console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
				return res.data
		})
		.catch(err =>{
			console.log(err,"err in logut api")
			return err
		}),
    add_service: (data, headers) =>
      axios
        .post(`${new_url}/mcmCatalogue/addServiceToCatalogue`, data, headers)
        .then((res) => {
          console.log(res, "res in mcmCatalogue/addServiceToCatalogue");
          return res.data;
        })
        .catch(err =>{
          console.log(err,"err in logut api")
          return err
        }),
		add_insurance: (data, headers) =>
			axios
				.post(`${new_url}/insurance/addInsurance`, data, headers)
				.then((res) => {
					console.log(res, 'res in insurance/addInsurance');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'error in insurance');
				}),
	service_data_add_service:(data, headers) =>
    axios
        .post(`${new_url}/mcmCatalogue/addServiceFamily`, data, headers)
        .then(res => {
          console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
              return res.data
        })
        .catch(err =>{
          console.log(err,"err in logut api")
          return err
        }),
    service_data_handler: (data, headers) =>
    axios
        .put(`${new_url}/mcmCatalogue/updateServiceFamilyybyId?id=${data.id}`, data, headers)
        .then(res => {
          console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
              return res.data
        })
        .catch(err =>{
          console.log(err,"err in logut api")
          return err
        }),
        get_service_family_byid: (data, headers) =>
      axios
        .get(
          `${new_url}/mcmCatalogue/getServiceFamilyInfoById?id=${data.id}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in mcmCatalogue/getServiceFamilyInfoById");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_service_family_list_by_specialityid: (data, headers) =>
      axios
        .get(
          `${new_url}/mcmCatalogue/getServicefamilybySpecialityId?specialityId=${data.specialityId}`,
          data,
          headers
        )
        .then((res) => {
          console.log(
            res,
            "res in mcmCatalogue/getServicefamilybySpecialityId"
          );
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    add_insurance: (data, headers) =>
      axios
        .post(`${new_url}/insurance/addInsurance`, data, headers)
        .then((res) => {
          console.log(res, "res in insurance/addInsurance");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "error in insurance");
        }),
    sent_catalogue_reports: (data, headers) => {
      console.log(data, "data in previous_variance_list");
      let url = `${new_url}/sales/getEmailsReports?page=${
        !!data.page ? data.page : ""
      }&limit=${
        !!data.limit ? data.limit : ""
      }&reportType=CATALOGUE_UPDATE_REPORT`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    sent_speciality_reports: (data, headers) => {
      console.log(data, "data in previous_variance_list");
      let url = `${new_url}/sales/getEmailsReports?page=${
        !!data.page ? data.page : ""
      }&limit=${
        !!data.limit ? data.limit : ""
      }&reportType=SPECIALITY_BUSINESS_REPORT`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_speciality_report: (data, headers) =>
      axios
        .post(`${new_url}/sales/sendSpecialityFacilities`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    email_preview: (data, headers) =>
      axios
        .get(
          `${new_url}/sales/templatePreview?template=${data.type}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in email_preview");
          // document.write(res.data, 'width=700,height=900,left=200,top=200')
          return {
            ...res,
            success: true,
          };
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    send_catalogue_report: (data, headers) =>
      axios
        .post(`${new_url}/sales/catalogueUpdateReport`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    previous_business_reports: (data, headers) => {
      console.log(data, "data in previous_variance_list");
      let url = `${new_url}/sales/getEmailsReports?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&reportType=BUSINESS_REPORT`;
      // if(!!data.searchParams){
      //   if(data.searchParams!=={}){
      //     let keys = Object.keys(data.searchParams)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //          console.log(item,"item in data>searchParams")
      //          url = url+`&${keys[`${i}`]}=${!!data.searchParams[`${keys[i]}`]?data.searchParams[`${keys[i]}`]:''}`
      //     })
      //   }
      // }
      // if(!!data.filters){
      //   if(data.filters!=={}){
      //     let keys = Object.keys(data.filters)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //           console.log(item,"item in data>filters")
      //       url = url+`&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`
      //     })
      //   }
      // }
      // if(!!data.intFilters){
      //   if(data.intFilters!=={}){
      //     let keys = Object.keys(data.intFilters)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //          console.log(item,"item in data>intFilters")
      //       url = url+`&${keys[`${i}`]}=${data.intFilters[`${keys[i]}`].minimum+'by'+data.intFilters[`${keys[i]}`].maximum}`
      //     })
      //   }
      // }
      // if(!!data.dateFilters){
      //   if(data.dateFilters!=={}){
      //     let keys = Object.keys(data.dateFilters)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //          console.log(item,"item in data>dateFilters")
      //          console.log(data.dateFilters[`${keys[i]}`],"item ksdjksdjkldsjksdjfkdsf")
      //          if(data.dateFilters[`${keys[i]}`].length!==0){
      //            url = url+`&${keys[`${i}`]}=${data.dateFilters[`${keys[i]}`][0]+'-'+data.dateFilters[`${keys[i]}`][1]}`
      //          }
      //     })
      //   }
      // }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_business_reports: (data, headers) =>
      axios
        .post(`${new_url}/sales/sendBusinessReports`, data, headers)
        .then((res) => {
          console.log(res, "res in send_variance_info");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    previous_variance_list: (data, headers) => {
      console.log(data, "data in previous_variance_list");
      let url = `${new_url}/sales/getEmailsReports?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      // if(!!data.searchParams){
      //   if(data.searchParams!=={}){
      //     let keys = Object.keys(data.searchParams)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //          console.log(item,"item in data>searchParams")
      //          url = url+`&${keys[`${i}`]}=${!!data.searchParams[`${keys[i]}`]?data.searchParams[`${keys[i]}`]:''}`
      //     })
      //   }
      // }
      // if(!!data.filters){
      //   if(data.filters!=={}){
      //     let keys = Object.keys(data.filters)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //           console.log(item,"item in data>filters")
      //       url = url+`&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`
      //     })
      //   }
      // }
      // if(!!data.intFilters){
      //   if(data.intFilters!=={}){
      //     let keys = Object.keys(data.intFilters)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //          console.log(item,"item in data>intFilters")
      //       url = url+`&${keys[`${i}`]}=${data.intFilters[`${keys[i]}`].minimum+'by'+data.intFilters[`${keys[i]}`].maximum}`
      //     })
      //   }
      // }
      // if(!!data.dateFilters){
      //   if(data.dateFilters!=={}){
      //     let keys = Object.keys(data.dateFilters)
      //     console.log(keys,"keys in api")
      //        keys.map((item,i)=>{
      //          console.log(item,"item in data>dateFilters")
      //          console.log(data.dateFilters[`${keys[i]}`],"item ksdjksdjkldsjksdjfkdsf")
      //          if(data.dateFilters[`${keys[i]}`].length!==0){
      //            url = url+`&${keys[`${i}`]}=${data.dateFilters[`${keys[i]}`][0]+'-'+data.dateFilters[`${keys[i]}`][1]}`
      //          }
      //     })
      //   }
      // }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    send_variance_info: (data, headers) =>
      axios
        .post(`${new_url}/sales/varianceUpdateReport`, data, headers)
        .then((res) => {
          console.log(res, "res in send_variance_info");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_key_prof_services: (data, headers) => {
      console.log(data, "data in get_key_prof_services");
      let url = `${new_url}/professional/getServicesWithPriceAnomalises?specialityId=${
        data.speciality
      }&id=${data.professional_id}&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_key_prof: (data, headers) => {
      let url = `${new_url}/professional/getAllProfessionalsInformation?specialityId=${
        data.speciality
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }&longitude=${!!data.longitude ? data.longitude : ""}&serviceName=${
        !!data.serviceName ? data.serviceName : ""
      }&latitude=${!!data.latitude ? data.latitude : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    addNewMarketingPage:(data, headers) =>
      axios
        .post(`${new_url}/campaign/addCampaignProcedure`, data, headers)
        .then(res => {
          console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
              return res.data
        })
        .catch(err =>{
          console.log(err,"err in logut api")
          return err
        }),
    updateMarketingPage: (data, headers) =>
      axios
        .put(`${new_url}/campaign/updateCampaignProcedure?id=${data.id}`, data, headers)
        .then(res => {
          console.log(res,"res in mcmCatalogue/addServiceToCatalogue")
              return res.data
        })
        .catch(err =>{
          console.log(err,"err in logut api")
          return err
        }),
  },
  professionals: { 
    delete_video:(data, professional_id, headers) =>
    axios
      .get(
        `${new_url}/media/removeVideo?professionalId=${professional_id}&videoId=${data._id}`,
        headers
      )
      .then((res) => {
        console.log(res, "res in update_professional_byid");
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err in logut api");
        return err;
      }),
    update_video:(data, professional_id, headers) =>
    axios
      .put(
        `${new_url}/media/updateVideo?professionalId=${professional_id}`,
        data,
        headers
      )
      .then((res) => {
        console.log(res, "res in update_professional_byid");
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err in logut api");
        return err;
      }),
    add_video :(data, professional_id, headers) =>
    axios
      .put(
        `${new_url}/professional/addVideo?professionalId=${professional_id}`,
        data,
        headers
      )
      .then((res) => {
        console.log(res, "res in update_professional_byid");
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err in logut api");
        return err;
      }),
    get_professional_info: (data, professional_id, headers) =>
    axios
      .get(
        `${new_url}/professional/getProfesssionalInfo/${professional_id}`,
        headers
      )
      .then((res) => {
        console.log(res, "res in update_professional_byid");
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err in logut api");
        return err;
      }),

      delete_image:(data, professional_id, headers) =>
    axios
      .get(
        `${new_url}/media/removePhoto?professionalId=${professional_id}&imageId=${data._id}&imageType=${data.imageType}`,
        headers
      )
      .then((res) => {
        console.log(res, "res in update_professional_byid");
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err in logut api");
        return err;
      }),
    add_image :(data, imageType, professional_id, headers) =>
    axios
      .post(
        `${new_url}/media/addPhotos?professionalId=${professional_id}&imageType=${imageType}`,
        data,
        headers
      )
      .then((res) => {
        console.log(res, "res in update_professional_byid");
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err in logut api");
        return err;
      }),
    update_catalogue: (data, professional_id, headers) =>
      axios
        .patch(
          `${new_url}/professional/addServicesToProfessional/${professional_id}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_prof_services: (data, speciality_id, userId, headers) => {
      let url = `${new_url}/professional/getServicesOfProfessionals?id=${userId}&specialityId=${
        data.specialities
      }&page=${!!data.page ? data.page : ""}&limit=${
        !!data.limit ? data.limit : ""
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_prof_specialities: (data, headers) =>
      axios
        .get(
          `${new_url}/professional/getSpecialityOfProfessional/${data.professional_id}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    update_professional_byid: (data, headers) =>
      axios
        .patch(`${new_url}/professional/updateProfessionalInfo`, data, headers)
        .then((res) => {
          console.log(res, "res in update_professional_byid");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_professional_byid: (data, headers) =>
      axios
        .get(
          `${new_url}/professional/getProfileInfo/${data.professional_id}`,
          headers
        )
        .then((res) => {
          console.log(res, "res in get_professional_by_id");
          return res.data;
        })
        .catch((err) => {
          console.log(err, "err in logut api");
          return err;
        }),
    get_professionals: (data, headers) => {
      let url = `${new_url}/professional/getProfessionals?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&specialityId=${
        data.specialityId
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },    
    get_insurance: (headers) => 
		  axios
			.get(`${new_url}/insurance`, headers)
			.then((res) => {
				console.log("GET_INSURANCE RES", res);
				return res.data;
			})
			.catch((err) => {
				console.log("GET_INSURANCE ERROR", err);
			}),    
    add_insurance_to_professional: (data, headers) => 
		  axios
			.post(`${new_url}/professional/addInsuranceToProfessional`, data, headers)
			.then((res) => {
				console.log("ADD_INSURANCE RES", res);
				return res.data;
			})
			.catch((err) => {
				console.log("ADD_INSURANCE ERROR", err);
			}),
    remove_insurance_from_professional: (data, headers) => 
		  axios
			.put(`${new_url}/professional/removeInsurance`, data, headers)
			.then((res) => {
				console.log("REMOVE_INSURANCE RES", res);
				return res.data;
			})
			.catch((err) => {
				console.log("REMOVE_INSURANCE ERROR", err);
			}),
    get_medical_doctors: (data, headers) => {
      let url = `${new_url}/professional/getMedicalDoctors?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    update_medical_doctor: (data, headers) => 
      axios
        .put(`${new_url}/professional/updateMedicalDoctor/${data.doctorId}`, data, headers)
        .then((res) => {
          console.log("UPDATE_MEDICAL_DOCTOR RES", res);
          return res.data;
        })
        .catch((err) => {
          console.log("ADD_INSURANCE ERROR", err);
        }),
  },
    // global: {
		// get_agent_list: (data, headers) =>
		// axios
		// 	.get(`${new_url}/mcmUser/sales`, headers)
		// 	.then((res) => {
		// 		return res.data;
		// 	})
		// 	.catch((err) => {
		// 		console.log(err, 'err in get_user api');
		// 		return err;
		// 	}),
    //   get_specialities:(data, headers)=>
    //   axios
    //   .get(`${admin_url}/specialitiesId`, headers)
    //   .then(res => {
    //         return res.data
    //   }).catch(err =>{
    //     console.log(err,"err in get_user api")
    //     return err
    //   }),
    //   get_services:(data, headers)=>
    //   axios
    //   .get(`${new_url}/mcmCatalogue/getServicesBySpecialityId?specialityId=${data.speciality}`, headers)
    //   .then(res => {
    //         return res.data
    //   }).catch(err =>{
    //     console.log(err,"err in get_user api")
    //     return err
    //   }),
    // get_service_families:(data, headers)=>
    // axios
    // .get(`${new_url}/mcmCatalogue/getServicefamilybySpecialityId?specialityId=${data.speciality}`, headers)
    // .then(res => {
    //   console.log("ssssss", res)
    //       return res.data
    // }).catch(err =>{
    //   console.log(err,"err in get_user api")
    //   return err
    // })
    // },
  bids: {
    get_bids: (data, headers) =>
      axios
        .get(
          `${new_url}/bidding/createSolution?serviceId=${data.service}&latitude=${data.lat}&longitude=${data.lng}`,
          headers
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err.response, "err in get_bids api");
          let data = {
            success: false,
            message: "Unable to fetch bids for now , try again later",
          };
          return data;
        }),
  },
  user: {
    get_users: (data, headers) => {
      let url = `${new_url}/mcmUser/registeredUsers?page=${
        !!data.page ? data.page : ""
      }&limit=${!!data.limit ? data.limit : ""}&specialityId=${
        data.specialityId
      }`;
      if (!!data.searchParams) {
        if (data.searchParams !== {}) {
          let keys = Object.keys(data.searchParams);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>searchParams");
            url =
              url +
              `&${keys[`${i}`]}=${
                !!data.searchParams[`${keys[i]}`]
                  ? data.searchParams[`${keys[i]}`]
                  : ""
              }`;
          });
        }
      }
      if (!!data.filters) {
        if (data.filters !== {}) {
          let keys = Object.keys(data.filters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>filters");
            url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
          });
        }
      }
      if (!!data.intFilters) {
        if (data.intFilters !== {}) {
          let keys = Object.keys(data.intFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>intFilters");
            url =
              url +
              `&${keys[`${i}`]}=${
                data.intFilters[`${keys[i]}`].minimum +
                "by" +
                data.intFilters[`${keys[i]}`].maximum
              }`;
          });
        }
      }
      if (!!data.dateFilters) {
        if (data.dateFilters !== {}) {
          let keys = Object.keys(data.dateFilters);
          console.log(keys, "keys in api");
          keys.map((item, i) => {
            console.log(item, "item in data>dateFilters");
            console.log(
              data.dateFilters[`${keys[i]}`],
              "item ksdjksdjkldsjksdjfkdsf"
            );
            if (data.dateFilters[`${keys[i]}`].length !== 0) {
              url =
                url +
                `&${keys[`${i}`]}=${
                  data.dateFilters[`${keys[i]}`][0] +
                  "-" +
                  data.dateFilters[`${keys[i]}`][1]
                }`;
            }
          });
        }
      }
      return axios
        .get(`${url}`, headers)
        .then((res) => {
          console.log(res, "res in get_professionals");
          return res.data;
        })
        .catch((err) => err.response.data);
    },
    get_user: (data, headers) =>
      axios
        .get(`${new_url}/mcmUser`, headers)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err.response, "err in get_user api");
          return err.response;
        }),
    getTpaUserDetails: (data, headers) => {
          console.log(data, 'data in get_key_prof_services');
          let url = `${new_url}/mcmUser/getTpaUsers?&page=${
            !!data.page ? data.page : ''
          }&limit=${!!data.limit ? data.limit : ''}`;
          if (!!data.searchParams) {
            if (data.searchParams !== {}) {
              let keys = Object.keys(data.searchParams);
              console.log(keys, 'keys in api');
              keys.map((item, i) => {
                console.log(item, 'item in data>searchParams');
                url =
                  url +
                  `&${keys[`${i}`]}=${
                    !!data.searchParams[`${keys[i]}`]
                      ? data.searchParams[`${keys[i]}`]
                      : ''
                  }`;
              });
            }
          }
          if (!!data.filters) {
            if (data.filters !== {}) {
              let keys = Object.keys(data.filters);
              console.log(keys, 'keys in api');
              keys.map((item, i) => {
                console.log(item, 'item in data>filters');
                url =
                  url +
                  `&${keys[`${i}`]}=${
                    !!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
                  }`;
              });
            }
          }
          if (!!data.intFilters) {
            if (data.intFilters !== {}) {
              let keys = Object.keys(data.intFilters);
              console.log(keys, 'keys in api');
              keys.map((item, i) => {
                console.log(item, 'item in data>intFilters');
                url =
                  url +
                  `&${keys[`${i}`]}=${
                    data.intFilters[`${keys[i]}`].minimum +
                    'by' +
                    data.intFilters[`${keys[i]}`].maximum
                  }`;
              });
            }
          }
          if (!!data.dateFilters) {
            if (data.dateFilters !== {}) {
              let keys = Object.keys(data.dateFilters);
              console.log(keys, 'keys in api');
              keys.map((item, i) => {
                console.log(item, 'item in data>dateFilters');
                console.log(
                  data.dateFilters[`${keys[i]}`],
                  'item ksdjksdjkldsjksdjfkdsf'
                );
                if (data.dateFilters[`${keys[i]}`].length !== 0) {
                  url =
                    url +
                    `&${keys[`${i}`]}=${
                      data.dateFilters[`${keys[i]}`][0] +
                      '-' +
                      data.dateFilters[`${keys[i]}`][1]
                    }`;
                }
              });
            }
          }
          return axios
            .get(`${url}`, headers)
            .then((res) => {
              console.log(res, 'res in get_professionals');
              return res;
            })
            .catch((err) => err.response.data);
        },
    downloadTpaUserDetails: (data, headers) =>
      axios
        .get(`${new_url}/mcmUser/downloadTpaUsers?dates=${data.dates}`, headers)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err.response, "err in get_user api");
          return err.response;
        }),
  },

	salesfunnel: {
		get_manual_biddings: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/regEnquiries/manualBidding?&page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_bulk_sms_list: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/sales/getEmailsReports?reportType=${
				data.reportType
			}&page=${!!data.page ? data.page : ''}&limit=${
				!!data.limit ? data.limit : ''
			}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_upload_list: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/queries/getUploadedFiles?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_ivr_messages: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/queries/getIVRQueries?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		send_facebook_query_message: (data, queryId, headers) =>
			axios
				.put(
					`${new_url}/queries/sendMssageTosocialMediaQueries?queryId=${queryId}`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in covidStatusUpdate');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		update_facebook_data: (data, headers) =>
			axios
				.put(`${new_url}/queries/updateNotesAndStatusSMQ`, data, headers)
				.then((res) => {
					console.log(res, 'res in covidStatusUpdate');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		bulk_send: (data, headers) =>
			axios
				.put(`${new_url}/sales/sendMessageToRegisteredUsers`, data, headers)
				.then((res) => {
					console.log(res, 'res in covidStatusUpdate');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		send_ivr_message: (data, headers) =>
			axios
				.post(`${new_url}/queries/SendIVRCallMessages`, data, headers)
				.then((res) => {
					console.log(res, 'res in covidStatusUpdate');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		send_ivr_message: (data, headers) =>
			axios
				.post(`${new_url}/queries/SendIVRCallMessages`, data, headers)
				.then((res) => {
					console.log(res, 'res in covidStatusUpdate');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		get_facebook_queries: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/queries/socialMediaQueries?fileId=${
				data.fileId
			}&page=${!!data.page ? data.page : ''}&limit=${
				!!data.limit ? data.limit : ''
			}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		upload_facebook_queries: (data, headers) =>
			axios
				.put(`${new_url}/queries/covidStatusUpdate`, data, headers)
				.then((res) => {
					console.log(res, 'res in covidStatusUpdate');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),

		update_covid_queries: (data, headers) =>
			axios
				.put(`${new_url}/queries/covidStatusUpdate`, data, headers)
				.then((res) => {
					console.log(res, 'res in covidStatusUpdate');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		get_ivr_calls: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/queries/getCallRecords?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
      const newUrl = `${new_url}/queries/getKnowlarityCallRecords?page=${
				!!data.page ? (data.page-1)*10 : ''
			}&limit=${!!data.limit ? data.limit : ''}&client_number=${get_string_params(url, 'client_number') ? get_string_params(url, 'client_number') : ''}&caller_type=&from_date=&to_date=&token=${data.token}`
      console.log(newUrl, "res in GET_IVR_CALLS_API_RES URL");
			return axios
				.get(`${newUrl}`, headers)
				.then((res) => {
          console.log(res, "res in GET_IVR_CALLS_API_RES 2");
					return res.data;
				})
				.catch((err) => {
          console.log(err.response, "GET_IVR_CALLS_API_RES ERR")
          return err.response.data
        })
		},
		// get_ivr_calls: (data, headers) => {
		// 	console.log(data, 'data in get_key_prof_services');
		// 	let url = `${new_url}/queries/getCallRecords?page=${
		// 		!!data.page ? data.page : ''
		// 	}&limit=${!!data.limit ? data.limit : ''}`;
		// 	if (!!data.searchParams) {
		// 		if (data.searchParams !== {}) {
		// 			let keys = Object.keys(data.searchParams);
		// 			console.log(keys, 'keys in api');
		// 			keys.map((item, i) => {
		// 				console.log(item, 'item in data>searchParams');
		// 				url =
		// 					url +
		// 					`&${keys[`${i}`]}=${
		// 						!!data.searchParams[`${keys[i]}`]
		// 							? data.searchParams[`${keys[i]}`]
		// 							: ''
		// 					}`;
		// 			});
		// 		}
		// 	}
		// 	if (!!data.filters) {
		// 		if (data.filters !== {}) {
		// 			let keys = Object.keys(data.filters);
		// 			console.log(keys, 'keys in api');
		// 			keys.map((item, i) => {
		// 				console.log(item, 'item in data>filters');
		// 				url =
		// 					url +
		// 					`&${keys[`${i}`]}=${
		// 						!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
		// 					}`;
		// 			});
		// 		}
		// 	}
		// 	if (!!data.intFilters) {
		// 		if (data.intFilters !== {}) {
		// 			let keys = Object.keys(data.intFilters);
		// 			console.log(keys, 'keys in api');
		// 			keys.map((item, i) => {
		// 				console.log(item, 'item in data>intFilters');
		// 				url =
		// 					url +
		// 					`&${keys[`${i}`]}=${
		// 						data.intFilters[`${keys[i]}`].minimum +
		// 						'by' +
		// 						data.intFilters[`${keys[i]}`].maximum
		// 					}`;
		// 			});
		// 		}
		// 	}
		// 	if (!!data.dateFilters) {
		// 		if (data.dateFilters !== {}) {
		// 			let keys = Object.keys(data.dateFilters);
		// 			console.log(keys, 'keys in api');
		// 			keys.map((item, i) => {
		// 				console.log(item, 'item in data>dateFilters');
		// 				console.log(
		// 					data.dateFilters[`${keys[i]}`],
		// 					'item ksdjksdjkldsjksdjfkdsf'
		// 				);
		// 				if (data.dateFilters[`${keys[i]}`].length !== 0) {
		// 					url =
		// 						url +
		// 						`&${keys[`${i}`]}=${
		// 							data.dateFilters[`${keys[i]}`][0] +
		// 							'-' +
		// 							data.dateFilters[`${keys[i]}`][1]
		// 						}`;
		// 				}
		// 			});
		// 		}
		// 	}
    //   const newUrl = `${new_url}/queries/getCallRecords?page=${
		// 		!!data.page ? data.page : ''
		// 	}&limit=${!!data.limit ? data.limit : ''}&client_number=${get_string_params(url, 'client_number') ? get_string_params(url, 'client_number') : ''}&caller_type=&from_date=&to_date=&token=${data.token}`
    //   console.log(newUrl, "res in GET_IVR_CALLS_API_RES URL");
		// 	return axios
		// 		.get(`${newUrl}`, headers)
		// 		.then((res) => {
    //       console.log(res, "res in GET_IVR_CALLS_API_RES 2");
		// 			return res.data;
		// 		})
		// 		.catch((err) => {
    //       console.log(err.response, "GET_IVR_CALLS_API_RES ERR")
    //       return err.response.data
    //     })
		// },
		get_ivr_sms_token: (data, headers) =>
			axios
				.get(`${new_url}/queries/getToken`, data, headers)
				.then((res) => {
					console.log(res, 'ivr-sms-token res');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'ivr-sms-token err');
					return err;
				}),
		send_solution_sms: (data, headers) =>
			axios
				.put(`${new_url}/regEnquiries/sendBulkMessages`, data, headers)
				.then((res) => {
					console.log(res, 'res in update_professional_byid');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		send_catalogue_report: (data, headers) =>
			axios
				.put(`${new_url}/sales/catalogueUpdateReport`, data, headers)
				.then((res) => {
					console.log(res, 'res in update_professional_byid');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		get_solutions: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/regEnquiries/registeredSearchedSolution?specialityId=${
				data.specialityId
			}&page=${!!data.page ? data.page : ''}&limit=${
				!!data.limit ? data.limit : ''
			}&serviceId=${data.serviceId}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		send_covid_sms: (data, headers) =>
			axios
				.put(
					`${new_url}/queries/sendCovidMessage?queryId=${data.queryId}`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in update_professional_byid');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		send_solutions_sms: (data, solutionId, headers) =>
			axios
				.put(
					`${new_url}/regEnquiries/sendSolutionMessages?solutionId=${solutionId}`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in update_professional_byid');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		send_registered_sms: (data, headers) =>
			axios
				.put(
					`${new_url}/regEnquiries/sendEnquiryMessage?queryId=${data.queryId}`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in update_professional_byid');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		send_public_sms: (data, headers) =>
			axios
				.put(
					`${new_url}/queries/sendQueryMessage?queryId=${data.queryId}`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in update_professional_byid');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		submit_query: (data, headers) =>
			axios
				.post(`${new_url}/queries/ivrCallQueries`, data, headers)
				.then((res) => {
					console.log(res, 'res in update_professional_byid');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
    update_city: (data, flag, headers) =>
      // console.clear()
      // console.error(data, "data in update_city");
      axios
      .put(
        `${new_url}/${
          !(flag === "Registered") ? "queries" : "regEnquiries"
        }/updateCity`,
        data,
        headers
        )
        .then((res) => {
          // console.clear()
          console.log(res, "res in update_city");
          return res.data;
        })
        .catch((err) => {
          // console.clear()
          console.log(err, "err in logut api");
          return err;
        })
    ,
		update_status: (data, flag, headers) =>
			axios
				.put(
					`${new_url}/${
						!(flag === 'Registered') ? 'queries' : 'regEnquiries'
					}/updateNotesAndStatus`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in updateNotesAndStatus');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		update_prospect: (data, flag, headers) =>
			axios
				.put(
					`${new_url}/${
						!(flag === 'Registered') ? 'queries' : 'regEnquiries'
					}/updateProspect`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in updateProspect');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		update_query: (data, flag, headers) =>
			axios
				.put(
					`${new_url}/${
						!(flag === 'Registered') ? 'queries' : 'regEnquiries'
					}/updateQuery`,
					data,
					headers
				)
				.then((res) => {
					console.log(res, 'res in updateProspect');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
      sync_knowlarity_leads: (headers) =>
			axios
				.get(
					`${new_url}/queries/syncCallerDeskCallRecords`,
					headers
				)
				.then((res) => {
					console.log(res, 'res in updateProspect');
					return res.data;
				})
				.catch((err) => {
					console.log(err, 'err in logut api');
					return err;
				}),
		get_merged_queries: (data, headers) => {
			let url = `${new_url}/queries/getMergedUnRegQueries?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_all_queries: (data, headers) => {
			let url = `${new_url}/queries/getAllUnRegQueries?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		download_sales_leads: (data, headers) => {
			let url = `${new_url}/queries/downloadAllUnRegQueries?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_queries_by_number: (data, headers) => {
			let url = `${new_url}/queries/getQueriesByNumber?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_public_queries: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/queries/getAllQueries?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_reg_public_queries: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/regEnquiries/getAllEnquiries?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
		get_covid_queries: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/queries/covidQueries?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url = url + `&${keys[`${i}`]}=${data.filters[`${keys[i]}`]}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res.data;
				})
				.catch((err) => err.response.data);
		},
    get_message_templates: () => {
      return axios
        .get(`${new_url}/queries/messagetemplates`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => err.response.data)
    },
    send_template_sms: (data, queryId, headers) => {
      console.log(data, "RET_TEMPLATE_SMS DATA")
      return axios
        .put(`${new_url}/queries/sentTemplatemessages?queryId=${queryId}`, data, headers)
        .then((res) => {
          console.log(res.data, "RET_TEMPLATE_SMS RES")
          return res.data;
        })
        .catch((err) => {
          console.log(err.response.data, "RET_TEMPLATE_SMS ERR")
          return err.response.data
        })
    },
    send_ivr_template_sms: (data, headers) => {
      return axios
        .put(`${new_url}/queries/sentIVRmessages`, data, headers)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response.data
        })
    },
    send_manual_template_sms: (data, headers) => {
      console.log(data, "SEND_MANUAL_MESSAGE_RES DATA")
      return axios
        .put(`${new_url}/queries/sentManualMessage`, data, headers)
        .then((res) => {
          console.log(res.data, "SEND_MANUAL_MESSAGE_RES RES")
          return res.data;
        })
        .catch((err) => {
          return err.response.data
        })
    },
    get_manual_sms_list: (data, headers) => {
      console.log(data, "MANUAL_MESSAGE_RES DATA")
      let url = `${new_url}/queries/getManualMessages?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
      const newUrl = `${new_url}/queries/getManualMessages?page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}&mobileNumber=${get_string_params(url, 'mobileNumber') ? get_string_params(url, 'mobileNumber') : ''}&name=${get_string_params(url, 'name') ? get_string_params(url, 'name') : ''}`
      console.log(newUrl, "res in GET_MANUAL_MESSAGE_LIST URL");

      return axios
        .get(newUrl, headers, data)
        .then((res) => {
          console.log(res.data, "GET_MANUAL_MESSAGE_LIST RES")
          return res.data;
        })
        .catch((err) => {
          console.log(err.response, "GET_MANUAL_MESSAGE_LIST ERR")
          return err.response.data
        })
    }
	},
  insuranceBookings: {
    getInsuranceBookings: (data, headers) => {
			console.log(data, 'data in get_key_prof_services');
			let url = `${new_url}/booking/getInsuranceBookings?&page=${
				!!data.page ? data.page : ''
			}&limit=${!!data.limit ? data.limit : ''}`;
			if (!!data.searchParams) {
				if (data.searchParams !== {}) {
					let keys = Object.keys(data.searchParams);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>searchParams');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.searchParams[`${keys[i]}`]
									? data.searchParams[`${keys[i]}`]
									: ''
							}`;
					});
				}
			}
			if (!!data.filters) {
				if (data.filters !== {}) {
					let keys = Object.keys(data.filters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>filters');
						url =
							url +
							`&${keys[`${i}`]}=${
								!!data.filters[`${keys[i]}`] ? data.filters[`${keys[i]}`] : ''
							}`;
					});
				}
			}
			if (!!data.intFilters) {
				if (data.intFilters !== {}) {
					let keys = Object.keys(data.intFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>intFilters');
						url =
							url +
							`&${keys[`${i}`]}=${
								data.intFilters[`${keys[i]}`].minimum +
								'by' +
								data.intFilters[`${keys[i]}`].maximum
							}`;
					});
				}
			}
			if (!!data.dateFilters) {
				if (data.dateFilters !== {}) {
					let keys = Object.keys(data.dateFilters);
					console.log(keys, 'keys in api');
					keys.map((item, i) => {
						console.log(item, 'item in data>dateFilters');
						console.log(
							data.dateFilters[`${keys[i]}`],
							'item ksdjksdjkldsjksdjfkdsf'
						);
						if (data.dateFilters[`${keys[i]}`].length !== 0) {
							url =
								url +
								`&${keys[`${i}`]}=${
									data.dateFilters[`${keys[i]}`][0] +
									'-' +
									data.dateFilters[`${keys[i]}`][1]
								}`;
						}
					});
				}
			}
			return axios
				.get(`${url}`, headers)
				.then((res) => {
					console.log(res, 'res in get_professionals');
					return res;
				})
		},
    updateBookingSession: (data, headers) =>
      axios
        .post(`${new_url}/booking/updateBookingSession?bookingId=${data.bookingId}`, data, headers)
        .then(res => {
              return res
    }),
    updateBooking: (data, headers) =>
      axios
        .post(`${new_url}/booking/updateBooking?bookingId=${data.bookingId}&preAuth=${data.preAuth}`, data, headers)
        .then(res => {
              return res
    }),
    addBookingSession: (data, headers) =>
      axios
        .post(`${new_url}/booking/addBookingSession?bookingId=${data.bookingId}`, data, headers)
        .then(res => {
              return res
    }),
    uploadReport: (data, headers) =>
    axios
        .post(`${new_url}/booking/userReport?reportType=${data.type}`, data.files, headers)
        .then(res => {
              return res
        }),
    uploadInsurance: (data, headers) =>
    axios
        .post(`${new_url}/booking/uploadInsurance?insuranceType=${data.type}`, data.files, headers)
        .then(res => {
              return res
        }),
  }
};
