import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Menu,
  Form,
  DatePicker,
  Row,
  Col,
  Descriptions,
  Tag,
  Tabs,
  Popover,
  Divider,
  message,
  Upload,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  DownOutlined,
  UserOutlined,
  SolutionOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  error_form_check,
  isEmpty,
  get_month,
  get_url_params,
} from "../../utils/common_utilities";

import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
//import Servicelist from "./Servicelist"
import { Link } from "react-router-dom";
import validator from "validator";
import moment from "moment";

import User from "./User";
import UploadImageLinks from "./../ProfessionalDetails/UploadImageLinks";
import Modal from "antd/lib/modal/Modal";
import { get_url } from "./../../utils/common_utilities";
const { SubMenu } = Menu;
const { TabPane } = Tabs;

const MedicalDetails = (props) => {
  const [state, set_state] = useState({
    check_status: "PENDING",
  });
  const [confirm_medical_details, set_confirm_medical_details] =
    useState(false);
  const [data, set_data] = useState({
    add_details: "",
    prev_treatment_info: "",
    insurancePartner: "",
    doctors: [],
    insuranceId: "",
    timestamp: "",
    policy: ""
  });

  const [error, set_error] = useState({
    description: { state: false, text: "" },
    insuranceDetails: { state: false, text: "" }
  });

  const [loading, set_loading] = useState(false);
  const [upload_modal, set_upload_modal] = useState(false);
  const [upload_modalInsurance, set_upload_modalInsurance] = useState(false);
  const [initial_data, set_initial_data] = useState({});

  const [reportLink, setReportLink] = useState(null)
  const [insuranceLink, setInsuranceLink] = useState(null)
  const [edit, setEdit] = useState(false)

  const [form] = Form.useForm();

  useEffect(() => {
    if (props.user) {
      set_initial_data({
        ...props.user.data,
      });
    }
  }, []);


  useEffect(()=>{
    if(!!props.upload_user_report_ret){
      console.log(props.upload_user_report_ret,"USER_REPORT_UPLOAD_RET Status")
        // set_bids(modif_data(props.bids))
       if(props.upload_user_report_ret.success){
          message.success(`${props.upload_user_report_ret.data.reports[0].key} file uploaded successfully.`)
          setReportLink(props.upload_user_report_ret.data.reports[0].url)
       }else{
        message.error(`file upload failed.`)
       }
        props.upload_user_report_loading()
    }       
  },[props.upload_user_report_ret])

  useEffect(()=>{
    if(!!props.upload_insurance_report_ret){
      console.log(props.upload_insurance_report_ret,"INSURANCE_REPORT_UPLOAD_RET Status")
        // set_bids(modif_data(props.bids))
       if(props.upload_insurance_report_ret.success){
          message.success(`${props.upload_insurance_report_ret.data.reports.key} file uploaded successfully.`)
          setInsuranceLink(props.upload_insurance_report_ret.data.reports.url)
       }else{
        message.error(`file upload failed.`)
       }
        props.upload_insurance_report_loading()
    }
   
       
  },[props.upload_insurance_report_ret])


  useEffect(()=>{
    if(!!props.get_medical_details_ret){
       if(props.get_medical_details_ret.success){
         setEdit(true)
        const item = props.get_medical_details_ret.data
        set_data({
          ...data,
          add_details: item.additionalDetails,
          prev_treatment_info: item.previousTreatmentDetails,
          reportId: item._id
        })
        if (item.insuranceDetails && item.insuranceDetails[0])
        set_data({
          ...data,
          insurancePartner: item.insuranceDetails[0].insurancePartner,
          insuranceId: item.insuranceDetails[0].insuranceId,
          policy: item.insuranceDetails[0].policy
        })
        if (item.reportUrl.length > 0)
          setReportLink(item.reportUrl[0].reportUrl)
      }
    }     
  },[props.get_medical_details_ret])

useEffect(()=>{
  if(!!props.submit_medical_details_ret){
    console.log(props.submit_medical_details_ret,"USER_REPORT_UPLOAD_RET Status")
      // set_bids(modif_data(props.bids))
     if(props.submit_medical_details_ret.success){
      message.success(props.submit_medical_details_ret.message)
      const userId = props.user.data._id;
      props.get_medical_details({
        type: "get_medical_details",
        userId: userId,
      })
     }else{
      message.error(`Medical Details couldn't be uploaded`)
     }
      props.submit_medical_details_loading()
  }     
},[props.submit_medical_details_ret])

useEffect(()=>{
  if(!!props.edit_medical_details_ret){
    console.log(props.edit_medical_details_ret,"USER_REPORT_UPDATE_RET Status")
      // set_bids(modif_data(props.bids))
     if(props.edit_medical_details_ret.success){
      message.success(props.edit_medical_details_ret.message)
      const userId = props.user.data._id;
      props.get_medical_details({
        type: "get_medical_details",
        userId: userId,
      })
     }else{
      message.error(props.edit_medical_details_ret.message)
     }
      props.edit_medical_details_loading()
  }
 
     
},[props.edit_medical_details_ret])

const beforeUpload = (file, fileType) => {
  let isJpgOrPng = false
  if (fileType === "IMAGE") {
    isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
  }
  if (fileType === "REPORT") {
    isJpgOrPng = file.type === "application/pdf"
    if (!isJpgOrPng) {
      message.error("You can only upload PDF file!");
    }
  }
  if (fileType === "VIDEO") {
    let type = file.type.split("/")[0]
    isJpgOrPng = type === "video"
    if (!isJpgOrPng) {
      message.error("You can only upload Video file!");
    }
  }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }
  return isJpgOrPng;
};

const beforeUploadInsurance = (file) => {
  let isJpgOrPng = false
    let type = file.type.split("/")[0]
    isJpgOrPng = type === "video" || file.type === "application/pdf"
    if (!isJpgOrPng) {
      message.error("Please Select video/pdf to upload!");
    }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }
  return isJpgOrPng;
};

  const handle_upload_change = (event) => {
    // if (event.file.status === 'uploading') {
    //   set_loading(true);
    //   return;
    // }
    if (event.file.status === "done") {
      // Get this url from response in real world.
      // getBase64(event.file.originFileObj, imageUrl =>
      //   this.setState({
      //     imageUrl,
      //     loading: false,
      //   }),
      // );
      // message.success("File uploaded successfully.");
    }
  };

  const uploadReport = (file, type) => {
    // console.log(file, "IMAGE_UPLOAD_RESPONSE REDUX")
    const uploadReportData = {
      file: file.file,
      userId: props.user.data._id,
      type: type
    }

    props.upload_user_report(uploadReportData)
    file.onSuccess("Success:true", file.file)
  }

  const uploadReportInsurance = (file, type) => {
    // console.log(file, "IMAGE_UPLOAD_RESPONSE REDUX")
    const uploadReportData = {
      file: file.file,
      userId: props.user.data._id,
      type: type
    }

    props.upload_insurance_report(uploadReportData)
    file.onSuccess("Success:true", file.file)
  }


  const popover_content = (
    <div>
      <Modal
        width="90rem"
        title={`Add ${upload_modal}`}
        visible={upload_modal}
        onCancel={() => set_upload_modal(false)}
        footer={null}
      >
        <Upload
          name="file"
          customRequest={(file) => uploadReport(file, upload_modal)}
          multiple={false}
          maxCount={1}
          beforeUpload={(file) =>  beforeUpload(file, upload_modal)}
          onChange={handle_upload_change}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Modal>
      <div className="select-report-type" onClick={() => set_upload_modal("IMAGE")}>Photos</div>
      <div className="select-report-type" onClick={() => set_upload_modal("VIDEO")}>Videos</div>
      <div className="select-report-type" onClick={() => set_upload_modal("REPORT")}>Pdf</div>
    </div>
  );

  const document_popover_content = (
    <div>
      <Modal
        width="90rem"
        title={`Add ${upload_modalInsurance}`}
        visible={upload_modalInsurance}
        onCancel={() => set_upload_modalInsurance(false)}
        footer={null}
      >
        <Upload
          name="file"
          customRequest={(file) => uploadReportInsurance(file, upload_modalInsurance)}
          multiple={false}
          maxCount={1}
          beforeUpload={(file) =>  beforeUploadInsurance(file)}
          onChange={handle_upload_change}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Modal>
      <div className="select-report-type" onClick={() => set_upload_modalInsurance("CARD")}>Card</div>
      <div className="select-report-type" onClick={() => set_upload_modalInsurance("POLICY")}>Policy</div>
    </div>
  );

  const validate = (data) => {
    const error = {
      description: { state: false, text: "" },
      insuranceDetails: { state: false, text: "" }
    };
    if (data.description === "") {
      {
        error.description.text = "Enter description";
        error.description.state = true;
      }
    }
    if (data.insuranceDetails.insuranceId === "" || data.insuranceDetails.insurancePartner === "" || data.insuranceDetails.policy === "")
    {
      error.insuranceDetails.text = "Enter description";
      error.insuranceDetails.state = true;      
    }
    return error;
  };
  const submit = () => {
    // const ret_error = validate({ ...data });
    // set_error({ ...error, ...ret_error });
    // console.log(ret_error, "ret_error in add_member");
    console.log(data, "Submit_Medical_Details")
    const submitData = {
      userId: props.user.data._id,
      previousTreatmentDetails: data.prev_treatment_info,
      description: data.description,
      additionalDetails: data.add_details,
      insuranceDetails: {
        insuranceId: data.insuranceId,
        insurancePartner: data.insurancePartner,
        policy: data.policy
      },
    }
    let userReports = []
    reportLink && userReports.push({url: reportLink})
    submitData.reportUrls = userReports
    if (submitData.previousTreatmentDetails.length > 0)
      submitData.treatedPreviously = true
    if (edit)
      submitData.reportId = data.reportId
    edit ? props.edit_medical_details(submitData) : props.submit_medical_details(submitData)
  };

  return (
    <Card
      bodyStyle={{
        height: "52rem",
        overflowY: "scroll",
        padding: "1rem 2rem 1rem 1rem",
        backgroundColor: "#F9F9F9",
      }}
      bordered={false}
    >
      <Form label form={form} layout="vertical">
        <Form.Item noStyle>
          <AntdInput
            label={<strong>Additional Details Of the Service</strong>}
            value={data.add_details}
            // options={props.global_store.specialities}
            // loading={props.global_store.get_specialities_loading}
            // error_text={error.speciality.text}
            // toggle_error={() =>
            //   set_error({
            //     ...error,
            //     speciality: { text: "", state: false },
            //   })
            // }
            // error={error.speciality.state}
            name="Additional Details"
            type="textarea"
            onChange={(event) => {
              set_data({
                ...data,
                add_details: event.target.value,
              });
            }}
            placeholder="Enter Additional Details of the user"
            rows={3}
          />
        </Form.Item>
        <Form.Item noStyle>
          <AntdInput
            label="Previous Treatment Details"
            value={data.prev_treatment_info}
            // options={props.global_store.services}
            loading={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            disabled={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            // error_text={error.service.text}
            // toggle_error={() =>
            //   set_error({
            //     ...error,
            //     service: { text: "", state: false },
            //   })
            // }
            // error={error.service.state}
            name="prev_treatment_details"
            type="textarea"
            onChange={(event) => {
              set_data({
                ...data,
                prev_treatment_info: event.target.value,
              });
            }}
            placeholder="Enter Previous Treatment Details"
          />
        </Form.Item>
        <Form.Item>
          <Divider orientation="left">
            <Popover
              placement="bottom"
              content={popover_content}
              trigger="click"
              // visible={set_visible(true)}
              // onVisibleChange={set_visible(true)}
            >
              <Button type="link">
                Attach report <PaperClipOutlined />
              </Button>
            </Popover>
          </Divider>
          {reportLink && <a href={reportLink}>Report Link</a>}
        </Form.Item>
        {/* <Form.Item noStyle>
          <Divider orientation="left">
            <Popover
              placement="bottom"
              content={document_popover_content}
              style={{ color: "black !important" }}
              trigger="click"
              // visible={set_visible(true)}
              // onVisibleChange={set_visible(true)}
            >
              <Button type="link">
                Attach Insurance details <PaperClipOutlined />
              </Button>
            </Popover>
          </Divider>
          {insuranceLink && <a href={insuranceLink}>Insurance Link</a>}
          <br/><br/>
        </Form.Item>*/}
        <Form.Item noStyle> 
          <AntdInput
            label="Insurance Partner"
            value={data.insurancePartner}
            loading={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            disabled={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            // error_text={error.doctor.text}
            // toggle_error={() =>
            //   set_error({
            //     ...error,
            //     doctor: { text: "", state: false },
            //   })
            // }
            // error={error.doctor.state}
            name="insurancePartner"
            onChange={(event) =>
              set_data({ ...data, insurancePartner: event.target.value })
            }
            type="input"
            placeholder="Enter Insurance Partner"
          />
        </Form.Item>
        <Form.Item noStyle>
          <AntdInput
            label="Policy Name"
            value={data.policy}
            loading={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            disabled={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            // error_text={error.doctor.text}
            // toggle_error={() =>
            //   set_error({
            //     ...error,
            //     doctor: { text: "", state: false },
            //   })
            // }
            // error={error.doctor.state}
            name="policy"
            type="input"
            onChange={(event) =>
              set_data({ ...data, policy: event.target.value })
            }
            placeholder="Enter Policy Name"
          />
        </Form.Item>
        <Form.Item noStyle>
          <AntdInput
            label="Insurance Id"
            value={data.insuranceId}
            // error_text={error.amount.text}
            loading={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            disabled={props.gget_medical_details_loading_flag || props.submit_medical_details_loading_flag || props.edit_medical_details_loading_flag}
            // toggle_error={() =>
            //   set_error({
            //     ...error,
            //     amount: { text: "", state: false },
            //   })
            // }
            // error={error.amount.state}
            name="insuranceId"
            onChange={(event) =>
              set_data({ ...data, insuranceId: event.target.value })
            }
            placeholder="Enter Policy Number"
          />
          {/* <Tag
                      onClick={max_dicount_click}
                      style={{ cursor: "pointer" }}
                      icon={<CheckCircleOutlined />}
                      color="success"
                    >
                      Min Price
                    </Tag> */}
        </Form.Item>

        <div className=" u-margin-top-small text-center">
          <Button
            type="primary"
            onClick={() => submit()}
            style={{ width: "auto", margin: "auto" }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Card>
  );
};
export default MedicalDetails;
