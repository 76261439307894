import React from "react"
import { Alert, Card } from "antd"

const NotificationList = (props) => {
        return (
            <React.Fragment>
                     <Card title="Notifications">
                            {props.notification_data.data.map(item=>{
                                return <div className="u-margin-top-mini">
                                  <a href={`${item.notificationType === "query" ? "/dashboard/salesfunnel?queries_list" : "#"}`}> <Alert
                                  message={item.queryType}
                                  description={item.notificationData}
                                  type="success"
                                />
                              </a>
                            </div>
                            })}
                     </Card>
            </React.Fragment>
        )
}

export default NotificationList