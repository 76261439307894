import {GET_CATALOGUE,GET_CATALOGUE_RET,GET_CATALOGUE_BYID,GET_CATALOGUE_BYID_RET,CATALOGUE_VIEW} from '../Constants';

export const get_catalogue = () => {
    return {
        type: GET_CATALOGUE
    }
}

export const get_catalogue_ret = (data) => {
    return {
        type: GET_CATALOGUE_RET,
        data
    }
}

export const get_catalogue_byid = (data) => {
    return {
        type: GET_CATALOGUE_BYID,data
    }
}

export const get_catalogue_byid_ret = (data) => {
    return {
        type: GET_CATALOGUE_BYID_RET,data
    }
}

export const catalogue_view = (data) => {
    return {
        type: CATALOGUE_VIEW,data
    }
}

// export const get_catalogue_loading = (data) => {
//     return {
//         type: GET_CATALOGUE_LOADING,
//         data
//     }
// }