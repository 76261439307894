import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services, get_service_families, get_service_families_loading } from "../actions/global"
import {
	get_key_prof, get_key_prof_loading, get_key_prof_services,
	get_key_prof_services_loading, send_variance_info, send_variance_info_loading, set_key_manager_professionals,
	previous_variance_list, previous_variance_list_loading, previous_business_reports, previous_business_reports_loading,
	send_business_reports, send_business_reports_loading, send_catalogue_report, send_catalogue_report_loading,
	email_preview, email_preview_loading, send_speciality_report, send_speciality_report_loading, sent_speciality_reports,
	sent_speciality_reports_loading, sent_catalogue_reports, sent_catalogue_reports_loading, add_service, add_service_loading,
	service_data_handler, service_data_handler_loading, get_service_family_byid, get_service_family_byid_loading,
	get_service_family_list_by_specialityid, get_service_family_list_by_specialityid_loading, add_insurance,
	add_insurance_loading, update_faq, update_faq_loading,
	updateMarketingPage, updateMarketingPage_loading,
	update_advanced_treatment, update_advanced_treatment_loading,
	upload_advanced_treatment_image, upload_advanced_treatment_image_loading,
	upload_advanced_treatment_icon, upload_advanced_treatment_icon_loading
} from "../actions/keyManager"

import { connect } from 'react-redux';
import Notif from '../Components/Notif';
import ProfessionalsList from '../Views/KeyManager';
import { get_url_params } from '../utils/common_utilities';
import SendData from '../Views/KeyManager/SendData';
import AddService from '../Views/KeyManager/AddService';
import ServiceDataHandler from '../Views/KeyManager/serviceDataHandle'
import CampaignServiceHandler from "../Views/KeyManager/CampaignServiceHandler";
import ServiceMetaData from "../Views/ServicesMetaData";
import MostBookedServices from "../Views/MostBookedServices";

class KeyManager extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			valid: true,
		};
	}

	render() {
		// console.log(this.props, 'this.props  in Professional_container');
		return (
			<React.Fragment>
				<div>
					{this.props.location.search === '?professionals_list' ? (
						<React.Fragment>
							<ProfessionalsList

								add_insurance={this.props.add_insurance}
								add_insurance_ret={
									this.props.keymanager_store.add_insurance_ret
								}
								add_insurance_loading={this.props.add_insurance_loading}
								add_insurance_loading_flag={
									this.props.keymanager_store.add_insurance_loading
								}

								get_key_prof={this.props.get_key_prof}
								get_key_prof_ret={this.props.keymanager_store.get_key_prof_ret}
								get_key_prof_loading={this.props.get_key_prof_loading}
								get_key_prof_loading_flag={
									this.props.keymanager_store.get_key_prof_loading
								}
								global_error={this.props.global_error}
								get_key_prof_services={this.props.get_key_prof_services}
								get_key_prof_services_loading={
									this.props.get_key_prof_services_loading
								}
								get_key_prof_services_loading_flag={
									this.props.keymanager_store.get_key_prof_services_loading
								}
								get_key_prof_services_ret={
									this.props.keymanager_store.get_key_prof_services_ret
								}
								global_store={this.props.global_store}
								get_services={this.props.get_services}
								set_key_manager_professionals={
									this.props.set_key_manager_professionals
								}
							/>
						</React.Fragment>
					) : this.props.location.search === '?add-service' ? (
						<React.Fragment>
							<AddService
								add_service_loading={this.props.add_service_loading}
								add_service={this.props.add_service}
								add_service_ret={this.props.keymanager_store.add_service_ret}
								add_service_loading_flag={
									this.props.keymanager_store.add_service_loading
								}
								get_services={this.props.get_services}
								global_error={this.props.global_error}
								global_store={this.props.global_store}
								keymanager_store={this.props.keymanager_store}
							/>
						</React.Fragment>) : this.props.location.search === "?service-data-handler" ? (<React.Fragment>
							<ServiceDataHandler

								service_data_handler_loading={this.props.service_data_handler_loading}
								service_data_handler={this.props.service_data_handler}
								service_data_handler_ret={this.props.keymanager_store.service_data_handler_ret}
								service_data_handler_loading_flag={this.props.keymanager_store.service_data_handler_loading}

								get_service_family_byid_loading={this.props.get_service_family_byid_loading}
								get_service_family_byid={this.props.get_service_family_byid}
								get_service_family_byid_ret={this.props.keymanager_store.get_service_family_byid_ret}
								get_service_family_byid_loading_flag={this.props.keymanager_store.get_service_family_byid_loading}

								get_service_family_list_by_specialityid_loading={this.props.get_service_family_list_by_specialityid_loading}
								get_service_family_list_by_specialityid={this.props.get_service_family_list_by_specialityid}
								get_service_family_list_by_specialityid_ret={this.props.keymanager_store.get_service_family_list_by_specialityid_ret}
								get_service_family_list_by_specialityid_loading_flag={this.props.keymanager_store.get_service_family_list_by_specialityid_loading}

								get_services={this.props.get_services}

								get_service_families={this.props.get_service_families}
								get_service_families_loading={this.props.get_service_families_loading}

								update_faq={this.props.update_faq}
								update_faq_loading={this.props.update_faq_loading}
								update_faq_ret={this.props.keymanager_store.update_faq_ret}
								update_faq_loading_flag={this.props.keymanager_store.update_faq_loading}


								global_error={this.props.global_error}
								global_store={this.props.global_store}
								keymanager_store={this.props.keymanager_store}
							/>
						</React.Fragment>) : this.props.location.search === "?campaign-service-data-handler" ? (<React.Fragment>
							<CampaignServiceHandler

								updateMarketingPage_loading={this.props.updateMarketingPage_loading}
								updateMarketingPage={this.props.updateMarketingPage}
								updateMarketingPage_ret={this.props.keymanager_store.update_marketing_page_ret}
								updateMarketingPage_loading_flag={this.props.keymanager_store.update_marketing_page_loading}

								update_faq={this.props.update_faq}
								update_faq_loading={this.props.update_faq_loading}
								update_faq_ret={this.props.keymanager_store.update_faq_ret}
								update_faq_loading_flag={this.props.keymanager_store.update_faq_loading}

								update_advanced_treatment={this.props.update_advanced_treatment}
								update_advanced_treatment_loading={this.props.update_advanced_treatment_loading}
								update_advanced_treatment_ret={this.props.keymanager_store.update_advanced_treatment_ret}
								update_advanced_treatment_loading_flag={this.props.keymanager_store.update_advanced_treatment_loading}

								upload_advanced_treatment_image={this.props.upload_advanced_treatment_image}
								upload_advanced_treatment_image_loading={this.props.upload_advanced_treatment_image_loading}
								upload_advanced_treatment_image_ret={this.props.keymanager_store.upload_advanced_treatment_image_ret}
								upload_advanced_treatment_image_loading_flag={this.props.keymanager_store.upload_advanced_treatment_image_loading}

								upload_advanced_treatment_icon={this.props.upload_advanced_treatment_icon}
								upload_advanced_treatment_icon_loading={this.props.upload_advanced_treatment_icon_loading}
								upload_advanced_treatment_icon_ret={this.props.keymanager_store.upload_advanced_treatment_icon_ret}
								upload_advanced_treatment_icon_loading_flag={this.props.keymanager_store.upload_advanced_treatment_icon_loading}

								specialities_options={this.props.global_store.specialities}
								specialities_loading={this.props.global_store.get_specialities_loading}

								global_error={this.props.global_error}
								global_store={this.props.global_store}
								keymanager_store={this.props.keymanager_store}
							/>
						</React.Fragment>) : this.props.location.search === '?send_data' ? (
							<SendData
								global_error={this.props.global_error}
								sent_catalogue_reports={this.props.sent_catalogue_reports}
								sent_catalogue_reports_ret={
									this.props.keymanager_store.sent_catalogue_reports_ret
								}
								sent_catalogue_reports_loading_flag={
									this.props.keymanager_store.sent_catalogue_reports_loading
								}
								sent_catalogue_reports_loading={
									this.props.sent_catalogue_reports_loading
								}
								sent_speciality_reports={this.props.sent_speciality_reports}
								sent_speciality_reports_ret={
									this.props.keymanager_store.sent_speciality_reports_ret
								}
								sent_speciality_reports_loading_flag={
									this.props.keymanager_store.sent_speciality_reports_loading
								}
								sent_speciality_reports_loading={
									this.props.sent_speciality_reports_loading
								}
								send_variance_info={this.props.send_variance_info}
								send_variance_info_loading={this.props.send_variance_info_loading}
								send_variance_info_loading_flag={
									this.props.keymanager_store.send_variance_info_loading
								}
								send_variance_info_ret={
									this.props.keymanager_store.send_variance_info_ret
								}
								set_key_manager_professionals={
									this.props.set_key_manager_professionals
								}
								send_business_reports={this.props.send_business_reports}
								send_business_reports_loading={
									this.props.send_business_reports_loading
								}
								send_business_reports_loading_flag={
									this.props.keymanager_store.send_business_reports_loading
								}
								send_business_reports_ret={
									this.props.keymanager_store.send_business_reports_ret
								}
								previous_business_reports={this.props.previous_business_reports}
								previous_business_reports_loading={
									this.props.previous_business_reports_loading
								}
								previous_business_reports_loading_flag={
									this.props.keymanager_store.previous_business_reports_loading
								}
								previous_business_reports_ret={
									this.props.keymanager_store.previous_business_reports_ret
								}
								previous_variance_list={this.props.previous_variance_list}
								previous_variance_list_loading={
									this.props.previous_variance_list_loading
								}
								previous_variance_list_loading_flag={
									this.props.keymanager_store.previous_variance_list_loading
								}
								previous_variance_list_ret={
									this.props.keymanager_store.previous_variance_list_ret
								}
								send_catalogue_report={this.props.send_catalogue_report}
								send_catalogue_report_loading={
									this.props.send_catalogue_report_loading
								}
								send_catalogue_report_loading_flag={
									this.props.keymanager_store.send_catalogue_report_loading
								}
								send_catalogue_report_ret={
									this.props.keymanager_store.send_catalogue_report_ret
								}
								send_speciality_report={this.props.send_speciality_report}
								send_speciality_report_loading={
									this.props.send_speciality_report_loading
								}
								send_speciality_report_loading_flag={
									this.props.keymanager_store.send_speciality_report_loading
								}
								send_speciality_report_ret={
									this.props.keymanager_store.send_speciality_report_ret
								}
								get_services={this.props.get_services}
								email_preview={this.props.email_preview}
								email_preview_loading={this.props.email_preview_loading}
								email_preview_ret={this.props.keymanager_store.email_preview_ret}
								email_preview_loading_flag={
									this.props.keymanager_store.email_preview_loading
								}
								global_store={this.props.global_store}
								keymanager_store={this.props.keymanager_store}
							/>
						) : this.props.location.search === '?set-meta-data' ?
						<ServiceMetaData
							add_service_loading_flag={
								this.props.keymanager_store.add_service_loading
							}
							global_store={this.props.global_store} /> : this.props.location.search === '?most-booked-services' ?
							<MostBookedServices
								add_service_loading_flag={
									this.props.keymanager_store.add_service_loading
								}
								global_store={this.props.global_store} /> : (
								<React.Fragment></React.Fragment>
							)}
					<Notif
						global_error={this.props.global_error}
						global_error_ret={this.props.global_error_ret}
						global_error_clr={this.props.global_error_clr}
					/>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	auth_store: state.auth_store,
	global_store: state.global_store,
	keymanager_store: state.keymanager_store,
	global_error_ret: state.global_store.global_error_ret,
	global_store: state.global_store,
});

export default connect(mapStateToProps, {
	global_error,
	global_error_clr,
	global_loading_set,
	get_key_prof,
	get_key_prof_loading,
	get_key_prof_services,
	get_key_prof_services_loading,
	send_variance_info,
	send_variance_info_loading,
	set_key_manager_professionals,
	previous_variance_list,
	send_catalogue_report,
	send_catalogue_report_loading,
	previous_variance_list_loading,
	send_business_reports,
	send_business_reports_loading,
	previous_business_reports,
	previous_business_reports_loading,
	get_services,
	email_preview,
	email_preview_loading,
	send_speciality_report,
	send_speciality_report_loading,
	sent_speciality_reports,
	sent_speciality_reports_loading,
	sent_catalogue_reports,
	sent_catalogue_reports_loading,
	add_service,
	add_service_loading,
	service_data_handler,
	service_data_handler_loading,
	get_service_family_byid,
	get_service_family_byid_loading,
	get_service_family_list_by_specialityid,
	get_service_family_list_by_specialityid_loading,
	get_service_families,
	get_service_families_loading,
	update_faq,
	update_faq_loading,
	update_advanced_treatment,
	update_advanced_treatment_loading,
	upload_advanced_treatment_image,
	upload_advanced_treatment_image_loading,
	upload_advanced_treatment_icon,
	upload_advanced_treatment_icon_loading,
	updateMarketingPage,
	updateMarketingPage_loading
})(KeyManager)
