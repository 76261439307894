import { GET_PUBLIC_QUERIES, GET_PUBLIC_QUERIES_LOADING, GET_PUBLIC_QUERIES_RET, GET_COVID_QUERIES, 
         GET_COVID_QUERIES_RET, GET_COVID_QUERIES_LOADING, SUBMIT_QUERY, SUBMIT_QUERY_RET, SUBMIT_QUERY_LOADING, UPDATE_CITY, UPDATE_CITY_LOADING, UPDATE_CITY_RET,
         UPDATE_STATUS_LOADING, UPDATE_STATUS_RET, UPDATE_STATUS, GET_REG_PUBLIC_QUERIES, GET_REG_PUBLIC_QUERIES_RET, 
         GET_REG_PUBLIC_QUERIES_LOADING, SEND_SMS_LOADING, SEND_SMS_RET, SEND_SMS, GET_SOLUTIONS_LOADING,
         GET_SOLUTIONS_RET, GET_SOLUTIONS, SEND_SOLUTION_SMS, SEND_SOLUTION_SMS_LOADING, SEND_SOLUTION_SMS_RET ,
          GET_IVR_CALLS_LOADING, GET_IVR_CALLS_RET, GET_IVR_CALLS, UPDATE_COVID_QUERIES_LOADING, UPDATE_COVID_QUERIES,
          UPDATE_COVID_QUERIES_RET , GET_FACEBOOK_QUERIES_LOADING, GET_FACEBOOK_QUERIES_RET, GET_FACEBOOK_QUERIES, 
          UPLOAD_FACEBOOK_QUERIES, UPLOAD_FACEBOOK_QUERIES_LOADING, UPLOAD_FACEBOOK_QUERIES_RET, SEND_IVR_MESSAGE_LOADING,
          SEND_IVR_MESSAGE_RET, SEND_IVR_MESSAGE, BULK_SEND_LOADING, BULK_SEND, BULK_SEND_RET, UPDATE_FACEBOOK_DATA_LOADING,
          UPDATE_FACEBOOK_DATA_RET, UPDATE_FACEBOOK_DATA, GET_IVR_MESSAGES_LOADING, GET_IVR_MESSAGES_RET, GET_IVR_MESSAGES,
          GET_UPLOAD_LIST_LOADING, GET_UPLOAD_LIST_RET, GET_UPLOAD_LIST, GET_BULK_SMS_LIST_LOADING, GET_BULK_SMS_LIST_RET, GET_BULK_SMS_LIST,
          GET_MANUAL_BIDDINGS, GET_MANUAL_BIDDINGS_RET, GET_MANUAL_BIDDINGS_LOADING, 
          GET_MESSAGE_TEMPLATES, GET_MESSAGE_TEMPLATES_RET, SEND_TEMPLATE_SMS, SEND_TEMPLATE_SMS_RET, SEND_TEMPLATE_SMS_LOADING, SEND_IVR_TEMPLATE_SMS, 
          GET_IVR_SMS_TOKEN, GET_IVR_SMS_TOKEN_RET, GET_IVR_SMS_TOKEN_LOADING, SEND_MANUAL_TEMPLATE_SMS, SEND_MANUAL_TEMPLATE_SMS_RET, SEND_MANUAL_TEMPLATE_SMS_LOADING, GET_MANUAL_SMS_LIST, GET_MANUAL_SMS_LIST_RET, GET_MANUAL_SMS_LIST_LOADING, 
          GET_ALL_QUERIES, GET_ALL_QUERIES_RET, GET_ALL_QUERIES_LOADING, 
          GET_QUERIES_BY_NUMBER, GET_QUERIES_BY_NUMBER_RET, GET_QUERIES_BY_NUMBER_LOADING, 
          GET_MERGED_QUERIES, GET_MERGED_QUERIES_RET, GET_MERGED_QUERIES_LOADING, 
          UPDATE_PROSPECT, UPDATE_PROSPECT_RET, UPDATE_PROSPECT_LOADING, 
          UPDATE_QUERY, UPDATE_QUERY_RET, UPDATE_QUERY_LOADING, 
          GET_CAMPAIGN_SPECIALITY_AND_SERVICES, GET_CAMPAIGN_SPECIALITY_AND_SERVICES_RET, GET_CAMPAIGN_SPECIALITY_AND_SERVICES_LOADING, SYNC_KNOWLARITY_LEADS, SYNC_KNOWLARITY_LEADS_RET, SYNC_KNOWLARITY_LEADS_LOADING, DOWNLOAD_SALES_LEADS, DOWNLOAD_SALES_LEADS_RET, DOWNLOAD_SALES_LEADS_LOADING } from "../Constants"


          export const get_manual_biddings = (data) => {
            return {
                type:GET_MANUAL_BIDDINGS,
                data
            }
        } 

        export const get_manual_biddings_ret = (data) => {
            return {
                type:GET_MANUAL_BIDDINGS_RET,
                data
            }
        } 

        export const get_manual_biddings_loading = (data) => {
            return {
                type:GET_MANUAL_BIDDINGS_LOADING,
                data
            }
        } 

        export const get_bulk_sms_list = (data) => {
            return {
                type:GET_BULK_SMS_LIST,
                data
            }
        }  
        export const get_bulk_sms_list_ret = (data) => {
            return {
                type:GET_BULK_SMS_LIST_RET,
                data
            }
        }    
        export const get_bulk_sms_list_loading = (data) => {
            return {
                type:GET_BULK_SMS_LIST_LOADING,
                data
            }
        }
        export const get_upload_list = (data) => {
            return {
                type:GET_UPLOAD_LIST,
                data
            }
        }    
        export const get_upload_list_ret = (data) => {
            return {
                type:GET_UPLOAD_LIST_RET,
                data
            }
        }        
        export const get_upload_list_loading = (data) => {
            return {
                type:GET_UPLOAD_LIST_LOADING,
                data
            }
        }        

       export const get_ivr_messages = (data) => {
            return {
                type:GET_IVR_MESSAGES,
                data
            }
        }

        export const get_ivr_messages_ret = (data) => {
            return {
                type:GET_IVR_MESSAGES_RET,
                data
            }
        }

        export const get_ivr_messages_loading = (data) => {
            return {
                type:GET_IVR_MESSAGES_LOADING,
                data
            }
        }


    export const update_facebook_data = (data) => {
        return {
            type:UPDATE_FACEBOOK_DATA,
            data
        }
    }



    export const update_facebook_data_ret = (data) => {
        return {
            type:UPDATE_FACEBOOK_DATA_RET,
            data
        }
    }
    
    export const update_facebook_data_loading = (data) => {
        return {
            type:UPDATE_FACEBOOK_DATA_LOADING,
            data
        }
    }

        export const bulk_send = (data) => {
            return {
                type:BULK_SEND,
                data
            }
        }

        export const bulk_send_ret = (data) => {
            return {
                type:BULK_SEND_RET,
                data
            }
        }


        export const bulk_send_loading = (data) => {
            return {
                type:BULK_SEND_LOADING,
                data
            }
        }

      export const upload_facebook_queries = (data) => {
        return {
            type:UPLOAD_FACEBOOK_QUERIES,
            data
        }
      }

      export const send_ivr_message = (data) => {
        return {
            type:SEND_IVR_MESSAGE,
            data
        }
      }

      export const send_ivr_message_ret = (data) => {
        return {
            type:SEND_IVR_MESSAGE_RET,
            data
        }
      }

      export const send_ivr_message_loading = (data) => {
        return {
            type:SEND_IVR_MESSAGE_LOADING,
            data
        }
      }

      export const upload_facebook_queries_ret = (data) => {
        return {
            type:UPLOAD_FACEBOOK_QUERIES_RET,
            data
        }
      }

      export const upload_facebook_queries_loading = (data) => {
        return {
            type:UPLOAD_FACEBOOK_QUERIES_LOADING,
            data
        }
      }
          
       export const get_facebook_queries = (data) => {
            return {
                type:GET_FACEBOOK_QUERIES,
                data
            }
        }

        export const get_facebook_queries_ret = (data) => {
            return {
                type:GET_FACEBOOK_QUERIES_RET,
                data
            }
        }

        export const get_facebook_queries_loading = (data) => {
            return {
                type:GET_FACEBOOK_QUERIES_LOADING,
                data
            }
        }
        export const update_covid_queries = (data) => {
            return {
                type:UPDATE_COVID_QUERIES,
                data
            }
        }

        export const update_covid_queries_ret = (data) => {
            return {
                type:UPDATE_COVID_QUERIES_RET,
                data
            }
        }
        export const update_covid_queries_loading = (data) => {
            return {
                type:UPDATE_COVID_QUERIES_LOADING,
                data
            }
        }

         export const get_ivr_calls = (data) => {
            return {
                type:GET_IVR_CALLS,
                data
            }
        }
        
        export const get_ivr_calls_ret = (data) => {
            return {
                type:GET_IVR_CALLS_RET,
                data
            }
        }
        export const get_ivr_calls_loading = (data) => {
            return {
                type:GET_IVR_CALLS_LOADING,
                data
            }
        }


export const send_solution_sms = (data) => {
    return {
            type:SEND_SOLUTION_SMS,
            data
    }
}
export const send_solution_sms_ret = (data) => {
    return {
            type:SEND_SOLUTION_SMS_RET,
            data
    }
}
export const send_solution_sms_loading = (data) => {
    return {
            type:SEND_SOLUTION_SMS_LOADING,
            data
    }
}
export const get_solutions = (data) => {
    return {
         type:GET_SOLUTIONS,
          data
    }
}
export const get_solutions_ret = (data) => {
    return {
         type:GET_SOLUTIONS_RET,
          data
    }
}

export const get_solutions_loading = (data) => {
    return {
         type:GET_SOLUTIONS_LOADING,
          data
    }
}
export const send_sms = (data) => {
    return {
        type:SEND_SMS,
        data
    }
}
export const send_sms_ret = (data) => {
    return {
        type:SEND_SMS_RET,
        data
    }
}
export const send_sms_loading = (data) => {
    return {
        type:SEND_SMS_LOADING,
        data
    }
}

export const get_reg_public_queries = (data) => {
    return {
        type:GET_REG_PUBLIC_QUERIES,
        data
    }
}
export const get_reg_public_queries_ret = (data) => {
    return {
        type:GET_REG_PUBLIC_QUERIES_RET,
        data
    }
}
export const get_reg_public_queries_loading = (data) => {
    return {
        type:GET_REG_PUBLIC_QUERIES_LOADING,
        data
    }
}
export const update_city = (data) => {
    return {
        type:UPDATE_CITY,
        data
    }
}
export const update_city_ret = (data) => {
    return {
        type:UPDATE_CITY_RET,
        data
    }
}
export const update_city_loading = (data) => {
    return {
        type:UPDATE_CITY_LOADING,
        data
    }
}
export const update_status = (data) => {
    return {
        type:UPDATE_STATUS,
        data
    }
}
export const update_status_ret = (data) => {
    return {
        type:UPDATE_STATUS_RET,
        data
    }
}
export const update_status_loading = (data) => {
    return {
        type:UPDATE_STATUS_LOADING,
        data
    }
}
export const get_public_queries = (data) => {
    return {
        type:GET_PUBLIC_QUERIES,
        data
    }
}
export const get_public_queries_ret = (data) => {
    return {
        type:GET_PUBLIC_QUERIES_RET,
        data
    }
}
export const get_public_queries_loading = (data) => {
    return {
        type:GET_PUBLIC_QUERIES_LOADING,
        data
    }
}

export const get_covid_queries = (data) => {
    return {
        type:GET_COVID_QUERIES,
        data
    }
}

export const get_covid_queries_ret = (data) => {
    return {
        type:GET_COVID_QUERIES_RET,
        data
    }
}
export const get_covid_queries_loading = (data) => {
    return {
        type:GET_COVID_QUERIES_LOADING,
        data
    }
}
export const submit_query = (data) => {
    return {
        type:SUBMIT_QUERY,
        data
    }
}
export const submit_query_ret = (data) => {
    return {
        type:SUBMIT_QUERY_RET,
        data
    }
}
export const submit_query_loading = (data) => {
    return {
        type:SUBMIT_QUERY_LOADING,
        data
    }
}

// template messages

export const get_message_templates = () => {
    return {
        type: GET_MESSAGE_TEMPLATES
    }
}

export const get_message_templates_ret = (data) => {
    return {
        type: GET_MESSAGE_TEMPLATES_RET,
        data
    }
}

export const send_template_sms = (data) => {
    return {
        type: SEND_TEMPLATE_SMS,
        data
    }
}

export const send_ivr_template_sms = (data) => {
    return {
        type: SEND_IVR_TEMPLATE_SMS,
        data
    }
}

export const send_template_sms_ret = (data) => {
    return {
        type: SEND_TEMPLATE_SMS_RET,
        data
    }
}

export const send_template_sms_loading = () => {
    return {
        type: SEND_TEMPLATE_SMS_LOADING
    }
}

export const get_ivr_sms_token = () => {
    return {
        type: GET_IVR_SMS_TOKEN,
    }
}

export const get_ivr_sms_token_ret = (data) => {
    return {
        type: GET_IVR_SMS_TOKEN_RET,
        data
    }
}

export const get_ivr_sms_token_loading = () => {
    return {
        type: GET_IVR_SMS_TOKEN_LOADING
    }
}

export const send_manual_template_sms = (data) => {
    return {
        type: SEND_MANUAL_TEMPLATE_SMS,
        data
    }
}

export const send_manual_template_sms_ret = (data) => {
    return {
        type: SEND_MANUAL_TEMPLATE_SMS_RET,
        data
    }
}

export const send_manual_template_sms_loading = () => {
    return {
        type: SEND_MANUAL_TEMPLATE_SMS_LOADING
    }
}

export const get_manual_sms_list = (data) => {
    return {
        type: GET_MANUAL_SMS_LIST,
        data
    }
}

export const get_manual_sms_list_ret = (data) => {
    return {
        type: GET_MANUAL_SMS_LIST_RET,
        data
    }
}

export const get_manual_sms_list_loading = () => {
    return {
        type: GET_MANUAL_SMS_LIST_LOADING
    }
}

export const get_merged_queries = (data) => {
    return {
        type: GET_MERGED_QUERIES,
        data
    }
}
export const get_merged_queries_ret = (data) => {
    return {
        type: GET_MERGED_QUERIES_RET,
        data
    }
}
export const get_merged_queries_loading = (data) => {
    return {
        type: GET_MERGED_QUERIES_LOADING,
        data
    }
}

export const get_all_queries = (data) => {
    return {
        type: GET_ALL_QUERIES,
        data
    }
}
export const get_all_queries_ret = (data) => {
    return {
        type: GET_ALL_QUERIES_RET,
        data
    }
}
export const get_all_queries_loading = (data) => {
    return {
        type: GET_ALL_QUERIES_LOADING,
        data
    }
}

export const get_queries_by_number = (data) => {
    return {
        type: GET_QUERIES_BY_NUMBER,
        data
    }
}
export const get_queries_by_number_ret = (data) => {
    return {
        type: GET_QUERIES_BY_NUMBER_RET,
        data
    }
}
export const get_queries_by_number_loading = (data) => {
    return {
        type: GET_QUERIES_BY_NUMBER_LOADING,
        data
    }
}

export const update_prospect = (data) => {
    return {
        type:UPDATE_PROSPECT,
        data
    }
}
export const update_prospect_ret = (data) => {
    return {
        type:UPDATE_PROSPECT_RET,
        data
    }
}
export const update_prospect_loading = (data) => {
    return {
        type:UPDATE_PROSPECT_LOADING,
        data
    }
}

export const update_query = (data) => {
    return {
        type: UPDATE_QUERY,
        data
    }
}
export const update_query_ret = (data) => {
    return {
        type: UPDATE_QUERY_RET,
        data
    }
}
export const update_query_loading = (data) => {
    return {
        type: UPDATE_QUERY_LOADING,
        data
    }
}

export const get_campaign_speciality_and_services = (data) => {
    return {
        type: GET_CAMPAIGN_SPECIALITY_AND_SERVICES,
        data
    }
}
export const get_campaign_speciality_and_services_ret = (data) => {
    return {
        type: GET_CAMPAIGN_SPECIALITY_AND_SERVICES_RET,
        data
    }
}
export const get_campaign_speciality_and_services_loading = (data) => {
    return {
        type: GET_CAMPAIGN_SPECIALITY_AND_SERVICES_LOADING,
        data
    }
}

export const sync_knowlarity_leads = (data) => {
    return {
        type: SYNC_KNOWLARITY_LEADS,
        data
    }
}
export const sync_knowlarity_leads_ret = (data) => {
    return {
        type: SYNC_KNOWLARITY_LEADS_RET,
        data
    }
}
export const sync_knowlarity_leads_loading = (data) => {
    return {
        type: SYNC_KNOWLARITY_LEADS_LOADING,
        data
    }
}

export const download_sales_leads = (data) => {
    return {
        type: DOWNLOAD_SALES_LEADS,
        data
    }
}
export const download_sales_leads_ret = (data) => {
    return {
        type: DOWNLOAD_SALES_LEADS_RET,
        data
    }
}
export const download_sales_leads_loading = (data) => {
    return {
        type: DOWNLOAD_SALES_LEADS_LOADING,
        data
    }
}