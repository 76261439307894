import React from 'react'
import { Button, Card, Col, Descriptions, Divider, message, PageHeader, Row, Tabs, Upload } from 'antd'
import { Redirect } from 'react-router-dom'
import { CalendarOutlined, DollarOutlined, EditOutlined, FileOutlined, SolutionOutlined, UploadOutlined } from '@ant-design/icons'

import { get_url_params, get_url } from '../../utils/common_utilities'
import api from '../../utils/api_routes'
import BookingDetails from './BookingDetails'

const ViewBooking = (props) => {
  const [flag, set_flag] = React.useState(false)
  const [edit_flag, set_edit_flag] = React.useState(0)
  const [booking_id, set_booking_id] = React.useState(null)
  const [booking_data, set_booking_data] = React.useState({
    alNumber: '',
    alLetterDetails: {
      initialLetter: null,
      finalLetter: null
    },
    cardNumber: '',
    dischargeDoc: {
      docUploaded: false,
      docURL: ''
    },
    doa: '',
    dod: '',
    insuranceDetails: {
      insuranceCard: [],
      insurancePartner: '',
      insurancePolicy: '',
      policyNumber: ''
    },
    ipNumber: '',
    patientStatus: '',
    paymentDetails: {
      finalBill: '',
      paymentStatus: '',
      utr: ''
    },
    preAuthStatus: '',
    queryDoc: {
      queryRecieved: false,
      queryResponseRecieved: false,
      queryUrl: ''
    },
    userMobileNumber: '',
    userName: '',
  })
  const [insuranceDocuments, setInsuranceDocuments] = React.useState({
    policyNumber: '',
    insurancePartner: '',
    insurancePolicy: '',
    insuranceCard: []
  })
  const [queryDocuments, setQueryDocuments] = React.useState({
    _id: null,
    queryUrl: null,
    queryResponseUrl: null,
    queryRecieved: false,
    queryResponseRecieved: false
  })

  const getBooking = () => {
    const id = get_url_params("booking_id")
    if (!!id) {
      set_booking_id(id)
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      const data = {
        booking_id: id
      }
      api.bookings.get_booking_by_id(data, headers)
        .then((res) => {
          let booking = res.data[0].data[0]
          booking.alNumber = booking.alNumber ? booking.alNumber : 'Not Updated Yet'
          booking.dod = booking.dod ? booking.dod : booking.doa
          booking.queryDoc.queryUrl = booking.queryDoc.queryUrl ? booking.queryDoc.queryUrl : ''
          set_booking_data(booking)
          setQueryDocuments({
            _id: id,
            queryUrl: booking.queryDoc && booking.queryDoc.queryUrl ? booking.queryDoc.queryUrl : null,
            queryResponseUrl: booking.queryDoc && booking.queryDoc.queryResponseUrl ? booking.queryDoc.queryResponseUrl : null,
            queryRecieved: booking.queryDoc.queryRecieved,
            queryResponseRecieved: booking.queryDoc.queryResponseRecieved
          })
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  React.useEffect(getBooking, [])
  React.useEffect(getBooking, [edit_flag])

  const queryProps = {
    name: 'file',
    action: `${get_url()}/booking/uploadBookingQueryDoc?bookingId=${booking_id}`,
    accept: "application/pdf",
    headers: {
      authorization: `Bearer ${localStorage.getItem('token') }`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        const resBooking = info.file.response.data
        setQueryDocuments({
          ...queryDocuments,
          queryResponseUrl: resBooking.queryDoc.queryResponseUrl
        })
        console.log(`${info.file.name} file uploaded successfully`)
        message.success(`Query Document uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`Query Document upload failed.`)
        console.log(`${info.file.name} file upload failed.`)
      }
    }
  }

  const initialProps = {
    name: 'file',
    action: `${get_url()}/booking/uploadAuthLetter?status=INITIAL&bookingId=${booking_id}`,
    accept: "application/pdf",
    headers: {
      authorization: `Bearer ${localStorage.getItem('token') }`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const resBooking = info.file.response.data
        set_booking_data({
          ...booking_data,
          alLetterDetails: resBooking.alLetterDetails
        })
        message.success(`Initial AL Letter uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Initial AL Letter upload failed.`);
      }
    }
  }

  const finalProps = {
    name: 'file',
    action: `${get_url()}/booking/uploadAuthLetter?status=FINAL&bookingId=${booking_id}`,
    accept: "application/pdf",
    headers: {
      authorization: `Bearer ${localStorage.getItem('token') }`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const resBooking = info.file.response.data
        set_booking_data({
          ...booking_data,
          alLetterDetails: resBooking.alLetterDetails
        })
        message.success(`Final AL Letter uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Final AL Letter upload failed.`);
      }
    },
  }

  if (flag) {
    return <Redirect to="/dashboard/insurance-bookings?list" />
  }

  return (
    <React.Fragment>
      <Card>
        <PageHeader className='site-page-header' title="Bookings" onBack={() => set_flag(true)} />

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={
            <span onClick={() => set_edit_flag(0)}><SolutionOutlined />General Info</span>
          } key={1}>
            {
              (!!edit_flag) ? (
                <BookingDetails
                  alNumber={booking_data.alNumber}
                  finalAmount={booking_data.paymentDetails.finalBill}
                  cardNumber={booking_data.cardNumber}
                  ipNumber={booking_data.ipNumber}
                  doa={booking_data.doa}
                  dod={booking_data.dod}
                  preAuthStatus={booking_data.preAuthStatus}
                  paymentDetails={booking_data.paymentDetails}
                  utr={booking_data.paymentDetails.utr}
                  
                  bookingId={booking_id}
                  edit_flag={edit_flag}
                  set_edit_flag={set_edit_flag}

                  update_booking = {props.update_booking}
                  update_booking_ret = {props.update_booking_ret}
                  update_booking_loading = {props.update_booking_loading}
                  update_booking_loading_flag = {props.update_booking_loading_flag}

                  update_booking_session = {props.update_booking_session}
                  update_booking_session_ret = {props.update_booking_session_ret}
                  update_booking_session_loading = {props.update_booking_session_loading}
                  update_booking_session_loading_flag = {props.update_booking_session_loading_flag}
                />
              ) : (
                <React.Fragment>
                  <Descriptions title="Booking Info" bordered>
                    <Descriptions.Item label="Name">{booking_data.userName}</Descriptions.Item>
                    <Descriptions.Item label="Contact">{booking_data.userMobileNumber}</Descriptions.Item>
                    <Descriptions.Item label="Status">{booking_data.preAuthStatus}</Descriptions.Item>

                    <Descriptions.Item label="IP Number">{booking_data.ipNumber}</Descriptions.Item>
                    <Descriptions.Item label="Patient Status">{booking_data.patientStatus}</Descriptions.Item>
                    <Descriptions.Item label="Payment Status">{booking_data.paymentDetails.paymentStatus}</Descriptions.Item>
                    
                    <Descriptions.Item label="AL Number">{booking_data.alNumber}</Descriptions.Item>
                    <Descriptions.Item label="Card Number">{booking_data.cardNumber}</Descriptions.Item>
                  </Descriptions>
                  
                  <div className='u-margin-top-small'>
                    <Row gutter={12}>
                      <Col>
                        <Button type="primary" size='small' style={{ width: 90 }} icon={<EditOutlined />} onClick={() => set_edit_flag(1)}>Edit</Button>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              )
            }
          </Tabs.TabPane>

          <Tabs.TabPane tab={
            <span onClick={() => set_edit_flag(0)}><DollarOutlined />Costs</span>
          } key={2}>
            {
              (!!edit_flag) ? (
                <BookingDetails
                  alNumber={booking_data.alNumber}
                  finalAmount={booking_data.paymentDetails.finalBill}
                  cardNumber={booking_data.cardNumber}
                  ipNumber={booking_data.ipNumber}
                  tdsDeductions={booking_data.paymentDetails?.tdsDeductions}
                  doa={booking_data.doa}
                  dod={booking_data.dod}
                  preAuthStatus={booking_data.preAuthStatus}
                  paymentDetails={booking_data.paymentDetails}
                  utr={booking_data.paymentDetails.utr}
                  
                  bookingId={booking_id}
                  edit_flag={edit_flag}
                  set_edit_flag={set_edit_flag}

                  update_booking = {props.update_booking}
                  update_booking_ret = {props.update_booking_ret}
                  update_booking_loading = {props.update_booking_loading}
                  update_booking_loading_flag = {props.update_booking_loading_flag}

                  update_booking_session = {props.update_booking_session}
                  update_booking_session_ret = {props.update_booking_session_ret}
                  update_booking_session_loading = {props.update_booking_session_loading}
                  update_booking_session_loading_flag = {props.update_booking_session_loading_flag}
                />
              ) : (
                <React.Fragment>
                  <Descriptions title="Costs" bordered>
                      <Descriptions.Item label="Sourcing Cost">{booking_data.paymentDetails.sourcingCost ? booking_data.paymentDetails.sourcingCost : ''}</Descriptions.Item>
                      <Descriptions.Item label="Initial Approved">{booking_data.paymentDetails.initialApproved ? booking_data.paymentDetails.initialApproved : ''}</Descriptions.Item>
                      <Descriptions.Item label="Final Approved">{booking_data.paymentDetails.finalApproved ? booking_data.paymentDetails.finalApproved : ''}</Descriptions.Item>
                      <Descriptions.Item label="Discount Amount">{booking_data.paymentDetails.discount ? booking_data.paymentDetails.discount : ''}</Descriptions.Item>
                      <Descriptions.Item label="Settled Amount">{booking_data.paymentDetails.settledAmount ? booking_data.paymentDetails.settledAmount : ''}</Descriptions.Item>
                      <Descriptions.Item label="TDS Deductions">{booking_data.paymentDetails.tdsDeductions ? booking_data.paymentDetails.tdsDeductions : ''}</Descriptions.Item>
                      <Descriptions.Item label="Total Bill">{booking_data.paymentDetails.totalBill ? booking_data.paymentDetails.totalBill : ''}</Descriptions.Item>
                      <Descriptions.Item label="Final Bill">{booking_data.paymentDetails.finalBill ? booking_data.paymentDetails.finalBill : ''}</Descriptions.Item>
                      <Descriptions.Item label="UTR">{booking_data.paymentDetails.utr ? booking_data.paymentDetails.utr : ''}</Descriptions.Item>
                  </Descriptions>

                  <div className='u-margin-top-small'>
                    <Row gutter={12}>
                      <Col>
                        <Button type="primary" size='small' style={{ width: 90 }} icon={<EditOutlined />} onClick={() => set_edit_flag(2)}>Edit</Button>
                      </Col>
                    </Row>
                  </div>
                  <div className='u-margin-top-small'>
                    <Card title="Enhancements" className="booking-info-card user bill-amounts">
                      <Row>
                        <Col span={6}>
                          <label>Package</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.package ? booking_data.paymentDetails?.package : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Col span={6}>
                          <label>Room Rent & Nursing Charges</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.roomRentNursing ? booking_data.paymentDetails?.roomRentNursing : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Col span={6}>
                          <label>Consultation</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.cvc ? booking_data.paymentDetails?.cvc : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Col span={6}>
                          <label>OT Charges</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.otCharges ? booking_data.paymentDetails?.otCharges : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Col span={6}>
                          <label>Surgeon & Anesthetist</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.soa ? booking_data.paymentDetails?.soa : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Col span={6}>
                          <label>Investigation</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.investigation ? booking_data.paymentDetails?.investigation : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Col span={6}>
                          <label>Medicine & Consumables</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.medicineConsumables ? booking_data.paymentDetails?.medicineConsumables : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Col span={6}>
                          <label>Other Hospital Expenses</label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.others ? booking_data.paymentDetails?.others : 0).toLocaleString('en-IN')}</span>
                        </Col>
                        <Divider />
                        <Col span={6}>
                          <label><b>Total</b></label>
                        </Col>
                        <Col span={18}>
                          <span>₹ {Number(booking_data.paymentDetails?.totalBill ? booking_data.paymentDetails?.totalBill : 0).toLocaleString('en-IN')}</span>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                  {/* <Button style={{margin: "2rem"}} type={"primary"} onClick={() => setActiveKey('4')}>Enhacements</Button> */}
                </React.Fragment>
              )
            }
          </Tabs.TabPane>

          <Tabs.TabPane tab={
            <span onClick={() => set_edit_flag(0)}><CalendarOutlined />Dates</span>
          } key={3}>
            {
              (!!edit_flag) ? (
                <BookingDetails
                  alNumber={booking_data.alNumber}
                  finalAmount={booking_data.paymentDetails.finalBill}
                  cardNumber={booking_data.cardNumber}
                  ipNumber={booking_data.ipNumber}
                  doa={booking_data.doa}
                  dod={booking_data.dod}
                  preAuthStatus={booking_data.preAuthStatus}
                  paymentDetails={booking_data.paymentDetails}
                  utr={booking_data.paymentDetails.utr}
                  
                  bookingId={booking_id}
                  edit_flag={edit_flag}
                  set_edit_flag={set_edit_flag}

                  update_booking = {props.update_booking}
                  update_booking_ret = {props.update_booking_ret}
                  update_booking_loading = {props.update_booking_loading}
                  update_booking_loading_flag = {props.update_booking_loading_flag}

                  update_booking_session = {props.update_booking_session}
                  update_booking_session_ret = {props.update_booking_session_ret}
                  update_booking_session_loading = {props.update_booking_session_loading}
                  update_booking_session_loading_flag = {props.update_booking_session_loading_flag}
                />
              ) : (
                <React.Fragment>
                  <Descriptions title="Dates" bordered>
                      <Descriptions.Item label="Date of Admission">{booking_data.doa}</Descriptions.Item>
                      <Descriptions.Item label="Date of Discharge">{booking_data.dod}</Descriptions.Item>
                  </Descriptions>

                  <div className='u-margin-top-small'>
                    <Row gutter={12}>
                      <Col>
                        <Button type="primary" size='small' style={{ width: 90 }} icon={<EditOutlined />} onClick={() => set_edit_flag(3)}>Edit</Button>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              )
            }
          </Tabs.TabPane>

          <Tabs.TabPane key={4} tab={
            <span><FileOutlined />Documents</span>
          }>
            <React.Fragment>
              <Descriptions title="Documents" bordered>
                {/* <div className="row insurance-booking-session">
                  <div className="col-6">
                    <label className="policy-label">Policy Number</label>
                    <span className="policy-text">{booking_data.insuranceDetails.policyNumber}</span>
                  </div>
                  <div className="col-6">
                    <label className="policy-label">Insurance Partner</label>
                    <span className="policy-text">{booking_data.insuranceDetails.insurancePartner}</span>
                  </div>
                </div>
                <div className="row insurance-booking-session">
                  {booking_data.insuranceDetails.insurancePolicy && <div className="col-6">
                    <label className="policy-label">Insurance PDF</label>
                    <a className="edit-session-button policy" href={booking_data.insuranceDetails.insurancePolicy}>Download</a>
                  </div>} */}
                  {/* {insuranceDocuments.insuranceCard && insuranceDocuments.insuranceCard.length > 0 && <div className="col-6">
                    <label className="policy-label">Insurance Card</label>
                    <PhotoGallery id="insurance-card" data={insuranceDocuments.insuranceCard} />
                  </div>}  */}
                {/* </div> */}
                <Descriptions.Item label="Policy Number">{booking_data.insuranceDetails.policyNumber}</Descriptions.Item>
                <Descriptions.Item label="Insurance Partner">{booking_data.insuranceDetails.insurancePartner}</Descriptions.Item>
                <Descriptions.Item label="Insurance PDF">{booking_data.insuranceDetails.insurancePolicy == '' ? <span>Not available</span> : <a href={booking_data.insuranceDetails.insurancePolicy}>Download</a>}</Descriptions.Item>
                
                <Descriptions.Item label="Query Document">{booking_data.queryDoc.queryUrl ? <a href={booking_data.queryDoc.queryUrl}>Download</a> : "N/A"}</Descriptions.Item>
                <Descriptions.Item label="Initial AL Letter">{booking_data.alLetterDetails.initialLetter ? <a href={booking_data.alLetterDetails.initialLetter}>Download</a> : "N/A"}</Descriptions.Item>
                <Descriptions.Item label="Final AL Letter">{ booking_data.alLetterDetails.finalLetter ? <a href={booking_data.alLetterDetails.finalLetter}>Download</a> : "N/A" }</Descriptions.Item>

                <Descriptions.Item span={3} label="Discharge Document">{ booking_data.dischargeDoc.docUploaded ? <a href={booking_data.dischargeDoc.docURL}>Download</a> : <span>N/A</span> }</Descriptions.Item>
              </Descriptions>

              <br />

              <Card title="Query Documents">
                <Row justify="space-around" gutter={24}>
                  <Col span={8}>
                    <label className='policy-label'><b>Submit Query</b></label>
                    <Upload { ...queryProps }>
                      <Button icon={<UploadOutlined />}>{ queryDocuments.queryUrl ? "Update " : "Upload " } Query Document</Button>
                    </Upload>
                  </Col>
                  <Col span={8}>
                    <label className='policy-label'><b>Query Response</b></label>
                    { queryDocuments && queryDocuments.queryResponseUrl ? <label style={{margin: "1.25rem 0"}} className="policy-label"><a href={queryDocuments.queryResponseUrl}>Download Query Response</a></label> : <label className="policy-label">No Response Received</label> }
                  </Col>
                </Row>
              </Card>

              <br />

              <Card title="AL Letters">
                <Row justify="space-around" gutter={24}>
                  <Col span={8}>
                    <label className='session-label'>Initial AL Letter</label>
                    <Upload {...initialProps}>
                      <Button icon={<UploadOutlined />}>{ booking_data.alLetterDetails && booking_data.alLetterDetails.initialLetter ? "Update " : "Uplaod " } Initial AL Letter</Button>
                    </Upload>
                  </Col>
                  <Col span={8}>
                    <label className='session-label'>Final AL Letter</label>
                    <Upload {...finalProps}>
                      <Button icon={<UploadOutlined />}>{ booking_data.alLetterDetails && booking_data.alLetterDetails.finalLetter ? "Update " : "Uplaod " } Final AL Letter</Button>
                    </Upload>
                  </Col>
                </Row>
              </Card>
            </React.Fragment>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </React.Fragment>
  )
}

export default ViewBooking