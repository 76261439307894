import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Input, Row, Select } from 'antd'
import AntdInput from '../../Components/AntdInput'
import { error_form_check } from '../../utils/common_utilities'
import Modal from 'antd/lib/modal/Modal'
import { CloseOutlined } from '@ant-design/icons'
import ManualMessagesList from './ManualMessagesList'
const { Option }  =  Select

function ManualMessages(props) {

    const [data, set_data] = useState({
        message:''
    })
    const [error, set_error] = useState({
        message:{ state:false, text:"" }
    })
    const [errorNo, set_errorNo] = useState({
        message:{ state:false, text:"" }
    })
    const [errorName, set_errorName] = useState({
        message:{ state:false, text:"" }
    })
    const [userName, setUserName] = useState("")
    const [template, setTemplate] = useState(null)
    const [templates, setTemplates] = useState(null)
    const [numberModal, setNumberModal] = useState(false)
    const [modalNumber, setModalNumber] = useState("")
    const [numbers, setNumbers] = useState([])
    const [get_manual_sms_list, set_get_manual_sms_list] = useState({
        page:1,
        limit:10,
        total:10,
        searchParams:{
        
        },
        filters:{
         
        }
   })

    useEffect(()=>{
      props.get_message_templates && props.get_message_templates()
    },[])
  
    useEffect(()=>{
      if(props.get_message_templates)
        if(!!props.get_message_templates_ret){
                if(props.get_message_templates_ret.success){
                    setTemplates(props.get_message_templates_ret.data)
            } else {
                props.global_error({
                    success:false,
                    message:props.get_message_templates_ret.message,
                    heading:'Templates'
                })
            }
        }
    },[props.get_message_templates_ret])

    useEffect(()=>{
        if(!!props.send_manual_template_sms_ret){
           if(props.send_manual_template_sms_ret.success){
            props.global_error({
                success:true,
                message:props.send_manual_template_sms_ret.message,
                heading:'Manual Template SMS'
              })
              console.log(props.send_manual_template_sms_ret,"SEND_MANUAL_SMS-RES")
              props.get_manual_sms_list(get_manual_sms_list)
           }else{
            props.global_error({
              success:false,
              message:props.send_manual_template_sms_ret.message,
              heading:'Professionals'
            })
           }
           props.send_manual_template_sms_loading()
        }  
    },[props.send_manual_template_sms_ret])


  const send_template_message = () => {
      if(numbers.length > 0) {
        let mNos = ""
        numbers.map((number, index) => {
            if (index === 0)
                mNos = number
            else
                mNos = mNos + "," + number 
        })
        const ret_error = validate_template(template);
        set_error({...error, ...ret_error});
        if(!error_form_check(ret_error)){
            let flag = false
            // if(numbers.length === 1) {
            //     if(userName.length === 0) {
            //         flag = true
            //         let message = {
            //             state: true,
            //             text: "Please Enter a User Name"
            //         }
            //         set_errorName({message: message})
            //     }
            // }
            !flag && props.send_manual_template_sms({
                mobileNumbers: mNos,
                messagecontent: data.message,
                templateId: template.templateId,
                templateType: template.templateType,
                templateName: template.templateName,
                name: numbers.length === 1 ? "" : ""
            })
        }
    } else {
        props.global_error({
            success:false,
            message: "Please Enter a number",
            heading:'Manual Message'
        })        
    }
  }

    const openModal = () => {
        setModalNumber("")
        setNumberModal(true)
    }

    const addNumber = () => {
        let message = {
            state: false,
            text: ""
        }
        set_errorNo({message: message})

        let modalNos =  modalNumber.split(",")
        modalNos.map((noRaw, index) => {
            let no = noRaw.trim()
            const nos = numbers
            const bool = numbers.find((number) => number === no)
            const boolNo = /\+?\d[\d -]{8,12}\d/.test(no)
            if (bool) {
                let message = {
                    state: true,
                    text: "Cannot add a number twice"
                }
                set_errorNo({message: message})
            } else if(!boolNo){
                let message = {
                    state: true,
                    text: "Please enter a valid number"
                }
                set_errorNo({message: message}) 
            } else {
                nos.push(no)
                setNumbers(nos)
                setModalNumber("")
            }
        })
    }

    const handleCancel = () => {
        setNumberModal(false)
    }

    const validate_template = (data) => {
        const  error = {
            message:{ state:false, text:"" }
        }
        if(data === null){
            error.message.text = "Please select a template"
            error.message.state = true
        }
        return error
    }

    const removeNumber = (number) => {
        const newArray = numbers.filter(no => no !== number)
        setNumbers(newArray)
    }
    
    return (
        <>
            <div className="sales-funnel-manual-messages">
                <Modal
                title="Enter Number"
                visible={numberModal}
                onOk={addNumber}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: "none" } }}
                >
                    <AntdInput
                        value={modalNumber}
                        error_text={errorNo.message.text} 
                        toggle_error = {()=>set_error({...errorNo, message:{text:'',state:false}})} 
                        error={errorNo.message.state} 
                        onChange={(e) => setModalNumber(e.target.value)} 
                    />
                </Modal>
                <h1>Send Message</h1>
                <Row className="numbers-controller" gutter={[32,0]}>
                    <Col span={18}>
                        <div className="manual-messages-number">
                            <Row gutter={[8,0]} >
                                {numbers.map((number, index) => <Col key={index}>
                                    <Row gutter={[0,0]} className="customer-number-item">
                                        <Col className="customer-number">{number}</Col>
                                        <Col><Button onClick={() => removeNumber(number)} className="remove-number-btn"><CloseOutlined /></Button></Col>
                                    </Row>
                                </Col>)}
                            </Row>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Button size="large" onClick={() => openModal()} className="manual-messages-add-number">Add Number</Button>
                    </Col>
                </Row>
                {/* {numbers.length === 1 && 
                <Row className="user-name-continer" gutter={[16,0]}>
                    <Col span={8}>
                        <AntdInput
                            value={userName}
                            error_text={errorName.message.text} 
                            toggle_error = {()=>set_error({...errorName, message:{text:'',state:false}})} 
                            error={errorName.message.state} 
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="User Name"
                        />
                    </Col>
                    <Col>
                        <h5>Enter User Name</h5>
                    </Col>
                </Row>} */}
                <div className="manual-message-card">
                    {templates && <div className="manual-messages-template-dropdown">
                        <Select
                        placeholder="Please Select a Template"
                        style={{width: "100%"}} className="messages-template-dropdown" onChange={(val) => {
                        const selTemplate = templates.find(template => template.templateId === val)
                        setTemplate(selTemplate)
                        set_data({
                            message: selTemplate.templateContent
                        })
                        }}>
                        {templates.map((template, index) => 
                            <Option key={index} value={template.templateId}>{template.templateName.replaceAll('_', ' ')}</Option>
                        )}
                        </Select>
                    </div>}
                    <AntdInput
                        className="template-message-input"
                        value={data.message} 
                        error_text={error.message.text} 
                        loading = {props.send_manual_template_sms_loading_flag}
                        disabled = {props.send_manual_template_sms_loading_flag} 
                        toggle_error = {()=>set_error({...error, message:{text:'',state:false}})} 
                        error={error.message.state} 
                        name="message" 
                        onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
                        placeholder="Enter message"
                        type="textarea"
                        rows= {5}
                    />
                    <Button loading={props.send_manual_template_sms_loading_flag} onClick={()=> send_template_message()} type={"primary"}>Send</Button>
                </div>
            </div>
            <div className="manual-message-list-container">
                <ManualMessagesList 
                    get_manual_sms_list  = {props.get_manual_sms_list}
                    get_manual_sms_list_loading = {props.get_manual_sms_list_loading}
                    get_manual_sms_list_ret = {props.get_manual_sms_list_ret}
                    get_manual_sms_list_loading_flag = {props.get_manual_sms_list_loading_flag}

                    global_error = {props.global_error}
                />
            </div>
        </>
    )
}

export default ManualMessages
