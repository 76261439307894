import React from "react"
import { Collapse,  Popover, Modal, Button, Upload, message } from 'antd';
import { SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { error_form_check } from '../../utils/common_utilities'
import AntdInput from "../../Components/AntdInput"
import { useEffect } from "react";

const { Panel } = Collapse

const EditAdvancedTreatments  = (props) => {
   const [state, set_state] = React.useState({
    expandIconPosition:'left'
   })

   const [imageURL, setImageURL] = React.useState("");
   const [iconURL, setIconURL] = React.useState("");

   const [visible, set_visible] = React.useState(false)
   const [update, set_update] = React.useState({
       _id:'',
       type:'',
       state:false
   })

   const [update_faq, set_update_faq] = React.useState({
    typeA: "",
    typeB: ""
  });
  const [update_faq_error, set_update_faq_error] = React.useState({
    typeA: { state: false, text: "" },
    typeB: { state: false, text: "" },
  })

  const uploadImage = (file, type) => {
    // console.log(file, "IMAGE_UPLOAD_RESPONSE REDUX")
    const uploadImageData = {
      file: file.file,
      family_id: props.family_id.toString()
    }

    props.upload_advanced_treatment_image(uploadImageData)
    file.onSuccess("Success:true", file.file)
  }

  const uploadIcon = (file) => {
    // console.log(file, "IMAGE_UPLOAD_RESPONSE REDUX")
    const uploadImageData = {
      file: file.file,
      family_id: props.family_id.toString()
    }

    props.upload_advanced_treatment_icon(uploadImageData)
    file.onSuccess("Success:true", file.file)
  }

  const beforeUpload = (file) => {
    let isJpgOrPng = false
    isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     message.error('Image must smaller than 2MB!');
    //   }
    return isJpgOrPng;
  };

  React.useEffect(()=>{
            if(props.upload_advanced_treatment_image_ret){
                if(props.upload_advanced_treatment_image_ret.success){
                    props.global_error({
                        success: true,
                        message: props.upload_advanced_treatment_image_ret.message,
                        heading: 'Upload Treatment Image'
                       
                    })
                    setImageURL(props.upload_advanced_treatment_image_ret.data.url)
                }else {
                    props.global_error({
                        success: false,
                        message: props.upload_advanced_treatment_image_ret.message,
                        heading: 'Upload Treatment Image'
                    })
                }
                props.upload_advanced_treatment_image_loading()
            }
  },[props.upload_advanced_treatment_image_ret])

  React.useEffect(()=>{
            if(props.upload_advanced_treatment_icon_ret){
                if(props.upload_advanced_treatment_icon_ret.success){
                    props.global_error({
                        success: true,
                        message: props.upload_advanced_treatment_icon_ret.message,
                        heading: 'Upload Treatment Icon'
                       
                    })
                    setIconURL(props.upload_advanced_treatment_icon_ret.data.url)
                }else {
                    props.global_error({
                        success: false,
                        message: props.upload_advanced_treatment_icon_ret.message,
                        heading: 'Upload Treatment Icon'
                    })
                }
                props.upload_advanced_treatment_icon_loading()
            }
  },[props.upload_advanced_treatment_icon_ret])

  React.useEffect(()=>{
            if(props.update_advanced_treatment_ret){
                if(props.update_advanced_treatment_ret.success){
                  props.get_service_family_byid && props.get_service_family_byid({id:props.family_id})
                    props.global_error({
                        success: true,
                        message: props.update_advanced_treatment_ret.message,
                        heading: 'Edit Advanced Treatment'
                       
                    })
                     handleCancel()
                }else {
                    props.global_error({
                        success: false,
                        message: props.update_advanced_treatment_ret.message,
                        heading: 'Edit Advanced Treatment'
                    })
                }
                props.update_advanced_treatment_loading()
            }
  },[props.update_advanced_treatment_ret])

   const  callback = (key)=> {
      console.log(key);
    }
      
      const submit = () => {
        const ret_error = validate({ ...update_faq });
        set_update_faq_error({ ...ret_error });
        console.log(ret_error, "ret_error in add_member");
        if (!error_form_check(ret_error)) {
            if(update.type === "add"){
                props.update_advanced_treatment({
                    type: "add_advanced_treatment",
                    family_id:props.family_id,
                    name: update_faq.typeA,
                    info: update_faq.typeB,
                    image: imageURL.length > 0 ? imageURL : null,
                    icon: iconURL.length > 0 ? iconURL : null
                  })
            }else {
                props.update_advanced_treatment({
                    type: "edit_advanced_treatment",
                    family_id:props.family_id,
                    faq_id:update._id,
                    name: update_faq.typeA,
                    info: update_faq.typeB,
                    image: imageURL.length > 0 ? imageURL : null,
                    icon: iconURL.length > 0 ? iconURL : null
                  })
            }
          
        }
      }

      const validate = (data) => {
        const error = {
          typeA: { state: false, text: "" },
          typeB: { state: false, text: "" }
        };
        if (update_faq.typeA === "") {
          {
            error.typeA.text =`Please enter ${props.typeA}`;
            error.typeA.state = true;
          }
        }
        if (update_faq.typeB === "") {
          {
            error.typeB.text =`Please enter ${props.typeB}`;
            error.typeB.state = true;
          }
        }
        return error;
      };
    
      const handleCancel = (e) => {
        console.log(e);
        set_visible(false);
        set_update_faq({
          typeA: "",
          typeB: "",
         
        
        });
        set_update_faq_error({
          typeA: { state: false, text: "" },
          typeB: { state: false, text: "" },
        
        });
      };

      const content = (id) =>   (
        <div>
          <p className="u-cursor-pointer" onClick={(event)=>{
                        set_update({_id:id, type:'edit', state:true})
                        let data = [...props.data]
                        data = data.filter(item => {
                         return item._id === id
                       })
                       set_update_faq({
                           typeA: data[0].name,
                           typeB: data[0].info
                       })
                       set_visible(true)
                        event.stopPropagation()
          }}>Edit</p>
          <p className="u-cursor-pointer" onClick={(event)=>{
                set_update({_id:id, type:'delete', state:true})   
                props.update_advanced_treatment({
                    type: "delete_advanced_treatment",
                    family_id:props.family_id,
                    faq_id:id
                  })          
                event.stopPropagation()
          }} >Delete</p>
        </div>
      );
      
      const genExtra = (id) => (
        <Popover content={content(id)} title="Update" trigger="click">
                <SettingOutlined
                onClick={event => {
                    event.stopPropagation()
                    console.log("genExtra getting called")
                }}
                />
      </Popover>
      
      )

      const  onPositionChange = expandIconPosition => {
        set_state({expandIconPosition})
      };

      const { expandIconPosition } = state;
      

    return (<React.Fragment>

<Collapse
          defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition={expandIconPosition}
        >
          {!!props.data?props.data.map((item,i)=>{
              return  <Panel header={item.name} key={item._id} extra={genExtra(item._id)}>
              <div>
                {item.info}
                <br/>
                <b>Treatment image: </b>
                <img src={item.image} />
                <br/>
                <b>Treatment icon: </b>
                <img src={item.icon} />
              </div>
            </Panel>
          }):''}
          <div className="text-center u-margin-top-small">
              <Button
                  type="primary"
                 onClick={() =>  {
                    set_update({ type:'add', state:true})
                   set_update_faq({
                       typeA:"",
                       typeB:""
                   })
                   set_visible(true)
                 }}
                 disabled = {props.update_bookings_loading_flag}
                 loading={props.update_bookings_loading_flag}
                 style={{ width: 'auto', margin:'auto' }}
               >
                    {`Add ${props.type}`}
            </Button>
          </div>
        </Collapse>

        <Modal
        title={`Update ${props.type}`}
        visible={visible}
        onOk={submit}
        onCancel={handleCancel}
      >
        <div className="flex-parent-new u-margin-top-small">
          <span className="flex-child-1-new">
            <AntdInput
              label={props.typeA}
              value={update_faq.typeA}
              error_text={update_faq_error.typeA.text}
              loading={props.update_advanced_treatment_loading_flag}
              disabled={props.update_advanced_treatment_loading_flag}
              toggle_error={() =>
                set_update_faq_error({
                  ...update_faq_error,
                  typeA: { text: "", state: false },
                })
              }
              error={update_faq_error.typeA.state}
              name="typeA"
              onChange={(event) =>
                set_update_faq({
                  ...update_faq,
                  typeA: event.target.value,
                })
              }
              placeholder={`Enter ${props.typeA}`}
            />
          </span>
        </div>
        <div className="flex-parent-new u-margin-top-small">
            <span className="flex-child-1-new">
              <label>Upload Image: </label>
              <Upload
                name="file"
                customRequest={(file) => uploadImage(file)}
                multiple={false}
                maxCount={1}
                label="Upload Image"
                beforeUpload={(file) => beforeUpload(file)}
                onChange={(e) => console.log("YAy")}
              >
                <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
              </Upload>
            </span>
            <span className="flex-child-1-new">
              <label>Upload Icon: </label>
              <Upload
                  name="file"
                  customRequest={(file) => uploadIcon(file)}
                  multiple={false}
                  maxCount={1}
                  label="Upload Image"
                  beforeUpload={(file) => beforeUpload(file)}
                  onChange={(e) => console.log("YAy")}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload Icon</Button>
              </Upload>
            </span>
        </div>
        <div className="flex-parent-new u-margin-top-small">
          <span className="flex-child-1-new">
                <AntdInput
                label={props.typeB}
                value={update_faq.typeB}
                error_text={update_faq_error.typeB.text}
                loading={props.update_advanced_treatment_loading_flag}
                disabled={props.update_advanced_treatment_loading_flag}
                toggle_error={() =>
                    set_update_faq_error({
                    ...update_faq_error,
                    typeB: { text: "", state: false },
                    })
                }
                error={update_faq_error.typeB.state}
                name="description"
                rows ="7"
                onChange={(event) => {
                    set_update_faq({
                        ...update_faq,
                        typeB: event.target.value,
                    })
                }}
                placeholder={`Enter ${props.typeB}`}
                type="textarea"
                />
           </span>
        </div>

        {/* <div className="text-center">
          <Button
                  type="primary"
                 onClick={() => submit()}
                 disabled = {props.update_bookings_loading_flag}
                 loading={props.update_bookings_loading_flag}
                 style={{ width: 'auto', margin:'auto' }}
      >
       Submit
    </Button>
        </div> */}
      </Modal>


    </React.Fragment>)
}

export default EditAdvancedTreatments