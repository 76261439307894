import { GET_PUBLIC_QUERIES_RET, GET_PUBLIC_QUERIES, GET_PUBLIC_QUERIES_LOADING, 
    GET_COVID_QUERIES_LOADING, GET_COVID_QUERIES, GET_COVID_QUERIES_RET, SUBMIT_QUERY, SUBMIT_QUERY_RET,
    SUBMIT_QUERY_LOADING, UPDATE_CITY, UPDATE_CITY_RET, UPDATE_CITY_LOADING, UPDATE_STATUS, UPDATE_STATUS_RET, UPDATE_STATUS_LOADING,
    GET_REG_PUBLIC_QUERIES, GET_REG_PUBLIC_QUERIES_RET, GET_REG_PUBLIC_QUERIES_LOADING ,SEND_SMS_LOADING,
    SEND_SMS_RET, SEND_SMS, GET_SOLUTIONS_LOADING, GET_SOLUTIONS_RET, GET_SOLUTIONS, SEND_SOLUTION_SMS, SEND_SOLUTION_SMS_LOADING,
    SEND_SOLUTION_SMS_RET , GET_IVR_CALLS_LOADING, GET_IVR_CALLS_RET, GET_IVR_CALLS, UPDATE_COVID_QUERIES_LOADING, UPDATE_COVID_QUERIES,
    UPDATE_COVID_QUERIES_RET,   GET_FACEBOOK_QUERIES_LOADING,  GET_FACEBOOK_QUERIES_RET, GET_FACEBOOK_QUERIES, 
    UPLOAD_FACEBOOK_QUERIES, UPLOAD_FACEBOOK_QUERIES_LOADING, UPLOAD_FACEBOOK_QUERIES_RET,
    SEND_IVR_MESSAGE_RET, SEND_IVR_MESSAGE , SEND_IVR_MESSAGE_LOADING, BULK_SEND, BULK_SEND_RET, BULK_SEND_LOADING, 
    UPDATE_FACEBOOK_DATA_LOADING,  UPDATE_FACEBOOK_DATA_RET, UPDATE_FACEBOOK_DATA, GET_IVR_MESSAGES_LOADING,
    GET_IVR_MESSAGES_RET, GET_IVR_MESSAGES, GET_UPLOAD_LIST_LOADING, GET_UPLOAD_LIST_RET, GET_UPLOAD_LIST,
    GET_BULK_SMS_LIST_LOADING, GET_BULK_SMS_LIST_RET, GET_BULK_SMS_LIST, GET_MANUAL_BIDDINGS_LOADING, GET_MANUAL_BIDDINGS_RET,
    GET_MANUAL_BIDDINGS,
    GET_MESSAGE_TEMPLATES_RET, SEND_TEMPLATE_SMS, SEND_TEMPLATE_SMS_RET, SEND_TEMPLATE_SMS_LOADING, SEND_IVR_TEMPLATE_SMS, 
    GET_IVR_SMS_TOKEN, GET_IVR_SMS_TOKEN_RET, GET_IVR_SMS_TOKEN_LOADING, SEND_MANUAL_TEMPLATE_SMS, SEND_MANUAL_TEMPLATE_SMS_RET, SEND_MANUAL_TEMPLATE_SMS_LOADING, GET_MANUAL_SMS_LIST, GET_MANUAL_SMS_LIST_LOADING, GET_MANUAL_SMS_LIST_RET, 
    GET_ALL_QUERIES, GET_ALL_QUERIES_RET, GET_ALL_QUERIES_LOADING, 
    GET_QUERIES_BY_NUMBER, GET_QUERIES_BY_NUMBER_RET, GET_QUERIES_BY_NUMBER_LOADING, GET_MERGED_QUERIES, GET_MERGED_QUERIES_RET, GET_MERGED_QUERIES_LOADING, UPDATE_PROSPECT, UPDATE_PROSPECT_RET, UPDATE_PROSPECT_LOADING, UPDATE_QUERY, UPDATE_QUERY_RET, UPDATE_QUERY_LOADING, SYNC_KNOWLARITY_LEADS, SYNC_KNOWLARITY_LEADS_RET, SYNC_KNOWLARITY_LEADS_LOADING, DOWNLOAD_SALES_LEADS, DOWNLOAD_SALES_LEADS_RET, DOWNLOAD_SALES_LEADS_LOADING
   } from "../Constants"

const initialState = {


get_manual_biddings:false,
get_manual_biddings_ret:false,
get_manual_biddings_loading:false,

send_solution_sms:false,
send_solution_sms_ret:false,
send_solution_sms_loading:false,

get_reg_public_queries:false,
get_reg_public_queries_ret:false,
get_reg_public_queries_loading:false,

get_public_queries:false,
get_public_queries_ret:false,
get_public_queries_loading:false,

get_covid_queries:false,
get_covid_queries_ret:false,
get_covid_queries_loading:false,

submit_query:false,
submit_query_ret:false,
submit_query_loading:false,

update_city:false,
update_city_ret:false,
update_city_loading:false,

update_status:false,
update_status_ret:false,
update_status_loading:false,

send_sms:false,
send_sms_ret:false,
send_sms_loading:false,

get_solutions:false,
get_solutions_ret:false,
get_solutions_loading:false,

get_ivr_calls:false,
get_ivr_calls_ret:false,
get_ivr_calls_loading:false,

update_covid_queries:false,
update_covid_queries_ret:false,
update_covid_queries_loading:false,

get_facebook_queries:false,
get_facebook_queries_ret:false,
get_facebook_queries_loading:false,

upload_facebook_queries:false,
upload_facebook_queries_ret:false,
upload_facebook_queries_loading:false, 

update_facebook_data:false,
update_facebook_data_ret:false,
update_facebook_data_loadiing:false,

send_ivr_message:false,
send_ivr_message_ret:false,
send_ivr_message_loading:false,

bulk_send:false,
bulk_send_false:false,
bulk_send_loading:false,

get_ivr_messages:false,
get_ivr_messages_ret:false,
get_ivr_messages_loading:false,

get_upload_list:false,
get_upload_list_ret:false,
get_upload_list_loading:false,

get_bulk_sms_list:false,
get_bulk_sms_list_ret:false,
get_bulk_sms_list_loading:false,

get_message_templates_ret: false,
message_templates_loading: true,

send_template_sms: false,
send_ivr_template_sms: false,
send_template_sms_ret: false,
send_template_sms_loading: false,

get_ivr_sms_token_ret:false,
get_ivr_sms_token_loading:false,

send_manual_template_sms: false,
send_manual_template_sms_ret: false,
send_manual_template_sms_loading: false,

get_manual_sms_list: false,
get_manual_sms_list_ret: false,
get_manual_sms_list_loading: false,

get_all_queries:false,
get_all_queries_ret:false,
get_all_queries_loading:false,

get_merged_queries:false,
get_merged_queries_ret:false,
get_merged_queries_loading:false,

get_queries_by_number:false,
get_queries_by_number_ret:false,
get_queries_by_number_loading:false,

update_prospect:false,
update_prospect_ret:false,
update_prospect_loading:false,

update_query:false,
update_query_ret:false,
update_query_loading:false,

sync_knowlarity_leads:false,
sync_knowlarity_leads_ret:false,
sync_knowlarity_leads_loading:false,

download_sales_leads:false,
download_sales_leads_ret:false,
download_sales_leads_loading:false

}

export default function (state = initialState, action) {
switch (action.type) {

    case GET_MANUAL_BIDDINGS:
        return {
            ...state,
            get_manual_biddings:action.data,
            get_manual_biddings_ret:true
        }
    case GET_MANUAL_BIDDINGS_RET:
        return {
            ...state,
            get_manual_biddings_ret:action.data,
            get_manual_biddings_loading:true
        }
    case GET_MANUAL_BIDDINGS_LOADING:
        return {
            ...state,
            get_manual_biddings_ret:false,
            get_manual_biddings_loading:false
        }


    case GET_BULK_SMS_LIST:
        return {
            ...state,
            get_bulk_sms_list:action.data,
            get_bulk_sms_list_loading:true
        }
    case GET_BULK_SMS_LIST_RET:
        return {
            ...state,
            get_bulk_sms_list_ret:action.data,
            get_bulk_sms_list_loading:true
        }
    case GET_BULK_SMS_LIST_LOADING:
        return {
            ...state,
            get_bulk_sms_list_ret:false,
            get_bulk_sms_list_loading:false
        }


    case GET_UPLOAD_LIST:
        return {
            ...state,
            get_upload_list:action.data,
            get_upload_list_loading:true
        }
    case GET_UPLOAD_LIST_RET:
        return {
            ...state,
            get_upload_list_ret:action.data,
            get_upload_list_loading:true
        }
    case GET_UPLOAD_LIST_LOADING:
        return {
            ...state,
            get_upload_list_ret:false,
            get_upload_list_loading:false
        }

    case GET_IVR_MESSAGES:
        return {
            ...state,
            get_ivr_messages:action.data,
            get_ivr_messages_loading:true
        }
    case GET_IVR_MESSAGES_RET:
        return {
            ...state,
            get_ivr_messages_ret:action.data,
            get_ivr_messages_loading:true
        }
    case GET_IVR_MESSAGES_LOADING:
        return {
            ...state,
            get_ivr_messages_ret:false,
            get_ivr_messages_loading:false
        }

    case UPDATE_FACEBOOK_DATA:
        return {
            ...state,
            update_facebook_data:action.data,
            update_facebook_data_loadiing:true
        }
    case UPDATE_FACEBOOK_DATA_RET:
        return {
            ...state,
            update_facebook_data_ret:action.data,
            update_facebook_data_loadiing:true
        }
    case UPDATE_FACEBOOK_DATA_LOADING:
        return {
            ...state,
            update_facebook_data_ret:false,
            update_facebook_data_loadiing:false
        }

    case SEND_IVR_MESSAGE:
        return {
            ...state,
            send_ivr_message:action.data,
            send_ivr_message_loading:true
        }
    case SEND_IVR_MESSAGE_RET:
        return {
            ...state,
            send_ivr_message_ret:action.data,
            send_ivr_message_loading:true
        }
    case SEND_IVR_MESSAGE_LOADING:
        return {
            ...state,
            send_ivr_message_ret:false,
            send_ivr_message_loading:false
        }


    case BULK_SEND:
        return {
            ...state,
            bulk_send:action.data,
            bulk_send_loading:true
        }
    case BULK_SEND_RET:
        return {
            ...state,
            bulk_send_ret:action.data,
            bulk_send_loading:true
        }
    case BULK_SEND_LOADING:
        return {
            ...state,
            bulk_send_ret:false,
            bulk_send_loading:false
        }


    case UPLOAD_FACEBOOK_QUERIES:
        return {
            ...state,
            upload_facebook_queries:action.data,
            upload_facebook_queries_loading:true
        }
    case UPLOAD_FACEBOOK_QUERIES_RET:
        return {
            ...state,
            upload_facebook_queries_ret:action.data,
            upload_facebook_queries_loading:true
        }
    case UPLOAD_FACEBOOK_QUERIES_LOADING:
        return {
            ...state,
            upload_facebook_queries_ret:false,
            upload_facebook_queries_loading:false
        }

    case GET_FACEBOOK_QUERIES:
        return {
            ...state,
            get_facebook_queries:action.data,
            get_facebook_queries_loading:true
        }
    case GET_FACEBOOK_QUERIES_RET:
        return {
            ...state,
            get_facebook_queries_ret:action.data,
            get_facebook_queries_loading:true
        }
    case GET_FACEBOOK_QUERIES_LOADING:
        return {
            ...state,
            get_facebook_queries_ret:false,
            get_facebook_queries_loading:false
        }

    case UPDATE_COVID_QUERIES:
        return {
            ...state,
            update_covid_queries:action.data,
            update_covid_queries_loading:true
        }
    case UPDATE_COVID_QUERIES_RET:
        return {
            ...state,
            update_covid_queries_ret:action.data,
            update_covid_queries_loading:true
        }
    case UPDATE_COVID_QUERIES_LOADING:
        return {
            ...state,
            update_covid_queries_ret:false,
            update_covid_queries_loading:false
        }

    case GET_IVR_CALLS:
        return {
            ...state,
            get_ivr_calls:action.data,
            get_ivr_calls_loading:true
        }
    case GET_IVR_CALLS_RET:
        return {
            ...state,
            get_ivr_calls_ret:action.data,
            get_ivr_calls_loading:true
        }
    case GET_IVR_CALLS_LOADING:
        return {
            ...state,
            get_ivr_calls_ret:false,
            get_ivr_calls_loading:false
        }


    case SEND_SOLUTION_SMS:
        return {
            ...state,
            send_solution_sms:action.data,
            send_solution_sms_loading:true
        }
    case SEND_SOLUTION_SMS_RET:
        return {
            ...state,
            send_solution_sms_ret:action.data,
            send_solution_sms_loading:true
        }
    case SEND_SOLUTION_SMS_LOADING:
        return {
            ...state,
            send_solution_sms_ret:false,
            send_solution_sms_loading:false
        }
    case GET_SOLUTIONS:
        return {
            ...state,
            get_solutions:action.data,
            get_solutions_loading:true
        }
    case GET_SOLUTIONS_RET:
        return {
            ...state,
            get_solutions_ret:action.data,
            get_solutions_loading:true
        }
    case GET_SOLUTIONS_LOADING:
        return {
            ...state,
            get_solutions_ret:false,
            get_solutions_loading:false
        }
    case SEND_SMS:
       return {
           ...state,
           send_sms:action.data,
           send_sms_loading:true
       }
   case SEND_SMS_RET:
       return {
           ...state,
           send_sms_ret:action.data,
           send_sms_loading:true
       }
   case SEND_SMS_LOADING:
       return {
           ...state,
           send_sms_ret:false,
           send_sms_loading:false
       }

    case UPDATE_CITY:
       return {
           ...state,
           update_city:action.data,
           update_city_loading:true
       }
   case UPDATE_CITY_RET:
       return {
           ...state,
           update_city_ret:action.data,
           update_city_loading:true
       }
   case UPDATE_CITY_LOADING:
       return {
           ...state,
           update_city_ret:false,
           update_city_loading:false
       }

    case UPDATE_STATUS:
        console.log(action.data,"action.data in Reducer")
       return {
           ...state,
           update_status:action.data,
           update_status_loading:true
       }
   case UPDATE_STATUS_RET:
       return {
           ...state,
           update_status_ret:action.data,
           update_status_loading:true
       }
   case UPDATE_STATUS_LOADING:
       return {
           ...state,
           update_status_ret:false,
           update_status_loading:false
       }

    case GET_REG_PUBLIC_QUERIES:
    return {
        ...state,
        get_reg_public_queries:action.data,
        get_reg_public_queries_loading:true
    }
    case GET_REG_PUBLIC_QUERIES_RET:
        return {
            ...state,
            get_reg_public_queries_ret:action.data,
            get_reg_public_queries_loading:true
        }
    case GET_REG_PUBLIC_QUERIES_LOADING:
        return {
            ...state,
            get_reg_public_queries_ret:false,
            get_reg_public_queries_loading:false
        }
 
   case GET_PUBLIC_QUERIES:
       return {
           ...state,
           get_public_queries:action.data,
           get_public_queries_loading:true
       }
   case GET_PUBLIC_QUERIES_RET:
       return {
           ...state,
           get_public_queries_ret:action.data,
           get_public_queries_loading:true
       }
   case GET_PUBLIC_QUERIES_LOADING:
       return {
           ...state,
           get_public_queries_ret:false,
           get_public_queries_loading:false
       }

    case GET_COVID_QUERIES:
        return {
            ...state,
            get_covid_queries:action.data,
            get_covid_queries_loading:true
        }
    case GET_COVID_QUERIES_RET:
        return {
            ...state,
            get_covid_queries_ret:action.data,
            get_covid_queries_loading:true
        }
    case GET_COVID_QUERIES_LOADING:
        return {
            ...state,
            get_covid_queries_ret:false,
            get_covid_queries_loading:true
        }

    case SUBMIT_QUERY:
        return {
            ...state,
            submit_query:action.data,
            submit_query_loading:true
        }
    case SUBMIT_QUERY_RET:
        console.log(action.data,"action.data in SubmitQuery Ret")
        return {
            ...state,
            submit_query_ret:action.data,
            submit_query_loading:true
        }
    case SUBMIT_QUERY_LOADING:
        return {
            ...state,
            submit_query_loading:false,
            submit_query_ret:false
        }
    case GET_MESSAGE_TEMPLATES_RET:
        return {
            ...state,
            get_message_templates_ret: action.data,
            message_templates_loading: false
        }
    case SEND_TEMPLATE_SMS:
        return {
            ...state,
            send_template_sms: action.data,
            send_template_sms_loading: true
        }
    case SEND_IVR_TEMPLATE_SMS:
        return {
            ...state,
            send_ivr_template_sms: action.data,
            send_template_sms_loading: true
        }
    case SEND_TEMPLATE_SMS_RET:
        return {
            ...state,
            send_template_sms_ret: action.data,
            send_template_sms_loading: true
        }
    case SEND_TEMPLATE_SMS_LOADING:
        return {
            ...state,
            send_template_sms_ret: false,
            send_template_sms_loading: false
        }
    case GET_IVR_SMS_TOKEN:
        return {
            ...state,
            get_ivr_sms_token_ret: false,
            get_ivr_sms_token_loading:true
        }
    case GET_IVR_SMS_TOKEN_RET:
        return {
            ...state,
            get_ivr_sms_token_ret:action.data,
            get_ivr_sms_token_loading:true
        }
    case GET_IVR_SMS_TOKEN_LOADING:
        return {
            ...state,
            get_ivr_sms_token_loading:false
        }
        
    case SEND_MANUAL_TEMPLATE_SMS:
        return {
            ...state,
            send_manual_template_sms: action.data,
            send_manual_template_sms_ret:false,
            send_manual_template_sms_loading:true,
        }
    case SEND_MANUAL_TEMPLATE_SMS_RET:
        return {
            ...state,
            send_manual_template_sms_ret: action.data,
            send_manual_template_sms_loading:true,
        }
    case SEND_MANUAL_TEMPLATE_SMS_LOADING:
        return {
            ...state,
            send_manual_template_sms_ret: false,
            send_manual_template_sms_loading:false
        }
        
    case GET_MANUAL_SMS_LIST:
        return {
            ...state,
            get_manual_sms_list: action.data,
            get_manual_sms_list_ret:false,
            get_manual_sms_list_loading:true,
        }
    case GET_MANUAL_SMS_LIST_RET:
        return {
            ...state,
            get_manual_sms_list_ret: action.data,
            get_manual_sms_list_loading:true,
        }
    case GET_MANUAL_SMS_LIST_LOADING:
        return {
            ...state,
            get_manual_sms_list_ret: false,
            get_manual_sms_list_loading:false
        }

   case GET_ALL_QUERIES:
       return {
           ...state,
           get_all_queries:action.data,
           get_all_queries_loading:true
       }
   case GET_ALL_QUERIES_RET:
       return {
           ...state,
           get_all_queries_ret:action.data,
           get_all_queries_loading:true
       }
    case GET_ALL_QUERIES_LOADING:
        return {
            ...state,
            get_all_queries_ret:false,
            get_all_queries_loading:false
        }

    case GET_MERGED_QUERIES:
        return {
            ...state,
            get_merged_queries:action.data,
            get_merged_queries_loading:true
        }
    case GET_MERGED_QUERIES_RET:
        return {
            ...state,
            get_merged_queries_ret:action.data,
            get_merged_queries_loading:true
        }
    case GET_MERGED_QUERIES_LOADING:
        return {
            ...state,
            get_merged_queries_ret:false,
            get_merged_queries_loading:false
        }

    case GET_QUERIES_BY_NUMBER:
        return {
            ...state,
            get_queries_by_number:action.data,
            get_queries_by_number_loading:true
        }
    case GET_QUERIES_BY_NUMBER_RET:
        return {
            ...state,
            get_queries_by_number_ret:action.data,
            get_queries_by_number_loading:true
        }
    case GET_QUERIES_BY_NUMBER_LOADING:
        return {
            ...state,
            get_queries_by_number_ret:false,
            get_queries_by_number_loading:false
        } 
    
    case UPDATE_PROSPECT:
        return {
            ...state,
            update_prospect:action.data,
            update_prospect_loading:true
        }
    case UPDATE_PROSPECT_RET:
        return {
            ...state,
            update_prospect_ret:action.data,
            update_prospect_loading:true
        }
    case UPDATE_PROSPECT_LOADING:
        return {
            ...state,
            update_prospect_ret:false,
            update_prospect_loading:false
        }
    
    case UPDATE_QUERY:
        return {
            ...state,
            update_query:action.data,
            update_query_loading:true
        }
    case UPDATE_QUERY_RET:
        return {
            ...state,
            update_query_ret:action.data,
            update_query_loading:true
        }
    case UPDATE_QUERY_LOADING:
        return {
            ...state,
            update_query_ret:false,
            update_query_loading:false
        }
    
    case SYNC_KNOWLARITY_LEADS:
        return {
            ...state,
            sync_knowlarity_leads:action.data,
            sync_knowlarity_leads_loading:true
        }
    case SYNC_KNOWLARITY_LEADS_RET:
        return {
            ...state,
            sync_knowlarity_leads_ret:action.data,
            sync_knowlarity_leads_loading:true
        }
    case SYNC_KNOWLARITY_LEADS_LOADING:
        return {
            ...state,
            sync_knowlarity_leads_ret:false,
            sync_knowlarity_leads_loading:false
        }
    
    case DOWNLOAD_SALES_LEADS:
        return {
            ...state,
            download_sales_leads:action.data,
            download_sales_leads_loading:true
        }
    case DOWNLOAD_SALES_LEADS_RET:
        return {
            ...state,
            download_sales_leads_ret:action.data,
            download_sales_leads_loading:true
        }
    case DOWNLOAD_SALES_LEADS_LOADING:
        return {
            ...state,
            download_sales_leads_ret:false,
            download_sales_leads_loading:false
        }

    default:
    return {...state}

    }
}