import React from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Card,
  Menu,
  Row,
  Col,
  Form,
  Tag,
  Modal,
  Upload,
  Typography
} from "antd";
import Highlighter from "react-highlight-words";
import ImgCrop from "antd-img-crop";
import {
  SearchOutlined,
  UserOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { for_loop, objectEquals } from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
import Servicelist from "./Servicelist";
import SearchLocation from "../../Components/SearchLocation";
import { Link } from "react-router-dom";
import { set_services } from "../../actions/global";
const { Title } = Typography;

const { SubMenu } = Menu;

const KeyManager = (props) => {
  const [edit_profile_flag, set_edit_profile_flag] = React.useState(false)



  const [state, set_state] = React.useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: "",
    searchedColumn: "",
  });
  const [form] = Form.useForm();
  const [variance_info, set_variance_info] = React.useState([]);
  const [service, set_service] = React.useState("");
  const [selectedRowKeys, setSelectedKeys] = React.useState([]);
  const [selected_professionals, set_selected_professionals] = React.useState(
    []
  );
  const [expandedRowKeys, set_expandedRowKeys] = React.useState([]);
  const [get_key_prof_params, set_get_key_prof_params] = React.useState({
    page: 1,
    limit: 10,
    locality: "All",
    speciality: "",
    total: 10,
    longitude: "",
    latitude: "",
    serviceName: "",
    searchParams: {
      name: "",
      email: "",
      mobileNumber: "",
    },
    filters: {
      locality: "All",
      userType: "",
      level: "",
      catalogue: "",
      isVideos:false,
      isPhotos:false
    },
  });

  const [professionals_list, set_professionals_list] = React.useState([]);
  const [data, set_data] = React.useState([]);
  const [list, set_list] = React.useState([]);
  const [professional_selected, set_professional_selected] = React.useState(
    false
  );
  const [edit_insurance, set_edit_insurance] = React.useState(false);
  const [error, set_error] = React.useState({
    location: { state: false, text: "" },
  });
  const [vedioList, setVedioList] = React.useState([
    {
      vedioLink: "",
      vedioHeading: "",
      subHeading: "",
      indexing: "",
    },
  ]);

  const [imageFileList, setImageFileList] = React.useState([]);

  React.useEffect(() => {
    props.get_key_prof(get_key_prof_params);
  }, []);

  React.useEffect(() => {
    if (!!props.get_key_prof_ret) {
      // console.log(props.get_key_prof_ret,"get_professional_ret")
      // set_bids(modif_data(props.bids))
      if (props.get_key_prof_ret.success) {
        set_data(modif_data([...props.get_key_prof_ret.data]));
        set_get_key_prof_params({
          ...get_key_prof_params,
          total: props.get_key_prof_ret.count,
        });
      } else {
        props.global_error({
          success: false,
          message: props.get_key_prof_ret.message,
          heading: "Professionals",
        });
      }
      props.get_key_prof_loading();
    }
  }, [props.get_key_prof_ret]);

  function handleButtonClick(e) {
    console.log("click left button", e);
  }

  function handleMenuClick(e) {
    console.log("click", e);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        <Link to="/dashboard/keymanager?send_sms">Send SMS</Link>
      </Menu.Item>

      <Menu.Item key="2" icon={<UserOutlined />}>
        <Link to="/dashboard/keymanager?send_email">Send Email</Link>
      </Menu.Item>

      <SubMenu title="Send Manually">
        <Menu.Item>SMS</Menu.Item>
        <Menu.Item>Eamil</Menu.Item>
      </SubMenu>
    </Menu>
  );



  const handleChange = (pagination, filters, sorter) => {
    console.log(pagination, filters, "pagination filter sorter");
    let state_filters = { ...get_key_prof_params.filters };
    console.log(state_filters, "state filters");
    let updated_filters = {};
    updated_filters.locality = "All";
    updated_filters.level = filters.level;
    updated_filters.catalogue = filters.catalogue;
    updated_filters.userType = filters.userType;
    if (
      !!(
        pagination.current === get_key_prof_params.page &&
        pagination.pageSize === get_key_prof_params.limit
      )
    ) {
      if (objectEquals(state_filters, updated_filters)) {
        console.log("Objects are equal");
      } else {
        // window.alert("Inside objects are not equal")
        console.log("Objects are not equal , call filter operation");

        console.log(
          state_filters,
          updated_filters,
          "state_filters, updated_filters"
        );
        console.log("Objects are not equal , call filter operation");
        set_get_key_prof_params({
          ...get_key_prof_params,
          page: 1,
          filters: {
            locality: "All",
            userType: filters.userType ? filters.userType : "",
            level: filters.level ? filters.level : "",
            catalogue: filters.catalogue ? filters.catalogue : "",
          },
        });
        props.get_key_prof({
          ...get_key_prof_params,
          page: 1,
          filters: {
            locality: "All",
            userType: filters.userType ? filters.userType : "",
            level: filters.level ? filters.level : "",
            catalogue: filters.catalogue ? filters.catalogue : "",
          },
        });
      }
    }
  };

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};

  const getColumnSearchProps = (dataIndex, placeholder, searchProp, type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, searchProp)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, searchProp)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handle_reset(clearFilters, data, dataIndex, searchProp)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
          title={text}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
    confirm();
    set_get_key_prof_params({
      ...get_key_prof_params,
      page: 1,
      searchParams: {
        ...get_key_prof_params.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    props.get_key_prof({
      ...get_key_prof_params,
      page: 1,
      searchParams: {
        ...get_key_prof_params.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    set_state({
      ...state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    set_state({ searchText: "" });
  };

  const modif_data = (arr) => {
    let prof_list = [];
    for_loop(arr, (item) => {
      let obj = {
        name: item.name,
        email: item.email,
        mobileNumber: item.mobileNumber,
        registrationNumber: item.registrationNumber,
        level: item.level,
        address: item.address,
        userType: item.userType,
        catalogue: item.catalogue,
        specialityNames: item.specialityNames,
        varianceInfo: item.varianceInfo,
        _id: item._id,
      };
      prof_list.push({
        ...obj,
      });
    });

    return prof_list;
  };

  const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
    set_get_key_prof_params({ ...get_key_prof_params, limit: b, page: 1 });
    props.get_key_prof({ ...get_key_prof_params, limit: b, page: 1 });
  };

  const page_change = (a, b) => {
    console.log(a, b, get_key_prof_params, "inside page change");
    // if(get_key_prof_params.page !== a){
    if (true) {
      set_get_key_prof_params({ ...get_key_prof_params, page: a, limit: b });
      props.get_key_prof({ ...get_key_prof_params, page: a, limit: b });
    }
  };

  const handle_reset = (a, b, c) => {
    handleReset(a);
    set_state({ filteredInfo: null });
    set_get_key_prof_params({
      ...get_key_prof_params,
      page: 1,
      searchParams: {
        name: "",
        email: "",
        mobileNumber: "",
      },
      filters: {
        locality: "All",
        userType: "",
        level: "",
        catalogue: "",
      },
    });
    props.get_key_prof({
      ...get_key_prof_params,
      page: 1,
      searchParams: {
        name: "",
        email: "",
        mobileNumber: "",
      },
      filters: {
        locality: "All",
        userType: "",
        level: "",
        catalogue: "",
      },
    });
  };
  const clear_filters = () => {
    // handleReset()
    set_state({ filteredInfo: null });
    // clearFilters()
    set_state({ searchText: "" });
    set_get_key_prof_params({
      ...get_key_prof_params,
      page: 1,
      filters: {
        locality: "All",
        userType: "",
        catalogue: "",
        level: "",
      },
    });
    props.get_key_prof({
      ...get_key_prof_params,
      page: 1,
      filters: {
        locality: "All",
        userType: "",
        catalogue: "",
        level: "",
      },
    });
  };

  const row_click = (r) => {
    console.log(r, "r in row_click");
    set_professional_selected(r);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ellipsis: true,
      ...getColumnSearchProps("name", "Search name", "name"),
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "Search email", "email"),
      // ellipsis: true,
      width: "15%",
    },
    {
      title: "Phone",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      ...getColumnSearchProps(
        "mobileNumber",
        "Search Phonenumber",
        "mobileNumber"
      ),
      // ellipsis: true,
      width: "15%",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "name",
      // ellipsis: true,
      // ...getColumnSearchProps('name', "Search name", 'name'),
      width: "20%",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      filters: [
        {
          text: "Level 1",
          value: "1",
        },
        {
          text: "Level 2",
          value: "2",
        },
        {
          text: "Level 3",
          value: "3",
        },
      ],
      filterMultiple: false,
      width: "10%",
      onChange: (a, b, c) => console.log(a, b, c, "a,b,c in onFilter"),
      // ellipsis: true,
    },
    {
      title: "User type",
      dataIndex: "userType",
      key: "userType",
      filters: [
        {
          text: "Hospitals",
          value: "Hospital",
        },
        {
          text: "Labs",
          value: "Lab",
        },
        {
          text: "Doctors",
          value: "Doctor",
        },
      ],
      filterMultiple: false,
      width: "15%",
      onChange: (a, b, c) => console.log(a, b, c, "a,b,c in onFilter"),
      // ellipsis: true,
    },
    {
      title: "Catalogue",
      dataIndex: "catalogue",
      key: "catalogue",
      filters: [
        {
          text: "Yes",
          value: "Yes",
        },
        {
          text: "No",
          value: "No",
        },
      ],
      width: "10%",
      filterMultiple: false,
      onChange: (a, b, c) => console.log(a, b, c, "a,b,c in onFilter"),
      // ellipsis: true,
    },
  ];

  const onSelectChange = (data) => {
    console.log("selectedRowKeys changed: ", data);
    // this.setState({ selectedRowKeys });
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (e, b) => console.log(e, b),
  };

  const expand = (record, a, b) => {
    console.log(record, a, b, "record,a,b");
    if (expandedRowKeys[0] !== a._id) {
      set_expandedRowKeys([a._id]);
      console.log(a, "a.varianceInfo");
      set_variance_info(a.varianceInfo);
    } else {
      set_expandedRowKeys([]);
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        timeout = null;

        func(...args);
      };

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);
    };
  };
  const returnedFunction = debounce(function (data) {
    try {
      variance_change(data);
    } catch (e) {
      console.log(e);
    }
    // setState({...state, selectPlace:data})
  }, 500);

  function showTotal(total) {
    return `Total ${total} items`;
  }

  const variance_change = (data) => {
    set_get_key_prof_params({ ...get_key_prof_params, serviceName: data });
    props.get_key_prof({ ...get_key_prof_params, serviceName: data });
  };

  console.log(props.global_store.services, "props.global_store.services");
  if (!!professional_selected) {
    return (
      <Redirect
        to={`/dashboard/professionals?professional_id=${professional_selected._id}`}
      />
    );
  }

  const handleVedioChange = (e, index) => {
    const { field, value } = e.target;
    const list = [...vedioList];
    list[index][field] = value;
    setVedioList(list);
  };

  const addVedioDetails = () => {
    const list = [...vedioList];
    list.push({
      vedioLink: "",
      vedioHeading: "",
      subHeading: "",
      indexing: "",
    });
    setVedioList(list);
  };

  const removeVedioDetails = (index) => {
    const list = [...vedioList];
    list.splice(index, 1);
    setVedioList(list);
  };

  const onChangeImage = ({ fileList: newFileList }) => {
    setImageFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          {/* <Dropdown.Button onClick={handleButtonClick} overlay={menu}>
                  Actions
                </Dropdown.Button> */}
          <Link to="/dashboard/keymanager?send_data">
            <Button
              type="primary"
              onClick={() =>
                props.set_key_manager_professionals([...selected_professionals])
              }
            >
              Send Data
            </Button>
          </Link>
        </Col>
        <Col span={12}>
          <AntdInput
            label="Speciality"
            value={get_key_prof_params.speciality}
            disabled={props.get_key_prof_loading_flag}
            options={props.global_store.specialities}
            loading={props.global_store.get_specialities_loading}
            error_text={""}
            toggle_error={() => console.log()}
            error={false}
            name="speciality"
            type="select"
            onChange={(event) => {
              set_get_key_prof_params({
                ...get_key_prof_params,
                speciality: event,
                serviceName: "",
              });
              props.get_key_prof({
                ...get_key_prof_params,
                speciality: event,
                serviceName: "",
              });
              props.get_services({ speciality: event });
              set_service("");
            }}
            placeholder="Choose speciality"
          />
        </Col>
        <Col span={24}>
          <Form form={form} layout="vertical">
            <div style={{ width: "100%" }} className="flex-parent-new">
              <span className="flex-child-1-new">
                <AntdInput
                  label="Service"
                  value={service}
                  disabled={props.get_key_prof_loading_flag}
                  options={props.global_store.services}
                  loading={props.global_store.get_specialities_loading}
                  error_text={""}
                  toggle_error={() => console.log()}
                  error={false}
                  name="service"
                  type="select"
                  onChange={(event) => {
                    set_service(event);
                    set_get_key_prof_params({
                      ...get_key_prof_params,
                      serviceName: props.global_store.services.filter(
                        (item) => item.value === event
                      )[0].name,
                    });
                    props.get_key_prof({
                      ...get_key_prof_params,
                      serviceName: props.global_store.services.filter(
                        (item) => item.value === event
                      )[0].name,
                    });
                  }}
                  placeholder="Choose speciality"
                />
                {/* <AntdInput 
       label="Service"  
      
       error_text={""} 
      //  toggle_error = {()=>set_error({...error,name:{text:'',state:false}})} 
      toggle_error = {()=>console.log()}
       error={false} 
       name="service" 
       onChange={(e)=>returnedFunction(e.target.value)}
      //  onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
       placeholder="Enter Service"
       /> */}
              </span>
              <span className="flex-child-1-new">
                <SearchLocation
                  label="Location"
                  disabled={props.get_bids_loading_flag}
                  error_text={error.location.text}
                  toggle_error={() =>
                    set_error({
                      ...error,
                      location: { text: "", state: false },
                    })
                  }
                  error={error.location.state}
                  name="location"
                  set_long_lat={(val) => {
                    set_get_key_prof_params({
                      ...get_key_prof_params,
                      page: 1,
                      longitude: val.lng,
                      latitude: val.lat,
                    });
                    props.get_key_prof({
                      ...get_key_prof_params,
                      page: 1,
                      longitude: val.lng,
                      latitude: val.lat,
                    });
                  }}
                  placeholder="Enter Location"
                />
              </span>
             
            </div>

           
          </Form>
        </Col>
      </Row>
      <Card title="Professionals">
        <Table
          columns={columns}
          dataSource={data}
          loading={props.get_key_prof_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          expandedRowRender={(record) => {
            console.log(record, "record");
            return (
              <React.Fragment>
                <div key={record._id}>
                  <Servicelist
                    get_key_prof_services={props.get_key_prof_services}
                    get_key_prof_services_ret={props.get_key_prof_services_ret}
                    get_key_prof_services_loading_flag={
                      props.get_key_prof_services_loading_flag
                    }
                    get_key_prof_services_loading={
                      props.get_key_prof_services_loading
                    }
                    global_error={props.global_error}
                    professional_id={record._id}
                    specialityId={get_key_prof_params.speciality}
                    variance_info={variance_info}
                  />
                </div>
              </React.Fragment>
            );
          }}
          expandedRowKeys={expandedRowKeys}
          onExpandedRowsChange={(a, b, c) =>
            console.log(a, b, c, "onExpandedRowsChange")
          }
          onExpand={(a, b, c) => expand(a, b, c)}
          rowKey={(record) => record._id}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: (e, b) => {
              setSelectedKeys([...e]);
              set_selected_professionals([
                ...b.map((item) => {
                  return {
                    name: item.name,
                    _id: item._id,
                  };
                }),
              ]);
            },
          }}
          pagination={{
            showSizeChanger: true,
            limit: get_key_prof_params.limit,
            onChange: (a, c) => page_change(a, c),
            // onShowSizeChange:(a,b)=>change_limit(a,b),
            defaultCurrent: get_key_prof_params.page,
            total: get_key_prof_params.total,
            showTotal: showTotal,
          }}
        />
      </Card>

      {  edit_profile_flag &&  <React.Fragment>

        <Card title="Introduction" direction="vertical">
        <Button
          type="primary"
          icon={<EditOutlined />}
          style={{ float: "right" }}
        >
          Edit
        </Button>
        <AntdInput type="textarea" />
      </Card>
      <Card title="Photo">
        <Space direction="horizontal">
          <img
            height={200}
            width={400}
            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          />
          <img
            height={200}
            width={200}
            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          />
        </Space>
      </Card>
      <Card title="Photo">
        <ImgCrop rotate>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={imageFileList}
            onChange={onChangeImage}
            onPreview={onPreview}
          >
            {< PlusCircleTwoTone />}
            {" Add Photo"}
          </Upload>
        </ImgCrop>
      </Card>
      <Card title="Vedio">
        <Form layout="vertical">
          <Form.Item>
            {vedioList.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex-parent-new ">
                    <span className="flex-child-1-new">
                      <Form.Item label="Enter Video link">
                        <AntdInput onChange={handleVedioChange} />
                      </Form.Item>
                    </span>
                    <span className="flex-child-1-new">
                      <Form.Item label="Enter Heading">
                        <AntdInput onChange={handleVedioChange} />
                      </Form.Item>
                    </span>
                  </div>
                  <div className="flex-parent-new ">
                    <span className="flex-child-1-new">
                      <Form.Item label="Sub Heading">
                        <AntdInput onChange={handleVedioChange} />
                      </Form.Item>
                    </span>
                    <span className="flex-child-1-new">
                      <Form.Item label="Enter Indexing">
                        <div className="flex-parent-new">
                          <span className="flex-child-1-new">
                            <AntdInput
                              placeholder="Ex. 1"
                              onChange={handleVedioChange}
                            />
                          </span>
                          <span className="flex-child-1-new">
                            <Button
                              type="transparent"
                              icon={<DeleteOutlined />}
                              onClick={() => removeVedioDetails(index)}
                            >
                              Delete
                            </Button>
                          </span>
                          <span className="flex-child-1-new">
                            <Button type="primary" icon={<EditOutlined />}>
                              Edit
                            </Button>
                          </span>
                        </div>
                      </Form.Item>
                    </span>
                  </div>
                </div>
              );
            })}
            <Button
              type="dashed"
              icon={<PlusCircleTwoTone />}
              onClick={addVedioDetails}
            >
              Add more
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Team of Expert">
        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="Enter doctor name"
              placeholder="Ex. Atul Mishra"
            />
          </span>
          <span className="flex-child-1-new">
            <br />
            <Button type="default" icon={<PlusCircleTwoTone />}>
              Add
            </Button>
          </span>
        </div>

        <img
          height={130}
          width={226}
          alt="example"
          style={{ borderRadius: 10 }}
          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        />
        <h4>Dr. Atul Mishra</h4>
        <p style={{ opacity: 0.7 }}>
          Surgeon <br />
          Experience: 1
        </p>
      </Card>
      <Card title="Centers">
        <Row>
          <Tag style={{ height: 70, width: 350 }}>
            <Row>
              <img
                height={60}
                width={60}
                alt="example"
                style={{ borderRadius: 50, textAlign: "center" }}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              />
              <h2 style={{ textAlign: "center" }}>Gurgaon sector-43</h2>
            </Row>
          </Tag>
          <Tag style={{ height: 70, width: 350 }}>
            <PlusCircleTwoTone style={{ margin: "auto" }} />
            <Button type="link" style={{ textAlign: "center" }}>
              Add More
            </Button>
          </Tag>
        </Row>
      </Card>
      <Card title="Time Slots">
        <Tag style={{ borderRadius: 15, width: 100, textAlign: "center" }}>
          <h3>9:00 AM</h3>
        </Tag>
        <Tag style={{ borderRadius: 15, width: 120, textAlign: "center" }}>
          <Button type="link" icon={<PlusCircleTwoTone />}>
            Add more
          </Button>
        </Tag>
      </Card>
      <Card title="Insurance Accepted in Facility">
        <SearchOutlined />
        <Input placeholder="Search insurance provider" style={{ width: 200 }} />
        <Button
          type="primary"
          icon={<EditOutlined />}
          style={{ float: "right" }}
          onClick={() => set_edit_insurance(true)}
        >
          Edit
        </Button>
      </Card>


          </React.Fragment>}

      <Modal
        visible={edit_insurance}
        width={800}
        onCancel={() => set_edit_insurance(false)}
      >
        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput label="Enter insurance provider name" />
          </span>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default KeyManager;
