import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {  GET_MEMBERS , ADD_MEMBER} from '../Constants'
import { get_members_ret, add_member_ret, set_members_data } from '../actions/members'
import api from '../utils/api_routes'
import store from '../store'

function* get_members_saga() {
    console.log("Inside get_members_saga")

   try {
      const  data = yield store.getState().members_store.get_members
      const headers = {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
       }
       const api_data = yield call(api.members.get_members, data, headers)
      console.log(api_data,"api_data in get_members_saga")
      if(!!api_data){
         if(api_data.success){
           
            yield put(get_members_ret({
                success:true,
                message:api_data.message ||'Something went wrong try again later..',
                data:api_data.data
               }))
        }else{
            yield put(get_members_ret({
                success:false,
                message:api_data.message ||'Something went wrong try again later..',
                data:[]
               }))
        }
      }
   } catch (e) {
    console.log(e,"e in get_act insigt saga")
    try{
        yield put(get_members_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
      }catch(x){
        yield put(get_members_ret({
            success:false,
            message:'Something went wrong try again later..',
            data:[]
           }))
        }
   }
}

function* add_member_saga() {
  try {
     const  data = yield store.getState().members_store.add_member
     const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      const api_data = yield call(api.members.add_member, data, headers)
     console.log(api_data,"api_data in add_member")
     if(!!api_data){
        if(api_data.status===201){
           yield put(add_member_ret({
               success:true,
               message:api_data.message ||'Member successfully added.',
               data:api_data.data.user
              }))
            //   const  members = yield store.getState().members_store.members_data.members
            //   yield put(set_members_data({...members, ...api_data.data.data.user}))
       }else{
          
           yield put(add_member_ret({
               success:false,
               message:api_data.data.error||'Something went wrong try again later..',
               data:[]
              }))
       }
     }
  } catch (e) {
   console.log(e,"e in get_act insigt saga")
   try{
       yield put(add_member_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
     }catch(x){
       yield put(add_member_ret({
           success:false,
           message:'Something went wrong try again later..',
           data:[]
          }))
       }
  }
}



export const members_saga = [
   takeLatest(GET_MEMBERS, get_members_saga),
   takeLatest(ADD_MEMBER, add_member_saga)
]

export default members_saga

