import  React from "react"
import { Tabs, Card } from 'antd';
import { SolutionOutlined, FolderOpenOutlined } from '@ant-design/icons';
import VarianceData from "./VarianceData"
import BusinessReports from "./BusinessReports"
import CatalogueData from './CatalogueData'
import Speciality from './Speciality'


const { TabPane } = Tabs;

const SendEmail = (props) => {

    return <React.Fragment>
          <Card title="Information">
            <Tabs defaultActiveKey="1">
                <TabPane
                        tab={
                            <span>
                           <SolutionOutlined />
                              Variance Update 
                            </span>
                        }
                        key="1">
                            <VarianceData 
                                global_error = {props.global_error}
                                send_variance_info = {props.send_variance_info}
                                send_variance_info_loading = {props.send_variance_info_loading}
                                send_variance_info_loading_flag = {props.send_variance_info_loading_flag}
                                send_variance_info_ret = {props.send_variance_info_ret}

                                send_business_reports = {props.send_business_reports}
                                send_business_reports_loading = {props.send_business_reports_loading}
                                send_business_reports_loading_flag = {props.send_business_reports_loading_flag}
                                send_business_reports_ret = {props.send_business_reports_ret}

                                previous_business_reports = {props.previous_business_reports}
                                previous_business_reports_loading = {props.previous_business_reports_loading}
                                previous_business_reports_loading_flag = {props.previous_business_reports_loading_flag}
                                previous_business_reports_ret = {props.previous_business_reports_ret}

                                previous_variance_list = {props.previous_variance_list}
                                previous_variance_list_loading = {props.previous_variance_list_loading}
                                previous_variance_list_loading_flag = {props.previous_variance_list_loading_flag}
                                previous_variance_list_ret = {props.previous_variance_list_ret}


                                email_preview = {props.email_preview}
                                email_preview_loading = {props.email_preview_loading}
                                email_preview_ret = {props.email_preview_ret}
                                email_preview_loading_flag = {props.email_preview_loading}
                    
                                global_store={props.global_store}
                                keymanager_store = {props.keymanager_store}
                                set_key_manager_professionals = {props.set_key_manager_professionals}

                                email
                            />
              
                </TabPane>
                <TabPane
                tab={
                    <span>
                       <FolderOpenOutlined />
                       Business Report 
                    </span>
                }
                key="2"
                >
                    <BusinessReports
                        send_business_reports = {props.send_business_reports}
                        send_business_reports_loading = {props.send_business_reports_loading}
                        send_business_reports_loading_flag = {props.send_business_reports_loading_flag}
                        send_business_reports_ret = {props.send_business_reports_ret}

                        previous_business_reports = {props.previous_business_reports}
                        previous_business_reports_loading = {props.previous_business_reports_loading}
                        previous_business_reports_loading_flag = {props.previous_business_reports_loading_flag}
                        previous_business_reports_ret = {props.previous_business_reports_ret}

                        email_preview = {props.email_preview}
                        email_preview_loading = {props.email_preview_loading}
                        email_preview_ret = {props.email_preview_ret}
                        email_preview_loading_flag = {props.email_preview_loading}

                        global_store={props.global_store}
                        global_error = {props.global_error}
                        keymanager_store = {props.keymanager_store}
                        set_key_manager_professionals = {props.set_key_manager_professionals}
                    />
                </TabPane>
                <TabPane
                tab={
                    <span>
                       <FolderOpenOutlined />
                      Speciality Wise Report 
                    </span>
                }
                key="3"
                >
                    <Speciality 
                            sent_speciality_reports = {props.sent_speciality_reports}
                            sent_speciality_reports_ret = {props.sent_speciality_reports_ret}
                            sent_speciality_reports_loading_flag = {props.sent_speciality_reports_loading_flag}
                            sent_speciality_reports_loading = {props.sent_speciality_reports_loading}

                            send_speciality_report = {props.send_speciality_report}
                            send_speciality_report_loading = {props.send_speciality_report_loading}
                            send_speciality_report_loading_flag = {props.send_speciality_report_loading_flag}
                            send_speciality_report_ret = {props.send_speciality_report_ret}

                            previous_business_reports = {props.previous_business_reports}
                            previous_business_reports_loading = {props.previous_business_reports_loading}
                            previous_business_reports_loading_flag = {props.previous_business_reports_loading_flag}
                            previous_business_reports_ret = {props.previous_business_reports_ret}


                            email_preview = {props.email_preview}
                            email_preview_loading = {props.email_preview_loading}
                            email_preview_ret = {props.email_preview_ret}
                            email_preview_loading_flag = {props.email_preview_loading}

                            get_services= {props.get_services}
                            global_store={props.global_store}
                            global_error = {props.global_error}
                            keymanager_store = {props.keymanager_store}
                            set_key_manager_professionals = {props.set_key_manager_professionals}
                    />
                </TabPane>

                <TabPane
                tab={
                    <span>
                       <FolderOpenOutlined />
                      Catalogue Reports
                    </span>
                }
                key="4"
                >
                    <CatalogueData 
                            send_catalogue_report = {props.send_catalogue_report}
                            send_catalogue_report_loading = {props.send_catalogue_report_loading}
                            send_catalogue_report_loading_flag = {props.send_catalogue_report_loading_flag}
                            send_catalogue_report_ret = {props.send_catalogue_report_ret}


                            sent_catalogue_reports = {props.sent_catalogue_reports}
                            sent_catalogue_reports_loading_flag = {props.sent_catalogue_reports_loading_flag}
                            sent_catalogue_reports_loading = {props.sent_catalogue_reports_loading}
                            sent_catalogue_reports_ret = {props.sent_catalogue_reports_ret}


                            email_preview = {props.email_preview}
                            email_preview_loading = {props.email_preview_loading}
                            email_preview_ret = {props.email_preview_ret}
                            email_preview_loading_flag = {props.email_preview_loading}

                            get_services= {props.get_services}
                            global_store={props.global_store}
                            global_error = {props.global_error}
                            keymanager_store = {props.keymanager_store}
                            set_key_manager_professionals = {props.set_key_manager_professionals}
                    />
                </TabPane>
            </Tabs>
            </Card>
    </React.Fragment>
}

export default SendEmail