import React from "react"
import { PlayButton, Timer } from 'react-soundplayer/components';
 
// it's just an alias for `withSoundCloudAudio` but makes code clearer
import { withCustomAudio } from 'react-soundplayer/addons';



const AudioPlayer = withCustomAudio(props => {
    const { trackTitle } = props;
   
    return (
      <div>
        <PlayButton {...props} />
       
        <Timer {...props} />
      </div>
    );
  });

export default AudioPlayer