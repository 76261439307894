import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Menu,
  DatePicker,
  Modal,
  Form,
  Select,
  Popover,
  Row,
  Col,
  Card,
  Divider,
  Checkbox
} from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  DownOutlined,
  UserOutlined,
  InfoCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  for_loop,
  objectEquals,
  isEmpty,
  error_form_check,
  getDateFormat,
} from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Photogallery from "../../Components/Photogallery"
import moment from "moment";

const CheckboxGroup = Checkbox.Group;

const PreAuth = (props) => {


 const onPlaceSelected = ( place ) => {
    try{
      geocodeByAddress(place.value.description)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) =>{
            let location = {type: "Point", coordinates: [lng, lat]}
            setUserDetails({...userDetails, location: location})
        }
   
    ).catch(e=>{
      let location = {type: "Point", coordinates: ["", ""]}
      setUserDetails({...userDetails, location: location})
    })
    }catch (e){
      console.log(e,"catch in onPlaceSelected")
    }
  }

    useEffect(() => {
        if (isEmpty(userDetails)) {
            return <Redirect to="/dashboard/bookings?add-user" />;
        }
    }, [])

    useEffect(() => {
        setUserDetails(props.user.data)
    }, [props.user])

    const [userDetails, setUserDetails] = useState({})

    const plainOptions = [
        { label: 'Male', value: "M"}, 
        { label: 'Female', value: "F"},
        { label: 'Other', value: "O"}];

    const submitUpdates = () => {
    }

    return (props.user && userDetails && <>
        <Row gutter={24}>
            <Col span={12}>
                <h1>User Details</h1>
                <div className="patient-details-box">
                    <Card
                        className="patient-details-card"
                        bodyStyle={{
                            // height: "52rem",
                            // overflowY: "scroll",
                            // padding: "1rem 2rem 1rem 1rem",
                            backgroundColor: "#F5F5F5",
                        }}
                        // bordered={false}
                    >
                        <Form>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Mobile No</strong>}
                                            value={userDetails.mobileNumber}
                                            name="mobileNo"
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Patient Name</strong>}
                                            name="Name"
                                            value={userDetails.name}
                                            onChange={(event) => {
                                                setUserDetails({...userDetails, name: event.target.value})
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Location"
                                // validateStatus={error?"error":''}
                                // help={error?props.error_text:''}
                            >
                                <GooglePlacesAutocomplete
                                    selectProps={{
                                    onChange: onPlaceSelected,
                                    isDisabled:props.disabled,
                                    placeholder:"Search location"
                                    }}
                                    componentRestrictions={{country: "in"}}
                                    apiKey="AIzaSyAqn7q_9-GV3balsnk94-FgODIu_8lT3oM"
                                />
                            </Form.Item>
                            <Form.Item noStyle>
                                <AntdInput
                                    label={<strong>Address(Optional)</strong>}
                                    value={userDetails.address}
                                    name="Location"
                                    onChange={(event) => {
                                        setUserDetails({...userDetails, address: event.target.value})
                                    }}
                                />
                            </Form.Item>
                            <Form.Item noStyle>
                                <AntdInput
                                    label={<strong>Email Id</strong>}
                                    value={userDetails.email}
                                    name="email"
                                    onChange={(event) => {
                                        setUserDetails({...userDetails, email: event.target.value})
                                    }}
                                />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <label className="label-class">Gender</label>
                                        <CheckboxGroup options={plainOptions} value={userDetails.gender} onChange={(val) => console.log(val)} />
                                    </Col>
                                    <Col span={12}>
                                        <label>D.O.B</label><br />
                                        <DatePicker
                                            defaultValue={moment(userDetails.birthDate)}
                                            format="DD/MM/YYYY"
                                            onChange={(date, dateString) => {
                                                setUserDetails({
                                                ...userDetails,
                                                birthDate: dateString,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>

                            </Form.Item>
                            <Form.Item noStyle>
                                <AntdInput
                                    label={<strong>Previous Treatment Details</strong>}
                                    value={userDetails.previousTreatments}
                                    type="textarea"
                                    rows="5"
                                    onChange={(event) => {
                                        setUserDetails({...userDetails, previousTreatments: event.target.value})
                                    }}
                                />
                            </Form.Item>
                        </Form>
                        <div className="prescription-reports-container">
                            <Divider className="divider-text" orientation="left" orientationMargin="0">
                                Attached reports & Prescriptions <PaperClipOutlined />
                            </Divider>
                            <Row>
                                <Photogallery
                                    className="photo-gallery-docs"
                                    data={[{"_id":"608bff7e9a8abcac62f4d6bc","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/10.jpg"},{"_id":"608bff7e9a8abcac62f4d6bb","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/11.jpg"},{"_id":"608bff7e9a8abcac62f4d6ba","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/2.jpg"},{"_id":"608bff7e9a8abcac62f4d6b9","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/3.jpg"},{"_id":"608bff7e9a8abcac62f4d6b8","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/4.jpg"},{"_id":"608bff7e9a8abcac62f4d6b7","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/5.jpg"},{"_id":"608bff7e9a8abcac62f4d6b6","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/6.jpg"},{"_id":"608bff7e9a8abcac62f4d6b5","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/7.jpg"},{"_id":"608bff7e9a8abcac62f4d6b4","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/8.jpg"},{"_id":"608bff7e9a8abcac62f4d6b3","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/9.jpg"},{"_id":"608bff7e9a8abcac62f4d6b2","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/ll.jpg"},{"_id":"61bafe84046e922b1128a0a9","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/d70acc9d6e9b128295e21e4d33d41828.jpg"}]}
                                    limit={3}
                                />
                            </Row>
                        </div>
                    </Card>
                </div>
            </Col>
            <Col span={12}>
                <h1>Policy Details</h1>
                <div className="patient-details-box policy-details-box">
                    <Card
                        className="patient-details-card"
                        bodyStyle={{
                            // height: "52rem",
                            // overflowY: "scroll",
                            // padding: "1rem 2rem 1rem 1rem",
                            backgroundColor: "#F9F9F9",
                        }}
                        // bordered={false}
                    >
                        <Form>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Policy No</strong>}
                                            name="mobileNo"
                                            onChange={(event) => {
                                                setUserDetails({...userDetails, policyNumber: event.target.value})
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Insurance Partner</strong>}
                                            value={userDetails.insuranceDetails && userDetails.insuranceDetails.insurancePartner}
                                            name="insurancePartner"
                                            onChange={(event) => {
                                                setUserDetails({...userDetails, insurancePartner: event.target.value})
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Aadhar Card No</strong>}
                                            name="aadharCard"
                                            value={userDetails.aadharCard}
                                            onChange={(event) => {
                                                setUserDetails({...userDetails, aadharCard: event.target.value})
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Pancard No</strong>}
                                            value={userDetails.panCard}
                                            name="patientName"
                                            onChange={(event) => {
                                                setUserDetails({...userDetails, panCard: event.target.value})
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Policy Type</strong>}
                                            name="policyType"
                                            value={userDetails.insuranceDetails && userDetails.insuranceDetails.policyType}
                                            onChange={(event) => {
                                                setUserDetails({...userDetails, policyType: event.target.value})
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Policy Validity</strong>}
                                            value={userDetails.insuranceDetails && userDetails.insuranceDetails.validity}
                                            name="patientName"
                                            onChange={(event) => {
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item noStyle>
                                    <AntdInput
                                        label={<strong>Start date</strong>}
                                        value={userDetails.insuranceDetails && userDetails.insuranceDetails.start}
                                        name="startDate"
                                        onChange={(event) => {
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item noStyle>
                                    <AntdInput
                                        label={<strong>Renew date</strong>}
                                        value={userDetails.insuranceDetails && userDetails.insuranceDetails.renew}
                                        name="renewDate"
                                        onChange={(event) => {
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item noStyle>
                                    <AntdInput
                                        label={<strong>Expirey date</strong>}
                                        value={userDetails.insuranceDetails && userDetails.insuranceDetails.expirey}
                                        name="expDate"
                                        onChange={(event) => {
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="prescription-reports-container">
                            <Divider className="divider-text" orientation="left" orientationMargin="0">
                                Policy Cards <PaperClipOutlined />
                            </Divider>
                            <Row>
                                <Photogallery
                                    className="photo-gallery-docs"
                                    data={[{"_id":"608bff7e9a8abcac62f4d6bc","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/10.jpg"},{"_id":"608bff7e9a8abcac62f4d6bb","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/11.jpg"},{"_id":"608bff7e9a8abcac62f4d6ba","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/2.jpg"},{"_id":"608bff7e9a8abcac62f4d6b9","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/3.jpg"},{"_id":"608bff7e9a8abcac62f4d6b8","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/4.jpg"},{"_id":"608bff7e9a8abcac62f4d6b7","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/5.jpg"},{"_id":"608bff7e9a8abcac62f4d6b6","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/6.jpg"},{"_id":"608bff7e9a8abcac62f4d6b5","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/7.jpg"},{"_id":"608bff7e9a8abcac62f4d6b4","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/8.jpg"},{"_id":"608bff7e9a8abcac62f4d6b3","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/9.jpg"},{"_id":"608bff7e9a8abcac62f4d6b2","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/ll.jpg"},{"_id":"61bafe84046e922b1128a0a9","imageUrl":"https://facility-videos-pictures.s3.ap-south-1.amazonaws.com/9958026384-Artemis%20Hospital/d70acc9d6e9b128295e21e4d33d41828.jpg"}]}
                                    limit={3}
                                />
                            </Row>
                        </div>
                        <Form.Item noStyle>
                            <AntdInput
                                label={<strong>Remarks</strong>}
                                // value={"hello World"}
                                name="Location"
                                type="textarea"
                                rows="5"
                                onChange={(event) => {
                                }}
                            />
                        </Form.Item>
                        <div className="otp-container">
                            <Row gutter={24}>
                                <Col className="btn-container" span={8}>
                                    <Button>Pre-Auth</Button>
                                </Col>
                                <Col span={16}>
                                    <Form.Item noStyle>
                                        <AntdInput
                                            label={<strong>Enter Your 4 digit OTP</strong>}
                                            name="mobileNo"
                                            onChange={(event) => {
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </div>
            </Col>
        </Row>
        <div className="submit-updates">
            <Button onClick={(e) => {
                e.preventDefault()
                submitUpdates()
            }} >Submit</Button>
        </div>
    </>)
}
export default PreAuth;
