import React from "react"
import { global_error, global_error_clr, global_loading_set, set_mount, get_services } from "../actions/global"
import { get_bids, get_bids_loading } from "../actions/bidding"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import TrackBids from "../Views/TrackBids";

class Bidding extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:false
            }
        }

    render(){
console.log(this.props.global_store,"props in global_store")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?track") ? <React.Fragment></React.Fragment>: <TrackBids
                    get_bids = {this.props.get_bids}
                    get_bids_ret = {this.props.bidding_store.get_bids_ret}
                    get_bids_loading_flag = {this.props.bidding_store.get_bids_loading}
                    get_bids_loading = {this.props.get_bids_loading}
                  

                   global_error = {this.props.global_error}
                   global_loading_set = {this.props.global_loading_set}


                   global_store={this.props.global_store}
                   get_services = {this.props.get_services}
                 />  }
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  bidding_store:state.bidding_store,
  global_error_ret:state.global_store.global_error_ret
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
  get_bids,
  get_bids_loading
 })(Bidding)
