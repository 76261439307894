import React, { useEffect } from "react"
import { Button, Card, Form, Row, Col, notification } from 'antd';
import AntdInput from "../../Components/AntdInput"
import { get_url } from "../../utils/common_utilities";
import axios from "axios";

const ServiceMetaData = (props) => {

    const [data, set_data] = React.useState({
        meta_title: '',
        meta_description: '',
    })
    const [options, setOptions] = React.useState([]);
    const [speciality, setSpeciality] = React.useState("");
    const [form] = Form.useForm()

    useEffect(() => {

        const getOptions = async (url) => {
            try {
                const initial_options = await axios.get(url);

                const newOptions = initial_options?.data?.data?.map(item => ({ name: item?.speciality, value: item?._id }));
                setOptions(newOptions);

                const initial_data = initial_options?.data?.data?.find(item => (item?._id === speciality));
                set_data({ meta_title: initial_data?.metaData?.title, meta_description: initial_data?.metaData?.desc });
            }
            catch (error) {
                notification["error"]({
                    message: 'Error',
                    description: "An Error Occurred!! Please try again later.",
                })
            }
        }
        const url = `${get_url()}/mcmCatalogue/getSpecialitiesForWeb`;
        getOptions(url);

    }, [speciality])

    const submit = async () => {
        const url = `${get_url()}/mcmCatalogue/updateCommonSpecialityById?id=${speciality}`;
        try {
            const response = await axios.post(url, data);

            if (response?.data?.success) {
                // console.log({response},"HEREEEE")
                notification["success"]({
                    message: 'Success',
                    description: "Data added sucessfully",
                })
            }
        }
        catch (error) {
            notification["error"]({
                message: 'Error',
                description: "An Error Occurred!! Please try again later.",
            })
        }

    }

    return (<React.Fragment>
        <Card title="Update Speciality Meta Data" bordered={true}>
            <Row >
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <div className="flex-parent-new">
                            <AntdInput
                                label="Speciality"
                                value={speciality}
                                disabled={props.get_bids_loading_flag || props.add_service_loading_flag}
                                options={options}
                                loading={props.global_store.get_specialities_loading || props.add_service_loading_flag}
                                name="speciality"
                                type="select"
                                onChange={event => setSpeciality(event)}
                                placeholder="Choose speciality" />
                        </div>
                        <div className="flex-parent-new">
                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="MetaData Title"
                                    value={data.meta_title}
                                    disabled={props.update_professional_byid_loading_flag}
                                    loading={props.update_professional_byid_loading_flag}
                                    name="meta_title"
                                    onChange={event => set_data({ ...data, meta_title: event.target.value })}
                                    placeholder="Enter MetaData Title" />
                            </span>

                            <span className="flex-child-1-new">
                                <AntdInput
                                    label="MetaData Description"
                                    value={data.meta_description}
                                    disabled={props.update_professional_byid_loading_flag}
                                    loading={props.update_professional_byid_loading_flag}
                                    name="meta_description"
                                    onChange={event => set_data({ ...data, meta_description: event.target.value })}
                                    placeholder="Enter MetaData Description" />
                            </span>
                        </div>


                        <div className=" u-margin-top-small text-center">
                            <Button
                                type="primary"
                                loading={props.add_service_loading_flag}
                                disabled={props.add_service_loading_flag}
                                onClick={() => submit()}
                                style={{ width: 'auto', margin: 'auto' }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>


        </Card>
    </React.Fragment>)
}
export default ServiceMetaData