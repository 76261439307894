import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services } from "../actions/global"
import { get_public_queries, get_public_queries_loading, 
    get_covid_queries, get_covid_queries_loading, submit_query, submit_query_loading,
    update_city, update_city_loading, update_status, update_status_loading, get_reg_public_queries, get_reg_public_queries_loading, send_sms,
    send_sms_loading, get_solutions, get_solutions_loading, send_solution_sms, send_solution_sms_loading,
    update_covid_queries, update_covid_queries_loading,
    get_ivr_calls, get_ivr_calls_loading, get_facebook_queries, get_facebook_queries_loading,
    upload_facebook_queries, send_ivr_message_loading, send_ivr_message, bulk_send, bulk_send_loading,
    upload_facebook_queries_loading, update_facebook_data, update_facebook_data_loading, get_ivr_messages,
    get_ivr_messages_loading, get_upload_list, get_upload_list_loading, get_bulk_sms_list, get_bulk_sms_list_loading, get_manual_biddings, get_manual_biddings_loading, 
    get_message_templates, get_message_templates_ret, send_template_sms, send_template_sms_loading, send_ivr_template_sms,
    get_ivr_sms_token, get_ivr_sms_token_loading,
    send_manual_template_sms, send_manual_template_sms_ret, send_manual_template_sms_loading,
    get_manual_sms_list, get_manual_sms_list_ret, get_manual_sms_list_loading,
    get_all_queries, get_all_queries_loading,
    get_merged_queries, get_merged_queries_loading,
    get_queries_by_number, get_queries_by_number_loading,
    update_prospect, update_prospect_loading,
    update_query, update_query_loading, sync_knowlarity_leads, sync_knowlarity_leads_loading, download_sales_leads, download_sales_leads_loading } from "../actions/salesfunnel"
import {update_follow_up_loading, update_follow_up } from "../actions/follow_up"
    import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import Querylist from "../Views/Querylist/index.js"
import { get_url_params } from "../utils/common_utilities"
import { get_insurance, get_insurance_loading } from "../actions/professionals";

class Salesfunnel extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      console.log(this.props,"this.props  in Professional_container")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?queries_list") ? <React.Fragment>
                      <Querylist
                        get_upload_list = {this.props.get_upload_list}
                        get_upload_list_loading = {this.props.get_upload_list_loading}
                        get_upload_list_ret = {this.props.salesfunnel_store.get_upload_list_ret}
                        get_upload_list_loading_flag = {this.props.salesfunnel_store.get_upload_list_loading}
                        
                        get_ivr_messages = {this.props.get_ivr_messages}
                        get_ivr_messages_loading = {this.props.get_ivr_messages_loading}
                        get_ivr_messages_ret = {this.props.salesfunnel_store.get_ivr_messages_ret}
                        get_ivr_messages_loading_flag = {this.props.salesfunnel_store.get_ivr_messages_loading}

                        bulk_send_loading = {this.props.bulk_send_loading}
                        bulk_send = {this.props.bulk_send}
                        bulk_send_ret = {this.props.salesfunnel_store.bulk_send_ret}
                        bulk_send_loading_flag = {this.props.salesfunnel_store.bulk_send_loading}
                        
                        get_public_queries = {this.props.get_public_queries}
                        get_public_queries_loading = {this.props.get_public_queries_loading}
                        get_public_queries_ret = {this.props.salesfunnel_store.get_public_queries_ret}
                        get_public_queries_loading_flag = {this.props.salesfunnel_store.get_public_queries_loading}

                        get_reg_public_queries = {this.props.get_reg_public_queries}
                        get_reg_public_queries_loading = {this.props.get_reg_public_queries_loading}
                        get_reg_public_queries_ret = {this.props.salesfunnel_store.get_reg_public_queries_ret}
                        get_reg_public_queries_loading_flag = {this.props.salesfunnel_store.get_reg_public_queries_loading}

                        get_covid_queries = {this.props.get_covid_queries}
                        get_covid_queries_loading = {this.props.get_covid_queries_loading}
                        get_covid_queries_ret = {this.props.salesfunnel_store.get_covid_queries_ret}
                        get_covid_queries_loading_flag = {this.props.salesfunnel_store.get_covid_queries_loading}

                        submit_query = {this.props.submit_query}
                        submit_query_loading = {this.props.submit_query_loading}
                        submit_query_ret = {this.props.salesfunnel_store.submit_query_ret}
                        submit_query_loading_flag = {this.props.salesfunnel_store.submit_query_loading}

                        update_city = {this.props.update_city}
                        update_city_loading = {this.props.update_city_loading}
                        update_city_ret = {this.props.salesfunnel_store.update_city_ret}
                        update_city_loading_flag = {this.props.salesfunnel_store.update_city_loading}

                        update_status = {this.props.update_status}
                        update_status_loading = {this.props.update_status_loading}
                        update_status_ret = {this.props.salesfunnel_store.update_status_ret}
                        update_status_loading_flag = {this.props.salesfunnel_store.update_status_loading}

                        send_sms = {this.props.send_sms}
                        send_sms_ret = {this.props.salesfunnel_store.send_sms_ret}
                        send_sms_loading = {this.props.send_sms_loading}
                        send_sms_loading_flag = {this.props.salesfunnel_store.send_sms_loading}

                        get_solutions = {this.props.get_solutions}
                        get_solutions_ret = {this.props.salesfunnel_store.get_solutions_ret}
                        get_solutions_loading = {this.props.get_solutions_loading}
                        get_solutions_loading_flag = {this.props.salesfunnel_store.get_solutions_loading}

                        send_solution_sms = {this.props.send_solution_sms}
                        send_solution_sms_ret = {this.props.salesfunnel_store.send_solution_sms_ret}
                        send_solution_sms_loading = {this.props.send_solution_sms_loading}
                        send_solution_sms_loading_flag = {this.props.salesfunnel_store.send_sms_loading_flag}

                        get_ivr_calls_loading = {this.props.get_ivr_calls_loading}
                        get_ivr_calls = {this.props.get_ivr_calls}
                        get_ivr_calls_loading_flag = {this.props.salesfunnel_store.get_ivr_calls_loading}
                        get_ivr_calls_ret = {this.props.salesfunnel_store.get_ivr_calls_ret}

                        send_ivr_message = {this.props.send_ivr_message}
                        send_ivr_message_loading = {this.props.send_ivr_message_loading}
                        send_ivr_message_ret = {this.props.salesfunnel_store.send_ivr_message_ret}
                        send_ivr_message_loading_flag = {this.props.salesfunnel_store.send_ivr_message_loading}

                        update_covid_queries_loading  = {this.props.update_covid_queries_loading}
                        update_covid_queries = {this.props.update_covid_queries}
                        update_covid_queries_ret = {this.props.salesfunnel_store.update_covid_queries_ret}
                        update_covid_queries_loading_flag = {this.props.salesfunnel_store.update_covid_queries_loading}

                        get_facebook_queries_loading  = {this.props.get_facebook_queries_loading}
                        get_facebook_queries = {this.props.get_facebook_queries}
                        get_facebook_queries_ret = {this.props.salesfunnel_store.get_facebook_queries_ret}
                        get_facebook_queries_loading_flag = {this.props.salesfunnel_store.get_facebook_queries_loading}

                        update_facebook_data = {this.props.update_facebook_data}
                        update_facebook_data_ret = {this.props.salesfunnel_store.update_facebook_data_ret}
                        update_facebook_data_loading = {this.props.update_facebook_data_loading}
                        update_facebook_data_loading_flag = {this.props.salesfunnel_store.update_facebook_data_loading}

                        upload_facebook_queries_loading  = {this.props.upload_facebook_queries_loading}
                        upload_facebook_queries = {this.props.upload_facebook_queries}
                        upload_facebook_queries_ret = {this.props.salesfunnel_store.upload_facebook_queries_ret}
                        upload_facebook_queries_loading_flag = {this.props.salesfunnel_store.upload_facebook_queries_loading}

                        get_bulk_sms_list  = {this.props.get_bulk_sms_list}
                        get_bulk_sms_list_loading = {this.props.get_bulk_sms_list_loading}
                        get_bulk_sms_list_ret = {this.props.salesfunnel_store.get_bulk_sms_list_ret}
                        get_bulk_sms_list_loading_flag = {this.props.salesfunnel_store.get_bulk_sms_list_loading}


                        update_follow_up_loading = {this.props.update_follow_up_loading}
                        update_follow_up_loading_flag = {this.props.follow_up_store.update_follow_up_loading}
                        update_follow_up = {this.props.update_follow_up}
                        update_follow_up_ret = {this.props.follow_up_store.update_follow_up_ret}

                        get_manual_biddings = {this.props.get_manual_biddings}
                        get_manual_biddings_loading = {this.props.get_manual_biddings_loading}
                        get_manual_biddings_ret = {this.props.salesfunnel_store.get_manual_biddings_ret}
                        get_manual_biddings_loading_flag = {this.props.salesfunnel_store.get_manual_biddings_loading}

                        global_error = {this.props.global_error}

                        global_store = {this.props.global_store}
                        get_services = {this.props.get_services}

                        get_message_templates = {this.props.get_message_templates}
                        get_message_templates_ret = {this.props.salesfunnel_store.get_message_templates_ret}
                        send_template_sms = {this.props.send_template_sms}
                        send_ivr_template_sms = {this.props.send_ivr_template_sms}
                        send_template_sms_ret = {this.props.salesfunnel_store.send_template_sms_ret}
                        send_template_sms_loading_flag = {this.props.salesfunnel_store.send_template_sms_loading}
                        send_template_sms_loading = {this.props.send_template_sms_loading}

                        get_ivr_sms_token = {this.props.get_ivr_sms_token}
                        get_ivr_sms_token_ret = {this.props.salesfunnel_store.get_ivr_sms_token_ret}
                        get_ivr_sms_token_loading = {this.props.get_ivr_sms_token_loading}
                        get_ivr_sms_token_loading_flag = {this.props.salesfunnel_store.get_ivr_sms_token_loading}

                        send_manual_template_sms = {this.props.send_manual_template_sms}
                        send_manual_template_sms_ret = {this.props.salesfunnel_store.send_manual_template_sms_ret}
                        send_manual_template_sms_loading = {this.props.send_manual_template_sms_loading}
                        send_manual_template_sms_loading_flag = {this.props.salesfunnel_store.send_manual_template_sms_loading}

                        get_manual_sms_list  = {this.props.get_manual_sms_list}
                        get_manual_sms_list_loading = {this.props.get_manual_sms_list_loading}
                        get_manual_sms_list_ret = {this.props.salesfunnel_store.get_manual_sms_list_ret}
                        get_manual_sms_list_loading_flag = {this.props.salesfunnel_store.get_manual_sms_list_loading}
                                                
                        get_all_queries = {this.props.get_all_queries}
                        get_all_queries_loading = {this.props.get_all_queries_loading}
                        get_all_queries_ret = {this.props.salesfunnel_store.get_all_queries_ret}
                        get_all_queries_loading_flag = {this.props.salesfunnel_store.get_all_queries_loading}
                                                
                        get_merged_queries = {this.props.get_merged_queries}
                        get_merged_queries_loading = {this.props.get_merged_queries_loading}
                        get_merged_queries_ret = {this.props.salesfunnel_store.get_merged_queries_ret}
                        get_merged_queries_loading_flag = {this.props.salesfunnel_store.get_merged_queries_loading}
                                                
                        get_queries_by_number = {this.props.get_queries_by_number}
                        get_queries_by_number_loading = {this.props.get_queries_by_number_loading}
                        get_queries_by_number_ret = {this.props.salesfunnel_store.get_queries_by_number_ret}
                        get_queries_by_number_loading_flag = {this.props.salesfunnel_store.get_queries_by_number_loading}

                        get_insurance={this.props.get_insurance}
                        get_insurance_ret={this.props.professionals_store.get_insurance_ret}
                        get_insurance_loading={this.props.get_insurance_loading}
                        get_insurance_loading_flag={this.props.professionals_store.get_insurance_loading}

                        specialities_options={this.props.global_store.specialities}
                        specialities_loading={this.props.global_store.get_specialities_loading}
                        services_options={this.props.global_store.services}

                        update_prospect = {this.props.update_prospect}
                        update_prospect_loading = {this.props.update_prospect_loading}
                        update_prospect_ret = {this.props.salesfunnel_store.update_prospect_ret}
                        update_prospect_loading_flag = {this.props.salesfunnel_store.update_prospect_loading}

                        update_query = {this.props.update_query}
                        update_query_loading = {this.props.update_query_loading}
                        update_query_ret = {this.props.salesfunnel_store.update_query_ret}
                        update_query_loading_flag = {this.props.salesfunnel_store.update_query_loading}

                        sync_knowlarity_leads = {this.props.sync_knowlarity_leads}
                        sync_knowlarity_leads_loading = {this.props.sync_knowlarity_leads_loading}
                        sync_knowlarity_leads_ret = {this.props.salesfunnel_store.sync_knowlarity_leads_ret}
                        sync_knowlarity_leads_loading_flag = {this.props.salesfunnel_store.sync_knowlarity_leads_loading}

                        user = {this.props.user_store.user_data}

                        download_sales_leads = {this.props.download_sales_leads}
                        download_sales_leads_loading = {this.props.download_sales_leads_loading}
                        download_sales_leads_ret = {this.props.salesfunnel_store.download_sales_leads_ret}
                        download_sales_leads_loading_flag = {this.props.download_sales_leads_loading}
                        />
                 
                  </React.Fragment>:(!!get_url_params('professional_id')) ? <React.Fragment>
                   {/* <ProfessionalDetails
                    get_professional_byid = {this.props.get_professional_byid}
                    get_professional_byid_loading = {this.props.get_professional_byid_loading}
                    get_professional_byid_ret  = {this.props.professionals_store.get_professional_byid_ret}
                    get_professional_byid_loading_flag= {this.props.professionals_store.get_professional_byid_loading}


                    update_professional_byid = {this.props.update_professional_byid}
                    update_professional_byid_loading = {this.props.update_professional_byid_loading}
                    update_professional_byid_ret  = {this.props.professionals_store.update_professional_byid_ret}
                    update_professional_byid_loading_flag= {this.props.professionals_store.update_professional_byid_loading}

                    get_prof_specialities = {this.props.get_prof_specialities}
                    get_prof_specialities_ret = {this.props.professionals_store.get_prof_specialities_ret}
                    get_prof_specialities_loading = {this.props.get_prof_specialities_loading}
                    get_prof_specialities_loading_flag = {this.props.professionals_store.get_prof_specialities_loading}

                    get_prof_services = {this.props.get_prof_services}
                    get_prof_services_ret = {this.props.professionals_store.get_prof_services_ret}
                    get_prof_services_loading = {this.props.get_prof_services_loading}
                    get_prof_services_loading_flag = {this.props.professionals_store.get_prof_services_loading}

                    global_error = {this.props.global_error}

                   
                   /> */}
                  </React.Fragment>:<React.Fragment></React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  user_store:state.user_store,
  salesfunnel_store:state.salesfunnel_store,
  professionals_store:state.professionals_store,
  global_error_ret:state.global_store.global_error_ret,
  global_store:state.global_store,
  follow_up_store:state.follow_up_store
})

export default connect(mapStateToProps, {
  update_follow_up_loading,
  update_follow_up,
  global_error,
  global_error_clr,
  global_loading_set,
  get_public_queries,
  get_public_queries_loading,
  get_covid_queries,
  get_covid_queries_loading,
  submit_query,
  submit_query_loading,
  update_city,
  update_city_loading,
  update_status,
  update_status_loading,
  get_reg_public_queries, 
  get_reg_public_queries_loading,
  send_sms,
  send_sms_loading,
  get_solutions,
  get_solutions_loading,
  get_services,
  send_solution_sms,
  send_solution_sms_loading,
  get_ivr_calls,
  get_ivr_calls_loading,
  update_covid_queries,
  update_covid_queries_loading,
  get_facebook_queries,
  get_facebook_queries_loading,
  upload_facebook_queries,
  upload_facebook_queries_loading,
  send_ivr_message, 
  send_ivr_message_loading,
  bulk_send,
  bulk_send_loading,
  update_facebook_data,
  update_facebook_data_loading,
  get_ivr_messages,
  get_ivr_messages_loading,
  get_upload_list,
  get_upload_list_loading,
  get_bulk_sms_list,
  get_bulk_sms_list_loading,
  get_manual_biddings,
  get_manual_biddings_loading,
  get_message_templates,
  get_message_templates_ret,
  send_template_sms,
  send_ivr_template_sms,
  send_template_sms_loading,
  get_ivr_sms_token,
  get_ivr_sms_token_loading,
  send_manual_template_sms,
  send_manual_template_sms_ret,
  send_manual_template_sms_loading,
  get_manual_sms_list,
  get_manual_sms_list_ret,
  get_manual_sms_list_loading,
  get_all_queries,
  get_all_queries_loading,
  get_merged_queries,
  get_merged_queries_loading,
  get_queries_by_number,
  get_queries_by_number_loading,
  get_insurance, 
  get_insurance_loading,
  update_prospect,
  update_prospect_loading,
  update_query,
  update_query_loading,
  sync_knowlarity_leads,
  sync_knowlarity_leads_loading,
  download_sales_leads,
  download_sales_leads_loading
 })(Salesfunnel)
