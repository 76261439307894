import React, { useEffect, useState } from "react"
import { Table, Input, Button, Space, Card, Switch, message, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { error_form_check, for_loop, objectEquals } from '../utils/common_utilities'
import AntdInput from "../Components/AntdInput";

const MedicalDoctorsList = (props) => {
  const [state, set_state] = useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: '',
    searchedColumn: ''
  })

  const [get_professionals_params, set_get_professionals_params] = useState({
    page: 1,
    limit: 10,
    total: 10,
    searchParams: {
      name: '',
      hospitalName: '',
      hospitalMobileNumber: ''
    },
  })

  const [data, set_data] = useState([])
  const [showMetaModal, setShowMetaModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [index,setIndex]=useState(0);

  const [error, setError] = useState({
    rating: { state: false, text: "", id: null }
  });

  useEffect(() => {
    props.get_medical_doctors(get_professionals_params)
  }, [])

  useEffect(() => {
    if (!!props.get_medical_doctors_ret) {
      // console.log(props.get_medical_doctors_ret,"get_professional_ret")
      // set_bids(modif_data(props.bids))
      if (props.get_medical_doctors_ret.success) {
        // console.log("MEDICAL_DOCTORS_RET", props.get_medical_doctors_ret)
        set_data(modif_data([...props.get_medical_doctors_ret.data]))
        set_get_professionals_params({ ...get_professionals_params, total: props.get_medical_doctors_ret.count })
        // console.log({data},"DATAA")
      } else {
        props.global_error({
          success: false,
          message: props.get_medical_doctors_ret.message,
          heading: 'Medical Doctors'
        })
      }
      props.get_medical_doctors_loading()
    }


  }, [props.get_medical_doctors_ret])

  useEffect(() => {
    if (!!props.update_medical_doctor_ret) {
      // console.log(props.update_medical_doctor_ret,"get_professional_ret")
      // set_bids(modif_data(props.bids))
      if (props.update_medical_doctor_ret.success) {
        console.log("MEDICAL_DOCTORS_RET", props.update_medical_doctor_ret)
        props.global_error({
          success: true,
          message: props.update_medical_doctor_ret.message,
          heading: 'Medical Doctors'
        })
      } else {
        props.global_error({
          success: false,
          message: props.update_medical_doctor_ret.message,
          heading: 'Medical Doctors'
        })
      }
      props.update_medical_doctor_loading()
    }

  }, [props.update_medical_doctor_ret])

  const handleChange = (pagination) => {
    // console.log(pagination, "pagination filter sorter")
    let state_filters = { ...get_professionals_params.filters }

    if (!!((pagination.current === get_professionals_params.page) && (pagination.pageSize === get_professionals_params.limit))) {
      // console.log(state_filters, updated_filters,"state_filters, updated_filters")
      console.log("Objects are not equal , call filter operation")
      set_get_professionals_params({ ...get_professionals_params, page: 1 })
      props.get_medical_doctors({ ...get_professionals_params, page: 1 })
    }
  }

  const validate = (data) => {
    const error = {
      rating: { state: false, text: "" }
    };
    if (isNaN(data.rating) || data.rating > 5 || data.rating < 0) {
      error.rating.text = "Invalid rating";
      error.rating.state = true;
      error.rating.id = data._id
      message.error("Rating must be a number between 0 and 5");
    }
    return error;
  }

  let { sortedInfo, filteredInfo } = state
  sortedInfo = sortedInfo || {}
  filteredInfo = filteredInfo || {}



  const getColumnSearchProps = (dataIndex, placeholder, searchProp, type) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchProp)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handle_reset(clearFilters, data, dataIndex, searchProp)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => state.searchInput.select(), 100);
      }
    },
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
          title={text}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
    confirm();
    set_get_professionals_params({
      ...get_professionals_params, page: 1, searchParams: {
        ...get_professionals_params.searchParams, [searchProp]: selectedKeys[0]
      }
    })
    props.get_medical_doctors({
      ...get_professionals_params, page: 1, searchParams: {
        ...get_professionals_params.searchParams, [searchProp]: selectedKeys[0]
      }
    })
    set_state({
      ...state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    set_state({ searchText: '' })
  }


  const modif_data = (arr) => {
    let prof_list = []
    for_loop(arr, (item) => {
      let obj = {
        name: item.doctorName,
        hospitalName: item.hospitalName,
        hospitalMobileNumber: item.hospitalMobileNumber,
        rating: item.rating,
        active: item.active,
        showcase: item.showcase,
        _id: item._id,
        metaData:item?.metaData,
      }
      prof_list.push({
        ...obj
      })

    })

    return prof_list;
  }

  const changeData = (data) => {
    
    const newData=[...data];
    newData[index]=({...newData[index],metaData:modalData?.metaData});
    return newData;
  }

  const page_change = (a, b) => {
    // console.log(a,b,get_professionals_params,"a and b get_professionals_params")
    // if(get_professionals_params.page !== a){
    if (true) {
      set_get_professionals_params({ ...get_professionals_params, page: a, limit: b })
      props.get_medical_doctors({ ...get_professionals_params, page: a, limit: b })
    }
  }

  const handle_reset = (a, b, c) => {
    handleReset(a)
    set_state({ filteredInfo: null });
    set_get_professionals_params({
      ...get_professionals_params, page: 1, searchParams: {
        name: '',
        hospitalName: '',
        hospitalMobileNumber: ''
      }
    })
    props.get_medical_doctors({
      ...get_professionals_params, page: 1, searchParams: {
        name: '',
        hospitalName: '',
        hospitalMobileNumber: ''
      }
    })
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

      ...getColumnSearchProps('name', "Search name", 'name'),
    },
    {
      title: 'Hospital',
      dataIndex: 'hospitalName',
      key: 'hospitalName',
      ...getColumnSearchProps('hospitalName', "Search Hospital", 'hospitalName'),

    },
    {
      title: 'Phone Number',
      dataIndex: 'hospitalMobileNumber',
      key: 'hospitalMobileNumber',
      ...getColumnSearchProps('hospitalMobileNumber', "Search Phonenumber", 'hospitalMobileNumber')
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: '10%',
      render: (text, record, index) =>
        <AntdInput
          value={text}
          type="number"
          loading={props.update_medical_doctor_loading_flag}
          disabled={props.update_medical_doctor_loading_flag}
          error_text={error.rating.text}
          toggle_error={() =>
            setError({ ...error, rating: { text: "", state: false } })
          }
          className="text-center"
          error={error.rating.state && error.rating.id === record._id}
          onChange={(e) => {
            let newArr = [...data];
            newArr[index].rating = e.target.value;
            set_data(newArr);
          }}
        />
    },
    {
      title: 'MetaData',
      render: (value, record,index) =>
        <Button
          type="primary"
          onClick={() => { setModalData(record); setShowMetaModal(true); setIndex(index)}}
        >
          Edit
        </Button>
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (value) => value ? <span>Active</span> : <span>Inactive</span>
    },
    {
      title: 'ShowCase',
      dataIndex: 'showcase',
      key: 'showcase',
      render: (value, record, index) =>
        <Switch checked={value} onChange={() => {
          let newArr = [...data];
          newArr[index].showcase = !value
          set_data(newArr)
        }}
        />
    },
    {
      title: 'Submit',
      render: (value, record) =>
        <Button
          type="primary"
          onClick={() => {
            let errs = validate(record)
            setError(errs)
            if (!error_form_check(errs))
              props.update_medical_doctor({ doctorId: record._id, rating: record.rating, showcase: record.showcase, meta_title: record?.metaData?.title, meta_description: record?.metaData?.desc })
          }}
        >
          Update
        </Button>
    },
  ]
  function showTotal(total) {
    return `Total ${total} items`;
  }

  // console.log({data},"HERE");

  return (<React.Fragment>
    <Card title="Professionals">
      <Table
        columns={columns}
        dataSource={data}
        loading={props.get_medical_doctors_loading_flag || props.update_medical_doctor_loading_flag}
        onChange={handleChange}
        rowClassName="cursor-pointer"
        pagination={{
          showSizeChanger: true,
          limit: get_professionals_params.limit,
          onChange: (a, c) => page_change(a, c),
          // onShowSizeChange:(a,b)=>change_limit(a,b),
          defaultCurrent: get_professionals_params.page,
          total: get_professionals_params.total,
          showTotal: showTotal
        }}
      />
    </Card>
    {
      showMetaModal ?<Modal
        title="Update Meta Data"
        visible={showMetaModal}
        onCancel={() => setShowMetaModal(false)}
        onOk={() => {
          
          props.update_medical_doctor({ doctorId: modalData?._id, rating: modalData?.rating, showcase: modalData?.showcase, meta_title: modalData?.metaData?.title, meta_description: modalData?.metaData?.desc })
          set_data(changeData(data));
          setShowMetaModal(false);
        }}
      >
        <div className="flex-parent-new">
          <span className="flex-child-1-new">
            <AntdInput
              label="MetaData Title"
              value={modalData?.metaData?.title}
              name="meta_title"
              onChange={event => setModalData(({ ...modalData, metaData: {title:event.target.value,desc:modalData?.metaData?.desc} }))}
              placeholder="Enter MetaData Title" />
          </span>

          <span className="flex-child-1-new">
            <AntdInput
              label="MetaData Description"
              value={modalData?.metaData?.desc}
              name="meta_description"
              onChange={event => setModalData(({ ...modalData, metaData: {desc:event.target.value,title:modalData?.metaData?.title} }))}
              placeholder="Enter MetaData Description" />
          </span>
        </div>
      </Modal>:null

    }
  </React.Fragment>)
}
export default MedicalDoctorsList