import React from "react"
import { global_error, global_error_clr, global_loading_set, get_services } from "../actions/global"
import {  check_number, check_number_loading, register_user, register_user_loading, get_doctors,
  get_doctors_loading , make_booking, make_booking_loading, get_bookings_loading, get_bookings, generate_link,
  generate_link_loading, set_user, update_bookings, update_bookings_loading, get_insights, get_insights_loading,
  get_sol_professionals_loading, get_sol_professionals, update_sol_professional_loading, update_sol_professional,
  global_bookings, global_bookings_loading, update_global_booking, update_global_booking_loading, get_refunds,
  get_refunds_loading, get_reports_byid, get_reports_byid_loading,
  approve_booking_insurance_update, approve_booking_insurance_update_loading,
  update_booking_process, update_booking_process_loading   } from "../actions/bookings"
import { connect } from 'react-redux';
import Notif from '../Components/Notif'
import AddUser from "../Views/Bookings/AddUser"
import Booking from "../Views/Bookings/Booking"
import PreviousBookings from "../Views/Bookings/PreviousBookings"
import Solutions from "../Views/Bookings/Solutions"
import BookingsView from "../Views/Bookings/BookingsView"
import Refunds from "../Views/Bookings/Refunds"
import PreAuth from "../Views/Bookings/PreAuth";


class Bookings extends React.PureComponent {
        constructor(props){
            super(props)
            this.state = {
                valid:true
            }
        }

    render(){
      console.log(this.props,"this.props  in Professional_container")
        return (
             <React.Fragment>
                <div >
                  {(this.props.location.search==="?add-user") ? <React.Fragment>
                    <AddUser 
                       check_number_loading = {this.props.check_number_loading}
                       check_number = {this.props.check_number}
                       check_number_ret = {this.props.bookings_store.check_number_ret}
                       check_number_loading_flag = {this.props.bookings_store.check_number_loading}
                       global_error = {this.props.global_error}
                       global_store={this.props.global_store}
                       get_services = {this.props.get_services}

                       register_user_loading = {this.props.register_user_loading}
                       register_user_loading_flag  = {this.props.bookings_store.register_user_loading}
                       register_user_ret = {this.props.bookings_store.register_user_ret}
                       register_user = {this.props.register_user} 

                       get_bookings = {this.props.get_bookings}
                       get_bookings_loading = {this.props.get_bookings_loading}
                       get_bookings_loading_flag = {this.props.bookings_store.get_bookings_loading}
                       get_bookings_ret = {this.props.bookings_store.get_bookings_ret}

                       set_user = {this.props.set_user}
                                            />
                  </React.Fragment>:(this.props.location.search==="?add-booking")   ?   <React.Fragment>
                      <Booking 
                           global_error = {this.props.global_error}
                           global_store={this.props.global_store}
                           get_services = {this.props.get_services}
                           user = {this.props.bookings_store.user}

                           get_doctors_loading = {this.props.get_doctors_loading}
                           get_doctors_loading_flag  = {this.props.bookings_store.get_doctors_loading}
                           get_doctors_ret = {this.props.bookings_store.get_doctors_ret}
                           get_doctors = {this.props.get_doctors} 

                           make_booking_loading = {this.props.make_booking_loading}
                           make_booking_loading_flag  = {this.props.bookings_store.make_booking_loading}
                           make_booking_ret = {this.props.bookings_store.make_booking_ret}
                           make_booking = {this.props.make_booking} 

                           generate_link = {this.props.generate_link}
                           generate_link_loading = {this.props.generate_link_loading}
                           generate_link_loading_flag = {this.props.bookings_store.generate_link_loading}
                           generate_link_ret = {this.props.bookings_store.generate_link_ret}
  
                           update_bookings_loading = {this.props.update_bookings_loading}
                           update_bookings_ret = {this.props.bookings_store.update_bookings_ret}
                           update_bookings = {this.props.update_bookings}
                           update_bookings_loading_flag = {this.props.bookings_store.update_bookings_loading}
                          
                           
                      />
                  </React.Fragment>:(this.props.location.search==="?previous-bookings")?<React.Fragment>
                        <PreviousBookings 
                         global_error = {this.props.global_error}
                         global_store={this.props.global_store}
                         get_services = {this.props.get_services}
                         user = {this.props.bookings_store.user}

                         get_bookings_loading_flag = {this.props.bookings_store.get_bookings_loading}
                         get_bookings_loading = {this.props.get_bookings_loading}
                         get_bookings = {this.props.get_bookings}
                         get_bookings_ret  = {this.props.bookings_store.get_bookings_ret}

                         generate_link = {this.props.generate_link}
                         generate_link_loading = {this.props.generate_link_loading}
                         generate_link_loading_flag = {this.props.bookings_store.generate_link_loading}
                         generate_link_ret = {this.props.bookings_store.generate_link_ret}

                         update_bookings_loading = {this.props.update_bookings_loading}
                         update_bookings_ret = {this.props.bookings_store.update_bookings_ret}
                         update_bookings = {this.props.update_bookings}
                         update_bookings_loading_flag = {this.props.bookings_store.update_bookings_loading}

                         get_doctors_loading = {this.props.get_doctors_loading}
                         get_doctors_loading_flag  = {this.props.bookings_store.get_doctors_loading}
                         get_doctors_ret = {this.props.bookings_store.get_doctors_ret}
                         get_doctors = {this.props.get_doctors} 


                        />
                  </React.Fragment>:(this.props.location.search==="?preAuth")?<React.Fragment>
                  <PreAuth 
                    global_error = {this.props.global_error}
                    global_store={this.props.global_store}

                    user = {this.props.bookings_store.user}

                  />
                  </React.Fragment>:(this.props.location.search==="?solutions")?<React.Fragment>
                          <Solutions
                          get_insights = {this.props.get_insights}
                          get_insights_ret = {this.props.bookings_store.get_insights_ret}
                          get_insights_loading = {this.props.get_insights_loading}
                          get_insights_loading_flag = {this.props.bookings_store.get_insights_loading}

                          get_sol_professionals = {this.props.get_sol_professionals}
                          get_sol_professionals_ret = {this.props.bookings_store.get_sol_professionals_ret}
                          get_sol_professionals_loading = {this.props.get_sol_professionals_loading}
                          get_sol_professionals_loading_flag = {this.props.bookings_store.get_sol_professionals_loading}

                          update_sol_professional_ret = {this.props.bookings_store.update_sol_professional_ret}
                          update_sol_professional_loading_flag = {this.props.bookings_store.update_sol_professional_loading}
                          update_sol_professional_loading = {this.props.update_sol_professional_loading}
                          update_sol_professional = {this.props.update_sol_professional}

                          get_reports_byid = {this.props.get_reports_byid}
                          get_reports_byid_ret = {this.props.bookings_store.get_reports_byid_ret}
                          get_reports_byid_loading_flag = {this.props.bookings_store.get_reports_byid_loading}
                          get_reports_byid_loading = {this.props.get_reports_byid_loading}
                          

                        

                          global_error = {this.props.global_error}
                          global_store = {this.props.global_store}
                          />

                  </React.Fragment>:(this.props.location.search==="?gloabl-bookings")?<React.Fragment>
                    <BookingsView
                          global_bookings = {this.props.global_bookings}
                          global_bookings_loading = {this.props.global_bookings_loading}
                          global_bookings_ret = {this.props.bookings_store.global_bookings_ret}
                          global_bookings_loading_flag = {this.props.bookings_store.global_bookings_loading}

                          update_global_booking = {this.props.update_global_booking}
                          update_global_booking_ret = {this.props.bookings_store.update_global_booking_ret}
                          update_global_booking_loading = {this.props.update_global_booking_loading}
                          update_global_booking_loading_flag = {this.props.bookings_store.update_global_booking_loading}

                          update_booking_process = {this.props.update_booking_process}
                          update_booking_process_ret = {this.props.bookings_store.update_booking_process_ret}
                          update_booking_process_loading = {this.props.update_booking_process_loading}
                          update_booking_process_loading_flag = {this.props.bookings_store.update_booking_process_loading}

                          approve_booking_insurance_update = {this.props.approve_booking_insurance_update}
                          approve_booking_insurance_update_ret = {this.props.bookings_store.approve_booking_insurance_update_ret}
                          approve_booking_insurance_update_loading = {this.props.approve_booking_insurance_update_loading}
                          approve_booking_insurance_update_loading_flag = {this.props.bookings_store.approve_booking_insurance_update_loading}

                          generate_link = {this.props.generate_link}
                          generate_link_loading = {this.props.generate_link_loading}
                          generate_link_loading_flag = {this.props.bookings_store.generate_link_loading}
                          generate_link_ret = {this.props.bookings_store.generate_link_ret}
                          
                          global_error = {this.props.global_error}
                          global_store = {this.props.global_store}
                     />

                  </React.Fragment>:(this.props.location.search==="?refunds")?<React.Fragment>
                    <Refunds
                         get_refunds = {this.props.get_refunds}
                         get_refunds_ret = {this.props.bookings_store.get_refunds_ret}
                         get_refunds_loading = {this.props.get_refunds_loading}
                         get_refunds_loading_flag = {this.props.bookings_store.get_refunds_loading}

                         update_global_booking = {this.props.update_global_booking}
                         update_global_booking_ret = {this.props.bookings_store.update_global_booking_ret}
                         update_global_booking_loading = {this.props.update_global_booking_loading}
                         update_global_booking_loading_flag = {this.props.bookings_store.update_global_booking_loading}

                         global_error = {this.props.global_error}
                         global_store = {this.props.global_store}
                    />
                    </React.Fragment>:<React.Fragment>
                    </React.Fragment>}
                  <Notif 
                     global_error = {this.props.global_error}
                     global_error_ret = {this.props.global_error_ret}
                     global_error_clr = {this.props.global_error_clr}
                  />
                </div>
             </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  auth_store: state.auth_store,
  global_store:state.global_store,
  insights_store:state.insights_store,
  bookings_store:state.bookings_store,
  global_error_ret:state.global_store.global_error_ret,
  global_store:state.global_store
})

export default connect(mapStateToProps, {
  global_error,
  global_error_clr,
  global_loading_set,
  get_services,
  check_number,
  check_number_loading,
  register_user,
  register_user_loading,
  get_doctors,
  make_booking,
  make_booking_loading,
  get_doctors_loading,
  get_bookings_loading,
  get_bookings,
  generate_link,
  generate_link_loading,
  set_user,
  update_bookings,
  update_bookings_loading,
  update_booking_process,
  update_booking_process_loading,
  get_insights,
  get_insights_loading,
  get_sol_professionals_loading,
  get_sol_professionals,
  update_sol_professional,
  update_sol_professional_loading,
  global_bookings,
  global_bookings_loading,
  update_global_booking_loading,
  update_global_booking,
  approve_booking_insurance_update_loading,
  approve_booking_insurance_update,
  get_refunds,
  get_refunds_loading,
  get_reports_byid,
  get_reports_byid_loading
 })(Bookings)
