import React, { useState,useEffect } from 'react';
import { Select, Upload, message, Row, Col, Card, List, Typography, Divide } from 'antd';
import PanelTable from './Table';
import {connect} from 'react-redux';
import {get_catalogue,catalogue_view} from '../../actions/catalogue';
import { bindActionCreators } from "redux";
import './Panel.css';
import CatalogueView from './CatalogueView';
import { InboxOutlined } from '@ant-design/icons';
import {  get_url } from "../../utils/common_utilities"
import  CatalogueList from "../../Views/CatalogueManager/CatalogueList"


const { Dragger } = Upload;

const { Option } = Select;

const Panel = (props) => {

    const [panel,setPanel] = useState([]);
    const [upload_progress_data, set_upload_progress_data] = useState({
        errors:[],
        notMatchedServices:[],
        matchedServices:[]
    })
    const [catView, setCatView] = useState(false);
    const [dataType, setDataType] = useState('Catalogue')
    const options = ['Catalogue','Hospital']

    const [get_catalogue_list_params, set_get_catalogue_list_params] = React.useState({
        page:1,
        limit:10,
        total:10,
        searchParams:{
         
        },
        filters:{
         
        }
   })

    useEffect(() => {
        // console.log(props.catalogue_store)
        if(props.catalogue_store.catalogue[0]!==undefined){
            console.log("undefined condition passed")
        
            setPanel(props.catalogue_store.catalogue);
            // console.log(props.catalogue_store)
            // console.log(panel);
        }
        
    },[props.catalogue_store.catalogue.length])

   useEffect(()=>{
    if(!!props.upload_catalogue_ret){
     
         if(props.upload_catalogue_ret.success){
            props.global_error({
                success:true,
                message:props.upload_catalogue_ret.message,
                heading:'Upload Catalogue'
              })
              props.get_catalogue_list(get_catalogue_list_params)           
         }else{
          props.global_error({
            success:false,
            message:props.upload_catalogue_ret.message,
            heading:'Upload Catalogue'
          })
         }
          props.upload_catalogue_loading()
      }
   },[props.upload_catalogue_ret])

    useEffect(() => {
        props.global_store.upload_progress_toggle && props.get_catalogue_list(get_catalogue_list_params)
    }, [props.global_store.upload_progress_toggle])

   useEffect(() => {
    set_upload_progress_data({
        ...props.global_store.upload_progress_data
    })
   },[props.global_store.upload_progress_data])

   const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }

    const upload_props = {
        name: 'file',
        multiple: true,
        disabled:props.upload_file,
        type:'POST',
        headers:headers.headers,
        action: dataType==="Hospital"?`${get_url()}/catalogueManager/uploadHospital`:`${get_url()}/catalogueManager/uploadCatalogue`,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
          if (status === 'done') {
              console.log(info,"info in upload_props")
            message.success(`${info.file.name} file uploaded successfully.`)
            try {
                props.set_global_upload({...info.file.response.data})
            } catch (error) {
                console.log(error)
            }
            
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`)
          }
        },
      };
      



   

    const panelHandler = () => {
        props.catalogue_view(false);
        console.log("panel clicked");
        setCatView(false);
        console.log('panelHandler clicked',catView);
    }

    const submitHandler = () => {
        console.log("button clicked")
        props.upload_catalogue({
            fileName:props.upload_file.filename,
            type:dataType,
        })
    }

    // console.log(upload_progress_data,"=============>>>>>.upload_progress_data<<<<<<<<<<<<<<<<<<<");

    return (
        <React.Fragment>
           
                {/* <div className='panel_opt'>
                    <ul>
                        <li><p onClick={panelHandler}>Catalogue</p></li>
                    </ul>
                </div> */}
                {props.catalogue_store.view ? 
                (<CatalogueView 
                    catalogue_view = {props.catalogue_view}
                    data={props.catalogue_store.catalogue_byid}/>) 
                : 
                (<React.Fragment>
                 <Row>
                     <Col  span={8}>
                         <Card title="Upload File">
                       <div className="text-center u-margin-bottom-small">
                            <Select onChange = {(v)=>setDataType(v)} defaultValue={dataType} style={{ width: 'auto' }}>
                                {options.map(option => {
                                    return <Option key={option} value={option}>{option}</Option>
                                })}
                            </Select>
                        </div>
                        <div className="text-center u-margin-top-small">
                        <Dragger {...upload_props}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">  {!props.upload_file?"Click or drag file to this area to upload":''} </p>
                            <p className="ant-upload-hint">
                                {!props.upload_file?"   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.":
                                <React.Fragment>
                                    {props.global_store.upload_progress_toggle?`Please wait while we are processing the ${props.upload_file.originalname} file`:`Press submit button to upload ${props.upload_file.originalname} file`}    
                                </React.Fragment>
}             
                            </p>
                        </Dragger>
                        {props.upload_file?<React.Fragment>{!props.global_store.upload_progress_toggle && <button type='submit' className='submit' onClick={submitHandler} >Submit</button>} </React.Fragment>:''}
                        </div>
                        </Card>
                      </Col>
                      <Col span={16}> 
                      {/* {props.global_store.upload_progress_toggle &&   <Row>
                                <Col span={12}>
                                    <div className="table">
                                    <List
                                        size="small"
                                        header={<div>Matched Services</div>}
                                        footer={<div>Updating...</div>}
                                        bordered
                                        dataSource={[upload_progress_data.matchedServices[upload_progress_data.matchedServices.length -1>0?upload_progress_data.matchedServices.length -1:0]]}
                                        renderItem={item => item && <List.Item>{item}</List.Item>}
                                     />
                                    </div>
                                </Col>    
                                <Col span={12}>
                                <div className="table">
                                    <List
                                         size="small"
                                            header={<div>Unmatched Services</div>}
                                            footer={<div>Updating...</div>}
                                            bordered
                                            dataSource={[upload_progress_data.notMatchedServices[upload_progress_data.notMatchedServices.length -1>0?upload_progress_data.notMatchedServices.length -1:0]]}
                                            renderItem={item => item && <List.Item>{item}</List.Item>}
                                     />
                                    </div>
                                </Col> 
                        </Row>  } */}
                       
                           <div className="table">

                           </div>
                            <div className='table'>
                                {panel ? <CatalogueList 
                                            get_catalogue_list = {props.get_catalogue_list}
                                            get_catalogue_list_ret  = {props.get_catalogue_list_ret}
                                            get_catalogue_list_loading = {props.get_catalogue_list_loading}
                                            get_catalogue_list_loading_flag = {props.get_catalogue_list_loading_flag}

                                            get_catalogue_by_id = {props.get_catalogue_by_id}
                                            get_catalogue_by_id_ret = {props.get_catalogue_by_id_ret}
                                            get_catalogue_by_id_loading = {props.get_catalogue_by_id_loading}
                                            get_catalogue_by_id_loading_flag = {props.get_catalogue_by_id_loading_flag}

                                            global_store = {props.global_store}
                                            global_error = {props.global_error}
                                /> : <p>no data</p>}
                                {/* <PanelTable data={panel}/> */}
                            </div>
                    </Col>
                    </Row>
                </React.Fragment>
                )
                }
                
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    catalogue_store: state.catalogue_store
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ get_catalogue, catalogue_view }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Panel);