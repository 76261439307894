// import {
//   GET_KEY_PROF,
//   GET_KEY_PROF_RET,
//   GET_KEY_PROF_LOADING,
//   GET_KEY_PROF_SERVICES_LOADING,
//   GET_KEY_PROF_SERVICES_RET,
//   GET_KEY_PROF_SERVICES,
//   SEND_VARIANCE_INFO_LOADING,
//   SEND_VARIANCE_INFO_RET,
//   SEND_VARIANCE_INFO,
//   SET_KEY_MANAGER_PROFESSIONALS,
//   PREVIOUS_VARIANCE_LIST,
//   PREVIOUS_VARIANCE_LIST_RET,
//   PREVIOUS_VARIANCE_LIST_LOADING,
//   SEND_BUSINESS_REPORTS_LOADING,
//   SEND_BUSINESS_REPORTS_RET,
//   SEND_BUSINESS_REPORTS,
//   PREVIOUS_BUSINESS_REPORTS,
//   PREVIOUS_BUSINESS_REPORTS_LOADING,
//   PREVIOUS_BUSINESS_REPORTS_RET,
//   SEND_CATALOGUE_REPORT,
//   SEND_CATALOGUE_REPORT_RET,
//   SEND_CATALOGUE_REPORT_LOADING,
//   EMAIL_PREVIEW_LOADING,
//   EMAIL_PREVIEW_RET,
//   EMAIL_PREVIEW,
//   SEND_SPECIALITY_REPORT_LOADING,
//   SEND_SPECIALITY_REPORT_RET,
//   SEND_SPECIALITY_REPORT,
//   SENT_SPECIALITY_REPORTS,
//   SENT_SPECIALITY_REPORTS_LOADING,
//   SENT_SPECIALITY_REPORTS_RET,
//   SENT_CATALOGUE_REPORTS_LOADING,
//   SENT_CATALOGUE_REPORTS,
//   SENT_CATALOGUE_REPORTS_RET,
//   GET_IVR_CALLS_LOADING,
//   GET_IVR_CALLS_RET,
//   GET_IVR_CALLS,
//   ADD_SERVICE_LOADING,
//   ADD_SERVICE_RET,
//   ADD_SERVICE,
//   ADD_INSURANCE,
//   GET_INSURANCE,
//   REMOVE_INSURANCE_FROM_DB,
//   REMOVE_INSURANCE_FROM_PROF,
//   ADD_INSURANCE_RET,
//   ADD_INSURANCE_LOADING,
//   ADD_INSURANCE_TO_PROFESSIONAL,
//   ADD_INSURANCE_TO_PROFESSIONAL_RET,
//   ADD_INSURANCE_TO_PROFESSIONAL_LOADING,
//   GET_INSURANCE_RET,
//   GET_INSURANCE_LOADING,
//   REMOVE_INSURANCE_FROM_DB_RET,
//   REMOVE_INSURANCE_FROM_DB_LOADING,
//   REMOVE_INSURANCE_FROM_PROF_RET,
//   REMOVE_INSURANCE_FROM_PROF_LOADING,
//   SERVICE_DATA_HANDLER,
//   SERVICE_DATA_HANDLER_RET,
//   SERVICE_DATA_HANDLER_LOADING,
//   GET_SERVICE_FAMILY_BYID,
//   GET_SERVICE_FAMILY_BYID_RET,
//   GET_SERVICE_FAMILY_BYID_LOADING,
//   GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID,
//   GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_RET,
//   GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING,
// } from "../Constants";

import { 
  GET_KEY_PROF, GET_KEY_PROF_RET, GET_KEY_PROF_LOADING, GET_KEY_PROF_SERVICES_LOADING,
  GET_KEY_PROF_SERVICES_RET, GET_KEY_PROF_SERVICES, SEND_VARIANCE_INFO_LOADING, SEND_VARIANCE_INFO_RET, SEND_VARIANCE_INFO,
  SET_KEY_MANAGER_PROFESSIONALS, PREVIOUS_VARIANCE_LIST, PREVIOUS_VARIANCE_LIST_RET,  PREVIOUS_VARIANCE_LIST_LOADING, SEND_BUSINESS_REPORTS_LOADING, 
  SEND_BUSINESS_REPORTS_RET, SEND_BUSINESS_REPORTS,
  PREVIOUS_BUSINESS_REPORTS, PREVIOUS_BUSINESS_REPORTS_LOADING, PREVIOUS_BUSINESS_REPORTS_RET, SEND_CATALOGUE_REPORT,
  SEND_CATALOGUE_REPORT_RET, SEND_CATALOGUE_REPORT_LOADING, EMAIL_PREVIEW_LOADING, EMAIL_PREVIEW_RET, EMAIL_PREVIEW,
  SEND_SPECIALITY_REPORT_LOADING, SEND_SPECIALITY_REPORT_RET, SEND_SPECIALITY_REPORT, SENT_SPECIALITY_REPORTS,
  SENT_SPECIALITY_REPORTS_LOADING, SENT_SPECIALITY_REPORTS_RET, SENT_CATALOGUE_REPORTS_LOADING,
  SENT_CATALOGUE_REPORTS, SENT_CATALOGUE_REPORTS_RET, GET_IVR_CALLS_LOADING, GET_IVR_CALLS_RET, GET_IVR_CALLS,
  ADD_SERVICE_LOADING, ADD_SERVICE_RET, ADD_SERVICE, ADD_INSURANCE,
	GET_INSURANCE,
	REMOVE_INSURANCE_FROM_DB,
	REMOVE_INSURANCE_FROM_PROFESSIONAL,
	ADD_INSURANCE_RET,
	ADD_INSURANCE_LOADING,
	GET_INSURANCE_RET,
	GET_INSURANCE_LOADING,
	REMOVE_INSURANCE_FROM_DB_RET,
	REMOVE_INSURANCE_FROM_DB_LOADING,
	REMOVE_INSURANCE_FROM_PROFESSIONAL_RET,
	REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING,SERVICE_DATA_HANDLER, SERVICE_DATA_HANDLER_RET, SERVICE_DATA_HANDLER_LOADING,
  GET_SERVICE_FAMILY_BYID, GET_SERVICE_FAMILY_BYID_RET, GET_SERVICE_FAMILY_BYID_LOADING, GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID,
  GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_RET, GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING, 
	UPDATE_FAQ, UPDATE_FAQ_RET, UPDATE_FAQ_LOADING,   ADD_INSURANCE_TO_PROFESSIONAL,
  ADD_INSURANCE_TO_PROFESSIONAL_RET,
  ADD_INSURANCE_TO_PROFESSIONAL_LOADING,  
  UPDATE_MARKETING_PAGE, UPDATE_MARKETING_PAGE_LOADING, UPDATE_MARKETING_PAGE_RET, 
  UPDATE_ADVANCED_TREATMENT, UPDATE_ADVANCED_TREATMENT_RET, UPDATE_ADVANCED_TREATMENT_LOADING, 
  UPLOAD_ADVANCED_TREATMENT_IMAGE, UPLOAD_ADVANCED_TREATMENT_IMAGE_RET, UPLOAD_ADVANCED_TREATMENT_IMAGE_LOADING, 
  UPLOAD_ADVANCED_TREATMENT_ICON, UPLOAD_ADVANCED_TREATMENT_ICON_RET, UPLOAD_ADVANCED_TREATMENT_ICON_LOADING} from "../Constants"


const initialState = {

  sent_speciality_reports: false,
  sent_speciality_reports_ret: false,
  sent_speciality_reports_loading: false,


	sent_catalogue_reports: false,
	sent_catalogue_reports_ret: false,
	sent_catalogue_reports_loading: false,

	sent_speciality_reports: false,
	sent_speciality_reports_ret: false,
	sent_speciality_reports_loading: false,

	get_key_prof: false,
	get_key_prof_ret: false,
	get_key_prof_loading: false,

	get_key_prof_services: false,
	get_key_prof_services_ret: false,
	get_key_prof_services_loading: false,

	send_variance_info: false,
	send_variance_info_ret: false,
	send_variance_info_loading: false,

	previous_variance_list: false,
	previous_variance_list_ret: false,
	previous_variance_list_loading: false,

	previous_business_reports: false,
	previous_business_reports_ret: false,
	previous_business_reports_loading: false,

	send_business_reports: false,
	send_business_reports_ret: false,
	send_business_reports_loading: false,

	send_catalogue_report: false,
	send_catalogue_report_ret: false,
	send_catalogue_report_loading: false,

	email_preview: false,
	email_preview_ret: false,
	email_preview_loading: false,

	send_speciality_report: false,
	send_speciality_report_ret: false,
	send_speciality_report_loading: false,

	add_service: false,
	add_service_ret: false,
	add_service_loading: false,

	add_insurance: false,
	add_insurance_ret: false,
	add_insurance_loading: false,

  get_service_family_byid:false,
  get_service_family_byid_ret:false,
  get_service_family_byid_loading:false,

  get_service_family_list_by_specialityid:false,
  get_service_family_list_by_specialityid_ret:false,
  get_service_family_list_by_specialityid_loading:false,

  selected_professionals:[],
  
	get_insurance: false,
	get_insurance_ret: false,
	get_insurance_loading: false,

	remove_insurance_from_db: false,
	remove_insurance_from_db_ret: false,
	remove_insurance_from_db_loading: false,

	remove_insurance_from_professional: false,
	remove_insurance_from_professional_ret: false,
	remove_insurance_from_professional_loading: false,

	update_faq: false,
	update_faq_ret: false,
	update_faq_loading: false,

  update_marketing_page: false,
  update_marketing_page_ret: false,
  update_marketing_page_loading: false,

	update_advanced_treatment: false,
	update_advanced_treatment_ret: false,
	update_advanced_treatment_loading: false,

	upload_advanced_treatment_image: false,
	upload_advanced_treatment_image_ret: false,
	upload_advanced_treatment_image_loading: false,

	upload_advanced_treatment_icon: false,
	upload_advanced_treatment_icon_ret: false,
	upload_advanced_treatment_icon_loading: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_FAQ:
			return {
				...state,
				update_faq: action.data,
				update_faq_loading: true,
			};
		case UPDATE_FAQ_RET:
			return {
				...state,
				update_faq_ret: action.data,
				update_faq_loading: true,
			};
		case UPDATE_FAQ_LOADING:
			return {
				...state,
				update_faq_ret: false,
				update_faq_loading: false,
			};
		case ADD_SERVICE:
			return {
				...state,
				add_service: action.data,
				add_service_loading: true,
			};
		case ADD_SERVICE_RET:
			return {
				...state,
				add_service_ret: action.data,
				add_service_loading: true,
			};
		case ADD_SERVICE_LOADING:
			return {
				...state,
				add_service_ret: false,
				add_service_loading: false,
			};
		case SERVICE_DATA_HANDLER:
        return {
            ...state,
            service_data_handler:action.data,
            service_data_handler_loading:true
        }
    case SERVICE_DATA_HANDLER_RET:
      return {
        ...state,
        service_data_handler_ret: action.data,
        service_data_handler_loading: true,
      };
    case SERVICE_DATA_HANDLER_LOADING:
      return {
        ...state,
        service_data_handler_ret: false,
        service_data_handler_loading: false,
      };

    case GET_SERVICE_FAMILY_BYID:
      return {
        ...state,
        get_service_family_byid: action.data,
        get_service_family_byid_loading: true,
      };
    case GET_SERVICE_FAMILY_BYID_RET:
      return {
        ...state,
        get_service_family_byid_ret: action.data,
        get_service_family_byid_loading: true,
      };
    case GET_SERVICE_FAMILY_BYID_LOADING:
      return {
        ...state,
        get_service_family_byid_ret: false,
        get_service_family_byid_loading: false,
      };

    case GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID:
      return {
        ...state,
        get_service_family_list_by_specialityid: action.data,
        get_service_family_list_by_specialityid_loading: true,
      };
    case GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_RET:
      return {
        ...state,
        get_service_family_list_by_specialityid_ret: action.data,
        get_service_family_list_by_specialityid_loading: true,
      };
    case GET_SERVICE_FAMILY_LIST_BY_SPECIALITYID_LOADING:
      return {
        ...state,
        get_service_family_list_by_specialityid_ret: false,
        get_service_family_list_by_specialityid_loading: false,
      };

    case SENT_CATALOGUE_REPORTS:
      return {
        ...state,
        sent_catalogue_reports: action.data,
        sent_catalogue_reports_loading: true,
      };
    case SENT_CATALOGUE_REPORTS_RET:
      return {
        ...state,
        sent_catalogue_reports_ret: action.data,
        sent_catalogue_reports_loading: true,
      };
    case SENT_CATALOGUE_REPORTS_LOADING:
      return {
        ...state,
        sent_catalogue_reports_ret: false,
        sent_catalogue_reports_loading: false,
      };
    case SEND_SPECIALITY_REPORT:
      return {
        ...state,
        send_speciality_report: action.data,
        send_speciality_report_loading: true,
      };
    case SEND_SPECIALITY_REPORT_RET:
      return {
        ...state,
        send_speciality_report_ret: action.data,
        send_speciality_report_loading: true,
      };
    case SEND_SPECIALITY_REPORT_LOADING:
      return {
        ...state,
        send_speciality_report_loading: false,
        send_speciality_report_ret: false,
      };
    case SENT_CATALOGUE_REPORTS:
      return {
        ...state,
        sent_catalogue_reports: action.data,
        sent_catalogue_reports_loading: true,
      };
    case SENT_CATALOGUE_REPORTS_RET:
      return {
        ...state,
        sent_catalogue_reports_ret: action.data,
        sent_catalogue_reports_loading: true,
      };
    case SENT_CATALOGUE_REPORTS_LOADING:
      return {
        ...state,
        sent_catalogue_reports_ret: false,
        sent_catalogue_reports_loading: false,
      };
    case SEND_SPECIALITY_REPORT:
      return {
        ...state,
        send_speciality_report: action.data,
        send_speciality_report_loading: true,
      };
    case SEND_SPECIALITY_REPORT_RET:
      return {
        ...state,
        send_speciality_report_ret: action.data,
        send_speciality_report_loading: true,
      };
    case SEND_SPECIALITY_REPORT_LOADING:
      return {
        ...state,
        send_speciality_report_loading: false,
        send_speciality_report_ret: false,
      };

    case SENT_SPECIALITY_REPORTS:
      return {
        ...state,
        sent_speciality_reports: action.data,
        sent_speciality_reports_loading: true,
      };
    case SENT_SPECIALITY_REPORTS_RET:
      return {
        ...state,
        sent_speciality_reports_ret: action.data,
        sent_speciality_reports_loading: true,
      };
    case SENT_SPECIALITY_REPORTS_LOADING:
      return {
        ...state,
        sent_speciality_reports_loading: false,
        sent_speciality_reports_ret: false,
      };

    case EMAIL_PREVIEW:
      return {
        ...state,
        email_preview: action.data,
        email_preview_loading: true,
      };
    case EMAIL_PREVIEW_RET:
      return {
        ...state,
        email_preview_ret: action.data,
        email_preview_loading: true,
      };
    case EMAIL_PREVIEW_LOADING:
      return {
        ...state,
        email_preview_loading: false,
        email_preview_ret: false,
      };
    case SEND_CATALOGUE_REPORT:
      return {
        ...state,
        send_catalogue_report: action.data,
        send_catalogue_report_loading: true,
      };
    case SEND_CATALOGUE_REPORT_RET:
      return {
        ...state,
        send_catalogue_report_ret: action.data,
        send_catalogue_report_loading: true,
      };
    case SEND_CATALOGUE_REPORT_LOADING:
      return {
        ...state,
        send_catalogue_report_ret: false,
        send_catalogue_report_loading: false,
      };
    case SEND_BUSINESS_REPORTS:
      return {
        ...state,
        send_business_reports: action.data,
        send_business_reports_loading: true,
      };
    case SEND_BUSINESS_REPORTS_RET:
      return {
        ...state,
        send_business_reports_ret: action.data,
        send_business_reports_loading: true,
      };
    case SEND_BUSINESS_REPORTS_LOADING:
      return {
        ...state,
        send_business_reports_ret: false,
        send_business_reports_loading: false,
      };

    case PREVIOUS_BUSINESS_REPORTS:
      return {
        ...state,
        previous_business_reports: action.data,
        previous_business_reports_loading: true,
      };
    case PREVIOUS_BUSINESS_REPORTS_RET:
      return {
        ...state,
        previous_business_reports_ret: action.data,
        previous_business_reports_loading: true,
      };
    case PREVIOUS_BUSINESS_REPORTS_LOADING:
      return {
        ...state,
        previous_business_reports_ret: false,
        previous_business_reports_loading: false,
      };
    case PREVIOUS_VARIANCE_LIST:
      return {
        ...state,
        previous_variance_list: action.data,
        previous_variance_list_loading: true,
      };
    case PREVIOUS_VARIANCE_LIST_RET:
      return {
        ...state,
        previous_variance_list_ret: action.data,
        previous_variance_list_loading: true,
      };
    case PREVIOUS_VARIANCE_LIST_LOADING:
      return {
        ...state,
        previous_variance_list_ret: false,
        previous_variance_list_loading: false,
      };
    case SET_KEY_MANAGER_PROFESSIONALS:
      return {
        ...state,
        selected_professionals: [...action.data],
      };
    case SEND_VARIANCE_INFO:
      return {
        ...state,
        send_variance_info: action.data,
        send_variance_info_loading: true,
      };
    case SEND_VARIANCE_INFO_RET:
      return {
        ...state,
        send_variance_info_ret: action.data,
        send_variance_info_loading: true,
      };
    case SEND_VARIANCE_INFO_LOADING:
      return {
        ...state,
        send_variance_info_ret: false,
        send_variance_info_loading: false,
      };
    case GET_KEY_PROF: {
      return {
        ...state,
        get_key_prof: { ...action.data },
        get_key_prof_loading: true,
      };
    }
    case GET_KEY_PROF_RET:
      return {
        ...state,
        get_key_prof_ret: action.data,
        get_key_prof_loading: true,
      };

    case GET_KEY_PROF_LOADING:
      return {
        ...state,
        get_key_prof_ret: false,
        get_key_prof_loading: false,
      };

    case GET_KEY_PROF_SERVICES: {
      return {
        ...state,
        get_key_prof_services: { ...action.data },
        get_key_prof_services_loading: true,
      };
    }
    case GET_KEY_PROF_SERVICES_RET:
      return {
        ...state,
        get_key_prof_services_ret: action.data,
        get_key_prof_services_loading: true,
      };

    case GET_KEY_PROF_SERVICES_LOADING:
      return {
        ...state,
        get_key_prof_services_ret: false,
        get_key_prof_services_loading: false,
      };
    case ADD_INSURANCE: {
      return {
        ...state,
        add_insurance: { ...action.data },
        add_insurance_loading: true,
      };
    }

    case ADD_INSURANCE_RET:
      return {
        ...state,
        add_insurance_ret: action.data,
        add_insurance_loading: true,
      };
    case ADD_INSURANCE_LOADING:
      return {
        ...state,
        add_insurance_ret: false,
        add_insurance_loading: false,
      };
	  case ADD_INSURANCE_TO_PROFESSIONAL: {
		return {
		  ...state,
		  add_insurance_to_professional: { ...action.data },
		  add_insurance_to_professional_loading: true,
		};
	  }
  
	  case ADD_INSURANCE_TO_PROFESSIONAL_RET:
		return {
		  ...state,
		  add_insurance_to_professional_ret: action.data,
		  add_insurance_to_professional_loading: true,
		};
	  case ADD_INSURANCE_TO_PROFESSIONAL_LOADING:
		return {
		  ...state,
		  add_insurance_to_professional_ret: false,
		  add_insurance_to_professional_loading: false,
		};
    case GET_INSURANCE: {
      return {
        ...state,
        get_insurance: { ...action.data },
        get_insurance_loading: true,
      };
    }

    case GET_INSURANCE_RET:
      return {
        ...state,
        get_insurance_ret: action.data,
        get_insurance_loading: true,
      };
    case GET_INSURANCE_LOADING:
      return {
        ...state,
        get_insurance_ret: false,
        get_insurance_loading: false,
      };
    case REMOVE_INSURANCE_FROM_DB: {
      return {
        ...state,
        remove_insurance_from_db: { ...action.data },
        remove_insurance_from_db_loading: true,
      };
    }
    case REMOVE_INSURANCE_FROM_DB_RET:
      return {
        ...state,
        remove_insurance_from_db_ret: action.data,
        remove_insurance_from_db_loading: true,
      };
    case REMOVE_INSURANCE_FROM_DB_LOADING:
      return {
        ...state,
        remove_insurance_from_db_ret: false,
        remove_insurance_from_db_loading: false,
      };
    case REMOVE_INSURANCE_FROM_PROFESSIONAL: {
      return {
        ...state,
        remove_insurance_from_professional: { ...action.data },
        remove_insurance_from_professional_loading: true,
      };
    }
    case REMOVE_INSURANCE_FROM_PROFESSIONAL_RET:
      return {
        ...state,
        remove_insurance_from_professional_ret: action.data,
        remove_insurance_from_professional_loading: true,
      };
    case REMOVE_INSURANCE_FROM_PROFESSIONAL_LOADING:
      return {
        ...state,
        remove_insurance_from_professional_ret: false,
        remove_insurance_from_professional_loading: false,
      };

    case UPDATE_MARKETING_PAGE:
        return {
            ...state,
            update_marketing_page:action.data,
            update_marketing_page_loading:true
        }
    case UPDATE_MARKETING_PAGE_RET:
      return {
        ...state,
        update_marketing_page_ret: action.data,
        update_marketing_page_loading: true,
      };
    case UPDATE_MARKETING_PAGE_LOADING:
      return {
        ...state,
        update_marketing_page_ret: false,
        update_marketing_page_loading: false,
      };

    case UPDATE_ADVANCED_TREATMENT:
      return {
        ...state,
        update_advanced_treatment: action.data,
        update_advanced_treatment_loading: true,
      };
    case UPDATE_ADVANCED_TREATMENT_RET:
      return {
        ...state,
        update_advanced_treatment_ret: action.data,
        update_advanced_treatment_loading: true,
      };
    case UPDATE_ADVANCED_TREATMENT_LOADING:
      return {
        ...state,
        update_advanced_treatment_ret: false,
        update_advanced_treatment_loading: false,
      };

    case UPLOAD_ADVANCED_TREATMENT_IMAGE:
      return {
        ...state,
        upload_advanced_treatment_image: action.data,
        upload_advanced_treatment_image_loading: true,
      };
    case UPLOAD_ADVANCED_TREATMENT_IMAGE_RET:
      return {
        ...state,
        upload_advanced_treatment_image_ret: action.data,
        upload_advanced_treatment_image_loading: true,
      };
    case UPLOAD_ADVANCED_TREATMENT_IMAGE_LOADING:
      return {
        ...state,
        upload_advanced_treatment_image_ret: false,
        upload_advanced_treatment_image_loading: false,
      };

    case UPLOAD_ADVANCED_TREATMENT_ICON:
      return {
        ...state,
        upload_advanced_treatment_icon: action.data,
        upload_advanced_treatment_icon_loading: true,
      };
    case UPLOAD_ADVANCED_TREATMENT_ICON_RET:
      return {
        ...state,
        upload_advanced_treatment_icon_ret: action.data,
        upload_advanced_treatment_icon_loading: true,
      };
    case UPLOAD_ADVANCED_TREATMENT_ICON_LOADING:
      return {
        ...state,
        upload_advanced_treatment_icon_ret: false,
        upload_advanced_treatment_icon_loading: false,
      };

    default:
      return { ...state };
  }
}
