import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Input, Layout, message, Modal, notification, Row, Select, Space, Table, Tag, Upload } from 'antd';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';

import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { getDateFormat, getTime, get_url } from '../../utils/common_utilities';
import moment from 'moment';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
dayjs.extend(weekday)
dayjs.extend(localeData)

const { Option }  =  Select
const { RangePicker } = DatePicker;


function TpaDetails(props) {

  const [expandedRowKeys, setExpandRowKeys] = useState([])
  let ref = useRef()
  const [rentDetailsFlag, setRentDetailsFlag] = useState(false)
  const dateFormat = 'DD-MMM-YYYY';
  const timeFormat = 'hh:mm A';
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] =React.useState("")

  const [tableParams, setTableParams] = useState({
    page:1,
    limit:10,
    total:10,
    searchParams:{},
    filters:{
      createdAt:1000
    }
  })

  const getDateString = (date) => {
    let ts = date.split("-")
    ts = ts[0] + "-" + ts[1].charAt(0).toUpperCase() + ts[1].charAt(1).toLowerCase() + ts[1].charAt(2).toLowerCase() + "-" + ts[2]
    return ts
  }

  const handleDownload = (dates) => {
    props.download_tpa_user_details({
      dates
    })
  }

  const  getColumnDateSearchProps = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <RangePicker
          ref={ref}
          style={{ margin: "auto", width: "100%"}}
          // value={selectedDate.length > 0 ? [moment(selectedDate.split(',')[0], dateFormat), moment(selectedDate.split(',')[1], dateFormat)] : null}
          onChange={(e) => {
            if(e) {
              setSelectedDate(e[0].format("MM/DD/YYYY") + "," + e[1].format("MM/DD/YYYY"))
            }
          }}
          format={dateFormat}
        />
        <Row
          gutter={12}
          justify="center"
          style={{ margin: "auto", width: "100%", marginTop: 8, display: 'flex', justifyContent: 'center' }}
        >
          <Col span={24}>
            <Button
              type="primary"
              disabled={selectedDate.length === 0}
              onClick={() => handleDownload(selectedDate)}
              icon={<DownloadOutlined />}
              size="small"
              style={{ width: "100%", display: "block", marginTop: 8 }}
            >
              Download
            </Button>
          </Col>
        </Row>
      </div>
    ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: "3%",
      render: text => <span>{text}</span>,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      width: "3%",
      render: text => <span>{text}</span>,
    },
    {
      title: 'Hospital Name',
      dataIndex: 'hospitalName',
      key: 'hospitalName',
      width: "3%",
      render: text => <span>{text}</span>,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: "3%",
      render: text => <span>{text}</span>,
    },
    {
      title: 'Last Update',
      dataIndex: 'latestRequest',
      key: 'latestRequest',
      width: "3%",
      render: text => <span>{text}</span>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...getColumnDateSearchProps('date', "Search dateda", 'date', 'date'),
      width: "3%",
      render: text => <span>{text}</span>,
    },
  ];

  const [data, setData] = useState([])

  useEffect(()=>{
    props.get_tpa_user_details(tableParams)
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  function showTotal(total) {
    return `Total ${total} items`;
  }

  useEffect(()=>{
    if(props.get_tpa_user_details_ret) {
      if(props.get_tpa_user_details_ret.success) {
        let tableData = []
        props.get_tpa_user_details_ret.data.data.data.map((item) => {
          let timeString = getTime(item.createdAt)
          const [hourString, minute] = timeString.split(":");
          const hour = +hourString % 24;
          timeString = ((hour % 12 || 12) < 10 ? `0${(hour % 12 || 12)}` : (hour % 12 || 12)) + ":" + minute + (hour < 12 ? " AM" : " PM");

          let obj = {
            _id: item._id,
            name: item.name,
            mobileNumber: item.mobileNumber,
            time: timeString,
            hospitalName: item.hospitalName,
            latestRequest:  getDateFormat(item.latestRequest),
            createdAt: getDateFormat(item.createdAt)

          }
          tableData.push(obj)
        })

        setTableParams({...tableParams, total: props.get_tpa_user_details_ret.data.data.count[0].total})
        setData(tableData)
        scrollToTop()
      } else {
        openNotificationWithIcon()
      }
      props.get_tpa_user_details_loading()
    }
  },[props.get_tpa_user_details_ret])

  useEffect(()=>{
    if(!!props.download_tpa_user_details_ret){
          if(props.download_tpa_user_details_ret.success){
            const link = document.createElement('a');
            link.href = props.download_tpa_user_details_ret.data.url;
            link.setAttribute('download', props.download_tpa_user_details_ret.data.filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            props.global_error({
              success:true,
              message:props.download_tpa_user_details_ret.message,
              heading:'Anywhere Cashless'
            })
  
          }else{
            props.global_error({
              success:false,
              message:props.download_tpa_user_details_ret.message,
              heading:'Anywhere Cashless'
            })
          }
          props.download_tpa_user_details_loading()
    }
   },[props.download_tpa_user_details_ret])


  const openNotificationWithIcon = () => {
    notification["error"]({
      message: 'Could not fetch details',
      description:
        'Please try again later',
    });
  };

  const page_change = (a, b) => {
      setTableParams({...tableParams, page:a, limit:b})
      props.get_tpa_user_details({...tableParams, page:a, limit:b})
  }
  
    return (
      <>
      {/* <InsurancePDF /> */}
        <Card title="Anywhere Cashless - ERGO">
          <Layout className="site-layout">
            <div className="patient-table-container">
              <Table columns={columns} dataSource={data}
                loading={props.get_tpa_user_details_loading_flag || props.download_tpa_user_details_loading_flag || props.add_booking_session_loading_flag || props.update_booking_loading_flag || loading}
                expandable={{ columnWidth: "1%"}}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange ={(a,b,c)=>console.log(a,b,c,'onExpandedRowsChange')}
                rowKey={record => record._id}
                pagination = {{
                  // showSizeChanger:true,
                  limit:tableParams.limit,
                  onChange:(a,c)=>page_change(a,c),
                  defaultCurrent:tableParams.page,
                  total:tableParams.total,
                  showTotal:showTotal
                }} 
              />
            </div>
          </Layout>
        </Card>
      </>
    )
}

export default TpaDetails