import React, { useState } from 'react';
import BlogsCard from './BlogsCard';
import BlogsTable from './BlogsTable';

function Blogs() {

  const [reloadTable, setReloadTable] = useState(false)

  return (
    <div className='blogs-wrapper'>
        <BlogsCard heading={"Add Post"} header={true} editBlog={false} reloadTable={()=>setReloadTable(!reloadTable)} />
        <BlogsTable reloadTable={reloadTable} />
    </div>
  );
}

export default Blogs;
