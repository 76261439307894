import { GET_BAR_CHART_DATA, GET_BAR_CHART_DATA_RET, GET_BAR_CHART_DATA_LOADING, 
    GET_PROF_INFO_LOADING, GET_PROF_INFO_RET, GET_PROF_INFO, UPLOAD_CATALOGUE_LOADING,
    UPLOAD_CATALOGUE_RET, UPLOAD_CATALOGUE , GET_CATALOGUE_LIST, GET_CATALOGUE_LIST_RET,
    GET_CATALOGUE_LIST_LOADING, GET_CATALOGUE_BY_ID_LOADING, GET_CATALOGUE_BY_ID_RET,
    GET_CATALOGUE_BY_ID, GET_RECORDS_LOADING, GET_RECORDS_RET, GET_RECORDS, UPDATE_UNMATCHED_RET,
    UPDATE_UNMATCHED, UPDATE_UNMATCHED_LOADING, UPDATE_PRICE_CATALOGUE, UPDATE_PRICE_CATALOGUE_RET,
     UPDATE_PRICE_CATALOGUE_LOADING, GET_PRICE_COMPARISION_LOADING, GET_PRICE_COMPARISION_RET, GET_PRICE_COMPARISION } from "../Constants"
import { act } from "react-dom/test-utils"

const initialState = {

    get_catalogue_by_id:false,
    get_catalogue_by_id_ret:false,
    get_catalogue_by_id_loading:false,
    
    get_records:false,
    get_records_ret:false,
    get_records_loading:false,

    update_unmatched:false,
    update_unmatched_ret:false,
    update_unmatched_loading:false,

    update_price_catalogue:false,
    update_price_catalogue_ret:false,
    update_price_catalogue_loading:false,

    upload_catalogue:false,
    upload_catalogue_ret:false,
    upload_catalogue_loading:false,

    get_price_comparision:false,
    get_price_comparision_ret:false,
    get_price_comparision_loading:false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BAR_CHART_DATA: {
            return {
                ...state,
                get_bar_chart_data:{...action.data},
                get_bar_chart_data_loading:true
            }
        }

        case GET_BAR_CHART_DATA_RET: {
            return {
                ...state,
                get_bar_chart_data_ret:{...action.data},
                get_bar_chart_data_loading:true
            }
        }

        case GET_BAR_CHART_DATA_LOADING: {
            return {
                ...state,
                get_bar_chart_data_ret:false,
                get_bar_chart_data_loading:false
            }
        }


        case GET_PRICE_COMPARISION: {
            return {
                ...state,
                get_price_comparision:{...action.data},
                get_price_comparision_loading:true
            }
        }

        case GET_PRICE_COMPARISION_RET: {
            return {
                ...state,
                get_price_comparision_ret:{...action.data},
                get_price_comparision_loading:true
            }
        }

        case GET_PRICE_COMPARISION_LOADING: {
            return {
                ...state,
                get_price_comparision_ret:false,
                get_price_comparision_loading:false
            }
        }



        case UPDATE_PRICE_CATALOGUE: {
            return {
                ...state,
                update_price_catalogue:{...action.data},
                update_price_catalogue_loading:true
            }
        }

        case UPDATE_PRICE_CATALOGUE_RET: {
            return {
                ...state,
                update_price_catalogue_ret:{...action.data},
                update_price_catalogue_loading:true
            }
        }

        case UPDATE_PRICE_CATALOGUE_LOADING: {
            return {
                ...state,
                update_price_catalogue_ret:false,
                update_price_catalogue_loading:false
            }
        }

        case UPDATE_UNMATCHED: {
            return {
                ...state,
                update_unmatched:{...action.data},
                update_unmatched_loading:true
            }
        }

        case UPDATE_UNMATCHED_RET: {
            return {
                ...state,
                update_unmatched_ret:{...action.data},
                update_unmatched_loading:true
            }
        }

        case UPDATE_UNMATCHED_LOADING: {
            return {
                ...state,
                update_unmatched_ret:false,
                update_unmatched_loading:false
            }
        }

        case GET_RECORDS: {
            return {
                ...state,
                get_records:{...action.data},
                get_records_loading:true
            }
        }

        case GET_RECORDS_RET: {
            return {
                ...state,
                get_records_ret:{...action.data},
                get_records_loading:true
            }
        }

        case GET_RECORDS_LOADING: {
            return {
                ...state,
                get_records_ret:false,
                get_records_loading:false
            }
        }




        case GET_CATALOGUE_BY_ID: {
            return {
                ...state,
                get_catalogue_by_id:{...action.data},
                get_catalogue_by_id_loading:true
            }
        }

        case GET_CATALOGUE_BY_ID_RET: {
            return {
                ...state,
                get_catalogue_by_id_ret:{...action.data},
                get_catalogue_by_id_loading:true
            }
        }

        case GET_CATALOGUE_BY_ID_LOADING: {
            return {
                ...state,
                get_catalogue_by_id_ret:false,
                get_catalogue_by_id_loading:false
            }
        }



        case GET_CATALOGUE_LIST: {
            return {
                ...state,
                get_catalogue_list:{...action.data},
                get_catalogue_list_loading:true
            }
        }

        case GET_CATALOGUE_LIST_RET: {
            return {
                ...state,
                get_catalogue_list_ret:{...action.data},
                get_catalogue_list_loading:true
            }
        }

        case GET_CATALOGUE_LIST_LOADING: {
            return {
                ...state,
                get_catalogue_list_ret:false,
                get_catalogue_list_loading:false
            }
        }




        case GET_PROF_INFO: {
            return {
                ...state,
                get_prof_info:{...action.data},
                get_prof_info_loading:true
            }
        }

        case GET_PROF_INFO_RET: {
            return {
                ...state,
                get_prof_info_ret:{...action.data},
                get_prof_info_loading:true
            }
        }

        case GET_PROF_INFO_LOADING: {
            return {
                ...state,
                get_prof_info_ret:false,
                get_prof_info_loading:false
            }
        }


        case UPLOAD_CATALOGUE: {
            return {
                ...state,
                upload_catalogue:{...action.data},
                upload_catalogue_loading:true
            }
        }

        case UPLOAD_CATALOGUE_RET: {
            return {
                ...state,
                upload_catalogue_ret:{...action.data},
                upload_catalogue_loading:true
            }
        }

        case UPLOAD_CATALOGUE_LOADING: {
            return {
                ...state,
                upload_catalogue_ret:false,
                upload_catalogue_loading:false
            }
        }


        default:
            return {...state}
    }
}