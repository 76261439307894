import { GET_BIDS, GET_BIDS_RET, GET_BIDS_LOADING } from "../Constants"
import { act } from "react-dom/test-utils"

const initialState = {
    
        get_bids:false,
        get_bids_ret:false,
        get_bids_loading:false
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_BIDS:{
            return {
                ...state,
                get_bids:action.data,
                get_bids_loading:true
            }
        }
        case GET_BIDS_RET:
             return {
               ...state,
               get_bids_ret:action.data,
               get_bids_loading:true
             }

        case GET_BIDS_LOADING:
        
            return {
            ...state,
            get_bids_ret:false,
            get_bids_loading:false
            }

        default:
        return {...state}
    
        }
}