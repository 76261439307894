import React from "react"
import { Card, PageHeader, Form, Input, Button } from "antd"
import { Redirect } from "react-router-dom"
import { error_form_check } from "../../utils/common_utilities"
import validator from "validator"
import AntdInput from "../../Components/AntdInput"
import SearchLocation from "../../Components/SearchLocation"
import IvrCallsList from "./IvrCallsList"


const Ivrcalls = (props) => {
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = React.useState('optional');
    const [move_back, set_move_back] = React.useState(false)
    const [data, set_data] = React.useState({
        speciality:[],
        phonenumber:'',
        message:'',
        name:''
    })
    const [bids_list, set_bids_list] = React.useState(false)
    const [error, set_error] = React.useState({
        speciality:{ state:false, text:"" },
        phonenumber:{ state:false, text:"" },
        message:{ state:false, text:"" },
        name:{ state:false, text:"" }
 })

 React.useEffect(()=>{
    if(props.submit_query_ret){
        if(props.submit_query_ret.success){
          // console.log(props.get_bids_ret,"props.get_bids_ret in TrackBids")
            props.global_error({
             success:true,
             message:props.submit_query_ret.message,
             heading:'IVR call query',
           })
        //    set_bids_list([...props.get_bids_ret.data])
          //  props.get_members()
        }else {
      
            props.global_error({
             success:false,
             message:props.submit_query_ret.message,
             heading:'IVR call query'
           })
        }
        props.submit_query_loading()
    }
}, [props.submit_query_ret])

    const validate  = (data) =>{
        const  error = {
              phonenumber:{ state:false, text:"" },
              speciality:{ state:false, text:"" },
              message:{ state:false, text:"" },
              name:{ state:false, text:"" }
          }
          if(data.name===''){
            {
              error.name.text = "Please enter name"
              error.name.state = true
            }
          }
          if(!!(data.speciality.length === 0)){
            {
              error.speciality.text = "Please choose a speciality"
              error.speciality.state = true
            }
          }
      if(data.phonenumber===''){
        {
          error.phonenumber.text = "Please enter phonenumber"
          error.phonenumber.state = true
        }
      }
      if(data.message===''){
        {
          error.message.text = "Please enter message"
          error.message.state = true
        }
      }
        return error
      }
    const add_member = () => {
        const ret_error = validate({...data});
        set_error({...error, ...ret_error});
        console.log(ret_error,"ret_error in add_member")
        if(!error_form_check(ret_error)){
            // console.log("Inside Eror_form_check")
        //    props.execute_captcha()
        let arr = data.speciality.map((item)=>{
          return {
            specialityId:item
          }
        })
        let obj = {
            "name":data.name,
            "mobileNumber":data.phonenumber,
             "specialities":arr,
             "message": data.message
        }
            props.submit_query({...obj})
        }
    }

    const speciality_change = (val) => {
         set_data({...data,"speciality":val})
    }
    // console.log(data,"data in Add Member")
    console.log(data,"data in IVR calls")
    return (
        <React.Fragment>
            <Card>
   <Form
      form={form}
      layout="vertical"
    >
 
<div className="flex-parent-new">
  <span className="flex-child-1-new">
    <AntdInput 
      label="Speciality"  
      mode="multiple"
      value={data.speciality} 
      disabled = {props.submit_query_loading_flag}
      options = {props.global_store.specialities}
      loading={props.global_store.get_specialities_loading || props.submit_query_loading_flag}
      error_text={error.speciality.text} 
      toggle_error = {()=>set_error({...error,speciality:{text:'',state:false}})} 
      error={error.speciality.state}
      name="speciality" 
      type="select"
      onChange = {event=>speciality_change(event)} 
      placeholder="Choose speciality" />
</span>
<span className="flex-child-1-new">
<AntdInput label="Phonenumber"  
            value={data.phonenumber} 
            error_text={error.phonenumber.text}
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error,phonenumber:{text:'',state:false}})} 
            error={error.phonenumber.state} 
            name="phonenumber" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter phonenumber" />
</span>

<span className="flex-child-1-new">
<AntdInput label="Name"  
            value={data.name} 
            error_text={error.name.text}
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error, name:{text:'',state:false}})} 
            error={error.name.state} 
            name="name" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter Name" />
</span>
</div>

<div className="flex-parent-new">
<span className="flex-child-1-new">
            <AntdInput label="Message"  
            value={data.message} 
            error_text={error.message.text} 
            loading = {props.submit_query_loading_flag}
            disabled = {props.submit_query_loading_flag} 
            toggle_error = {()=>set_error({...error,message:{text:'',state:false}})} 
            error={error.message.state} 
            name="message" 
            onChange = {event=>set_data({...data,[event.target.name]:event.target.value})} 
            placeholder="Enter message"
            type="textarea"
            rows= {5}
            />
  </span>
  </div>

  <div className="flex-parent-new">
<span className="flex-child-1-new">
      <Form.Item>
        <Button onClick={add_member} loading={props.submit_query_loading_flag} type="primary">Submit</Button>
      </Form.Item>
      </span>
 </div>
    </Form>

    <IvrCallsList
      get_ivr_calls_loading = {props.get_ivr_calls_loading}
      get_ivr_calls = {props.get_ivr_calls}
      get_ivr_calls_loading_flag = {props.get_ivr_calls_loading_flag}
      get_ivr_calls_ret = {props.get_ivr_calls_ret}


      global_error = {props.global_error}

      send_ivr_message = {props.send_ivr_message}
      send_ivr_message_ret = {props.send_ivr_message_ret}
      send_ivr_message_loading = {props.send_ivr_message_loading}
      send_ivr_message_loading_flag = {props.send_ivr_message_loading_flag}

      get_ivr_messages = {props.get_ivr_messages}
      get_ivr_messages_ret = {props.get_ivr_messages_ret}
      get_ivr_messages_loading = {props.get_ivr_messages_loading}
      get_ivr_messages_loading_flag = {props.get_ivr_messages_loading_flag}

      get_message_templates = {props.get_message_templates}
      get_message_templates_ret = {props.get_message_templates_ret}
      send_ivr_template_sms = {props.send_ivr_template_sms}
      send_template_sms_ret = {props.send_template_sms_ret}
      send_template_sms_loading_flag = {props.send_template_sms_loading_flag}
      send_template_sms_loading = {props.send_template_sms_loading}

      get_ivr_sms_token = {props.get_ivr_sms_token}
      get_ivr_sms_token_ret = {props.get_ivr_sms_token_ret}
      get_ivr_sms_token_loading = {props.get_ivr_sms_token_loading}
      get_ivr_sms_token_loading_flag = {props.get_ivr_sms_token_loading_flag}
/>
  </Card>
        </React.Fragment>
    )
}

export default Ivrcalls