import React from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Card,
  Form,
  Select,
  Modal,
  DatePicker,
  Alert,
} from "antd";
import { error_form_check } from "../../utils/common_utilities";
import Highlighter from "react-highlight-words";
import { SearchOutlined , EditOutlined} from "@ant-design/icons";
import { for_loop, objectEquals, get_time, getDateFormat, get_url } from "../../utils/common_utilities";
import { Redirect } from "react-router-dom";
import AntdInput from "../../Components/AntdInput";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Photogallery from "../../Components/Photogallery";
import axios from "axios";
import FileDownload from "js-file-download";

const { Option } = Select;

// import Map from "./Map.js"

const BookingsView = (props) => {
  const { form } = Form.useForm();
  const [state, set_state] = React.useState({
    filteredInfo: null,
    sortedInfo: null,
    searchText: "",
    searchedColumn: "",
  });
  const [global_bookings_params, set_global_bookings_params] = React.useState({
    page: 1,
    limit: 10,
    total: 10,
    professional_id: props.professional_id,
    searchParams: {
      userName: "",
      professionalName: "",
    },
    filters: {},
  });

  const [payment_link, set_payment_link] = React.useState(false);
  const [insurance, set_insurance] = React.useState(false);
  const [invoice, setInvoice] = React.useState(false);
  const [downloadBooking, set_download_booking] = React.useState(false);
  const [selected_row, set_selected_row] = React.useState([]);
  const [data, set_data] = React.useState([]);
  const [copy, set_copy] = React.useState(false);
  const [professional_selected, set_professional_selected] = React.useState(
    false
  );
  const [expandedRowKeys, set_expandedRowKeys] = React.useState([]);
  const [payment_link_data, set_payment_link_data] = React.useState({
    amount: "",
  });
  const [error, set_error] = React.useState({
    amount: { state: false, text: "" },
  });

  const [fileCheck, setFileCheck] = React.useState(true);
  const [edit_price_modal, set_edit_price_modal] = React.useState(false);
  const [professionals_amounts, set_professionals_amounts] = React.useState([]);
  const [
    professionals_amounts_error,
    set_professionals_amounts_error,
  ] = React.useState([]);

  const [bookingData, set_booking_data] = React.useState({
    start_date: "",
    end_date: "",
    status: "All",
  });

  React.useEffect(() => {
    props.global_bookings({ ...global_bookings_params });
  }, []);

  React.useEffect(() => {
    if (!!props.global_bookings_ret) {
      if (props.global_bookings_ret.success) {
        console.log(props.global_bookings_ret, "get_professional_ret");
        set_data(modif_data([...props.global_bookings_ret.data]));
        set_global_bookings_params({
          ...global_bookings_params,
          total: props.global_bookings_ret.total,
        });
        let arr = [];
        props.global_bookings_ret.data.map((item) => {
          arr[item._id] = item.service.newPrice;
        });
        let arr_error = {};
        props.global_bookings_ret.data.map((item) => {
          arr_error[item._id] = { state: false, text: "" };
        });

        set_professionals_amounts_error(arr_error);
        set_professionals_amounts(arr);
      } else {
        props.global_error({
          success: false,
          message: props.global_bookings_ret.message,
          heading: "Professionals",
        });
      }
      props.global_bookings_loading();
    }

    if (!!props.update_global_booking_ret) {
      if (props.update_global_booking_ret.success) {
        // set_payment_link(props.generate_link_ret.data)

        props.global_bookings({ ...global_bookings_params });
        props.global_error({
          success: true,
          message: props.update_global_booking_ret.message,
          heading: "Booking",
        });
      } else {
        props.global_error({
          success: false,
          message: props.update_global_booking_ret.message,
          heading: "Booking",
        });
      }
      props.update_global_booking_loading();
    }

    if (!!props.update_booking_process_ret) {
      if (props.update_booking_process_ret.success) {
        // set_payment_link(props.generate_link_ret.data)

        props.global_bookings({ ...global_bookings_params });
        props.global_error({
          success: true,
          message: props.update_booking_process_ret.message,
          heading: "Booking",
        });
      } else {
        props.global_error({
          success: false,
          message: props.update_booking_process_ret.message,
          heading: "Booking",
        });
      }
      props.update_booking_process_loading();
    }

    if (!!props.generate_link_ret) {
      if (props.generate_link_ret.success) {
        set_payment_link(props.generate_link_ret.data);
        props.global_error({
          success: true,
          message: props.generate_link_ret.message,
          heading: "Payment Link",
        });
      } else {
        props.global_error({
          success: false,
          message: props.generate_link_ret.message,
          heading: "Payment Link",
        });
      }
      props.generate_link_loading();
    }
  }, [
    props.global_bookings_ret,
    props.update_global_booking_ret,
    props.generate_link_ret,
    props.update_booking_process_ret
  ]);


  React.useEffect(() => {
    if (!!props.approve_booking_insurance_update_ret) {
      if (props.approve_booking_insurance_update_ret.success) {
        props.global_error({
          success: true,
          message: props.approve_booking_insurance_update_ret.message,
          heading: "Booking",
        });
        handleCancel()
        props.global_bookings({ ...global_bookings_params });

      } else {
        props.global_error({
          success: false,
          message: props.approve_booking_insurance_update_ret.message,
          heading: "Booking",
        });
      }
      props.approve_booking_insurance_update_loading();
    }
}, [props.approve_booking_insurance_update_ret])

  const handleChange = (pagination, filters, sorter) => {
    // console.log(pagination, "pagination filter sorter")
    let state_filters = { ...global_bookings_params.filters };
    let updated_filters = {};

    updated_filters.level = filters.level;
    updated_filters.catalogue = filters.catalogue;
    updated_filters.userType = filters.userType;
    if (
      !!(
        pagination.current === global_bookings_params.page &&
        pagination.pageSize === global_bookings_params.limit
      )
    ) {
      if (objectEquals(state_filters, updated_filters)) {
        console.log("Objects are equal");
      } else {
        // console.log(state_filters, updated_filters,"state_filters, updated_filters")
        console.log("Objects are not equal , call filter operation");
        set_global_bookings_params({
          ...global_bookings_params,
          page: 1,
          filters: {
            createdAt: filters.createdAt ? filters.createdAt : "",
            //    level:filters.level?filters.level:'',
            //    catalogue:filters.catalogue?filters.catalogue:''
          },
        });
        props.global_bookings({
          ...global_bookings_params,
          page: 1,
          filters: {
            createdAt: filters.createdAt ? filters.createdAt : "",
            //    level:filters.level?filters.level:'',
            //    catalogue:filters.catalogue?filters.catalogue:''
          },
        });
      }
    }
  };

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};

  const getColumnSearchProps = (dataIndex, placeholder, searchProp, type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, searchProp)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, searchProp)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handle_reset(clearFilters, data, dataIndex, searchProp)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
          title={text}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
    confirm();
    set_global_bookings_params({
      ...global_bookings_params,
      page: 1,
      searchParams: {
        ...global_bookings_params.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    props.global_bookings({
      ...global_bookings_params,
      page: 1,
      searchParams: {
        ...global_bookings_params.searchParams,
        [searchProp]: selectedKeys[0],
      },
    });
    set_state({
      ...state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    set_state({ searchText: "" });
  };

  const modif_data = (arr) => {
    let prof_list = [];
    try {
      for_loop(arr, (item) => {
        let rgCheck = item.professionalName.includes("RG Stone")
        let obj = {
          newPrice: item.service.newPrice,
          amountDue: item.amountDue,
          amountPaid: item.amountPaid,
          amountPaidCredits: item.amountPaidCredits,
          appointmentTime: get_time(item.appointmentTime),
          bookingStatus: item.bookingStatus,
          bookingProcess: item.bookingProcess,
          createdAt: item.createdAt,
          creditsUsed: item.creditsUsed,
          professionalMobileNumber: item.professionalMobileNumber,
          professionalName: item.professionalName,
          refundStatus: item.refundStatus,
          serviceName: item.serviceName,
          userMobileNumber: item.userMobileNumber,
          userName: item.userName,
          bookingStatus: item.bookingStatus,
          userEmail: item.userEmail,
          insuranceUpdate: item.insuranceUpdate,
          insuranceEditRequest: item.insuranceEditRequest,
          // discount:item.service.discount.toFixed(2),
          createdAt: get_time(item.createdAt),
          razorPay: item.razorPay.razorpayAmount
            ? item.razorPay.razorpayAmount
            : item.razorPay.razorpayAmount === 0
            ? 0
            : "N/A",
          paytm: item.paytm.paytmAmount
            ? item.paytm.paytmAmount
            : item.paytm.paytmAmount === 0
            ? 0
            : "N/A",
          paymentLink: item.paymentLink.payLinkAmount
            ? item.paymentLink.payLinkAmount
            : item.paymentLink.payLinkAmount === 0
            ? 0
            : "N/A",
          offline: item.offline.cashPaid
            ? item.offline.cashPaid
            : item.offline.cashPaid === 0
            ? 0
            : "N/A",
          userCredits: item.userCredits.creditUsed
            ? item.userCredits.creditUsed
            : item.userCredits.creditUsed === 0
            ? 0
            : "N/A",
          paymentThrough: item.paymentThrough
            ? item.paymentThrough
            : item.paymentThrough === 0
            ? 0
            : "N/A",
          haveInsurance: item.haveInsurance,
          insuranceDetails: item.insuranceDetails,
          voucherLink: <a href={`https://www.plunes.com/booking-voucher?user-name=${item.userName}&service=${item.serviceName}&price=${item.service.newPrice}&book-date=${item.createdAt}&appointment-date=${item.appointmentTime}&user-mobile=${item.userMobileNumber}&user-email=${item.userEmail}&booking-id=${item._id}&facility=${rgCheck ? "rg-stone" : "plunes"}`}>Voucher</a>,
          _id: item._id
        };
        prof_list.push({
          ...obj,
        });
      });
    } catch (error) {
      console.log(error, "error in Modify Data");
    }

    return prof_list;
  };

  const expand = (record, a, b) => {
    console.log(record, a, b, "record,a,b");
    if (expandedRowKeys[0] !== a._id) {
      set_expandedRowKeys([a._id]);
      set_payment_link(false);
    } else {
      set_expandedRowKeys([]);
      set_payment_link(false);
    }
  };

  const update_status = (val, data) => {
    props.update_global_booking({
      bookingId: data._id,
      bookingStatus: val,
    });
  };

  const updateBookingProcess = (val, data) => {
    props.update_booking_process({
      bookingId: data._id,
      bookingProcess: val,
    });
  };

  const change_limit = (a, b) => {
    //  console.log(a,b,"a and b in change_limit")
    set_global_bookings_params({
      ...global_bookings_params,
      limit: b,
      page: 1,
    });
    props.global_bookings({ ...global_bookings_params, limit: b, page: 1 });
  };

  const page_change = (a, b) => {
    // console.log(a,b,global_bookings_params,"a and b global_bookings_params")
    // if(global_bookings_params.page !== a){
    if (true) {
      set_global_bookings_params({
        ...global_bookings_params,
        page: a,
        limit: b,
      });
      props.global_bookings({ ...global_bookings_params, page: a, limit: b });
    }
  };

  const handle_reset = (a, b, c) => {
    handleReset(a);
    set_state({ filteredInfo: null });
    set_global_bookings_params({
      ...global_bookings_params,
      page: 1,
      searchParams: {
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters: {
        // userType:"",
        // level:"",
        // catalogue:''
      },
    });
    props.global_bookings({
      ...global_bookings_params,
      page: 1,
      searchParams: {
        // name:'',
        // email:'',
        // mobileNumber:''
      },
      filters: {
        // userType:"",
        // level:"",
        // catalogue:''
      },
    });
  };
  const clear_filters = () => {
    // handleReset()
    set_state({ filteredInfo: null });
    // clearFilters()
    set_state({ searchText: "" });
    set_global_bookings_params({
      ...global_bookings_params,
      page: 1,
      filters: {
        // userType:"",
        // catalogue:'',
        // level:''
      },
    });
    props.global_bookings({
      ...global_bookings_params,
      page: 1,
      filters: {
        // userType:"",
        // catalogue:'',
        // level:''
      },
    });
  };

  const row_click = (r) => {
    console.log(r, "r in row_click");
    set_professional_selected(r);
  };

  const set_insurance_data = (data) => {
    let ins = {
      bookingId: data._id,
      haveInsurance: data.haveInsurance,
      insuranceDetails: {
        insuranceId: data.insuranceDetails
          ? data.insuranceDetails.insuranceId ? data.insuranceDetails.insuranceId : "N/A"
          : "",
        policyNumber: data.insuranceDetails
          ? data.insuranceDetails.policyNumber ? data.insuranceDetails.policyNumber : "N/A"
          : "",
        insuranceCard: data.insuranceDetails
          ? data.insuranceDetails.insuranceCard ? data.insuranceDetails.insuranceCard : "N/A"
          : "",
        insurancePolicy: data.insuranceDetails
          ? data.insuranceDetails.insurancePolicy ? data.insuranceDetails.insurancePolicy : "N/A"
          : "",
        insurancePartner: data.insuranceDetails
          ? data.insuranceDetails.insurancePartner ? data.insuranceDetails.insurancePartner : "N/A"
          : "",
      },
      insuranceEditRequest: data.insuranceEditRequest,
    }
    let insImages = []
    if(data.insuranceDetails && Array.isArray(data.insuranceDetails.insuranceCard)) {
      data.insuranceDetails.insuranceCard.map((insuranceCard) => {
        insImages.push({
          imageUrl: insuranceCard
        })
      })
    }
    let updateImages = []
    if(data.insuranceUpdate && data.insuranceUpdate.insuranceCard && Array.isArray(data.insuranceUpdate.insuranceCard)) {
      data.insuranceUpdate.insuranceCard.map((insuranceCard) => {
        updateImages.push({
          imageUrl: insuranceCard
        })
      })
    }

    ins.insuranceDetails.insuranceCard = insImages
    ins.insuranceUpdate = updateImages
    
    set_insurance(ins);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      width: "30%",
      fixed: "left",
      ...getColumnSearchProps("userName", "Search name", "userName"),
    },
    {
      title: "Service",
      dataIndex: "serviceName",
      key: "serviceName",
      ...getColumnSearchProps("serviceName", "Search name", "serviceName"),
      width: "35%",
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "30%",
      fixed: "left",
      filters: [
        {
          text: "Today",
          value: "1",
        },
        {
          text: "Yesterday",
          value: "2",
        },
        {
          text: "Last week",
          value: "7",
        },
      ],
    },
    {
      title: "Mobile",
      dataIndex: "userMobileNumber",
      key: "userMobileNumber",
      fixed: "left",
      width: "30%",
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "userEmail",
      fixed: "left",
      width: "30%",
    },

    {
      title: "Professional",
      dataIndex: "professionalName",
      key: "professionalName",
      width: "30%",
    },

    {
      title: "Prof. Mobile",
      dataIndex: "professionalMobileNumber",
      key: "professionalMobileNumber",
      width: "30%",
    },
    {
      title: "Process Status",
      dataIndex: "bookingProcess",
      key: "bookingProcess",
      width: "35%",
      // filters: [
      //   {
      //     text: "Pending",
      //     value: "PENDING",
      //   },
      //   {
      //     text: "OPD Done",
      //     value: "OPD_DONE",
      //   },
      //   {
      //     text: "IPD Done",
      //     value: "IPD_DONE",
      //   },
      //   {
      //     text: "Cancelled",
      //     value: "CANCELLED",
      //   },
      //   {
      //     text: "Rescheduled",
      //     value: "RESCHEDULED",
      //   },
      // ],
      render: (item, record) => {
        return (
          <div>
            <div className="u-align-center">
              <Select
                value={record.bookingProcess}
                style={{ width: 120 }}
                onChange={(val) => updateBookingProcess(val, record)}
              >
                <Option
                  disabled={record.status === "PENDING"}
                  value="PENDING"
                >
                  Pending
                </Option>
                <Option
                  disabled={record.status === "OPD_DONE"}
                  value="OPD_DONE"
                >
                  OPD Done
                </Option>
                <Option
                  disabled={record.status === "IPD_DONE"}
                  value="IPD_DONE"
                >
                  IPD Done
                </Option>
                <Option
                  disabled={record.status === "CANCELLED"}
                  value="CANCELLED"
                >
                  Cancelled
                </Option>
                <Option
                  disabled={record.status === "RESCHEDULED"}
                  value="RESCHEDULED"
                >
                  Rescheduled
                </Option>
              </Select>
            </div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      width: "30%",
      render: (item, record) => {
        return (
          <div>
            <div className="u-align-center">
              <Select
                value={record.bookingStatus}
                style={{ width: 120 }}
                onChange={(val) => update_status(val, record)}
              >
                <Option
                  disabled={record.status === "Requested"}
                  value="Requested"
                >
                  Requested
                </Option>
                <Option
                  disabled={record.status === "Confirmed"}
                  value="Confirmed"
                >
                  Confirmed
                </Option>
                <Option
                  disabled={record.status === "Reserved"}
                  value="Reserved"
                >
                  Reserved
                </Option>
                <Option
                  disabled={record.status === "Cancelled"}
                  value="Cancelled"
                >
                  Cancelled
                </Option>
              </Select>
            </div>
          </div>
        );
      },
    },
    {
      title: "Appointement Time",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
      width: "40%",
    },
    {
      title: (record) => {
        return (
          <React.Fragment>
            <div>
              Booking Price
              <EditOutlined onClick={() => set_edit_price_modal(true)} />
            </div>
          </React.Fragment>
        );
      },
      dataIndex: "newPrice",
      key: "newPrice",
      width: "30%",
    },
    {
      title: "Voucher Link",
      dataIndex: "voucherLink",
      key: "voucherLink",
      width: "40%",
    },
    {
      title: "Due",
      dataIndex: "amountDue",
      key: "amountDue",
      width: "30%",
    },
    // {
    //   title: 'Discount',
    //   dataIndex: 'discount',
    //   key: 'discount',
    //   width: '30%'
    // },
    {
      title: "Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      width: "30%",
    },
    {
      title: "Mode",
      dataIndex: "paymentThrough",
      key: "paymentThrough",
      width: "30%",
    },
    {
      title: "Refund",
      dataIndex: "refundStatus",
      key: "refundStatus",
      width: "30%",
    },
    {
      title: "Paytm",
      dataIndex: "paytm",
      key: "paytm",
      width: "30%",
    },
    {
      title: "Razor Pay",
      dataIndex: "razorPay",
      key: "razorPay",
      width: "30%",
    },
    {
      title: "Offline",
      dataIndex: "offline",
      key: "offline",
      width: "20%",
    },

    {
      title: "Credits",
      dataIndex: "userCredits",
      key: "userCredits",
      width: "20%",
    },

    {
      title: "Payment Link",
      dataIndex: "paymentLink",
      key: "paymentLink",
      width: "30%",
    },
    {
      title: "Details",
      key: "action",
      width: "30%",
      render: (text, record) => (
        <React.Fragment>
          <Space size="middle">
            <a onClick={() => set_insurance_data(record)}>Insurance Details</a>
          </Space>
        </React.Fragment>
      ),
    },
    {
      title: "Update Invoice",
      key: "invoice",
      width: "30%",
      render: (text, record) => (
        <React.Fragment>
          <Space size="middle">
            <a onClick={() => set_insurance_data(record)}>Invoice Details</a>
          </Space>
        </React.Fragment>
      ),
    },
  ];

  const submit_payment_link_amount = () => {
    try {
      if (payment_link_data.amount === 0 || payment_link_data.amount === "") {
        set_error({
          ...error,
          amount: {
            state: true,
            text: "Enter amount for generating payment link.",
          },
        });
        throw new Error("Enter amount");
      }

      props.generate_link({
        bookingId: selected_row._id,
        paymentPrice: payment_link_data.amount,
      });
    } catch (error) {
      console.log(error);
    }
    if (payment_link_data.amount > selected_row.remainingAmount) {
      props.global_error({
        success: false,
        message: "Invalid Amount",
        heading: "Generate Payment Link",
      });
    } else {
    }
  };

  const validate_edit_price = () => {
    console.log("data", data);
    let error = {};
    data.map((item) => {
      error[item._id] = { state: false, text: "" };
    });

    Object.keys(professionals_amounts).map(function (item, index) {
      console.log(item, "item in validate_edit_price");
      if (professionals_amounts[item] < 1) {
        error[item] = { state: true, text: "Invalid Amount" };
      }
    });

    return error;
  };

  const submit_edit_price = () => {
    // console.log("in submit:", edit_price);
    const ret_error = validate_edit_price();
    console.log(ret_error, "ret_error in submit_edit_price");
    set_professionals_amounts_error({
      ...professionals_amounts_error,
      ...ret_error,
    });
    let flag = false;
    Object.keys(ret_error).map(function (item, index) {
      if (ret_error[item].state) {
        flag = true;
      }
    });

    if (!flag) {
      let professionals = [];
      Object.keys(professionals_amounts).map(function (item, index) {
        professionals.push({
          id: item,
          updatedPrice: professionals_amounts[item],
        });
      });
      console.log(
        professionals,
        "professionals array inside submit_edit_price"
      );
      props.update_global_booking({
        professionalId: props.professionalId,
        professionals: professionals,
        type: "update_all",
        // updatedPrice:edit_price.amount,
        // updatedPrice:amount
      });
    }
  };

  const handleCancel = () => {
    set_insurance(false);
    set_download_booking(false);
    set_booking_data({
      start_date: "",
      end_date: "",
      status: "All",
    });
    setFileCheck(true);
    set_edit_price_modal(false);
  };

  console.log(expandedRowKeys, "expandedRowKeys");
  function showTotal(total) {
    return `Total ${total} items`;
  }

  // const convertDatetoEpoch = () => {
  //   const sDate = new Date(bookingData.start_date);
  //   const eDate = new Date(bookingData.end_date);

  //   sDate = sDate.getTime();
  //   eDate = eDate.getTime();
  // }

  const dataBooking = {
    startingDate: getDateFormat(bookingData.start_date),
    endDate: getDateFormat(bookingData.end_date),
    bookingStatus: bookingData.status === "All" ? "" : bookingData.status,
  };

  // check by calling the api that file exists or not
  const checkBookings = () => {
    axios
      .post(
        `${get_url()}/booking/downloadBookings`,
        dataBooking
      )
      .then((res) => {
        console.log("File exists: ", res.data.success);
        if (res.data.success === undefined) {
          setFileCheck(true);
          downloadBookings();
        } else {
          setFileCheck(false);
        }
      })
      .catch((err) => console.log("DOWNLOAD ERROR: ", err));
  };

  // on the click of Download Bookings button
  const downloadBookings = () => {
    axios
      .post(
        `${get_url()}/booking/downloadBookings`,
        dataBooking,
        { responseType: "blob" }
      )
      .then((res) => {
        console.log(res);
        console.log(Date(bookingData.start_date).getTime);
        FileDownload(
          res.data,
          `bookingsData((${bookingData.start_date})-(${bookingData.end_date})).xlsx`
        );
      })
      .catch((err) => console.log("DOWNLOAD ERROR: ", err));
  };

  const openDownloadBookingModal = () => {
    set_download_booking(true);
  };

  //  if(!!professional_selected){
  //       return <Redirect to={`/dashboard/catalogue-manager?catalogue_id=${professional_selected._id}`} />
  //  }

  let arr = [];

  const get_label = (key) => {
    let label = "";
    data.map((item) => {
      if (item._id === key) {
        label = `${item.professionalName}`;
      }
    });
    return label;
  };

  return (
    <React.Fragment>
      <Card title="Bookings">
        <Button type="primary" onClick={openDownloadBookingModal}>
          Download Bookings
        </Button>
        <Form form={form} layout="vertical"></Form>
        <Table
          columns={columns}
          dataSource={data}
          loading={props.global_bookings_loading_flag}
          onChange={handleChange}
          rowClassName="cursor-pointer"
          scroll={{ x: 3000 }}
          expandedRowKeys={expandedRowKeys}
          onExpand={(a, b, c) => expand(a, b, c)}
          expandedRowRender={(record) => {
            console.log(record, "record");
            set_selected_row(record);

            return (
              <React.Fragment>
                <div key={record._id}>
                  <div className="flex-parent-new">
                    <span className="flex-child-1-new">
                      <AntdInput
                        label="Amount"
                        value={payment_link_data.amount}
                        disabled={props.generate_link_loading_flag}
                        loading={props.generate_link_loading_flag}
                        error_text={error.amount.text}
                        toggle_error={() =>
                          set_error({
                            ...error,
                            amount: { text: "", state: false },
                          })
                        }
                        error={error.amount.state}
                        name="amount"
                        validation="positive_integer"
                        onChange={(event) =>
                          set_payment_link_data({
                            ...payment_link_data,
                            amount: event.target.value,
                          })
                        }
                        placeholder="Enter Amount"
                      />
                    </span>
                    <span className="flex-child-1-new">
                      <Button
                        type="primary"
                        onClick={() => submit_payment_link_amount()}
                        disabled={props.generate_link_loading_flag}
                        loading={props.generate_link_loading_flag}
                        style={{ width: "auto", margin: "auto" }}
                      >
                        Generate Link
                      </Button>
                    </span>
                  </div>

                  {payment_link && (
                    <div className="flex-parent-new">
                      <CopyToClipboard
                        text={payment_link}
                        onCopy={() => {
                          set_copy(true);
                          setTimeout(() => {
                            set_copy(false);
                          }, 2000);
                        }}
                      >
                        <span className="flex-child-1-new">
                          <div
                            title={copy ? "Copied" : "Click to copy code"}
                            className="payment_link_wrapper"
                          >
                            <text className="payment_link_text">
                              {payment_link}
                            </text>
                          </div>
                        </span>
                      </CopyToClipboard>
                      <span className="flex-child-1-new"></span>
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          }}
          rowKey={(record) => record._id}
          pagination={{
            showSizeChanger: true,
            limit: global_bookings_params.limit,
            onChange: (a, c) => page_change(a, c),
            defaultCurrent: global_bookings_params.page,
            total: global_bookings_params.total,
            showTotal: showTotal,
          }}
        />

        <Modal
          title="Update Price"
          visible={edit_price_modal}
          onOk={submit_edit_price}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          width={800}
        >
          <div>
            {Object.keys(professionals_amounts).map(function (item, index) {
              arr.push(
                <AntdInput
                  label={get_label(item)}
                  className="alignment"
                  value={[professionals_amounts[item]]}
                  error_text={professionals_amounts_error[item].text}
                  loading={props.check_number_loading_flag}
                  disabled={props.check_number_loading_flag}
                  toggle_error={() =>
                    set_professionals_amounts_error({
                      ...professionals_amounts_error,
                      [item]: { text: "", state: false },
                    })
                  }
                  error={professionals_amounts_error[item].state}
                  name={item}
                  onChange={(event) =>
                    set_professionals_amounts({
                      ...professionals_amounts,
                      [event.target.name]: event.target.value,
                    })
                  }
                  placeholder="Enter amount"
                  validation="positive_integer"
                />
              );
            })}
            {arr}
          </div>
        </Modal>

        <Modal
          title="Insurance Details"
          visible={insurance}
          onOk={handleCancel}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          width={600}
        >
          {insurance.haveInsurance ? (
            <div>
              <div className="informnation-wrapper-modal">
                <div className="informnation-child-1">
                  <div className="information-heading">Insutance Id</div>
                  <div className="information-content">
                    {insurance.insuranceDetails.insuranceId
                      ? insurance.insuranceDetails.insuranceId
                      : "N/A"}
                  </div>
                </div>
                <div className="informnation-child-1">
                  <div className="information-heading">Policy Number</div>
                  <div className="information-content">
                    {insurance.insuranceDetails.policyNumber
                      ? insurance.insuranceDetails.policyNumber
                      : "N/A"}
                  </div>
                </div>
                <div className="informnation-child-1">
                  <div className="information-heading">Insurance Partner</div>
                  <div className="information-content">
                    {insurance.insuranceDetails.insurancePartner
                      ? insurance.insuranceDetails.insurancePartner
                      : "N/A"}
                  </div>
                </div>
              </div>

              <div className="video-reports-wrapper">
                {insurance.insuranceDetails.insuranceCard && (
                  <div className="video-reports-child-1">
                    <div className="heading-new">Insurance Photos</div>
                    <div className="photos-wrapper">
                      <Photogallery
                        data={insurance.insuranceDetails.insuranceCard}
                        limit={1}
                      />
                    </div>
                  </div>
                )}

                <div className="video-reports-child-1">
                  <div className="heading-new">Insurance Policy</div>
                  <div className="photos-wrapper">
                    {(insurance.insuranceDetails.insurancePolicy && insurance.insuranceDetails.insurancePolicy != "false")  ? (
                      <div>
                        <div className="video-div">
                          <a
                            href={insurance.insuranceDetails.insurancePolicy}
                            target="_blank"
                          >
                            {insurance.insuranceDetails.insurancePolicy}
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div>N/A</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="video-reports-wrapper">
                {insurance.insuranceEditRequest && (
                  <div className="video-reports-child-1">
                    <div className="heading-new">Insurance Photos Update Request</div>
                    <div className="photos-wrapper">
                      <Photogallery
                        data={insurance.insuranceUpdate}
                        limit={1}
                      />
                    </div>
                  </div>
                )}
                {insurance.insuranceEditRequest && <div className="video-reports-child-1">
                    <div className="photos-wrapper" style={{ display: "flex", height: "100%" }}>
                      <Button
                        type="primary"
                        disabled={props.generate_link_loading_flag}
                        loading={props.generate_link_loading_flag}
                        style={{ width: "auto", margin: "auto" }}
                        onClick={(e) => {
                          e.preventDefault()
                          props.approve_booking_insurance_update({bookingId: insurance.bookingId})
                        }}
                      >
                        Approve Update Request
                      </Button>
                    </div>
                </div>}
              </div>
            </div>
          ) : (
            <div>No Insurance Available</div>
          )}
        </Modal>
        <Modal
          title="Invoice Details"
          visible={invoice}
          onOk={handleCancel}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          width={600}
        >

        </Modal>
        <Modal
          title="Booking Details"
          visible={downloadBooking}
          onOk={handleCancel}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          width={600}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              htmlType="submit"
              disabled={
                bookingData.start_date === "" || bookingData.end_date === ""
              }
              type="primary"
              onClick={() => checkBookings()}
            >
              Download
            </Button>,
            !fileCheck && (
              <Alert
                style={{ width: "60%" }}
                message="File does not exists for given dates"
                type="error"
                showIcon
              />
            ),
          ]}
        >
          <Form layout="vertical">
            <Form.Item label={"Starting Date"}>
              <DatePicker
                style={{ width: "100%" }}
                value={bookingData.start_date}
                onChange={(event) => {
                  console.log("START DATE: ", event);
                  let today = new Date();
                  today.setDate(today.getDate());
                  let date = event;
                  if (date <= today) {
                    set_booking_data({
                      ...bookingData,
                      start_date: date,
                      end_date: "",
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item label={"End date"}>
              <DatePicker
                style={{ width: "100%" }}
                value={bookingData.end_date}
                onChange={(event) => {
                  console.log("END DATE: ", event);
                  let today = new Date();
                  today.setDate(today.getDate());
                  let date = event;
                  if (date >= bookingData.start_date && date <= today) {
                    set_booking_data({
                      ...bookingData,
                      end_date: date,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item label={"Booking status"}>
              <Select
                style={{ width: "100%" }}
                value={bookingData.status}
                onChange={(event) => {
                  set_booking_data({
                    ...bookingData,
                    status: event,
                  });
                }}
              >
                <Option value="All">All</Option>
                <Option value="Requested">Requested</Option>
                <Option value="Confirmed">Confirmed</Option>
                <Option value="Reserved">Reserved</Option>
                <Option value="Failed">Failed</Option>
                <Option value="Cancelled">Cancelled</Option>
                <Option value="Cancellation Requested">
                  Cancellation Requested
                </Option>
                <Option value="Cart">Cart</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </React.Fragment>
  );
};
export default BookingsView;
