import React, { useState } from "react";
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

const PhotoItem = ({ image, thumb, group, count, display, data, limit }) => {
    console.log(display,"display in Phoeto item")
            return  (
                <div className="image-gallery-div" style={{padding: "0rem", display:!!display?"inherit":'none', position:"relative" }}>
                  <LightgalleryItem group={group} src={image} thumb={thumb}>
                    {count === 0 && data.length > limit ? <div className="img-count-container">
                      <span className="image-count-span" style={{cursor:'pointer'}}>{`+${data.length} more`}</span>
                      <img id={count===0?"light-gallery-first-image":''}  src={image} style={{ width: "100%", cursor:'pointer', borderRadius:'.5rem' , display:!!display?"inherit":'none'}} />
                    </div>: 
                      <img id={count===0?"light-gallery-first-image":''}  src={image} style={{ width: "100%", cursor:'pointer', borderRadius:'.5rem' , display:!!display?"inherit":'none'}} />
                    }
                  </LightgalleryItem>
                </div>
              )
}

const OpenButtonWithHoc = withLightgallery(({ openGallery, ...props }) => {
  return (
    <button
      {...props}
      onClick={() => {
        openGallery("group1");
      }}
      className={["button is-primary", props.className || ""].join(" ")}
    >
      Open first photos group (using hoc)
    </button>
  );
});

const OpenButtonWithHook = props => {
  const { openGallery } = useLightgallery()
  const { data, limit } = props
  return (
    <React.Fragment>
    <div className="plus_more_wrapper"
      style={{
        display: "flex",
        position:"relative",
        alignItems: "center",
        justifyContent: props.width?"":"space-evenly",
        flexWrap:"wrap"
      }}
    >
                <span style={{cursor:'pointer'}} onClick={() => openGallery("group2")}>{`+${data.length - limit} more`}</span>
                </div>
   </React.Fragment>
  );
};

function Photogallery(props) {
  const [visible, setVisible] = useState(true);
  const {data, limit} = props

  const [render_items, set_render_items] = React.useState([])
  const [toggle, set_toggle] = React.useState(false)

  let searchbar = document.getElementById('searchbar')
  let searchIcon = document.getElementsByClassName('header_searchIcon')




React.useEffect(()=>{
        let updated_arr  =   data.map((p, idx) =><PhotoItem key={idx} count={idx} image={p.imageUrl} group="group2" display={get_display_prop(idx)} data={data} limit={limit} />)
        set_render_items([...updated_arr])
},[data])


const get_display_prop = (key) => {
    console.log(key,"key on get_display_prop")
        if(key===0){
          return true
        }else {
          return false
        }
            // if(data.length >limit){
            //         if(key>=limit){
            //             return  false
            //         }else{
            //             return true
            //         }
            // }else {
            //     return true
            // }
}
  const on_before_slide = () => {
    // console.log(searchIcon,"searchIcon")
    // if(searchIcon){
    //     searchIcon[0].style.display = "none"
    // }
    // if(searchbar){
    //     searchbar.style.display = 'none'
    // }
  }

  const on_close_after = () => {
    // if(searchIcon){
    //     searchIcon[0].style.display = "inline"
    // }
    // if(searchbar){
    //     searchbar.style.display = 'inline'
    // }
  }

  const on_after_slide = () => {
   
  }

  return (
    <div className="content">
      <div>
        {visible ? (
          <LightgalleryProvider
            onBeforeOpen={() => console.info("onBeforeOpen")}
            onAfterOpen={() => console.info("onAfterOpen")}
            onSlideItemLoad={() => console.info("onSlideItemLoad")}
            onBeforeSlide={() => on_before_slide()}
            onAfterSlide={() =>   on_after_slide()}
            onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
            onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
            onDragstart={() => console.info("onDragstart")}
            onDragmove={() => console.info("onDragmove")}
            onDragend={() => console.info("onDragend")}
            onSlideClick={() => console.info("onSlideClick")}
            onBeforeClose={() => console.info("onBeforeClose")}
            onCloseAfter={() => on_close_after()}
            plugins = {["lg-fullscreen.js", "lg-thumbnail.js", "lg-video.js", "lg-zoom.js"]}
          >
            <div           
            style={{
              display: "flex",
              position:"relative",
              alignItems: "center",
              justifyContent: props.width?"":"space-evenly",
              flexWrap:"wrap"
            }}
            >

                {render_items}
            </div>
          </LightgalleryProvider>
        ) : null}
      </div>
    </div>
  )
}

export default Photogallery


